export type TOrderDetails = {
  fplRef: string | undefined
  customerRef: string | null | undefined
  loadId: string | null | undefined
  transactionId: string | null | undefined
  agreedRate?: number
  tariff: string | undefined
  tariffCategory: string | undefined
}

export type TAdress = {
  id: string | undefined | null
  name: string | undefined | null
  postcode: string | undefined | null
  adressLine1: string | undefined | null
  adressLine2: string | undefined | null
  city: string | undefined | null
  countryName: string | undefined | null
}

export const DeliveryType = {
  collection: 'COLLECTION',
  delivery: 'DELIVERY',
}

export type TAdresses = {
  id: string
  type: string
  at: string | undefined | null
  by: string | undefined | null
  adress: TAdress
}[]

export type TPriceCurrency = {
  price: number
  symbol: string
}

import { Box, Stack } from '@mui/material'
import { TPriceCurrency } from 'types/orderDetails.types'

import { OrderDetailsLabel } from './OrderDetailsItems'

interface IProps {
  label: keyof typeof OrderDetailsLabel
  value: unknown | TPriceCurrency
}

function OrderDetailsItem(props: IProps) {
  const { label, value } = props

  const formatValue = () => {
    if (value === null || value === undefined || value === '') {
      return 'N/A'
    }

    if (typeof value === 'string') {
      return value
    }
  }

  return (
    <Stack direction='row' spacing={1}>
      <Box sx={{ opacity: 0.5, whiteSpace: 'nowrap' }}>{label}:</Box>
      <Box>
        <strong>{formatValue()}</strong>
      </Box>
    </Stack>
  )
}

export default OrderDetailsItem

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: any;
  URL: any;
  UUID: any;
};


export type AccountPayableCurrencyAmountDto = {
  __typename?: 'AccountPayableCurrencyAmountDto';
  id: Scalars['UUID'];
  net: Scalars['Decimal'];
  vat: Scalars['Decimal'];
  gross: Scalars['Decimal'];
  currencySymbol?: Maybe<Scalars['String']>;
};

export type AccountPayableCurrencyAmountDtoFilterInput = {
  and?: Maybe<Array<AccountPayableCurrencyAmountDtoFilterInput>>;
  or?: Maybe<Array<AccountPayableCurrencyAmountDtoFilterInput>>;
  id?: Maybe<UuidOperationFilterInput>;
  net?: Maybe<DecimalOperationFilterInput>;
  vat?: Maybe<DecimalOperationFilterInput>;
  gross?: Maybe<DecimalOperationFilterInput>;
  currencySymbol?: Maybe<StringOperationFilterInput>;
};

export type AccountPayableOverviewDto = {
  __typename?: 'AccountPayableOverviewDto';
  id: Scalars['UUID'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  supplierCodes: Array<Scalars['String']>;
  dollarsAmount: Scalars['Decimal'];
  eurosAmount: Scalars['Decimal'];
  poundsAmount: Scalars['Decimal'];
  scheduledInvoicesCount: Scalars['Int'];
  bookingsWithApprovedDocumentsCount: Scalars['Int'];
  bookingsWithCompletedDocumentsCount: Scalars['Int'];
  bookingsWithCreditNoteReceivedCount: Scalars['Int'];
  bookingsWithOpenQueriesCount: Scalars['Int'];
  bookingsWithSupplementaryInvoiceReceivedCount: Scalars['Int'];
  overduesForApprovalCount: Scalars['Int'];
  failedPaymentsCount: Scalars['Int'];
  sentPaymentsCount: Scalars['Int'];
};

export type AccountPayablePanelDto = {
  __typename?: 'AccountPayablePanelDto';
  id: Scalars['UUID'];
  htmlString: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type AccountPayablePanelPodDto = {
  __typename?: 'AccountPayablePanelPodDto';
  signatureUrls: Array<Scalars['String']>;
  proofOfDeliveryPhotoUrls: Array<Scalars['String']>;
  proofOfCollectionPhotoUrls: Array<Scalars['String']>;
  id: Scalars['UUID'];
  htmlString: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type AccountPayablePaymentDateDtoInput = {
  accountPayableIds?: Maybe<Array<Scalars['Long']>>;
  paymentDate: Scalars['DateTime'];
};

export type AccountPayableRecord = {
  __typename?: 'AccountPayableRecord';
  statusLabel: Scalars['String'];
  status: AccountPayableRecordStatus;
  isDriverInvoiceApproved: Scalars['Boolean'];
  isPodDocumentApproved: Scalars['Boolean'];
  bookingInvoiceId: Scalars['Long'];
  bookingInvoice: BookingInvoice;
  net: Scalars['Decimal'];
  vat: Scalars['Decimal'];
  gross: Scalars['Decimal'];
  currencyId?: Maybe<Scalars['Long']>;
  currency: Currency;
  bookingInvoiceBatchLineId?: Maybe<Scalars['Long']>;
  bookingInvoiceBatchLine?: Maybe<BookingInvoiceBatchLine>;
  openQueries?: Maybe<Array<OpenQuery>>;
  logs?: Maybe<Array<AccountPayableRecordLog>>;
  reviewPeriodStartDateTime: Scalars['DateTime'];
  payedExternally: Scalars['Boolean'];
  remittanceAdviceSent: Scalars['Boolean'];
  notifications?: Maybe<Array<MobileNotification>>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type AccountPayableRecordFilterInput = {
  and?: Maybe<Array<AccountPayableRecordFilterInput>>;
  or?: Maybe<Array<AccountPayableRecordFilterInput>>;
  status?: Maybe<AccountPayableRecordStatusOperationFilterInput>;
  isDriverInvoiceApproved?: Maybe<BooleanOperationFilterInput>;
  isPodDocumentApproved?: Maybe<BooleanOperationFilterInput>;
  bookingInvoiceId?: Maybe<LongOperationFilterInput>;
  bookingInvoice?: Maybe<BookingInvoiceFilterInput>;
  net?: Maybe<DecimalOperationFilterInput>;
  vat?: Maybe<DecimalOperationFilterInput>;
  gross?: Maybe<DecimalOperationFilterInput>;
  currencyId?: Maybe<LongOperationFilterInput>;
  currency?: Maybe<CurrencyFilterInput>;
  bookingInvoiceBatchLineId?: Maybe<LongOperationFilterInput>;
  bookingInvoiceBatchLine?: Maybe<BookingInvoiceBatchLineFilterInput>;
  openQueries?: Maybe<ListFilterInputTypeOfOpenQueryFilterInput>;
  logs?: Maybe<ListFilterInputTypeOfAccountPayableRecordLogFilterInput>;
  reviewPeriodStartDateTime?: Maybe<DateTimeOperationFilterInput>;
  payedExternally?: Maybe<BooleanOperationFilterInput>;
  remittanceAdviceSent?: Maybe<BooleanOperationFilterInput>;
  notifications?: Maybe<ListFilterInputTypeOfMobileNotificationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type AccountPayableRecordLog = {
  __typename?: 'AccountPayableRecordLog';
  accountPayableRecordId: Scalars['Long'];
  accountPayableRecord: AccountPayableRecord;
  reason: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type AccountPayableRecordLogFilterInput = {
  and?: Maybe<Array<AccountPayableRecordLogFilterInput>>;
  or?: Maybe<Array<AccountPayableRecordLogFilterInput>>;
  accountPayableRecordId?: Maybe<LongOperationFilterInput>;
  accountPayableRecord?: Maybe<AccountPayableRecordFilterInput>;
  reason?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

/** A connection to a list of items. */
export type AccountPayableRecordLogsConnection = {
  __typename?: 'AccountPayableRecordLogsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<AccountPayableRecordLogsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AccountPayableRecordLog>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AccountPayableRecordLogsEdge = {
  __typename?: 'AccountPayableRecordLogsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AccountPayableRecordLog;
};

export type AccountPayableRecordSortInput = {
  status?: Maybe<SortEnumType>;
  isDriverInvoiceApproved?: Maybe<SortEnumType>;
  isPodDocumentApproved?: Maybe<SortEnumType>;
  bookingInvoiceId?: Maybe<SortEnumType>;
  bookingInvoice?: Maybe<BookingInvoiceSortInput>;
  net?: Maybe<SortEnumType>;
  vat?: Maybe<SortEnumType>;
  gross?: Maybe<SortEnumType>;
  currencyId?: Maybe<SortEnumType>;
  currency?: Maybe<CurrencySortInput>;
  bookingInvoiceBatchLineId?: Maybe<SortEnumType>;
  bookingInvoiceBatchLine?: Maybe<BookingInvoiceBatchLineSortInput>;
  reviewPeriodStartDateTime?: Maybe<SortEnumType>;
  payedExternally?: Maybe<SortEnumType>;
  remittanceAdviceSent?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum AccountPayableRecordStatus {
  UnderReview = 'UNDER_REVIEW',
  Flagged = 'FLAGGED',
  ScheduledPayment = 'SCHEDULED_PAYMENT',
  OpenQuery = 'OPEN_QUERY',
  PaymentSent = 'PAYMENT_SENT',
  PaymentFailed = 'PAYMENT_FAILED',
  Archived = 'ARCHIVED'
}

export type AccountPayableRecordStatusDtoInput = {
  id: Scalars['Long'];
  status: AccountPayableRecordStatus;
  payedExternally?: Maybe<Scalars['Boolean']>;
};

export type AccountPayableRecordStatusOperationFilterInput = {
  eq?: Maybe<AccountPayableRecordStatus>;
  neq?: Maybe<AccountPayableRecordStatus>;
  in?: Maybe<Array<AccountPayableRecordStatus>>;
  nin?: Maybe<Array<AccountPayableRecordStatus>>;
};

/** A connection to a list of items. */
export type AccountPayableRecordsConnection = {
  __typename?: 'AccountPayableRecordsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<AccountPayableRecordsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AccountPayableRecord>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AccountPayableRecordsEdge = {
  __typename?: 'AccountPayableRecordsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AccountPayableRecord;
};

export enum AccountsPayableFilterCriteria {
  InvoiceDate = 'INVOICE_DATE',
  PaymentDate = 'PAYMENT_DATE'
}

/** A connection to a list of items. */
export type ActivitiesConnection = {
  __typename?: 'ActivitiesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ActivitiesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Activity>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ActivitiesEdge = {
  __typename?: 'ActivitiesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Activity;
};

export type Activity = {
  __typename?: 'Activity';
  type: ActivityType;
  note: Scalars['String'];
  date: Scalars['DateTime'];
  bookingId?: Maybe<Scalars['Long']>;
  booking?: Maybe<Booking>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  attachments: Array<File>;
};

export type ActivityFilterInput = {
  and?: Maybe<Array<ActivityFilterInput>>;
  or?: Maybe<Array<ActivityFilterInput>>;
  type?: Maybe<ActivityTypeOperationFilterInput>;
  note?: Maybe<StringOperationFilterInput>;
  date?: Maybe<DateTimeOperationFilterInput>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ActivitySortInput = {
  type?: Maybe<SortEnumType>;
  note?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum ActivityType {
  Note = 'NOTE',
  Meeting = 'MEETING',
  Call = 'CALL',
  QuoteStatus = 'QUOTE_STATUS'
}

export type ActivityTypeOperationFilterInput = {
  eq?: Maybe<ActivityType>;
  neq?: Maybe<ActivityType>;
  in?: Maybe<Array<ActivityType>>;
  nin?: Maybe<Array<ActivityType>>;
};

export type Address = {
  __typename?: 'Address';
  isDefault: Scalars['Boolean'];
  customerId: Scalars['Long'];
  customer: Customer;
  postcode: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryId: Scalars['Long'];
  country: Country;
  isAutoFill: Scalars['Boolean'];
  contact?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isNotification: Scalars['Boolean'];
  googleMapsPlaceId?: Maybe<Scalars['String']>;
  googleMapsPlaceIdLastUpdate?: Maybe<Scalars['DateTime']>;
  isVerified: Scalars['Boolean'];
  bookings: Array<BookingAddress>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type AddressBookingDto = {
  __typename?: 'AddressBookingDto';
  id: Scalars['Long'];
  sequenceOrder: Scalars['Int'];
  type: BookingAddressType;
  address: AddressDto;
};

export type AddressContactEmailDtoInput = {
  checkInMessage: Scalars['String'];
  bookingAddressId: Scalars['Long'];
};

export type AddressDto = {
  __typename?: 'AddressDto';
  id: Scalars['ID'];
  postcode: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryId: Scalars['Long'];
  googleMapsPlaceId?: Maybe<Scalars['String']>;
  isVerified: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AddressFilterInput = {
  and?: Maybe<Array<AddressFilterInput>>;
  or?: Maybe<Array<AddressFilterInput>>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  customerId?: Maybe<LongOperationFilterInput>;
  customer?: Maybe<CustomerFilterInput>;
  postcode?: Maybe<StringOperationFilterInput>;
  addressLine1?: Maybe<StringOperationFilterInput>;
  addressLine2?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  countryId?: Maybe<LongOperationFilterInput>;
  country?: Maybe<CountryFilterInput>;
  isAutoFill?: Maybe<BooleanOperationFilterInput>;
  contact?: Maybe<StringOperationFilterInput>;
  telephoneNumber?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  isNotification?: Maybe<BooleanOperationFilterInput>;
  googleMapsPlaceId?: Maybe<StringOperationFilterInput>;
  googleMapsPlaceIdLastUpdate?: Maybe<DateTimeOperationFilterInput>;
  isVerified?: Maybe<BooleanOperationFilterInput>;
  bookings?: Maybe<ListFilterInputTypeOfBookingAddressFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type AddressPackageDtoInput = {
  packageId: Scalars['Long'];
  quantity: Scalars['Int'];
};

export type AddressSortInput = {
  isDefault?: Maybe<SortEnumType>;
  customerId?: Maybe<SortEnumType>;
  customer?: Maybe<CustomerSortInput>;
  postcode?: Maybe<SortEnumType>;
  addressLine1?: Maybe<SortEnumType>;
  addressLine2?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  countryId?: Maybe<SortEnumType>;
  country?: Maybe<CountrySortInput>;
  isAutoFill?: Maybe<SortEnumType>;
  contact?: Maybe<SortEnumType>;
  telephoneNumber?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  isNotification?: Maybe<SortEnumType>;
  googleMapsPlaceId?: Maybe<SortEnumType>;
  googleMapsPlaceIdLastUpdate?: Maybe<SortEnumType>;
  isVerified?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type AddressesConnection = {
  __typename?: 'AddressesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<AddressesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Address>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AddressesEdge = {
  __typename?: 'AddressesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Address;
};

export type ApplicationRole = {
  __typename?: 'ApplicationRole';
  description?: Maybe<Scalars['String']>;
  policyRoles: Array<PolicyRole>;
  id: Scalars['Long'];
  name?: Maybe<Scalars['String']>;
  normalizedName?: Maybe<Scalars['String']>;
  concurrencyStamp?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER',
  Validation = 'VALIDATION'
}

export type ArchiveBookingDtoInput = {
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
};

export type AuditRecord = {
  __typename?: 'AuditRecord';
  table: Scalars['String'];
  action: Scalars['String'];
  primaryKey?: Maybe<Scalars['Long']>;
  changes?: Maybe<Scalars['String']>;
  columnValues?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  relatedBookingId?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type AuditRecordFilterInput = {
  and?: Maybe<Array<AuditRecordFilterInput>>;
  or?: Maybe<Array<AuditRecordFilterInput>>;
  table?: Maybe<StringOperationFilterInput>;
  action?: Maybe<StringOperationFilterInput>;
  primaryKey?: Maybe<LongOperationFilterInput>;
  changes?: Maybe<StringOperationFilterInput>;
  columnValues?: Maybe<StringOperationFilterInput>;
  transactionId?: Maybe<StringOperationFilterInput>;
  success?: Maybe<BooleanOperationFilterInput>;
  errorMessage?: Maybe<StringOperationFilterInput>;
  relatedBookingId?: Maybe<LongOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type AuditRecordSortInput = {
  table?: Maybe<SortEnumType>;
  action?: Maybe<SortEnumType>;
  primaryKey?: Maybe<SortEnumType>;
  changes?: Maybe<SortEnumType>;
  columnValues?: Maybe<SortEnumType>;
  transactionId?: Maybe<SortEnumType>;
  success?: Maybe<SortEnumType>;
  errorMessage?: Maybe<SortEnumType>;
  relatedBookingId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type Banner = {
  __typename?: 'Banner';
  type: BannerType;
  fileId?: Maybe<Scalars['Long']>;
  file?: Maybe<File>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type BannerFilterInput = {
  and?: Maybe<Array<BannerFilterInput>>;
  or?: Maybe<Array<BannerFilterInput>>;
  type?: Maybe<BannerTypeOperationFilterInput>;
  fileId?: Maybe<LongOperationFilterInput>;
  file?: Maybe<FileFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BannerSortInput = {
  type?: Maybe<SortEnumType>;
  fileId?: Maybe<SortEnumType>;
  file?: Maybe<FileSortInput>;
  isActive?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum BannerType {
  MainPageWide = 'MAIN_PAGE_WIDE'
}

export type BannerTypeOperationFilterInput = {
  eq?: Maybe<BannerType>;
  neq?: Maybe<BannerType>;
  in?: Maybe<Array<BannerType>>;
  nin?: Maybe<Array<BannerType>>;
};

export type Booking = {
  __typename?: 'Booking';
  calculatedStatus: CalculatedStatus;
  statusLabel?: Maybe<Scalars['String']>;
  /** @deprecated Use NextNonSubmittedSequenceOrder */
  nextNonSubmittedSequenceOrder: Scalars['Int'];
  status: BookingStatus;
  isPending: Scalars['Boolean'];
  isPreBooked: Scalars['Boolean'];
  isOnHold: Scalars['Boolean'];
  isCancelledOrQuoteLost: Scalars['Boolean'];
  isQuickQuote: Scalars['Boolean'];
  /** @deprecated Use IsCancelledOrQuoteLost instead of this */
  isCancelled: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isQuoteOriginally: Scalars['Boolean'];
  isCreatedByCustomer: Scalars['Boolean'];
  customerId: Scalars['Long'];
  customer: Customer;
  contactId: Scalars['Long'];
  contact: Contact;
  overrideContactEmail?: Maybe<Scalars['String']>;
  overrideContactTelephoneNumber?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  docketReference?: Maybe<Scalars['String']>;
  takenBy?: Maybe<Scalars['String']>;
  takenOn?: Maybe<Scalars['DateTime']>;
  alternativeOurReference?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  addresses: Array<BookingAddress>;
  collectionEstimatedTimeOfArrival?: Maybe<Scalars['DateTime']>;
  deliveryEstimatedTimeOfArrival?: Maybe<Scalars['DateTime']>;
  tariffId?: Maybe<Scalars['Long']>;
  tariff?: Maybe<Tariff>;
  distance: Scalars['Decimal'];
  isCalculateDistanceAutomatically: Scalars['Boolean'];
  isWaitAndReturn: Scalars['Boolean'];
  noteOrSpecialInstruction?: Maybe<Scalars['String']>;
  quoteLostReasonId?: Maybe<Scalars['Long']>;
  quoteLostReason?: Maybe<QuoteLostReason>;
  cancellationReasonOrQuoteLostDetail?: Maybe<Scalars['String']>;
  potentialBookingId?: Maybe<Scalars['Long']>;
  potentialBooking?: Maybe<PotentialBooking>;
  template?: Maybe<ScheduledBookingTemplate>;
  createdByScheduledTask?: Maybe<ScheduledBookingTask>;
  packages: Array<Package>;
  drivers?: Maybe<Array<BookingDriver>>;
  driverBids: Array<DriverBid>;
  charges: Array<BookingCharge>;
  courierExchangeLoadId?: Maybe<Scalars['String']>;
  courierExchangeLoad?: Maybe<CourierExchangeLoad>;
  courierExchangeLastManualUpdateAt?: Maybe<Scalars['DateTime']>;
  consignmentFee: Scalars['Decimal'];
  isEmailNotification: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  isTransitIgnored: Scalars['Boolean'];
  isBiddingEnabled: Scalars['Boolean'];
  trackingGuid?: Maybe<Scalars['UUID']>;
  trackingExpiredDate?: Maybe<Scalars['DateTime']>;
  invoices: Array<BookingInvoice>;
  attachments: Array<BookingAttachment>;
  tasks?: Maybe<Array<Task>>;
  activities?: Maybe<Array<Activity>>;
  exchangeRates?: Maybe<Array<BookingExchangeRate>>;
  logs?: Maybe<Array<BookingLog>>;
  notifications?: Maybe<Array<MobileNotification>>;
  docs: Scalars['Int'];
  hasNotes: Scalars['Boolean'];
  isUsingInternalApp: Scalars['Boolean'];
  salesInvoiceNumber?: Maybe<Scalars['Long']>;
  ourReference: Scalars['String'];
  revenue: Scalars['Decimal'];
  /** @deprecated Cost is calculated without exchange rate conversion */
  cost: Scalars['Decimal'];
  /** @deprecated This method uses Cost prop */
  marginPercent: Scalars['Decimal'];
  isDeletable: Scalars['Boolean'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  courierServicesStatusLabel: Scalars['String'];
  totalDriverCharge: Scalars['Decimal'];
  pagedStatus: CalculatedStatus;
  hasTasks: Scalars['Boolean'];
  currentAction?: Maybe<BookingAddress>;
};

export type BookingAddress = {
  __typename?: 'BookingAddress';
  status: BookingAddressStatus;
  bookingId: Scalars['Long'];
  booking: Booking;
  addressId: Scalars['Long'];
  address: Address;
  type: BookingAddressType;
  at?: Maybe<Scalars['DateTime']>;
  by?: Maybe<Scalars['DateTime']>;
  sequenceOrder: Scalars['Int'];
  /** Note for driver */
  note?: Maybe<Scalars['String']>;
  noteFromDriver?: Maybe<Scalars['String']>;
  waitingTime?: Maybe<Scalars['Int']>;
  waitingTimeCharge?: Maybe<Scalars['Decimal']>;
  arrivedAt?: Maybe<Scalars['DateTime']>;
  signedAt?: Maybe<Scalars['DateTime']>;
  /** Collection or Delivery Time */
  processTime?: Maybe<Scalars['DateTime']>;
  signedByFirstName?: Maybe<Scalars['String']>;
  signedByLastName?: Maybe<Scalars['String']>;
  signedBy: Scalars['String'];
  /** Estimated Time of Arrival */
  eTA?: Maybe<Scalars['DateTime']>;
  driverTimeFrom?: Maybe<Scalars['String']>;
  driverTimeTo?: Maybe<Scalars['String']>;
  courierServiceNumberOfItems?: Maybe<Scalars['Int']>;
  courierServiceWeight?: Maybe<Scalars['Decimal']>;
  courierServiceWeightUnit?: Maybe<Scalars['String']>;
  courierServiceStopId?: Maybe<Scalars['Long']>;
  isDriverLate: Scalars['Boolean'];
  allocations: Array<PackageAllocation>;
  addressPackages?: Maybe<Array<BookingAddressPackage>>;
  exceptionId?: Maybe<Scalars['Long']>;
  exception?: Maybe<ExceptionReason>;
  driverWaitTimeId?: Maybe<Scalars['Long']>;
  driverWaitTime?: Maybe<DriverWaitTime>;
  reasonCodeId?: Maybe<Scalars['Long']>;
  reasonCode?: Maybe<ReasonCode>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  signature?: Maybe<File>;
  attachments: Array<File>;
  nextAddressId?: Maybe<Scalars['Long']>;
  nextNonSubmittedSequenceOrder: Scalars['Int'];
  isReadOnly: Scalars['Boolean'];
  totalPackagesQuantity: Scalars['Int'];
  totalPackagesWeight?: Maybe<Scalars['Decimal']>;
};

export type BookingAddressCompleteDtoInput = {
  id: Scalars['ID'];
  bookingId: Scalars['ID'];
  defaultVehicleId?: Maybe<Scalars['Long']>;
  signedAt: Scalars['DateTime'];
};

export type BookingAddressFilterInput = {
  and?: Maybe<Array<BookingAddressFilterInput>>;
  or?: Maybe<Array<BookingAddressFilterInput>>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  addressId?: Maybe<LongOperationFilterInput>;
  address?: Maybe<AddressFilterInput>;
  type?: Maybe<BookingAddressTypeOperationFilterInput>;
  at?: Maybe<DateTimeOperationFilterInput>;
  by?: Maybe<DateTimeOperationFilterInput>;
  sequenceOrder?: Maybe<IntOperationFilterInput>;
  /** Note for driver */
  note?: Maybe<StringOperationFilterInput>;
  noteFromDriver?: Maybe<StringOperationFilterInput>;
  waitingTime?: Maybe<IntOperationFilterInput>;
  waitingTimeCharge?: Maybe<DecimalOperationFilterInput>;
  arrivedAt?: Maybe<DateTimeOperationFilterInput>;
  signedAt?: Maybe<DateTimeOperationFilterInput>;
  /** Collection or Delivery Time */
  processTime?: Maybe<DateTimeOperationFilterInput>;
  signedByFirstName?: Maybe<StringOperationFilterInput>;
  signedByLastName?: Maybe<StringOperationFilterInput>;
  signedBy?: Maybe<StringOperationFilterInput>;
  /** Estimated Time of Arrival */
  eTA?: Maybe<DateTimeOperationFilterInput>;
  driverTimeFrom?: Maybe<StringOperationFilterInput>;
  driverTimeTo?: Maybe<StringOperationFilterInput>;
  courierServiceNumberOfItems?: Maybe<IntOperationFilterInput>;
  courierServiceWeight?: Maybe<DecimalOperationFilterInput>;
  courierServiceWeightUnit?: Maybe<StringOperationFilterInput>;
  courierServiceStopId?: Maybe<LongOperationFilterInput>;
  isDriverLate?: Maybe<BooleanOperationFilterInput>;
  allocations?: Maybe<ListFilterInputTypeOfPackageAllocationFilterInput>;
  addressPackages?: Maybe<ListFilterInputTypeOfBookingAddressPackageFilterInput>;
  exceptionId?: Maybe<LongOperationFilterInput>;
  exception?: Maybe<ExceptionReasonFilterInput>;
  driverWaitTimeId?: Maybe<LongOperationFilterInput>;
  driverWaitTime?: Maybe<DriverWaitTimeFilterInput>;
  reasonCodeId?: Maybe<LongOperationFilterInput>;
  reasonCode?: Maybe<ReasonCodeFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BookingAddressPackage = {
  __typename?: 'BookingAddressPackage';
  bookingAddressId: Scalars['Long'];
  bookingAddress: BookingAddress;
  packageId: Scalars['Long'];
  package: Package;
  quantity: Scalars['Int'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type BookingAddressPackageFilterInput = {
  and?: Maybe<Array<BookingAddressPackageFilterInput>>;
  or?: Maybe<Array<BookingAddressPackageFilterInput>>;
  bookingAddressId?: Maybe<LongOperationFilterInput>;
  bookingAddress?: Maybe<BookingAddressFilterInput>;
  packageId?: Maybe<LongOperationFilterInput>;
  package?: Maybe<PackageFilterInput>;
  quantity?: Maybe<IntOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum BookingAddressStatus {
  Awaiting = 'AWAITING',
  Active = 'ACTIVE',
  Submitted = 'SUBMITTED'
}

export enum BookingAddressType {
  Collection = 'COLLECTION',
  Delivery = 'DELIVERY'
}

export type BookingAddressTypeOperationFilterInput = {
  eq?: Maybe<BookingAddressType>;
  neq?: Maybe<BookingAddressType>;
  in?: Maybe<Array<BookingAddressType>>;
  nin?: Maybe<Array<BookingAddressType>>;
};

export type BookingAttachment = {
  __typename?: 'BookingAttachment';
  bookingId: Scalars['Long'];
  booking: Booking;
  fileId: Scalars['Long'];
  file: File;
  isAvailableForDriver: Scalars['Boolean'];
  isAvailableForCustomer: Scalars['Boolean'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type BookingAttachmentFilterInput = {
  and?: Maybe<Array<BookingAttachmentFilterInput>>;
  or?: Maybe<Array<BookingAttachmentFilterInput>>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  fileId?: Maybe<LongOperationFilterInput>;
  file?: Maybe<FileFilterInput>;
  isAvailableForDriver?: Maybe<BooleanOperationFilterInput>;
  isAvailableForCustomer?: Maybe<BooleanOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BookingAttachmentSortInput = {
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  fileId?: Maybe<SortEnumType>;
  file?: Maybe<FileSortInput>;
  isAvailableForDriver?: Maybe<SortEnumType>;
  isAvailableForCustomer?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BookingAttachmentsConnection = {
  __typename?: 'BookingAttachmentsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<BookingAttachmentsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<File>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BookingAttachmentsEdge = {
  __typename?: 'BookingAttachmentsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: File;
};

/** A connection to a list of items. */
export type BookingAuditConnection = {
  __typename?: 'BookingAuditConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<BookingAuditEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AuditRecord>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BookingAuditEdge = {
  __typename?: 'BookingAuditEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AuditRecord;
};

export type BookingCharge = {
  __typename?: 'BookingCharge';
  bookingId: Scalars['Long'];
  booking: Booking;
  type: BookingChargeType;
  quantity: Scalars['Int'];
  rate: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  xeroServiceCode?: Maybe<Scalars['String']>;
  vatCategoryId?: Maybe<Scalars['Long']>;
  vatCategory?: Maybe<VatCategory>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type BookingChargeFilterInput = {
  and?: Maybe<Array<BookingChargeFilterInput>>;
  or?: Maybe<Array<BookingChargeFilterInput>>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  type?: Maybe<BookingChargeTypeOperationFilterInput>;
  quantity?: Maybe<IntOperationFilterInput>;
  rate?: Maybe<DecimalOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  xeroServiceCode?: Maybe<StringOperationFilterInput>;
  vatCategoryId?: Maybe<LongOperationFilterInput>;
  vatCategory?: Maybe<VatCategoryFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum BookingChargeType {
  Custom = 'CUSTOM',
  Other = 'OTHER',
  Drop = 'DROP',
  SpecialZone = 'SPECIAL_ZONE',
  WaitingTime = 'WAITING_TIME',
  ProductReturn = 'PRODUCT_RETURN',
  Toll = 'TOLL',
  OutOfHours = 'OUT_OF_HOURS'
}

export type BookingChargeTypeOperationFilterInput = {
  eq?: Maybe<BookingChargeType>;
  neq?: Maybe<BookingChargeType>;
  in?: Maybe<Array<BookingChargeType>>;
  nin?: Maybe<Array<BookingChargeType>>;
};

export type BookingDriver = {
  __typename?: 'BookingDriver';
  bookingId: Scalars['Long'];
  booking: Booking;
  driverId: Scalars['Long'];
  driver: Driver;
  vehicleId?: Maybe<Scalars['Long']>;
  vehicle?: Maybe<Vehicle>;
  driverContactId?: Maybe<Scalars['Long']>;
  driverContact?: Maybe<DriverContact>;
  xeroPurchaseCode?: Maybe<Scalars['String']>;
  charge: Scalars['Decimal'];
  extraPayment: Scalars['Decimal'];
  isShownInstruction: Scalars['Boolean'];
  instruction?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['Long']>;
  invoice?: Maybe<BookingInvoice>;
  allocationStatus: DriverAllocationStatus;
  currencyId?: Maybe<Scalars['Long']>;
  currency?: Maybe<Currency>;
  reference: Scalars['String'];
  driverNotes?: Maybe<Scalars['String']>;
  bookingExchangeRates?: Maybe<Array<BookingExchangeRate>>;
  rating?: Maybe<Scalars['Int']>;
  ratingComment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  totalCharge: Scalars['Decimal'];
};

export type BookingDriverFilterInput = {
  and?: Maybe<Array<BookingDriverFilterInput>>;
  or?: Maybe<Array<BookingDriverFilterInput>>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  driverId?: Maybe<LongOperationFilterInput>;
  driver?: Maybe<DriverFilterInput>;
  vehicleId?: Maybe<LongOperationFilterInput>;
  vehicle?: Maybe<VehicleFilterInput>;
  driverContactId?: Maybe<LongOperationFilterInput>;
  driverContact?: Maybe<DriverContactFilterInput>;
  xeroPurchaseCode?: Maybe<StringOperationFilterInput>;
  charge?: Maybe<DecimalOperationFilterInput>;
  extraPayment?: Maybe<DecimalOperationFilterInput>;
  isShownInstruction?: Maybe<BooleanOperationFilterInput>;
  instruction?: Maybe<StringOperationFilterInput>;
  invoiceId?: Maybe<LongOperationFilterInput>;
  invoice?: Maybe<BookingInvoiceFilterInput>;
  allocationStatus?: Maybe<DriverAllocationStatusOperationFilterInput>;
  currencyId?: Maybe<LongOperationFilterInput>;
  currency?: Maybe<CurrencyFilterInput>;
  reference?: Maybe<StringOperationFilterInput>;
  driverNotes?: Maybe<StringOperationFilterInput>;
  bookingExchangeRates?: Maybe<ListFilterInputTypeOfBookingExchangeRateFilterInput>;
  rating?: Maybe<IntOperationFilterInput>;
  ratingComment?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BookingDriverRatingDtoInput = {
  bookingId: Scalars['Long'];
  driverId: Scalars['Long'];
  rating: Scalars['Int'];
  ratingComment?: Maybe<Scalars['String']>;
};

export type BookingDriverSortInput = {
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  driverId?: Maybe<SortEnumType>;
  driver?: Maybe<DriverSortInput>;
  vehicleId?: Maybe<SortEnumType>;
  vehicle?: Maybe<VehicleSortInput>;
  driverContactId?: Maybe<SortEnumType>;
  driverContact?: Maybe<DriverContactSortInput>;
  xeroPurchaseCode?: Maybe<SortEnumType>;
  charge?: Maybe<SortEnumType>;
  extraPayment?: Maybe<SortEnumType>;
  isShownInstruction?: Maybe<SortEnumType>;
  instruction?: Maybe<SortEnumType>;
  invoiceId?: Maybe<SortEnumType>;
  invoice?: Maybe<BookingInvoiceSortInput>;
  allocationStatus?: Maybe<SortEnumType>;
  currencyId?: Maybe<SortEnumType>;
  currency?: Maybe<CurrencySortInput>;
  reference?: Maybe<SortEnumType>;
  driverNotes?: Maybe<SortEnumType>;
  rating?: Maybe<SortEnumType>;
  ratingComment?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type BookingExchangeRate = {
  __typename?: 'BookingExchangeRate';
  stage: BookingExchangeRateStage;
  bookingId: Scalars['Long'];
  booking: Booking;
  exchangeRateId: Scalars['Long'];
  exchangeRate: ExchangeRate;
  bookingDriverId?: Maybe<Scalars['Long']>;
  bookingDriver?: Maybe<BookingDriver>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type BookingExchangeRateFilterInput = {
  and?: Maybe<Array<BookingExchangeRateFilterInput>>;
  or?: Maybe<Array<BookingExchangeRateFilterInput>>;
  stage?: Maybe<BookingExchangeRateStageOperationFilterInput>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  exchangeRateId?: Maybe<LongOperationFilterInput>;
  exchangeRate?: Maybe<ExchangeRateFilterInput>;
  bookingDriverId?: Maybe<LongOperationFilterInput>;
  bookingDriver?: Maybe<BookingDriverFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum BookingExchangeRateStage {
  Allocation = 'ALLOCATION',
  ProofOfDelivery = 'PROOF_OF_DELIVERY',
  Payment = 'PAYMENT'
}

export type BookingExchangeRateStageOperationFilterInput = {
  eq?: Maybe<BookingExchangeRateStage>;
  neq?: Maybe<BookingExchangeRateStage>;
  in?: Maybe<Array<BookingExchangeRateStage>>;
  nin?: Maybe<Array<BookingExchangeRateStage>>;
};

export type BookingFilterInput = {
  and?: Maybe<Array<BookingFilterInput>>;
  or?: Maybe<Array<BookingFilterInput>>;
  status?: Maybe<BookingStatusOperationFilterInput>;
  isPending?: Maybe<BooleanOperationFilterInput>;
  isPreBooked?: Maybe<BooleanOperationFilterInput>;
  isOnHold?: Maybe<BooleanOperationFilterInput>;
  isCancelledOrQuoteLost?: Maybe<BooleanOperationFilterInput>;
  isQuickQuote?: Maybe<BooleanOperationFilterInput>;
  isCancelled?: Maybe<BooleanOperationFilterInput>;
  isArchived?: Maybe<BooleanOperationFilterInput>;
  isQuoteOriginally?: Maybe<BooleanOperationFilterInput>;
  isCreatedByCustomer?: Maybe<BooleanOperationFilterInput>;
  customerId?: Maybe<LongOperationFilterInput>;
  customer?: Maybe<CustomerFilterInput>;
  contactId?: Maybe<LongOperationFilterInput>;
  contact?: Maybe<ContactFilterInput>;
  overrideContactEmail?: Maybe<StringOperationFilterInput>;
  overrideContactTelephoneNumber?: Maybe<StringOperationFilterInput>;
  customerReference?: Maybe<StringOperationFilterInput>;
  docketReference?: Maybe<StringOperationFilterInput>;
  takenBy?: Maybe<StringOperationFilterInput>;
  takenOn?: Maybe<DateTimeOperationFilterInput>;
  alternativeOurReference?: Maybe<StringOperationFilterInput>;
  validUntil?: Maybe<DateTimeOperationFilterInput>;
  addresses?: Maybe<ListFilterInputTypeOfBookingAddressFilterInput>;
  collectionEstimatedTimeOfArrival?: Maybe<DateTimeOperationFilterInput>;
  deliveryEstimatedTimeOfArrival?: Maybe<DateTimeOperationFilterInput>;
  tariffId?: Maybe<LongOperationFilterInput>;
  tariff?: Maybe<TariffFilterInput>;
  distance?: Maybe<DecimalOperationFilterInput>;
  isCalculateDistanceAutomatically?: Maybe<BooleanOperationFilterInput>;
  isWaitAndReturn?: Maybe<BooleanOperationFilterInput>;
  noteOrSpecialInstruction?: Maybe<StringOperationFilterInput>;
  quoteLostReasonId?: Maybe<LongOperationFilterInput>;
  quoteLostReason?: Maybe<QuoteLostReasonFilterInput>;
  cancellationReasonOrQuoteLostDetail?: Maybe<StringOperationFilterInput>;
  potentialBookingId?: Maybe<LongOperationFilterInput>;
  potentialBooking?: Maybe<PotentialBookingFilterInput>;
  template?: Maybe<ScheduledBookingTemplateFilterInput>;
  createdByScheduledTask?: Maybe<ScheduledBookingTaskFilterInput>;
  packages?: Maybe<ListFilterInputTypeOfPackageFilterInput>;
  drivers?: Maybe<ListFilterInputTypeOfBookingDriverFilterInput>;
  driverBids?: Maybe<ListFilterInputTypeOfDriverBidFilterInput>;
  charges?: Maybe<ListFilterInputTypeOfBookingChargeFilterInput>;
  courierExchangeLoadId?: Maybe<StringOperationFilterInput>;
  courierExchangeLoad?: Maybe<CourierExchangeLoadFilterInput>;
  courierExchangeLastManualUpdateAt?: Maybe<DateTimeOperationFilterInput>;
  consignmentFee?: Maybe<DecimalOperationFilterInput>;
  isEmailNotification?: Maybe<BooleanOperationFilterInput>;
  isImported?: Maybe<BooleanOperationFilterInput>;
  isTransitIgnored?: Maybe<BooleanOperationFilterInput>;
  isBiddingEnabled?: Maybe<BooleanOperationFilterInput>;
  trackingGuid?: Maybe<UuidOperationFilterInput>;
  trackingExpiredDate?: Maybe<DateTimeOperationFilterInput>;
  invoices?: Maybe<ListFilterInputTypeOfBookingInvoiceFilterInput>;
  attachments?: Maybe<ListFilterInputTypeOfBookingAttachmentFilterInput>;
  tasks?: Maybe<ListFilterInputTypeOfTaskFilterInput>;
  activities?: Maybe<ListFilterInputTypeOfActivityFilterInput>;
  exchangeRates?: Maybe<ListFilterInputTypeOfBookingExchangeRateFilterInput>;
  logs?: Maybe<ListFilterInputTypeOfBookingLogFilterInput>;
  notifications?: Maybe<ListFilterInputTypeOfMobileNotificationFilterInput>;
  docs?: Maybe<IntOperationFilterInput>;
  hasNotes?: Maybe<BooleanOperationFilterInput>;
  isUsingInternalApp?: Maybe<BooleanOperationFilterInput>;
  salesInvoiceNumber?: Maybe<LongOperationFilterInput>;
  ourReference?: Maybe<StringOperationFilterInput>;
  revenue?: Maybe<DecimalOperationFilterInput>;
  cost?: Maybe<DecimalOperationFilterInput>;
  marginPercent?: Maybe<DecimalOperationFilterInput>;
  isDeletable?: Maybe<BooleanOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BookingInvoice = {
  __typename?: 'BookingInvoice';
  bookingId: Scalars['Long'];
  booking: Booking;
  invoiceType: InvoiceType;
  customerId?: Maybe<Scalars['Long']>;
  customer?: Maybe<Customer>;
  bookingDriverId?: Maybe<Scalars['Long']>;
  bookingDriver?: Maybe<BookingDriver>;
  accountPayableRecord?: Maybe<AccountPayableRecord>;
  contents: Array<BookingInvoiceContent>;
  contactName: Scalars['String'];
  emailAddress?: Maybe<Scalars['String']>;
  poAddressLine1?: Maybe<Scalars['String']>;
  poAddressLine2?: Maybe<Scalars['String']>;
  poCity?: Maybe<Scalars['String']>;
  poPostalCode?: Maybe<Scalars['String']>;
  poCountry?: Maybe<Scalars['String']>;
  invoiceNumber: Scalars['Long'];
  driverInvoiceNumber?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  invoiceDate: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  paymentDate: Scalars['DateTime'];
  payment?: Maybe<Payment>;
  bookingInvoiceBatchId?: Maybe<Scalars['Long']>;
  bookingInvoiceBatch?: Maybe<BookingInvoiceBatch>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type BookingInvoiceBatch = {
  __typename?: 'BookingInvoiceBatch';
  invoiceType: InvoiceType;
  invoiceNumber?: Maybe<Scalars['Long']>;
  date?: Maybe<Scalars['DateTime']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  currencyId: Scalars['Long'];
  currency: Currency;
  isSentToXero: Scalars['Boolean'];
  isSuccess: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
  batchLines: Array<BookingInvoiceBatchLine>;
  bookingInvoices: Array<BookingInvoice>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type BookingInvoiceBatchFilterInput = {
  and?: Maybe<Array<BookingInvoiceBatchFilterInput>>;
  or?: Maybe<Array<BookingInvoiceBatchFilterInput>>;
  invoiceType?: Maybe<InvoiceTypeOperationFilterInput>;
  invoiceNumber?: Maybe<LongOperationFilterInput>;
  date?: Maybe<DateTimeOperationFilterInput>;
  paymentDate?: Maybe<DateTimeOperationFilterInput>;
  currencyId?: Maybe<LongOperationFilterInput>;
  currency?: Maybe<CurrencyFilterInput>;
  isSentToXero?: Maybe<BooleanOperationFilterInput>;
  isSuccess?: Maybe<BooleanOperationFilterInput>;
  errorMessage?: Maybe<StringOperationFilterInput>;
  batchLines?: Maybe<ListFilterInputTypeOfBookingInvoiceBatchLineFilterInput>;
  bookingInvoices?: Maybe<ListFilterInputTypeOfBookingInvoiceFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BookingInvoiceBatchLine = {
  __typename?: 'BookingInvoiceBatchLine';
  batchId: Scalars['Long'];
  accountCode?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
  bookingInvoiceBatch: BookingInvoiceBatch;
  accountPayableRecords: Array<AccountPayableRecord>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  total: Scalars['String'];
};

export type BookingInvoiceBatchLineFilterInput = {
  and?: Maybe<Array<BookingInvoiceBatchLineFilterInput>>;
  or?: Maybe<Array<BookingInvoiceBatchLineFilterInput>>;
  batchId?: Maybe<LongOperationFilterInput>;
  accountCode?: Maybe<StringOperationFilterInput>;
  taxType?: Maybe<StringOperationFilterInput>;
  bookingInvoiceBatch?: Maybe<BookingInvoiceBatchFilterInput>;
  accountPayableRecords?: Maybe<ListFilterInputTypeOfAccountPayableRecordFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BookingInvoiceBatchLineSortInput = {
  batchId?: Maybe<SortEnumType>;
  accountCode?: Maybe<SortEnumType>;
  taxType?: Maybe<SortEnumType>;
  bookingInvoiceBatch?: Maybe<BookingInvoiceBatchSortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BookingInvoiceBatchLinesConnection = {
  __typename?: 'BookingInvoiceBatchLinesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<BookingInvoiceBatchLinesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingInvoiceBatchLine>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BookingInvoiceBatchLinesEdge = {
  __typename?: 'BookingInvoiceBatchLinesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingInvoiceBatchLine;
};

export type BookingInvoiceBatchSortInput = {
  invoiceType?: Maybe<SortEnumType>;
  invoiceNumber?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  paymentDate?: Maybe<SortEnumType>;
  currencyId?: Maybe<SortEnumType>;
  currency?: Maybe<CurrencySortInput>;
  isSentToXero?: Maybe<SortEnumType>;
  isSuccess?: Maybe<SortEnumType>;
  errorMessage?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BookingInvoiceBatchesConnection = {
  __typename?: 'BookingInvoiceBatchesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<BookingInvoiceBatchesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingInvoiceBatch>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BookingInvoiceBatchesEdge = {
  __typename?: 'BookingInvoiceBatchesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingInvoiceBatch;
};

/** A connection to a list of items. */
export type BookingInvoiceConnection = {
  __typename?: 'BookingInvoiceConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<BookingInvoiceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingInvoice>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type BookingInvoiceContent = {
  __typename?: 'BookingInvoiceContent';
  bookingInvoiceId: Scalars['Long'];
  bookingInvoice: BookingInvoice;
  inventoryItemCode?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  quantity: Scalars['Int'];
  unitAmount: Scalars['Decimal'];
  discount: Scalars['Decimal'];
  total: Scalars['Decimal'];
  accountCode: Scalars['String'];
  taxType: Scalars['String'];
  taxAmount: Scalars['Decimal'];
  trackingName1?: Maybe<Scalars['String']>;
  trackingOption1?: Maybe<Scalars['String']>;
  currencyId?: Maybe<Scalars['Long']>;
  currency?: Maybe<Currency>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type BookingInvoiceContentFilterInput = {
  and?: Maybe<Array<BookingInvoiceContentFilterInput>>;
  or?: Maybe<Array<BookingInvoiceContentFilterInput>>;
  bookingInvoiceId?: Maybe<LongOperationFilterInput>;
  bookingInvoice?: Maybe<BookingInvoiceFilterInput>;
  inventoryItemCode?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  quantity?: Maybe<IntOperationFilterInput>;
  unitAmount?: Maybe<DecimalOperationFilterInput>;
  discount?: Maybe<DecimalOperationFilterInput>;
  total?: Maybe<DecimalOperationFilterInput>;
  accountCode?: Maybe<StringOperationFilterInput>;
  taxType?: Maybe<StringOperationFilterInput>;
  taxAmount?: Maybe<DecimalOperationFilterInput>;
  trackingName1?: Maybe<StringOperationFilterInput>;
  trackingOption1?: Maybe<StringOperationFilterInput>;
  currencyId?: Maybe<LongOperationFilterInput>;
  currency?: Maybe<CurrencyFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BookingInvoiceContentSortInput = {
  bookingInvoiceId?: Maybe<SortEnumType>;
  bookingInvoice?: Maybe<BookingInvoiceSortInput>;
  inventoryItemCode?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  quantity?: Maybe<SortEnumType>;
  unitAmount?: Maybe<SortEnumType>;
  discount?: Maybe<SortEnumType>;
  total?: Maybe<SortEnumType>;
  accountCode?: Maybe<SortEnumType>;
  taxType?: Maybe<SortEnumType>;
  taxAmount?: Maybe<SortEnumType>;
  trackingName1?: Maybe<SortEnumType>;
  trackingOption1?: Maybe<SortEnumType>;
  currencyId?: Maybe<SortEnumType>;
  currency?: Maybe<CurrencySortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** An edge in a connection. */
export type BookingInvoiceEdge = {
  __typename?: 'BookingInvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingInvoice;
};

export type BookingInvoiceFilterInput = {
  and?: Maybe<Array<BookingInvoiceFilterInput>>;
  or?: Maybe<Array<BookingInvoiceFilterInput>>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  invoiceType?: Maybe<InvoiceTypeOperationFilterInput>;
  customerId?: Maybe<LongOperationFilterInput>;
  customer?: Maybe<CustomerFilterInput>;
  bookingDriverId?: Maybe<LongOperationFilterInput>;
  bookingDriver?: Maybe<BookingDriverFilterInput>;
  accountPayableRecord?: Maybe<AccountPayableRecordFilterInput>;
  contents?: Maybe<ListFilterInputTypeOfBookingInvoiceContentFilterInput>;
  contactName?: Maybe<StringOperationFilterInput>;
  emailAddress?: Maybe<StringOperationFilterInput>;
  poAddressLine1?: Maybe<StringOperationFilterInput>;
  poAddressLine2?: Maybe<StringOperationFilterInput>;
  poCity?: Maybe<StringOperationFilterInput>;
  poPostalCode?: Maybe<StringOperationFilterInput>;
  poCountry?: Maybe<StringOperationFilterInput>;
  invoiceNumber?: Maybe<LongOperationFilterInput>;
  driverInvoiceNumber?: Maybe<StringOperationFilterInput>;
  reference?: Maybe<StringOperationFilterInput>;
  invoiceDate?: Maybe<DateTimeOperationFilterInput>;
  dueDate?: Maybe<DateTimeOperationFilterInput>;
  paymentDate?: Maybe<DateTimeOperationFilterInput>;
  payment?: Maybe<PaymentFilterInput>;
  bookingInvoiceBatchId?: Maybe<LongOperationFilterInput>;
  bookingInvoiceBatch?: Maybe<BookingInvoiceBatchFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BookingInvoiceSortInput = {
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  invoiceType?: Maybe<SortEnumType>;
  customerId?: Maybe<SortEnumType>;
  customer?: Maybe<CustomerSortInput>;
  bookingDriverId?: Maybe<SortEnumType>;
  bookingDriver?: Maybe<BookingDriverSortInput>;
  accountPayableRecord?: Maybe<AccountPayableRecordSortInput>;
  contactName?: Maybe<SortEnumType>;
  emailAddress?: Maybe<SortEnumType>;
  poAddressLine1?: Maybe<SortEnumType>;
  poAddressLine2?: Maybe<SortEnumType>;
  poCity?: Maybe<SortEnumType>;
  poPostalCode?: Maybe<SortEnumType>;
  poCountry?: Maybe<SortEnumType>;
  invoiceNumber?: Maybe<SortEnumType>;
  driverInvoiceNumber?: Maybe<SortEnumType>;
  reference?: Maybe<SortEnumType>;
  invoiceDate?: Maybe<SortEnumType>;
  dueDate?: Maybe<SortEnumType>;
  paymentDate?: Maybe<SortEnumType>;
  payment?: Maybe<PaymentSortInput>;
  bookingInvoiceBatchId?: Maybe<SortEnumType>;
  bookingInvoiceBatch?: Maybe<BookingInvoiceBatchSortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type BookingLog = {
  __typename?: 'BookingLog';
  bookingId: Scalars['Long'];
  booking: Booking;
  type: BookingLogType;
  description: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type BookingLogFilterInput = {
  and?: Maybe<Array<BookingLogFilterInput>>;
  or?: Maybe<Array<BookingLogFilterInput>>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  type?: Maybe<BookingLogTypeOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  details?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type BookingLogSortInput = {
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  type?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  details?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum BookingLogType {
  Debug = 'DEBUG',
  Information = 'INFORMATION',
  Warning = 'WARNING',
  Error = 'ERROR',
  Fatal = 'FATAL'
}

export type BookingLogTypeOperationFilterInput = {
  eq?: Maybe<BookingLogType>;
  neq?: Maybe<BookingLogType>;
  in?: Maybe<Array<BookingLogType>>;
  nin?: Maybe<Array<BookingLogType>>;
};

/** A connection to a list of items. */
export type BookingLogsConnection = {
  __typename?: 'BookingLogsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<BookingLogsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingLog>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BookingLogsEdge = {
  __typename?: 'BookingLogsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingLog;
};

export type BookingSortInput = {
  status?: Maybe<SortEnumType>;
  isPending?: Maybe<SortEnumType>;
  isPreBooked?: Maybe<SortEnumType>;
  isOnHold?: Maybe<SortEnumType>;
  isCancelledOrQuoteLost?: Maybe<SortEnumType>;
  isQuickQuote?: Maybe<SortEnumType>;
  isCancelled?: Maybe<SortEnumType>;
  isArchived?: Maybe<SortEnumType>;
  isQuoteOriginally?: Maybe<SortEnumType>;
  isCreatedByCustomer?: Maybe<SortEnumType>;
  customerId?: Maybe<SortEnumType>;
  customer?: Maybe<CustomerSortInput>;
  contactId?: Maybe<SortEnumType>;
  contact?: Maybe<ContactSortInput>;
  overrideContactEmail?: Maybe<SortEnumType>;
  overrideContactTelephoneNumber?: Maybe<SortEnumType>;
  customerReference?: Maybe<SortEnumType>;
  docketReference?: Maybe<SortEnumType>;
  takenBy?: Maybe<SortEnumType>;
  takenOn?: Maybe<SortEnumType>;
  alternativeOurReference?: Maybe<SortEnumType>;
  validUntil?: Maybe<SortEnumType>;
  collectionEstimatedTimeOfArrival?: Maybe<SortEnumType>;
  deliveryEstimatedTimeOfArrival?: Maybe<SortEnumType>;
  tariffId?: Maybe<SortEnumType>;
  tariff?: Maybe<TariffSortInput>;
  distance?: Maybe<SortEnumType>;
  isCalculateDistanceAutomatically?: Maybe<SortEnumType>;
  isWaitAndReturn?: Maybe<SortEnumType>;
  noteOrSpecialInstruction?: Maybe<SortEnumType>;
  quoteLostReasonId?: Maybe<SortEnumType>;
  quoteLostReason?: Maybe<QuoteLostReasonSortInput>;
  cancellationReasonOrQuoteLostDetail?: Maybe<SortEnumType>;
  potentialBookingId?: Maybe<SortEnumType>;
  potentialBooking?: Maybe<PotentialBookingSortInput>;
  template?: Maybe<ScheduledBookingTemplateSortInput>;
  createdByScheduledTask?: Maybe<ScheduledBookingTaskSortInput>;
  courierExchangeLoadId?: Maybe<SortEnumType>;
  courierExchangeLoad?: Maybe<CourierExchangeLoadSortInput>;
  courierExchangeLastManualUpdateAt?: Maybe<SortEnumType>;
  consignmentFee?: Maybe<SortEnumType>;
  isEmailNotification?: Maybe<SortEnumType>;
  isImported?: Maybe<SortEnumType>;
  isTransitIgnored?: Maybe<SortEnumType>;
  isBiddingEnabled?: Maybe<SortEnumType>;
  trackingGuid?: Maybe<SortEnumType>;
  trackingExpiredDate?: Maybe<SortEnumType>;
  docs?: Maybe<SortEnumType>;
  hasNotes?: Maybe<SortEnumType>;
  isUsingInternalApp?: Maybe<SortEnumType>;
  salesInvoiceNumber?: Maybe<SortEnumType>;
  ourReference?: Maybe<SortEnumType>;
  revenue?: Maybe<SortEnumType>;
  cost?: Maybe<SortEnumType>;
  marginPercent?: Maybe<SortEnumType>;
  isDeletable?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum BookingStatus {
  Quotation = 'QUOTATION',
  EBooking = 'E_BOOKING',
  Waiting = 'WAITING',
  Allocated = 'ALLOCATED',
  PackageOnBoardOnWay = 'PACKAGE_ON_BOARD_ON_WAY',
  PackageOnBoardArrival = 'PACKAGE_ON_BOARD_ARRIVAL',
  PackageOnBoardPartial = 'PACKAGE_ON_BOARD_PARTIAL',
  PackageOnBoard = 'PACKAGE_ON_BOARD',
  ProofOfDeliveryOnWay = 'PROOF_OF_DELIVERY_ON_WAY',
  ProofOfDeliveryArrival = 'PROOF_OF_DELIVERY_ARRIVAL',
  ProofOfDeliveryPartial = 'PROOF_OF_DELIVERY_PARTIAL',
  ProofOfDelivery = 'PROOF_OF_DELIVERY',
  Complete = 'COMPLETE',
  Invoice = 'INVOICE',
  Declined = 'DECLINED',
  ScheduledTemplate = 'SCHEDULED_TEMPLATE'
}

export type BookingStatusOperationFilterInput = {
  eq?: Maybe<BookingStatus>;
  neq?: Maybe<BookingStatus>;
  in?: Maybe<Array<BookingStatus>>;
  nin?: Maybe<Array<BookingStatus>>;
};

/** A connection to a list of items. */
export type BookingTrackingDashboardDataConnection = {
  __typename?: 'BookingTrackingDashboardDataConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<BookingTrackingDashboardDataEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingTrackingDashboardDto>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BookingTrackingDashboardDataEdge = {
  __typename?: 'BookingTrackingDashboardDataEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingTrackingDashboardDto;
};

export type BookingTrackingDashboardDto = {
  __typename?: 'BookingTrackingDashboardDto';
  id: Scalars['Long'];
  ourReference: Scalars['String'];
  status: BookingStatus;
  statusLabel?: Maybe<Scalars['String']>;
  customerName: Scalars['String'];
  customerReference?: Maybe<Scalars['String']>;
  driverStatus: DriverStatusDto;
};

export type BookingTrackingDashboardDtoFilterInput = {
  and?: Maybe<Array<BookingTrackingDashboardDtoFilterInput>>;
  or?: Maybe<Array<BookingTrackingDashboardDtoFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  ourReference?: Maybe<StringOperationFilterInput>;
  status?: Maybe<BookingStatusOperationFilterInput>;
  statusLabel?: Maybe<StringOperationFilterInput>;
  customerName?: Maybe<StringOperationFilterInput>;
  customerReference?: Maybe<StringOperationFilterInput>;
  driverStatus?: Maybe<DriverStatusDtoFilterInput>;
};

export type BookingTrackingDashboardDtoSortInput = {
  id?: Maybe<SortEnumType>;
  ourReference?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  statusLabel?: Maybe<SortEnumType>;
  customerName?: Maybe<SortEnumType>;
  customerReference?: Maybe<SortEnumType>;
  driverStatus?: Maybe<DriverStatusDtoSortInput>;
};

export type BookingTrackingDto = {
  __typename?: 'BookingTrackingDto';
  id: Scalars['ID'];
  distance: Scalars['Decimal'];
  consignmentFee: Scalars['Decimal'];
  trackingGuid?: Maybe<Scalars['UUID']>;
  trackingExpiredDate?: Maybe<Scalars['DateTime']>;
  tariff?: Maybe<TariffPagedDto>;
  addresses: Array<AddressBookingDto>;
};

export type BookingUpdatedDto = {
  __typename?: 'BookingUpdatedDto';
  id: Scalars['Long'];
  updatedBy: Scalars['String'];
};

export type BookingUserDto = {
  __typename?: 'BookingUserDto';
  id: Scalars['Long'];
  userName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  emailConfirmed: Scalars['Boolean'];
};

export type BookingUserDtoFilterInput = {
  and?: Maybe<Array<BookingUserDtoFilterInput>>;
  or?: Maybe<Array<BookingUserDtoFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  userName?: Maybe<StringOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
  emailConfirmed?: Maybe<BooleanOperationFilterInput>;
};

export type BookingUserDtoSortInput = {
  id?: Maybe<SortEnumType>;
  userName?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
  emailConfirmed?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BookingsAvailableForBiddingConnection = {
  __typename?: 'BookingsAvailableForBiddingConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<BookingsAvailableForBiddingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Booking>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BookingsAvailableForBiddingEdge = {
  __typename?: 'BookingsAvailableForBiddingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Booking;
};

/** A connection to a list of items. */
export type BookingsConnection = {
  __typename?: 'BookingsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<BookingsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Booking>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BookingsEdge = {
  __typename?: 'BookingsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Booking;
};

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};

export enum CalculatedStatus {
  Quotation = 'QUOTATION',
  EBooking = 'E_BOOKING',
  Waiting = 'WAITING',
  Allocated = 'ALLOCATED',
  PackageOnBoardOnWay = 'PACKAGE_ON_BOARD_ON_WAY',
  PackageOnBoardPartial = 'PACKAGE_ON_BOARD_PARTIAL',
  PackageOnBoardArrival = 'PACKAGE_ON_BOARD_ARRIVAL',
  PackageOnBoard = 'PACKAGE_ON_BOARD',
  ProofOfDeliveryOnWay = 'PROOF_OF_DELIVERY_ON_WAY',
  ProofOfDeliveryPartial = 'PROOF_OF_DELIVERY_PARTIAL',
  ProofOfDeliveryArrival = 'PROOF_OF_DELIVERY_ARRIVAL',
  ProofOfDelivery = 'PROOF_OF_DELIVERY',
  Complete = 'COMPLETE',
  Invoice = 'INVOICE',
  Declined = 'DECLINED',
  ScheduledTemplate = 'SCHEDULED_TEMPLATE',
  OnHold = 'ON_HOLD',
  IsPreBooked = 'IS_PRE_BOOKED',
  IsCancelledOrQuoteLost = 'IS_CANCELLED_OR_QUOTE_LOST',
  IsArchived = 'IS_ARCHIVED',
  IsPending = 'IS_PENDING',
  IsQuickQuote = 'IS_QUICK_QUOTE'
}

export type CancelBookingDtoInput = {
  id: Scalars['ID'];
  isCancelled: Scalars['Boolean'];
  cancellationReason: Scalars['String'];
};

export type Commentary = {
  __typename?: 'Commentary';
  message: Scalars['String'];
  taskId: Scalars['Long'];
  task: Task;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CommentaryFilterInput = {
  and?: Maybe<Array<CommentaryFilterInput>>;
  or?: Maybe<Array<CommentaryFilterInput>>;
  message?: Maybe<StringOperationFilterInput>;
  taskId?: Maybe<LongOperationFilterInput>;
  task?: Maybe<TaskFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CommentarySortInput = {
  message?: Maybe<SortEnumType>;
  taskId?: Maybe<SortEnumType>;
  task?: Maybe<TaskSortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type CompanyBriefDto = {
  __typename?: 'CompanyBriefDto';
  companyId?: Maybe<Scalars['Long']>;
  cxCompanyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ConfirmQuickQuoteDtoInput = {
  id: Scalars['ID'];
};

export type CongestionZone = {
  __typename?: 'CongestionZone';
  weekdaysCollection: Array<Weekday>;
  durationFromTotalSeconds: Scalars['Float'];
  durationToTotalSeconds: Scalars['Float'];
  customerCharge: Scalars['Decimal'];
  weekdays: Weekday;
  durationFrom: Scalars['TimeSpan'];
  durationTo: Scalars['TimeSpan'];
  tariffs: Array<CongestionZoneTariff>;
  isAttachedToAllTariffs: Scalars['Boolean'];
  isAppliedAutomatically: Scalars['Boolean'];
  xeroServiceCode?: Maybe<Scalars['String']>;
  postcodes: Array<CongestionZonePostcode>;
  timeZoneId: Scalars['Long'];
  timeZone: TimeZone;
  is24Hours: Scalars['Boolean'];
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CongestionZoneFilterInput = {
  and?: Maybe<Array<CongestionZoneFilterInput>>;
  or?: Maybe<Array<CongestionZoneFilterInput>>;
  customerCharge?: Maybe<DecimalOperationFilterInput>;
  weekdays?: Maybe<WeekdayOperationFilterInput>;
  durationFrom?: Maybe<TimeSpanOperationFilterInput>;
  durationTo?: Maybe<TimeSpanOperationFilterInput>;
  tariffs?: Maybe<ListFilterInputTypeOfCongestionZoneTariffFilterInput>;
  isAttachedToAllTariffs?: Maybe<BooleanOperationFilterInput>;
  isAppliedAutomatically?: Maybe<BooleanOperationFilterInput>;
  xeroServiceCode?: Maybe<StringOperationFilterInput>;
  postcodes?: Maybe<ListFilterInputTypeOfCongestionZonePostcodeFilterInput>;
  timeZoneId?: Maybe<LongOperationFilterInput>;
  timeZone?: Maybe<TimeZoneFilterInput>;
  is24Hours?: Maybe<BooleanOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CongestionZonePostcode = {
  __typename?: 'CongestionZonePostcode';
  congestionZoneId: Scalars['Long'];
  congestionZone: CongestionZone;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CongestionZonePostcodeFilterInput = {
  and?: Maybe<Array<CongestionZonePostcodeFilterInput>>;
  or?: Maybe<Array<CongestionZonePostcodeFilterInput>>;
  congestionZoneId?: Maybe<LongOperationFilterInput>;
  congestionZone?: Maybe<CongestionZoneFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CongestionZonePostcodeSortInput = {
  congestionZoneId?: Maybe<SortEnumType>;
  congestionZone?: Maybe<CongestionZoneSortInput>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type CongestionZonePostcodesConnection = {
  __typename?: 'CongestionZonePostcodesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CongestionZonePostcodesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CongestionZonePostcode>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CongestionZonePostcodesEdge = {
  __typename?: 'CongestionZonePostcodesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CongestionZonePostcode;
};

export type CongestionZoneSortInput = {
  customerCharge?: Maybe<SortEnumType>;
  weekdays?: Maybe<SortEnumType>;
  durationFrom?: Maybe<SortEnumType>;
  durationTo?: Maybe<SortEnumType>;
  isAttachedToAllTariffs?: Maybe<SortEnumType>;
  isAppliedAutomatically?: Maybe<SortEnumType>;
  xeroServiceCode?: Maybe<SortEnumType>;
  timeZoneId?: Maybe<SortEnumType>;
  timeZone?: Maybe<TimeZoneSortInput>;
  is24Hours?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type CongestionZoneTariff = {
  __typename?: 'CongestionZoneTariff';
  congestionZoneId: Scalars['Long'];
  congestionZone: CongestionZone;
  tariffId: Scalars['Long'];
  tariff: Tariff;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CongestionZoneTariffFilterInput = {
  and?: Maybe<Array<CongestionZoneTariffFilterInput>>;
  or?: Maybe<Array<CongestionZoneTariffFilterInput>>;
  congestionZoneId?: Maybe<LongOperationFilterInput>;
  congestionZone?: Maybe<CongestionZoneFilterInput>;
  tariffId?: Maybe<LongOperationFilterInput>;
  tariff?: Maybe<TariffFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type Contact = {
  __typename?: 'Contact';
  customerId: Scalars['Long'];
  customer: Customer;
  identityUserName?: Maybe<Scalars['String']>;
  bookings: Array<Booking>;
  isActive: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  telephoneNumber: Scalars['String'];
  email: Scalars['String'];
  additionalEmails: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobRole?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ContactFilterInput = {
  and?: Maybe<Array<ContactFilterInput>>;
  or?: Maybe<Array<ContactFilterInput>>;
  customerId?: Maybe<LongOperationFilterInput>;
  customer?: Maybe<CustomerFilterInput>;
  identityUserName?: Maybe<StringOperationFilterInput>;
  bookings?: Maybe<ListFilterInputTypeOfBookingFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  telephoneNumber?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  additionalEmails?: Maybe<StringOperationFilterInput>;
  department?: Maybe<StringOperationFilterInput>;
  jobRole?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ContactSortInput = {
  customerId?: Maybe<SortEnumType>;
  customer?: Maybe<CustomerSortInput>;
  identityUserName?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  isDefault?: Maybe<SortEnumType>;
  telephoneNumber?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  additionalEmails?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  jobRole?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type CoordsDtoInput = {
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  altitude?: Maybe<Scalars['String']>;
  accuracy?: Maybe<Scalars['String']>;
  altitudeAccuracy?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  formalName: Scalars['String'];
  iso3166Alpha2: Scalars['String'];
  iso3166Alpha3: Scalars['String'];
  iso3166Numeric: Scalars['Int'];
  addresses: Array<Address>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CountryDto = {
  __typename?: 'CountryDto';
  id: Scalars['Long'];
  name: Scalars['String'];
};

export type CountryFilterInput = {
  and?: Maybe<Array<CountryFilterInput>>;
  or?: Maybe<Array<CountryFilterInput>>;
  formalName?: Maybe<StringOperationFilterInput>;
  iso3166Alpha2?: Maybe<StringOperationFilterInput>;
  iso3166Alpha3?: Maybe<StringOperationFilterInput>;
  iso3166Numeric?: Maybe<IntOperationFilterInput>;
  addresses?: Maybe<ListFilterInputTypeOfAddressFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CountrySortInput = {
  formalName?: Maybe<SortEnumType>;
  iso3166Alpha2?: Maybe<SortEnumType>;
  iso3166Alpha3?: Maybe<SortEnumType>;
  iso3166Numeric?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type CourierExchangeBookLoadDtoInput = {
  loadId: Scalars['Long'];
  /** This is the Id from our DB (CourierExchangeQuote.Id) and NOT the one from CX (CourierExchangeQuote.QuoteId). */
  courierExchangeQuoteId?: Maybe<Scalars['Long']>;
  agreedRate: Scalars['Float'];
  paymentTerm: FixedOrderDtoPaymentTerm;
  subcontractorId: Scalars['Long'];
};

export type CourierExchangeLoad = {
  __typename?: 'CourierExchangeLoad';
  bookingId: Scalars['Long'];
  booking: Booking;
  loadId?: Maybe<Scalars['Long']>;
  status?: Maybe<LoadDtoStatus>;
  rawRequest?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CourierExchangeLoadDtoInput = {
  bookingId: Scalars['Long'];
  showYourReferenceToSubcontractor: Scalars['Boolean'];
  showCustomerReferenceToSubcontractor: Scalars['Boolean'];
  freightType: LoadDtoFreightType;
  minVehicleSize: FixedLoadDtoMinVehicleSize;
  jobDescription: FixedJobDescriptionType;
  bodyType: LoadDtoBodyType;
  jobType: LoadDtoJobType;
  tailLift: Scalars['Boolean'];
  hiab: Scalars['Boolean'];
  trailer: Scalars['Boolean'];
  moffetMounty: Scalars['Boolean'];
  twoMan: Scalars['Boolean'];
  waitAndReturn: Scalars['Boolean'];
  acceptanceRequired: Scalars['Boolean'];
  podHardCopiesRequired: Scalars['Boolean'];
  readyAt?: Maybe<Scalars['String']>;
  collectBy?: Maybe<Scalars['String']>;
  pickupTimeRange: PickupTimeRangeType;
  deliverBy?: Maybe<Scalars['String']>;
  deliverFrom?: Maybe<Scalars['String']>;
  deliverTimeRange: DeliverTimeRangeType;
  stopTimeDetails?: Maybe<Array<CourierExchangeLoadStopDtoInput>>;
  numberOfItems?: Maybe<Scalars['Int']>;
  packaging?: Maybe<PackagingType>;
  volume?: Maybe<FixedVolumeDtoInput>;
  grossWeight?: Maybe<FixedWeightDtoInput>;
  notes?: Maybe<Scalars['String']>;
  orderSubcontractorId?: Maybe<Scalars['Long']>;
  orderAgreedRate?: Maybe<Scalars['Float']>;
  orderPaymentTerm?: Maybe<FixedOrderDtoPaymentTerm>;
};

export type CourierExchangeLoadFilterInput = {
  and?: Maybe<Array<CourierExchangeLoadFilterInput>>;
  or?: Maybe<Array<CourierExchangeLoadFilterInput>>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  loadId?: Maybe<LongOperationFilterInput>;
  status?: Maybe<NullableOfLoadDtoStatusOperationFilterInput>;
  rawRequest?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CourierExchangeLoadSortInput = {
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  loadId?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  rawRequest?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type CourierExchangeLoadStopDtoInput = {
  timeRange?: Maybe<StopTimeRangeType>;
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
};

export type CourierExchangeQuote = {
  __typename?: 'CourierExchangeQuote';
  loadId?: Maybe<Scalars['Long']>;
  quoteId?: Maybe<Scalars['Long']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
  quoteOwnerCompanyId?: Maybe<Scalars['Long']>;
  quoteOwnerCompanyName?: Maybe<Scalars['String']>;
  quoteOwnerDriverName?: Maybe<Scalars['String']>;
  quoteOwnerPhone?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  status?: Maybe<QuoteInfoStatus>;
  notes?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CourierExchangeQuoteDto = {
  __typename?: 'CourierExchangeQuoteDto';
  id: Scalars['Long'];
  loadId?: Maybe<Scalars['Long']>;
  quoteId?: Maybe<Scalars['Long']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  quoteOwnerCompanyId?: Maybe<Scalars['Long']>;
  quoteOwnerCompanyName?: Maybe<Scalars['String']>;
  quoteOwnerDriverName?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Float']>;
  createdOn?: Maybe<Scalars['String']>;
  status?: Maybe<QuoteInfoStatus>;
  notes?: Maybe<Scalars['String']>;
  isDriverInSystem: Scalars['Boolean'];
  isDriverUsingOurApp: Scalars['Boolean'];
};

export type CourierExchangeQuoteFilterInput = {
  and?: Maybe<Array<CourierExchangeQuoteFilterInput>>;
  or?: Maybe<Array<CourierExchangeQuoteFilterInput>>;
  loadId?: Maybe<LongOperationFilterInput>;
  quoteId?: Maybe<LongOperationFilterInput>;
  price?: Maybe<FloatOperationFilterInput>;
  currency?: Maybe<StringOperationFilterInput>;
  vehicleType?: Maybe<StringOperationFilterInput>;
  quoteOwnerCompanyId?: Maybe<LongOperationFilterInput>;
  quoteOwnerCompanyName?: Maybe<StringOperationFilterInput>;
  quoteOwnerDriverName?: Maybe<StringOperationFilterInput>;
  quoteOwnerPhone?: Maybe<StringOperationFilterInput>;
  createdOn?: Maybe<StringOperationFilterInput>;
  status?: Maybe<NullableOfQuoteInfoStatusOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  totalPrice?: Maybe<FloatOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CourierExchangeQuoteSortInput = {
  loadId?: Maybe<SortEnumType>;
  quoteId?: Maybe<SortEnumType>;
  price?: Maybe<SortEnumType>;
  currency?: Maybe<SortEnumType>;
  vehicleType?: Maybe<SortEnumType>;
  quoteOwnerCompanyId?: Maybe<SortEnumType>;
  quoteOwnerCompanyName?: Maybe<SortEnumType>;
  quoteOwnerDriverName?: Maybe<SortEnumType>;
  quoteOwnerPhone?: Maybe<SortEnumType>;
  createdOn?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  totalPrice?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type CourierExchangeQuotesConnection = {
  __typename?: 'CourierExchangeQuotesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CourierExchangeQuotesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CourierExchangeQuote>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CourierExchangeQuotesEdge = {
  __typename?: 'CourierExchangeQuotesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CourierExchangeQuote;
};

export type CreateAndUpdateAddressDtoInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  isDefault: Scalars['Boolean'];
  customerId: Scalars['Long'];
  postcode: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryId: Scalars['Long'];
  isAutoFill: Scalars['Boolean'];
  contact?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isNotification: Scalars['Boolean'];
};

export type CreateAndUpdateBannerDtoInput = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  type: BannerType;
  fileId?: Maybe<Scalars['Long']>;
  isActive: Scalars['Boolean'];
};

export type CreateAndUpdateBookingCombinedAddressDtoInput = {
  id?: Maybe<Scalars['ID']>;
  bookingId?: Maybe<Scalars['ID']>;
  type: BookingAddressType;
  at?: Maybe<Scalars['DateTime']>;
  by?: Maybe<Scalars['DateTime']>;
  sequenceOrder: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  reasonCodeId?: Maybe<Scalars['Long']>;
  addressData: CreateAndUpdateAddressDtoInput;
};

export type CreateAndUpdateBookingCombinedChargeDtoInput = {
  id?: Maybe<Scalars['ID']>;
  bookingId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: BookingChargeType;
  quantity: Scalars['Int'];
  rate: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  xeroServiceCode?: Maybe<Scalars['String']>;
  vatCategoryId?: Maybe<Scalars['ID']>;
};

export type CreateAndUpdateBookingCombinedDtoInput = {
  id?: Maybe<Scalars['ID']>;
  customerId: Scalars['ID'];
  contactId: Scalars['ID'];
  tariffId?: Maybe<Scalars['ID']>;
  overrideContactEmail?: Maybe<Scalars['String']>;
  overrideContactTelephoneNumber?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  xeroServiceCode?: Maybe<Scalars['String']>;
  ourReference: Scalars['String'];
  noteOrSpecialInstruction?: Maybe<Scalars['String']>;
  consignmentFee: Scalars['Decimal'];
  cancellationReason?: Maybe<Scalars['String']>;
  isEmailNotification: Scalars['Boolean'];
  distance?: Maybe<Scalars['Decimal']>;
  isCalculateDistanceAutomatically: Scalars['Boolean'];
  isWaitAndReturn: Scalars['Boolean'];
  isTransitIgnored: Scalars['Boolean'];
  isQuote?: Maybe<Scalars['Boolean']>;
  isQuickQuote: Scalars['Boolean'];
  sendQuoteEmail?: Maybe<Scalars['Boolean']>;
  alternativeOurReference?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  potentialBookingId?: Maybe<Scalars['Long']>;
  addressesList?: Maybe<Array<CreateAndUpdateBookingCombinedAddressDtoInput>>;
  chargesList?: Maybe<Array<CreateAndUpdateBookingCombinedChargeDtoInput>>;
  packagesList?: Maybe<Array<CreateAndUpdateBookingCombinedPackageDtoInput>>;
  isBiddingEnabled: Scalars['Boolean'];
};

export type CreateAndUpdateBookingCombinedPackageDtoInput = {
  id?: Maybe<Scalars['ID']>;
  bookingId?: Maybe<Scalars['ID']>;
  collectionSequenceOrder: Scalars['Int'];
  deliverySequenceOrder: Scalars['Int'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  weight?: Maybe<Scalars['Decimal']>;
  length?: Maybe<Scalars['Decimal']>;
  width?: Maybe<Scalars['Decimal']>;
  height?: Maybe<Scalars['Decimal']>;
  packageReference?: Maybe<Scalars['String']>;
  type?: Maybe<PackagingType>;
};

export type CreateAndUpdateBookingDriverDtoInput = {
  id?: Maybe<Scalars['ID']>;
  bookingId: Scalars['ID'];
  driverId: Scalars['ID'];
  vehicleId?: Maybe<Scalars['ID']>;
  driverContactId?: Maybe<Scalars['ID']>;
  xeroPurchaseCode?: Maybe<Scalars['String']>;
  distance: Scalars['Decimal'];
  charge: Scalars['Decimal'];
  extraPayment: Scalars['Decimal'];
  isShownInstruction: Scalars['Boolean'];
  instruction?: Maybe<Scalars['String']>;
  currencyId?: Maybe<Scalars['ID']>;
  allocationStatus?: Maybe<DriverAllocationStatus>;
  driverNotes?: Maybe<Scalars['String']>;
};

export type CreateAndUpdateCongestionZoneDtoInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  customerCharge: Scalars['Decimal'];
  weekdays: Array<Weekday>;
  durationFrom: Scalars['DateTime'];
  durationFromHours: Scalars['Int'];
  durationFromMin: Scalars['Int'];
  durationTo: Scalars['DateTime'];
  durationToHours: Scalars['Int'];
  durationToMin: Scalars['Int'];
  isAttachedToAllTariffs: Scalars['Boolean'];
  tariffIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  postcodeNames?: Maybe<Array<Scalars['String']>>;
  isAppliedAutomatically: Scalars['Boolean'];
  xeroServiceCode?: Maybe<Scalars['String']>;
  timeZoneId: Scalars['Long'];
  is24Hours: Scalars['Boolean'];
};

export type CreateAndUpdateCongestionZonePostcodeDtoInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  congestionZoneId?: Maybe<Scalars['ID']>;
};

export type CreateAndUpdateContactDtoInput = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  isDefault: Scalars['Boolean'];
  telephoneNumber: Scalars['String'];
  email: Scalars['String'];
  additionalEmails: Scalars['String'];
  customerId: Scalars['Long'];
  department?: Maybe<Scalars['String']>;
  jobRole?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
};

export type CreateAndUpdateCustomerDtoInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  legalAccountType: LegalAccountType;
  postcode: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryId: Scalars['Long'];
  isOnHold: Scalars['Boolean'];
  invoiceEmail?: Maybe<Scalars['String']>;
  additionalInvoiceEmails?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['Long']>;
  paymentMethodId?: Maybe<Scalars['Long']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  vatCategoryId?: Maybe<Scalars['Long']>;
  isApplyVatToOtherCharges: Scalars['Boolean'];
  accountCode: Scalars['String'];
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  vatIdNumber?: Maybe<Scalars['String']>;
  eoriNumber?: Maybe<Scalars['String']>;
  invoiceCompanyName?: Maybe<Scalars['String']>;
  isInvoiceGrouped: Scalars['Boolean'];
  invoiceFrequency: InvoiceFrequency;
  startDate: Scalars['DateTime'];
  salesName?: Maybe<Scalars['String']>;
  acquiredBy?: Maybe<Scalars['String']>;
  customerAcquisitionSourceId?: Maybe<Scalars['Long']>;
  notes?: Maybe<Scalars['String']>;
  customerAccountType: CustomerAccountType;
  invoiceTermId?: Maybe<Scalars['Long']>;
  isUlezCharge: Scalars['Boolean'];
  isReferenceRequired: Scalars['Boolean'];
  isEnabled: Scalars['Boolean'];
  currencyId: Scalars['ID'];
  primaryCustomerId?: Maybe<Scalars['ID']>;
  isReasonCodeOptionEnabled: Scalars['Boolean'];
  isBookingImportOptionEnabled: Scalars['Boolean'];
  isPackageDetailsOptional: Scalars['Boolean'];
};

export type CreateAndUpdateDriverBidDtoInput = {
  id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Decimal']>;
  eta?: Maybe<Scalars['DateTime']>;
  notes?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  bookingId?: Maybe<Scalars['ID']>;
  vehicleId?: Maybe<Scalars['ID']>;
  currencyId?: Maybe<Scalars['ID']>;
};

export type CreateAndUpdateDriverContactDtoInput = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  isDefault: Scalars['Boolean'];
  telephoneNumber: Scalars['String'];
  email: Scalars['String'];
  additionalEmails: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobRole?: Maybe<Scalars['String']>;
  driverId: Scalars['Long'];
};

export type CreateAndUpdateDriverDtoInput = {
  id?: Maybe<Scalars['ID']>;
  primaryDriverId?: Maybe<Scalars['ID']>;
  currencyId: Scalars['ID'];
  name: Scalars['String'];
  driverType: DriverType;
  xeroCodeId?: Maybe<Scalars['Long']>;
  courierExchangeMemberId?: Maybe<Scalars['String']>;
  xeroPurchaseCode?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryId: Scalars['Long'];
  email: Scalars['String'];
  telephoneNumber: Scalars['String'];
  startDate: Scalars['DateTime'];
  paymentTerm: PaymentTerm;
  useDefaultRate?: Maybe<Scalars['Boolean']>;
  distanceRate?: Maybe<Scalars['Decimal']>;
  minMiles?: Maybe<Scalars['Decimal']>;
  minCharge?: Maybe<Scalars['Decimal']>;
  publicLiabilityExpirationDate?: Maybe<Scalars['DateTime']>;
  isEmailNotification?: Maybe<Scalars['Boolean']>;
  isMobileAppNotification?: Maybe<Scalars['Boolean']>;
  licenseType: DriverLicenseType;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  endorsements?: Maybe<Scalars['String']>;
  nationalInsuranceNumber?: Maybe<Scalars['String']>;
  lastPaid?: Maybe<Scalars['DateTime']>;
  vatCategoryId: Scalars['ID'];
  bankName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  vatIdNumber?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
  eoriNumber?: Maybe<Scalars['String']>;
  invoiceEmail?: Maybe<Scalars['String']>;
  bankSortCode?: Maybe<Scalars['String']>;
  employeeReference?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  creditCard?: Maybe<Scalars['String']>;
  isOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  isMobileOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  alternativeTelephoneNumber?: Maybe<Scalars['String']>;
  bankAccountHolderName?: Maybe<Scalars['String']>;
  internationalBankAccountNumber?: Maybe<Scalars['String']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
};

export type CreateAndUpdateOtherChargeDtoInput = {
  id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rate: Scalars['Decimal'];
  xeroServiceCode?: Maybe<Scalars['String']>;
  vatCategoryId: Scalars['ID'];
};

export type CreateAndUpdateQuoteLostReasonDtoInput = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type CreateAndUpdateReasonCodeDtoInput = {
  id?: Maybe<Scalars['Long']>;
  customerId?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type CreateAndUpdateStandardOperatingProcedureDtoInput = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  description: Scalars['String'];
  steps: Scalars['String'];
  frequentlyAskedQuestions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  driverInstructions?: Maybe<Scalars['String']>;
  isAttachedToAllCustomers: Scalars['Boolean'];
  customerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateAndUpdateStrategicPartnerContactDtoInput = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  isDefault: Scalars['Boolean'];
  telephoneNumber: Scalars['String'];
  email: Scalars['String'];
  additionalEmails: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobRole?: Maybe<Scalars['String']>;
  strategicPartnerId: Scalars['Long'];
};

export type CreateAndUpdateStrategicPartnerDtoInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  serviceType: ServiceType;
  vatIdNumber?: Maybe<Scalars['String']>;
  eoriNumber?: Maybe<Scalars['String']>;
  xeroPurchaseCode?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Long']>;
  email?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
};

export type CreateAndUpdateSubDriverDtoInput = {
  id?: Maybe<Scalars['ID']>;
  primaryDriverId: Scalars['ID'];
  vatCategoryId: Scalars['ID'];
  name: Scalars['String'];
  driverType: DriverType;
  xeroCodeId?: Maybe<Scalars['Long']>;
  callSign?: Maybe<Scalars['String']>;
  xeroPurchaseCode?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  telephoneNumber: Scalars['String'];
  useDefaultRate?: Maybe<Scalars['Boolean']>;
  distanceRate?: Maybe<Scalars['Decimal']>;
  minMiles?: Maybe<Scalars['Decimal']>;
  minCharge?: Maybe<Scalars['Decimal']>;
  isEmailNotification?: Maybe<Scalars['Boolean']>;
  isMobileAppNotification?: Maybe<Scalars['Boolean']>;
  isActive: Scalars['Boolean'];
};

export type CreateAndUpdateTariffCategoryDtoInput = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  courierExchangeVehicleSize?: Maybe<FixedLoadDtoMinVehicleSize>;
  hasSpotRate: Scalars['Boolean'];
};

export type CreateAndUpdateTariffDtoInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  tariffCategoryId?: Maybe<Scalars['ID']>;
  vatCategoryId?: Maybe<Scalars['ID']>;
  xeroServiceCode?: Maybe<Scalars['String']>;
  xeroCodeId?: Maybe<Scalars['ID']>;
  type?: Maybe<TariffType>;
  minMiles?: Maybe<Scalars['Decimal']>;
  minCharge?: Maybe<Scalars['Decimal']>;
  rate: Scalars['Decimal'];
  isAttachedToAllCustomers: Scalars['Boolean'];
  customerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  baseUnit?: Maybe<TariffBaseUnit>;
  isDropChargeEnabled: Scalars['Boolean'];
  isUseDefaultDropCharge: Scalars['Boolean'];
  dropChargeRate?: Maybe<Scalars['Decimal']>;
  isOverwriteDefaultWaitingTime: Scalars['Boolean'];
  collectionAllowanceMinutes?: Maybe<Scalars['Int']>;
  deliveryAllowanceMinutes?: Maybe<Scalars['Int']>;
  excessWaitingTimeCharge?: Maybe<Scalars['Decimal']>;
  excessWaitingTimeMinutesPer?: Maybe<Scalars['Int']>;
  calculateCharge?: Maybe<TariffCalculateCharge>;
  isExternal: Scalars['Boolean'];
  visibilitySequenceNumber?: Maybe<Scalars['Int']>;
  iconUrl?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  tariffIconId?: Maybe<Scalars['Long']>;
};

export type CreateAndUpdateTariffIconDtoInput = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  fileId?: Maybe<Scalars['Long']>;
  vehicleCapacity?: Maybe<Scalars['String']>;
};

export type CreateAndUpdateVehicleDtoInput = {
  id?: Maybe<Scalars['ID']>;
  isDefault: Scalars['Boolean'];
  driverId: Scalars['ID'];
  registrationNumber: Scalars['String'];
  vehicleTypeId: Scalars['Long'];
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  dateOfManufacture?: Maybe<Scalars['DateTime']>;
  colour?: Maybe<Scalars['String']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  insuranceExpiryDate?: Maybe<Scalars['DateTime']>;
  motTestExpiryDate?: Maybe<Scalars['DateTime']>;
  isRenewalReminder: Scalars['Boolean'];
  policyOrCoverNote?: Maybe<Scalars['String']>;
  goodsInTransitExpiryDate?: Maybe<Scalars['DateTime']>;
  timeZoneOffset?: Maybe<Scalars['Float']>;
};

export type CreateAndUpdateXeroCodeDtoInput = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: XeroCodeType;
  isInternational: Scalars['Boolean'];
  serviceType: XeroServiceType;
};

export type CreateBookingInvoiceDtoInput = {
  bookingIds: Array<Scalars['Long']>;
};

export type CreateDriverLocationDtoInput = {
  coords: CoordsDtoInput;
  timeStamp: Scalars['String'];
};

export type CreateDriverWaitTimeDtoInput = {
  bookingAddressId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateExceptionReasonDtoInput = {
  bookingAddressId: Scalars['ID'];
  reasonType?: Maybe<ExceptionReasonType>;
  comments: Scalars['String'];
};

export type CreatePaymentDtoInput = {
  accountPayableId?: Maybe<Scalars['ID']>;
};

export type CreateScheduledBookingDateDtoInput = {
  templateId: Scalars['Long'];
  executeOnDate: Scalars['DateTime'];
};

export type CreateUpdateActivityDtoInput = {
  id?: Maybe<Scalars['Long']>;
  type: ActivityType;
  note: Scalars['String'];
  date: Scalars['DateTime'];
  bookingId?: Maybe<Scalars['Long']>;
};

export type CreateUpdateCommentaryDtoInput = {
  id?: Maybe<Scalars['Long']>;
  message: Scalars['String'];
  taskId: Scalars['Long'];
};

export type CreateUpdateOpenQueryDtoInput = {
  id?: Maybe<Scalars['Long']>;
  accountPayableRecordId: Scalars['Long'];
  reason: Scalars['Int'];
};

export type CreateUpdateTaskDtoInput = {
  id?: Maybe<Scalars['Long']>;
  description: Scalars['String'];
  additionalDetail?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['DateTime']>;
  assignedUserName: Scalars['String'];
  status: TaskStatus;
  bookingId?: Maybe<Scalars['Long']>;
  categoryId?: Maybe<Scalars['Long']>;
};

export type CreateUpdateTaskSettingDtoInput = {
  id?: Maybe<Scalars['ID']>;
  taskRelatedTo: TaskRelatedTo;
  taskCaption: Scalars['String'];
  interval: Scalars['Int'];
};

export type CreateUpdateUserDto = {
  __typename?: 'CreateUpdateUserDto';
  id?: Maybe<Scalars['ID']>;
  username: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  dob: Scalars['DateTime'];
  countryId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  companyDetails?: Maybe<UserCompanyDetailsDto>;
};

export type CreateUpdateUserDtoInput = {
  id?: Maybe<Scalars['ID']>;
  username: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  city: Scalars['String'];
  postcode: Scalars['String'];
  dob: Scalars['DateTime'];
  countryId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  companyDetails?: Maybe<UserCompanyDetailsDtoInput>;
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  symbol: Scalars['String'];
  isBaseCurrency: Scalars['Boolean'];
  drivers?: Maybe<Array<Driver>>;
  customers?: Maybe<Array<Customer>>;
  bookingDrivers?: Maybe<Array<BookingDriver>>;
  payments?: Maybe<Array<Payment>>;
  driverBids?: Maybe<Array<DriverBid>>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CurrencyFilterInput = {
  and?: Maybe<Array<CurrencyFilterInput>>;
  or?: Maybe<Array<CurrencyFilterInput>>;
  code?: Maybe<StringOperationFilterInput>;
  symbol?: Maybe<StringOperationFilterInput>;
  isBaseCurrency?: Maybe<BooleanOperationFilterInput>;
  drivers?: Maybe<ListFilterInputTypeOfDriverFilterInput>;
  customers?: Maybe<ListFilterInputTypeOfCustomerFilterInput>;
  bookingDrivers?: Maybe<ListFilterInputTypeOfBookingDriverFilterInput>;
  payments?: Maybe<ListFilterInputTypeOfPaymentFilterInput>;
  driverBids?: Maybe<ListFilterInputTypeOfDriverBidFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CurrencySortInput = {
  code?: Maybe<SortEnumType>;
  symbol?: Maybe<SortEnumType>;
  isBaseCurrency?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type CurrentDriversLocationsConnection = {
  __typename?: 'CurrentDriversLocationsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<CurrentDriversLocationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<DriverLocation>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CurrentDriversLocationsEdge = {
  __typename?: 'CurrentDriversLocationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DriverLocation;
};

export type Customer = {
  __typename?: 'Customer';
  primaryCustomerId?: Maybe<Scalars['ID']>;
  primaryCustomer?: Maybe<Customer>;
  subCustomers: Array<Customer>;
  legalAccountType: LegalAccountType;
  postcode: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryId: Scalars['Long'];
  country: Country;
  isOnHold: Scalars['Boolean'];
  invoiceEmail?: Maybe<Scalars['String']>;
  additionalInvoiceEmails?: Maybe<Scalars['String']>;
  contacts: Array<Contact>;
  paymentMethodId?: Maybe<Scalars['Long']>;
  paymentMethod?: Maybe<PaymentMethod>;
  telephoneNumber?: Maybe<Scalars['String']>;
  vatCategoryId?: Maybe<Scalars['Long']>;
  vatCategory?: Maybe<VatCategory>;
  isApplyVatToOtherCharges: Scalars['Boolean'];
  accountCode: Scalars['String'];
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  vatIdNumber?: Maybe<Scalars['String']>;
  eoriNumber?: Maybe<Scalars['String']>;
  invoiceCompanyName?: Maybe<Scalars['String']>;
  isInvoiceGrouped: Scalars['Boolean'];
  invoiceFrequency: InvoiceFrequency;
  startDate: Scalars['DateTime'];
  salesName?: Maybe<Scalars['String']>;
  acquiredBy?: Maybe<Scalars['String']>;
  customerAcquisitionSourceId?: Maybe<Scalars['Long']>;
  customerAcquisitionSource?: Maybe<CustomerAcquisitionSource>;
  notes?: Maybe<Scalars['String']>;
  customerAccountType: CustomerAccountType;
  invoiceTermId?: Maybe<Scalars['Long']>;
  invoiceTerm?: Maybe<InvoiceTerm>;
  isUlezCharge: Scalars['Boolean'];
  isReferenceRequired: Scalars['Boolean'];
  addresses: Array<Address>;
  isReasonCodeOptionEnabled: Scalars['Boolean'];
  isBookingImportOptionEnabled: Scalars['Boolean'];
  isNotificationForQuote: Scalars['Boolean'];
  isNotificationForBooking: Scalars['Boolean'];
  isNotificationForPobArrival: Scalars['Boolean'];
  isNotificationForPOB: Scalars['Boolean'];
  isNotificationForPodArrival: Scalars['Boolean'];
  isNotificationForPOD: Scalars['Boolean'];
  isNotificationForCancellation: Scalars['Boolean'];
  isNotificationForInvoice: Scalars['Boolean'];
  isNotificationForCarded: Scalars['Boolean'];
  isNotificationForAmendment: Scalars['Boolean'];
  isNotificationForDriverAllocated: Scalars['Boolean'];
  isNotificationForPodPartial: Scalars['Boolean'];
  isNotificationForReadyForAllocation: Scalars['Boolean'];
  customerInvoiceNotificationType: CustomerInvoiceNotificationType;
  isNotificationForInvoiceDue: Scalars['Boolean'];
  isPackageDetailsOptional: Scalars['Boolean'];
  customerReferenceLabel?: Maybe<Scalars['String']>;
  isCustomerReferenceMandatory: Scalars['Boolean'];
  noteForAccount?: Maybe<Scalars['String']>;
  noteForBooking?: Maybe<Scalars['String']>;
  noteForPaperwork?: Maybe<Scalars['String']>;
  isPaperworkRequired: Scalars['Boolean'];
  noteForPackage?: Maybe<Scalars['String']>;
  instructionsForCourierExchangeDriver?: Maybe<Scalars['String']>;
  bookings: Array<Booking>;
  tariffs: Array<CustomerTariff>;
  standardOperatingProcedures: Array<CustomerStandardOperatingProcedure>;
  invoices: Array<BookingInvoice>;
  reasonCodes: Array<ReasonCode>;
  currencyId?: Maybe<Scalars['Long']>;
  currency?: Maybe<Currency>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  defaultAddressId?: Maybe<Scalars['Long']>;
  defaultAddress?: Maybe<Address>;
};

export enum CustomerAccountType {
  Cash = 'CASH',
  Credit = 'CREDIT',
  Card = 'CARD',
  Factor = 'FACTOR'
}

export type CustomerAccountTypeOperationFilterInput = {
  eq?: Maybe<CustomerAccountType>;
  neq?: Maybe<CustomerAccountType>;
  in?: Maybe<Array<CustomerAccountType>>;
  nin?: Maybe<Array<CustomerAccountType>>;
};

export type CustomerAcquisitionSource = {
  __typename?: 'CustomerAcquisitionSource';
  customers: Array<Customer>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CustomerAcquisitionSourceFilterInput = {
  and?: Maybe<Array<CustomerAcquisitionSourceFilterInput>>;
  or?: Maybe<Array<CustomerAcquisitionSourceFilterInput>>;
  customers?: Maybe<ListFilterInputTypeOfCustomerFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CustomerAcquisitionSourceSortInput = {
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type CustomerContactListReportInputDtoInput = {
  customerIds?: Maybe<Array<Scalars['Long']>>;
};

export type CustomerDto = {
  __typename?: 'CustomerDto';
  id: Scalars['Long'];
  customer: Customer;
  logoId?: Maybe<Scalars['Long']>;
  logo?: Maybe<File>;
};

export type CustomerFilterInput = {
  and?: Maybe<Array<CustomerFilterInput>>;
  or?: Maybe<Array<CustomerFilterInput>>;
  primaryCustomerId?: Maybe<LongOperationFilterInput>;
  primaryCustomer?: Maybe<CustomerFilterInput>;
  subCustomers?: Maybe<ListFilterInputTypeOfCustomerFilterInput>;
  legalAccountType?: Maybe<LegalAccountTypeOperationFilterInput>;
  postcode?: Maybe<StringOperationFilterInput>;
  addressLine1?: Maybe<StringOperationFilterInput>;
  addressLine2?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  countryId?: Maybe<LongOperationFilterInput>;
  country?: Maybe<CountryFilterInput>;
  isOnHold?: Maybe<BooleanOperationFilterInput>;
  invoiceEmail?: Maybe<StringOperationFilterInput>;
  additionalInvoiceEmails?: Maybe<StringOperationFilterInput>;
  contacts?: Maybe<ListFilterInputTypeOfContactFilterInput>;
  paymentMethodId?: Maybe<LongOperationFilterInput>;
  paymentMethod?: Maybe<PaymentMethodFilterInput>;
  telephoneNumber?: Maybe<StringOperationFilterInput>;
  vatCategoryId?: Maybe<LongOperationFilterInput>;
  vatCategory?: Maybe<VatCategoryFilterInput>;
  isApplyVatToOtherCharges?: Maybe<BooleanOperationFilterInput>;
  accountCode?: Maybe<StringOperationFilterInput>;
  companyRegistrationNumber?: Maybe<StringOperationFilterInput>;
  vatIdNumber?: Maybe<StringOperationFilterInput>;
  eoriNumber?: Maybe<StringOperationFilterInput>;
  invoiceCompanyName?: Maybe<StringOperationFilterInput>;
  isInvoiceGrouped?: Maybe<BooleanOperationFilterInput>;
  invoiceFrequency?: Maybe<InvoiceFrequencyOperationFilterInput>;
  startDate?: Maybe<DateTimeOperationFilterInput>;
  salesName?: Maybe<StringOperationFilterInput>;
  acquiredBy?: Maybe<StringOperationFilterInput>;
  customerAcquisitionSourceId?: Maybe<LongOperationFilterInput>;
  customerAcquisitionSource?: Maybe<CustomerAcquisitionSourceFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  customerAccountType?: Maybe<CustomerAccountTypeOperationFilterInput>;
  invoiceTermId?: Maybe<LongOperationFilterInput>;
  invoiceTerm?: Maybe<InvoiceTermFilterInput>;
  isUlezCharge?: Maybe<BooleanOperationFilterInput>;
  isReferenceRequired?: Maybe<BooleanOperationFilterInput>;
  addresses?: Maybe<ListFilterInputTypeOfAddressFilterInput>;
  isReasonCodeOptionEnabled?: Maybe<BooleanOperationFilterInput>;
  isBookingImportOptionEnabled?: Maybe<BooleanOperationFilterInput>;
  isNotificationForQuote?: Maybe<BooleanOperationFilterInput>;
  isNotificationForBooking?: Maybe<BooleanOperationFilterInput>;
  isNotificationForPobArrival?: Maybe<BooleanOperationFilterInput>;
  isNotificationForPOB?: Maybe<BooleanOperationFilterInput>;
  isNotificationForPodArrival?: Maybe<BooleanOperationFilterInput>;
  isNotificationForPOD?: Maybe<BooleanOperationFilterInput>;
  isNotificationForCancellation?: Maybe<BooleanOperationFilterInput>;
  isNotificationForInvoice?: Maybe<BooleanOperationFilterInput>;
  isNotificationForCarded?: Maybe<BooleanOperationFilterInput>;
  isNotificationForAmendment?: Maybe<BooleanOperationFilterInput>;
  isNotificationForDriverAllocated?: Maybe<BooleanOperationFilterInput>;
  isNotificationForPodPartial?: Maybe<BooleanOperationFilterInput>;
  isNotificationForReadyForAllocation?: Maybe<BooleanOperationFilterInput>;
  customerInvoiceNotificationType?: Maybe<CustomerInvoiceNotificationTypeOperationFilterInput>;
  isNotificationForInvoiceDue?: Maybe<BooleanOperationFilterInput>;
  isPackageDetailsOptional?: Maybe<BooleanOperationFilterInput>;
  customerReferenceLabel?: Maybe<StringOperationFilterInput>;
  isCustomerReferenceMandatory?: Maybe<BooleanOperationFilterInput>;
  noteForAccount?: Maybe<StringOperationFilterInput>;
  noteForBooking?: Maybe<StringOperationFilterInput>;
  noteForPaperwork?: Maybe<StringOperationFilterInput>;
  isPaperworkRequired?: Maybe<BooleanOperationFilterInput>;
  noteForPackage?: Maybe<StringOperationFilterInput>;
  instructionsForCourierExchangeDriver?: Maybe<StringOperationFilterInput>;
  bookings?: Maybe<ListFilterInputTypeOfBookingFilterInput>;
  tariffs?: Maybe<ListFilterInputTypeOfCustomerTariffFilterInput>;
  standardOperatingProcedures?: Maybe<ListFilterInputTypeOfCustomerStandardOperatingProcedureFilterInput>;
  invoices?: Maybe<ListFilterInputTypeOfBookingInvoiceFilterInput>;
  reasonCodes?: Maybe<ListFilterInputTypeOfReasonCodeFilterInput>;
  currencyId?: Maybe<LongOperationFilterInput>;
  currency?: Maybe<CurrencyFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CustomerIncomeReportInputDtoInput = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  customerIds?: Maybe<Array<Scalars['Long']>>;
};

export type CustomerInvoiceDto = {
  __typename?: 'CustomerInvoiceDto';
  id: Scalars['Long'];
  bookings: Array<Booking>;
  ourReferences: Scalars['String'];
  bookingStatuses: Scalars['String'];
  customerReferences: Scalars['String'];
  bookingDate: Scalars['String'];
};

export type CustomerInvoiceDtoFilterInput = {
  and?: Maybe<Array<CustomerInvoiceDtoFilterInput>>;
  or?: Maybe<Array<CustomerInvoiceDtoFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  bookings?: Maybe<ListFilterInputTypeOfBookingFilterInput>;
  ourReferences?: Maybe<StringOperationFilterInput>;
  bookingStatuses?: Maybe<StringOperationFilterInput>;
  customerReferences?: Maybe<StringOperationFilterInput>;
  bookingDate?: Maybe<StringOperationFilterInput>;
};

export type CustomerInvoiceDtoSortInput = {
  id?: Maybe<SortEnumType>;
  ourReferences?: Maybe<SortEnumType>;
  bookingStatuses?: Maybe<SortEnumType>;
  customerReferences?: Maybe<SortEnumType>;
  bookingDate?: Maybe<SortEnumType>;
};

export enum CustomerInvoiceNotificationType {
  Email = 'EMAIL',
  PrintDirectly = 'PRINT_DIRECTLY'
}

export type CustomerInvoiceNotificationTypeOperationFilterInput = {
  eq?: Maybe<CustomerInvoiceNotificationType>;
  neq?: Maybe<CustomerInvoiceNotificationType>;
  in?: Maybe<Array<CustomerInvoiceNotificationType>>;
  nin?: Maybe<Array<CustomerInvoiceNotificationType>>;
};

export enum CustomerOption {
  None = 'NONE',
  ReasonCode = 'REASON_CODE',
  BookingImport = 'BOOKING_IMPORT'
}

export type CustomerProfileDto = {
  __typename?: 'CustomerProfileDto';
  id: Scalars['Long'];
  userName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  emailConfirmed: Scalars['Boolean'];
  telephoneNumber: Scalars['String'];
  avatarUri: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Long']>;
  country?: Maybe<Scalars['String']>;
  customerId: Scalars['Long'];
  contactId: Scalars['Long'];
  customerTradingName: Scalars['String'];
  isReferenceRequired: Scalars['Boolean'];
  isReasonCodeOptionEnabled: Scalars['Boolean'];
  isBookingImportOptionEnabled: Scalars['Boolean'];
  isPackageDetailsOptional: Scalars['Boolean'];
  primaryCustomerId?: Maybe<Scalars['Long']>;
};

export type CustomerSortInput = {
  primaryCustomerId?: Maybe<SortEnumType>;
  primaryCustomer?: Maybe<CustomerSortInput>;
  legalAccountType?: Maybe<SortEnumType>;
  postcode?: Maybe<SortEnumType>;
  addressLine1?: Maybe<SortEnumType>;
  addressLine2?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  countryId?: Maybe<SortEnumType>;
  country?: Maybe<CountrySortInput>;
  isOnHold?: Maybe<SortEnumType>;
  invoiceEmail?: Maybe<SortEnumType>;
  additionalInvoiceEmails?: Maybe<SortEnumType>;
  paymentMethodId?: Maybe<SortEnumType>;
  paymentMethod?: Maybe<PaymentMethodSortInput>;
  telephoneNumber?: Maybe<SortEnumType>;
  vatCategoryId?: Maybe<SortEnumType>;
  vatCategory?: Maybe<VatCategorySortInput>;
  isApplyVatToOtherCharges?: Maybe<SortEnumType>;
  accountCode?: Maybe<SortEnumType>;
  companyRegistrationNumber?: Maybe<SortEnumType>;
  vatIdNumber?: Maybe<SortEnumType>;
  eoriNumber?: Maybe<SortEnumType>;
  invoiceCompanyName?: Maybe<SortEnumType>;
  isInvoiceGrouped?: Maybe<SortEnumType>;
  invoiceFrequency?: Maybe<SortEnumType>;
  startDate?: Maybe<SortEnumType>;
  salesName?: Maybe<SortEnumType>;
  acquiredBy?: Maybe<SortEnumType>;
  customerAcquisitionSourceId?: Maybe<SortEnumType>;
  customerAcquisitionSource?: Maybe<CustomerAcquisitionSourceSortInput>;
  notes?: Maybe<SortEnumType>;
  customerAccountType?: Maybe<SortEnumType>;
  invoiceTermId?: Maybe<SortEnumType>;
  invoiceTerm?: Maybe<InvoiceTermSortInput>;
  isUlezCharge?: Maybe<SortEnumType>;
  isReferenceRequired?: Maybe<SortEnumType>;
  isReasonCodeOptionEnabled?: Maybe<SortEnumType>;
  isBookingImportOptionEnabled?: Maybe<SortEnumType>;
  isNotificationForQuote?: Maybe<SortEnumType>;
  isNotificationForBooking?: Maybe<SortEnumType>;
  isNotificationForPobArrival?: Maybe<SortEnumType>;
  isNotificationForPOB?: Maybe<SortEnumType>;
  isNotificationForPodArrival?: Maybe<SortEnumType>;
  isNotificationForPOD?: Maybe<SortEnumType>;
  isNotificationForCancellation?: Maybe<SortEnumType>;
  isNotificationForInvoice?: Maybe<SortEnumType>;
  isNotificationForCarded?: Maybe<SortEnumType>;
  isNotificationForAmendment?: Maybe<SortEnumType>;
  isNotificationForDriverAllocated?: Maybe<SortEnumType>;
  isNotificationForPodPartial?: Maybe<SortEnumType>;
  isNotificationForReadyForAllocation?: Maybe<SortEnumType>;
  customerInvoiceNotificationType?: Maybe<SortEnumType>;
  isNotificationForInvoiceDue?: Maybe<SortEnumType>;
  isPackageDetailsOptional?: Maybe<SortEnumType>;
  customerReferenceLabel?: Maybe<SortEnumType>;
  isCustomerReferenceMandatory?: Maybe<SortEnumType>;
  noteForAccount?: Maybe<SortEnumType>;
  noteForBooking?: Maybe<SortEnumType>;
  noteForPaperwork?: Maybe<SortEnumType>;
  isPaperworkRequired?: Maybe<SortEnumType>;
  noteForPackage?: Maybe<SortEnumType>;
  instructionsForCourierExchangeDriver?: Maybe<SortEnumType>;
  currencyId?: Maybe<SortEnumType>;
  currency?: Maybe<CurrencySortInput>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type CustomerStandardOperatingProcedure = {
  __typename?: 'CustomerStandardOperatingProcedure';
  customerId: Scalars['Long'];
  customer: Customer;
  standardOperatingProcedureId: Scalars['Long'];
  standardOperatingProcedure: StandardOperatingProcedure;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CustomerStandardOperatingProcedureFilterInput = {
  and?: Maybe<Array<CustomerStandardOperatingProcedureFilterInput>>;
  or?: Maybe<Array<CustomerStandardOperatingProcedureFilterInput>>;
  customerId?: Maybe<LongOperationFilterInput>;
  customer?: Maybe<CustomerFilterInput>;
  standardOperatingProcedureId?: Maybe<LongOperationFilterInput>;
  standardOperatingProcedure?: Maybe<StandardOperatingProcedureFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type CustomerTariff = {
  __typename?: 'CustomerTariff';
  isDefault: Scalars['Boolean'];
  customerId: Scalars['Long'];
  customer: Customer;
  tariffId: Scalars['Long'];
  tariff: Tariff;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CustomerTariffFilterInput = {
  and?: Maybe<Array<CustomerTariffFilterInput>>;
  or?: Maybe<Array<CustomerTariffFilterInput>>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  customerId?: Maybe<LongOperationFilterInput>;
  customer?: Maybe<CustomerFilterInput>;
  tariffId?: Maybe<LongOperationFilterInput>;
  tariff?: Maybe<TariffFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};


export type DateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  gt?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  nlt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};


export type DecimalOperationFilterInput = {
  eq?: Maybe<Scalars['Decimal']>;
  neq?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  gt?: Maybe<Scalars['Decimal']>;
  ngt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  ngte?: Maybe<Scalars['Decimal']>;
  lt?: Maybe<Scalars['Decimal']>;
  nlt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  nlte?: Maybe<Scalars['Decimal']>;
};

export type DeleteBookingAddressAttachmentDtoInput = {
  addressId: Scalars['Long'];
  fileId: Scalars['Long'];
  category?: Maybe<FileCategory>;
};

export type DeleteOrDisableBookingDtoInput = {
  id: Scalars['Long'];
  notifyDrivers: Scalars['Boolean'];
  notifyCustomer: Scalars['Boolean'];
  reason: Scalars['String'];
};

export type DeleteSopAttachmentDtoInput = {
  id: Scalars['Long'];
  attachmentId: Scalars['Long'];
};

export enum DeliverTimeRangeType {
  Asap = 'ASAP',
  At = 'AT',
  By = 'BY',
  Between = 'BETWEEN'
}

export type DeviceNotificationToken = {
  __typename?: 'DeviceNotificationToken';
  token: Scalars['String'];
  platform: TokenPlatformType;
  driverId: Scalars['Long'];
  driver: Driver;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type DeviceNotificationTokenFilterInput = {
  and?: Maybe<Array<DeviceNotificationTokenFilterInput>>;
  or?: Maybe<Array<DeviceNotificationTokenFilterInput>>;
  token?: Maybe<StringOperationFilterInput>;
  platform?: Maybe<TokenPlatformTypeOperationFilterInput>;
  driverId?: Maybe<LongOperationFilterInput>;
  driver?: Maybe<DriverFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type DeviceRegistration = {
  __typename?: 'DeviceRegistration';
  registrationId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type DeviceTrend = {
  __typename?: 'DeviceTrend';
  deviceType?: Maybe<Scalars['String']>;
  registrationCount: Scalars['Int'];
};

export type DistanceDto = {
  __typename?: 'DistanceDto';
  id: Scalars['Long'];
  status: GoogleDistanceStatus;
  errorMessage?: Maybe<Scalars['String']>;
  distance: Scalars['Decimal'];
};

export type Driver = {
  __typename?: 'Driver';
  primaryDriverId?: Maybe<Scalars['ID']>;
  primaryDriver?: Maybe<Driver>;
  subDrivers: Array<Driver>;
  driverType: DriverType;
  xeroCodeId?: Maybe<Scalars['Long']>;
  xeroCode?: Maybe<XeroCode>;
  callSign?: Maybe<Scalars['String']>;
  courierExchangeMemberId?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  xeroPurchaseCode?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  postcode: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryId: Scalars['Long'];
  country: Country;
  currencyId?: Maybe<Scalars['Long']>;
  currency?: Maybe<Currency>;
  email: Scalars['String'];
  telephoneNumber: Scalars['String'];
  startDate: Scalars['DateTime'];
  leavingDate?: Maybe<Scalars['DateTime']>;
  paymentTerm: PaymentTerm;
  isUlezCharge: Scalars['Boolean'];
  useDefaultRate?: Maybe<Scalars['Boolean']>;
  distanceRate?: Maybe<Scalars['Decimal']>;
  minMiles?: Maybe<Scalars['Decimal']>;
  minCharge?: Maybe<Scalars['Decimal']>;
  publicLiabilityExpirationDate?: Maybe<Scalars['DateTime']>;
  isEmailNotification?: Maybe<Scalars['Boolean']>;
  isMobileAppNotification?: Maybe<Scalars['Boolean']>;
  vehicles: Array<Vehicle>;
  bookings: Array<BookingDriver>;
  contacts: Array<DriverContact>;
  driverBids: Array<DriverBid>;
  licenseType: DriverLicenseType;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  endorsements?: Maybe<Scalars['String']>;
  nationalInsuranceNumber?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<PaymentMethod>;
  lastPaid?: Maybe<Scalars['DateTime']>;
  vatCategoryId?: Maybe<Scalars['ID']>;
  vatCategory?: Maybe<VatCategory>;
  bankName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  creditCard?: Maybe<Scalars['String']>;
  payFrequency: PayFrequency;
  chequeName?: Maybe<Scalars['String']>;
  isGenerateSeparateInvoice: Scalars['Boolean'];
  vatIdNumber?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
  eoriNumber?: Maybe<Scalars['String']>;
  bankSortCode?: Maybe<Scalars['String']>;
  employeeReference?: Maybe<Scalars['String']>;
  isEmailInvoice: Scalars['Boolean'];
  invoiceEmail?: Maybe<Scalars['String']>;
  isTrackingEnabled: Scalars['Boolean'];
  identityUserName?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<DriverPackage>>;
  isUsingInternalApp: Scalars['Boolean'];
  locationId?: Maybe<Scalars['Long']>;
  location?: Maybe<DriverLocation>;
  notificationTokens?: Maybe<Array<DeviceNotificationToken>>;
  isOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  isMobileOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  alternativeTelephoneNumber?: Maybe<Scalars['String']>;
  bankAccountHolderName?: Maybe<Scalars['String']>;
  internationalBankAccountNumber?: Maybe<Scalars['String']>;
  averageRating?: Maybe<Scalars['Decimal']>;
  companyRegistrationNumber?: Maybe<Scalars['String']>;
  notifications: Array<MobileNotification>;
  preferredLanguage: LanguageCode;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  userProfile?: Maybe<DriverUserProfileDto>;
};

export enum DriverAllocationStatus {
  Awaiting = 'AWAITING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export type DriverAllocationStatusOperationFilterInput = {
  eq?: Maybe<DriverAllocationStatus>;
  neq?: Maybe<DriverAllocationStatus>;
  in?: Maybe<Array<DriverAllocationStatus>>;
  nin?: Maybe<Array<DriverAllocationStatus>>;
};

export type DriverBid = {
  __typename?: 'DriverBid';
  price: Scalars['Decimal'];
  eta: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  isAllocated: Scalars['Boolean'];
  driverId: Scalars['Long'];
  driver: Driver;
  bookingId: Scalars['Long'];
  booking: Booking;
  vehicleId: Scalars['Long'];
  vehicle: Vehicle;
  currencyId: Scalars['Long'];
  currency: Currency;
  notifications?: Maybe<Array<MobileNotification>>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type DriverBidFilterInput = {
  and?: Maybe<Array<DriverBidFilterInput>>;
  or?: Maybe<Array<DriverBidFilterInput>>;
  price?: Maybe<DecimalOperationFilterInput>;
  eta?: Maybe<DateTimeOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  isAllocated?: Maybe<BooleanOperationFilterInput>;
  driverId?: Maybe<LongOperationFilterInput>;
  driver?: Maybe<DriverFilterInput>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  vehicleId?: Maybe<LongOperationFilterInput>;
  vehicle?: Maybe<VehicleFilterInput>;
  currencyId?: Maybe<LongOperationFilterInput>;
  currency?: Maybe<CurrencyFilterInput>;
  notifications?: Maybe<ListFilterInputTypeOfMobileNotificationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type DriverBidSortInput = {
  price?: Maybe<SortEnumType>;
  eta?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  isAllocated?: Maybe<SortEnumType>;
  driverId?: Maybe<SortEnumType>;
  driver?: Maybe<DriverSortInput>;
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  vehicleId?: Maybe<SortEnumType>;
  vehicle?: Maybe<VehicleSortInput>;
  currencyId?: Maybe<SortEnumType>;
  currency?: Maybe<CurrencySortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type DriverBidsPagedConnection = {
  __typename?: 'DriverBidsPagedConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<DriverBidsPagedEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<DriverBid>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DriverBidsPagedEdge = {
  __typename?: 'DriverBidsPagedEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DriverBid;
};

export type DriverContact = {
  __typename?: 'DriverContact';
  driverId: Scalars['Long'];
  driver: Driver;
  isDefault: Scalars['Boolean'];
  telephoneNumber: Scalars['String'];
  email: Scalars['String'];
  additionalEmails: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobRole?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type DriverContactFilterInput = {
  and?: Maybe<Array<DriverContactFilterInput>>;
  or?: Maybe<Array<DriverContactFilterInput>>;
  driverId?: Maybe<LongOperationFilterInput>;
  driver?: Maybe<DriverFilterInput>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  telephoneNumber?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  additionalEmails?: Maybe<StringOperationFilterInput>;
  department?: Maybe<StringOperationFilterInput>;
  jobRole?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type DriverContactSortInput = {
  driverId?: Maybe<SortEnumType>;
  driver?: Maybe<DriverSortInput>;
  isDefault?: Maybe<SortEnumType>;
  telephoneNumber?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  additionalEmails?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  jobRole?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type DriverContactsConnection = {
  __typename?: 'DriverContactsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<DriverContactsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<DriverContact>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DriverContactsEdge = {
  __typename?: 'DriverContactsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DriverContact;
};

export type DriverFilterInput = {
  and?: Maybe<Array<DriverFilterInput>>;
  or?: Maybe<Array<DriverFilterInput>>;
  primaryDriverId?: Maybe<LongOperationFilterInput>;
  primaryDriver?: Maybe<DriverFilterInput>;
  subDrivers?: Maybe<ListFilterInputTypeOfDriverFilterInput>;
  driverType?: Maybe<DriverTypeOperationFilterInput>;
  xeroCodeId?: Maybe<LongOperationFilterInput>;
  xeroCode?: Maybe<XeroCodeFilterInput>;
  callSign?: Maybe<StringOperationFilterInput>;
  courierExchangeMemberId?: Maybe<StringOperationFilterInput>;
  dateOfBirth?: Maybe<DateTimeOperationFilterInput>;
  xeroPurchaseCode?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  postcode?: Maybe<StringOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  addressLine1?: Maybe<StringOperationFilterInput>;
  addressLine2?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  countryId?: Maybe<LongOperationFilterInput>;
  country?: Maybe<CountryFilterInput>;
  currencyId?: Maybe<LongOperationFilterInput>;
  currency?: Maybe<CurrencyFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  telephoneNumber?: Maybe<StringOperationFilterInput>;
  startDate?: Maybe<DateTimeOperationFilterInput>;
  leavingDate?: Maybe<DateTimeOperationFilterInput>;
  paymentTerm?: Maybe<PaymentTermOperationFilterInput>;
  isUlezCharge?: Maybe<BooleanOperationFilterInput>;
  useDefaultRate?: Maybe<BooleanOperationFilterInput>;
  distanceRate?: Maybe<DecimalOperationFilterInput>;
  minMiles?: Maybe<DecimalOperationFilterInput>;
  minCharge?: Maybe<DecimalOperationFilterInput>;
  publicLiabilityExpirationDate?: Maybe<DateTimeOperationFilterInput>;
  isEmailNotification?: Maybe<BooleanOperationFilterInput>;
  isMobileAppNotification?: Maybe<BooleanOperationFilterInput>;
  vehicles?: Maybe<ListFilterInputTypeOfVehicleFilterInput>;
  bookings?: Maybe<ListFilterInputTypeOfBookingDriverFilterInput>;
  contacts?: Maybe<ListFilterInputTypeOfDriverContactFilterInput>;
  driverBids?: Maybe<ListFilterInputTypeOfDriverBidFilterInput>;
  licenseType?: Maybe<DriverLicenseTypeOperationFilterInput>;
  licenseExpiryDate?: Maybe<DateTimeOperationFilterInput>;
  endorsements?: Maybe<StringOperationFilterInput>;
  nationalInsuranceNumber?: Maybe<StringOperationFilterInput>;
  paymentMethodId?: Maybe<LongOperationFilterInput>;
  paymentMethod?: Maybe<PaymentMethodFilterInput>;
  lastPaid?: Maybe<DateTimeOperationFilterInput>;
  vatCategoryId?: Maybe<LongOperationFilterInput>;
  vatCategory?: Maybe<VatCategoryFilterInput>;
  bankName?: Maybe<StringOperationFilterInput>;
  bankAccountNumber?: Maybe<StringOperationFilterInput>;
  creditCard?: Maybe<StringOperationFilterInput>;
  payFrequency?: Maybe<PayFrequencyOperationFilterInput>;
  chequeName?: Maybe<StringOperationFilterInput>;
  isGenerateSeparateInvoice?: Maybe<BooleanOperationFilterInput>;
  vatIdNumber?: Maybe<StringOperationFilterInput>;
  swift?: Maybe<StringOperationFilterInput>;
  eoriNumber?: Maybe<StringOperationFilterInput>;
  bankSortCode?: Maybe<StringOperationFilterInput>;
  employeeReference?: Maybe<StringOperationFilterInput>;
  isEmailInvoice?: Maybe<BooleanOperationFilterInput>;
  invoiceEmail?: Maybe<StringOperationFilterInput>;
  isTrackingEnabled?: Maybe<BooleanOperationFilterInput>;
  identityUserName?: Maybe<StringOperationFilterInput>;
  packages?: Maybe<ListFilterInputTypeOfDriverPackageFilterInput>;
  isUsingInternalApp?: Maybe<BooleanOperationFilterInput>;
  locationId?: Maybe<LongOperationFilterInput>;
  location?: Maybe<DriverLocationFilterInput>;
  notificationTokens?: Maybe<ListFilterInputTypeOfDeviceNotificationTokenFilterInput>;
  isOnboardingCompleted?: Maybe<BooleanOperationFilterInput>;
  isMobileOnboardingCompleted?: Maybe<BooleanOperationFilterInput>;
  alternativeTelephoneNumber?: Maybe<StringOperationFilterInput>;
  bankAccountHolderName?: Maybe<StringOperationFilterInput>;
  internationalBankAccountNumber?: Maybe<StringOperationFilterInput>;
  averageRating?: Maybe<DecimalOperationFilterInput>;
  companyRegistrationNumber?: Maybe<StringOperationFilterInput>;
  notifications?: Maybe<ListFilterInputTypeOfMobileNotificationFilterInput>;
  preferredLanguage?: Maybe<LanguageCodeOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum DriverLicenseType {
  Hgv1 = 'HGV1',
  Hgv2 = 'HGV2',
  Hgv3 = 'HGV3',
  Motorbike = 'MOTORBIKE',
  Standard = 'STANDARD'
}

export type DriverLicenseTypeOperationFilterInput = {
  eq?: Maybe<DriverLicenseType>;
  neq?: Maybe<DriverLicenseType>;
  in?: Maybe<Array<DriverLicenseType>>;
  nin?: Maybe<Array<DriverLicenseType>>;
};

export type DriverListReportInputDtoInput = {
  driverType?: Maybe<DriverType>;
};

export type DriverLocation = {
  __typename?: 'DriverLocation';
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  altitude?: Maybe<Scalars['String']>;
  accuracy?: Maybe<Scalars['String']>;
  altitudeAccuracy?: Maybe<Scalars['String']>;
  heading?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['String']>;
  timeStamp: Scalars['String'];
  driverId?: Maybe<Scalars['Long']>;
  driver?: Maybe<Driver>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  driverTrackingStatus: DriverTrackingStatusDto;
};

export type DriverLocationFilterInput = {
  and?: Maybe<Array<DriverLocationFilterInput>>;
  or?: Maybe<Array<DriverLocationFilterInput>>;
  latitude?: Maybe<StringOperationFilterInput>;
  longitude?: Maybe<StringOperationFilterInput>;
  altitude?: Maybe<StringOperationFilterInput>;
  accuracy?: Maybe<StringOperationFilterInput>;
  altitudeAccuracy?: Maybe<StringOperationFilterInput>;
  heading?: Maybe<StringOperationFilterInput>;
  speed?: Maybe<StringOperationFilterInput>;
  timeStamp?: Maybe<StringOperationFilterInput>;
  driverId?: Maybe<LongOperationFilterInput>;
  driver?: Maybe<DriverFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type DriverLocationSortInput = {
  latitude?: Maybe<SortEnumType>;
  longitude?: Maybe<SortEnumType>;
  altitude?: Maybe<SortEnumType>;
  accuracy?: Maybe<SortEnumType>;
  altitudeAccuracy?: Maybe<SortEnumType>;
  heading?: Maybe<SortEnumType>;
  speed?: Maybe<SortEnumType>;
  timeStamp?: Maybe<SortEnumType>;
  driverId?: Maybe<SortEnumType>;
  driver?: Maybe<DriverSortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type DriverPackage = {
  __typename?: 'DriverPackage';
  driverId: Scalars['Long'];
  driver: Driver;
  packageId: Scalars['Long'];
  package: Package;
  quantity: Scalars['Int'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type DriverPackageFilterInput = {
  and?: Maybe<Array<DriverPackageFilterInput>>;
  or?: Maybe<Array<DriverPackageFilterInput>>;
  driverId?: Maybe<LongOperationFilterInput>;
  driver?: Maybe<DriverFilterInput>;
  packageId?: Maybe<LongOperationFilterInput>;
  package?: Maybe<PackageFilterInput>;
  quantity?: Maybe<IntOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type DriverProfileDto = {
  __typename?: 'DriverProfileDto';
  id: Scalars['Long'];
  driverType: DriverType;
  vehicles: Array<Vehicle>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  callSign?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  primaryDriverId?: Maybe<Scalars['Long']>;
  primaryDriverName?: Maybe<Scalars['String']>;
  countryId: Scalars['ID'];
  country: Country;
  email: Scalars['String'];
  contactEmail: Scalars['String'];
  telephoneNumber: Scalars['String'];
  paymentTerm: PaymentTerm;
  paymentTermDescription: Scalars['String'];
  isMobileAppNotification?: Maybe<Scalars['Boolean']>;
  isEmailNotification?: Maybe<Scalars['Boolean']>;
  isTrackingEnabled: Scalars['Boolean'];
  licenseType: DriverLicenseType;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  nationalInsuranceNumber?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<PaymentMethod>;
  vatCategoryId?: Maybe<Scalars['ID']>;
  vatCategory?: Maybe<VatCategory>;
  bankName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  creditCard?: Maybe<Scalars['String']>;
  payFrequency: PayFrequency;
  chequeName?: Maybe<Scalars['String']>;
  bankSortCode?: Maybe<Scalars['String']>;
  isEmailInvoice: Scalars['Boolean'];
  useDefaultRate?: Maybe<Scalars['Boolean']>;
  distanceRate?: Maybe<Scalars['Decimal']>;
  minMiles?: Maybe<Scalars['Decimal']>;
  minCharge?: Maybe<Scalars['Decimal']>;
  userName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailConfirmed: Scalars['Boolean'];
  profilePhoto?: Maybe<Scalars['String']>;
  pushNotificationToken?: Maybe<Scalars['String']>;
  isMobileOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  isOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  preferredLanguage?: Maybe<LanguageCode>;
};

export type DriverSortInput = {
  primaryDriverId?: Maybe<SortEnumType>;
  primaryDriver?: Maybe<DriverSortInput>;
  driverType?: Maybe<SortEnumType>;
  xeroCodeId?: Maybe<SortEnumType>;
  xeroCode?: Maybe<XeroCodeSortInput>;
  callSign?: Maybe<SortEnumType>;
  courierExchangeMemberId?: Maybe<SortEnumType>;
  dateOfBirth?: Maybe<SortEnumType>;
  xeroPurchaseCode?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  postcode?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  addressLine1?: Maybe<SortEnumType>;
  addressLine2?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  countryId?: Maybe<SortEnumType>;
  country?: Maybe<CountrySortInput>;
  currencyId?: Maybe<SortEnumType>;
  currency?: Maybe<CurrencySortInput>;
  email?: Maybe<SortEnumType>;
  telephoneNumber?: Maybe<SortEnumType>;
  startDate?: Maybe<SortEnumType>;
  leavingDate?: Maybe<SortEnumType>;
  paymentTerm?: Maybe<SortEnumType>;
  isUlezCharge?: Maybe<SortEnumType>;
  useDefaultRate?: Maybe<SortEnumType>;
  distanceRate?: Maybe<SortEnumType>;
  minMiles?: Maybe<SortEnumType>;
  minCharge?: Maybe<SortEnumType>;
  publicLiabilityExpirationDate?: Maybe<SortEnumType>;
  isEmailNotification?: Maybe<SortEnumType>;
  isMobileAppNotification?: Maybe<SortEnumType>;
  licenseType?: Maybe<SortEnumType>;
  licenseExpiryDate?: Maybe<SortEnumType>;
  endorsements?: Maybe<SortEnumType>;
  nationalInsuranceNumber?: Maybe<SortEnumType>;
  paymentMethodId?: Maybe<SortEnumType>;
  paymentMethod?: Maybe<PaymentMethodSortInput>;
  lastPaid?: Maybe<SortEnumType>;
  vatCategoryId?: Maybe<SortEnumType>;
  vatCategory?: Maybe<VatCategorySortInput>;
  bankName?: Maybe<SortEnumType>;
  bankAccountNumber?: Maybe<SortEnumType>;
  creditCard?: Maybe<SortEnumType>;
  payFrequency?: Maybe<SortEnumType>;
  chequeName?: Maybe<SortEnumType>;
  isGenerateSeparateInvoice?: Maybe<SortEnumType>;
  vatIdNumber?: Maybe<SortEnumType>;
  swift?: Maybe<SortEnumType>;
  eoriNumber?: Maybe<SortEnumType>;
  bankSortCode?: Maybe<SortEnumType>;
  employeeReference?: Maybe<SortEnumType>;
  isEmailInvoice?: Maybe<SortEnumType>;
  invoiceEmail?: Maybe<SortEnumType>;
  isTrackingEnabled?: Maybe<SortEnumType>;
  identityUserName?: Maybe<SortEnumType>;
  isUsingInternalApp?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
  location?: Maybe<DriverLocationSortInput>;
  isOnboardingCompleted?: Maybe<SortEnumType>;
  isMobileOnboardingCompleted?: Maybe<SortEnumType>;
  alternativeTelephoneNumber?: Maybe<SortEnumType>;
  bankAccountHolderName?: Maybe<SortEnumType>;
  internationalBankAccountNumber?: Maybe<SortEnumType>;
  averageRating?: Maybe<SortEnumType>;
  companyRegistrationNumber?: Maybe<SortEnumType>;
  preferredLanguage?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum DriverStatus {
  Unknown = 'UNKNOWN',
  OnTime = 'ON_TIME',
  Risk = 'RISK',
  Late = 'LATE'
}

export type DriverStatusDto = {
  __typename?: 'DriverStatusDto';
  id: Scalars['UUID'];
  status: DriverStatus;
  statusLabel: Scalars['String'];
  date?: Maybe<Scalars['DateTime']>;
  postcode?: Maybe<Scalars['String']>;
};

export type DriverStatusDtoFilterInput = {
  and?: Maybe<Array<DriverStatusDtoFilterInput>>;
  or?: Maybe<Array<DriverStatusDtoFilterInput>>;
  id?: Maybe<UuidOperationFilterInput>;
  status?: Maybe<DriverStatusOperationFilterInput>;
  statusLabel?: Maybe<StringOperationFilterInput>;
  date?: Maybe<DateTimeOperationFilterInput>;
  postcode?: Maybe<StringOperationFilterInput>;
};

export type DriverStatusDtoSortInput = {
  id?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  statusLabel?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  postcode?: Maybe<SortEnumType>;
};

export type DriverStatusOperationFilterInput = {
  eq?: Maybe<DriverStatus>;
  neq?: Maybe<DriverStatus>;
  in?: Maybe<Array<DriverStatus>>;
  nin?: Maybe<Array<DriverStatus>>;
};

export type DriverTrackingDto = {
  __typename?: 'DriverTrackingDto';
  id: Scalars['UUID'];
  longitude: Scalars['String'];
  latitude: Scalars['String'];
  nextStopGoogleMapsPlaceId?: Maybe<Scalars['String']>;
};

export type DriverTrackingStatusDto = {
  __typename?: 'DriverTrackingStatusDto';
  id: Scalars['UUID'];
  allocated: Scalars['String'];
  nextStopPostCode: Scalars['String'];
  nextStopGoogleMapsPlaceId?: Maybe<Scalars['String']>;
  nextStopDateTime?: Maybe<Scalars['DateTime']>;
  nextStopLiveEta?: Maybe<Scalars['DateTime']>;
};

export enum DriverType {
  Staff = 'STAFF',
  Contract = 'CONTRACT',
  Cx = 'CX',
  Other = 'OTHER'
}

export type DriverTypeOperationFilterInput = {
  eq?: Maybe<DriverType>;
  neq?: Maybe<DriverType>;
  in?: Maybe<Array<DriverType>>;
  nin?: Maybe<Array<DriverType>>;
};

export type DriverUserProfileDto = {
  __typename?: 'DriverUserProfileDto';
  id: Scalars['Long'];
  userName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullName: Scalars['String'];
  email: Scalars['String'];
};

export type DriverWaitTime = {
  __typename?: 'DriverWaitTime';
  name: Scalars['String'];
  bookingAddressId: Scalars['Long'];
  bookingAddress: BookingAddress;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type DriverWaitTimeDto = {
  __typename?: 'DriverWaitTimeDto';
  waitTime: DriverWaitTime;
  signature?: Maybe<File>;
};

export type DriverWaitTimeFilterInput = {
  and?: Maybe<Array<DriverWaitTimeFilterInput>>;
  or?: Maybe<Array<DriverWaitTimeFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  bookingAddressId?: Maybe<LongOperationFilterInput>;
  bookingAddress?: Maybe<BookingAddressFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

/** A connection to a list of items. */
export type DriversConnection = {
  __typename?: 'DriversConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<DriversEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Driver>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DriversEdge = {
  __typename?: 'DriversEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Driver;
};

export type Email = {
  __typename?: 'Email';
  subject: Scalars['String'];
  to: Scalars['String'];
  body: Scalars['String'];
  cc?: Maybe<Scalars['String']>;
  bcc?: Maybe<Scalars['String']>;
  isHtml: Scalars['Boolean'];
  emailTemplateId?: Maybe<Scalars['Long']>;
  emailTemplate?: Maybe<EmailTemplate>;
  attachment: EmailAttachment;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type EmailAttachment = {
  __typename?: 'EmailAttachment';
  filename: Scalars['String'];
  contentType: Scalars['String'];
  emailId: Scalars['Long'];
  email: Email;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type EmailAttachmentFilterInput = {
  and?: Maybe<Array<EmailAttachmentFilterInput>>;
  or?: Maybe<Array<EmailAttachmentFilterInput>>;
  filename?: Maybe<StringOperationFilterInput>;
  contentType?: Maybe<StringOperationFilterInput>;
  emailId?: Maybe<LongOperationFilterInput>;
  email?: Maybe<EmailFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type EmailFilterInput = {
  and?: Maybe<Array<EmailFilterInput>>;
  or?: Maybe<Array<EmailFilterInput>>;
  subject?: Maybe<StringOperationFilterInput>;
  to?: Maybe<StringOperationFilterInput>;
  body?: Maybe<StringOperationFilterInput>;
  cc?: Maybe<StringOperationFilterInput>;
  bcc?: Maybe<StringOperationFilterInput>;
  isHtml?: Maybe<BooleanOperationFilterInput>;
  emailTemplateId?: Maybe<LongOperationFilterInput>;
  emailTemplate?: Maybe<EmailTemplateFilterInput>;
  attachment?: Maybe<EmailAttachmentFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum EmailSentStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
  BookingHasNotificationsDisabled = 'BOOKING_HAS_NOTIFICATIONS_DISABLED',
  CustomerHasNotificationDisabled = 'CUSTOMER_HAS_NOTIFICATION_DISABLED',
  DriverHasNotificationsDisabled = 'DRIVER_HAS_NOTIFICATIONS_DISABLED',
  EmailIsNotProvided = 'EMAIL_IS_NOT_PROVIDED'
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  tokens: Array<EmailTemplateToken>;
  type: EmailTemplateType;
  isBlindCarbonCopyEnabled: Scalars['Boolean'];
  blindCarbonCopy?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  message: Scalars['String'];
  iconUri?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Email>>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type EmailTemplateFilterInput = {
  and?: Maybe<Array<EmailTemplateFilterInput>>;
  or?: Maybe<Array<EmailTemplateFilterInput>>;
  type?: Maybe<EmailTemplateTypeOperationFilterInput>;
  isBlindCarbonCopyEnabled?: Maybe<BooleanOperationFilterInput>;
  blindCarbonCopy?: Maybe<StringOperationFilterInput>;
  subject?: Maybe<StringOperationFilterInput>;
  message?: Maybe<StringOperationFilterInput>;
  iconUri?: Maybe<StringOperationFilterInput>;
  emails?: Maybe<ListFilterInputTypeOfEmailFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type EmailTemplateSortInput = {
  type?: Maybe<SortEnumType>;
  isBlindCarbonCopyEnabled?: Maybe<SortEnumType>;
  blindCarbonCopy?: Maybe<SortEnumType>;
  subject?: Maybe<SortEnumType>;
  message?: Maybe<SortEnumType>;
  iconUri?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum EmailTemplateToken {
  BookingNo = 'BOOKING_NO',
  CustomerLogo = 'CUSTOMER_LOGO',
  Contact = 'CONTACT',
  CustomerName = 'CUSTOMER_NAME',
  CustomerRef = 'CUSTOMER_REF',
  DriverName = 'DRIVER_NAME',
  EtaCollection = 'ETA_COLLECTION',
  EtaDelivery = 'ETA_DELIVERY',
  InvoiceNo = 'INVOICE_NO',
  NetPrice = 'NET_PRICE',
  OtherCharges = 'OTHER_CHARGES',
  QuoteNo = 'QUOTE_NO',
  SignatureIcon = 'SIGNATURE_ICON',
  TrackingPageUrl = 'TRACKING_PAGE_URL'
}

export type EmailTemplateTokenDto = {
  __typename?: 'EmailTemplateTokenDto';
  value: EmailTemplateToken;
  token: Scalars['String'];
  description: Scalars['String'];
  numericValue: Scalars['Int'];
};

export enum EmailTemplateType {
  CustomerBooking = 'CUSTOMER_BOOKING',
  CustomerQuotation = 'CUSTOMER_QUOTATION',
  CustomerPackageOnBoardArrival = 'CUSTOMER_PACKAGE_ON_BOARD_ARRIVAL',
  CustomerPackageOnBoard = 'CUSTOMER_PACKAGE_ON_BOARD',
  CustomerProofOfDeliveryArrival = 'CUSTOMER_PROOF_OF_DELIVERY_ARRIVAL',
  CustomerProofOfDelivery = 'CUSTOMER_PROOF_OF_DELIVERY',
  CustomerBookingCancellation = 'CUSTOMER_BOOKING_CANCELLATION',
  CustomerInvoice = 'CUSTOMER_INVOICE',
  CustomerCarded = 'CUSTOMER_CARDED',
  CustomerBookingAmend = 'CUSTOMER_BOOKING_AMEND',
  CustomerDriverAllocated = 'CUSTOMER_DRIVER_ALLOCATED',
  CustomerProofOfDeliveryPartial = 'CUSTOMER_PROOF_OF_DELIVERY_PARTIAL',
  CustomerBookingReadyForAllocation = 'CUSTOMER_BOOKING_READY_FOR_ALLOCATION',
  DriverDocket = 'DRIVER_DOCKET',
  DriverInvoice = 'DRIVER_INVOICE',
  DriverInvoiceRemittance = 'DRIVER_INVOICE_REMITTANCE',
  DriverStaff = 'DRIVER_STAFF',
  Signature = 'SIGNATURE',
  SignatureDriver = 'SIGNATURE_DRIVER',
  TermsDriver = 'TERMS_DRIVER',
  DeliveryNote = 'DELIVERY_NOTE',
  CustomerDeleteBooking = 'CUSTOMER_DELETE_BOOKING',
  DriverDeleteBooking = 'DRIVER_DELETE_BOOKING',
  DriverIsLate = 'DRIVER_IS_LATE',
  VehicleRenewalReminder = 'VEHICLE_RENEWAL_REMINDER',
  ApDueDateAlert = 'AP_DUE_DATE_ALERT',
  Remittance = 'REMITTANCE',
  DriverCheckIn = 'DRIVER_CHECK_IN'
}

export type EmailTemplateTypeOperationFilterInput = {
  eq?: Maybe<EmailTemplateType>;
  neq?: Maybe<EmailTemplateType>;
  in?: Maybe<Array<EmailTemplateType>>;
  nin?: Maybe<Array<EmailTemplateType>>;
};

export type ExceptionReason = {
  __typename?: 'ExceptionReason';
  bookingAddressId: Scalars['Long'];
  reasonType: ExceptionReasonType;
  bookingAddress: BookingAddress;
  comments: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ExceptionReasonDto = {
  __typename?: 'ExceptionReasonDto';
  exception: ExceptionReason;
  photo?: Maybe<File>;
};

export type ExceptionReasonFilterInput = {
  and?: Maybe<Array<ExceptionReasonFilterInput>>;
  or?: Maybe<Array<ExceptionReasonFilterInput>>;
  bookingAddressId?: Maybe<LongOperationFilterInput>;
  reasonType?: Maybe<ExceptionReasonTypeOperationFilterInput>;
  bookingAddress?: Maybe<BookingAddressFilterInput>;
  comments?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum ExceptionReasonType {
  SafeAsInstructed = 'SAFE_AS_INSTRUCTED',
  Canceled = 'CANCELED'
}

export type ExceptionReasonTypeOperationFilterInput = {
  eq?: Maybe<ExceptionReasonType>;
  neq?: Maybe<ExceptionReasonType>;
  in?: Maybe<Array<ExceptionReasonType>>;
  nin?: Maybe<Array<ExceptionReasonType>>;
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  sourceCurrencyId: Scalars['Long'];
  sourceCurrency: Currency;
  targetCurrencyId: Scalars['Long'];
  targetCurrency: Currency;
  rate: Scalars['Decimal'];
  bookingExchangeRates?: Maybe<Array<BookingExchangeRate>>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ExchangeRateFilterInput = {
  and?: Maybe<Array<ExchangeRateFilterInput>>;
  or?: Maybe<Array<ExchangeRateFilterInput>>;
  sourceCurrencyId?: Maybe<LongOperationFilterInput>;
  sourceCurrency?: Maybe<CurrencyFilterInput>;
  targetCurrencyId?: Maybe<LongOperationFilterInput>;
  targetCurrency?: Maybe<CurrencyFilterInput>;
  rate?: Maybe<DecimalOperationFilterInput>;
  bookingExchangeRates?: Maybe<ListFilterInputTypeOfBookingExchangeRateFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ExchangeRateSortInput = {
  sourceCurrencyId?: Maybe<SortEnumType>;
  sourceCurrency?: Maybe<CurrencySortInput>;
  targetCurrencyId?: Maybe<SortEnumType>;
  targetCurrency?: Maybe<CurrencySortInput>;
  rate?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ExchangeRatesConnection = {
  __typename?: 'ExchangeRatesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ExchangeRatesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ExchangeRate>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ExchangeRatesEdge = {
  __typename?: 'ExchangeRatesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ExchangeRate;
};

export type ExternalDriverInvoice = {
  __typename?: 'ExternalDriverInvoice';
  fileId: Scalars['Long'];
  file: File;
  bookingInvoiceId?: Maybe<Scalars['Long']>;
  bookingInvoice?: Maybe<BookingInvoice>;
  companyName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  poAddressLine1?: Maybe<Scalars['String']>;
  poAddressLine2?: Maybe<Scalars['String']>;
  poAddressLine3?: Maybe<Scalars['String']>;
  poAddressLine4?: Maybe<Scalars['String']>;
  poCity?: Maybe<Scalars['String']>;
  poRegion?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  poCountry?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  inventoryItemCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  invoiceValue?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ExternalDriverInvoiceFilterInput = {
  and?: Maybe<Array<ExternalDriverInvoiceFilterInput>>;
  or?: Maybe<Array<ExternalDriverInvoiceFilterInput>>;
  fileId?: Maybe<LongOperationFilterInput>;
  file?: Maybe<FileFilterInput>;
  bookingInvoiceId?: Maybe<LongOperationFilterInput>;
  bookingInvoice?: Maybe<BookingInvoiceFilterInput>;
  companyName?: Maybe<StringOperationFilterInput>;
  emailAddress?: Maybe<StringOperationFilterInput>;
  poAddressLine1?: Maybe<StringOperationFilterInput>;
  poAddressLine2?: Maybe<StringOperationFilterInput>;
  poAddressLine3?: Maybe<StringOperationFilterInput>;
  poAddressLine4?: Maybe<StringOperationFilterInput>;
  poCity?: Maybe<StringOperationFilterInput>;
  poRegion?: Maybe<StringOperationFilterInput>;
  postcode?: Maybe<StringOperationFilterInput>;
  poCountry?: Maybe<StringOperationFilterInput>;
  invoiceNumber?: Maybe<StringOperationFilterInput>;
  reference?: Maybe<StringOperationFilterInput>;
  invoiceDate?: Maybe<StringOperationFilterInput>;
  dueDate?: Maybe<StringOperationFilterInput>;
  inventoryItemCode?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  quantity?: Maybe<StringOperationFilterInput>;
  invoiceValue?: Maybe<StringOperationFilterInput>;
  discount?: Maybe<StringOperationFilterInput>;
  service?: Maybe<StringOperationFilterInput>;
  taxType?: Maybe<StringOperationFilterInput>;
  vat?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ExternalDriverInvoiceSortInput = {
  fileId?: Maybe<SortEnumType>;
  file?: Maybe<FileSortInput>;
  bookingInvoiceId?: Maybe<SortEnumType>;
  bookingInvoice?: Maybe<BookingInvoiceSortInput>;
  companyName?: Maybe<SortEnumType>;
  emailAddress?: Maybe<SortEnumType>;
  poAddressLine1?: Maybe<SortEnumType>;
  poAddressLine2?: Maybe<SortEnumType>;
  poAddressLine3?: Maybe<SortEnumType>;
  poAddressLine4?: Maybe<SortEnumType>;
  poCity?: Maybe<SortEnumType>;
  poRegion?: Maybe<SortEnumType>;
  postcode?: Maybe<SortEnumType>;
  poCountry?: Maybe<SortEnumType>;
  invoiceNumber?: Maybe<SortEnumType>;
  reference?: Maybe<SortEnumType>;
  invoiceDate?: Maybe<SortEnumType>;
  dueDate?: Maybe<SortEnumType>;
  inventoryItemCode?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  quantity?: Maybe<SortEnumType>;
  invoiceValue?: Maybe<SortEnumType>;
  discount?: Maybe<SortEnumType>;
  service?: Maybe<SortEnumType>;
  taxType?: Maybe<SortEnumType>;
  vat?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type File = {
  __typename?: 'File';
  category: FileCategory;
  originalFileName: Scalars['String'];
  fileNameInStorage: Scalars['String'];
  size: Scalars['Long'];
  absoluteUri: Scalars['String'];
  entityReference?: Maybe<Scalars['String']>;
  entityReferenceId?: Maybe<Scalars['Long']>;
  externalFileId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileOrigin: FileOrigin;
  bookingAttachmentId?: Maybe<Scalars['Long']>;
  bookingAttachment?: Maybe<BookingAttachment>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  publicUri?: Maybe<Scalars['URL']>;
};

export enum FileCategory {
  Default = 'DEFAULT',
  Signature = 'SIGNATURE',
  BookingDocument = 'BOOKING_DOCUMENT',
  ProofOfDeliveryDocument = 'PROOF_OF_DELIVERY_DOCUMENT',
  StopDocument = 'STOP_DOCUMENT',
  Photo = 'PHOTO',
  ExternalDriverInvoice = 'EXTERNAL_DRIVER_INVOICE',
  DriverListImport = 'DRIVER_LIST_IMPORT',
  BookingListImport = 'BOOKING_LIST_IMPORT',
  DriversXeroCode = 'DRIVERS_XERO_CODE',
  TariffIcon = 'TARIFF_ICON',
  Banner = 'BANNER',
  PotentialBooking = 'POTENTIAL_BOOKING',
  AuditHistory = 'AUDIT_HISTORY'
}

export type FileCategoryOperationFilterInput = {
  eq?: Maybe<FileCategory>;
  neq?: Maybe<FileCategory>;
  in?: Maybe<Array<FileCategory>>;
  nin?: Maybe<Array<FileCategory>>;
};

export type FileFilterInput = {
  and?: Maybe<Array<FileFilterInput>>;
  or?: Maybe<Array<FileFilterInput>>;
  category?: Maybe<FileCategoryOperationFilterInput>;
  originalFileName?: Maybe<StringOperationFilterInput>;
  fileNameInStorage?: Maybe<StringOperationFilterInput>;
  size?: Maybe<LongOperationFilterInput>;
  absoluteUri?: Maybe<StringOperationFilterInput>;
  entityReference?: Maybe<StringOperationFilterInput>;
  entityReferenceId?: Maybe<LongOperationFilterInput>;
  externalFileId?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  fileOrigin?: Maybe<FileOriginOperationFilterInput>;
  bookingAttachmentId?: Maybe<LongOperationFilterInput>;
  bookingAttachment?: Maybe<BookingAttachmentFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum FileOrigin {
  Unknown = 'UNKNOWN',
  DriverApp = 'DRIVER_APP',
  CourierExchange = 'COURIER_EXCHANGE',
  UserUpload = 'USER_UPLOAD'
}

export type FileOriginOperationFilterInput = {
  eq?: Maybe<FileOrigin>;
  neq?: Maybe<FileOrigin>;
  in?: Maybe<Array<FileOrigin>>;
  nin?: Maybe<Array<FileOrigin>>;
};

export type FileSortInput = {
  category?: Maybe<SortEnumType>;
  originalFileName?: Maybe<SortEnumType>;
  fileNameInStorage?: Maybe<SortEnumType>;
  size?: Maybe<SortEnumType>;
  absoluteUri?: Maybe<SortEnumType>;
  entityReference?: Maybe<SortEnumType>;
  entityReferenceId?: Maybe<SortEnumType>;
  externalFileId?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  fileOrigin?: Maybe<SortEnumType>;
  bookingAttachmentId?: Maybe<SortEnumType>;
  bookingAttachment?: Maybe<BookingAttachmentSortInput>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type FilesConnection = {
  __typename?: 'FilesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<FilesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<File>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FilesEdge = {
  __typename?: 'FilesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: File;
};

export enum FixedJobDescriptionType {
  SameDayTimed = 'SAME_DAY_TIMED',
  SameDayNonTimed = 'SAME_DAY_NON_TIMED',
  NextDayTimed = 'NEXT_DAY_TIMED',
  NextDayNonTimed = 'NEXT_DAY_NON_TIMED',
  Value_3_5Days = 'VALUE_3_5_DAYS',
  MultiDrop = 'MULTI_DROP',
  DeliverDirect = 'DELIVER_DIRECT',
  Other = 'OTHER'
}

export enum FixedLoadDtoMinVehicleSize {
  Bike = 'BIKE',
  Car = 'CAR',
  SVan = 'S_VAN',
  MVan = 'M_VAN',
  Swb = 'SWB',
  Transit = 'TRANSIT',
  Lwb = 'LWB',
  Xlwb = 'XLWB',
  Luton = 'LUTON',
  Value_7_5T = 'VALUE_7_5_T',
  Value_12T = 'VALUE_12_T',
  Value_18T = 'VALUE_18_T',
  Value_26T = 'VALUE_26_T',
  Value_13_6M = 'VALUE_13_6_M',
  Skel_1X20 = 'SKEL_1X20',
  Skel_2X20 = 'SKEL_2X20',
  Skel_1X40 = 'SKEL_1X40',
  Skel_1X44 = 'SKEL_1X44',
  Value_4AxTrac = 'VALUE_4AX_TRAC',
  Value_6AxTrac = 'VALUE_6AX_TRAC',
  CargoVla = 'CARGO_VLA',
  Cargo = 'CARGO',
  CarPup = 'CAR_PUP',
  CargoVsm = 'CARGO_VSM',
  Sprinter = 'SPRINTER',
  Flatbed = 'FLATBED',
  Stakebed = 'STAKEBED',
  SStraight = 'S_STRAIGHT',
  LStraight = 'L_STRAIGHT',
  Tractor48 = 'TRACTOR48',
  Tractor53 = 'TRACTOR53',
  Superb = 'SUPERB',
  LowBoy = 'LOW_BOY',
  Tank = 'TANK',
  Other = 'OTHER',
  CargoV = 'CARGO_V',
  SprinterC = 'SPRINTER_C',
  StrTruck = 'STR_TRUCK',
  Tractor = 'TRACTOR',
  BikeOther = 'BIKE_OTHER',
  CarOther = 'CAR_OTHER',
  CargoSm = 'CARGO_SM',
  CargoLa = 'CARGO_LA',
  PupOp = 'PUP_OP',
  PupCl = 'PUP_CL',
  Suv = 'SUV',
  Flat = 'FLAT',
  Stake = 'STAKE',
  Box10 = 'BOX10',
  Box12 = 'BOX12',
  Box14 = 'BOX14',
  Box16 = 'BOX16',
  Box18 = 'BOX18',
  Box20 = 'BOX20',
  Box22 = 'BOX22',
  Box24 = 'BOX24',
  Box26 = 'BOX26',
  Box28 = 'BOX28',
  Box30 = 'BOX30',
  Box40 = 'BOX40',
  Box48 = 'BOX48',
  Box53 = 'BOX53'
}

export enum FixedOrderDtoPaymentTerm {
  Cod = 'COD',
  Value_30Fi = 'VALUE_30FI',
  Value_30Eom = 'VALUE_30EOM',
  Value_60Fi = 'VALUE_60FI',
  Value_07Fi = 'VALUE_07FI',
  Value_21Fi = 'VALUE_21FI',
  Value_14Fi = 'VALUE_14FI',
  Value_45Fi = 'VALUE_45FI',
  Value_45Eom = 'VALUE_45EOM'
}

export type FixedVolumeDtoInput = {
  units?: Maybe<VolumeUnitType>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  unit?: Maybe<LocalisedValueDtoInput>;
  width?: Maybe<Scalars['Float']>;
};

export type FixedWeightDtoInput = {
  units?: Maybe<WeightUnitType>;
  unit?: Maybe<LocalisedValueDtoInput>;
  value?: Maybe<Scalars['Float']>;
};

export type FloatOperationFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  gt?: Maybe<Scalars['Float']>;
  ngt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  ngte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  nlt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  nlte?: Maybe<Scalars['Float']>;
};

export type GenericReportOutputDto = {
  __typename?: 'GenericReportOutputDto';
  rows: Array<ReportRow>;
  title: Scalars['String'];
  fileName: Scalars['String'];
};

export type GetBookingEtaDtoInput = {
  bookingId: Scalars['Long'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GoogleAddressDtoInput = {
  sequenceOrder: Scalars['Int'];
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  postcode: Scalars['String'];
  countryId: Scalars['Long'];
  city: Scalars['String'];
};

export enum GoogleDistanceStatus {
  Error = 'ERROR',
  Success = 'SUCCESS'
}

export type GroupedAccountPayableRecordDto = {
  __typename?: 'GroupedAccountPayableRecordDto';
  id: Scalars['UUID'];
  driverId: Scalars['Long'];
  driverName?: Maybe<Scalars['String']>;
  invoiceCount: Scalars['Int'];
  dueDate?: Maybe<Array<Scalars['DateTime']>>;
  xeroPurchaseCode?: Maybe<Scalars['String']>;
  driverNumber?: Maybe<Scalars['String']>;
  courierExchangeLoadId?: Maybe<Scalars['String']>;
  ourReference?: Maybe<Scalars['Long']>;
  netAmounts?: Maybe<Array<Scalars['Decimal']>>;
  vatAmounts?: Maybe<Array<Scalars['Decimal']>>;
  grossAmounts?: Maybe<Array<Scalars['Decimal']>>;
  currencySymbols?: Maybe<Array<Scalars['String']>>;
  currencyAmountSum: Array<AccountPayableCurrencyAmountDto>;
  accountPayableStatus: Scalars['Int'];
  dueDateStart?: Maybe<Scalars['DateTime']>;
  dueDateEnd?: Maybe<Scalars['DateTime']>;
};

export type GroupedAccountPayableRecordDtoFilterInput = {
  and?: Maybe<Array<GroupedAccountPayableRecordDtoFilterInput>>;
  or?: Maybe<Array<GroupedAccountPayableRecordDtoFilterInput>>;
  id?: Maybe<UuidOperationFilterInput>;
  driverId?: Maybe<LongOperationFilterInput>;
  driverName?: Maybe<StringOperationFilterInput>;
  invoiceCount?: Maybe<IntOperationFilterInput>;
  dueDate?: Maybe<ListDateTimeOperationFilterInput>;
  xeroPurchaseCode?: Maybe<StringOperationFilterInput>;
  driverNumber?: Maybe<StringOperationFilterInput>;
  courierExchangeLoadId?: Maybe<StringOperationFilterInput>;
  ourReference?: Maybe<LongOperationFilterInput>;
  netAmounts?: Maybe<ListDecimalOperationFilterInput>;
  vatAmounts?: Maybe<ListDecimalOperationFilterInput>;
  grossAmounts?: Maybe<ListDecimalOperationFilterInput>;
  currencySymbols?: Maybe<ListStringOperationFilterInput>;
  currencyAmountSum?: Maybe<ListFilterInputTypeOfAccountPayableCurrencyAmountDtoFilterInput>;
  accountPayableStatus?: Maybe<IntOperationFilterInput>;
  dueDateStart?: Maybe<DateTimeOperationFilterInput>;
  dueDateEnd?: Maybe<DateTimeOperationFilterInput>;
};

export type GroupedAccountPayableRecordDtoSortInput = {
  id?: Maybe<SortEnumType>;
  driverId?: Maybe<SortEnumType>;
  driverName?: Maybe<SortEnumType>;
  invoiceCount?: Maybe<SortEnumType>;
  xeroPurchaseCode?: Maybe<SortEnumType>;
  driverNumber?: Maybe<SortEnumType>;
  courierExchangeLoadId?: Maybe<SortEnumType>;
  ourReference?: Maybe<SortEnumType>;
  accountPayableStatus?: Maybe<SortEnumType>;
  dueDateStart?: Maybe<SortEnumType>;
  dueDateEnd?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type GroupedAccountPayableRecordsConnection = {
  __typename?: 'GroupedAccountPayableRecordsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<GroupedAccountPayableRecordsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<GroupedAccountPayableRecordDto>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type GroupedAccountPayableRecordsEdge = {
  __typename?: 'GroupedAccountPayableRecordsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GroupedAccountPayableRecordDto;
};

export type GroupedPaymentRecordByMonthDto = {
  __typename?: 'GroupedPaymentRecordByMonthDto';
  id: Scalars['UUID'];
  scheduledDate: Scalars['DateTime'];
  totalAmount: Scalars['Decimal'];
  currencySymbol: Scalars['String'];
  statuses: Array<PaymentStatus>;
  invoiceCount: Scalars['Int'];
  bookingInvoices: Array<ScheduledPaymentBookingInvoice>;
};

export type GroupedPaymentRecordByMonthDtoFilterInput = {
  and?: Maybe<Array<GroupedPaymentRecordByMonthDtoFilterInput>>;
  or?: Maybe<Array<GroupedPaymentRecordByMonthDtoFilterInput>>;
  id?: Maybe<UuidOperationFilterInput>;
  scheduledDate?: Maybe<DateTimeOperationFilterInput>;
  totalAmount?: Maybe<DecimalOperationFilterInput>;
  currencySymbol?: Maybe<StringOperationFilterInput>;
  statuses?: Maybe<ListPaymentStatusOperationFilterInput>;
  invoiceCount?: Maybe<IntOperationFilterInput>;
  bookingInvoices?: Maybe<ListFilterInputTypeOfScheduledPaymentBookingInvoiceFilterInput>;
};

export type GroupedPaymentsInputParametersDtoInput = {
  driverId: Scalars['ID'];
  statuses?: Maybe<Array<PaymentStatus>>;
  paymentStatuses?: Maybe<Array<AccountPayableRecordStatus>>;
};

export type IdentityUserDetailsDto = {
  __typename?: 'IdentityUserDetailsDto';
  id: Scalars['Long'];
  userName: Scalars['String'];
  role: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  countryId: Scalars['Long'];
  city?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
  dob: Scalars['DateTime'];
  companyDetails?: Maybe<UserCompanyDetailsDto>;
  driverId?: Maybe<Scalars['Long']>;
  customerId?: Maybe<Scalars['Long']>;
  emailConfirmed: Scalars['Boolean'];
  suggestedCompany: Scalars['String'];
};

export type IdentityUserDto = {
  __typename?: 'IdentityUserDto';
  id: Scalars['Long'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  userName: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailConfirmed: Scalars['Boolean'];
  role?: Maybe<Scalars['String']>;
  lastLoggedIn?: Maybe<Scalars['DateTime']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  isEnabled: Scalars['Boolean'];
  isApproved: Scalars['Boolean'];
  approvedBy?: Maybe<Scalars['String']>;
  approvedDate?: Maybe<Scalars['DateTime']>;
  companyName?: Maybe<Scalars['String']>;
};

export type IdentityUserDtoFilterInput = {
  and?: Maybe<Array<IdentityUserDtoFilterInput>>;
  or?: Maybe<Array<IdentityUserDtoFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  userName?: Maybe<StringOperationFilterInput>;
  address?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  emailConfirmed?: Maybe<BooleanOperationFilterInput>;
  role?: Maybe<StringOperationFilterInput>;
  lastLoggedIn?: Maybe<DateTimeOperationFilterInput>;
  createdDate?: Maybe<DateTimeOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
  isApproved?: Maybe<BooleanOperationFilterInput>;
  approvedBy?: Maybe<StringOperationFilterInput>;
  approvedDate?: Maybe<DateTimeOperationFilterInput>;
  companyName?: Maybe<StringOperationFilterInput>;
};

export type IdentityUserDtoSortInput = {
  id?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  userName?: Maybe<SortEnumType>;
  address?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  emailConfirmed?: Maybe<SortEnumType>;
  role?: Maybe<SortEnumType>;
  lastLoggedIn?: Maybe<SortEnumType>;
  createdDate?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
  isApproved?: Maybe<SortEnumType>;
  approvedBy?: Maybe<SortEnumType>;
  approvedDate?: Maybe<SortEnumType>;
  companyName?: Maybe<SortEnumType>;
};

export type IntOperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gt?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  nlt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type InvoiceBookingsConnection = {
  __typename?: 'InvoiceBookingsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<InvoiceBookingsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Booking>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type InvoiceBookingsEdge = {
  __typename?: 'InvoiceBookingsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Booking;
};

export enum InvoiceFrequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY'
}

export type InvoiceFrequencyOperationFilterInput = {
  eq?: Maybe<InvoiceFrequency>;
  neq?: Maybe<InvoiceFrequency>;
  in?: Maybe<Array<InvoiceFrequency>>;
  nin?: Maybe<Array<InvoiceFrequency>>;
};

export type InvoiceTerm = {
  __typename?: 'InvoiceTerm';
  days: Scalars['Int'];
  type: InvoiceTermType;
  customers: Array<Customer>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type InvoiceTermFilterInput = {
  and?: Maybe<Array<InvoiceTermFilterInput>>;
  or?: Maybe<Array<InvoiceTermFilterInput>>;
  days?: Maybe<IntOperationFilterInput>;
  type?: Maybe<InvoiceTermTypeOperationFilterInput>;
  customers?: Maybe<ListFilterInputTypeOfCustomerFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type InvoiceTermSortInput = {
  days?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum InvoiceTermType {
  AfterInvoiceDate = 'AFTER_INVOICE_DATE',
  AfterMonthEnd = 'AFTER_MONTH_END'
}

export type InvoiceTermTypeOperationFilterInput = {
  eq?: Maybe<InvoiceTermType>;
  neq?: Maybe<InvoiceTermType>;
  in?: Maybe<Array<InvoiceTermType>>;
  nin?: Maybe<Array<InvoiceTermType>>;
};

export enum InvoiceType {
  Sale = 'SALE',
  Purchase = 'PURCHASE'
}

export type InvoiceTypeOperationFilterInput = {
  eq?: Maybe<InvoiceType>;
  neq?: Maybe<InvoiceType>;
  in?: Maybe<Array<InvoiceType>>;
  nin?: Maybe<Array<InvoiceType>>;
};

export enum LanguageCode {
  En = 'EN',
  Pl = 'PL',
  Es = 'ES',
  Ro = 'RO'
}

export type LanguageCodeOperationFilterInput = {
  eq?: Maybe<LanguageCode>;
  neq?: Maybe<LanguageCode>;
  in?: Maybe<Array<LanguageCode>>;
  nin?: Maybe<Array<LanguageCode>>;
};

export enum LegalAccountType {
  Business = 'BUSINESS',
  Personal = 'PERSONAL'
}

export type LegalAccountTypeOperationFilterInput = {
  eq?: Maybe<LegalAccountType>;
  neq?: Maybe<LegalAccountType>;
  in?: Maybe<Array<LegalAccountType>>;
  nin?: Maybe<Array<LegalAccountType>>;
};

export type ListAccountPayableRecordStatusOperationFilterInput = {
  all?: Maybe<AccountPayableRecordStatusOperationFilterInput>;
  none?: Maybe<AccountPayableRecordStatusOperationFilterInput>;
  some?: Maybe<AccountPayableRecordStatusOperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListDateTimeOperationFilterInput = {
  all?: Maybe<DateTimeOperationFilterInput>;
  none?: Maybe<DateTimeOperationFilterInput>;
  some?: Maybe<DateTimeOperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListDecimalOperationFilterInput = {
  all?: Maybe<DecimalOperationFilterInput>;
  none?: Maybe<DecimalOperationFilterInput>;
  some?: Maybe<DecimalOperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfAccountPayableCurrencyAmountDtoFilterInput = {
  all?: Maybe<AccountPayableCurrencyAmountDtoFilterInput>;
  none?: Maybe<AccountPayableCurrencyAmountDtoFilterInput>;
  some?: Maybe<AccountPayableCurrencyAmountDtoFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfAccountPayableRecordFilterInput = {
  all?: Maybe<AccountPayableRecordFilterInput>;
  none?: Maybe<AccountPayableRecordFilterInput>;
  some?: Maybe<AccountPayableRecordFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfAccountPayableRecordLogFilterInput = {
  all?: Maybe<AccountPayableRecordLogFilterInput>;
  none?: Maybe<AccountPayableRecordLogFilterInput>;
  some?: Maybe<AccountPayableRecordLogFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfActivityFilterInput = {
  all?: Maybe<ActivityFilterInput>;
  none?: Maybe<ActivityFilterInput>;
  some?: Maybe<ActivityFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfAddressFilterInput = {
  all?: Maybe<AddressFilterInput>;
  none?: Maybe<AddressFilterInput>;
  some?: Maybe<AddressFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingAddressFilterInput = {
  all?: Maybe<BookingAddressFilterInput>;
  none?: Maybe<BookingAddressFilterInput>;
  some?: Maybe<BookingAddressFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingAddressPackageFilterInput = {
  all?: Maybe<BookingAddressPackageFilterInput>;
  none?: Maybe<BookingAddressPackageFilterInput>;
  some?: Maybe<BookingAddressPackageFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingAttachmentFilterInput = {
  all?: Maybe<BookingAttachmentFilterInput>;
  none?: Maybe<BookingAttachmentFilterInput>;
  some?: Maybe<BookingAttachmentFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingChargeFilterInput = {
  all?: Maybe<BookingChargeFilterInput>;
  none?: Maybe<BookingChargeFilterInput>;
  some?: Maybe<BookingChargeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingDriverFilterInput = {
  all?: Maybe<BookingDriverFilterInput>;
  none?: Maybe<BookingDriverFilterInput>;
  some?: Maybe<BookingDriverFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingExchangeRateFilterInput = {
  all?: Maybe<BookingExchangeRateFilterInput>;
  none?: Maybe<BookingExchangeRateFilterInput>;
  some?: Maybe<BookingExchangeRateFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingFilterInput = {
  all?: Maybe<BookingFilterInput>;
  none?: Maybe<BookingFilterInput>;
  some?: Maybe<BookingFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingInvoiceBatchLineFilterInput = {
  all?: Maybe<BookingInvoiceBatchLineFilterInput>;
  none?: Maybe<BookingInvoiceBatchLineFilterInput>;
  some?: Maybe<BookingInvoiceBatchLineFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingInvoiceContentFilterInput = {
  all?: Maybe<BookingInvoiceContentFilterInput>;
  none?: Maybe<BookingInvoiceContentFilterInput>;
  some?: Maybe<BookingInvoiceContentFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingInvoiceFilterInput = {
  all?: Maybe<BookingInvoiceFilterInput>;
  none?: Maybe<BookingInvoiceFilterInput>;
  some?: Maybe<BookingInvoiceFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfBookingLogFilterInput = {
  all?: Maybe<BookingLogFilterInput>;
  none?: Maybe<BookingLogFilterInput>;
  some?: Maybe<BookingLogFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfCommentaryFilterInput = {
  all?: Maybe<CommentaryFilterInput>;
  none?: Maybe<CommentaryFilterInput>;
  some?: Maybe<CommentaryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfCongestionZonePostcodeFilterInput = {
  all?: Maybe<CongestionZonePostcodeFilterInput>;
  none?: Maybe<CongestionZonePostcodeFilterInput>;
  some?: Maybe<CongestionZonePostcodeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfCongestionZoneTariffFilterInput = {
  all?: Maybe<CongestionZoneTariffFilterInput>;
  none?: Maybe<CongestionZoneTariffFilterInput>;
  some?: Maybe<CongestionZoneTariffFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfContactFilterInput = {
  all?: Maybe<ContactFilterInput>;
  none?: Maybe<ContactFilterInput>;
  some?: Maybe<ContactFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfCustomerFilterInput = {
  all?: Maybe<CustomerFilterInput>;
  none?: Maybe<CustomerFilterInput>;
  some?: Maybe<CustomerFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfCustomerStandardOperatingProcedureFilterInput = {
  all?: Maybe<CustomerStandardOperatingProcedureFilterInput>;
  none?: Maybe<CustomerStandardOperatingProcedureFilterInput>;
  some?: Maybe<CustomerStandardOperatingProcedureFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfCustomerTariffFilterInput = {
  all?: Maybe<CustomerTariffFilterInput>;
  none?: Maybe<CustomerTariffFilterInput>;
  some?: Maybe<CustomerTariffFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfDeviceNotificationTokenFilterInput = {
  all?: Maybe<DeviceNotificationTokenFilterInput>;
  none?: Maybe<DeviceNotificationTokenFilterInput>;
  some?: Maybe<DeviceNotificationTokenFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfDriverBidFilterInput = {
  all?: Maybe<DriverBidFilterInput>;
  none?: Maybe<DriverBidFilterInput>;
  some?: Maybe<DriverBidFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfDriverContactFilterInput = {
  all?: Maybe<DriverContactFilterInput>;
  none?: Maybe<DriverContactFilterInput>;
  some?: Maybe<DriverContactFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfDriverFilterInput = {
  all?: Maybe<DriverFilterInput>;
  none?: Maybe<DriverFilterInput>;
  some?: Maybe<DriverFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfDriverPackageFilterInput = {
  all?: Maybe<DriverPackageFilterInput>;
  none?: Maybe<DriverPackageFilterInput>;
  some?: Maybe<DriverPackageFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfEmailFilterInput = {
  all?: Maybe<EmailFilterInput>;
  none?: Maybe<EmailFilterInput>;
  some?: Maybe<EmailFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfMobileNotificationFilterInput = {
  all?: Maybe<MobileNotificationFilterInput>;
  none?: Maybe<MobileNotificationFilterInput>;
  some?: Maybe<MobileNotificationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfNotificationFilterInput = {
  all?: Maybe<NotificationFilterInput>;
  none?: Maybe<NotificationFilterInput>;
  some?: Maybe<NotificationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfOpenQueryFilterInput = {
  all?: Maybe<OpenQueryFilterInput>;
  none?: Maybe<OpenQueryFilterInput>;
  some?: Maybe<OpenQueryFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfOtherChargeFilterInput = {
  all?: Maybe<OtherChargeFilterInput>;
  none?: Maybe<OtherChargeFilterInput>;
  some?: Maybe<OtherChargeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfPackageAllocationFilterInput = {
  all?: Maybe<PackageAllocationFilterInput>;
  none?: Maybe<PackageAllocationFilterInput>;
  some?: Maybe<PackageAllocationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfPackageFilterInput = {
  all?: Maybe<PackageFilterInput>;
  none?: Maybe<PackageFilterInput>;
  some?: Maybe<PackageFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfPaymentFilterInput = {
  all?: Maybe<PaymentFilterInput>;
  none?: Maybe<PaymentFilterInput>;
  some?: Maybe<PaymentFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfReasonCodeFilterInput = {
  all?: Maybe<ReasonCodeFilterInput>;
  none?: Maybe<ReasonCodeFilterInput>;
  some?: Maybe<ReasonCodeFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfScheduledBookingDateFilterInput = {
  all?: Maybe<ScheduledBookingDateFilterInput>;
  none?: Maybe<ScheduledBookingDateFilterInput>;
  some?: Maybe<ScheduledBookingDateFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfScheduledBookingTaskFilterInput = {
  all?: Maybe<ScheduledBookingTaskFilterInput>;
  none?: Maybe<ScheduledBookingTaskFilterInput>;
  some?: Maybe<ScheduledBookingTaskFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfScheduledExchangeRateTaskFilterInput = {
  all?: Maybe<ScheduledExchangeRateTaskFilterInput>;
  none?: Maybe<ScheduledExchangeRateTaskFilterInput>;
  some?: Maybe<ScheduledExchangeRateTaskFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfScheduledPaymentBookingInvoiceFilterInput = {
  all?: Maybe<ScheduledPaymentBookingInvoiceFilterInput>;
  none?: Maybe<ScheduledPaymentBookingInvoiceFilterInput>;
  some?: Maybe<ScheduledPaymentBookingInvoiceFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfStrategicPartnerContactFilterInput = {
  all?: Maybe<StrategicPartnerContactFilterInput>;
  none?: Maybe<StrategicPartnerContactFilterInput>;
  some?: Maybe<StrategicPartnerContactFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfTariffFilterInput = {
  all?: Maybe<TariffFilterInput>;
  none?: Maybe<TariffFilterInput>;
  some?: Maybe<TariffFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfTaskFilterInput = {
  all?: Maybe<TaskFilterInput>;
  none?: Maybe<TaskFilterInput>;
  some?: Maybe<TaskFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListFilterInputTypeOfVehicleFilterInput = {
  all?: Maybe<VehicleFilterInput>;
  none?: Maybe<VehicleFilterInput>;
  some?: Maybe<VehicleFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListPaymentStatusOperationFilterInput = {
  all?: Maybe<PaymentStatusOperationFilterInput>;
  none?: Maybe<PaymentStatusOperationFilterInput>;
  some?: Maybe<PaymentStatusOperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export type ListStringOperationFilterInput = {
  all?: Maybe<StringOperationFilterInput>;
  none?: Maybe<StringOperationFilterInput>;
  some?: Maybe<StringOperationFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
};

export enum LoadDtoBodyType {
  Na = 'NA',
  Box = 'BOX',
  Curtain = 'CURTAIN',
  Dbldeck = 'DBLDECK',
  Dropside = 'DROPSIDE',
  Highroof = 'HIGHROOF',
  Lowloader = 'LOWLOADER',
  Panel = 'PANEL',
  Refrigerated = 'REFRIGERATED',
  Sideloader = 'SIDELOADER',
  Tanker = 'TANKER',
  Tempcontr = 'TEMPCONTR',
  Tilt = 'TILT',
  Tipper = 'TIPPER',
  Trailer = 'TRAILER',
  Vehtransp = 'VEHTRANSP',
  Container = 'CONTAINER',
  Curtainside = 'CURTAINSIDE',
  Doubledrop = 'DOUBLEDROP',
  Flatbed = 'FLATBED',
  Power = 'POWER',
  Racktarp = 'RACKTARP',
  Reefer = 'REEFER',
  Stepdeck = 'STEPDECK',
  Tractortrailer = 'TRACTORTRAILER',
  Other = 'OTHER',
  Straight = 'STRAIGHT',
  Sprinterna = 'SPRINTERNA',
  Rgn = 'RGN',
  Curtaiside = 'CURTAISIDE',
  Conestoga = 'CONESTOGA',
  Cargo = 'CARGO',
  Dryvan = 'DRYVAN',
  Fridgesingle = 'FRIDGESINGLE',
  Fridgemulti = 'FRIDGEMULTI',
  Fridgesplit = 'FRIDGESPLIT',
  Megatrailer = 'MEGATRAILER'
}

export enum LoadDtoFreightType {
  Na = 'NA',
  Ambient = 'AMBIENT',
  Adr = 'ADR',
  AirFreightLevelAB = 'AIR_FREIGHT_LEVEL_A_B',
  AirFreightLevelD = 'AIR_FREIGHT_LEVEL_D',
  Refrigerated = 'REFRIGERATED',
  Container = 'CONTAINER',
  Foodstuffs = 'FOODSTUFFS',
  ForsBronze = 'FORS_BRONZE',
  ForsSilver = 'FORS_SILVER',
  ForsGold = 'FORS_GOLD',
  Fragile = 'FRAGILE',
  DgsaQualified = 'DGSA_QUALIFIED',
  HangingGarments = 'HANGING_GARMENTS',
  InstallationAndSwapout = 'INSTALLATION_AND_SWAPOUT',
  Livestock = 'LIVESTOCK',
  Loose = 'LOOSE',
  Removals = 'REMOVALS',
  Secure = 'SECURE',
  Frozen = 'FROZEN',
  Waste = 'WASTE',
  Weee = 'WEEE',
  GeneralCargo = 'GENERAL_CARGO',
  Hazmat = 'HAZMAT',
  Chilled = 'CHILLED',
  AirFreight = 'AIR_FREIGHT',
  Samples = 'SAMPLES',
  Cattle = 'CATTLE',
  Sheep = 'SHEEP',
  Pigs = 'PIGS',
  Goats = 'GOATS',
  BulkFeed = 'BULK_FEED',
  BaledFeed = 'BALED_FEED'
}

export enum LoadDtoJobType {
  Hotshot = 'HOTSHOT',
  Backload = 'BACKLOAD'
}

export enum LoadDtoStatus {
  Posted = 'POSTED',
  Booked = 'BOOKED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

export type LoadFormValuesDto = {
  __typename?: 'LoadFormValuesDto';
  freightTypes?: Maybe<Array<LocalisedValueDto>>;
  jobDescriptions?: Maybe<Array<LocalisedValueDto>>;
  packagings?: Maybe<Array<LocalisedValueDto>>;
  paymentTerms?: Maybe<Array<LocalisedValueDto>>;
  vehicleBodyTypes?: Maybe<Array<LocalisedValueDto>>;
  vehicleTypes?: Maybe<Array<LocalisedValueDto>>;
};

export type LocalisedValueDto = {
  __typename?: 'LocalisedValueDto';
  apiKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LocalisedValueDtoInput = {
  apiKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type LongOperationFilterInput = {
  eq?: Maybe<Scalars['Long']>;
  neq?: Maybe<Scalars['Long']>;
  in?: Maybe<Array<Maybe<Scalars['Long']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Long']>>>;
  gt?: Maybe<Scalars['Long']>;
  ngt?: Maybe<Scalars['Long']>;
  gte?: Maybe<Scalars['Long']>;
  ngte?: Maybe<Scalars['Long']>;
  lt?: Maybe<Scalars['Long']>;
  nlt?: Maybe<Scalars['Long']>;
  lte?: Maybe<Scalars['Long']>;
  nlte?: Maybe<Scalars['Long']>;
};

export type LoqateFindDto = {
  __typename?: 'LoqateFindDto';
  isContainer: Scalars['Boolean'];
  isRetrievable: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type LoqateFindDtoFilterInput = {
  and?: Maybe<Array<LoqateFindDtoFilterInput>>;
  or?: Maybe<Array<LoqateFindDtoFilterInput>>;
  id?: Maybe<StringOperationFilterInput>;
  type?: Maybe<StringOperationFilterInput>;
  text?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
};

export type LoqateFindDtoSortInput = {
  id?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
  text?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
};

export type LoqateRetrieveDto = {
  __typename?: 'LoqateRetrieveDto';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Long']>;
};

export type MakeBookingOnHoldDtoInput = {
  id: Scalars['ID'];
  isOnHold: Scalars['Boolean'];
};

export type MobileNotification = {
  __typename?: 'MobileNotification';
  driverId: Scalars['Long'];
  driver: Driver;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  deliveryStatus: NotificationDeliveryStatus;
  priority: NotificationPriority;
  category: NotificationCategory;
  bookingId?: Maybe<Scalars['Long']>;
  booking?: Maybe<Booking>;
  bidId?: Maybe<Scalars['Long']>;
  bid?: Maybe<DriverBid>;
  accountPaybleRecordId?: Maybe<Scalars['Long']>;
  payableRecord?: Maybe<AccountPayableRecord>;
  isSeen: Scalars['Boolean'];
  deliveryStatusLog?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type MobileNotificationFilterInput = {
  and?: Maybe<Array<MobileNotificationFilterInput>>;
  or?: Maybe<Array<MobileNotificationFilterInput>>;
  driverId?: Maybe<LongOperationFilterInput>;
  driver?: Maybe<DriverFilterInput>;
  title?: Maybe<StringOperationFilterInput>;
  subtitle?: Maybe<StringOperationFilterInput>;
  body?: Maybe<StringOperationFilterInput>;
  deliveryStatus?: Maybe<NotificationDeliveryStatusOperationFilterInput>;
  priority?: Maybe<NotificationPriorityOperationFilterInput>;
  category?: Maybe<NotificationCategoryOperationFilterInput>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  bidId?: Maybe<LongOperationFilterInput>;
  bid?: Maybe<DriverBidFilterInput>;
  accountPaybleRecordId?: Maybe<LongOperationFilterInput>;
  payableRecord?: Maybe<AccountPayableRecordFilterInput>;
  isSeen?: Maybe<BooleanOperationFilterInput>;
  deliveryStatusLog?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type MobileNotificationSortInput = {
  driverId?: Maybe<SortEnumType>;
  driver?: Maybe<DriverSortInput>;
  title?: Maybe<SortEnumType>;
  subtitle?: Maybe<SortEnumType>;
  body?: Maybe<SortEnumType>;
  deliveryStatus?: Maybe<SortEnumType>;
  priority?: Maybe<SortEnumType>;
  category?: Maybe<SortEnumType>;
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  bidId?: Maybe<SortEnumType>;
  bid?: Maybe<DriverBidSortInput>;
  accountPaybleRecordId?: Maybe<SortEnumType>;
  payableRecord?: Maybe<AccountPayableRecordSortInput>;
  isSeen?: Maybe<SortEnumType>;
  deliveryStatusLog?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateAccountPayableRecordStatus: AccountPayableRecord;
  updateAccountPayableRecordsStatusRangeToScheduledPayment: Scalars['Boolean'];
  updateDriverInformation?: Maybe<Driver>;
  updateAccountPayablesPaymentDate: Array<BookingInvoice>;
  refreshPaymentStatus: Array<AccountPayableRecord>;
  updateAccountPayableNetAmount: AccountPayableRecord;
  createAddress: Address;
  updateAddress: Address;
  deleteAddress: Array<Address>;
  verifyAddressDelivery: Scalars['Boolean'];
  createBookingCombined?: Maybe<Booking>;
  updateBookingCombined?: Maybe<Booking>;
  deleteBookingAddress?: Maybe<Booking>;
  deleteBookingCharge?: Maybe<Booking>;
  deleteBookingPackage?: Maybe<Booking>;
  cancelBooking: Booking;
  markQuoteLost: Booking;
  archiveBooking: Booking;
  makeBookingOnHold: Booking;
  confirmQuickQuote?: Maybe<Booking>;
  updateBookingStatus: Booking;
  copyBooking: Scalars['Long'];
  deleteBooking: Scalars['Boolean'];
  addException: ExceptionReason;
  bookingAddressCollectionComplete: BookingAddress;
  bookingAddressDeliveryComplete: BookingAddress;
  updateBookingPackageOnBoard: BookingAddress;
  updateBookingProofOfDelivery: BookingAddress;
  sendProofOfDeliveryEmail: EmailSentStatus;
  createInvoiceTask: Scalars['Boolean'];
  processTasks: Scalars['Boolean'];
  createBookingInvoice: Array<BookingInvoice>;
  createQuoteLostReason: QuoteLostReason;
  updateQuoteLostReason: QuoteLostReason;
  processPotentialBookingFile: Scalars['Boolean'];
  updateBookingAddressEta: BookingAddress;
  addWaitTime: DriverWaitTime;
  updateSequenceOrder?: Maybe<Booking>;
  updateAllBookingEtas: Scalars['Int'];
  deleteBookingAddressAttachment: Scalars['Boolean'];
  sendAddressContactEmail: EmailSentStatus;
  updateBookingAddressWaitingTime: BookingAddress;
  updateYourBookingAddressInformation: BookingAddress;
  updateBookingAddressPackages: BookingAddress;
  updateBookingAddressAdditionalDetails: BookingAddress;
  updateSignatureData: BookingAddress;
  createCommentary?: Maybe<Commentary>;
  updateCommentary?: Maybe<Commentary>;
  deleteCommentary: Scalars['Long'];
  createCongestionZone: CongestionZone;
  updateCongestionZone: CongestionZone;
  createCongestionZonePostcode: CongestionZonePostcode;
  updateCongestionZonePostcode: CongestionZonePostcode;
  deleteCongestionZonePostcode: Scalars['Boolean'];
  deleteCongestionZoneTariff: CongestionZone;
  postLoad: CourierExchangeLoad;
  bookLoad: CourierExchangeLoad;
  cancelLoad: CourierExchangeLoad;
  updateBookingStatusFromCourierExchange?: Maybe<Booking>;
  updateBookingListStatusFromCourierExchange: Array<Scalars['String']>;
  loadQuotes: Array<CourierExchangeQuoteDto>;
  createCustomer: Customer;
  updateCustomer: Customer;
  updateCustomerNotifications: Customer;
  updateCustomerReferencesAndNotes: Customer;
  updateCustomerProfileDetails: CustomerProfileDto;
  deleteCustomer: Scalars['Boolean'];
  deleteCustomerTariff: Customer;
  createContact: Contact;
  updateContact: Contact;
  deleteContact: Scalars['Boolean'];
  createDriver?: Maybe<Driver>;
  createSubDriver?: Maybe<Driver>;
  updateDriver?: Maybe<Driver>;
  updateSubDriver?: Maybe<Driver>;
  updateDriverPreferredLanguage: Scalars['Boolean'];
  deleteDriver: Scalars['Boolean'];
  createDriverContact: DriverContact;
  updateDriverContact: DriverContact;
  deleteDriverContact: Scalars['Boolean'];
  addDriverLocation: DriverLocation;
  acceptBooking: Array<BookingDriver>;
  declineBooking: Array<BookingDriver>;
  updateDriverProfileDetails: DriverProfileDto;
  updateDriverProfileContacts: DriverProfileDto;
  updateDriverProfilePayment: DriverProfileDto;
  disableMyVehicle: Vehicle;
  /** @deprecated This mutation is depricated and will be removed in next releases. Use SetPushNotificationToken instead. */
  setMyPushNotificationToken: Scalars['String'];
  setPushNotificationToken?: Maybe<DeviceNotificationToken>;
  setDefaultVehicle: Array<Vehicle>;
  arrivedAtAddress: BookingAddress;
  onRouteAddress: BookingAddress;
  updateAddressEta: BookingAddress;
  switchTrackLocation: DriverProfileDto;
  deleteDriverProfile: Scalars['Boolean'];
  testPushNotification: Scalars['String'];
  updateDriverPaymentAccount: Driver;
  allocateDriverBids: Array<DriverBid>;
  createDriverBid?: Maybe<DriverBid>;
  updateDriverBid?: Maybe<DriverBid>;
  deleteDriverBid: Scalars['Boolean'];
  deleteDriverBidByBooking: Scalars['Boolean'];
  deleteDriverBidByDriver: Scalars['Boolean'];
  updateEmailTemplate: EmailTemplate;
  testSendingEmailToCustomer: EmailSentStatus;
  testSendingEmailToDriver: EmailSentStatus;
  refreshExchangeRates: Array<ExchangeRate>;
  runExchangeRateService: Scalars['Boolean'];
  createBanner?: Maybe<Banner>;
  updateBanner?: Maybe<Banner>;
  deleteBanner: Scalars['Boolean'];
  deleteCustomerLogo: Scalars['Boolean'];
  createOtherCharge: OtherCharge;
  updateOtherCharge: OtherCharge;
  deleteOtherCharge: Scalars['Boolean'];
  matchExternalDriverInvoices: Array<ExternalDriverInvoice>;
  createScheduledBookingTemplate: ScheduledBookingTemplate;
  updateScheduledBookingTemplate: ScheduledBookingTemplate;
  deleteScheduledBookingTemplate: ScheduledBookingTemplate;
  createScheduledBookingDate: Array<ScheduledBookingDate>;
  deleteScheduledBookingDate: Scalars['Boolean'];
  createScheduledExchangeRateTemplate?: Maybe<ScheduledExchangeRateTemplate>;
  updateScheduledExchangeRateTemplate?: Maybe<ScheduledExchangeRateTemplate>;
  deleteScheduledExchangeRateTemplate: Scalars['Boolean'];
  createStrategicPartner: StrategicPartner;
  updateStrategicPartner: StrategicPartner;
  deleteStrategicPartner: Scalars['Boolean'];
  createStrategicPartnerContact: StrategicPartnerContact;
  updateStrategicPartnerContact: StrategicPartnerContact;
  deleteStrategicPartnerContact: Scalars['Boolean'];
  createTariff?: Maybe<Tariff>;
  updateTariff?: Maybe<Tariff>;
  updateTariffPortalDetails: Tariff;
  deleteTariff: Scalars['Long'];
  createTariffCategory: TariffCategory;
  updateTariffCategory: TariffCategory;
  createTariffIcon?: Maybe<TariffIcon>;
  updateTariffIcon?: Maybe<TariffIcon>;
  deleteTariffIcon: Scalars['Boolean'];
  createXeroCode: XeroCode;
  updateXeroCode: XeroCode;
  deleteXeroCode: Scalars['Boolean'];
  createTask?: Maybe<Task>;
  updateTask?: Maybe<Task>;
  deleteTask: Scalars['Long'];
  createApplicationUser: CreateUpdateUserDto;
  updateApplicationUser: CreateUpdateUserDto;
  updateUserApproval: Scalars['Boolean'];
  deleteApplicationUser: Scalars['Boolean'];
  updateMyProfile: UserProfileDto;
  createVehicle: Vehicle;
  updateVehicle: Vehicle;
  createActivity?: Maybe<Activity>;
  updateActivity?: Maybe<Activity>;
  deleteActivity: Scalars['Boolean'];
  createOpenQuery?: Maybe<OpenQuery>;
  deleteOpenQuery: Scalars['Boolean'];
  deleteOpenQueryByAccountPayableRecord: Scalars['Boolean'];
  readNotification: Array<Notification>;
  deleteNotification: Scalars['Boolean'];
  createOrUpdateInstallation: Scalars['Boolean'];
  deleteInstallationById: Scalars['Boolean'];
  deleteInstallationByToken: Scalars['Boolean'];
  viewNotification?: Maybe<MobileNotification>;
  createSystemTaskSetting?: Maybe<SystemTaskSetting>;
  updateSystemTaskSetting?: Maybe<SystemTaskSetting>;
  createBookingDrivers: Array<BookingDriver>;
  createBookingDriver?: Maybe<BookingDriver>;
  updateBookingDriver?: Maybe<BookingDriver>;
  deleteBookingDriver: Array<BookingDriver>;
  sendDocketDetailsEmail: Scalars['String'];
  updateDriverRating: Array<BookingDriver>;
  deleteBookingDriverByBookingAndDriverId: Array<BookingDriver>;
  updateBookingAttachment?: Maybe<BookingAttachment>;
  deleteBookingAttachment: Scalars['Boolean'];
  createStandardOperatingProcedure?: Maybe<StandardOperatingProcedure>;
  updateStandardOperatingProcedure?: Maybe<StandardOperatingProcedure>;
  deleteStandardOperatingProcedure: Scalars['Boolean'];
  deleteSopAttachment: Scalars['Boolean'];
  createReasonCode: Array<ReasonCode>;
  updateReasonCode: Array<ReasonCode>;
  deleteReasonCode: Scalars['Boolean'];
  createPayment?: Maybe<Payment>;
  createSmartApprovalPayment: Array<Payment>;
  updateFailedPaymentStatus: Scalars['Boolean'];
};


export type MutationUpdateAccountPayableRecordStatusArgs = {
  input: AccountPayableRecordStatusDtoInput;
};


export type MutationUpdateAccountPayableRecordsStatusRangeToScheduledPaymentArgs = {
  inputs: Array<AccountPayableRecordStatusDtoInput>;
};


export type MutationUpdateDriverInformationArgs = {
  input: UpdateDriverInformationDtoInput;
};


export type MutationUpdateAccountPayablesPaymentDateArgs = {
  input: AccountPayablePaymentDateDtoInput;
};


export type MutationRefreshPaymentStatusArgs = {
  input: RefreshPaymentStatusDtoInput;
};


export type MutationUpdateAccountPayableNetAmountArgs = {
  input: UpdateAccountPayableNetAmountDtoInput;
};


export type MutationCreateAddressArgs = {
  input: CreateAndUpdateAddressDtoInput;
};


export type MutationUpdateAddressArgs = {
  input: CreateAndUpdateAddressDtoInput;
};


export type MutationDeleteAddressArgs = {
  customerId: Scalars['Long'];
  addressId: Scalars['Long'];
};


export type MutationCreateBookingCombinedArgs = {
  input: CreateAndUpdateBookingCombinedDtoInput;
};


export type MutationUpdateBookingCombinedArgs = {
  input: CreateAndUpdateBookingCombinedDtoInput;
};


export type MutationDeleteBookingAddressArgs = {
  bookingAddressId: Scalars['Long'];
};


export type MutationDeleteBookingChargeArgs = {
  bookingChargeId: Scalars['Long'];
};


export type MutationDeleteBookingPackageArgs = {
  packageId: Scalars['Long'];
};


export type MutationCancelBookingArgs = {
  input: CancelBookingDtoInput;
};


export type MutationMarkQuoteLostArgs = {
  input: QuoteLostDtoInput;
};


export type MutationArchiveBookingArgs = {
  input: ArchiveBookingDtoInput;
};


export type MutationMakeBookingOnHoldArgs = {
  input: MakeBookingOnHoldDtoInput;
};


export type MutationConfirmQuickQuoteArgs = {
  input: ConfirmQuickQuoteDtoInput;
};


export type MutationUpdateBookingStatusArgs = {
  bookingId: Scalars['Long'];
  status: BookingStatus;
};


export type MutationCopyBookingArgs = {
  bookingId: Scalars['Long'];
};


export type MutationDeleteBookingArgs = {
  input: DeleteOrDisableBookingDtoInput;
};


export type MutationAddExceptionArgs = {
  input: CreateExceptionReasonDtoInput;
};


export type MutationBookingAddressCollectionCompleteArgs = {
  input: BookingAddressCompleteDtoInput;
};


export type MutationBookingAddressDeliveryCompleteArgs = {
  input: BookingAddressCompleteDtoInput;
};


export type MutationUpdateBookingPackageOnBoardArgs = {
  input: UpdatePackageOnBoardDtoInput;
};


export type MutationUpdateBookingProofOfDeliveryArgs = {
  input: UpdateProofOfDeliveryDtoInput;
};


export type MutationSendProofOfDeliveryEmailArgs = {
  bookingId: Scalars['Long'];
  bookingAddressId?: Maybe<Scalars['Long']>;
};


export type MutationCreateBookingInvoiceArgs = {
  input: CreateBookingInvoiceDtoInput;
};


export type MutationCreateQuoteLostReasonArgs = {
  input: CreateAndUpdateQuoteLostReasonDtoInput;
};


export type MutationUpdateQuoteLostReasonArgs = {
  input: CreateAndUpdateQuoteLostReasonDtoInput;
};


export type MutationProcessPotentialBookingFileArgs = {
  input: ProcessPotentialBookingFileDtoInput;
};


export type MutationUpdateBookingAddressEtaArgs = {
  input: UpdateAddressEtaDtoInput;
};


export type MutationAddWaitTimeArgs = {
  input: CreateDriverWaitTimeDtoInput;
};


export type MutationUpdateSequenceOrderArgs = {
  input: UpdateSequenceOrderDtoInput;
};


export type MutationDeleteBookingAddressAttachmentArgs = {
  input: DeleteBookingAddressAttachmentDtoInput;
};


export type MutationSendAddressContactEmailArgs = {
  input: AddressContactEmailDtoInput;
};


export type MutationUpdateBookingAddressWaitingTimeArgs = {
  input: UpdateAddressWaitingTimeDtoInput;
};


export type MutationUpdateYourBookingAddressInformationArgs = {
  input: UpdateYourBookingAddressInformationDtoInput;
};


export type MutationUpdateBookingAddressPackagesArgs = {
  input: UpdateBookingAddressPackagesDtoInput;
};


export type MutationUpdateBookingAddressAdditionalDetailsArgs = {
  input: UpdateBookingAddressAdditionalDetailsDtoInput;
};


export type MutationUpdateSignatureDataArgs = {
  input: UpdateSignatureDataDtoInput;
};


export type MutationCreateCommentaryArgs = {
  input: CreateUpdateCommentaryDtoInput;
};


export type MutationUpdateCommentaryArgs = {
  input: CreateUpdateCommentaryDtoInput;
};


export type MutationDeleteCommentaryArgs = {
  id: Scalars['Long'];
};


export type MutationCreateCongestionZoneArgs = {
  input: CreateAndUpdateCongestionZoneDtoInput;
};


export type MutationUpdateCongestionZoneArgs = {
  input: CreateAndUpdateCongestionZoneDtoInput;
};


export type MutationCreateCongestionZonePostcodeArgs = {
  input: CreateAndUpdateCongestionZonePostcodeDtoInput;
};


export type MutationUpdateCongestionZonePostcodeArgs = {
  input: CreateAndUpdateCongestionZonePostcodeDtoInput;
};


export type MutationDeleteCongestionZonePostcodeArgs = {
  postcodeId: Scalars['Long'];
};


export type MutationDeleteCongestionZoneTariffArgs = {
  tariffId: Scalars['Long'];
  congestionZoneId: Scalars['Long'];
};


export type MutationPostLoadArgs = {
  input: CourierExchangeLoadDtoInput;
};


export type MutationBookLoadArgs = {
  input: CourierExchangeBookLoadDtoInput;
};


export type MutationCancelLoadArgs = {
  loadId: Scalars['Long'];
};


export type MutationUpdateBookingStatusFromCourierExchangeArgs = {
  loadId: Scalars['Long'];
};


export type MutationLoadQuotesArgs = {
  loadId: Scalars['Long'];
};


export type MutationCreateCustomerArgs = {
  input: CreateAndUpdateCustomerDtoInput;
};


export type MutationUpdateCustomerArgs = {
  input: CreateAndUpdateCustomerDtoInput;
};


export type MutationUpdateCustomerNotificationsArgs = {
  input: UpdateCustomerNotificationsDtoInput;
};


export type MutationUpdateCustomerReferencesAndNotesArgs = {
  input: UpdateCustomerReferencesAndNotesDtoInput;
};


export type MutationUpdateCustomerProfileDetailsArgs = {
  input: UpdateCustomerProfileDtoInput;
};


export type MutationDeleteCustomerArgs = {
  customerId: Scalars['Long'];
};


export type MutationDeleteCustomerTariffArgs = {
  tariffId: Scalars['Long'];
  customerId: Scalars['Long'];
};


export type MutationCreateContactArgs = {
  input: CreateAndUpdateContactDtoInput;
};


export type MutationUpdateContactArgs = {
  input: CreateAndUpdateContactDtoInput;
};


export type MutationDeleteContactArgs = {
  contactId: Scalars['Long'];
};


export type MutationCreateDriverArgs = {
  input: CreateAndUpdateDriverDtoInput;
};


export type MutationCreateSubDriverArgs = {
  input: CreateAndUpdateSubDriverDtoInput;
};


export type MutationUpdateDriverArgs = {
  input: CreateAndUpdateDriverDtoInput;
};


export type MutationUpdateSubDriverArgs = {
  input: CreateAndUpdateSubDriverDtoInput;
};


export type MutationUpdateDriverPreferredLanguageArgs = {
  languageCode: LanguageCode;
};


export type MutationDeleteDriverArgs = {
  driverId: Scalars['Long'];
};


export type MutationCreateDriverContactArgs = {
  input: CreateAndUpdateDriverContactDtoInput;
};


export type MutationUpdateDriverContactArgs = {
  input: CreateAndUpdateDriverContactDtoInput;
};


export type MutationDeleteDriverContactArgs = {
  driverContactId: Scalars['Long'];
};


export type MutationAddDriverLocationArgs = {
  input: CreateDriverLocationDtoInput;
};


export type MutationAcceptBookingArgs = {
  bookingId: Scalars['Long'];
};


export type MutationDeclineBookingArgs = {
  bookingId: Scalars['Long'];
  reason?: Maybe<Scalars['String']>;
};


export type MutationUpdateDriverProfileDetailsArgs = {
  input: UpdateDriverProfileDetailsDtoInput;
};


export type MutationUpdateDriverProfileContactsArgs = {
  input: UpdateDriverProfileContactsDtoInput;
};


export type MutationUpdateDriverProfilePaymentArgs = {
  input: UpdateDriverProfilePaymentInput;
};


export type MutationDisableMyVehicleArgs = {
  vehicleId: Scalars['Long'];
};


export type MutationSetMyPushNotificationTokenArgs = {
  token: Scalars['String'];
};


export type MutationSetPushNotificationTokenArgs = {
  input: SetPushNotificationTokenDtoInput;
};


export type MutationSetDefaultVehicleArgs = {
  vehicleId: Scalars['Long'];
};


export type MutationArrivedAtAddressArgs = {
  addressId: Scalars['Long'];
};


export type MutationOnRouteAddressArgs = {
  input: SubmitOnRouteAddressDtoInput;
};


export type MutationUpdateAddressEtaArgs = {
  bookingId: Scalars['Long'];
  bookingAddressId?: Maybe<Scalars['Long']>;
  input: UpdateGeoEtaDtoInput;
};


export type MutationSwitchTrackLocationArgs = {
  track: Scalars['Boolean'];
};


export type MutationTestPushNotificationArgs = {
  driverId: Scalars['Long'];
  title: Scalars['String'];
  message: Scalars['String'];
  subtitle: Scalars['String'];
  accessToken?: Maybe<Scalars['String']>;
};


export type MutationUpdateDriverPaymentAccountArgs = {
  input: UpdateDriverPaymentAccountDtoInput;
};


export type MutationAllocateDriverBidsArgs = {
  bidIds: Array<Scalars['Long']>;
};


export type MutationCreateDriverBidArgs = {
  input: CreateAndUpdateDriverBidDtoInput;
};


export type MutationUpdateDriverBidArgs = {
  input: CreateAndUpdateDriverBidDtoInput;
};


export type MutationDeleteDriverBidArgs = {
  driverId: Scalars['Long'];
};


export type MutationDeleteDriverBidByBookingArgs = {
  bookingId: Scalars['Long'];
};


export type MutationDeleteDriverBidByDriverArgs = {
  driverId: Scalars['Long'];
};


export type MutationUpdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};


export type MutationTestSendingEmailToCustomerArgs = {
  type?: Maybe<EmailTemplateType>;
  bookingId?: Maybe<Scalars['Long']>;
  bookingAddressId?: Maybe<Scalars['Long']>;
};


export type MutationTestSendingEmailToDriverArgs = {
  type?: Maybe<EmailTemplateType>;
  bookingId?: Maybe<Scalars['Long']>;
  driverId?: Maybe<Scalars['Long']>;
};


export type MutationRefreshExchangeRatesArgs = {
  where?: Maybe<ExchangeRateFilterInput>;
};


export type MutationCreateBannerArgs = {
  input: CreateAndUpdateBannerDtoInput;
};


export type MutationUpdateBannerArgs = {
  input: CreateAndUpdateBannerDtoInput;
};


export type MutationDeleteBannerArgs = {
  id: Scalars['Long'];
};


export type MutationDeleteCustomerLogoArgs = {
  customerId: Scalars['Long'];
};


export type MutationCreateOtherChargeArgs = {
  input: CreateAndUpdateOtherChargeDtoInput;
};


export type MutationUpdateOtherChargeArgs = {
  input: CreateAndUpdateOtherChargeDtoInput;
};


export type MutationDeleteOtherChargeArgs = {
  id: Scalars['Long'];
};


export type MutationMatchExternalDriverInvoicesArgs = {
  fileId: Scalars['Long'];
};


export type MutationCreateScheduledBookingTemplateArgs = {
  bookingId: Scalars['Long'];
};


export type MutationUpdateScheduledBookingTemplateArgs = {
  input: UpdateScheduledBookingTemplateDtoInput;
};


export type MutationDeleteScheduledBookingTemplateArgs = {
  templateId: Scalars['Long'];
};


export type MutationCreateScheduledBookingDateArgs = {
  input: Array<CreateScheduledBookingDateDtoInput>;
};


export type MutationDeleteScheduledBookingDateArgs = {
  dateId: Scalars['Long'];
};


export type MutationCreateScheduledExchangeRateTemplateArgs = {
  currencyId: Scalars['Long'];
  date: Scalars['DateTime'];
};


export type MutationUpdateScheduledExchangeRateTemplateArgs = {
  input: UpdateScheduledExchangeRateTemplateDtoInput;
};


export type MutationDeleteScheduledExchangeRateTemplateArgs = {
  id: Scalars['Long'];
};


export type MutationCreateStrategicPartnerArgs = {
  input: CreateAndUpdateStrategicPartnerDtoInput;
};


export type MutationUpdateStrategicPartnerArgs = {
  input: CreateAndUpdateStrategicPartnerDtoInput;
};


export type MutationDeleteStrategicPartnerArgs = {
  strategicPartnerId: Scalars['Long'];
};


export type MutationCreateStrategicPartnerContactArgs = {
  input: CreateAndUpdateStrategicPartnerContactDtoInput;
};


export type MutationUpdateStrategicPartnerContactArgs = {
  input: CreateAndUpdateStrategicPartnerContactDtoInput;
};


export type MutationDeleteStrategicPartnerContactArgs = {
  strategicPartnerContactId: Scalars['Long'];
};


export type MutationCreateTariffArgs = {
  input: CreateAndUpdateTariffDtoInput;
};


export type MutationUpdateTariffArgs = {
  input: CreateAndUpdateTariffDtoInput;
};


export type MutationUpdateTariffPortalDetailsArgs = {
  input: UpdateTariffPortalDetailsDtoInput;
};


export type MutationDeleteTariffArgs = {
  id: Scalars['Long'];
};


export type MutationCreateTariffCategoryArgs = {
  input: CreateAndUpdateTariffCategoryDtoInput;
};


export type MutationUpdateTariffCategoryArgs = {
  input: CreateAndUpdateTariffCategoryDtoInput;
};


export type MutationCreateTariffIconArgs = {
  input: CreateAndUpdateTariffIconDtoInput;
};


export type MutationUpdateTariffIconArgs = {
  input: CreateAndUpdateTariffIconDtoInput;
};


export type MutationDeleteTariffIconArgs = {
  id: Scalars['Long'];
};


export type MutationCreateXeroCodeArgs = {
  input: CreateAndUpdateXeroCodeDtoInput;
};


export type MutationUpdateXeroCodeArgs = {
  input: CreateAndUpdateXeroCodeDtoInput;
};


export type MutationDeleteXeroCodeArgs = {
  id: Scalars['Long'];
};


export type MutationCreateTaskArgs = {
  input: CreateUpdateTaskDtoInput;
};


export type MutationUpdateTaskArgs = {
  input: CreateUpdateTaskDtoInput;
};


export type MutationDeleteTaskArgs = {
  id: Scalars['Long'];
};


export type MutationCreateApplicationUserArgs = {
  input: CreateUpdateUserDtoInput;
};


export type MutationUpdateApplicationUserArgs = {
  input: CreateUpdateUserDtoInput;
};


export type MutationUpdateUserApprovalArgs = {
  input: UpdateUserApprovalDtoInput;
};


export type MutationDeleteApplicationUserArgs = {
  id: Scalars['Long'];
};


export type MutationUpdateMyProfileArgs = {
  input: UpdateProfileDtoInput;
};


export type MutationCreateVehicleArgs = {
  input: CreateAndUpdateVehicleDtoInput;
};


export type MutationUpdateVehicleArgs = {
  input: CreateAndUpdateVehicleDtoInput;
};


export type MutationCreateActivityArgs = {
  input: CreateUpdateActivityDtoInput;
};


export type MutationUpdateActivityArgs = {
  input: CreateUpdateActivityDtoInput;
};


export type MutationDeleteActivityArgs = {
  id: Scalars['Long'];
};


export type MutationCreateOpenQueryArgs = {
  input: CreateUpdateOpenQueryDtoInput;
  emailOptions: OpenQueryEmailOptionsDtoInput;
};


export type MutationDeleteOpenQueryArgs = {
  id: Scalars['Long'];
};


export type MutationDeleteOpenQueryByAccountPayableRecordArgs = {
  accountPayableRecordId: Scalars['Long'];
};


export type MutationReadNotificationArgs = {
  id: Scalars['Long'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['Long'];
};


export type MutationCreateOrUpdateInstallationArgs = {
  input: SetPushNotificationTokenDtoInput;
};


export type MutationDeleteInstallationByIdArgs = {
  installationId: Scalars['Long'];
};


export type MutationDeleteInstallationByTokenArgs = {
  deviceToken: Scalars['String'];
};


export type MutationViewNotificationArgs = {
  notificationId: Scalars['Long'];
};


export type MutationCreateSystemTaskSettingArgs = {
  input: CreateUpdateTaskSettingDtoInput;
};


export type MutationUpdateSystemTaskSettingArgs = {
  input: CreateUpdateTaskSettingDtoInput;
};


export type MutationCreateBookingDriversArgs = {
  input: Array<CreateAndUpdateBookingDriverDtoInput>;
};


export type MutationCreateBookingDriverArgs = {
  input: CreateAndUpdateBookingDriverDtoInput;
};


export type MutationUpdateBookingDriverArgs = {
  input: CreateAndUpdateBookingDriverDtoInput;
};


export type MutationDeleteBookingDriverArgs = {
  bookingDriverId: Scalars['Long'];
};


export type MutationSendDocketDetailsEmailArgs = {
  bookingId: Scalars['Long'];
};


export type MutationUpdateDriverRatingArgs = {
  input: BookingDriverRatingDtoInput;
};


export type MutationDeleteBookingDriverByBookingAndDriverIdArgs = {
  bookingId: Scalars['Long'];
  driverId: Scalars['Long'];
};


export type MutationUpdateBookingAttachmentArgs = {
  input: UpdateBookingAttachmentDtoInput;
};


export type MutationDeleteBookingAttachmentArgs = {
  bookingId: Scalars['Long'];
  fileId: Scalars['Long'];
};


export type MutationCreateStandardOperatingProcedureArgs = {
  input: CreateAndUpdateStandardOperatingProcedureDtoInput;
};


export type MutationUpdateStandardOperatingProcedureArgs = {
  input: CreateAndUpdateStandardOperatingProcedureDtoInput;
};


export type MutationDeleteStandardOperatingProcedureArgs = {
  id: Scalars['Long'];
};


export type MutationDeleteSopAttachmentArgs = {
  input: DeleteSopAttachmentDtoInput;
};


export type MutationCreateReasonCodeArgs = {
  input: CreateAndUpdateReasonCodeDtoInput;
};


export type MutationUpdateReasonCodeArgs = {
  input: CreateAndUpdateReasonCodeDtoInput;
};


export type MutationDeleteReasonCodeArgs = {
  reasonCodeId: Scalars['Long'];
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentDtoInput;
};


export type MutationCreateSmartApprovalPaymentArgs = {
  input: SmartApprovalPaymentDtoInput;
};


export type MutationUpdateFailedPaymentStatusArgs = {
  inputs: SmartApprovalPaymentDtoInput;
};

export type MyIncomeReportInputDtoInput = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  customerIds?: Maybe<Array<Scalars['Long']>>;
  reasonCodes?: Maybe<Array<Scalars['String']>>;
};

/** A connection to a list of items. */
export type MyJobsConnection = {
  __typename?: 'MyJobsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<MyJobsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingDriver>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MyJobsEdge = {
  __typename?: 'MyJobsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingDriver;
};

export type Notification = {
  __typename?: 'Notification';
  description: Scalars['String'];
  username: Scalars['String'];
  date: Scalars['DateTime'];
  priority: NotificationPriority;
  type: NotificationType;
  isRead: Scalars['Boolean'];
  taskId?: Maybe<Scalars['Long']>;
  task?: Maybe<Task>;
  deliveryStatus: NotificationDeliveryStatus;
  deliveryStatusLog?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export enum NotificationCategory {
  Allocation = 'ALLOCATION',
  Deallocation = 'DEALLOCATION',
  Cancel = 'CANCEL',
  BidSubmissionConfirmation = 'BID_SUBMISSION_CONFIRMATION',
  UpcomingPayment = 'UPCOMING_PAYMENT',
  CompletedPayment = 'COMPLETED_PAYMENT',
  FailedPayment = 'FAILED_PAYMENT',
  WinningBid = 'WINNING_BID'
}

export type NotificationCategoryOperationFilterInput = {
  eq?: Maybe<NotificationCategory>;
  neq?: Maybe<NotificationCategory>;
  in?: Maybe<Array<NotificationCategory>>;
  nin?: Maybe<Array<NotificationCategory>>;
};

export enum NotificationDeliveryStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Disabled = 'DISABLED',
  TokenIsNotProvided = 'TOKEN_IS_NOT_PROVIDED',
  DisabledOrTokenIsNotProvided = 'DISABLED_OR_TOKEN_IS_NOT_PROVIDED',
  Failed = 'FAILED'
}

export type NotificationDeliveryStatusOperationFilterInput = {
  eq?: Maybe<NotificationDeliveryStatus>;
  neq?: Maybe<NotificationDeliveryStatus>;
  in?: Maybe<Array<NotificationDeliveryStatus>>;
  nin?: Maybe<Array<NotificationDeliveryStatus>>;
};

export type NotificationFilterInput = {
  and?: Maybe<Array<NotificationFilterInput>>;
  or?: Maybe<Array<NotificationFilterInput>>;
  description?: Maybe<StringOperationFilterInput>;
  username?: Maybe<StringOperationFilterInput>;
  date?: Maybe<DateTimeOperationFilterInput>;
  priority?: Maybe<NotificationPriorityOperationFilterInput>;
  type?: Maybe<NotificationTypeOperationFilterInput>;
  isRead?: Maybe<BooleanOperationFilterInput>;
  taskId?: Maybe<LongOperationFilterInput>;
  task?: Maybe<TaskFilterInput>;
  deliveryStatus?: Maybe<NotificationDeliveryStatusOperationFilterInput>;
  deliveryStatusLog?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum NotificationPriority {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

export type NotificationPriorityOperationFilterInput = {
  eq?: Maybe<NotificationPriority>;
  neq?: Maybe<NotificationPriority>;
  in?: Maybe<Array<NotificationPriority>>;
  nin?: Maybe<Array<NotificationPriority>>;
};

export type NotificationSortInput = {
  description?: Maybe<SortEnumType>;
  username?: Maybe<SortEnumType>;
  date?: Maybe<SortEnumType>;
  priority?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
  isRead?: Maybe<SortEnumType>;
  taskId?: Maybe<SortEnumType>;
  task?: Maybe<TaskSortInput>;
  deliveryStatus?: Maybe<SortEnumType>;
  deliveryStatusLog?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum NotificationType {
  Email = 'EMAIL',
  Web = 'WEB',
  Mobile = 'MOBILE'
}

export type NotificationTypeOperationFilterInput = {
  eq?: Maybe<NotificationType>;
  neq?: Maybe<NotificationType>;
  in?: Maybe<Array<NotificationType>>;
  nin?: Maybe<Array<NotificationType>>;
};

/** A connection to a list of items. */
export type NotificationsConnection = {
  __typename?: 'NotificationsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<NotificationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Notification>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type NotificationsEdge = {
  __typename?: 'NotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Notification;
};

export type NullableOfAccountPayableRecordStatusOperationFilterInput = {
  eq?: Maybe<AccountPayableRecordStatus>;
  neq?: Maybe<AccountPayableRecordStatus>;
  in?: Maybe<Array<Maybe<AccountPayableRecordStatus>>>;
  nin?: Maybe<Array<Maybe<AccountPayableRecordStatus>>>;
};

export type NullableOfFixedLoadDtoMinVehicleSizeOperationFilterInput = {
  eq?: Maybe<FixedLoadDtoMinVehicleSize>;
  neq?: Maybe<FixedLoadDtoMinVehicleSize>;
  in?: Maybe<Array<Maybe<FixedLoadDtoMinVehicleSize>>>;
  nin?: Maybe<Array<Maybe<FixedLoadDtoMinVehicleSize>>>;
};

export type NullableOfLoadDtoStatusOperationFilterInput = {
  eq?: Maybe<LoadDtoStatus>;
  neq?: Maybe<LoadDtoStatus>;
  in?: Maybe<Array<Maybe<LoadDtoStatus>>>;
  nin?: Maybe<Array<Maybe<LoadDtoStatus>>>;
};

export type NullableOfPackagingTypeOperationFilterInput = {
  eq?: Maybe<PackagingType>;
  neq?: Maybe<PackagingType>;
  in?: Maybe<Array<Maybe<PackagingType>>>;
  nin?: Maybe<Array<Maybe<PackagingType>>>;
};

export type NullableOfPaymentStatusOperationFilterInput = {
  eq?: Maybe<PaymentStatus>;
  neq?: Maybe<PaymentStatus>;
  in?: Maybe<Array<Maybe<PaymentStatus>>>;
  nin?: Maybe<Array<Maybe<PaymentStatus>>>;
};

export type NullableOfQuoteInfoStatusOperationFilterInput = {
  eq?: Maybe<QuoteInfoStatus>;
  neq?: Maybe<QuoteInfoStatus>;
  in?: Maybe<Array<Maybe<QuoteInfoStatus>>>;
  nin?: Maybe<Array<Maybe<QuoteInfoStatus>>>;
};

export type OpenQuery = {
  __typename?: 'OpenQuery';
  accountPayableRecordId: Scalars['Long'];
  reason: Scalars['Int'];
  accountPayableRecord: AccountPayableRecord;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type OpenQueryEmailOptionsDtoInput = {
  emailMessage: Scalars['String'];
  sendEmail: Scalars['Boolean'];
  supplierEmail: Scalars['String'];
};

export type OpenQueryFilterInput = {
  and?: Maybe<Array<OpenQueryFilterInput>>;
  or?: Maybe<Array<OpenQueryFilterInput>>;
  accountPayableRecordId?: Maybe<LongOperationFilterInput>;
  reason?: Maybe<IntOperationFilterInput>;
  accountPayableRecord?: Maybe<AccountPayableRecordFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type OtherCharge = {
  __typename?: 'OtherCharge';
  description?: Maybe<Scalars['String']>;
  rate: Scalars['Decimal'];
  vatCategoryId: Scalars['Long'];
  vatCategory: VatCategory;
  xeroServiceCode?: Maybe<Scalars['String']>;
  bookingCharges?: Maybe<Array<BookingCharge>>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type OtherChargeFilterInput = {
  and?: Maybe<Array<OtherChargeFilterInput>>;
  or?: Maybe<Array<OtherChargeFilterInput>>;
  description?: Maybe<StringOperationFilterInput>;
  rate?: Maybe<DecimalOperationFilterInput>;
  vatCategoryId?: Maybe<LongOperationFilterInput>;
  vatCategory?: Maybe<VatCategoryFilterInput>;
  xeroServiceCode?: Maybe<StringOperationFilterInput>;
  bookingCharges?: Maybe<ListFilterInputTypeOfBookingChargeFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type OtherChargeSortInput = {
  description?: Maybe<SortEnumType>;
  rate?: Maybe<SortEnumType>;
  vatCategoryId?: Maybe<SortEnumType>;
  vatCategory?: Maybe<VatCategorySortInput>;
  xeroServiceCode?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type Package = {
  __typename?: 'Package';
  collectionSequenceOrder?: Maybe<Scalars['Int']>;
  deliverySequenceOrder?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  weight?: Maybe<Scalars['Decimal']>;
  length?: Maybe<Scalars['Decimal']>;
  width?: Maybe<Scalars['Decimal']>;
  height?: Maybe<Scalars['Decimal']>;
  packageReference?: Maybe<Scalars['String']>;
  type?: Maybe<PackagingType>;
  bookingId: Scalars['Long'];
  booking: Booking;
  collectionId?: Maybe<Scalars['Long']>;
  collection?: Maybe<BookingAddress>;
  deliveryId?: Maybe<Scalars['Long']>;
  delivery?: Maybe<BookingAddress>;
  allocations?: Maybe<Array<PackageAllocation>>;
  drivers?: Maybe<Array<DriverPackage>>;
  addressPackages?: Maybe<Array<BookingAddressPackage>>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type PackageAllocation = {
  __typename?: 'PackageAllocation';
  packageId: Scalars['Long'];
  package: Package;
  bookingAddressId: Scalars['Long'];
  bookingAddress: BookingAddress;
  quantity: Scalars['Int'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type PackageAllocationFilterInput = {
  and?: Maybe<Array<PackageAllocationFilterInput>>;
  or?: Maybe<Array<PackageAllocationFilterInput>>;
  packageId?: Maybe<LongOperationFilterInput>;
  package?: Maybe<PackageFilterInput>;
  bookingAddressId?: Maybe<LongOperationFilterInput>;
  bookingAddress?: Maybe<BookingAddressFilterInput>;
  quantity?: Maybe<IntOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type PackageFilterInput = {
  and?: Maybe<Array<PackageFilterInput>>;
  or?: Maybe<Array<PackageFilterInput>>;
  quantity?: Maybe<IntOperationFilterInput>;
  weight?: Maybe<DecimalOperationFilterInput>;
  length?: Maybe<DecimalOperationFilterInput>;
  width?: Maybe<DecimalOperationFilterInput>;
  height?: Maybe<DecimalOperationFilterInput>;
  packageReference?: Maybe<StringOperationFilterInput>;
  type?: Maybe<NullableOfPackagingTypeOperationFilterInput>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  collectionId?: Maybe<LongOperationFilterInput>;
  collection?: Maybe<BookingAddressFilterInput>;
  deliveryId?: Maybe<LongOperationFilterInput>;
  delivery?: Maybe<BookingAddressFilterInput>;
  allocations?: Maybe<ListFilterInputTypeOfPackageAllocationFilterInput>;
  drivers?: Maybe<ListFilterInputTypeOfDriverPackageFilterInput>;
  addressPackages?: Maybe<ListFilterInputTypeOfBookingAddressPackageFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum PackagingType {
  Bags = 'BAGS',
  Bales = 'BALES',
  Containers = 'CONTAINERS',
  Crates = 'CRATES',
  Packages = 'PACKAGES',
  Stillages = 'STILLAGES',
  Uld = 'ULD',
  Blankets = 'BLANKETS',
  Palletsukstandard = 'PALLETSUKSTANDARD',
  Palletseuro = 'PALLETSEURO'
}

export type PackagingTypeDto = {
  __typename?: 'PackagingTypeDto';
  id: Scalars['Long'];
  description: Scalars['String'];
  packagingType: PackagingType;
  disabled: Scalars['Boolean'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type PagedBannersConnection = {
  __typename?: 'PagedBannersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedBannersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Banner>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedBannersEdge = {
  __typename?: 'PagedBannersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Banner;
};

/** A connection to a list of items. */
export type PagedBookingAllocatedDriversConnection = {
  __typename?: 'PagedBookingAllocatedDriversConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedBookingAllocatedDriversEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingDriver>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedBookingAllocatedDriversEdge = {
  __typename?: 'PagedBookingAllocatedDriversEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingDriver;
};

/** A connection to a list of items. */
export type PagedBookingDriverAllocationsConnection = {
  __typename?: 'PagedBookingDriverAllocationsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedBookingDriverAllocationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingDriver>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedBookingDriverAllocationsEdge = {
  __typename?: 'PagedBookingDriverAllocationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingDriver;
};

/** A connection to a list of items. */
export type PagedChargesConnection = {
  __typename?: 'PagedChargesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedChargesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OtherCharge>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedChargesEdge = {
  __typename?: 'PagedChargesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: OtherCharge;
};

/** A connection to a list of items. */
export type PagedCongestionZonesConnection = {
  __typename?: 'PagedCongestionZonesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedCongestionZonesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CongestionZone>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedCongestionZonesEdge = {
  __typename?: 'PagedCongestionZonesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CongestionZone;
};

/** A connection to a list of items. */
export type PagedCurrenciesConnection = {
  __typename?: 'PagedCurrenciesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedCurrenciesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Currency>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedCurrenciesEdge = {
  __typename?: 'PagedCurrenciesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Currency;
};

/** A connection to a list of items. */
export type PagedCustomerContactsConnection = {
  __typename?: 'PagedCustomerContactsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedCustomerContactsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Contact>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedCustomerContactsEdge = {
  __typename?: 'PagedCustomerContactsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Contact;
};

/** A connection to a list of items. */
export type PagedCustomerInvoicesConnection = {
  __typename?: 'PagedCustomerInvoicesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedCustomerInvoicesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CustomerInvoiceDto>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedCustomerInvoicesEdge = {
  __typename?: 'PagedCustomerInvoicesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CustomerInvoiceDto;
};

/** A connection to a list of items. */
export type PagedCustomersConnection = {
  __typename?: 'PagedCustomersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedCustomersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Customer>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedCustomersEdge = {
  __typename?: 'PagedCustomersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Customer;
};

/** A connection to a list of items. */
export type PagedDriverInvoicesConnection = {
  __typename?: 'PagedDriverInvoicesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedDriverInvoicesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingInvoice>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedDriverInvoicesEdge = {
  __typename?: 'PagedDriverInvoicesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingInvoice;
};

/** A connection to a list of items. */
export type PagedExternalDriverInvoicesConnection = {
  __typename?: 'PagedExternalDriverInvoicesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedExternalDriverInvoicesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ExternalDriverInvoice>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedExternalDriverInvoicesEdge = {
  __typename?: 'PagedExternalDriverInvoicesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ExternalDriverInvoice;
};

/** A connection to a list of items. */
export type PagedInvoiceLinesConnection = {
  __typename?: 'PagedInvoiceLinesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedInvoiceLinesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BookingInvoiceContent>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedInvoiceLinesEdge = {
  __typename?: 'PagedInvoiceLinesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BookingInvoiceContent;
};

/** A connection to a list of items. */
export type PagedQuoteLostReasonsConnection = {
  __typename?: 'PagedQuoteLostReasonsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedQuoteLostReasonsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<QuoteLostReason>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedQuoteLostReasonsEdge = {
  __typename?: 'PagedQuoteLostReasonsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: QuoteLostReason;
};

/** A connection to a list of items. */
export type PagedReasonCodesConnection = {
  __typename?: 'PagedReasonCodesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedReasonCodesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ReasonCode>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedReasonCodesEdge = {
  __typename?: 'PagedReasonCodesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ReasonCode;
};

/** A connection to a list of items. */
export type PagedSubCustomersConnection = {
  __typename?: 'PagedSubCustomersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedSubCustomersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Customer>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedSubCustomersEdge = {
  __typename?: 'PagedSubCustomersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Customer;
};

/** A connection to a list of items. */
export type PagedTariffCategoriesConnection = {
  __typename?: 'PagedTariffCategoriesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedTariffCategoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TariffCategory>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedTariffCategoriesEdge = {
  __typename?: 'PagedTariffCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TariffCategory;
};

/** A connection to a list of items. */
export type PagedTariffIconsConnection = {
  __typename?: 'PagedTariffIconsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedTariffIconsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TariffIcon>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedTariffIconsEdge = {
  __typename?: 'PagedTariffIconsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TariffIcon;
};

/** A connection to a list of items. */
export type PagedTariffsConnection = {
  __typename?: 'PagedTariffsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedTariffsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TariffPagedDto>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedTariffsEdge = {
  __typename?: 'PagedTariffsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TariffPagedDto;
};

/** A connection to a list of items. */
export type PagedXeroCodesConnection = {
  __typename?: 'PagedXeroCodesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PagedXeroCodesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<XeroCode>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PagedXeroCodesEdge = {
  __typename?: 'PagedXeroCodesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: XeroCode;
};

export enum PayFrequency {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY'
}

export type PayFrequencyOperationFilterInput = {
  eq?: Maybe<PayFrequency>;
  neq?: Maybe<PayFrequency>;
  in?: Maybe<Array<PayFrequency>>;
  nin?: Maybe<Array<PayFrequency>>;
};

export type Payment = {
  __typename?: 'Payment';
  currencyId: Scalars['Long'];
  amount: Scalars['Decimal'];
  transactionId?: Maybe<Scalars['String']>;
  scheduledDate: Scalars['DateTime'];
  status: PaymentStatus;
  idempotentKey?: Maybe<Scalars['UUID']>;
  errorMessage?: Maybe<Scalars['String']>;
  currency: Currency;
  bookingInvoiceId?: Maybe<Scalars['Long']>;
  bookingInvoice?: Maybe<BookingInvoice>;
  rawRequest?: Maybe<Scalars['String']>;
  rawResponse?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type PaymentFilterInput = {
  and?: Maybe<Array<PaymentFilterInput>>;
  or?: Maybe<Array<PaymentFilterInput>>;
  currencyId?: Maybe<LongOperationFilterInput>;
  amount?: Maybe<DecimalOperationFilterInput>;
  transactionId?: Maybe<StringOperationFilterInput>;
  scheduledDate?: Maybe<DateTimeOperationFilterInput>;
  status?: Maybe<PaymentStatusOperationFilterInput>;
  idempotentKey?: Maybe<UuidOperationFilterInput>;
  errorMessage?: Maybe<StringOperationFilterInput>;
  currency?: Maybe<CurrencyFilterInput>;
  bookingInvoiceId?: Maybe<LongOperationFilterInput>;
  bookingInvoice?: Maybe<BookingInvoiceFilterInput>;
  rawRequest?: Maybe<StringOperationFilterInput>;
  rawResponse?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  customers: Array<Customer>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type PaymentMethodFilterInput = {
  and?: Maybe<Array<PaymentMethodFilterInput>>;
  or?: Maybe<Array<PaymentMethodFilterInput>>;
  customers?: Maybe<ListFilterInputTypeOfCustomerFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type PaymentMethodSortInput = {
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type PaymentRecordByMonthDto = {
  __typename?: 'PaymentRecordByMonthDto';
  id: Scalars['UUID'];
  scheduledDate: Scalars['DateTime'];
  totalAmount: Scalars['Decimal'];
  currencySymbol: Scalars['String'];
  statuses: Array<AccountPayableRecordStatus>;
  invoiceCount: Scalars['Int'];
  bookingInvoices: Array<ScheduledPaymentBookingInvoice>;
};

export type PaymentRecordByMonthDtoFilterInput = {
  and?: Maybe<Array<PaymentRecordByMonthDtoFilterInput>>;
  or?: Maybe<Array<PaymentRecordByMonthDtoFilterInput>>;
  id?: Maybe<UuidOperationFilterInput>;
  scheduledDate?: Maybe<DateTimeOperationFilterInput>;
  totalAmount?: Maybe<DecimalOperationFilterInput>;
  currencySymbol?: Maybe<StringOperationFilterInput>;
  statuses?: Maybe<ListAccountPayableRecordStatusOperationFilterInput>;
  invoiceCount?: Maybe<IntOperationFilterInput>;
  bookingInvoices?: Maybe<ListFilterInputTypeOfScheduledPaymentBookingInvoiceFilterInput>;
};

export type PaymentSortInput = {
  currencyId?: Maybe<SortEnumType>;
  amount?: Maybe<SortEnumType>;
  transactionId?: Maybe<SortEnumType>;
  scheduledDate?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  idempotentKey?: Maybe<SortEnumType>;
  errorMessage?: Maybe<SortEnumType>;
  currency?: Maybe<CurrencySortInput>;
  bookingInvoiceId?: Maybe<SortEnumType>;
  bookingInvoice?: Maybe<BookingInvoiceSortInput>;
  rawRequest?: Maybe<SortEnumType>;
  rawResponse?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum PaymentStatus {
  New = 'NEW',
  Ready = 'READY',
  Sent = 'SENT',
  Processed = 'PROCESSED',
  Failed = 'FAILED'
}

export type PaymentStatusOperationFilterInput = {
  eq?: Maybe<PaymentStatus>;
  neq?: Maybe<PaymentStatus>;
  in?: Maybe<Array<PaymentStatus>>;
  nin?: Maybe<Array<PaymentStatus>>;
};

export enum PaymentTerm {
  Cash = 'CASH',
  HalfMonthFromInvoiceDate = 'HALF_MONTH_FROM_INVOICE_DATE',
  MonthFromInvoiceDate = 'MONTH_FROM_INVOICE_DATE',
  MonthFromEndOfInvoiceMoth = 'MONTH_FROM_END_OF_INVOICE_MOTH',
  MonthAndHalfFromEndOfInvoiceMonth = 'MONTH_AND_HALF_FROM_END_OF_INVOICE_MONTH',
  SevenDaysFromInvoiceDate = 'SEVEN_DAYS_FROM_INVOICE_DATE'
}

export type PaymentTermDto = {
  __typename?: 'PaymentTermDto';
  id: Scalars['Long'];
  paymentTerm: PaymentTerm;
  description: Scalars['String'];
};

export type PaymentTermOperationFilterInput = {
  eq?: Maybe<PaymentTerm>;
  neq?: Maybe<PaymentTerm>;
  in?: Maybe<Array<PaymentTerm>>;
  nin?: Maybe<Array<PaymentTerm>>;
};

export type Permissions = {
  __typename?: 'Permissions';
  ViewAccountsPayableRecord: Scalars['String'];
  ViewAccountsPayableRecordDocumentActions: Scalars['String'];
  UpdateAccountsPayableRecord: Scalars['String'];
  UpdateAccountsPayablePaymentDate: Scalars['String'];
  ViewAccountPayableRecordLogs: Scalars['String'];
  ViewActivities: Scalars['String'];
  ViewActivity: Scalars['String'];
  CreateActivity: Scalars['String'];
  UpdateActivity: Scalars['String'];
  DeleteActivity: Scalars['String'];
  ViewAddressList: Scalars['String'];
  ViewAddressDetails: Scalars['String'];
  RequestLoqate: Scalars['String'];
  CreateAddress: Scalars['String'];
  UpdateAddress: Scalars['String'];
  DeleteAddress: Scalars['String'];
  VerifyAllAddresses: Scalars['String'];
  ViewBookingAllocatedDrivers: Scalars['String'];
  ViewAllocatedDrivers: Scalars['String'];
  ViewDriversAllocatedBookings: Scalars['String'];
  ViewUpdateBookingDriverRating: Scalars['String'];
  ViewBookingsList: Scalars['String'];
  ViewUserBookingsList: Scalars['String'];
  ViewBookingDetails: Scalars['String'];
  ViewUserBookingDetails: Scalars['String'];
  ViewBookingFeeInfoMargin: Scalars['String'];
  ViewBookingDriverFee: Scalars['String'];
  ViewBookingInvoice: Scalars['String'];
  ViewBookingAttachments: Scalars['String'];
  ViewBookingLogs: Scalars['String'];
  ViewBookingAudit: Scalars['String'];
  ViewBookingAddressDetails: Scalars['String'];
  ViewBookingAddresses: Scalars['String'];
  ViewBookingAddressSignature: Scalars['String'];
  ViewDriverLocation: Scalars['String'];
  AddAddressExceptionReason: Scalars['String'];
  ViewBookingAddressException: Scalars['String'];
  AddBookingAddressWaitTime: Scalars['String'];
  ViewBookingAddressWaitTime: Scalars['String'];
  ViewCourierServicesRates: Scalars['String'];
  UpdateBookingAttachment: Scalars['String'];
  DeleteBookingAttachment: Scalars['String'];
  CreateBooking: Scalars['String'];
  UpdateBooking: Scalars['String'];
  DeleteBooking: Scalars['String'];
  CopyBooking: Scalars['String'];
  ArchiveBooking: Scalars['String'];
  CreateBookingInvoice: Scalars['String'];
  CreateDriverInvoice: Scalars['String'];
  DeleteBookingAddress: Scalars['String'];
  AddBookingCharge: Scalars['String'];
  EditBookingCharge: Scalars['String'];
  DeleteBookingCharge: Scalars['String'];
  DeleteBookingPackage: Scalars['String'];
  ViewBookingAddressAttachments: Scalars['String'];
  DeleteBookingAddressAttachment: Scalars['String'];
  ViewBookingExchangeRates: Scalars['String'];
  CreateBookingDriver: Scalars['String'];
  UpdateBookingDriver: Scalars['String'];
  DeleteBookingDriver: Scalars['String'];
  SendDocketDetailsEmail: Scalars['String'];
  SendProofOfDeliveryEmail: Scalars['String'];
  UpdateAllEtas: Scalars['String'];
  UpdateBookingAddressesSequenceOrder: Scalars['String'];
  UpdateBookingStatus: Scalars['String'];
  ConfirmQuickQuote: Scalars['String'];
  UpdateBookingPackageOnBoard: Scalars['String'];
  ViewEtaToBookingAddress: Scalars['String'];
  UpdateBookingProofOfDelivery: Scalars['String'];
  MakeBookingOnHold: Scalars['String'];
  CancelBooking: Scalars['String'];
  MarkQuoteLost: Scalars['String'];
  MarkQuoteWon: Scalars['String'];
  BookQuote: Scalars['String'];
  CompleteBooking: Scalars['String'];
  ViewBookingNoteOrSpecialInstruction: Scalars['String'];
  ViewBookingCourierExchangeDetails: Scalars['String'];
  ViewBookingOtherDetails: Scalars['String'];
  ViewBookingInternalTariffDetails: Scalars['String'];
  ViewQuoteLostReasonList: Scalars['String'];
  CreateQuoteLostReason: Scalars['String'];
  UpdateQuoteLostReason: Scalars['String'];
  DeleteQuoteLostReason: Scalars['String'];
  ViewQuoteSaveButtonsAsSplitButtons: Scalars['String'];
  ViewCancelQuoteButton: Scalars['String'];
  ViewCancelBookingButton: Scalars['String'];
  ViewBookingUsers: Scalars['String'];
  SetBookingsListToInProgressAfterQuoteIsBooked: Scalars['String'];
  MakeBookingCollectionDateTimeRequired: Scalars['String'];
  MakeBookingCollectionAndDeliveryContactDataRequired: Scalars['String'];
  MakeBookingDistanceFieldReadonly: Scalars['String'];
  MakeBookingConsignmentFeeFieldReadonly: Scalars['String'];
  ShowInPOBAndPODWaitingTimeTableHeaderLabelsWithoutCXWord: Scalars['String'];
  UpdateBookingAfterQuoteIsBooked: Scalars['String'];
  ShowConfirmationDialogWhenDockedIsRePriced: Scalars['String'];
  ViewNewBookingButtonOnBookingsTable: Scalars['String'];
  ShowIfBookingIsCreatedByCustomer: Scalars['String'];
  ChangeBookingMapRouteAddressesOrder: Scalars['String'];
  UpdateBookingMapRouteAddressesOrder: Scalars['String'];
  EditQuoteAcceptancePeriod: Scalars['String'];
  ViewTrustpilotWidgetOnBookingsPage: Scalars['String'];
  UpdateBookingTransitTimeValidation: Scalars['String'];
  ViewPotentialBookingFiles: Scalars['String'];
  ViewPotentialBookingMappings: Scalars['String'];
  ViewPotentialBookings: Scalars['String'];
  ViewImportBookingsButton: Scalars['String'];
  ProcessPotentialBookingFile: Scalars['String'];
  ViewBookingsTrackingDashboard: Scalars['String'];
  ViewBookingsAvailableForBidding: Scalars['String'];
  ViewChargeList: Scalars['String'];
  ViewChargeDetails: Scalars['String'];
  CreateCharge: Scalars['String'];
  UpdateCharge: Scalars['String'];
  DeleteCharge: Scalars['String'];
  ViewTaskCommentaries: Scalars['String'];
  ViewTaskCommentary: Scalars['String'];
  CreateTaskCommentary: Scalars['String'];
  UpdateTaskCommentary: Scalars['String'];
  DeleteTaskCommentary: Scalars['String'];
  ViewCongestionZoneList: Scalars['String'];
  ViewCongestionZoneDetails: Scalars['String'];
  CreateCongestionZone: Scalars['String'];
  UpdateCongestionZone: Scalars['String'];
  DeleteCongestionZone: Scalars['String'];
  ViewCountriesList: Scalars['String'];
  ViewCourierExchangeSearchCompany: Scalars['String'];
  ViewCourierExchangeQuoteList: Scalars['String'];
  ViewCourierExchangeLoadFormValues: Scalars['String'];
  ViewCourierExchangeQuoteCreated: Scalars['String'];
  CourierExchangePostLoad: Scalars['String'];
  CourierExchangeBookLoad: Scalars['String'];
  CourierExchangeCancelLoad: Scalars['String'];
  CourierExchangeUpdateBookingStatus: Scalars['String'];
  CourierExchangeUpdateBookingListStatus: Scalars['String'];
  CourierExchangeGetLoadQuoteList: Scalars['String'];
  ViewCourierExchangePackagingTypes: Scalars['String'];
  ViewCourierExchangeLoadCurrentLocation: Scalars['String'];
  ViewCurrencies: Scalars['String'];
  ViewCurrency: Scalars['String'];
  ViewCustomerBookings: Scalars['String'];
  ViewCustomerAcquisitionSourceList: Scalars['String'];
  ViewCustomerList: Scalars['String'];
  ViewSubCustomerList: Scalars['String'];
  ViewCustomerDetails: Scalars['String'];
  ViewCustomerContactList: Scalars['String'];
  ViewCustomerInvoices: Scalars['String'];
  ViewCustomerTariffs: Scalars['String'];
  ViewCustomerContactDetails: Scalars['String'];
  ViewCustomerSalesNameList: Scalars['String'];
  ViewCustomersInstructionsForDriver: Scalars['String'];
  ViewCustomerOptions: Scalars['String'];
  ViewCurrentCustomerOptions: Scalars['String'];
  CreateCustomer: Scalars['String'];
  UpdateCustomer: Scalars['String'];
  DeleteCustomer: Scalars['String'];
  DeleteCustomerAddress: Scalars['String'];
  UpdateCustomerNotifications: Scalars['String'];
  UpdateCustomerReferencesAndNotes: Scalars['String'];
  CreateCustomerContact: Scalars['String'];
  DeleteCustomerContact: Scalars['String'];
  UpdateCustomerContact: Scalars['String'];
  DeleteCustomerTariff: Scalars['String'];
  ViewCustomerProfile: Scalars['String'];
  EditCustomerProfile: Scalars['String'];
  ViewDriverDetails: Scalars['String'];
  ViewDriverList: Scalars['String'];
  ViewDriverContactDetails: Scalars['String'];
  ViewDriverContactList: Scalars['String'];
  ViewDriversProfile: Scalars['String'];
  ViewDriversVehicleList: Scalars['String'];
  ViewDriverInvoices: Scalars['String'];
  ViewDriverAccountsPayableRecords: Scalars['String'];
  ViewDriverRatings: Scalars['String'];
  CreateDriver: Scalars['String'];
  UpdateDriver: Scalars['String'];
  DeleteDriver: Scalars['String'];
  UpdateDriverPayDetails: Scalars['String'];
  UpdateDriverDropChargeDetails: Scalars['String'];
  CreateDriverContact: Scalars['String'];
  UpdateDriverContact: Scalars['String'];
  DeleteDriverContact: Scalars['String'];
  AddDriverLocation: Scalars['String'];
  AcceptBooking: Scalars['String'];
  DeclineBooking: Scalars['String'];
  UpdateDriverProfileDetails: Scalars['String'];
  UpdateDriverProfileContacts: Scalars['String'];
  UpdateDriverProfilePaymentDetails: Scalars['String'];
  DisableDriversVehicle: Scalars['String'];
  SetDriversDefaultVehicle: Scalars['String'];
  SubmitArrivedAtAddress: Scalars['String'];
  SubmitOnRouteAddress: Scalars['String'];
  UpdateEta: Scalars['String'];
  SetPushNotificationToken: Scalars['String'];
  SwitchTrackLocation: Scalars['String'];
  ViewPaymentTerms: Scalars['String'];
  UpdatePaymentTerms: Scalars['String'];
  DeleteDriverProfile: Scalars['String'];
  ViewPaymentDetails: Scalars['String'];
  ViewOnboardingOptions: Scalars['String'];
  ViewOnboardingProcess: Scalars['String'];
  ViewDriverTrackingDashboard: Scalars['String'];
  ViewDriverBid: Scalars['String'];
  CreateDriverBid: Scalars['String'];
  UpdateDriverBid: Scalars['String'];
  DeleteDriverBid: Scalars['String'];
  ViewEmailTemplateList: Scalars['String'];
  ViewEmailTemplateTokenList: Scalars['String'];
  UpdateEmailTemplate: Scalars['String'];
  TestSendingEmailTemplate: Scalars['String'];
  ViewSentEmailsList: Scalars['String'];
  ViewSentEmail: Scalars['String'];
  ViewExchangeRateSettings: Scalars['String'];
  ViewExchangeRates: Scalars['String'];
  RetrieveNewExchangeRates: Scalars['String'];
  ViewPagedFiles: Scalars['String'];
  ViewBannersList: Scalars['String'];
  CreateBanner: Scalars['String'];
  UpdateBanner: Scalars['String'];
  DeleteBanner: Scalars['String'];
  DeleteCustomerLogo: Scalars['String'];
  ViewInvoiceTermList: Scalars['String'];
  ViewYourNotifications: Scalars['String'];
  ViewNotification: Scalars['String'];
  UpdateNotification: Scalars['String'];
  DeleteNotification: Scalars['String'];
  ViewAllNotifications: Scalars['String'];
  CreateOpenQuery: Scalars['String'];
  DeleteOpenQuery: Scalars['String'];
  ViewDashboard: Scalars['String'];
  ViewSuppliers: Scalars['String'];
  ViewReports: Scalars['String'];
  ViewSettings: Scalars['String'];
  ViewAccountsPayableRecords: Scalars['String'];
  ViewBookingMapRouteTab: Scalars['String'];
  ViewTariffTab: Scalars['String'];
  ViewSurchargesTab: Scalars['String'];
  ViewImportExportDataTab: Scalars['String'];
  ViewCurrenciesTab: Scalars['String'];
  ViewMiscellaneousTab: Scalars['String'];
  ViewBannersTab: Scalars['String'];
  ViewBookingAttachmentsTab: Scalars['String'];
  ViewOptions: Scalars['String'];
  ViewReasonCodesTab: Scalars['String'];
  ViewBookingImportTab: Scalars['String'];
  ViewPaymentMethodList: Scalars['String'];
  CreatePayment: Scalars['String'];
  ViewReasonCodeList: Scalars['String'];
  ViewUserReasonCodeList: Scalars['String'];
  ViewReasonCodeDetails: Scalars['String'];
  CreateReasonCode: Scalars['String'];
  UpdateReasonCode: Scalars['String'];
  DeleteReasonCode: Scalars['String'];
  ViewExternalInvoiceReport: Scalars['String'];
  ViewCustomerIncomeReport: Scalars['String'];
  ViewMyIncomeReport: Scalars['String'];
  ViewCustomerContactListReport: Scalars['String'];
  ViewDriverListReport: Scalars['String'];
  ViewSalesCommissionReport: Scalars['String'];
  ViewProofOfDeliveryReport: Scalars['String'];
  MatchExternalDriverInvoices: Scalars['String'];
  ViewExternalDriverInvoices: Scalars['String'];
  ViewScheduledBookingTemplate: Scalars['String'];
  ViewScheduledBookingDatesList: Scalars['String'];
  ViewScheduledBookingTasksList: Scalars['String'];
  CreateScheduledBookingTemplate: Scalars['String'];
  UpdateScheduledBookingTemplate: Scalars['String'];
  DeleteScheduledBookingTemplate: Scalars['String'];
  CreateScheduledBookingDate: Scalars['String'];
  DeleteScheduledBookingDate: Scalars['String'];
  ViewScheduledExchangeRateTasks: Scalars['String'];
  ViewScheduledExchangeRateTemplates: Scalars['String'];
  CreateScheduledExchangeRateTemplate: Scalars['String'];
  UpdateScheduledExchangeRateTemplate: Scalars['String'];
  DeleteScheduledExchangeRateTemplate: Scalars['String'];
  ViewSearchResults: Scalars['String'];
  ViewExternalSearchResults: Scalars['String'];
  ViewStandardOperatingProcedures: Scalars['String'];
  CreateStandardOperatingProcedure: Scalars['String'];
  UpdateStandardOperatingProcedure: Scalars['String'];
  DeleteStandardOperatingProcedure: Scalars['String'];
  ViewStrategicPartnerDetails: Scalars['String'];
  ViewStrategicPartnerList: Scalars['String'];
  ViewStrategicPartnerContactDetails: Scalars['String'];
  ViewStrategicPartnerContactList: Scalars['String'];
  CreateStrategicPartner: Scalars['String'];
  UpdateStrategicPartner: Scalars['String'];
  DeleteStrategicPartner: Scalars['String'];
  CreateStrategicPartnerContact: Scalars['String'];
  UpdateStrategicPartnerContact: Scalars['String'];
  DeleteStrategicPartnerContact: Scalars['String'];
  ViewTariffCategoryList: Scalars['String'];
  ViewTariffCategoryCreated: Scalars['String'];
  CreateTariffCategory: Scalars['String'];
  UpdateTariffCategory: Scalars['String'];
  DeleteTariffCategory: Scalars['String'];
  ViewTariffIconList: Scalars['String'];
  ViewTariffIconCreated: Scalars['String'];
  CreateTariffIcon: Scalars['String'];
  UpdateTariffIcon: Scalars['String'];
  DeleteTariffIcon: Scalars['String'];
  ViewTariffList: Scalars['String'];
  ViewUserTariffList: Scalars['String'];
  ViewTariffListForCustomer: Scalars['String'];
  ViewTariffDetails: Scalars['String'];
  ViewDhlRateList: Scalars['String'];
  CreateTariff: Scalars['String'];
  UpdateTariff: Scalars['String'];
  DeleteTariff: Scalars['String'];
  ViewTasks: Scalars['String'];
  ViewTaskDetails: Scalars['String'];
  CreateTask: Scalars['String'];
  UpdateTask: Scalars['String'];
  DeleteTask: Scalars['String'];
  ViewTaskCategories: Scalars['String'];
  ViewTaskSettings: Scalars['String'];
  CreateTaskSettings: Scalars['String'];
  UpdateTaskSettings: Scalars['String'];
  ApproveUser: Scalars['String'];
  DeclineUser: Scalars['String'];
  ViewUserList: Scalars['String'];
  ViewUserDetails: Scalars['String'];
  CreateUser: Scalars['String'];
  UpdateUser: Scalars['String'];
  DeleteUser: Scalars['String'];
  ViewProfile: Scalars['String'];
  EditProfile: Scalars['String'];
  ViewVatCategoryList: Scalars['String'];
  ViewVehicleList: Scalars['String'];
  ViewVehicleTypeList: Scalars['String'];
  ViewVehicleDetails: Scalars['String'];
  CreateVehicle: Scalars['String'];
  UpdateVehicle: Scalars['String'];
  ViewXeroCodeList: Scalars['String'];
  ViewXeroCodeCreated: Scalars['String'];
  CreateXeroCode: Scalars['String'];
  UpdateXeroCode: Scalars['String'];
  DeleteXeroCode: Scalars['String'];
};

export enum PickupTimeRangeType {
  At = 'AT',
  Before = 'BEFORE',
  Between = 'BETWEEN'
}

export type Policy = {
  __typename?: 'Policy';
  name: Scalars['String'];
  description: Scalars['String'];
  policyRoles: Array<PolicyRole>;
  parentPolicyId?: Maybe<Scalars['Long']>;
  parentPolicy?: Maybe<Policy>;
  id: Scalars['Long'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
};

export type PolicyRole = {
  __typename?: 'PolicyRole';
  roleId: Scalars['Long'];
  role: ApplicationRole;
  policyId: Scalars['Long'];
  policy: Policy;
  id: Scalars['Long'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
};

export type PotentialBooking = {
  __typename?: 'PotentialBooking';
  potentialBookingFileId: Scalars['Long'];
  potentialBookingFile: PotentialBookingFile;
  potentialBookingMappingId: Scalars['Long'];
  potentialBookingMapping: PotentialBookingMapping;
  bookingIdentifierKey: Scalars['String'];
  bookingIdentifierValue: Scalars['String'];
  customerIdentifierKey: Scalars['String'];
  customerIdentifierValue: Scalars['String'];
  isProcessed: Scalars['Boolean'];
  packages: Array<Booking>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export enum PotentialBookingAlgorithmType {
  TradechoiceGeneral = 'TRADECHOICE_GENERAL',
  DeltaGroupApple = 'DELTA_GROUP_APPLE'
}

export type PotentialBookingAlgorithmTypeOperationFilterInput = {
  eq?: Maybe<PotentialBookingAlgorithmType>;
  neq?: Maybe<PotentialBookingAlgorithmType>;
  in?: Maybe<Array<PotentialBookingAlgorithmType>>;
  nin?: Maybe<Array<PotentialBookingAlgorithmType>>;
};

export type PotentialBookingFile = {
  __typename?: 'PotentialBookingFile';
  fileId: Scalars['Long'];
  file: File;
  uploadedByCustomerId?: Maybe<Scalars['Long']>;
  uploadedByCustomer?: Maybe<Customer>;
  extractedData: Scalars['String'];
  isProcessed: Scalars['Boolean'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type PotentialBookingFileFilterInput = {
  and?: Maybe<Array<PotentialBookingFileFilterInput>>;
  or?: Maybe<Array<PotentialBookingFileFilterInput>>;
  fileId?: Maybe<LongOperationFilterInput>;
  file?: Maybe<FileFilterInput>;
  uploadedByCustomerId?: Maybe<LongOperationFilterInput>;
  uploadedByCustomer?: Maybe<CustomerFilterInput>;
  extractedData?: Maybe<StringOperationFilterInput>;
  isProcessed?: Maybe<BooleanOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type PotentialBookingFileSortInput = {
  fileId?: Maybe<SortEnumType>;
  file?: Maybe<FileSortInput>;
  uploadedByCustomerId?: Maybe<SortEnumType>;
  uploadedByCustomer?: Maybe<CustomerSortInput>;
  extractedData?: Maybe<SortEnumType>;
  isProcessed?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type PotentialBookingFilesConnection = {
  __typename?: 'PotentialBookingFilesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PotentialBookingFilesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PotentialBookingFile>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PotentialBookingFilesEdge = {
  __typename?: 'PotentialBookingFilesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PotentialBookingFile;
};

export type PotentialBookingFilterInput = {
  and?: Maybe<Array<PotentialBookingFilterInput>>;
  or?: Maybe<Array<PotentialBookingFilterInput>>;
  potentialBookingFileId?: Maybe<LongOperationFilterInput>;
  potentialBookingFile?: Maybe<PotentialBookingFileFilterInput>;
  potentialBookingMappingId?: Maybe<LongOperationFilterInput>;
  potentialBookingMapping?: Maybe<PotentialBookingMappingFilterInput>;
  bookingIdentifierKey?: Maybe<StringOperationFilterInput>;
  bookingIdentifierValue?: Maybe<StringOperationFilterInput>;
  customerIdentifierKey?: Maybe<StringOperationFilterInput>;
  customerIdentifierValue?: Maybe<StringOperationFilterInput>;
  isProcessed?: Maybe<BooleanOperationFilterInput>;
  packages?: Maybe<ListFilterInputTypeOfBookingFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type PotentialBookingMapping = {
  __typename?: 'PotentialBookingMapping';
  type: PotentialBookingAlgorithmType;
  distinctBookingIndicator: Scalars['String'];
  customerNameIndicator: Scalars['String'];
  mapping: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type PotentialBookingMappingFilterInput = {
  and?: Maybe<Array<PotentialBookingMappingFilterInput>>;
  or?: Maybe<Array<PotentialBookingMappingFilterInput>>;
  type?: Maybe<PotentialBookingAlgorithmTypeOperationFilterInput>;
  distinctBookingIndicator?: Maybe<StringOperationFilterInput>;
  customerNameIndicator?: Maybe<StringOperationFilterInput>;
  mapping?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type PotentialBookingMappingSortInput = {
  type?: Maybe<SortEnumType>;
  distinctBookingIndicator?: Maybe<SortEnumType>;
  customerNameIndicator?: Maybe<SortEnumType>;
  mapping?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type PotentialBookingSortInput = {
  potentialBookingFileId?: Maybe<SortEnumType>;
  potentialBookingFile?: Maybe<PotentialBookingFileSortInput>;
  potentialBookingMappingId?: Maybe<SortEnumType>;
  potentialBookingMapping?: Maybe<PotentialBookingMappingSortInput>;
  bookingIdentifierKey?: Maybe<SortEnumType>;
  bookingIdentifierValue?: Maybe<SortEnumType>;
  customerIdentifierKey?: Maybe<SortEnumType>;
  customerIdentifierValue?: Maybe<SortEnumType>;
  isProcessed?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type PotentialBookingsConnection = {
  __typename?: 'PotentialBookingsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PotentialBookingsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PotentialBooking>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PotentialBookingsEdge = {
  __typename?: 'PotentialBookingsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PotentialBooking;
};

/** A connection to a list of items. */
export type PrimaryDriversConnection = {
  __typename?: 'PrimaryDriversConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PrimaryDriversEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Driver>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PrimaryDriversEdge = {
  __typename?: 'PrimaryDriversEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Driver;
};

export type ProcessPotentialBookingFileDtoInput = {
  potentialBookingFileId: Scalars['Long'];
  potentialBookingMappingId: Scalars['Long'];
};

export type ProofOfDeliveryReportInputDtoInput = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  customerIds?: Maybe<Array<Scalars['Long']>>;
};

export type Query = {
  __typename?: 'Query';
  addresses?: Maybe<AddressesConnection>;
  address?: Maybe<Address>;
  distanceCalculation: DistanceDto;
  loqateFind?: Maybe<Array<LoqateFindDto>>;
  loqateRetrieve?: Maybe<LoqateRetrieveDto>;
  bookings?: Maybe<BookingsConnection>;
  bookingsAvailableForBidding?: Maybe<BookingsAvailableForBiddingConnection>;
  userBookings?: Maybe<UserBookingsConnection>;
  booking?: Maybe<Booking>;
  userBooking?: Maybe<Booking>;
  bookingInvoice?: Maybe<BookingInvoiceConnection>;
  pagedCustomerInvoices?: Maybe<PagedCustomerInvoicesConnection>;
  pagedInvoiceLines?: Maybe<PagedInvoiceLinesConnection>;
  invoiceBookings?: Maybe<InvoiceBookingsConnection>;
  bookingAttachments?: Maybe<BookingAttachmentsConnection>;
  bookingAttachment?: Maybe<BookingAttachment>;
  bookingLogs?: Maybe<BookingLogsConnection>;
  bookingLog?: Maybe<BookingLog>;
  bookingAudit?: Maybe<BookingAuditConnection>;
  auditRecord?: Maybe<AuditRecord>;
  driversLocations: Array<Maybe<DriverLocation>>;
  bookingExchangeRates: Array<BookingExchangeRate>;
  bookingCreators: Array<BookingUserDto>;
  bookingUsers: Array<BookingUserDto>;
  quoteLostReasons: Array<QuoteLostReason>;
  pagedQuoteLostReasons?: Maybe<PagedQuoteLostReasonsConnection>;
  quoteLostReason?: Maybe<QuoteLostReason>;
  potentialBookingFiles?: Maybe<PotentialBookingFilesConnection>;
  potentialBookingFile?: Maybe<PotentialBookingFile>;
  potentialBookingMappings: Array<PotentialBookingMapping>;
  potentialBookings?: Maybe<PotentialBookingsConnection>;
  potentialBooking?: Maybe<PotentialBooking>;
  bookingTrackingDashboardData?: Maybe<BookingTrackingDashboardDataConnection>;
  etaToBooking: Scalars['Int'];
  commentaries: Array<Commentary>;
  commentary?: Maybe<Commentary>;
  pagedCongestionZones?: Maybe<PagedCongestionZonesConnection>;
  congestionZones: Array<CongestionZone>;
  congestionZone?: Maybe<CongestionZone>;
  congestionZonePostcodes?: Maybe<CongestionZonePostcodesConnection>;
  congestionZonePostcode: Array<CongestionZonePostcode>;
  timeZones: Array<TimeZone>;
  countries: Array<Country>;
  courierExchangeSearchCompany?: Maybe<CompanyBriefDto>;
  courierExchangeQuote?: Maybe<CourierExchangeQuote>;
  courierExchangeQuotes?: Maybe<CourierExchangeQuotesConnection>;
  courierExchangeLoadFormValues?: Maybe<LoadFormValuesDto>;
  packagingTypes: Array<PackagingTypeDto>;
  loadCurrentLocation?: Maybe<DriverLocation>;
  currencies: Array<Currency>;
  pagedCurrencies?: Maybe<PagedCurrenciesConnection>;
  currency?: Maybe<Currency>;
  acquisitionSources: Array<CustomerAcquisitionSource>;
  pagedCustomers?: Maybe<PagedCustomersConnection>;
  customers: Array<Customer>;
  customer: CustomerDto;
  pagedCustomerContacts?: Maybe<PagedCustomerContactsConnection>;
  customerContacts: Array<Contact>;
  customerContact: Array<Contact>;
  salesNames: Array<Scalars['String']>;
  customerProfile: CustomerProfileDto;
  subCustomers: Array<Customer>;
  pagedSubCustomers?: Maybe<PagedSubCustomersConnection>;
  customersInstructionsForDriver: Scalars['String'];
  customerOptions: Array<CustomerOption>;
  currentCustomerOptions: Array<CustomerOption>;
  driver?: Maybe<Driver>;
  nonPaginatedDrivers: Array<Driver>;
  drivers?: Maybe<DriversConnection>;
  primaryDrivers?: Maybe<PrimaryDriversConnection>;
  subDrivers?: Maybe<SubDriversConnection>;
  pagedDriverInvoices?: Maybe<PagedDriverInvoicesConnection>;
  driverContact?: Maybe<DriverContact>;
  driverContacts?: Maybe<DriverContactsConnection>;
  driverLocations?: Maybe<DriverLocation>;
  bookingAddressException: ExceptionReasonDto;
  bookingAddressWaitTime: DriverWaitTimeDto;
  driverProfile: DriverProfileDto;
  myVehicles?: Maybe<Array<Vehicle>>;
  myActiveVehicle?: Maybe<Vehicle>;
  paymentTerms: Array<PaymentTermDto>;
  driverByCurrentUser?: Maybe<Driver>;
  currentDriversLocations?: Maybe<CurrentDriversLocationsConnection>;
  driverNextRoute: DriverTrackingDto;
  missingDriverPaymentDataFields: Array<Scalars['String']>;
  driverBid?: Maybe<DriverBid>;
  driverBidForCurrentDriver?: Maybe<DriverBid>;
  driverBids: Array<DriverBid>;
  driverBidsPaged?: Maybe<DriverBidsPagedConnection>;
  emailTemplates: Array<EmailTemplate>;
  emailTemplateTokens: Array<EmailTemplateTokenDto>;
  sentEmails?: Maybe<SentEmailsConnection>;
  sentEmail?: Maybe<SentEmail>;
  exchangeRates?: Maybe<ExchangeRatesConnection>;
  invoiceTerms: Array<InvoiceTerm>;
  pagedCharges?: Maybe<PagedChargesConnection>;
  otherCharges: Array<OtherCharge>;
  otherCharge?: Maybe<OtherCharge>;
  otherChargeNameIsUnique: Scalars['Boolean'];
  paymentMethods: Array<PaymentMethod>;
  driversCountries: Array<CountryDto>;
  driversLocationsByTrackingNumber: Array<Maybe<DriverLocation>>;
  bookingByTrackingNumber?: Maybe<BookingTrackingDto>;
  customerIncomeReport: GenericReportOutputDto;
  myIncomeReport: GenericReportOutputDto;
  customerContactListReport: GenericReportOutputDto;
  driverListReport: GenericReportOutputDto;
  salesCommissionReport: GenericReportOutputDto;
  proofOfDeliveryReport: GenericReportOutputDto;
  pagedExternalDriverInvoices?: Maybe<PagedExternalDriverInvoicesConnection>;
  scheduledBookingTemplateByBookingId?: Maybe<ScheduledBookingTemplate>;
  scheduledBookingDates?: Maybe<ScheduledBookingDatesConnection>;
  scheduledBookingTasks?: Maybe<ScheduledBookingTasksConnection>;
  scheduledExchangeRateTasks?: Maybe<ScheduledExchangeRateTasksConnection>;
  scheduledExchangeRateTemplates?: Maybe<ScheduledExchangeRateTemplatesConnection>;
  searchResults: Array<SearchResultDto>;
  externalSearchResults: Array<SearchResultDto>;
  strategicPartner?: Maybe<StrategicPartner>;
  strategicPartners?: Maybe<StrategicPartnersConnection>;
  strategicPartnerContact?: Maybe<StrategicPartnerContact>;
  strategicPartnerContacts?: Maybe<StrategicPartnerContactsConnection>;
  pagedTariffs?: Maybe<PagedTariffsConnection>;
  userPagedTariffs?: Maybe<UserPagedTariffsConnection>;
  tariffs: Array<Tariff>;
  tariffsForCustomer: Array<Tariff>;
  tariffById?: Maybe<Tariff>;
  tariffCategories: Array<TariffCategory>;
  pagedTariffCategories?: Maybe<PagedTariffCategoriesConnection>;
  tariffCategory?: Maybe<TariffCategory>;
  tariffIcons: Array<TariffIcon>;
  pagedTariffIcons?: Maybe<PagedTariffIconsConnection>;
  tariffIcon?: Maybe<TariffIcon>;
  xeroCodes: Array<XeroCode>;
  pagedXeroCodes?: Maybe<PagedXeroCodesConnection>;
  xeroCode?: Maybe<XeroCode>;
  xeroServiceTypes: Array<XeroServiceTypeDto>;
  tasks?: Maybe<TasksConnection>;
  task?: Maybe<Task>;
  taskStatuses: Array<TaskStatusDto>;
  taskUsers: Array<TaskUserDto>;
  taskBookings: Array<Booking>;
  taskCategories: Array<TaskCategory>;
  users?: Maybe<UsersConnection>;
  userDetails: IdentityUserDetailsDto;
  myProfile: UserProfileDto;
  vatCategories: Array<VatCategory>;
  vehicles?: Maybe<VehiclesConnection>;
  vehicle?: Maybe<Vehicle>;
  vehicleTypes: Array<VehicleType>;
  files?: Maybe<FilesConnection>;
  banners: Array<Banner>;
  pagedBanners?: Maybe<PagedBannersConnection>;
  banner?: Maybe<Banner>;
  activities?: Maybe<ActivitiesConnection>;
  activity?: Maybe<Activity>;
  notifications?: Maybe<NotificationsConnection>;
  notification?: Maybe<Notification>;
  allNotificationHubRegistrationInfo: Array<DeviceTrend>;
  allNotificationHubRegistration: Array<DeviceRegistration>;
  mobileNotifications: Array<MobileNotification>;
  mobileNotification?: Maybe<MobileNotification>;
  mobileNotificationsTotalCount: Scalars['Int'];
  taskSettings: Array<SystemTaskSetting>;
  bookingAddress?: Maybe<BookingAddress>;
  bookingAddresses: Array<BookingAddress>;
  /** @deprecated Patricide: use prop from BookingAddress entity (Signature) */
  bookingAddressSignature?: Maybe<File>;
  /** @deprecated Deprecated: use attachments prop from booking address (Attachments)  */
  bookingAddressAttachments: Array<File>;
  bookingAddressAttachment?: Maybe<File>;
  standardOperatingProcedures?: Maybe<StandardOperatingProceduresConnection>;
  standardOperatingProcedure?: Maybe<StandardOperatingProcedure>;
  standardOperatingProcedureHistory?: Maybe<StandardOperatingProcedureHistoryConnection>;
  bookingAllocatedDrivers: Array<BookingDriver>;
  pagedBookingAllocatedDrivers?: Maybe<PagedBookingAllocatedDriversConnection>;
  bookingAllocatedDriver?: Maybe<BookingDriver>;
  allocatedDrivers: Array<BookingDriver>;
  myJobs?: Maybe<MyJobsConnection>;
  myJob?: Maybe<BookingDriver>;
  bookingDriverRating: Array<BookingDriver>;
  pagedBookingDriverAllocations?: Maybe<PagedBookingDriverAllocationsConnection>;
  pagedReasonCodes?: Maybe<PagedReasonCodesConnection>;
  reasonCodes: Array<ReasonCode>;
  userPagedReasonCodes?: Maybe<UserPagedReasonCodesConnection>;
  userReasonCodes: Array<ReasonCode>;
  reasonCode?: Maybe<ReasonCode>;
  accountPayableRecord?: Maybe<AccountPayableRecord>;
  accountPayableRecords?: Maybe<AccountPayableRecordsConnection>;
  allAccountPayableRecords: Array<AccountPayableRecord>;
  accountPayableRecordsByIds: Array<AccountPayableRecord>;
  groupedAccountPayableRecords?: Maybe<GroupedAccountPayableRecordsConnection>;
  accountPayableTotals: Array<AccountPayableCurrencyAmountDto>;
  accountPayableOverview: AccountPayableOverviewDto;
  accountPayableTotalCount: Scalars['Int'];
  accountPayableInvoice: AccountPayablePanelDto;
  accountPayableSearchResults: Array<SearchResultDto>;
  accountPayablePod: AccountPayablePanelPodDto;
  paymentFailedErrorMessage: Scalars['String'];
  accountPayableRecordLogs?: Maybe<AccountPayableRecordLogsConnection>;
  bookingInvoiceBatches?: Maybe<BookingInvoiceBatchesConnection>;
  bookingInvoiceBatchLines?: Maybe<BookingInvoiceBatchLinesConnection>;
  bookingInvoiceBatch?: Maybe<BookingInvoiceBatch>;
  /** @deprecated Use GetPaymentRecordsByMonth */
  groupedPaymentRecordsByMonth: Array<GroupedPaymentRecordByMonthDto>;
  paymentRecordsByMonth: Array<PaymentRecordByMonthDto>;
  paymentRecord?: Maybe<Payment>;
  policyRoles: Array<PolicyRole>;
};


export type QueryAddressesArgs = {
  customerId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<AddressFilterInput>;
  order?: Maybe<Array<AddressSortInput>>;
};


export type QueryAddressArgs = {
  id: Scalars['Long'];
};


export type QueryDistanceCalculationArgs = {
  input: Array<GoogleAddressDtoInput>;
};


export type QueryLoqateFindArgs = {
  text: Scalars['String'];
  countryId: Scalars['Long'];
  container?: Maybe<Scalars['String']>;
  where?: Maybe<LoqateFindDtoFilterInput>;
  order?: Maybe<Array<LoqateFindDtoSortInput>>;
};


export type QueryLoqateRetrieveArgs = {
  id: Scalars['String'];
};


export type QueryBookingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingFilterInput>;
  order?: Maybe<Array<BookingSortInput>>;
};


export type QueryBookingsAvailableForBiddingArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingFilterInput>;
  order?: Maybe<Array<BookingSortInput>>;
};


export type QueryUserBookingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingFilterInput>;
  order?: Maybe<Array<BookingSortInput>>;
};


export type QueryBookingArgs = {
  bookingId: Scalars['Long'];
};


export type QueryUserBookingArgs = {
  bookingId: Scalars['Long'];
};


export type QueryBookingInvoiceArgs = {
  customerId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingInvoiceFilterInput>;
  order?: Maybe<Array<BookingInvoiceSortInput>>;
};


export type QueryPagedCustomerInvoicesArgs = {
  customerId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CustomerInvoiceDtoFilterInput>;
  order?: Maybe<Array<CustomerInvoiceDtoSortInput>>;
};


export type QueryPagedInvoiceLinesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingInvoiceContentFilterInput>;
  order?: Maybe<Array<BookingInvoiceContentSortInput>>;
};


export type QueryInvoiceBookingsArgs = {
  invoiceNumber: Scalars['Long'];
  invoiceType: InvoiceType;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingFilterInput>;
  order?: Maybe<Array<BookingSortInput>>;
};


export type QueryBookingAttachmentsArgs = {
  bookingId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<FileFilterInput>;
  order?: Maybe<Array<FileSortInput>>;
};


export type QueryBookingAttachmentArgs = {
  bookingId: Scalars['Long'];
  fileId: Scalars['Long'];
};


export type QueryBookingLogsArgs = {
  bookingId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingLogFilterInput>;
  order?: Maybe<Array<BookingLogSortInput>>;
};


export type QueryBookingLogArgs = {
  bookingLogId: Scalars['Long'];
};


export type QueryBookingAuditArgs = {
  bookingId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<AuditRecordFilterInput>;
  order?: Maybe<Array<AuditRecordSortInput>>;
};


export type QueryAuditRecordArgs = {
  auditRecordId: Scalars['Long'];
};


export type QueryDriversLocationsArgs = {
  bookingId: Scalars['Long'];
};


export type QueryBookingExchangeRatesArgs = {
  bookingId: Scalars['Long'];
};


export type QueryBookingCreatorsArgs = {
  order?: Maybe<Array<BookingUserDtoSortInput>>;
  where?: Maybe<BookingUserDtoFilterInput>;
};


export type QueryBookingUsersArgs = {
  order?: Maybe<Array<BookingUserDtoSortInput>>;
  where?: Maybe<BookingUserDtoFilterInput>;
};


export type QueryPagedQuoteLostReasonsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<QuoteLostReasonFilterInput>;
  order?: Maybe<Array<QuoteLostReasonSortInput>>;
};


export type QueryQuoteLostReasonArgs = {
  id: Scalars['Long'];
};


export type QueryPotentialBookingFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<PotentialBookingFileFilterInput>;
  order?: Maybe<Array<PotentialBookingFileSortInput>>;
};


export type QueryPotentialBookingFileArgs = {
  id: Scalars['Long'];
};


export type QueryPotentialBookingsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<PotentialBookingFilterInput>;
  order?: Maybe<Array<PotentialBookingSortInput>>;
};


export type QueryPotentialBookingArgs = {
  id: Scalars['Long'];
};


export type QueryBookingTrackingDashboardDataArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingTrackingDashboardDtoFilterInput>;
  order?: Maybe<Array<BookingTrackingDashboardDtoSortInput>>;
};


export type QueryEtaToBookingArgs = {
  input: GetBookingEtaDtoInput;
};


export type QueryCommentariesArgs = {
  order?: Maybe<Array<CommentarySortInput>>;
  where?: Maybe<CommentaryFilterInput>;
};


export type QueryCommentaryArgs = {
  id: Scalars['Long'];
};


export type QueryPagedCongestionZonesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CongestionZoneFilterInput>;
  order?: Maybe<Array<CongestionZoneSortInput>>;
};


export type QueryCongestionZoneArgs = {
  id: Scalars['Long'];
};


export type QueryCongestionZonePostcodesArgs = {
  congestionZoneId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CongestionZonePostcodeFilterInput>;
  order?: Maybe<Array<CongestionZonePostcodeSortInput>>;
};


export type QueryCongestionZonePostcodeArgs = {
  id: Scalars['Long'];
};


export type QueryCourierExchangeSearchCompanyArgs = {
  name: Scalars['String'];
};


export type QueryCourierExchangeQuoteArgs = {
  courierExchangeQuoteId: Scalars['Long'];
};


export type QueryCourierExchangeQuotesArgs = {
  loadId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CourierExchangeQuoteFilterInput>;
  order?: Maybe<Array<CourierExchangeQuoteSortInput>>;
};


export type QueryLoadCurrentLocationArgs = {
  loadId?: Maybe<Scalars['Long']>;
};


export type QueryCurrenciesArgs = {
  where?: Maybe<CurrencyFilterInput>;
};


export type QueryPagedCurrenciesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CurrencyFilterInput>;
  order?: Maybe<Array<CurrencySortInput>>;
};


export type QueryCurrencyArgs = {
  id: Scalars['Long'];
};


export type QueryPagedCustomersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CustomerFilterInput>;
  order?: Maybe<Array<CustomerSortInput>>;
};


export type QueryCustomersArgs = {
  order?: Maybe<Array<CustomerSortInput>>;
};


export type QueryCustomerArgs = {
  id: Scalars['Long'];
};


export type QueryPagedCustomerContactsArgs = {
  customerId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ContactFilterInput>;
  order?: Maybe<Array<ContactSortInput>>;
};


export type QueryCustomerContactsArgs = {
  customerId: Scalars['Long'];
  where?: Maybe<ContactFilterInput>;
  order?: Maybe<Array<ContactSortInput>>;
};


export type QueryCustomerContactArgs = {
  id: Scalars['Long'];
};


export type QuerySubCustomersArgs = {
  primaryCustomerId: Scalars['Long'];
  isPrimaryCustomerIncluded?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Array<CustomerSortInput>>;
};


export type QueryPagedSubCustomersArgs = {
  primaryCustomerId: Scalars['Long'];
  isPrimaryCustomerIncluded?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<CustomerFilterInput>;
  order?: Maybe<Array<CustomerSortInput>>;
};


export type QueryCustomersInstructionsForDriverArgs = {
  customerId: Scalars['Long'];
};


export type QueryCustomerOptionsArgs = {
  customerId: Scalars['Long'];
};


export type QueryDriverArgs = {
  driverId: Scalars['Long'];
};


export type QueryNonPaginatedDriversArgs = {
  where?: Maybe<DriverFilterInput>;
};


export type QueryDriversArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<DriverFilterInput>;
  order?: Maybe<Array<DriverSortInput>>;
};


export type QueryPrimaryDriversArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<DriverFilterInput>;
  order?: Maybe<Array<DriverSortInput>>;
};


export type QuerySubDriversArgs = {
  driverId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<DriverFilterInput>;
  order?: Maybe<Array<DriverSortInput>>;
};


export type QueryPagedDriverInvoicesArgs = {
  driverId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingInvoiceFilterInput>;
  order?: Maybe<Array<BookingInvoiceSortInput>>;
};


export type QueryDriverContactArgs = {
  driverContactId: Scalars['Long'];
};


export type QueryDriverContactsArgs = {
  driverId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<DriverContactFilterInput>;
  order?: Maybe<Array<DriverContactSortInput>>;
};


export type QueryDriverLocationsArgs = {
  driverId: Scalars['Long'];
};


export type QueryBookingAddressExceptionArgs = {
  id: Scalars['Long'];
};


export type QueryBookingAddressWaitTimeArgs = {
  id: Scalars['Long'];
};


export type QueryCurrentDriversLocationsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<DriverLocationFilterInput>;
  order?: Maybe<Array<DriverLocationSortInput>>;
};


export type QueryDriverNextRouteArgs = {
  driverId: Scalars['Long'];
};


export type QueryMissingDriverPaymentDataFieldsArgs = {
  driverId: Scalars['Long'];
};


export type QueryDriverBidArgs = {
  driverBidId: Scalars['Long'];
};


export type QueryDriverBidForCurrentDriverArgs = {
  bookingId: Scalars['Long'];
};


export type QueryDriverBidsArgs = {
  where?: Maybe<DriverBidFilterInput>;
};


export type QueryDriverBidsPagedArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<DriverBidFilterInput>;
  order?: Maybe<Array<DriverBidSortInput>>;
};


export type QueryEmailTemplatesArgs = {
  where?: Maybe<EmailTemplateFilterInput>;
  order?: Maybe<Array<EmailTemplateSortInput>>;
};


export type QuerySentEmailsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<SentEmailFilterInput>;
  order?: Maybe<Array<SentEmailSortInput>>;
};


export type QuerySentEmailArgs = {
  sentEmailId: Scalars['Long'];
};


export type QueryExchangeRatesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ExchangeRateFilterInput>;
  order?: Maybe<Array<ExchangeRateSortInput>>;
};


export type QueryPagedChargesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<OtherChargeFilterInput>;
  order?: Maybe<Array<OtherChargeSortInput>>;
};


export type QueryOtherChargeArgs = {
  id: Scalars['Long'];
};


export type QueryOtherChargeNameIsUniqueArgs = {
  id?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
};


export type QueryDriversLocationsByTrackingNumberArgs = {
  trackingGuid: Scalars['UUID'];
};


export type QueryBookingByTrackingNumberArgs = {
  trackingGuid: Scalars['UUID'];
};


export type QueryCustomerIncomeReportArgs = {
  input: CustomerIncomeReportInputDtoInput;
};


export type QueryMyIncomeReportArgs = {
  input: MyIncomeReportInputDtoInput;
};


export type QueryCustomerContactListReportArgs = {
  input: CustomerContactListReportInputDtoInput;
};


export type QueryDriverListReportArgs = {
  input: DriverListReportInputDtoInput;
};


export type QuerySalesCommissionReportArgs = {
  input: SalesCommissionReportInputDtoInput;
};


export type QueryProofOfDeliveryReportArgs = {
  input: ProofOfDeliveryReportInputDtoInput;
};


export type QueryPagedExternalDriverInvoicesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ExternalDriverInvoiceFilterInput>;
  order?: Maybe<Array<ExternalDriverInvoiceSortInput>>;
};


export type QueryScheduledBookingTemplateByBookingIdArgs = {
  bookingId: Scalars['Long'];
};


export type QueryScheduledBookingDatesArgs = {
  templateId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ScheduledBookingDateFilterInput>;
  order?: Maybe<Array<ScheduledBookingDateSortInput>>;
};


export type QueryScheduledBookingTasksArgs = {
  templateId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ScheduledBookingTaskFilterInput>;
  order?: Maybe<Array<ScheduledBookingTaskSortInput>>;
};


export type QueryScheduledExchangeRateTasksArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ScheduledExchangeRateTaskFilterInput>;
  order?: Maybe<Array<ScheduledExchangeRateTaskSortInput>>;
};


export type QueryScheduledExchangeRateTemplatesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ScheduledExchangeRateTemplateFilterInput>;
  order?: Maybe<Array<ScheduledExchangeRateTemplateSortInput>>;
};


export type QuerySearchResultsArgs = {
  query: Scalars['String'];
};


export type QueryExternalSearchResultsArgs = {
  query: Scalars['String'];
};


export type QueryStrategicPartnerArgs = {
  strategicPartnerId: Scalars['Long'];
};


export type QueryStrategicPartnersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<StrategicPartnerFilterInput>;
  order?: Maybe<Array<StrategicPartnerSortInput>>;
};


export type QueryStrategicPartnerContactArgs = {
  strategicPartnerContactId: Scalars['Long'];
};


export type QueryStrategicPartnerContactsArgs = {
  strategicPartnerId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<StrategicPartnerContactFilterInput>;
  order?: Maybe<Array<StrategicPartnerContactSortInput>>;
};


export type QueryPagedTariffsArgs = {
  customerId?: Maybe<Scalars['Long']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<TariffPagedDtoFilterInput>;
  order?: Maybe<Array<TariffPagedDtoSortInput>>;
};


export type QueryUserPagedTariffsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<TariffPagedDtoFilterInput>;
  order?: Maybe<Array<TariffPagedDtoSortInput>>;
};


export type QueryTariffsArgs = {
  where?: Maybe<TariffFilterInput>;
};


export type QueryTariffsForCustomerArgs = {
  customerId: Scalars['Long'];
};


export type QueryTariffByIdArgs = {
  id: Scalars['Long'];
};


export type QueryPagedTariffCategoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<TariffCategoryFilterInput>;
  order?: Maybe<Array<TariffCategorySortInput>>;
};


export type QueryTariffCategoryArgs = {
  id: Scalars['Long'];
};


export type QueryPagedTariffIconsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<TariffIconFilterInput>;
  order?: Maybe<Array<TariffIconSortInput>>;
};


export type QueryTariffIconArgs = {
  id: Scalars['Long'];
};


export type QueryXeroCodesArgs = {
  where?: Maybe<XeroCodeFilterInput>;
};


export type QueryPagedXeroCodesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<XeroCodeFilterInput>;
  order?: Maybe<Array<XeroCodeSortInput>>;
};


export type QueryXeroCodeArgs = {
  id: Scalars['Long'];
};


export type QueryTasksArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<TaskFilterInput>;
  order?: Maybe<Array<TaskSortInput>>;
};


export type QueryTaskArgs = {
  id: Scalars['Long'];
};


export type QueryTaskUsersArgs = {
  order?: Maybe<Array<TaskUserDtoSortInput>>;
  where?: Maybe<TaskUserDtoFilterInput>;
};


export type QueryTaskBookingsArgs = {
  order?: Maybe<Array<BookingSortInput>>;
  where?: Maybe<BookingFilterInput>;
};


export type QueryUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<IdentityUserDtoFilterInput>;
  order?: Maybe<Array<IdentityUserDtoSortInput>>;
};


export type QueryUserDetailsArgs = {
  userId: Scalars['Long'];
};


export type QueryVehiclesArgs = {
  driverId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<VehicleFilterInput>;
  order?: Maybe<Array<VehicleSortInput>>;
};


export type QueryVehicleArgs = {
  driverId: Scalars['Long'];
  vehicleId: Scalars['Long'];
};


export type QueryFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<FileFilterInput>;
  order?: Maybe<Array<FileSortInput>>;
};


export type QueryBannersArgs = {
  where?: Maybe<BannerFilterInput>;
};


export type QueryPagedBannersArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BannerFilterInput>;
  order?: Maybe<Array<BannerSortInput>>;
};


export type QueryBannerArgs = {
  id: Scalars['Long'];
};


export type QueryActivitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ActivityFilterInput>;
  order?: Maybe<Array<ActivitySortInput>>;
};


export type QueryActivityArgs = {
  id: Scalars['Long'];
};


export type QueryNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<NotificationFilterInput>;
  order?: Maybe<Array<NotificationSortInput>>;
};


export type QueryNotificationArgs = {
  id: Scalars['Long'];
};


export type QueryMobileNotificationsArgs = {
  where?: Maybe<MobileNotificationFilterInput>;
  order?: Maybe<Array<MobileNotificationSortInput>>;
};


export type QueryMobileNotificationArgs = {
  notificationId: Scalars['Long'];
};


export type QueryMobileNotificationsTotalCountArgs = {
  driverId: Scalars['Long'];
};


export type QueryTaskSettingsArgs = {
  where?: Maybe<SystemTaskSettingFilterInput>;
};


export type QueryBookingAddressArgs = {
  id: Scalars['Long'];
};


export type QueryBookingAddressesArgs = {
  bookingId: Scalars['Long'];
  where?: Maybe<BookingAddressFilterInput>;
};


export type QueryBookingAddressSignatureArgs = {
  id: Scalars['Long'];
};


export type QueryBookingAddressAttachmentsArgs = {
  id: Scalars['Long'];
  filterByUser: Scalars['Boolean'];
};


export type QueryBookingAddressAttachmentArgs = {
  addressId: Scalars['Long'];
  fileId: Scalars['Long'];
};


export type QueryStandardOperatingProceduresArgs = {
  customerId?: Maybe<Scalars['Long']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<StandardOperatingProcedureFilterInput>;
  order?: Maybe<Array<StandardOperatingProcedureSortInput>>;
};


export type QueryStandardOperatingProcedureArgs = {
  id: Scalars['Long'];
};


export type QueryStandardOperatingProcedureHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<StandardOperatingProcedureHistoryDtoFilterInput>;
  order?: Maybe<Array<StandardOperatingProcedureHistoryDtoSortInput>>;
};


export type QueryBookingAllocatedDriversArgs = {
  bookingId: Scalars['Long'];
  where?: Maybe<BookingDriverFilterInput>;
  order?: Maybe<Array<BookingDriverSortInput>>;
};


export type QueryPagedBookingAllocatedDriversArgs = {
  bookingId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingDriverFilterInput>;
  order?: Maybe<Array<BookingDriverSortInput>>;
};


export type QueryBookingAllocatedDriverArgs = {
  bookingDriverId: Scalars['Long'];
};


export type QueryMyJobsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingDriverFilterInput>;
  order?: Maybe<Array<BookingDriverSortInput>>;
};


export type QueryMyJobArgs = {
  bookingId: Scalars['Long'];
};


export type QueryBookingDriverRatingArgs = {
  bookingId: Scalars['Long'];
  driverId: Scalars['Long'];
};


export type QueryPagedBookingDriverAllocationsArgs = {
  driverId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingDriverFilterInput>;
  order?: Maybe<Array<BookingDriverSortInput>>;
};


export type QueryPagedReasonCodesArgs = {
  customerId?: Maybe<Scalars['Long']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ReasonCodeFilterInput>;
  order?: Maybe<Array<ReasonCodeSortInput>>;
};


export type QueryReasonCodesArgs = {
  customerId: Scalars['Long'];
};


export type QueryUserPagedReasonCodesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<ReasonCodeFilterInput>;
  order?: Maybe<Array<ReasonCodeSortInput>>;
};


export type QueryUserReasonCodesArgs = {
  isIncludeSubCustomers: Scalars['Boolean'];
};


export type QueryReasonCodeArgs = {
  id: Scalars['Long'];
};


export type QueryAccountPayableRecordArgs = {
  accountPayableRecordId: Scalars['Long'];
};


export type QueryAccountPayableRecordsArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<AccountPayableRecordFilterInput>;
  order?: Maybe<Array<AccountPayableRecordSortInput>>;
};


export type QueryAllAccountPayableRecordsArgs = {
  where?: Maybe<AccountPayableRecordFilterInput>;
  order?: Maybe<Array<AccountPayableRecordSortInput>>;
};


export type QueryAccountPayableRecordsByIdsArgs = {
  accountPayableRecordsIds: Array<Scalars['Long']>;
};


export type QueryGroupedAccountPayableRecordsArgs = {
  status: Scalars['Int'];
  dueDateStart?: Maybe<Scalars['DateTime']>;
  dueDateEnd?: Maybe<Scalars['DateTime']>;
  accountsPayableFilterCriteria?: Maybe<AccountsPayableFilterCriteria>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<GroupedAccountPayableRecordDtoFilterInput>;
  order?: Maybe<Array<GroupedAccountPayableRecordDtoSortInput>>;
};


export type QueryAccountPayableTotalsArgs = {
  status: Scalars['Int'];
  supplierId: Scalars['Long'];
};


export type QueryAccountPayableOverviewArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  supplierCodes: Array<Scalars['String']>;
};


export type QueryAccountPayableTotalCountArgs = {
  status: Scalars['Int'];
  supplierId: Scalars['Long'];
};


export type QueryAccountPayableInvoiceArgs = {
  accountPayableRecordId: Scalars['Long'];
};


export type QueryAccountPayableSearchResultsArgs = {
  query: Scalars['String'];
};


export type QueryAccountPayablePodArgs = {
  accountPayableRecordId: Scalars['Long'];
};


export type QueryPaymentFailedErrorMessageArgs = {
  accountPayableRecordId: Scalars['Long'];
};


export type QueryAccountPayableRecordLogsArgs = {
  accountPayableRecordId: Scalars['Long'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryBookingInvoiceBatchesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingInvoiceBatchFilterInput>;
  order?: Maybe<Array<BookingInvoiceBatchSortInput>>;
};


export type QueryBookingInvoiceBatchLinesArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  where?: Maybe<BookingInvoiceBatchLineFilterInput>;
  order?: Maybe<Array<BookingInvoiceBatchLineSortInput>>;
};


export type QueryBookingInvoiceBatchArgs = {
  bookingInvoiceBatchId: Scalars['Long'];
};


export type QueryGroupedPaymentRecordsByMonthArgs = {
  inputParameters: GroupedPaymentsInputParametersDtoInput;
  where?: Maybe<GroupedPaymentRecordByMonthDtoFilterInput>;
};


export type QueryPaymentRecordsByMonthArgs = {
  input: GroupedPaymentsInputParametersDtoInput;
  where?: Maybe<PaymentRecordByMonthDtoFilterInput>;
};


export type QueryPaymentRecordArgs = {
  bookingInvoiceId: Scalars['Long'];
};


export type QueryPolicyRolesArgs = {
  role: Scalars['String'];
};

export enum QuoteInfoStatus {
  Accepted = 'ACCEPTED',
  Posted = 'POSTED',
  Dismissed = 'DISMISSED',
  Cancelled = 'CANCELLED'
}

export type QuoteLostDtoInput = {
  bookingId: Scalars['ID'];
  quoteLostReasonId: Scalars['ID'];
  quoteLostDetail: Scalars['String'];
};

export type QuoteLostReason = {
  __typename?: 'QuoteLostReason';
  code?: Maybe<Scalars['String']>;
  bookings: Array<Booking>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type QuoteLostReasonFilterInput = {
  and?: Maybe<Array<QuoteLostReasonFilterInput>>;
  or?: Maybe<Array<QuoteLostReasonFilterInput>>;
  code?: Maybe<StringOperationFilterInput>;
  bookings?: Maybe<ListFilterInputTypeOfBookingFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type QuoteLostReasonSortInput = {
  code?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type ReasonCode = {
  __typename?: 'ReasonCode';
  customerId: Scalars['Long'];
  customer: Customer;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  bookingAddresses: Array<BookingAddress>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ReasonCodeFilterInput = {
  and?: Maybe<Array<ReasonCodeFilterInput>>;
  or?: Maybe<Array<ReasonCodeFilterInput>>;
  customerId?: Maybe<LongOperationFilterInput>;
  customer?: Maybe<CustomerFilterInput>;
  code?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  bookingAddresses?: Maybe<ListFilterInputTypeOfBookingAddressFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ReasonCodeSortInput = {
  customerId?: Maybe<SortEnumType>;
  customer?: Maybe<CustomerSortInput>;
  code?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type RefreshPaymentStatusDtoInput = {
  all: Scalars['Boolean'];
};

export type ReportCell = {
  __typename?: 'ReportCell';
  value?: Maybe<Scalars['String']>;
  type: ReportCellType;
  numberFormatId: Scalars['Int'];
  isBold: Scalars['Boolean'];
  isFilled: Scalars['Boolean'];
  formulaA1?: Maybe<Scalars['String']>;
  formulaR1C1?: Maybe<Scalars['String']>;
  hyperlink?: Maybe<Scalars['String']>;
};

export enum ReportCellType {
  Numeric = 'NUMERIC',
  String = 'STRING',
  Formula = 'FORMULA',
  Blank = 'BLANK',
  Boolean = 'BOOLEAN',
  Error = 'ERROR',
  DateTime = 'DATE_TIME',
  Date = 'DATE',
  Time = 'TIME',
  Hyperlink = 'HYPERLINK',
  Unknown = 'UNKNOWN'
}

export type ReportRow = {
  __typename?: 'ReportRow';
  cells: Array<ReportCell>;
};

export type SalesCommissionReportInputDtoInput = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  salesName?: Maybe<Scalars['String']>;
};

export type ScheduledBookingDate = {
  __typename?: 'ScheduledBookingDate';
  templateId: Scalars['Long'];
  template: ScheduledBookingTemplate;
  executeOnDate: Scalars['DateTime'];
  taskId?: Maybe<Scalars['Long']>;
  task?: Maybe<ScheduledBookingTask>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ScheduledBookingDateFilterInput = {
  and?: Maybe<Array<ScheduledBookingDateFilterInput>>;
  or?: Maybe<Array<ScheduledBookingDateFilterInput>>;
  templateId?: Maybe<LongOperationFilterInput>;
  template?: Maybe<ScheduledBookingTemplateFilterInput>;
  executeOnDate?: Maybe<DateTimeOperationFilterInput>;
  taskId?: Maybe<LongOperationFilterInput>;
  task?: Maybe<ScheduledBookingTaskFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ScheduledBookingDateSortInput = {
  templateId?: Maybe<SortEnumType>;
  template?: Maybe<ScheduledBookingTemplateSortInput>;
  executeOnDate?: Maybe<SortEnumType>;
  taskId?: Maybe<SortEnumType>;
  task?: Maybe<ScheduledBookingTaskSortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ScheduledBookingDatesConnection = {
  __typename?: 'ScheduledBookingDatesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ScheduledBookingDatesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ScheduledBookingDate>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ScheduledBookingDatesEdge = {
  __typename?: 'ScheduledBookingDatesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledBookingDate;
};

export enum ScheduledBookingProcessOnType {
  SameDay = 'SAME_DAY',
  DayBefore = 'DAY_BEFORE'
}

export type ScheduledBookingProcessOnTypeOperationFilterInput = {
  eq?: Maybe<ScheduledBookingProcessOnType>;
  neq?: Maybe<ScheduledBookingProcessOnType>;
  in?: Maybe<Array<ScheduledBookingProcessOnType>>;
  nin?: Maybe<Array<ScheduledBookingProcessOnType>>;
};

export type ScheduledBookingTask = {
  __typename?: 'ScheduledBookingTask';
  templateId: Scalars['Long'];
  template: ScheduledBookingTemplate;
  date?: Maybe<ScheduledBookingDate>;
  dueByDateTime: Scalars['DateTime'];
  status: ScheduledTaskStatus;
  errorMessage?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['Long']>;
  booking?: Maybe<Booking>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ScheduledBookingTaskFilterInput = {
  and?: Maybe<Array<ScheduledBookingTaskFilterInput>>;
  or?: Maybe<Array<ScheduledBookingTaskFilterInput>>;
  templateId?: Maybe<LongOperationFilterInput>;
  template?: Maybe<ScheduledBookingTemplateFilterInput>;
  date?: Maybe<ScheduledBookingDateFilterInput>;
  dueByDateTime?: Maybe<DateTimeOperationFilterInput>;
  status?: Maybe<ScheduledTaskStatusOperationFilterInput>;
  errorMessage?: Maybe<StringOperationFilterInput>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ScheduledBookingTaskSortInput = {
  templateId?: Maybe<SortEnumType>;
  template?: Maybe<ScheduledBookingTemplateSortInput>;
  date?: Maybe<ScheduledBookingDateSortInput>;
  dueByDateTime?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  errorMessage?: Maybe<SortEnumType>;
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ScheduledBookingTasksConnection = {
  __typename?: 'ScheduledBookingTasksConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ScheduledBookingTasksEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ScheduledBookingTask>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ScheduledBookingTasksEdge = {
  __typename?: 'ScheduledBookingTasksEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledBookingTask;
};

export type ScheduledBookingTemplate = {
  __typename?: 'ScheduledBookingTemplate';
  weekdaysCollection: Array<Weekday>;
  processOnOnwardsTotalSeconds?: Maybe<Scalars['Float']>;
  bookingId: Scalars['Long'];
  booking: Booking;
  weekdays: Weekday;
  processOn: ScheduledBookingProcessOnType;
  processOnOnwards?: Maybe<Scalars['TimeSpan']>;
  dates: Array<ScheduledBookingDate>;
  tasks: Array<ScheduledBookingTask>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ScheduledBookingTemplateFilterInput = {
  and?: Maybe<Array<ScheduledBookingTemplateFilterInput>>;
  or?: Maybe<Array<ScheduledBookingTemplateFilterInput>>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  weekdays?: Maybe<WeekdayOperationFilterInput>;
  processOn?: Maybe<ScheduledBookingProcessOnTypeOperationFilterInput>;
  processOnOnwards?: Maybe<TimeSpanOperationFilterInput>;
  dates?: Maybe<ListFilterInputTypeOfScheduledBookingDateFilterInput>;
  tasks?: Maybe<ListFilterInputTypeOfScheduledBookingTaskFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ScheduledBookingTemplateSortInput = {
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  weekdays?: Maybe<SortEnumType>;
  processOn?: Maybe<SortEnumType>;
  processOnOnwards?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type ScheduledExchangeRateTask = {
  __typename?: 'ScheduledExchangeRateTask';
  status: ScheduledTaskStatus;
  dueByDateTime: Scalars['DateTime'];
  templateId: Scalars['Long'];
  template: ScheduledExchangeRateTemplate;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ScheduledExchangeRateTaskFilterInput = {
  and?: Maybe<Array<ScheduledExchangeRateTaskFilterInput>>;
  or?: Maybe<Array<ScheduledExchangeRateTaskFilterInput>>;
  status?: Maybe<ScheduledTaskStatusOperationFilterInput>;
  dueByDateTime?: Maybe<DateTimeOperationFilterInput>;
  templateId?: Maybe<LongOperationFilterInput>;
  template?: Maybe<ScheduledExchangeRateTemplateFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ScheduledExchangeRateTaskSortInput = {
  status?: Maybe<SortEnumType>;
  dueByDateTime?: Maybe<SortEnumType>;
  templateId?: Maybe<SortEnumType>;
  template?: Maybe<ScheduledExchangeRateTemplateSortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ScheduledExchangeRateTasksConnection = {
  __typename?: 'ScheduledExchangeRateTasksConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ScheduledExchangeRateTasksEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ScheduledExchangeRateTask>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ScheduledExchangeRateTasksEdge = {
  __typename?: 'ScheduledExchangeRateTasksEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledExchangeRateTask;
};

export type ScheduledExchangeRateTemplate = {
  __typename?: 'ScheduledExchangeRateTemplate';
  dateToToday: Scalars['DateTime'];
  currencyId: Scalars['Long'];
  currency: Currency;
  date: Scalars['DateTime'];
  processOnOnwards?: Maybe<Scalars['TimeSpan']>;
  tasks: Array<ScheduledExchangeRateTask>;
  processOnOnwardsTotalSeconds?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type ScheduledExchangeRateTemplateFilterInput = {
  and?: Maybe<Array<ScheduledExchangeRateTemplateFilterInput>>;
  or?: Maybe<Array<ScheduledExchangeRateTemplateFilterInput>>;
  currencyId?: Maybe<LongOperationFilterInput>;
  currency?: Maybe<CurrencyFilterInput>;
  date?: Maybe<DateTimeOperationFilterInput>;
  processOnOnwards?: Maybe<TimeSpanOperationFilterInput>;
  tasks?: Maybe<ListFilterInputTypeOfScheduledExchangeRateTaskFilterInput>;
  processOnOnwardsTotalSeconds?: Maybe<FloatOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type ScheduledExchangeRateTemplateSortInput = {
  currencyId?: Maybe<SortEnumType>;
  currency?: Maybe<CurrencySortInput>;
  date?: Maybe<SortEnumType>;
  processOnOnwards?: Maybe<SortEnumType>;
  processOnOnwardsTotalSeconds?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type ScheduledExchangeRateTemplatesConnection = {
  __typename?: 'ScheduledExchangeRateTemplatesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ScheduledExchangeRateTemplatesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ScheduledExchangeRateTemplate>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ScheduledExchangeRateTemplatesEdge = {
  __typename?: 'ScheduledExchangeRateTemplatesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledExchangeRateTemplate;
};

export type ScheduledPaymentBookingInvoice = {
  __typename?: 'ScheduledPaymentBookingInvoice';
  id: Scalars['UUID'];
  scheduledDate: Scalars['DateTime'];
  bookingInvoice: BookingInvoice;
  /** @deprecated Use payment status */
  status?: Maybe<PaymentStatus>;
  paymentStatus?: Maybe<AccountPayableRecordStatus>;
  paymentAmount: Scalars['Decimal'];
  currency: Scalars['String'];
};

export type ScheduledPaymentBookingInvoiceFilterInput = {
  and?: Maybe<Array<ScheduledPaymentBookingInvoiceFilterInput>>;
  or?: Maybe<Array<ScheduledPaymentBookingInvoiceFilterInput>>;
  id?: Maybe<UuidOperationFilterInput>;
  scheduledDate?: Maybe<DateTimeOperationFilterInput>;
  bookingInvoice?: Maybe<BookingInvoiceFilterInput>;
  status?: Maybe<NullableOfPaymentStatusOperationFilterInput>;
  paymentStatus?: Maybe<NullableOfAccountPayableRecordStatusOperationFilterInput>;
  paymentAmount?: Maybe<DecimalOperationFilterInput>;
  currency?: Maybe<StringOperationFilterInput>;
};

export enum ScheduledTaskStatus {
  New = 'NEW',
  Processed = 'PROCESSED',
  Error = 'ERROR'
}

export type ScheduledTaskStatusOperationFilterInput = {
  eq?: Maybe<ScheduledTaskStatus>;
  neq?: Maybe<ScheduledTaskStatus>;
  in?: Maybe<Array<ScheduledTaskStatus>>;
  nin?: Maybe<Array<ScheduledTaskStatus>>;
};

export type SearchResultDto = {
  __typename?: 'SearchResultDto';
  entityName: Scalars['String'];
  entityId: Scalars['Long'];
  description: Scalars['String'];
  currencySymbol?: Maybe<Scalars['String']>;
};

export type SentEmail = {
  __typename?: 'SentEmail';
  hasErrors: Scalars['Boolean'];
  methodName: Scalars['String'];
  to: Scalars['String'];
  cc?: Maybe<Scalars['String']>;
  bcc?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  hasAttachments: Scalars['Boolean'];
  isSuccessful: Scalars['Boolean'];
  sendGridMessageId?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type SentEmailFilterInput = {
  and?: Maybe<Array<SentEmailFilterInput>>;
  or?: Maybe<Array<SentEmailFilterInput>>;
  methodName?: Maybe<StringOperationFilterInput>;
  to?: Maybe<StringOperationFilterInput>;
  cc?: Maybe<StringOperationFilterInput>;
  bcc?: Maybe<StringOperationFilterInput>;
  subject?: Maybe<StringOperationFilterInput>;
  body?: Maybe<StringOperationFilterInput>;
  hasAttachments?: Maybe<BooleanOperationFilterInput>;
  isSuccessful?: Maybe<BooleanOperationFilterInput>;
  sendGridMessageId?: Maybe<StringOperationFilterInput>;
  errorMessages?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type SentEmailSortInput = {
  methodName?: Maybe<SortEnumType>;
  to?: Maybe<SortEnumType>;
  cc?: Maybe<SortEnumType>;
  bcc?: Maybe<SortEnumType>;
  subject?: Maybe<SortEnumType>;
  body?: Maybe<SortEnumType>;
  hasAttachments?: Maybe<SortEnumType>;
  isSuccessful?: Maybe<SortEnumType>;
  sendGridMessageId?: Maybe<SortEnumType>;
  errorMessages?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type SentEmailsConnection = {
  __typename?: 'SentEmailsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<SentEmailsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SentEmail>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SentEmailsEdge = {
  __typename?: 'SentEmailsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SentEmail;
};

export enum ServiceType {
  None = 'NONE',
  DomesticDedicated = 'DOMESTIC_DEDICATED',
  DomesticParcels = 'DOMESTIC_PARCELS',
  DomesticPallets = 'DOMESTIC_PALLETS',
  EuropeanDedicated = 'EUROPEAN_DEDICATED',
  EuropeanGroupage = 'EUROPEAN_GROUPAGE',
  InternationalParcels = 'INTERNATIONAL_PARCELS',
  InternationalAirFreight = 'INTERNATIONAL_AIR_FREIGHT',
  SeaFreight = 'SEA_FREIGHT',
  CustomsClearanceAgent = 'CUSTOMS_CLEARANCE_AGENT'
}

export type ServiceTypeOperationFilterInput = {
  eq?: Maybe<ServiceType>;
  neq?: Maybe<ServiceType>;
  in?: Maybe<Array<ServiceType>>;
  nin?: Maybe<Array<ServiceType>>;
};

export type SetPushNotificationTokenDtoInput = {
  token: Scalars['String'];
  platform: TokenPlatformType;
};

export type SmartApprovalPaymentDtoInput = {
  accountPayableIds?: Maybe<Array<Scalars['Long']>>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StandardOperatingProcedure = {
  __typename?: 'StandardOperatingProcedure';
  isActive: Scalars['Boolean'];
  description: Scalars['String'];
  steps: Scalars['String'];
  frequentlyAskedQuestions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  driverInstructions?: Maybe<Scalars['String']>;
  isAttachedToAllCustomers: Scalars['Boolean'];
  customers: Array<CustomerStandardOperatingProcedure>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type StandardOperatingProcedureFilterInput = {
  and?: Maybe<Array<StandardOperatingProcedureFilterInput>>;
  or?: Maybe<Array<StandardOperatingProcedureFilterInput>>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  steps?: Maybe<StringOperationFilterInput>;
  frequentlyAskedQuestions?: Maybe<StringOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  driverInstructions?: Maybe<StringOperationFilterInput>;
  isAttachedToAllCustomers?: Maybe<BooleanOperationFilterInput>;
  customers?: Maybe<ListFilterInputTypeOfCustomerStandardOperatingProcedureFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

/** A connection to a list of items. */
export type StandardOperatingProcedureHistoryConnection = {
  __typename?: 'StandardOperatingProcedureHistoryConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<StandardOperatingProcedureHistoryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<StandardOperatingProcedureHistoryDto>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

export type StandardOperatingProcedureHistoryDto = {
  __typename?: 'StandardOperatingProcedureHistoryDto';
  id: Scalars['UUID'];
  sopId: Scalars['Long'];
  name: Scalars['String'];
  description: Scalars['String'];
  isActive: Scalars['Boolean'];
  steps: Scalars['String'];
  frequentlyAskedQuestions?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  driverInstructions?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isAttachedToAllCustomers: Scalars['Boolean'];
};

export type StandardOperatingProcedureHistoryDtoFilterInput = {
  and?: Maybe<Array<StandardOperatingProcedureHistoryDtoFilterInput>>;
  or?: Maybe<Array<StandardOperatingProcedureHistoryDtoFilterInput>>;
  id?: Maybe<UuidOperationFilterInput>;
  sopId?: Maybe<LongOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  isActive?: Maybe<BooleanOperationFilterInput>;
  steps?: Maybe<StringOperationFilterInput>;
  frequentlyAskedQuestions?: Maybe<StringOperationFilterInput>;
  notes?: Maybe<StringOperationFilterInput>;
  driverInstructions?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isAttachedToAllCustomers?: Maybe<BooleanOperationFilterInput>;
};

export type StandardOperatingProcedureHistoryDtoSortInput = {
  id?: Maybe<SortEnumType>;
  sopId?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  isActive?: Maybe<SortEnumType>;
  steps?: Maybe<SortEnumType>;
  frequentlyAskedQuestions?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  driverInstructions?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isAttachedToAllCustomers?: Maybe<SortEnumType>;
};

/** An edge in a connection. */
export type StandardOperatingProcedureHistoryEdge = {
  __typename?: 'StandardOperatingProcedureHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StandardOperatingProcedureHistoryDto;
};

export type StandardOperatingProcedureSortInput = {
  isActive?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  steps?: Maybe<SortEnumType>;
  frequentlyAskedQuestions?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  driverInstructions?: Maybe<SortEnumType>;
  isAttachedToAllCustomers?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type StandardOperatingProceduresConnection = {
  __typename?: 'StandardOperatingProceduresConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<StandardOperatingProceduresEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<StandardOperatingProcedure>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StandardOperatingProceduresEdge = {
  __typename?: 'StandardOperatingProceduresEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StandardOperatingProcedure;
};

export enum StopTimeRangeType {
  Asap = 'ASAP',
  Between = 'BETWEEN'
}

export type StrategicPartner = {
  __typename?: 'StrategicPartner';
  serviceType: ServiceType;
  vatIdNumber?: Maybe<Scalars['String']>;
  eoriNumber?: Maybe<Scalars['String']>;
  xeroPurchaseCode?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Long']>;
  country?: Maybe<Country>;
  email?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  paymentTerm: PaymentTerm;
  contacts: Array<StrategicPartnerContact>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type StrategicPartnerContact = {
  __typename?: 'StrategicPartnerContact';
  strategicPartnerId: Scalars['Long'];
  strategicPartner: StrategicPartner;
  isDefault: Scalars['Boolean'];
  telephoneNumber: Scalars['String'];
  email: Scalars['String'];
  additionalEmails: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobRole?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type StrategicPartnerContactFilterInput = {
  and?: Maybe<Array<StrategicPartnerContactFilterInput>>;
  or?: Maybe<Array<StrategicPartnerContactFilterInput>>;
  strategicPartnerId?: Maybe<LongOperationFilterInput>;
  strategicPartner?: Maybe<StrategicPartnerFilterInput>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  telephoneNumber?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  additionalEmails?: Maybe<StringOperationFilterInput>;
  department?: Maybe<StringOperationFilterInput>;
  jobRole?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type StrategicPartnerContactSortInput = {
  strategicPartnerId?: Maybe<SortEnumType>;
  strategicPartner?: Maybe<StrategicPartnerSortInput>;
  isDefault?: Maybe<SortEnumType>;
  telephoneNumber?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  additionalEmails?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  jobRole?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type StrategicPartnerContactsConnection = {
  __typename?: 'StrategicPartnerContactsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<StrategicPartnerContactsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<StrategicPartnerContact>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StrategicPartnerContactsEdge = {
  __typename?: 'StrategicPartnerContactsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StrategicPartnerContact;
};

export type StrategicPartnerFilterInput = {
  and?: Maybe<Array<StrategicPartnerFilterInput>>;
  or?: Maybe<Array<StrategicPartnerFilterInput>>;
  serviceType?: Maybe<ServiceTypeOperationFilterInput>;
  vatIdNumber?: Maybe<StringOperationFilterInput>;
  eoriNumber?: Maybe<StringOperationFilterInput>;
  xeroPurchaseCode?: Maybe<StringOperationFilterInput>;
  postcode?: Maybe<StringOperationFilterInput>;
  addressLine1?: Maybe<StringOperationFilterInput>;
  addressLine2?: Maybe<StringOperationFilterInput>;
  city?: Maybe<StringOperationFilterInput>;
  countryId?: Maybe<LongOperationFilterInput>;
  country?: Maybe<CountryFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  telephoneNumber?: Maybe<StringOperationFilterInput>;
  paymentTerm?: Maybe<PaymentTermOperationFilterInput>;
  contacts?: Maybe<ListFilterInputTypeOfStrategicPartnerContactFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type StrategicPartnerSortInput = {
  serviceType?: Maybe<SortEnumType>;
  vatIdNumber?: Maybe<SortEnumType>;
  eoriNumber?: Maybe<SortEnumType>;
  xeroPurchaseCode?: Maybe<SortEnumType>;
  postcode?: Maybe<SortEnumType>;
  addressLine1?: Maybe<SortEnumType>;
  addressLine2?: Maybe<SortEnumType>;
  city?: Maybe<SortEnumType>;
  countryId?: Maybe<SortEnumType>;
  country?: Maybe<CountrySortInput>;
  email?: Maybe<SortEnumType>;
  telephoneNumber?: Maybe<SortEnumType>;
  paymentTerm?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type StrategicPartnersConnection = {
  __typename?: 'StrategicPartnersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<StrategicPartnersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<StrategicPartner>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StrategicPartnersEdge = {
  __typename?: 'StrategicPartnersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: StrategicPartner;
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type SubDriversConnection = {
  __typename?: 'SubDriversConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<SubDriversEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Driver>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SubDriversEdge = {
  __typename?: 'SubDriversEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Driver;
};

export type SubmitOnRouteAddressDtoInput = {
  bookingAddressId: Scalars['ID'];
  geoEtaInput: UpdateGeoEtaDtoInput;
};

export type Subscription = {
  __typename?: 'Subscription';
  onCourierExchangeQuoteCreated: CourierExchangeQuote;
  onBookAdded: Array<TariffCategory>;
  onTaskAdded: Array<Task>;
  onTaskUpdated: Array<Task>;
  onCommentaryCreated: Commentary;
  onCommentaryUpdated: Commentary;
  onCommentaryDeleted: Scalars['Long'];
  onNewNotification: Notification;
  onBookingUpdated: BookingUpdatedDto;
};


export type SubscriptionOnNewNotificationArgs = {
  username: Scalars['String'];
};


export type SubscriptionOnBookingUpdatedArgs = {
  bookingId: Scalars['Long'];
};

export type SystemTaskSetting = {
  __typename?: 'SystemTaskSetting';
  interval: Scalars['Long'];
  taskCaption: Scalars['String'];
  taskRelatedTo: TaskRelatedTo;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type SystemTaskSettingFilterInput = {
  and?: Maybe<Array<SystemTaskSettingFilterInput>>;
  or?: Maybe<Array<SystemTaskSettingFilterInput>>;
  interval?: Maybe<LongOperationFilterInput>;
  taskCaption?: Maybe<StringOperationFilterInput>;
  taskRelatedTo?: Maybe<TaskRelatedToOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type Tariff = {
  __typename?: 'Tariff';
  basedOnTariffId?: Maybe<Scalars['Long']>;
  tariffCategoryId: Scalars['ID'];
  tariffCategory: TariffCategory;
  xeroServiceCode?: Maybe<Scalars['String']>;
  vatCategoryId: Scalars['ID'];
  vatCategory: VatCategory;
  xeroCodeId?: Maybe<Scalars['ID']>;
  xeroCode?: Maybe<XeroCode>;
  type: TariffType;
  minMiles?: Maybe<Scalars['Decimal']>;
  minCharge?: Maybe<Scalars['Decimal']>;
  rate: Scalars['Decimal'];
  customers: Array<CustomerTariff>;
  isAttachedToAllCustomers: Scalars['Boolean'];
  baseUnit: TariffBaseUnit;
  isWaitAndReturnEnabled: Scalars['Boolean'];
  isDropChargeEnabled: Scalars['Boolean'];
  isUseDefaultDropCharge: Scalars['Boolean'];
  dropChargeRate?: Maybe<Scalars['Decimal']>;
  isDpdOvernightEnabled: Scalars['Boolean'];
  isOverwriteDefaultWaitingTime: Scalars['Boolean'];
  collectionAllowanceMinutes?: Maybe<Scalars['Int']>;
  deliveryAllowanceMinutes?: Maybe<Scalars['Int']>;
  calculateCharge: TariffCalculateCharge;
  excessWaitingTimeCharge?: Maybe<Scalars['Decimal']>;
  excessWaitingTimeMinutesPer?: Maybe<Scalars['Int']>;
  isUlezEnabled: Scalars['Boolean'];
  ulezCustomerCharge?: Maybe<Scalars['Decimal']>;
  ulezDriverCost?: Maybe<Scalars['Decimal']>;
  loadCapacity?: Maybe<Scalars['Decimal']>;
  palletCapacity?: Maybe<Scalars['Int']>;
  visibleOn: TariffVisibleOn;
  isSaturdayDelivery: Scalars['Boolean'];
  isExternal: Scalars['Boolean'];
  sizeDescription?: Maybe<Scalars['String']>;
  visibilitySequenceNumber?: Maybe<Scalars['Int']>;
  iconUrl?: Maybe<Scalars['String']>;
  /** Vehicle capacity */
  capacity?: Maybe<Scalars['String']>;
  tariffIconId?: Maybe<Scalars['ID']>;
  tariffIcon?: Maybe<TariffIcon>;
  bookings: Array<Booking>;
  congestionZones: Array<CongestionZoneTariff>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export enum TariffBaseUnit {
  Kilometer = 'KILOMETER',
  Mile = 'MILE',
  Kilogram = 'KILOGRAM',
  Ton = 'TON'
}

export type TariffBaseUnitOperationFilterInput = {
  eq?: Maybe<TariffBaseUnit>;
  neq?: Maybe<TariffBaseUnit>;
  in?: Maybe<Array<TariffBaseUnit>>;
  nin?: Maybe<Array<TariffBaseUnit>>;
};

export enum TariffCalculateCharge {
  ExpressWaitingFee = 'EXPRESS_WAITING_FEE',
  FlatFee = 'FLAT_FEE'
}

export type TariffCalculateChargeOperationFilterInput = {
  eq?: Maybe<TariffCalculateCharge>;
  neq?: Maybe<TariffCalculateCharge>;
  in?: Maybe<Array<TariffCalculateCharge>>;
  nin?: Maybe<Array<TariffCalculateCharge>>;
};

export type TariffCategory = {
  __typename?: 'TariffCategory';
  tariffs: Array<Tariff>;
  courierExchangeVehicleSize?: Maybe<FixedLoadDtoMinVehicleSize>;
  hasSpotRate: Scalars['Boolean'];
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type TariffCategoryFilterInput = {
  and?: Maybe<Array<TariffCategoryFilterInput>>;
  or?: Maybe<Array<TariffCategoryFilterInput>>;
  tariffs?: Maybe<ListFilterInputTypeOfTariffFilterInput>;
  courierExchangeVehicleSize?: Maybe<NullableOfFixedLoadDtoMinVehicleSizeOperationFilterInput>;
  hasSpotRate?: Maybe<BooleanOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type TariffCategorySortInput = {
  courierExchangeVehicleSize?: Maybe<SortEnumType>;
  hasSpotRate?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type TariffFilterInput = {
  and?: Maybe<Array<TariffFilterInput>>;
  or?: Maybe<Array<TariffFilterInput>>;
  basedOnTariffId?: Maybe<LongOperationFilterInput>;
  tariffCategoryId?: Maybe<LongOperationFilterInput>;
  tariffCategory?: Maybe<TariffCategoryFilterInput>;
  xeroServiceCode?: Maybe<StringOperationFilterInput>;
  vatCategoryId?: Maybe<LongOperationFilterInput>;
  vatCategory?: Maybe<VatCategoryFilterInput>;
  xeroCodeId?: Maybe<LongOperationFilterInput>;
  xeroCode?: Maybe<XeroCodeFilterInput>;
  type?: Maybe<TariffTypeOperationFilterInput>;
  minMiles?: Maybe<DecimalOperationFilterInput>;
  minCharge?: Maybe<DecimalOperationFilterInput>;
  rate?: Maybe<DecimalOperationFilterInput>;
  customers?: Maybe<ListFilterInputTypeOfCustomerTariffFilterInput>;
  isAttachedToAllCustomers?: Maybe<BooleanOperationFilterInput>;
  baseUnit?: Maybe<TariffBaseUnitOperationFilterInput>;
  isWaitAndReturnEnabled?: Maybe<BooleanOperationFilterInput>;
  isDropChargeEnabled?: Maybe<BooleanOperationFilterInput>;
  isUseDefaultDropCharge?: Maybe<BooleanOperationFilterInput>;
  dropChargeRate?: Maybe<DecimalOperationFilterInput>;
  isDpdOvernightEnabled?: Maybe<BooleanOperationFilterInput>;
  isOverwriteDefaultWaitingTime?: Maybe<BooleanOperationFilterInput>;
  collectionAllowanceMinutes?: Maybe<IntOperationFilterInput>;
  deliveryAllowanceMinutes?: Maybe<IntOperationFilterInput>;
  calculateCharge?: Maybe<TariffCalculateChargeOperationFilterInput>;
  excessWaitingTimeCharge?: Maybe<DecimalOperationFilterInput>;
  excessWaitingTimeMinutesPer?: Maybe<IntOperationFilterInput>;
  isUlezEnabled?: Maybe<BooleanOperationFilterInput>;
  ulezCustomerCharge?: Maybe<DecimalOperationFilterInput>;
  ulezDriverCost?: Maybe<DecimalOperationFilterInput>;
  loadCapacity?: Maybe<DecimalOperationFilterInput>;
  palletCapacity?: Maybe<IntOperationFilterInput>;
  visibleOn?: Maybe<TariffVisibleOnOperationFilterInput>;
  isSaturdayDelivery?: Maybe<BooleanOperationFilterInput>;
  isExternal?: Maybe<BooleanOperationFilterInput>;
  sizeDescription?: Maybe<StringOperationFilterInput>;
  visibilitySequenceNumber?: Maybe<IntOperationFilterInput>;
  iconUrl?: Maybe<StringOperationFilterInput>;
  /** Vehicle capacity */
  capacity?: Maybe<StringOperationFilterInput>;
  tariffIconId?: Maybe<LongOperationFilterInput>;
  tariffIcon?: Maybe<TariffIconFilterInput>;
  bookings?: Maybe<ListFilterInputTypeOfBookingFilterInput>;
  congestionZones?: Maybe<ListFilterInputTypeOfCongestionZoneTariffFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type TariffIcon = {
  __typename?: 'TariffIcon';
  fileId?: Maybe<Scalars['Long']>;
  file?: Maybe<File>;
  vehicleCapacity?: Maybe<Scalars['String']>;
  tariffs: Array<Tariff>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type TariffIconFilterInput = {
  and?: Maybe<Array<TariffIconFilterInput>>;
  or?: Maybe<Array<TariffIconFilterInput>>;
  fileId?: Maybe<LongOperationFilterInput>;
  file?: Maybe<FileFilterInput>;
  vehicleCapacity?: Maybe<StringOperationFilterInput>;
  tariffs?: Maybe<ListFilterInputTypeOfTariffFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type TariffIconSortInput = {
  fileId?: Maybe<SortEnumType>;
  file?: Maybe<FileSortInput>;
  vehicleCapacity?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type TariffPagedDto = {
  __typename?: 'TariffPagedDto';
  id: Scalars['Long'];
  customer: Scalars['String'];
  category: Scalars['String'];
  type: TariffType;
  name: Scalars['String'];
  rate: Scalars['Decimal'];
  minMiles?: Maybe<Scalars['Decimal']>;
  minCharge?: Maybe<Scalars['Decimal']>;
  baseUnit: TariffBaseUnit;
  isExternal: Scalars['Boolean'];
  visibilitySequenceNumber?: Maybe<Scalars['Int']>;
  iconUrl?: Maybe<Scalars['String']>;
  tariffIconId?: Maybe<Scalars['ID']>;
  tariffIcon?: Maybe<TariffIcon>;
  capacity?: Maybe<Scalars['String']>;
};

export type TariffPagedDtoFilterInput = {
  and?: Maybe<Array<TariffPagedDtoFilterInput>>;
  or?: Maybe<Array<TariffPagedDtoFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  customer?: Maybe<StringOperationFilterInput>;
  category?: Maybe<StringOperationFilterInput>;
  type?: Maybe<TariffTypeOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  rate?: Maybe<DecimalOperationFilterInput>;
  minMiles?: Maybe<DecimalOperationFilterInput>;
  minCharge?: Maybe<DecimalOperationFilterInput>;
  baseUnit?: Maybe<TariffBaseUnitOperationFilterInput>;
  isExternal?: Maybe<BooleanOperationFilterInput>;
  visibilitySequenceNumber?: Maybe<IntOperationFilterInput>;
  iconUrl?: Maybe<StringOperationFilterInput>;
  tariffIconId?: Maybe<LongOperationFilterInput>;
  tariffIcon?: Maybe<TariffIconFilterInput>;
  capacity?: Maybe<StringOperationFilterInput>;
};

export type TariffPagedDtoSortInput = {
  id?: Maybe<SortEnumType>;
  customer?: Maybe<SortEnumType>;
  category?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  rate?: Maybe<SortEnumType>;
  minMiles?: Maybe<SortEnumType>;
  minCharge?: Maybe<SortEnumType>;
  baseUnit?: Maybe<SortEnumType>;
  isExternal?: Maybe<SortEnumType>;
  visibilitySequenceNumber?: Maybe<SortEnumType>;
  iconUrl?: Maybe<SortEnumType>;
  tariffIconId?: Maybe<SortEnumType>;
  tariffIcon?: Maybe<TariffIconSortInput>;
  capacity?: Maybe<SortEnumType>;
};

export type TariffSortInput = {
  basedOnTariffId?: Maybe<SortEnumType>;
  tariffCategoryId?: Maybe<SortEnumType>;
  tariffCategory?: Maybe<TariffCategorySortInput>;
  xeroServiceCode?: Maybe<SortEnumType>;
  vatCategoryId?: Maybe<SortEnumType>;
  vatCategory?: Maybe<VatCategorySortInput>;
  xeroCodeId?: Maybe<SortEnumType>;
  xeroCode?: Maybe<XeroCodeSortInput>;
  type?: Maybe<SortEnumType>;
  minMiles?: Maybe<SortEnumType>;
  minCharge?: Maybe<SortEnumType>;
  rate?: Maybe<SortEnumType>;
  isAttachedToAllCustomers?: Maybe<SortEnumType>;
  baseUnit?: Maybe<SortEnumType>;
  isWaitAndReturnEnabled?: Maybe<SortEnumType>;
  isDropChargeEnabled?: Maybe<SortEnumType>;
  isUseDefaultDropCharge?: Maybe<SortEnumType>;
  dropChargeRate?: Maybe<SortEnumType>;
  isDpdOvernightEnabled?: Maybe<SortEnumType>;
  isOverwriteDefaultWaitingTime?: Maybe<SortEnumType>;
  collectionAllowanceMinutes?: Maybe<SortEnumType>;
  deliveryAllowanceMinutes?: Maybe<SortEnumType>;
  calculateCharge?: Maybe<SortEnumType>;
  excessWaitingTimeCharge?: Maybe<SortEnumType>;
  excessWaitingTimeMinutesPer?: Maybe<SortEnumType>;
  isUlezEnabled?: Maybe<SortEnumType>;
  ulezCustomerCharge?: Maybe<SortEnumType>;
  ulezDriverCost?: Maybe<SortEnumType>;
  loadCapacity?: Maybe<SortEnumType>;
  palletCapacity?: Maybe<SortEnumType>;
  visibleOn?: Maybe<SortEnumType>;
  isSaturdayDelivery?: Maybe<SortEnumType>;
  isExternal?: Maybe<SortEnumType>;
  sizeDescription?: Maybe<SortEnumType>;
  visibilitySequenceNumber?: Maybe<SortEnumType>;
  iconUrl?: Maybe<SortEnumType>;
  /** Vehicle capacity */
  capacity?: Maybe<SortEnumType>;
  tariffIconId?: Maybe<SortEnumType>;
  tariffIcon?: Maybe<TariffIconSortInput>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum TariffType {
  Distance = 'DISTANCE',
  Other = 'OTHER'
}

export type TariffTypeOperationFilterInput = {
  eq?: Maybe<TariffType>;
  neq?: Maybe<TariffType>;
  in?: Maybe<Array<TariffType>>;
  nin?: Maybe<Array<TariffType>>;
};

export enum TariffVisibleOn {
  Sameday = 'SAMEDAY',
  Overnight = 'OVERNIGHT'
}

export type TariffVisibleOnOperationFilterInput = {
  eq?: Maybe<TariffVisibleOn>;
  neq?: Maybe<TariffVisibleOn>;
  in?: Maybe<Array<TariffVisibleOn>>;
  nin?: Maybe<Array<TariffVisibleOn>>;
};

export type Task = {
  __typename?: 'Task';
  timeTotalSeconds?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  additionalDetail?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  time?: Maybe<Scalars['TimeSpan']>;
  status: TaskStatus;
  bookingId?: Maybe<Scalars['Long']>;
  booking?: Maybe<Booking>;
  assignedUserName: Scalars['String'];
  commentaries?: Maybe<Array<Commentary>>;
  categoryId?: Maybe<Scalars['Long']>;
  category?: Maybe<TaskCategory>;
  notifications?: Maybe<Array<Notification>>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  assignedUser: Scalars['String'];
};

export type TaskCategory = {
  __typename?: 'TaskCategory';
  colour: Scalars['String'];
  isSystemGenerated: Scalars['Boolean'];
  tasks?: Maybe<Array<Task>>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type TaskCategoryFilterInput = {
  and?: Maybe<Array<TaskCategoryFilterInput>>;
  or?: Maybe<Array<TaskCategoryFilterInput>>;
  colour?: Maybe<StringOperationFilterInput>;
  isSystemGenerated?: Maybe<BooleanOperationFilterInput>;
  tasks?: Maybe<ListFilterInputTypeOfTaskFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type TaskCategorySortInput = {
  colour?: Maybe<SortEnumType>;
  isSystemGenerated?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type TaskFilterInput = {
  and?: Maybe<Array<TaskFilterInput>>;
  or?: Maybe<Array<TaskFilterInput>>;
  description?: Maybe<StringOperationFilterInput>;
  additionalDetail?: Maybe<StringOperationFilterInput>;
  endDate?: Maybe<DateTimeOperationFilterInput>;
  time?: Maybe<TimeSpanOperationFilterInput>;
  status?: Maybe<TaskStatusOperationFilterInput>;
  bookingId?: Maybe<LongOperationFilterInput>;
  booking?: Maybe<BookingFilterInput>;
  assignedUserName?: Maybe<StringOperationFilterInput>;
  commentaries?: Maybe<ListFilterInputTypeOfCommentaryFilterInput>;
  categoryId?: Maybe<LongOperationFilterInput>;
  category?: Maybe<TaskCategoryFilterInput>;
  notifications?: Maybe<ListFilterInputTypeOfNotificationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export enum TaskRelatedTo {
  Quote = 'QUOTE'
}

export type TaskRelatedToOperationFilterInput = {
  eq?: Maybe<TaskRelatedTo>;
  neq?: Maybe<TaskRelatedTo>;
  in?: Maybe<Array<TaskRelatedTo>>;
  nin?: Maybe<Array<TaskRelatedTo>>;
};

export type TaskSortInput = {
  description?: Maybe<SortEnumType>;
  additionalDetail?: Maybe<SortEnumType>;
  endDate?: Maybe<SortEnumType>;
  time?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  bookingId?: Maybe<SortEnumType>;
  booking?: Maybe<BookingSortInput>;
  assignedUserName?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  category?: Maybe<TaskCategorySortInput>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum TaskStatus {
  New = 'NEW',
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Completed = 'COMPLETED'
}

export type TaskStatusDto = {
  __typename?: 'TaskStatusDto';
  id: Scalars['Long'];
  status: TaskStatus;
  description: Scalars['String'];
};

export type TaskStatusOperationFilterInput = {
  eq?: Maybe<TaskStatus>;
  neq?: Maybe<TaskStatus>;
  in?: Maybe<Array<TaskStatus>>;
  nin?: Maybe<Array<TaskStatus>>;
};

export type TaskUserDto = {
  __typename?: 'TaskUserDto';
  id: Scalars['Long'];
  userName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  emailConfirmed: Scalars['Boolean'];
};

export type TaskUserDtoFilterInput = {
  and?: Maybe<Array<TaskUserDtoFilterInput>>;
  or?: Maybe<Array<TaskUserDtoFilterInput>>;
  id?: Maybe<LongOperationFilterInput>;
  userName?: Maybe<StringOperationFilterInput>;
  firstName?: Maybe<StringOperationFilterInput>;
  lastName?: Maybe<StringOperationFilterInput>;
  email?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
  emailConfirmed?: Maybe<BooleanOperationFilterInput>;
};

export type TaskUserDtoSortInput = {
  id?: Maybe<SortEnumType>;
  userName?: Maybe<SortEnumType>;
  firstName?: Maybe<SortEnumType>;
  lastName?: Maybe<SortEnumType>;
  email?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
  emailConfirmed?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type TasksConnection = {
  __typename?: 'TasksConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<TasksEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Task>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TasksEdge = {
  __typename?: 'TasksEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Task;
};


export type TimeSpanOperationFilterInput = {
  eq?: Maybe<Scalars['TimeSpan']>;
  neq?: Maybe<Scalars['TimeSpan']>;
  in?: Maybe<Array<Maybe<Scalars['TimeSpan']>>>;
  nin?: Maybe<Array<Maybe<Scalars['TimeSpan']>>>;
  gt?: Maybe<Scalars['TimeSpan']>;
  ngt?: Maybe<Scalars['TimeSpan']>;
  gte?: Maybe<Scalars['TimeSpan']>;
  ngte?: Maybe<Scalars['TimeSpan']>;
  lt?: Maybe<Scalars['TimeSpan']>;
  nlt?: Maybe<Scalars['TimeSpan']>;
  lte?: Maybe<Scalars['TimeSpan']>;
  nlte?: Maybe<Scalars['TimeSpan']>;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  description: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type TimeZoneFilterInput = {
  and?: Maybe<Array<TimeZoneFilterInput>>;
  or?: Maybe<Array<TimeZoneFilterInput>>;
  description?: Maybe<StringOperationFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type TimeZoneSortInput = {
  description?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum TokenPlatformType {
  Ios = 'IOS',
  Android = 'ANDROID',
  Web = 'WEB',
  MacOs = 'MAC_OS',
  Windows = 'WINDOWS'
}

export type TokenPlatformTypeOperationFilterInput = {
  eq?: Maybe<TokenPlatformType>;
  neq?: Maybe<TokenPlatformType>;
  in?: Maybe<Array<TokenPlatformType>>;
  nin?: Maybe<Array<TokenPlatformType>>;
};



export type UpdateAccountPayableNetAmountDtoInput = {
  id: Scalars['Long'];
  net?: Maybe<Scalars['Decimal']>;
};

export type UpdateAddressEtaDtoInput = {
  id: Scalars['Long'];
  auto: Scalars['Boolean'];
  eta?: Maybe<Scalars['DateTime']>;
};

export type UpdateAddressWaitingTimeDtoInput = {
  id: Scalars['Long'];
  waitingTime?: Maybe<Scalars['Int']>;
};

export type UpdateBookingAddressAdditionalDetailsDtoInput = {
  id: Scalars['ID'];
  noteFromDriver?: Maybe<Scalars['String']>;
};

export type UpdateBookingAddressPackagesDtoInput = {
  id: Scalars['ID'];
  packages?: Maybe<Array<AddressPackageDtoInput>>;
};

export type UpdateBookingAttachmentDtoInput = {
  bookingId: Scalars['ID'];
  fileId: Scalars['ID'];
  isAvailableForDriver: Scalars['Boolean'];
  isAvailableForCustomer: Scalars['Boolean'];
  changeToPod: Scalars['Boolean'];
  bookingAddressId: Scalars['Long'];
};

export type UpdateCustomerNotificationsDtoInput = {
  id: Scalars['ID'];
  isNotificationForQuote: Scalars['Boolean'];
  isNotificationForBooking: Scalars['Boolean'];
  isNotificationForPobArrival: Scalars['Boolean'];
  isNotificationForPOB: Scalars['Boolean'];
  isNotificationForPodArrival: Scalars['Boolean'];
  isNotificationForPodPartial: Scalars['Boolean'];
  isNotificationForPOD: Scalars['Boolean'];
  isNotificationForCancellation: Scalars['Boolean'];
  isNotificationForInvoice: Scalars['Boolean'];
  isNotificationForCarded: Scalars['Boolean'];
  isNotificationForAmendment: Scalars['Boolean'];
  isNotificationForDriverAllocated: Scalars['Boolean'];
  isNotificationForReadyForAllocation: Scalars['Boolean'];
  isNotificationForInvoiceDue: Scalars['Boolean'];
};

export type UpdateCustomerProfileDtoInput = {
  customerId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  city: Scalars['String'];
  countryId?: Maybe<Scalars['ID']>;
};

export type UpdateCustomerReferencesAndNotesDtoInput = {
  id: Scalars['ID'];
  customerReferenceLabel?: Maybe<Scalars['String']>;
  isCustomerReferenceMandatory: Scalars['Boolean'];
  noteForAccount?: Maybe<Scalars['String']>;
  noteForBooking?: Maybe<Scalars['String']>;
  noteForPaperwork?: Maybe<Scalars['String']>;
  isPaperworkRequired: Scalars['Boolean'];
  noteForPackage?: Maybe<Scalars['String']>;
  instructionsForCourierExchangeDriver?: Maybe<Scalars['String']>;
};

export type UpdateDriverInformationDtoInput = {
  accountPayableRecordId: Scalars['Long'];
  xeroPurchaseCode?: Maybe<Scalars['String']>;
  vatCategoryId?: Maybe<Scalars['Int']>;
};

export type UpdateDriverPaymentAccountDtoInput = {
  currencyId: Scalars['ID'];
  bankName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountHolderName?: Maybe<Scalars['String']>;
  bankSortCode?: Maybe<Scalars['String']>;
  internationalBankAccountNumber?: Maybe<Scalars['String']>;
  paymentTerm: PaymentTerm;
  swift?: Maybe<Scalars['String']>;
};

export type UpdateDriverProfileContactsDtoInput = {
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryId: Scalars['Long'];
  postcode: Scalars['String'];
  telephoneNumber: Scalars['String'];
  email: Scalars['String'];
};

export type UpdateDriverProfileDetailsDtoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  callSign?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
};

export type UpdateDriverProfilePaymentInput = {
  useDefaultRate: Scalars['Boolean'];
  distanceRate: Scalars['Decimal'];
  minMiles: Scalars['Decimal'];
  minCharge: Scalars['Decimal'];
};

export type UpdateEmailTemplateInput = {
  id?: Maybe<Scalars['ID']>;
  type: EmailTemplateType;
  isBlindCarbonCopyEnabled: Scalars['Boolean'];
  blindCarbonCopy?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  iconUri?: Maybe<Scalars['String']>;
};

export type UpdateGeoEtaDtoInput = {
  calculateAutomatically: Scalars['Boolean'];
  eta?: Maybe<Scalars['DateTime']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
};

export type UpdatePackageOnBoardDtoInput = {
  id: Scalars['ID'];
  bookingId: Scalars['ID'];
  noteFromDriver?: Maybe<Scalars['String']>;
  waitingTime?: Maybe<Scalars['Int']>;
  signedAt: Scalars['DateTime'];
  signedByFirstName?: Maybe<Scalars['String']>;
  signedByLastName?: Maybe<Scalars['String']>;
  arrivedAt?: Maybe<Scalars['DateTime']>;
  courierServiceNumberOfItems?: Maybe<Scalars['Int']>;
  courierServiceWeight?: Maybe<Scalars['Decimal']>;
  courierServiceWeightUnit?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<AddressPackageDtoInput>>;
  signedBy?: Maybe<Scalars['String']>;
};

export type UpdateProfileDtoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  city: Scalars['String'];
  countryId: Scalars['Long'];
};

export type UpdateProofOfDeliveryDtoInput = {
  id: Scalars['ID'];
  bookingId: Scalars['ID'];
  noteFromDriver?: Maybe<Scalars['String']>;
  waitingTime?: Maybe<Scalars['Int']>;
  signedAt: Scalars['DateTime'];
  signedByFirstName?: Maybe<Scalars['String']>;
  signedByLastName?: Maybe<Scalars['String']>;
  driverTimeFrom?: Maybe<Scalars['String']>;
  driverTimeTo?: Maybe<Scalars['String']>;
  waitingTimeCharge?: Maybe<Scalars['Decimal']>;
  arrivedAt: Scalars['DateTime'];
  courierServiceNumberOfItems?: Maybe<Scalars['Int']>;
  courierServiceWeight?: Maybe<Scalars['Decimal']>;
  courierServiceWeightUnit?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<AddressPackageDtoInput>>;
  signdeBy?: Maybe<Scalars['String']>;
};

export type UpdateScheduledBookingTemplateDtoInput = {
  id?: Maybe<Scalars['ID']>;
  weekdays: Array<Weekday>;
  processOn: ScheduledBookingProcessOnType;
  processOnOnwards?: Maybe<Scalars['DateTime']>;
};

export type UpdateScheduledExchangeRateTemplateDtoInput = {
  id?: Maybe<Scalars['ID']>;
  processOnOnwards?: Maybe<Scalars['DateTime']>;
};

export type UpdateSequenceOrderBookingAddressDtoInput = {
  bookingAddressId: Scalars['ID'];
  sequenceOrder: Scalars['Int'];
};

export type UpdateSequenceOrderDtoInput = {
  bookingId: Scalars['ID'];
  bookingAddresses: Array<UpdateSequenceOrderBookingAddressDtoInput>;
  distance: Scalars['Decimal'];
  consignmentFee: Scalars['Decimal'];
};

export type UpdateSignatureDataDtoInput = {
  id: Scalars['Long'];
  signedByFirstName?: Maybe<Scalars['String']>;
  signedByLastName?: Maybe<Scalars['String']>;
};

export type UpdateTariffPortalDetailsDtoInput = {
  id?: Maybe<Scalars['ID']>;
  loadCapacity?: Maybe<Scalars['Decimal']>;
  palletCapacity?: Maybe<Scalars['Int']>;
  visibleOn: TariffVisibleOn;
  isSaturdayDelivery: Scalars['Boolean'];
  isVisibleForQuote: Scalars['Boolean'];
  sizeDescription?: Maybe<Scalars['String']>;
  visibilitySequenceNumber?: Maybe<Scalars['Int']>;
};

export type UpdateUserApprovalDtoInput = {
  id?: Maybe<Scalars['ID']>;
  isApproved: Scalars['Boolean'];
  role?: Maybe<Scalars['String']>;
  driverId?: Maybe<Scalars['ID']>;
  customerId?: Maybe<Scalars['ID']>;
  contactId?: Maybe<Scalars['ID']>;
};

export type UpdateYourBookingAddressInformationDtoInput = {
  id: Scalars['ID'];
  vehicleId: Scalars['ID'];
  processTime: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type UserBookingsConnection = {
  __typename?: 'UserBookingsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<UserBookingsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Booking>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UserBookingsEdge = {
  __typename?: 'UserBookingsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Booking;
};

export type UserCompanyDetailsDto = {
  __typename?: 'UserCompanyDetailsDto';
  companyName: Scalars['String'];
  customerId: Scalars['Long'];
  contactId?: Maybe<Scalars['Long']>;
};

export type UserCompanyDetailsDtoInput = {
  companyName: Scalars['String'];
  customerId: Scalars['Long'];
  contactId?: Maybe<Scalars['Long']>;
};

/** A connection to a list of items. */
export type UserPagedReasonCodesConnection = {
  __typename?: 'UserPagedReasonCodesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<UserPagedReasonCodesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ReasonCode>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UserPagedReasonCodesEdge = {
  __typename?: 'UserPagedReasonCodesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ReasonCode;
};

/** A connection to a list of items. */
export type UserPagedTariffsConnection = {
  __typename?: 'UserPagedTariffsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<UserPagedTariffsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<TariffPagedDto>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UserPagedTariffsEdge = {
  __typename?: 'UserPagedTariffsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TariffPagedDto;
};

export type UserProfileDto = {
  __typename?: 'UserProfileDto';
  id: Scalars['Long'];
  userName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  emailConfirmed: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  avatarUri?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Long']>;
  country?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Long']>;
  contactId?: Maybe<Scalars['Long']>;
};

/** A connection to a list of items. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<UsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<IdentityUserDto>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: IdentityUserDto;
};

export type UuidOperationFilterInput = {
  eq?: Maybe<Scalars['UUID']>;
  neq?: Maybe<Scalars['UUID']>;
  in?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  gt?: Maybe<Scalars['UUID']>;
  ngt?: Maybe<Scalars['UUID']>;
  gte?: Maybe<Scalars['UUID']>;
  ngte?: Maybe<Scalars['UUID']>;
  lt?: Maybe<Scalars['UUID']>;
  nlt?: Maybe<Scalars['UUID']>;
  lte?: Maybe<Scalars['UUID']>;
  nlte?: Maybe<Scalars['UUID']>;
};

export type VatCategory = {
  __typename?: 'VatCategory';
  percent: Scalars['Int'];
  xeroTaxType: Scalars['String'];
  customers: Array<Customer>;
  otherCharges: Array<OtherCharge>;
  bookingCharges: Array<BookingCharge>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type VatCategoryFilterInput = {
  and?: Maybe<Array<VatCategoryFilterInput>>;
  or?: Maybe<Array<VatCategoryFilterInput>>;
  percent?: Maybe<IntOperationFilterInput>;
  xeroTaxType?: Maybe<StringOperationFilterInput>;
  customers?: Maybe<ListFilterInputTypeOfCustomerFilterInput>;
  otherCharges?: Maybe<ListFilterInputTypeOfOtherChargeFilterInput>;
  bookingCharges?: Maybe<ListFilterInputTypeOfBookingChargeFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type VatCategorySortInput = {
  percent?: Maybe<SortEnumType>;
  xeroTaxType?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  isDefault: Scalars['Boolean'];
  driverId: Scalars['Long'];
  driver: Driver;
  driverBids: Array<DriverBid>;
  registrationNumber: Scalars['String'];
  vehicleTypeId: Scalars['Long'];
  vehicleType: VehicleType;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  dateOfManufacture?: Maybe<Scalars['DateTime']>;
  colour?: Maybe<Scalars['String']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  insuranceExpiryDate?: Maybe<Scalars['DateTime']>;
  motTestExpiryDate?: Maybe<Scalars['DateTime']>;
  isRenewalReminder: Scalars['Boolean'];
  policyOrCoverNote?: Maybe<Scalars['String']>;
  goodsInTransitExpiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type VehicleFilterInput = {
  and?: Maybe<Array<VehicleFilterInput>>;
  or?: Maybe<Array<VehicleFilterInput>>;
  isDefault?: Maybe<BooleanOperationFilterInput>;
  driverId?: Maybe<LongOperationFilterInput>;
  driver?: Maybe<DriverFilterInput>;
  driverBids?: Maybe<ListFilterInputTypeOfDriverBidFilterInput>;
  registrationNumber?: Maybe<StringOperationFilterInput>;
  vehicleTypeId?: Maybe<LongOperationFilterInput>;
  vehicleType?: Maybe<VehicleTypeFilterInput>;
  manufacturer?: Maybe<StringOperationFilterInput>;
  model?: Maybe<StringOperationFilterInput>;
  dateOfManufacture?: Maybe<DateTimeOperationFilterInput>;
  colour?: Maybe<StringOperationFilterInput>;
  insuranceCompany?: Maybe<StringOperationFilterInput>;
  insuranceExpiryDate?: Maybe<DateTimeOperationFilterInput>;
  motTestExpiryDate?: Maybe<DateTimeOperationFilterInput>;
  isRenewalReminder?: Maybe<BooleanOperationFilterInput>;
  policyOrCoverNote?: Maybe<StringOperationFilterInput>;
  goodsInTransitExpiryDate?: Maybe<DateTimeOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type VehicleSortInput = {
  isDefault?: Maybe<SortEnumType>;
  driverId?: Maybe<SortEnumType>;
  driver?: Maybe<DriverSortInput>;
  registrationNumber?: Maybe<SortEnumType>;
  vehicleTypeId?: Maybe<SortEnumType>;
  vehicleType?: Maybe<VehicleTypeSortInput>;
  manufacturer?: Maybe<SortEnumType>;
  model?: Maybe<SortEnumType>;
  dateOfManufacture?: Maybe<SortEnumType>;
  colour?: Maybe<SortEnumType>;
  insuranceCompany?: Maybe<SortEnumType>;
  insuranceExpiryDate?: Maybe<SortEnumType>;
  motTestExpiryDate?: Maybe<SortEnumType>;
  isRenewalReminder?: Maybe<SortEnumType>;
  policyOrCoverNote?: Maybe<SortEnumType>;
  goodsInTransitExpiryDate?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  vehicles: Array<Vehicle>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type VehicleTypeFilterInput = {
  and?: Maybe<Array<VehicleTypeFilterInput>>;
  or?: Maybe<Array<VehicleTypeFilterInput>>;
  vehicles?: Maybe<ListFilterInputTypeOfVehicleFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type VehicleTypeSortInput = {
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type VehiclesConnection = {
  __typename?: 'VehiclesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<VehiclesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Vehicle>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type VehiclesEdge = {
  __typename?: 'VehiclesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Vehicle;
};

export enum VolumeUnitType {
  M = 'M',
  Cm = 'CM',
  In = 'IN',
  Ft = 'FT'
}

export enum Weekday {
  None = 'NONE',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type WeekdayOperationFilterInput = {
  eq?: Maybe<Weekday>;
  neq?: Maybe<Weekday>;
  in?: Maybe<Array<Weekday>>;
  nin?: Maybe<Array<Weekday>>;
};

export enum WeightUnitType {
  Kg = 'KG',
  Lb = 'LB',
  T = 'T'
}

export type XeroCode = {
  __typename?: 'XeroCode';
  code: Scalars['String'];
  serviceType: XeroServiceType;
  description?: Maybe<Scalars['String']>;
  type: XeroCodeType;
  isInternational: Scalars['Boolean'];
  tariffs: Array<Tariff>;
  drivers: Array<Driver>;
  name: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type XeroCodeFilterInput = {
  and?: Maybe<Array<XeroCodeFilterInput>>;
  or?: Maybe<Array<XeroCodeFilterInput>>;
  code?: Maybe<StringOperationFilterInput>;
  serviceType?: Maybe<XeroServiceTypeOperationFilterInput>;
  description?: Maybe<StringOperationFilterInput>;
  type?: Maybe<XeroCodeTypeOperationFilterInput>;
  isInternational?: Maybe<BooleanOperationFilterInput>;
  tariffs?: Maybe<ListFilterInputTypeOfTariffFilterInput>;
  drivers?: Maybe<ListFilterInputTypeOfDriverFilterInput>;
  name?: Maybe<StringOperationFilterInput>;
  id?: Maybe<LongOperationFilterInput>;
  createdAt?: Maybe<DateTimeOperationFilterInput>;
  createdBy?: Maybe<StringOperationFilterInput>;
  lastModifiedAt?: Maybe<DateTimeOperationFilterInput>;
  lastModifiedBy?: Maybe<StringOperationFilterInput>;
  isEnabled?: Maybe<BooleanOperationFilterInput>;
};

export type XeroCodeSortInput = {
  code?: Maybe<SortEnumType>;
  serviceType?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  type?: Maybe<SortEnumType>;
  isInternational?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  createdBy?: Maybe<SortEnumType>;
  lastModifiedAt?: Maybe<SortEnumType>;
  lastModifiedBy?: Maybe<SortEnumType>;
  isEnabled?: Maybe<SortEnumType>;
};

export enum XeroCodeType {
  Revenue = 'REVENUE',
  Sales = 'SALES'
}

export type XeroCodeTypeOperationFilterInput = {
  eq?: Maybe<XeroCodeType>;
  neq?: Maybe<XeroCodeType>;
  in?: Maybe<Array<XeroCodeType>>;
  nin?: Maybe<Array<XeroCodeType>>;
};

export enum XeroServiceType {
  DomesticDedicated = 'DOMESTIC_DEDICATED',
  DomesticParcels = 'DOMESTIC_PARCELS',
  InternationalParcels = 'INTERNATIONAL_PARCELS',
  DomesticPallets = 'DOMESTIC_PALLETS',
  EuropeanDedicated = 'EUROPEAN_DEDICATED',
  EuropeanGroupage = 'EUROPEAN_GROUPAGE',
  InternationalAirFreight = 'INTERNATIONAL_AIR_FREIGHT',
  SeaFreight = 'SEA_FREIGHT'
}

export type XeroServiceTypeDto = {
  __typename?: 'XeroServiceTypeDto';
  id: Scalars['Long'];
  serviceType: XeroServiceType;
  description: Scalars['String'];
};

export type XeroServiceTypeOperationFilterInput = {
  eq?: Maybe<XeroServiceType>;
  neq?: Maybe<XeroServiceType>;
  in?: Maybe<Array<XeroServiceType>>;
  nin?: Maybe<Array<XeroServiceType>>;
};

export type ActivityDetailsFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'id' | 'type' | 'note' | 'date' | 'bookingId' | 'createdAt' | 'createdBy' | 'lastModifiedAt' | 'lastModifiedBy'>
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'ourReference'>
  )> }
);

export type BookingDetailsFragment = (
  { __typename?: 'Booking' }
  & Pick<Booking, 'customerReference' | 'customerId' | 'contactId' | 'consignmentFee' | 'isEmailNotification' | 'courierExchangeLoadId' | 'isArchived' | 'isCancelledOrQuoteLost' | 'isOnHold' | 'noteOrSpecialInstruction' | 'alternativeOurReference' | 'ourReference' | 'overrideContactEmail' | 'overrideContactTelephoneNumber' | 'status' | 'statusLabel' | 'calculatedStatus' | 'distance' | 'isCalculateDistanceAutomatically' | 'isWaitAndReturn' | 'tariffId' | 'courierExchangeLastManualUpdateAt' | 'isImported' | 'isQuoteOriginally' | 'isQuickQuote' | 'isCreatedByCustomer' | 'validUntil' | 'marginPercent' | 'docketReference' | 'revenue' | 'cost' | 'takenBy' | 'takenOn' | 'createdBy' | 'createdAt' | 'isTransitIgnored' | 'potentialBookingId' | 'isBiddingEnabled'>
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name' | 'isReferenceRequired' | 'isReasonCodeOptionEnabled' | 'isPackageDetailsOptional'>
  ) }
);

export type BookingAddressesFragment = (
  { __typename?: 'Booking' }
  & { addresses: Array<(
    { __typename?: 'BookingAddress' }
    & Pick<BookingAddress, 'id' | 'bookingId' | 'type' | 'at' | 'by' | 'sequenceOrder' | 'note' | 'noteFromDriver' | 'waitingTime' | 'waitingTimeCharge' | 'arrivedAt' | 'signedAt' | 'signedBy' | 'signedByFirstName' | 'signedByLastName' | 'driverTimeFrom' | 'driverTimeTo' | 'courierServiceNumberOfItems' | 'courierServiceWeight' | 'courierServiceWeightUnit' | 'eTA' | 'reasonCodeId'>
    & { addressPackages?: Maybe<Array<(
      { __typename?: 'BookingAddressPackage' }
      & Pick<BookingAddressPackage, 'id' | 'packageId' | 'quantity'>
      & { package: (
        { __typename?: 'Package' }
        & Pick<Package, 'id' | 'name' | 'quantity'>
      ) }
    )>>, allocations: Array<(
      { __typename?: 'PackageAllocation' }
      & Pick<PackageAllocation, 'id' | 'packageId' | 'quantity'>
      & { package: (
        { __typename?: 'Package' }
        & Pick<Package, 'id' | 'name' | 'quantity'>
      ) }
    )>, address: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'name' | 'addressLine1' | 'addressLine2' | 'postcode' | 'city' | 'countryId' | 'contact' | 'email' | 'telephoneNumber' | 'isVerified' | 'googleMapsPlaceId'>
    ) }
  )> }
);

export type BookingDriversFragment = (
  { __typename?: 'Booking' }
  & { drivers?: Maybe<Array<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id' | 'charge' | 'extraPayment' | 'driverId' | 'vehicleId'>
    & { driver: (
      { __typename?: 'Driver' }
      & Pick<Driver, 'id' | 'name' | 'callSign'>
      & { xeroCode?: Maybe<(
        { __typename?: 'XeroCode' }
        & Pick<XeroCode, 'id' | 'name'>
      )> }
    ), vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleTypeId'>
      & { vehicleType: (
        { __typename?: 'VehicleType' }
        & Pick<VehicleType, 'id' | 'name'>
      ) }
    )> }
  )>> }
);

export type BookingChargesFragment = (
  { __typename?: 'Booking' }
  & { charges: Array<(
    { __typename?: 'BookingCharge' }
    & Pick<BookingCharge, 'id' | 'bookingId' | 'name' | 'type' | 'description' | 'quantity' | 'rate' | 'xeroServiceCode' | 'vatCategoryId'>
  )> }
);

export type BookingPackagesFragment = (
  { __typename?: 'Booking' }
  & { packages: Array<(
    { __typename?: 'Package' }
    & Pick<Package, 'id' | 'name' | 'quantity' | 'type' | 'height' | 'width' | 'length' | 'weight' | 'packageReference' | 'collectionId' | 'deliveryId' | 'collectionSequenceOrder' | 'deliverySequenceOrder'>
    & { collection?: Maybe<(
      { __typename?: 'BookingAddress' }
      & Pick<BookingAddress, 'sequenceOrder'>
    )>, delivery?: Maybe<(
      { __typename?: 'BookingAddress' }
      & Pick<BookingAddress, 'sequenceOrder'>
    )> }
  )> }
);

export type BookingTariffFragment = (
  { __typename?: 'Booking' }
  & { tariff?: Maybe<(
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'id' | 'name' | 'type' | 'minCharge' | 'minMiles' | 'rate' | 'baseUnit' | 'isWaitAndReturnEnabled' | 'iconUrl' | 'isExternal' | 'capacity'>
    & { tariffCategory: (
      { __typename?: 'TariffCategory' }
      & Pick<TariffCategory, 'id' | 'name' | 'courierExchangeVehicleSize'>
    ), xeroCode?: Maybe<(
      { __typename?: 'XeroCode' }
      & Pick<XeroCode, 'id' | 'serviceType'>
    )> }
  )> }
);

export type BookingAllocatedDriverFragment = (
  { __typename?: 'BookingDriver' }
  & Pick<BookingDriver, 'bookingId' | 'driverId' | 'xeroPurchaseCode' | 'charge' | 'extraPayment' | 'isShownInstruction' | 'instruction' | 'currencyId' | 'vehicleId'>
  & { driver: (
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'name' | 'callSign'>
    & { xeroCode?: Maybe<(
      { __typename?: 'XeroCode' }
      & Pick<XeroCode, 'id' | 'name'>
    )> }
  ), vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleTypeId'>
    & { vehicleType: (
      { __typename?: 'VehicleType' }
      & Pick<VehicleType, 'id' | 'name'>
    ) }
  )>, driverContact?: Maybe<(
    { __typename?: 'DriverContact' }
    & Pick<DriverContact, 'name'>
  )> }
);

export type UserBookingDetailsFragment = (
  { __typename?: 'Booking' }
  & Pick<Booking, 'customerReference' | 'customerId' | 'contactId' | 'consignmentFee' | 'isEmailNotification' | 'courierExchangeLoadId' | 'isArchived' | 'isCancelledOrQuoteLost' | 'isOnHold' | 'noteOrSpecialInstruction' | 'alternativeOurReference' | 'ourReference' | 'overrideContactEmail' | 'overrideContactTelephoneNumber' | 'status' | 'statusLabel' | 'calculatedStatus' | 'distance' | 'isCalculateDistanceAutomatically' | 'isWaitAndReturn' | 'tariffId' | 'courierExchangeLastManualUpdateAt' | 'isImported' | 'isQuoteOriginally' | 'isQuickQuote' | 'isCreatedByCustomer' | 'isTransitIgnored' | 'validUntil' | 'docketReference' | 'takenBy' | 'takenOn' | 'createdBy' | 'createdAt' | 'potentialBookingId' | 'isBiddingEnabled'>
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name' | 'isReasonCodeOptionEnabled' | 'isPackageDetailsOptional' | 'isReferenceRequired'>
  ) }
);

export type CommentaryDetailsFragment = (
  { __typename?: 'Commentary' }
  & Pick<Commentary, 'message' | 'taskId' | 'createdAt' | 'createdBy' | 'lastModifiedAt' | 'lastModifiedBy'>
);

export type CongestionZoneDetailsFragment = (
  { __typename?: 'CongestionZone' }
  & Pick<CongestionZone, 'name' | 'isAppliedAutomatically' | 'isAttachedToAllTariffs' | 'customerCharge' | 'durationFrom' | 'durationTo' | 'durationFromTotalSeconds' | 'durationToTotalSeconds' | 'weekdaysCollection' | 'xeroServiceCode'>
  & { tariffs: Array<(
    { __typename?: 'CongestionZoneTariff' }
    & Pick<CongestionZoneTariff, 'id' | 'tariffId' | 'congestionZoneId'>
    & { tariff: (
      { __typename?: 'Tariff' }
      & Pick<Tariff, 'id' | 'name'>
    ) }
  )> }
);

export type CongestionZonePostcodeFragmentFragment = (
  { __typename?: 'CongestionZonePostcode' }
  & Pick<CongestionZonePostcode, 'name' | 'congestionZoneId'>
);

export type CurrencyDetailsFragment = (
  { __typename?: 'Currency' }
  & Pick<Currency, 'name' | 'code' | 'isBaseCurrency'>
);

export type CustomerReferenceAndNotesFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'customerReferenceLabel' | 'isCustomerReferenceMandatory' | 'isPaperworkRequired' | 'noteForAccount' | 'noteForBooking' | 'instructionsForCourierExchangeDriver' | 'noteForPackage' | 'noteForPaperwork'>
);

export type CustomerNotificationsFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'isNotificationForQuote' | 'isNotificationForReadyForAllocation' | 'isNotificationForBooking' | 'isNotificationForPobArrival' | 'isNotificationForPOB' | 'isNotificationForPodArrival' | 'isNotificationForPodPartial' | 'isNotificationForPOD' | 'isNotificationForCancellation' | 'isNotificationForCarded' | 'isNotificationForAmendment' | 'isNotificationForDriverAllocated' | 'isNotificationForInvoice' | 'isNotificationForInvoiceDue' | 'customerInvoiceNotificationType'>
);

export type CustomerDetailsFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'id' | 'primaryCustomerId' | 'name' | 'addressLine1' | 'addressLine2' | 'legalAccountType' | 'customerAccountType' | 'city' | 'postcode' | 'countryId' | 'invoiceEmail' | 'paymentMethodId' | 'vatCategoryId' | 'accountCode' | 'companyRegistrationNumber' | 'vatIdNumber' | 'eoriNumber' | 'invoiceCompanyName' | 'startDate' | 'acquiredBy' | 'customerAcquisitionSourceId' | 'notes' | 'invoiceTermId' | 'invoiceFrequency' | 'isInvoiceGrouped' | 'currencyId' | 'isReferenceRequired' | 'isUlezCharge' | 'isOnHold' | 'isNotificationForBooking' | 'isNotificationForInvoice' | 'isNotificationForInvoiceDue' | 'isNotificationForPOB' | 'isNotificationForPOD' | 'isNotificationForQuote' | 'isNotificationForReadyForAllocation' | 'isCustomerReferenceMandatory' | 'customerInvoiceNotificationType' | 'telephoneNumber' | 'salesName' | 'additionalInvoiceEmails' | 'isPaperworkRequired' | 'isApplyVatToOtherCharges' | 'isEnabled' | 'isReasonCodeOptionEnabled' | 'isBookingImportOptionEnabled' | 'isPackageDetailsOptional'>
);

export type CustomerOptionsFragment = (
  { __typename?: 'Customer' }
  & Pick<Customer, 'isReferenceRequired' | 'isReasonCodeOptionEnabled' | 'isPackageDetailsOptional'>
);

export type DriverDetailsFragment = (
  { __typename?: 'Driver' }
  & Pick<Driver, 'id' | 'firstName' | 'lastName' | 'addressLine1' | 'addressLine2' | 'bankAccountNumber' | 'bankName' | 'bankSortCode' | 'courierExchangeMemberId' | 'city' | 'countryId' | 'driverType' | 'xeroCodeId' | 'email' | 'employeeReference' | 'endorsements' | 'paymentTerm' | 'invoiceEmail' | 'eoriNumber' | 'isActive' | 'isEmailInvoice' | 'isGenerateSeparateInvoice' | 'lastPaid' | 'licenseExpiryDate' | 'licenseType' | 'currencyId' | 'name' | 'nationalInsuranceNumber' | 'paymentMethodId' | 'postcode' | 'publicLiabilityExpirationDate' | 'startDate' | 'telephoneNumber' | 'vatCategoryId' | 'vatIdNumber' | 'swift' | 'xeroPurchaseCode' | 'isOnboardingCompleted' | 'alternativeTelephoneNumber' | 'bankAccountHolderName' | 'internationalBankAccountNumber' | 'companyRegistrationNumber'>
  & { subDrivers: Array<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'name' | 'email' | 'callSign' | 'telephoneNumber' | 'xeroPurchaseCode'>
  )> }
);

export type DriverContactDetailsFragment = (
  { __typename?: 'DriverContact' }
  & Pick<DriverContact, 'id' | 'name' | 'isDefault' | 'telephoneNumber' | 'email' | 'additionalEmails' | 'department' | 'jobRole' | 'driverId'>
);

export type EmailTemplateDetailsFragment = (
  { __typename?: 'EmailTemplate' }
  & Pick<EmailTemplate, 'id' | 'type' | 'isBlindCarbonCopyEnabled' | 'blindCarbonCopy' | 'subject' | 'message' | 'iconUri' | 'tokens'>
);

export type ExchangeRateDetailsFragment = (
  { __typename?: 'ExchangeRate' }
  & Pick<ExchangeRate, 'id' | 'rate' | 'sourceCurrencyId' | 'targetCurrencyId' | 'createdAt'>
  & { sourceCurrency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'code'>
  ), targetCurrency: (
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'code'>
  ) }
);

export type FileDetailsFragment = (
  { __typename?: 'File' }
  & Pick<File, 'name' | 'category' | 'absoluteUri' | 'originalFileName' | 'fileNameInStorage' | 'entityReferenceId' | 'entityReference' | 'createdBy' | 'createdAt'>
);

export type NotificationDetailsFragment = (
  { __typename?: 'Notification' }
  & Pick<Notification, 'name' | 'description' | 'username' | 'isRead' | 'createdAt' | 'createdBy' | 'taskId' | 'priority' | 'date' | 'type'>
);

export type OtherChargeDetailsFragment = (
  { __typename?: 'OtherCharge' }
  & Pick<OtherCharge, 'name' | 'rate' | 'description' | 'xeroServiceCode' | 'vatCategoryId'>
);

export type ReasonCodeDetailsFragment = (
  { __typename?: 'ReasonCode' }
  & Pick<ReasonCode, 'name' | 'code' | 'description' | 'customerId'>
  & { customer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  ) }
);

export type ReportRowsFragment = (
  { __typename?: 'GenericReportOutputDto' }
  & { rows: Array<(
    { __typename?: 'ReportRow' }
    & { cells: Array<(
      { __typename?: 'ReportCell' }
      & Pick<ReportCell, 'value' | 'type' | 'isBold' | 'hyperlink'>
    )> }
  )> }
);

export type TemplateDetailsFragment = (
  { __typename?: 'ScheduledBookingTemplate' }
  & Pick<ScheduledBookingTemplate, 'id' | 'bookingId' | 'weekdaysCollection' | 'processOn' | 'processOnOnwards' | 'processOnOnwardsTotalSeconds'>
);

export type StandardOperatingProcedureDetailsFragment = (
  { __typename?: 'StandardOperatingProcedure' }
  & Pick<StandardOperatingProcedure, 'name' | 'isActive' | 'description' | 'steps' | 'frequentlyAskedQuestions' | 'notes' | 'driverInstructions' | 'isAttachedToAllCustomers'>
  & { customers: Array<(
    { __typename?: 'CustomerStandardOperatingProcedure' }
    & Pick<CustomerStandardOperatingProcedure, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
    ) }
  )> }
);

export type StrategicPartnerDetailsFragment = (
  { __typename?: 'StrategicPartner' }
  & Pick<StrategicPartner, 'id' | 'name' | 'serviceType' | 'addressLine1' | 'addressLine2' | 'city' | 'postcode' | 'email' | 'countryId' | 'telephoneNumber' | 'vatIdNumber' | 'eoriNumber' | 'xeroPurchaseCode'>
);

export type StrategicPartnerContactDetailsFragment = (
  { __typename?: 'StrategicPartnerContact' }
  & Pick<StrategicPartnerContact, 'id' | 'name' | 'isDefault' | 'telephoneNumber' | 'email' | 'additionalEmails' | 'department' | 'jobRole' | 'strategicPartnerId'>
);

export type TariffDetailsFragment = (
  { __typename?: 'Tariff' }
  & Pick<Tariff, 'id' | 'basedOnTariffId' | 'name' | 'tariffCategoryId' | 'xeroCodeId' | 'xeroServiceCode' | 'type' | 'minCharge' | 'minMiles' | 'rate' | 'baseUnit' | 'isWaitAndReturnEnabled' | 'vatCategoryId' | 'isAttachedToAllCustomers' | 'isDropChargeEnabled' | 'isUseDefaultDropCharge' | 'dropChargeRate' | 'isOverwriteDefaultWaitingTime' | 'collectionAllowanceMinutes' | 'deliveryAllowanceMinutes' | 'excessWaitingTimeCharge' | 'excessWaitingTimeMinutesPer' | 'calculateCharge' | 'isExternal' | 'visibilitySequenceNumber' | 'tariffIconId'>
  & { tariffCategory: (
    { __typename?: 'TariffCategory' }
    & Pick<TariffCategory, 'id' | 'name'>
  ), xeroCode?: Maybe<(
    { __typename?: 'XeroCode' }
    & Pick<XeroCode, 'id' | 'name' | 'code'>
  )>, customers: Array<(
    { __typename?: 'CustomerTariff' }
    & Pick<CustomerTariff, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
    ) }
  )> }
);

export type TaskDetailsFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'bookingId' | 'description' | 'status' | 'endDate' | 'time' | 'timeTotalSeconds' | 'categoryId' | 'assignedUser' | 'assignedUserName' | 'additionalDetail'>
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'ourReference' | 'status'>
  )>, category?: Maybe<(
    { __typename?: 'TaskCategory' }
    & Pick<TaskCategory, 'id' | 'name' | 'colour'>
  )> }
);

export type TaskSettingDetailsFragment = (
  { __typename?: 'SystemTaskSetting' }
  & Pick<SystemTaskSetting, 'taskRelatedTo' | 'taskCaption' | 'interval'>
);

export type UserProfileFragment = (
  { __typename?: 'UserProfileDto' }
  & Pick<UserProfileDto, 'country' | 'countryId' | 'city' | 'email' | 'lastName' | 'firstName' | 'phoneNumber' | 'userName'>
);

export type UpdateAccountPayableRecordStatusMutationVariables = Exact<{
  input: AccountPayableRecordStatusDtoInput;
}>;


export type UpdateAccountPayableRecordStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountPayableRecordStatus: (
    { __typename?: 'AccountPayableRecord' }
    & Pick<AccountPayableRecord, 'id' | 'status'>
  ) }
);

export type UpdateAccountPayablesPaymentDateMutationVariables = Exact<{
  input: AccountPayablePaymentDateDtoInput;
}>;


export type UpdateAccountPayablesPaymentDateMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountPayablesPaymentDate: Array<(
    { __typename?: 'BookingInvoice' }
    & Pick<BookingInvoice, 'id' | 'bookingId' | 'paymentDate'>
  )> }
);

export type UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutationVariables = Exact<{
  inputs: Array<AccountPayableRecordStatusDtoInput> | AccountPayableRecordStatusDtoInput;
}>;


export type UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAccountPayableRecordsStatusRangeToScheduledPayment'>
);

export type UpdateDriverInformationMutationVariables = Exact<{
  input: UpdateDriverInformationDtoInput;
}>;


export type UpdateDriverInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateDriverInformation?: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'vatCategoryId' | 'xeroPurchaseCode'>
  )> }
);

export type RefreshPaymentStatusMutationVariables = Exact<{
  input: RefreshPaymentStatusDtoInput;
}>;


export type RefreshPaymentStatusMutation = (
  { __typename?: 'Mutation' }
  & { refreshPaymentStatus: Array<(
    { __typename?: 'AccountPayableRecord' }
    & Pick<AccountPayableRecord, 'id' | 'status'>
  )> }
);

export type UpdateAccountPayableNetAmountMutationVariables = Exact<{
  input: UpdateAccountPayableNetAmountDtoInput;
}>;


export type UpdateAccountPayableNetAmountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountPayableNetAmount: (
    { __typename?: 'AccountPayableRecord' }
    & Pick<AccountPayableRecord, 'id' | 'net'>
  ) }
);

export type CreateActivityMutationVariables = Exact<{
  input: CreateUpdateActivityDtoInput;
}>;


export type CreateActivityMutation = (
  { __typename?: 'Mutation' }
  & { createActivity?: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id'>
    & ActivityDetailsFragment
  )> }
);

export type UpdateActivityMutationVariables = Exact<{
  input: CreateUpdateActivityDtoInput;
}>;


export type UpdateActivityMutation = (
  { __typename?: 'Mutation' }
  & { updateActivity?: Maybe<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id'>
    & ActivityDetailsFragment
  )> }
);

export type DeleteActivityMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActivity'>
);

export type CreateBannerMutationVariables = Exact<{
  input: CreateAndUpdateBannerDtoInput;
}>;


export type CreateBannerMutation = (
  { __typename?: 'Mutation' }
  & { createBanner?: Maybe<(
    { __typename?: 'Banner' }
    & Pick<Banner, 'id' | 'name' | 'type' | 'isActive' | 'fileId'>
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  )> }
);

export type UpdateBannerMutationVariables = Exact<{
  input: CreateAndUpdateBannerDtoInput;
}>;


export type UpdateBannerMutation = (
  { __typename?: 'Mutation' }
  & { updateBanner?: Maybe<(
    { __typename?: 'Banner' }
    & Pick<Banner, 'id' | 'name' | 'type' | 'isActive' | 'fileId'>
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  )> }
);

export type DeleteBannerMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteBannerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBanner'>
);

export type UpdateBookingAttachmentMutationVariables = Exact<{
  input: UpdateBookingAttachmentDtoInput;
}>;


export type UpdateBookingAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingAttachment?: Maybe<(
    { __typename?: 'BookingAttachment' }
    & Pick<BookingAttachment, 'id' | 'fileId' | 'bookingId' | 'isAvailableForDriver' | 'isAvailableForCustomer' | 'isEnabled'>
  )> }
);

export type DeleteBookingAttachmentMutationVariables = Exact<{
  bookingId: Scalars['Long'];
  fileId: Scalars['Long'];
}>;


export type DeleteBookingAttachmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBookingAttachment'>
);

export type CreateBookingCombinedMutationVariables = Exact<{
  input: CreateAndUpdateBookingCombinedDtoInput;
}>;


export type CreateBookingCombinedMutation = (
  { __typename?: 'Mutation' }
  & { createBookingCombined?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & BookingDetailsFragment
    & BookingAddressesFragment
    & BookingChargesFragment
    & BookingPackagesFragment
  )> }
);

export type UpdateBookingCombinedMutationVariables = Exact<{
  input: CreateAndUpdateBookingCombinedDtoInput;
}>;


export type UpdateBookingCombinedMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingCombined?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & BookingDetailsFragment
    & BookingAddressesFragment
    & BookingDriversFragment
    & BookingChargesFragment
    & BookingPackagesFragment
    & BookingTariffFragment
  )> }
);

export type CreateUserBookingCombinedMutationVariables = Exact<{
  input: CreateAndUpdateBookingCombinedDtoInput;
}>;


export type CreateUserBookingCombinedMutation = (
  { __typename?: 'Mutation' }
  & { createBookingCombined?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & UserBookingDetailsFragment
    & BookingAddressesFragment
    & BookingChargesFragment
    & BookingPackagesFragment
  )> }
);

export type UpdateUserBookingCombinedMutationVariables = Exact<{
  input: CreateAndUpdateBookingCombinedDtoInput;
}>;


export type UpdateUserBookingCombinedMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingCombined?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & UserBookingDetailsFragment
    & BookingAddressesFragment
    & BookingDriversFragment
    & BookingChargesFragment
    & BookingPackagesFragment
    & BookingTariffFragment
  )> }
);

export type DeleteBookingAddressMutationVariables = Exact<{
  bookingAddressId: Scalars['Long'];
}>;


export type DeleteBookingAddressMutation = (
  { __typename?: 'Mutation' }
  & { deleteBookingAddress?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
  )> }
);

export type DeleteBookingChargeMutationVariables = Exact<{
  bookingChargeId: Scalars['Long'];
}>;


export type DeleteBookingChargeMutation = (
  { __typename?: 'Mutation' }
  & { deleteBookingCharge?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
  )> }
);

export type DeleteBookingPackageMutationVariables = Exact<{
  packageId: Scalars['Long'];
}>;


export type DeleteBookingPackageMutation = (
  { __typename?: 'Mutation' }
  & { deleteBookingPackage?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
  )> }
);

export type CreateBookingDriversMutationVariables = Exact<{
  input: Array<CreateAndUpdateBookingDriverDtoInput> | CreateAndUpdateBookingDriverDtoInput;
}>;


export type CreateBookingDriversMutation = (
  { __typename?: 'Mutation' }
  & { createBookingDrivers: Array<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'takenBy' | 'takenOn'>
    ) }
    & BookingAllocatedDriverFragment
  )> }
);

export type CreateBookingDriverMutationVariables = Exact<{
  input: CreateAndUpdateBookingDriverDtoInput;
}>;


export type CreateBookingDriverMutation = (
  { __typename?: 'Mutation' }
  & { createBookingDriver?: Maybe<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id'>
    & BookingAllocatedDriverFragment
  )> }
);

export type UpdateBookingDriverMutationVariables = Exact<{
  input: CreateAndUpdateBookingDriverDtoInput;
}>;


export type UpdateBookingDriverMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingDriver?: Maybe<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id'>
    & BookingAllocatedDriverFragment
  )> }
);

export type DeleteBookingDriverMutationVariables = Exact<{
  bookingDriverId: Scalars['Long'];
}>;


export type DeleteBookingDriverMutation = (
  { __typename?: 'Mutation' }
  & { deleteBookingDriver: Array<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id'>
    & BookingAllocatedDriverFragment
  )> }
);

export type DeleteBookingDriverByBookingAndDriverIdMutationVariables = Exact<{
  bookingId: Scalars['Long'];
  driverId: Scalars['Long'];
}>;


export type DeleteBookingDriverByBookingAndDriverIdMutation = (
  { __typename?: 'Mutation' }
  & { deleteBookingDriverByBookingAndDriverId: Array<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id'>
    & BookingAllocatedDriverFragment
  )> }
);

export type CancelBookingMutationVariables = Exact<{
  input: CancelBookingDtoInput;
}>;


export type CancelBookingMutation = (
  { __typename?: 'Mutation' }
  & { cancelBooking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'isCancelledOrQuoteLost' | 'cancellationReasonOrQuoteLostDetail' | 'statusLabel'>
  ) }
);

export type MarkQuoteLostMutationVariables = Exact<{
  input: QuoteLostDtoInput;
}>;


export type MarkQuoteLostMutation = (
  { __typename?: 'Mutation' }
  & { markQuoteLost: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'isCancelledOrQuoteLost' | 'quoteLostReasonId' | 'cancellationReasonOrQuoteLostDetail' | 'statusLabel'>
  ) }
);

export type DeleteBookingMutationVariables = Exact<{
  input: DeleteOrDisableBookingDtoInput;
}>;


export type DeleteBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBooking'>
);

export type ArchiveBookingMutationVariables = Exact<{
  input: ArchiveBookingDtoInput;
}>;


export type ArchiveBookingMutation = (
  { __typename?: 'Mutation' }
  & { archiveBooking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'isArchived' | 'statusLabel'>
  ) }
);

export type MakeBookingOnHoldMutationVariables = Exact<{
  input: MakeBookingOnHoldDtoInput;
}>;


export type MakeBookingOnHoldMutation = (
  { __typename?: 'Mutation' }
  & { makeBookingOnHold: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'isOnHold' | 'statusLabel' | 'calculatedStatus' | 'status'>
  ) }
);

export type ConfirmQuickQuoteMutationVariables = Exact<{
  input: ConfirmQuickQuoteDtoInput;
}>;


export type ConfirmQuickQuoteMutation = (
  { __typename?: 'Mutation' }
  & { confirmQuickQuote?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'isQuickQuote' | 'status' | 'statusLabel' | 'calculatedStatus' | 'isArchived' | 'isCancelledOrQuoteLost' | 'isOnHold' | 'isPreBooked' | 'isPending'>
    & BookingAddressesFragment
  )> }
);

export type CopyBookingMutationVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type CopyBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'copyBooking'>
);

export type UpdateBookingProofOfDeliveryMutationVariables = Exact<{
  input: UpdateProofOfDeliveryDtoInput;
}>;


export type UpdateBookingProofOfDeliveryMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingProofOfDelivery: (
    { __typename?: 'BookingAddress' }
    & Pick<BookingAddress, 'id' | 'bookingId' | 'at' | 'by' | 'waitingTime' | 'waitingTimeCharge' | 'arrivedAt' | 'signedAt' | 'signedByFirstName' | 'signedByLastName' | 'driverTimeFrom' | 'driverTimeTo' | 'noteFromDriver' | 'eTA'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'statusLabel'>
    ), allocations: Array<(
      { __typename?: 'PackageAllocation' }
      & Pick<PackageAllocation, 'id' | 'packageId' | 'quantity'>
    )> }
  ) }
);

export type UpdateBookingPackageOnBoardMutationVariables = Exact<{
  input: UpdatePackageOnBoardDtoInput;
}>;


export type UpdateBookingPackageOnBoardMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingPackageOnBoard: (
    { __typename?: 'BookingAddress' }
    & Pick<BookingAddress, 'id' | 'bookingId' | 'at' | 'by' | 'waitingTime' | 'waitingTimeCharge' | 'arrivedAt' | 'signedAt' | 'signedByFirstName' | 'signedByLastName' | 'driverTimeFrom' | 'driverTimeTo' | 'noteFromDriver' | 'eTA'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'statusLabel'>
    ), addressPackages?: Maybe<Array<(
      { __typename?: 'BookingAddressPackage' }
      & Pick<BookingAddressPackage, 'id' | 'packageId' | 'quantity'>
    )>> }
  ) }
);

export type CreateBookingInvoiceMutationVariables = Exact<{
  input: CreateBookingInvoiceDtoInput;
}>;


export type CreateBookingInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createBookingInvoice: Array<(
    { __typename?: 'BookingInvoice' }
    & Pick<BookingInvoice, 'id' | 'bookingId'>
  )> }
);

export type UpdateBookingStatusMutationVariables = Exact<{
  bookingId: Scalars['Long'];
  status: BookingStatus;
}>;


export type UpdateBookingStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingStatus: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'status' | 'statusLabel' | 'calculatedStatus' | 'ourReference'>
  ) }
);

export type UpdateSequenceOrderMutationVariables = Exact<{
  input: UpdateSequenceOrderDtoInput;
}>;


export type UpdateSequenceOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateSequenceOrder?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'distance' | 'consignmentFee'>
    & { addresses: Array<(
      { __typename?: 'BookingAddress' }
      & Pick<BookingAddress, 'id' | 'sequenceOrder'>
    )> }
  )> }
);

export type DeleteBookingAddressAttachmentMutationVariables = Exact<{
  input: DeleteBookingAddressAttachmentDtoInput;
}>;


export type DeleteBookingAddressAttachmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBookingAddressAttachment'>
);

export type SendDocketDetailsMutationVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type SendDocketDetailsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendDocketDetailsEmail'>
);

export type UpdateBookingAddressEtaMutationVariables = Exact<{
  input: UpdateAddressEtaDtoInput;
}>;


export type UpdateBookingAddressEtaMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingAddressEta: (
    { __typename?: 'BookingAddress' }
    & Pick<BookingAddress, 'id' | 'eTA'>
  ) }
);

export type SendProofOfDeliveryEmailMutationVariables = Exact<{
  bookingId: Scalars['Long'];
  bookingAddressId?: Maybe<Scalars['Long']>;
}>;


export type SendProofOfDeliveryEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendProofOfDeliveryEmail'>
);

export type UpdateAllBookingEtasMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateAllBookingEtasMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAllBookingEtas'>
);

export type CreateQuoteLostReasonMutationVariables = Exact<{
  input: CreateAndUpdateQuoteLostReasonDtoInput;
}>;


export type CreateQuoteLostReasonMutation = (
  { __typename?: 'Mutation' }
  & { createQuoteLostReason: (
    { __typename?: 'QuoteLostReason' }
    & Pick<QuoteLostReason, 'id' | 'name' | 'code'>
  ) }
);

export type UpdateQuoteLostReasonMutationVariables = Exact<{
  input: CreateAndUpdateQuoteLostReasonDtoInput;
}>;


export type UpdateQuoteLostReasonMutation = (
  { __typename?: 'Mutation' }
  & { updateQuoteLostReason: (
    { __typename?: 'QuoteLostReason' }
    & Pick<QuoteLostReason, 'id' | 'name' | 'code'>
  ) }
);

export type ProcessPotentialBookingFileMutationVariables = Exact<{
  input: ProcessPotentialBookingFileDtoInput;
}>;


export type ProcessPotentialBookingFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'processPotentialBookingFile'>
);

export type CreateCommentaryMutationVariables = Exact<{
  input: CreateUpdateCommentaryDtoInput;
}>;


export type CreateCommentaryMutation = (
  { __typename?: 'Mutation' }
  & { createCommentary?: Maybe<(
    { __typename?: 'Commentary' }
    & Pick<Commentary, 'id'>
    & CommentaryDetailsFragment
  )> }
);

export type UpdateCommentaryMutationVariables = Exact<{
  input: CreateUpdateCommentaryDtoInput;
}>;


export type UpdateCommentaryMutation = (
  { __typename?: 'Mutation' }
  & { updateCommentary?: Maybe<(
    { __typename?: 'Commentary' }
    & Pick<Commentary, 'id'>
    & CommentaryDetailsFragment
  )> }
);

export type DeleteCommentaryMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteCommentaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCommentary'>
);

export type CreateCongestionZoneMutationVariables = Exact<{
  input: CreateAndUpdateCongestionZoneDtoInput;
}>;


export type CreateCongestionZoneMutation = (
  { __typename?: 'Mutation' }
  & { createCongestionZone: (
    { __typename?: 'CongestionZone' }
    & Pick<CongestionZone, 'id'>
    & CongestionZoneDetailsFragment
  ) }
);

export type UpdateCongestionZoneMutationVariables = Exact<{
  input: CreateAndUpdateCongestionZoneDtoInput;
}>;


export type UpdateCongestionZoneMutation = (
  { __typename?: 'Mutation' }
  & { updateCongestionZone: (
    { __typename?: 'CongestionZone' }
    & Pick<CongestionZone, 'id'>
    & CongestionZoneDetailsFragment
  ) }
);

export type CreateCongestionZonePostcodeMutationVariables = Exact<{
  input: CreateAndUpdateCongestionZonePostcodeDtoInput;
}>;


export type CreateCongestionZonePostcodeMutation = (
  { __typename?: 'Mutation' }
  & { createCongestionZonePostcode: (
    { __typename?: 'CongestionZonePostcode' }
    & Pick<CongestionZonePostcode, 'id'>
    & CongestionZonePostcodeFragmentFragment
  ) }
);

export type UpdateCongestionZonePostcodeMutationVariables = Exact<{
  input: CreateAndUpdateCongestionZonePostcodeDtoInput;
}>;


export type UpdateCongestionZonePostcodeMutation = (
  { __typename?: 'Mutation' }
  & { updateCongestionZonePostcode: (
    { __typename?: 'CongestionZonePostcode' }
    & Pick<CongestionZonePostcode, 'id'>
    & CongestionZonePostcodeFragmentFragment
  ) }
);

export type DeleteCongestionZonePostcodeMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteCongestionZonePostcodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCongestionZonePostcode'>
);

export type PostLoadMutationVariables = Exact<{
  input: CourierExchangeLoadDtoInput;
}>;


export type PostLoadMutation = (
  { __typename?: 'Mutation' }
  & { postLoad: (
    { __typename?: 'CourierExchangeLoad' }
    & Pick<CourierExchangeLoad, 'id' | 'bookingId' | 'loadId' | 'rawRequest'>
  ) }
);

export type LoadQuotesMutationVariables = Exact<{
  loadId: Scalars['Long'];
}>;


export type LoadQuotesMutation = (
  { __typename?: 'Mutation' }
  & { loadQuotes: Array<(
    { __typename?: 'CourierExchangeQuoteDto' }
    & Pick<CourierExchangeQuoteDto, 'id' | 'loadId' | 'quoteId' | 'price' | 'currency' | 'quoteOwnerCompanyId' | 'quoteOwnerCompanyName' | 'quoteOwnerDriverName' | 'totalPrice' | 'createdOn' | 'status' | 'notes' | 'isDriverInSystem' | 'isDriverUsingOurApp'>
  )> }
);

export type BookLoadMutationVariables = Exact<{
  input: CourierExchangeBookLoadDtoInput;
}>;


export type BookLoadMutation = (
  { __typename?: 'Mutation' }
  & { bookLoad: (
    { __typename?: 'CourierExchangeLoad' }
    & Pick<CourierExchangeLoad, 'id' | 'bookingId'>
    & { booking: (
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'takenOn' | 'takenBy'>
    ) }
  ) }
);

export type UpdateBookingStatusFromCourierExchangeMutationVariables = Exact<{
  loadId: Scalars['Long'];
}>;


export type UpdateBookingStatusFromCourierExchangeMutation = (
  { __typename?: 'Mutation' }
  & { updateBookingStatusFromCourierExchange?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & BookingDetailsFragment
    & BookingAddressesFragment
  )> }
);

export type UpdateBookingListStatusFromCourierExchangeMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateBookingListStatusFromCourierExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBookingListStatusFromCourierExchange'>
);

export type CreateCustomerMutationVariables = Exact<{
  input: CreateAndUpdateCustomerDtoInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer: (
    { __typename?: 'Customer' }
    & CustomerDetailsFragment
  ) }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: CreateAndUpdateCustomerDtoInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'Customer' }
    & CustomerDetailsFragment
  ) }
);

export type DeleteCustomerMutationVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomer'>
);

export type CreateAddressMutationVariables = Exact<{
  input: CreateAndUpdateAddressDtoInput;
}>;


export type CreateAddressMutation = (
  { __typename?: 'Mutation' }
  & { createAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'name' | 'city' | 'addressLine1' | 'addressLine2' | 'postcode' | 'email' | 'countryId' | 'telephoneNumber' | 'contact' | 'isNotification' | 'isAutoFill' | 'isDefault'>
  ) }
);

export type UpdateAddressMutationVariables = Exact<{
  input: CreateAndUpdateAddressDtoInput;
}>;


export type UpdateAddressMutation = (
  { __typename?: 'Mutation' }
  & { updateAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'name' | 'city' | 'addressLine1' | 'addressLine2' | 'postcode' | 'email' | 'countryId' | 'telephoneNumber' | 'contact' | 'isNotification' | 'isAutoFill' | 'isDefault'>
  ) }
);

export type DeleteAddressMutationVariables = Exact<{
  customerId: Scalars['Long'];
  addressId: Scalars['Long'];
}>;


export type DeleteAddressMutation = (
  { __typename?: 'Mutation' }
  & { deleteAddress: Array<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'name' | 'customerId'>
  )> }
);

export type CreateContactMutationVariables = Exact<{
  input: CreateAndUpdateContactDtoInput;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name'>
  ) }
);

export type UpdateContactMutationVariables = Exact<{
  input: CreateAndUpdateContactDtoInput;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name'>
  ) }
);

export type DeleteContactMutationVariables = Exact<{
  contactId: Scalars['Long'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContact'>
);

export type DeleteCustomerLogoMutationVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type DeleteCustomerLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomerLogo'>
);

export type UpdateCustomerNotificationsMutationVariables = Exact<{
  input: UpdateCustomerNotificationsDtoInput;
}>;


export type UpdateCustomerNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerNotifications: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'isNotificationForQuote' | 'isNotificationForReadyForAllocation' | 'isNotificationForBooking' | 'isNotificationForPobArrival' | 'isNotificationForPOB' | 'isNotificationForPodArrival' | 'isNotificationForPodPartial' | 'isNotificationForPOD' | 'isNotificationForCancellation' | 'isNotificationForCarded' | 'isNotificationForAmendment' | 'isNotificationForDriverAllocated' | 'isNotificationForInvoice' | 'isNotificationForInvoiceDue' | 'customerInvoiceNotificationType'>
  ) }
);

export type UpdateCustomerReferencesAndNotesMutationVariables = Exact<{
  input: UpdateCustomerReferencesAndNotesDtoInput;
}>;


export type UpdateCustomerReferencesAndNotesMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomerReferencesAndNotes: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'customerReferenceLabel' | 'isCustomerReferenceMandatory' | 'isPaperworkRequired' | 'noteForAccount' | 'noteForBooking' | 'instructionsForCourierExchangeDriver' | 'noteForPackage' | 'noteForPaperwork'>
  ) }
);

export type DeleteCustomerTariffMutationVariables = Exact<{
  customerId: Scalars['Long'];
  tariffId: Scalars['Long'];
}>;


export type DeleteCustomerTariffMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomerTariff: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
    & { tariffs: Array<(
      { __typename?: 'CustomerTariff' }
      & Pick<CustomerTariff, 'id' | 'customerId' | 'tariffId'>
    )> }
  ) }
);

export type AllocateDriverBidsMutationVariables = Exact<{
  ids: Array<Scalars['Long']> | Scalars['Long'];
}>;


export type AllocateDriverBidsMutation = (
  { __typename?: 'Mutation' }
  & { allocateDriverBids: Array<(
    { __typename?: 'DriverBid' }
    & Pick<DriverBid, 'id' | 'isAllocated' | 'isEnabled'>
  )> }
);

export type CreateDriverMutationVariables = Exact<{
  input: CreateAndUpdateDriverDtoInput;
}>;


export type CreateDriverMutation = (
  { __typename?: 'Mutation' }
  & { createDriver?: Maybe<(
    { __typename?: 'Driver' }
    & DriverDetailsFragment
  )> }
);

export type UpdateDriverMutationVariables = Exact<{
  input: CreateAndUpdateDriverDtoInput;
}>;


export type UpdateDriverMutation = (
  { __typename?: 'Mutation' }
  & { updateDriver?: Maybe<(
    { __typename?: 'Driver' }
    & DriverDetailsFragment
  )> }
);

export type DeleteDriverMutationVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type DeleteDriverMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDriver'>
);

export type CreateSubDriverMutationVariables = Exact<{
  input: CreateAndUpdateSubDriverDtoInput;
}>;


export type CreateSubDriverMutation = (
  { __typename?: 'Mutation' }
  & { createSubDriver?: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'primaryDriverId' | 'callSign' | 'driverType' | 'email' | 'isActive' | 'xeroCodeId' | 'name' | 'telephoneNumber' | 'xeroPurchaseCode'>
  )> }
);

export type UpdateSubDriverMutationVariables = Exact<{
  input: CreateAndUpdateSubDriverDtoInput;
}>;


export type UpdateSubDriverMutation = (
  { __typename?: 'Mutation' }
  & { updateSubDriver?: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'primaryDriverId' | 'callSign' | 'driverType' | 'email' | 'isActive' | 'xeroCodeId' | 'name' | 'telephoneNumber' | 'xeroPurchaseCode'>
  )> }
);

export type CreateDriverContactMutationVariables = Exact<{
  input: CreateAndUpdateDriverContactDtoInput;
}>;


export type CreateDriverContactMutation = (
  { __typename?: 'Mutation' }
  & { createDriverContact: (
    { __typename?: 'DriverContact' }
    & Pick<DriverContact, 'id' | 'driverId' | 'name' | 'isDefault' | 'telephoneNumber' | 'email' | 'additionalEmails' | 'department' | 'jobRole'>
  ) }
);

export type UpdateDriverContactMutationVariables = Exact<{
  input: CreateAndUpdateDriverContactDtoInput;
}>;


export type UpdateDriverContactMutation = (
  { __typename?: 'Mutation' }
  & { updateDriverContact: (
    { __typename?: 'DriverContact' }
    & Pick<DriverContact, 'id' | 'driverId' | 'name' | 'isDefault' | 'telephoneNumber' | 'email' | 'additionalEmails' | 'department' | 'jobRole'>
  ) }
);

export type DeleteDriverContactMutationVariables = Exact<{
  driverContactId: Scalars['Long'];
}>;


export type DeleteDriverContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDriverContact'>
);

export type SendTestNotificationMutationVariables = Exact<{
  driverId: Scalars['Long'];
  title: Scalars['String'];
  message: Scalars['String'];
  subtitle: Scalars['String'];
  accessToken?: Maybe<Scalars['String']>;
}>;


export type SendTestNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'testPushNotification'>
);

export type UpdateBookingDriverRatingMutationVariables = Exact<{
  input: BookingDriverRatingDtoInput;
}>;


export type UpdateBookingDriverRatingMutation = (
  { __typename?: 'Mutation' }
  & { updateDriverRating: Array<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id' | 'bookingId' | 'rating' | 'ratingComment'>
    & { driver: (
      { __typename?: 'Driver' }
      & Pick<Driver, 'id' | 'averageRating'>
    ) }
  )> }
);

export type UpdateEmailTemplateMutationVariables = Exact<{
  input: UpdateEmailTemplateInput;
}>;


export type UpdateEmailTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateEmailTemplate: (
    { __typename?: 'EmailTemplate' }
    & Pick<EmailTemplate, 'id'>
    & EmailTemplateDetailsFragment
  ) }
);

export type TestSendingEmailToCustomerMutationVariables = Exact<{
  type?: Maybe<EmailTemplateType>;
  bookingId?: Maybe<Scalars['Long']>;
  bookingAddressId?: Maybe<Scalars['Long']>;
}>;


export type TestSendingEmailToCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'testSendingEmailToCustomer'>
);

export type TestSendingEmailToDriverMutationVariables = Exact<{
  type?: Maybe<EmailTemplateType>;
  bookingId?: Maybe<Scalars['Long']>;
  driverId?: Maybe<Scalars['Long']>;
}>;


export type TestSendingEmailToDriverMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'testSendingEmailToDriver'>
);

export type RefreshExchangeRatesMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshExchangeRatesMutation = (
  { __typename?: 'Mutation' }
  & { refreshExchangeRates: Array<(
    { __typename?: 'ExchangeRate' }
    & Pick<ExchangeRate, 'id' | 'sourceCurrencyId' | 'targetCurrencyId'>
  )> }
);

export type RunExchangeRateServiceMutationVariables = Exact<{ [key: string]: never; }>;


export type RunExchangeRateServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runExchangeRateService'>
);

export type ReadNotificationMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & { readNotification: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'isRead'>
  )> }
);

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNotification'>
);

export type CreateOrUpdateInstallationMutationVariables = Exact<{
  input: SetPushNotificationTokenDtoInput;
}>;


export type CreateOrUpdateInstallationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrUpdateInstallation'>
);

export type DeleteInstallationByIdMutationVariables = Exact<{
  installationId: Scalars['Long'];
}>;


export type DeleteInstallationByIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInstallationById'>
);

export type CreateOpenQueryMutationVariables = Exact<{
  input: CreateUpdateOpenQueryDtoInput;
  emailOptions: OpenQueryEmailOptionsDtoInput;
}>;


export type CreateOpenQueryMutation = (
  { __typename?: 'Mutation' }
  & { createOpenQuery?: Maybe<(
    { __typename?: 'OpenQuery' }
    & Pick<OpenQuery, 'accountPayableRecordId' | 'reason'>
  )> }
);

export type DeleteOpenQueryMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteOpenQueryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOpenQuery'>
);

export type DeleteOpenQueryByAccountPayableRecordIdMutationVariables = Exact<{
  accountPayableRecordId: Scalars['Long'];
}>;


export type DeleteOpenQueryByAccountPayableRecordIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOpenQueryByAccountPayableRecord'>
);

export type CreateOtherChargeMutationVariables = Exact<{
  input: CreateAndUpdateOtherChargeDtoInput;
}>;


export type CreateOtherChargeMutation = (
  { __typename?: 'Mutation' }
  & { createOtherCharge: (
    { __typename?: 'OtherCharge' }
    & Pick<OtherCharge, 'id'>
    & OtherChargeDetailsFragment
  ) }
);

export type UpdateOtherChargeMutationVariables = Exact<{
  input: CreateAndUpdateOtherChargeDtoInput;
}>;


export type UpdateOtherChargeMutation = (
  { __typename?: 'Mutation' }
  & { updateOtherCharge: (
    { __typename?: 'OtherCharge' }
    & Pick<OtherCharge, 'id'>
    & OtherChargeDetailsFragment
  ) }
);

export type DeleteOtherChargeMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteOtherChargeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOtherCharge'>
);

export type CreatePaymentMutationVariables = Exact<{
  accountPayableId: Scalars['ID'];
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPayment?: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'status'>
  )> }
);

export type CreateSmartApprovalPaymentMutationVariables = Exact<{
  accountPayableIds?: Maybe<Array<Scalars['Long']> | Scalars['Long']>;
}>;


export type CreateSmartApprovalPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createSmartApprovalPayment: Array<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'status'>
  )> }
);

export type UpdateFailedPaymentStatusMutationVariables = Exact<{
  accountPayableIds?: Maybe<Array<Scalars['Long']> | Scalars['Long']>;
}>;


export type UpdateFailedPaymentStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFailedPaymentStatus'>
);

export type CreateReasonCodeMutationVariables = Exact<{
  input: CreateAndUpdateReasonCodeDtoInput;
}>;


export type CreateReasonCodeMutation = (
  { __typename?: 'Mutation' }
  & { createReasonCode: Array<(
    { __typename?: 'ReasonCode' }
    & Pick<ReasonCode, 'id'>
    & ReasonCodeDetailsFragment
  )> }
);

export type UpdateReasonCodeMutationVariables = Exact<{
  input: CreateAndUpdateReasonCodeDtoInput;
}>;


export type UpdateReasonCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateReasonCode: Array<(
    { __typename?: 'ReasonCode' }
    & Pick<ReasonCode, 'id'>
    & ReasonCodeDetailsFragment
  )> }
);

export type DeleteReasonCodeMutationVariables = Exact<{
  reasonCodeId: Scalars['Long'];
}>;


export type DeleteReasonCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReasonCode'>
);

export type MatchExternalDriverInvoiceMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type MatchExternalDriverInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { matchExternalDriverInvoices: Array<(
    { __typename?: 'ExternalDriverInvoice' }
    & Pick<ExternalDriverInvoice, 'id' | 'fileId' | 'invoiceNumber' | 'createdAt'>
  )> }
);

export type CreateScheduledBookingTemplateMutationVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type CreateScheduledBookingTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createScheduledBookingTemplate: (
    { __typename?: 'ScheduledBookingTemplate' }
    & Pick<ScheduledBookingTemplate, 'id'>
    & TemplateDetailsFragment
  ) }
);

export type UpdateScheduledBookingTemplateMutationVariables = Exact<{
  input: UpdateScheduledBookingTemplateDtoInput;
}>;


export type UpdateScheduledBookingTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateScheduledBookingTemplate: (
    { __typename?: 'ScheduledBookingTemplate' }
    & Pick<ScheduledBookingTemplate, 'id'>
    & TemplateDetailsFragment
  ) }
);

export type CreateScheduledBookingDateMutationVariables = Exact<{
  input: Array<CreateScheduledBookingDateDtoInput> | CreateScheduledBookingDateDtoInput;
}>;


export type CreateScheduledBookingDateMutation = (
  { __typename?: 'Mutation' }
  & { createScheduledBookingDate: Array<(
    { __typename?: 'ScheduledBookingDate' }
    & Pick<ScheduledBookingDate, 'id' | 'executeOnDate' | 'taskId'>
  )> }
);

export type DeleteScheduledBookingDateMutationVariables = Exact<{
  dateId: Scalars['Long'];
}>;


export type DeleteScheduledBookingDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteScheduledBookingDate'>
);

export type CreateScheduledExchangeRateTemplateMutationVariables = Exact<{
  currencyId: Scalars['Long'];
  date: Scalars['DateTime'];
}>;


export type CreateScheduledExchangeRateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createScheduledExchangeRateTemplate?: Maybe<(
    { __typename?: 'ScheduledExchangeRateTemplate' }
    & Pick<ScheduledExchangeRateTemplate, 'id' | 'currencyId' | 'createdBy' | 'createdAt'>
  )> }
);

export type DeleteScheduledExchangeRateTemplateMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteScheduledExchangeRateTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteScheduledExchangeRateTemplate'>
);

export type CreateStandardOperatingProcedureMutationVariables = Exact<{
  input: CreateAndUpdateStandardOperatingProcedureDtoInput;
}>;


export type CreateStandardOperatingProcedureMutation = (
  { __typename?: 'Mutation' }
  & { createStandardOperatingProcedure?: Maybe<(
    { __typename?: 'StandardOperatingProcedure' }
    & Pick<StandardOperatingProcedure, 'id'>
    & StandardOperatingProcedureDetailsFragment
  )> }
);

export type UpdateStandardOperatingProcedureMutationVariables = Exact<{
  input: CreateAndUpdateStandardOperatingProcedureDtoInput;
}>;


export type UpdateStandardOperatingProcedureMutation = (
  { __typename?: 'Mutation' }
  & { updateStandardOperatingProcedure?: Maybe<(
    { __typename?: 'StandardOperatingProcedure' }
    & Pick<StandardOperatingProcedure, 'id'>
    & StandardOperatingProcedureDetailsFragment
  )> }
);

export type DeleteStandardOperatingProcedureAttachmentMutationVariables = Exact<{
  input: DeleteSopAttachmentDtoInput;
}>;


export type DeleteStandardOperatingProcedureAttachmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSopAttachment'>
);

export type CreateStrategicPartnerMutationVariables = Exact<{
  input: CreateAndUpdateStrategicPartnerDtoInput;
}>;


export type CreateStrategicPartnerMutation = (
  { __typename?: 'Mutation' }
  & { createStrategicPartner: (
    { __typename?: 'StrategicPartner' }
    & StrategicPartnerDetailsFragment
  ) }
);

export type UpdateStrategicPartnerMutationVariables = Exact<{
  input: CreateAndUpdateStrategicPartnerDtoInput;
}>;


export type UpdateStrategicPartnerMutation = (
  { __typename?: 'Mutation' }
  & { updateStrategicPartner: (
    { __typename?: 'StrategicPartner' }
    & StrategicPartnerDetailsFragment
  ) }
);

export type DeleteStrategicPartnerMutationVariables = Exact<{
  strategicPartnerId: Scalars['Long'];
}>;


export type DeleteStrategicPartnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStrategicPartner'>
);

export type CreateStrategicPartnerContactMutationVariables = Exact<{
  input: CreateAndUpdateStrategicPartnerContactDtoInput;
}>;


export type CreateStrategicPartnerContactMutation = (
  { __typename?: 'Mutation' }
  & { createStrategicPartnerContact: (
    { __typename?: 'StrategicPartnerContact' }
    & StrategicPartnerContactDetailsFragment
  ) }
);

export type UpdateStrategicPartnerContactMutationVariables = Exact<{
  input: CreateAndUpdateStrategicPartnerContactDtoInput;
}>;


export type UpdateStrategicPartnerContactMutation = (
  { __typename?: 'Mutation' }
  & { updateStrategicPartnerContact: (
    { __typename?: 'StrategicPartnerContact' }
    & StrategicPartnerContactDetailsFragment
  ) }
);

export type DeleteStrategicPartnerContactMutationVariables = Exact<{
  strategicPartnerContactId: Scalars['Long'];
}>;


export type DeleteStrategicPartnerContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteStrategicPartnerContact'>
);

export type CreateTariffMutationVariables = Exact<{
  input: CreateAndUpdateTariffDtoInput;
}>;


export type CreateTariffMutation = (
  { __typename?: 'Mutation' }
  & { createTariff?: Maybe<(
    { __typename?: 'Tariff' }
    & TariffDetailsFragment
  )> }
);

export type UpdateTariffMutationVariables = Exact<{
  input: CreateAndUpdateTariffDtoInput;
}>;


export type UpdateTariffMutation = (
  { __typename?: 'Mutation' }
  & { updateTariff?: Maybe<(
    { __typename?: 'Tariff' }
    & TariffDetailsFragment
  )> }
);

export type DeleteTariffMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteTariffMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTariff'>
);

export type CreateTariffCategoryMutationVariables = Exact<{
  input: CreateAndUpdateTariffCategoryDtoInput;
}>;


export type CreateTariffCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createTariffCategory: (
    { __typename?: 'TariffCategory' }
    & Pick<TariffCategory, 'id' | 'name' | 'courierExchangeVehicleSize' | 'hasSpotRate'>
  ) }
);

export type UpdateTariffCategoryMutationVariables = Exact<{
  input: CreateAndUpdateTariffCategoryDtoInput;
}>;


export type UpdateTariffCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateTariffCategory: (
    { __typename?: 'TariffCategory' }
    & Pick<TariffCategory, 'id' | 'name' | 'courierExchangeVehicleSize' | 'hasSpotRate'>
  ) }
);

export type CreateTariffIconMutationVariables = Exact<{
  input: CreateAndUpdateTariffIconDtoInput;
}>;


export type CreateTariffIconMutation = (
  { __typename?: 'Mutation' }
  & { createTariffIcon?: Maybe<(
    { __typename?: 'TariffIcon' }
    & Pick<TariffIcon, 'id' | 'name' | 'vehicleCapacity' | 'fileId'>
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  )> }
);

export type UpdateTariffIconMutationVariables = Exact<{
  input: CreateAndUpdateTariffIconDtoInput;
}>;


export type UpdateTariffIconMutation = (
  { __typename?: 'Mutation' }
  & { updateTariffIcon?: Maybe<(
    { __typename?: 'TariffIcon' }
    & Pick<TariffIcon, 'id' | 'name' | 'vehicleCapacity' | 'fileId'>
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  )> }
);

export type DeleteTariffIconMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteTariffIconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTariffIcon'>
);

export type CreateTaskMutationVariables = Exact<{
  input: CreateUpdateTaskDtoInput;
}>;


export type CreateTaskMutation = (
  { __typename?: 'Mutation' }
  & { createTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & TaskDetailsFragment
  )> }
);

export type UpdateTaskMutationVariables = Exact<{
  input: CreateUpdateTaskDtoInput;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & TaskDetailsFragment
  )> }
);

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTask'>
);

export type CreateTaskSettingMutationVariables = Exact<{
  input: CreateUpdateTaskSettingDtoInput;
}>;


export type CreateTaskSettingMutation = (
  { __typename?: 'Mutation' }
  & { createSystemTaskSetting?: Maybe<(
    { __typename?: 'SystemTaskSetting' }
    & Pick<SystemTaskSetting, 'id'>
    & TaskSettingDetailsFragment
  )> }
);

export type UpdateTaskSettingMutationVariables = Exact<{
  input: CreateUpdateTaskSettingDtoInput;
}>;


export type UpdateTaskSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateSystemTaskSetting?: Maybe<(
    { __typename?: 'SystemTaskSetting' }
    & Pick<SystemTaskSetting, 'id'>
    & TaskSettingDetailsFragment
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUpdateUserDtoInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createApplicationUser: (
    { __typename?: 'CreateUpdateUserDto' }
    & Pick<CreateUpdateUserDto, 'id'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: CreateUpdateUserDtoInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateApplicationUser: (
    { __typename?: 'CreateUpdateUserDto' }
    & Pick<CreateUpdateUserDto, 'id'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApplicationUser'>
);

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateProfileDtoInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateMyProfile: (
    { __typename?: 'UserProfileDto' }
    & Pick<UserProfileDto, 'id'>
    & UserProfileFragment
  ) }
);

export type UpdateUserApprovalMutationVariables = Exact<{
  input: UpdateUserApprovalDtoInput;
}>;


export type UpdateUserApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserApproval'>
);

export type CreateVehicleMutationVariables = Exact<{
  input: CreateAndUpdateVehicleDtoInput;
}>;


export type CreateVehicleMutation = (
  { __typename?: 'Mutation' }
  & { createVehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'isDefault' | 'driverId' | 'registrationNumber' | 'vehicleTypeId' | 'manufacturer' | 'dateOfManufacture' | 'model' | 'colour' | 'insuranceCompany' | 'insuranceExpiryDate' | 'motTestExpiryDate' | 'isRenewalReminder' | 'policyOrCoverNote' | 'goodsInTransitExpiryDate'>
  ) }
);

export type UpdateVehicleMutationVariables = Exact<{
  input: CreateAndUpdateVehicleDtoInput;
}>;


export type UpdateVehicleMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'isDefault' | 'driverId' | 'registrationNumber' | 'vehicleTypeId' | 'manufacturer' | 'dateOfManufacture' | 'model' | 'colour' | 'insuranceCompany' | 'insuranceExpiryDate' | 'motTestExpiryDate' | 'isRenewalReminder' | 'policyOrCoverNote' | 'goodsInTransitExpiryDate'>
  ) }
);

export type DeleteXeroCodeMutationVariables = Exact<{
  id: Scalars['Long'];
}>;


export type DeleteXeroCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteXeroCode'>
);

export type CreateXeroCodeMutationVariables = Exact<{
  input: CreateAndUpdateXeroCodeDtoInput;
}>;


export type CreateXeroCodeMutation = (
  { __typename?: 'Mutation' }
  & { createXeroCode: (
    { __typename?: 'XeroCode' }
    & Pick<XeroCode, 'id' | 'name' | 'code' | 'description' | 'type' | 'serviceType' | 'isInternational'>
  ) }
);

export type UpdateXeroCodeMutationVariables = Exact<{
  input: CreateAndUpdateXeroCodeDtoInput;
}>;


export type UpdateXeroCodeMutation = (
  { __typename?: 'Mutation' }
  & { updateXeroCode: (
    { __typename?: 'XeroCode' }
    & Pick<XeroCode, 'id' | 'name' | 'code' | 'description' | 'type' | 'serviceType' | 'isInternational'>
  ) }
);

export type CreateInvoiceTaskMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateInvoiceTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createInvoiceTask'>
);

export type ProcessInvoiceTaskMutationVariables = Exact<{ [key: string]: never; }>;


export type ProcessInvoiceTaskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'processTasks'>
);

export type VerifyAddressDeliveryMutationVariables = Exact<{ [key: string]: never; }>;


export type VerifyAddressDeliveryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyAddressDelivery'>
);

export type AllAccountPayableRecordsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<AccountPayableRecordSortInput> | AccountPayableRecordSortInput>;
  where?: Maybe<AccountPayableRecordFilterInput>;
}>;


export type AllAccountPayableRecordsQuery = (
  { __typename?: 'Query' }
  & { accountPayableRecords?: Maybe<(
    { __typename?: 'AccountPayableRecordsConnection' }
    & Pick<AccountPayableRecordsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'AccountPayableRecordsEdge' }
      & { node: (
        { __typename?: 'AccountPayableRecord' }
        & Pick<AccountPayableRecord, 'id' | 'status' | 'statusLabel' | 'net' | 'vat' | 'gross'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        ), openQueries?: Maybe<Array<(
          { __typename?: 'OpenQuery' }
          & Pick<OpenQuery, 'id' | 'reason'>
        )>>, bookingInvoice: (
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'id' | 'invoiceDate' | 'driverInvoiceNumber' | 'dueDate' | 'paymentDate'>
          & { bookingDriver?: Maybe<(
            { __typename?: 'BookingDriver' }
            & Pick<BookingDriver, 'id'>
            & { driver: (
              { __typename?: 'Driver' }
              & Pick<Driver, 'id' | 'name'>
            ) }
          )>, booking: (
            { __typename?: 'Booking' }
            & Pick<Booking, 'id' | 'ourReference'>
          ) }
        ), bookingInvoiceBatchLine?: Maybe<(
          { __typename?: 'BookingInvoiceBatchLine' }
          & Pick<BookingInvoiceBatchLine, 'id' | 'batchId'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetAllAccountPayableRecordsQueryVariables = Exact<{
  order?: Maybe<Array<AccountPayableRecordSortInput> | AccountPayableRecordSortInput>;
  where?: Maybe<AccountPayableRecordFilterInput>;
}>;


export type GetAllAccountPayableRecordsQuery = (
  { __typename?: 'Query' }
  & { allAccountPayableRecords: Array<(
    { __typename?: 'AccountPayableRecord' }
    & Pick<AccountPayableRecord, 'id' | 'status' | 'statusLabel' | 'net' | 'vat' | 'gross'>
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'symbol'>
    ), openQueries?: Maybe<Array<(
      { __typename?: 'OpenQuery' }
      & Pick<OpenQuery, 'id' | 'reason'>
    )>>, bookingInvoice: (
      { __typename?: 'BookingInvoice' }
      & Pick<BookingInvoice, 'id' | 'invoiceDate' | 'driverInvoiceNumber' | 'dueDate' | 'paymentDate'>
      & { bookingDriver?: Maybe<(
        { __typename?: 'BookingDriver' }
        & Pick<BookingDriver, 'id'>
        & { driver: (
          { __typename?: 'Driver' }
          & Pick<Driver, 'id' | 'name'>
        ) }
      )>, booking: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'ourReference'>
      ) }
    ) }
  )> }
);

export type AllGroupedAccountPayableRecordsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<GroupedAccountPayableRecordDtoSortInput> | GroupedAccountPayableRecordDtoSortInput>;
  where?: Maybe<GroupedAccountPayableRecordDtoFilterInput>;
  status: Scalars['Int'];
  dueDateStart?: Maybe<Scalars['DateTime']>;
  dueDateEnd?: Maybe<Scalars['DateTime']>;
  accountsPayableFilterCriteria?: Maybe<AccountsPayableFilterCriteria>;
}>;


export type AllGroupedAccountPayableRecordsQuery = (
  { __typename?: 'Query' }
  & { groupedAccountPayableRecords?: Maybe<(
    { __typename?: 'GroupedAccountPayableRecordsConnection' }
    & Pick<GroupedAccountPayableRecordsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'GroupedAccountPayableRecordsEdge' }
      & { node: (
        { __typename?: 'GroupedAccountPayableRecordDto' }
        & Pick<GroupedAccountPayableRecordDto, 'id' | 'driverId' | 'driverName' | 'invoiceCount' | 'accountPayableStatus' | 'courierExchangeLoadId' | 'ourReference' | 'driverNumber' | 'dueDate' | 'xeroPurchaseCode'>
        & { currencyAmountSum: Array<(
          { __typename?: 'AccountPayableCurrencyAmountDto' }
          & Pick<AccountPayableCurrencyAmountDto, 'id' | 'net' | 'vat' | 'gross' | 'currencySymbol'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetAccountPayableRecordQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetAccountPayableRecordQuery = (
  { __typename?: 'Query' }
  & { accountPayableRecord?: Maybe<(
    { __typename?: 'AccountPayableRecord' }
    & Pick<AccountPayableRecord, 'id' | 'status' | 'gross'>
    & { openQueries?: Maybe<Array<(
      { __typename?: 'OpenQuery' }
      & Pick<OpenQuery, 'id' | 'reason'>
    )>>, currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'symbol'>
    ), bookingInvoice: (
      { __typename?: 'BookingInvoice' }
      & Pick<BookingInvoice, 'id' | 'invoiceDate'>
      & { bookingDriver?: Maybe<(
        { __typename?: 'BookingDriver' }
        & Pick<BookingDriver, 'id'>
        & { driver: (
          { __typename?: 'Driver' }
          & Pick<Driver, 'id' | 'name' | 'xeroPurchaseCode'>
          & { vatCategory?: Maybe<(
            { __typename?: 'VatCategory' }
            & Pick<VatCategory, 'id'>
          )> }
        ) }
      )>, booking: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'ourReference' | 'customerReference' | 'courierExchangeLoadId' | 'createdAt'>
        & { tariff?: Maybe<(
          { __typename?: 'Tariff' }
          & Pick<Tariff, 'id' | 'name'>
          & { tariffCategory: (
            { __typename?: 'TariffCategory' }
            & Pick<TariffCategory, 'id' | 'name'>
          ) }
        )>, addresses: Array<(
          { __typename?: 'BookingAddress' }
          & Pick<BookingAddress, 'id' | 'type' | 'at' | 'by'>
          & { address: (
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'name' | 'postcode' | 'addressLine1' | 'addressLine2' | 'city'>
            & { country: (
              { __typename?: 'Country' }
              & Pick<Country, 'name'>
            ) }
          ) }
        )> }
      ) }
    ) }
  )> }
);

export type GetAccountPayableDriverNameQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetAccountPayableDriverNameQuery = (
  { __typename?: 'Query' }
  & { accountPayableRecord?: Maybe<(
    { __typename?: 'AccountPayableRecord' }
    & Pick<AccountPayableRecord, 'id' | 'status' | 'statusLabel'>
    & { bookingInvoice: (
      { __typename?: 'BookingInvoice' }
      & Pick<BookingInvoice, 'id'>
      & { booking: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'ourReference'>
      ), bookingDriver?: Maybe<(
        { __typename?: 'BookingDriver' }
        & Pick<BookingDriver, 'id'>
        & { driver: (
          { __typename?: 'Driver' }
          & Pick<Driver, 'id' | 'name' | 'email'>
        ) }
      )> }
    ), bookingInvoiceBatchLine?: Maybe<(
      { __typename?: 'BookingInvoiceBatchLine' }
      & Pick<BookingInvoiceBatchLine, 'id' | 'batchId'>
    )> }
  )> }
);

export type GetTotalSumQueryVariables = Exact<{
  status: Scalars['Int'];
  supplierId: Scalars['Long'];
}>;


export type GetTotalSumQuery = (
  { __typename?: 'Query' }
  & { accountPayableTotals: Array<(
    { __typename?: 'AccountPayableCurrencyAmountDto' }
    & Pick<AccountPayableCurrencyAmountDto, 'id' | 'net' | 'vat' | 'gross' | 'currencySymbol'>
  )> }
);

export type AccountPayableOverviewQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  supplierCodes: Array<Scalars['String']> | Scalars['String'];
}>;


export type AccountPayableOverviewQuery = (
  { __typename?: 'Query' }
  & { accountPayableOverview: (
    { __typename?: 'AccountPayableOverviewDto' }
    & Pick<AccountPayableOverviewDto, 'eurosAmount' | 'poundsAmount' | 'dollarsAmount' | 'scheduledInvoicesCount' | 'bookingsWithCompletedDocumentsCount' | 'bookingsWithOpenQueriesCount' | 'bookingsWithSupplementaryInvoiceReceivedCount' | 'bookingsWithCreditNoteReceivedCount' | 'bookingsWithApprovedDocumentsCount' | 'overduesForApprovalCount' | 'failedPaymentsCount' | 'sentPaymentsCount'>
  ) }
);

export type GetAccountPayableTotalCountQueryVariables = Exact<{
  status: Scalars['Int'];
  supplierId: Scalars['Long'];
}>;


export type GetAccountPayableTotalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'accountPayableTotalCount'>
);

export type GetAccountPayableInvoiceQueryVariables = Exact<{
  accountPayableRecordId: Scalars['Long'];
}>;


export type GetAccountPayableInvoiceQuery = (
  { __typename?: 'Query' }
  & { accountPayableInvoice: (
    { __typename?: 'AccountPayablePanelDto' }
    & Pick<AccountPayablePanelDto, 'htmlString' | 'createdAt'>
  ) }
);

export type GetAccountPayablePodQueryVariables = Exact<{
  accountPayableRecordId: Scalars['Long'];
}>;


export type GetAccountPayablePodQuery = (
  { __typename?: 'Query' }
  & { accountPayablePod: (
    { __typename?: 'AccountPayablePanelPodDto' }
    & Pick<AccountPayablePanelPodDto, 'id' | 'htmlString' | 'createdAt' | 'signatureUrls' | 'proofOfDeliveryPhotoUrls' | 'proofOfCollectionPhotoUrls'>
  ) }
);

export type GetAccountPayableInvoiceSearchRequestQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type GetAccountPayableInvoiceSearchRequestQuery = (
  { __typename?: 'Query' }
  & { accountPayableSearchResults: Array<(
    { __typename?: 'SearchResultDto' }
    & Pick<SearchResultDto, 'entityId' | 'entityName' | 'description'>
  )> }
);

export type GetFailedPaymentErrorMessageQueryVariables = Exact<{
  accountPayableRecordId: Scalars['Long'];
}>;


export type GetFailedPaymentErrorMessageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'paymentFailedErrorMessage'>
);

export type GetAccountPayableRecordsByIdsQueryVariables = Exact<{
  accountPayableRecordsIds: Array<Scalars['Long']> | Scalars['Long'];
}>;


export type GetAccountPayableRecordsByIdsQuery = (
  { __typename?: 'Query' }
  & { accountPayableRecordsByIds: Array<(
    { __typename?: 'AccountPayableRecord' }
    & Pick<AccountPayableRecord, 'id'>
    & { bookingInvoice: (
      { __typename?: 'BookingInvoice' }
      & Pick<BookingInvoice, 'id' | 'invoiceDate' | 'driverInvoiceNumber'>
    ) }
  )> }
);

export type AllAccountPayableRecordsWithBatchLineQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<AccountPayableRecordSortInput> | AccountPayableRecordSortInput>;
  where?: Maybe<AccountPayableRecordFilterInput>;
}>;


export type AllAccountPayableRecordsWithBatchLineQuery = (
  { __typename?: 'Query' }
  & { accountPayableRecords?: Maybe<(
    { __typename?: 'AccountPayableRecordsConnection' }
    & Pick<AccountPayableRecordsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'AccountPayableRecordsEdge' }
      & { node: (
        { __typename?: 'AccountPayableRecord' }
        & Pick<AccountPayableRecord, 'id' | 'status' | 'statusLabel' | 'net' | 'vat' | 'gross'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'symbol'>
        ), openQueries?: Maybe<Array<(
          { __typename?: 'OpenQuery' }
          & Pick<OpenQuery, 'id' | 'reason'>
        )>>, bookingInvoice: (
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'id' | 'invoiceDate' | 'driverInvoiceNumber' | 'dueDate' | 'paymentDate'>
          & { bookingDriver?: Maybe<(
            { __typename?: 'BookingDriver' }
            & Pick<BookingDriver, 'id'>
            & { driver: (
              { __typename?: 'Driver' }
              & Pick<Driver, 'id' | 'name'>
            ) }
          )>, booking: (
            { __typename?: 'Booking' }
            & Pick<Booking, 'id' | 'ourReference'>
          ) }
        ), bookingInvoiceBatchLine?: Maybe<(
          { __typename?: 'BookingInvoiceBatchLine' }
          & Pick<BookingInvoiceBatchLine, 'id'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type AccountPayableRecordLogsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  accountPayableRecordId: Scalars['Long'];
}>;


export type AccountPayableRecordLogsQuery = (
  { __typename?: 'Query' }
  & { accountPayableRecordLogs?: Maybe<(
    { __typename?: 'AccountPayableRecordLogsConnection' }
    & Pick<AccountPayableRecordLogsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'AccountPayableRecordLogsEdge' }
      & { node: (
        { __typename?: 'AccountPayableRecordLog' }
        & Pick<AccountPayableRecordLog, 'id' | 'description' | 'reason' | 'createdAt' | 'createdBy'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetAccountPayableDetailsQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetAccountPayableDetailsQuery = (
  { __typename?: 'Query' }
  & { accountPayableRecord?: Maybe<(
    { __typename?: 'AccountPayableRecord' }
    & Pick<AccountPayableRecord, 'id' | 'net'>
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'symbol'>
    ) }
  )> }
);

export type GetAcquisitionSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAcquisitionSourcesQuery = (
  { __typename?: 'Query' }
  & { acquisitionSources: Array<(
    { __typename?: 'CustomerAcquisitionSource' }
    & Pick<CustomerAcquisitionSource, 'id' | 'name'>
  )> }
);

export type GetActivitiesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ActivitySortInput> | ActivitySortInput>;
  where?: Maybe<ActivityFilterInput>;
}>;


export type GetActivitiesQuery = (
  { __typename?: 'Query' }
  & { activities?: Maybe<(
    { __typename?: 'ActivitiesConnection' }
    & Pick<ActivitiesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'ActivitiesEdge' }
      & { node: (
        { __typename?: 'Activity' }
        & ActivityDetailsFragment
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetActivityQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetActivityQuery = (
  { __typename?: 'Query' }
  & { activity?: Maybe<(
    { __typename?: 'Activity' }
    & ActivityDetailsFragment
  )> }
);

export type GetAddressQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetAddressQuery = (
  { __typename?: 'Query' }
  & { address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'name' | 'city' | 'addressLine1' | 'addressLine2' | 'postcode' | 'email' | 'countryId' | 'telephoneNumber' | 'contact' | 'isNotification' | 'isAutoFill' | 'isDefault'>
  )> }
);

export type GetPagedAddressesQueryVariables = Exact<{
  customerId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<AddressSortInput> | AddressSortInput>;
  where?: Maybe<AddressFilterInput>;
}>;


export type GetPagedAddressesQuery = (
  { __typename?: 'Query' }
  & { addresses?: Maybe<(
    { __typename?: 'AddressesConnection' }
    & Pick<AddressesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'AddressesEdge' }
      & { node: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'name' | 'addressLine1' | 'addressLine2' | 'city' | 'postcode' | 'email' | 'telephoneNumber' | 'isDefault'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetCustomerDefaultAddressQueryVariables = Exact<{
  customerId: Scalars['Long'];
  where?: Maybe<AddressFilterInput>;
}>;


export type GetCustomerDefaultAddressQuery = (
  { __typename?: 'Query' }
  & { addresses?: Maybe<(
    { __typename?: 'AddressesConnection' }
    & { edges?: Maybe<Array<(
      { __typename?: 'AddressesEdge' }
      & { node: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'name' | 'city' | 'addressLine1' | 'addressLine2' | 'postcode' | 'countryId' | 'contact' | 'email' | 'telephoneNumber' | 'isDefault'>
      ) }
    )>> }
  )> }
);

export type GetLoqateFindQueryVariables = Exact<{
  text: Scalars['String'];
  countryId: Scalars['Long'];
  container?: Maybe<Scalars['String']>;
  order?: Maybe<Array<LoqateFindDtoSortInput> | LoqateFindDtoSortInput>;
  where?: Maybe<LoqateFindDtoFilterInput>;
}>;


export type GetLoqateFindQuery = (
  { __typename?: 'Query' }
  & { loqateFind?: Maybe<Array<(
    { __typename?: 'LoqateFindDto' }
    & Pick<LoqateFindDto, 'id' | 'type' | 'text' | 'description' | 'isContainer' | 'isRetrievable'>
  )>> }
);

export type GetLoqateRetrieveQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetLoqateRetrieveQuery = (
  { __typename?: 'Query' }
  & { loqateRetrieve?: Maybe<(
    { __typename?: 'LoqateRetrieveDto' }
    & Pick<LoqateRetrieveDto, 'id' | 'name' | 'postcode' | 'addressLine1' | 'addressLine2' | 'city' | 'countryId'>
  )> }
);

export type GetDistanceCalculationQueryVariables = Exact<{
  input: Array<GoogleAddressDtoInput> | GoogleAddressDtoInput;
}>;


export type GetDistanceCalculationQuery = (
  { __typename?: 'Query' }
  & { distanceCalculation: (
    { __typename?: 'DistanceDto' }
    & Pick<DistanceDto, 'id' | 'status' | 'errorMessage' | 'distance'>
  ) }
);

export type GetPagedBannersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BannerSortInput> | BannerSortInput>;
  where?: Maybe<BannerFilterInput>;
}>;


export type GetPagedBannersQuery = (
  { __typename?: 'Query' }
  & { pagedBanners?: Maybe<(
    { __typename?: 'PagedBannersConnection' }
    & Pick<PagedBannersConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedBannersEdge' }
      & { node: (
        { __typename?: 'Banner' }
        & Pick<Banner, 'id' | 'name' | 'isActive' | 'createdAt' | 'createdBy'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetBannersQueryVariables = Exact<{
  where?: Maybe<BannerFilterInput>;
}>;


export type GetBannersQuery = (
  { __typename?: 'Query' }
  & { banners: Array<(
    { __typename?: 'Banner' }
    & Pick<Banner, 'id' | 'name' | 'isActive'>
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  )> }
);

export type GetBannerQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetBannerQuery = (
  { __typename?: 'Query' }
  & { banner?: Maybe<(
    { __typename?: 'Banner' }
    & Pick<Banner, 'id' | 'name' | 'type' | 'isActive' | 'fileId'>
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  )> }
);

export type GetBookingAttachmentQueryVariables = Exact<{
  bookingId: Scalars['Long'];
  fileId: Scalars['Long'];
}>;


export type GetBookingAttachmentQuery = (
  { __typename?: 'Query' }
  & { bookingAttachment?: Maybe<(
    { __typename?: 'BookingAttachment' }
    & Pick<BookingAttachment, 'id' | 'isAvailableForDriver' | 'isAvailableForCustomer' | 'fileId' | 'bookingId' | 'createdBy'>
    & { file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'absoluteUri'>
    ) }
  )> }
);

export type GetBookingAttachmentsQueryVariables = Exact<{
  bookingId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<FileFilterInput>;
  order?: Maybe<Array<FileSortInput> | FileSortInput>;
}>;


export type GetBookingAttachmentsQuery = (
  { __typename?: 'Query' }
  & { bookingAttachments?: Maybe<(
    { __typename?: 'BookingAttachmentsConnection' }
    & Pick<BookingAttachmentsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'BookingAttachmentsEdge' }
      & { node: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'category' | 'size' | 'description' | 'fileOrigin' | 'originalFileName' | 'bookingAttachmentId'>
        & { bookingAttachment?: Maybe<(
          { __typename?: 'BookingAttachment' }
          & Pick<BookingAttachment, 'id' | 'isAvailableForDriver' | 'isAvailableForCustomer'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetBookingInvoiceBatchesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingInvoiceBatchSortInput> | BookingInvoiceBatchSortInput>;
  where?: Maybe<BookingInvoiceBatchFilterInput>;
}>;


export type GetBookingInvoiceBatchesQuery = (
  { __typename?: 'Query' }
  & { bookingInvoiceBatches?: Maybe<(
    { __typename?: 'BookingInvoiceBatchesConnection' }
    & Pick<BookingInvoiceBatchesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'BookingInvoiceBatchesEdge' }
      & { node: (
        { __typename?: 'BookingInvoiceBatch' }
        & Pick<BookingInvoiceBatch, 'id' | 'date' | 'paymentDate' | 'createdAt' | 'lastModifiedAt' | 'currencyId' | 'isSentToXero' | 'isSuccess'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'code'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type AllbookingInvoiceBatchLinesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingInvoiceBatchLineSortInput> | BookingInvoiceBatchLineSortInput>;
  where?: Maybe<BookingInvoiceBatchLineFilterInput>;
}>;


export type AllbookingInvoiceBatchLinesQuery = (
  { __typename?: 'Query' }
  & { bookingInvoiceBatchLines?: Maybe<(
    { __typename?: 'BookingInvoiceBatchLinesConnection' }
    & Pick<BookingInvoiceBatchLinesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'BookingInvoiceBatchLinesEdge' }
      & { node: (
        { __typename?: 'BookingInvoiceBatchLine' }
        & Pick<BookingInvoiceBatchLine, 'id' | 'accountCode' | 'taxType' | 'total'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetBookingInvoiceBatchQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetBookingInvoiceBatchQuery = (
  { __typename?: 'Query' }
  & { bookingInvoiceBatch?: Maybe<(
    { __typename?: 'BookingInvoiceBatch' }
    & Pick<BookingInvoiceBatch, 'id' | 'isSentToXero' | 'isSuccess' | 'errorMessage'>
  )> }
);

export type GetPagedBookingsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingSortInput> | BookingSortInput>;
  where?: Maybe<BookingFilterInput>;
}>;


export type GetPagedBookingsQuery = (
  { __typename?: 'Query' }
  & { bookings?: Maybe<(
    { __typename?: 'BookingsConnection' }
    & Pick<BookingsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'BookingsEdge' }
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'ourReference' | 'status' | 'pagedStatus' | 'statusLabel' | 'courierServicesStatusLabel' | 'collectionEstimatedTimeOfArrival' | 'deliveryEstimatedTimeOfArrival' | 'isOnHold' | 'isArchived' | 'isCancelledOrQuoteLost' | 'isPending' | 'isQuickQuote' | 'takenOn' | 'docs' | 'isUsingInternalApp' | 'consignmentFee' | 'contactId' | 'courierExchangeLoadId' | 'customerReference' | 'customerId' | 'tariffId' | 'revenue' | 'hasTasks' | 'hasNotes' | 'takenBy' | 'salesInvoiceNumber'>
        & { contact: (
          { __typename?: 'Contact' }
          & Pick<Contact, 'id' | 'name'>
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'name'>
        ), charges: Array<(
          { __typename?: 'BookingCharge' }
          & Pick<BookingCharge, 'id' | 'rate' | 'quantity'>
        )>, addresses: Array<(
          { __typename?: 'BookingAddress' }
          & Pick<BookingAddress, 'id' | 'type' | 'sequenceOrder' | 'at' | 'by'>
          & { address: (
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'name' | 'city' | 'postcode'>
          ) }
        )>, tariff?: Maybe<(
          { __typename?: 'Tariff' }
          & Pick<Tariff, 'id' | 'name'>
          & { tariffCategory: (
            { __typename?: 'TariffCategory' }
            & Pick<TariffCategory, 'id' | 'name'>
          ), xeroCode?: Maybe<(
            { __typename?: 'XeroCode' }
            & Pick<XeroCode, 'id' | 'code' | 'name' | 'isInternational'>
          )> }
        )>, attachments: Array<(
          { __typename?: 'BookingAttachment' }
          & Pick<BookingAttachment, 'id'>
        )>, drivers?: Maybe<Array<(
          { __typename?: 'BookingDriver' }
          & Pick<BookingDriver, 'charge'>
          & { driver: (
            { __typename?: 'Driver' }
            & Pick<Driver, 'id' | 'name'>
          ) }
        )>>, packages: Array<(
          { __typename?: 'Package' }
          & Pick<Package, 'packageReference'>
        )>, invoices: Array<(
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'id' | 'invoiceType' | 'invoiceNumber'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GerUserBookingsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingSortInput> | BookingSortInput>;
  where?: Maybe<BookingFilterInput>;
}>;


export type GerUserBookingsQuery = (
  { __typename?: 'Query' }
  & { userBookings?: Maybe<(
    { __typename?: 'UserBookingsConnection' }
    & Pick<UserBookingsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'UserBookingsEdge' }
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'ourReference' | 'status' | 'pagedStatus' | 'statusLabel' | 'collectionEstimatedTimeOfArrival' | 'deliveryEstimatedTimeOfArrival' | 'isOnHold' | 'isArchived' | 'isCancelledOrQuoteLost' | 'isPending' | 'isQuickQuote' | 'takenOn' | 'docs' | 'isUsingInternalApp' | 'consignmentFee' | 'courierExchangeLoadId' | 'contactId' | 'customerReference' | 'customerId' | 'tariffId' | 'distance' | 'hasNotes' | 'takenBy' | 'salesInvoiceNumber'>
        & { contact: (
          { __typename?: 'Contact' }
          & Pick<Contact, 'id' | 'name'>
        ), customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'name' | 'isReferenceRequired'>
        ), charges: Array<(
          { __typename?: 'BookingCharge' }
          & Pick<BookingCharge, 'id' | 'rate' | 'quantity'>
        )>, addresses: Array<(
          { __typename?: 'BookingAddress' }
          & Pick<BookingAddress, 'id' | 'type' | 'sequenceOrder' | 'at' | 'by'>
          & { address: (
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'name' | 'city' | 'postcode'>
          ) }
        )>, tariff?: Maybe<(
          { __typename?: 'Tariff' }
          & Pick<Tariff, 'id' | 'name'>
        )>, attachments: Array<(
          { __typename?: 'BookingAttachment' }
          & Pick<BookingAttachment, 'id'>
        )>, packages: Array<(
          { __typename?: 'Package' }
          & Pick<Package, 'packageReference'>
        )>, invoices: Array<(
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'id' | 'invoiceType' | 'invoiceNumber'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetBookingByTrackingNumberQueryVariables = Exact<{
  trackingGuid: Scalars['UUID'];
}>;


export type GetBookingByTrackingNumberQuery = (
  { __typename?: 'Query' }
  & { bookingByTrackingNumber?: Maybe<(
    { __typename?: 'BookingTrackingDto' }
    & Pick<BookingTrackingDto, 'id' | 'distance' | 'consignmentFee' | 'trackingGuid' | 'trackingExpiredDate'>
    & { tariff?: Maybe<(
      { __typename?: 'TariffPagedDto' }
      & Pick<TariffPagedDto, 'id' | 'rate' | 'minMiles' | 'minCharge'>
    )>, addresses: Array<(
      { __typename?: 'AddressBookingDto' }
      & Pick<AddressBookingDto, 'id' | 'sequenceOrder' | 'type'>
      & { address: (
        { __typename?: 'AddressDto' }
        & Pick<AddressDto, 'id' | 'addressLine1' | 'addressLine2' | 'postcode' | 'city' | 'countryId' | 'isVerified' | 'name' | 'googleMapsPlaceId'>
      ) }
    )> }
  )> }
);

export type GetBookingQueryVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type GetBookingQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & BookingDetailsFragment
    & BookingAddressesFragment
    & BookingDriversFragment
    & BookingChargesFragment
    & BookingPackagesFragment
    & BookingTariffFragment
  )> }
);

export type GetUserBookingQueryVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type GetUserBookingQuery = (
  { __typename?: 'Query' }
  & { userBooking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & UserBookingDetailsFragment
    & BookingAddressesFragment
    & BookingChargesFragment
    & BookingPackagesFragment
    & BookingTariffFragment
  )> }
);

export type GetBookingAddressesQueryVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type GetBookingAddressesQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & BookingAddressesFragment
  )> }
);

export type GetBookingAddressesDataQueryVariables = Exact<{
  bookingId: Scalars['Long'];
  where?: Maybe<BookingAddressFilterInput>;
}>;


export type GetBookingAddressesDataQuery = (
  { __typename?: 'Query' }
  & { bookingAddresses: Array<(
    { __typename?: 'BookingAddress' }
    & Pick<BookingAddress, 'id'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'name' | 'postcode'>
    ) }
  )> }
);

export type GetUserBookingAddressesQueryVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type GetUserBookingAddressesQuery = (
  { __typename?: 'Query' }
  & { userBooking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id'>
    & BookingAddressesFragment
  )> }
);

export type GetBookingAddressSignatureQueryVariables = Exact<{
  bookingAddressId: Scalars['Long'];
}>;


export type GetBookingAddressSignatureQuery = (
  { __typename?: 'Query' }
  & { bookingAddressSignature?: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id'>
    & FileDetailsFragment
  )> }
);

export type GetBookingAddressAttachmentsQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetBookingAddressAttachmentsQuery = (
  { __typename?: 'Query' }
  & { bookingAddress?: Maybe<(
    { __typename?: 'BookingAddress' }
    & Pick<BookingAddress, 'id' | 'type' | 'bookingId'>
    & { attachments: Array<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  )> }
);

export type GetBookingAllocatedDriversQueryVariables = Exact<{
  bookingId: Scalars['Long'];
  order?: Maybe<Array<BookingDriverSortInput> | BookingDriverSortInput>;
  where?: Maybe<BookingDriverFilterInput>;
}>;


export type GetBookingAllocatedDriversQuery = (
  { __typename?: 'Query' }
  & { bookingAllocatedDrivers: Array<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id' | 'charge' | 'extraPayment'>
    & { driver: (
      { __typename?: 'Driver' }
      & Pick<Driver, 'id' | 'name'>
    ), vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id'>
      & { vehicleType: (
        { __typename?: 'VehicleType' }
        & Pick<VehicleType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetBookingAllocatedDriversRatingsQueryVariables = Exact<{
  bookingId: Scalars['Long'];
  order?: Maybe<Array<BookingDriverSortInput> | BookingDriverSortInput>;
  where?: Maybe<BookingDriverFilterInput>;
}>;


export type GetBookingAllocatedDriversRatingsQuery = (
  { __typename?: 'Query' }
  & { bookingAllocatedDrivers: Array<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id' | 'rating'>
  )> }
);

export type GetPagedBookingAllocatedDriversQueryVariables = Exact<{
  bookingId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingDriverSortInput> | BookingDriverSortInput>;
  where?: Maybe<BookingDriverFilterInput>;
}>;


export type GetPagedBookingAllocatedDriversQuery = (
  { __typename?: 'Query' }
  & { pagedBookingAllocatedDrivers?: Maybe<(
    { __typename?: 'PagedBookingAllocatedDriversConnection' }
    & Pick<PagedBookingAllocatedDriversConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedBookingAllocatedDriversEdge' }
      & { node: (
        { __typename?: 'BookingDriver' }
        & Pick<BookingDriver, 'id' | 'charge' | 'extraPayment' | 'createdAt' | 'xeroPurchaseCode' | 'allocationStatus' | 'totalCharge' | 'driverNotes' | 'reference' | 'rating'>
        & { bookingExchangeRates?: Maybe<Array<(
          { __typename?: 'BookingExchangeRate' }
          & Pick<BookingExchangeRate, 'id' | 'stage'>
          & { exchangeRate: (
            { __typename?: 'ExchangeRate' }
            & Pick<ExchangeRate, 'id' | 'rate'>
            & { sourceCurrency: (
              { __typename?: 'Currency' }
              & Pick<Currency, 'id' | 'code'>
            ), targetCurrency: (
              { __typename?: 'Currency' }
              & Pick<Currency, 'id' | 'code'>
            ) }
          ) }
        )>>, driver: (
          { __typename?: 'Driver' }
          & Pick<Driver, 'id' | 'name' | 'callSign' | 'identityUserName' | 'isUsingInternalApp'>
          & { xeroCode?: Maybe<(
            { __typename?: 'XeroCode' }
            & Pick<XeroCode, 'id' | 'name'>
          )> }
        ), vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id'>
          & { vehicleType: (
            { __typename?: 'VehicleType' }
            & Pick<VehicleType, 'id' | 'name'>
          ) }
        )>, currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'id' | 'code'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetBookingAllocatedDriverQueryVariables = Exact<{
  bookingDriverId: Scalars['Long'];
}>;


export type GetBookingAllocatedDriverQuery = (
  { __typename?: 'Query' }
  & { bookingAllocatedDriver?: Maybe<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id'>
    & BookingAllocatedDriverFragment
  )> }
);

export type GetInvoiceBookingsQueryVariables = Exact<{
  invoiceNumber: Scalars['Long'];
  invoiceType: InvoiceType;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingSortInput> | BookingSortInput>;
  where?: Maybe<BookingFilterInput>;
}>;


export type GetInvoiceBookingsQuery = (
  { __typename?: 'Query' }
  & { invoiceBookings?: Maybe<(
    { __typename?: 'InvoiceBookingsConnection' }
    & Pick<InvoiceBookingsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'InvoiceBookingsEdge' }
      & { node: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'ourReference' | 'customerReference' | 'status' | 'createdAt'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetBookingInvoiceQueryVariables = Exact<{
  customerId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingInvoiceSortInput> | BookingInvoiceSortInput>;
  where?: Maybe<BookingInvoiceFilterInput>;
}>;


export type GetBookingInvoiceQuery = (
  { __typename?: 'Query' }
  & { bookingInvoice?: Maybe<(
    { __typename?: 'BookingInvoiceConnection' }
    & Pick<BookingInvoiceConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'BookingInvoiceEdge' }
      & { node: (
        { __typename?: 'BookingInvoice' }
        & Pick<BookingInvoice, 'id' | 'invoiceNumber' | 'invoiceDate' | 'reference' | 'bookingId'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetBookingLoadIdQueryVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type GetBookingLoadIdQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'courierExchangeLoadId'>
  )> }
);

export type GetBookingLogsQueryVariables = Exact<{
  bookingId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingLogSortInput> | BookingLogSortInput>;
}>;


export type GetBookingLogsQuery = (
  { __typename?: 'Query' }
  & { bookingLogs?: Maybe<(
    { __typename?: 'BookingLogsConnection' }
    & Pick<BookingLogsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'BookingLogsEdge' }
      & { node: (
        { __typename?: 'BookingLog' }
        & Pick<BookingLog, 'id' | 'createdAt' | 'createdBy' | 'type' | 'description'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetBookingLogQueryVariables = Exact<{
  bookingLogId: Scalars['Long'];
}>;


export type GetBookingLogQuery = (
  { __typename?: 'Query' }
  & { bookingLog?: Maybe<(
    { __typename?: 'BookingLog' }
    & Pick<BookingLog, 'id' | 'type' | 'description' | 'details'>
  )> }
);

export type GetBookingAuditQueryVariables = Exact<{
  bookingId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<AuditRecordSortInput> | AuditRecordSortInput>;
}>;


export type GetBookingAuditQuery = (
  { __typename?: 'Query' }
  & { bookingAudit?: Maybe<(
    { __typename?: 'BookingAuditConnection' }
    & Pick<BookingAuditConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'BookingAuditEdge' }
      & { node: (
        { __typename?: 'AuditRecord' }
        & Pick<AuditRecord, 'id' | 'createdAt' | 'createdBy' | 'name' | 'primaryKey' | 'action' | 'changes' | 'columnValues' | 'transactionId'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetAuditRecordQueryVariables = Exact<{
  auditRecordId: Scalars['Long'];
}>;


export type GetAuditRecordQuery = (
  { __typename?: 'Query' }
  & { auditRecord?: Maybe<(
    { __typename?: 'AuditRecord' }
    & Pick<AuditRecord, 'id' | 'name' | 'action' | 'columnValues' | 'success' | 'errorMessage'>
  )> }
);

export type GetQuoteQueryVariables = Exact<{
  quoteId: Scalars['Long'];
}>;


export type GetQuoteQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'docketReference' | 'takenBy' | 'takenOn'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
    ), drivers?: Maybe<Array<(
      { __typename?: 'BookingDriver' }
      & Pick<BookingDriver, 'id'>
    )>> }
    & BookingDetailsFragment
    & BookingAddressesFragment
    & BookingChargesFragment
    & BookingTariffFragment
  )> }
);

export type GetUserQuoteQueryVariables = Exact<{
  quoteId: Scalars['Long'];
}>;


export type GetUserQuoteQuery = (
  { __typename?: 'Query' }
  & { userBooking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'docketReference' | 'takenBy' | 'takenOn'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
    ), drivers?: Maybe<Array<(
      { __typename?: 'BookingDriver' }
      & Pick<BookingDriver, 'id'>
    )>> }
    & UserBookingDetailsFragment
    & BookingAddressesFragment
    & BookingChargesFragment
    & BookingTariffFragment
  )> }
);

export type GetPagedCustomerInvoiceQueryVariables = Exact<{
  customerId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<CustomerInvoiceDtoSortInput> | CustomerInvoiceDtoSortInput>;
  where?: Maybe<CustomerInvoiceDtoFilterInput>;
}>;


export type GetPagedCustomerInvoiceQuery = (
  { __typename?: 'Query' }
  & { pagedCustomerInvoices?: Maybe<(
    { __typename?: 'PagedCustomerInvoicesConnection' }
    & Pick<PagedCustomerInvoicesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedCustomerInvoicesEdge' }
      & { node: (
        { __typename?: 'CustomerInvoiceDto' }
        & Pick<CustomerInvoiceDto, 'id' | 'bookingStatuses' | 'ourReferences' | 'customerReferences' | 'bookingDate'>
        & { bookings: Array<(
          { __typename?: 'Booking' }
          & Pick<Booking, 'id' | 'status' | 'customerReference' | 'createdAt' | 'collectionEstimatedTimeOfArrival'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetPagedInvoiceLinesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingInvoiceContentSortInput> | BookingInvoiceContentSortInput>;
  where?: Maybe<BookingInvoiceContentFilterInput>;
}>;


export type GetPagedInvoiceLinesQuery = (
  { __typename?: 'Query' }
  & { pagedInvoiceLines?: Maybe<(
    { __typename?: 'PagedInvoiceLinesConnection' }
    & Pick<PagedInvoiceLinesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedInvoiceLinesEdge' }
      & { node: (
        { __typename?: 'BookingInvoiceContent' }
        & Pick<BookingInvoiceContent, 'id' | 'unitAmount' | 'accountCode' | 'description'>
        & { bookingInvoice: (
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'invoiceNumber' | 'reference' | 'invoiceDate' | 'dueDate' | 'bookingId' | 'driverInvoiceNumber'>
          & { booking: (
            { __typename?: 'Booking' }
            & Pick<Booking, 'id' | 'ourReference'>
          ), customer?: Maybe<(
            { __typename?: 'Customer' }
            & Pick<Customer, 'name' | 'postcode' | 'customerReferenceLabel'>
          )> }
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetBookingExchangeRatesQueryVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type GetBookingExchangeRatesQuery = (
  { __typename?: 'Query' }
  & { bookingExchangeRates: Array<(
    { __typename?: 'BookingExchangeRate' }
    & Pick<BookingExchangeRate, 'id' | 'stage' | 'bookingId' | 'exchangeRateId' | 'createdAt'>
    & { exchangeRate: (
      { __typename?: 'ExchangeRate' }
      & ExchangeRateDetailsFragment
    ) }
  )> }
);

export type GetBookingDriverTotalChargeQueryVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type GetBookingDriverTotalChargeQuery = (
  { __typename?: 'Query' }
  & { booking?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'totalDriverCharge'>
  )> }
);

export type GetPagedQuoteLostReasonsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<QuoteLostReasonSortInput> | QuoteLostReasonSortInput>;
  where?: Maybe<QuoteLostReasonFilterInput>;
}>;


export type GetPagedQuoteLostReasonsQuery = (
  { __typename?: 'Query' }
  & { pagedQuoteLostReasons?: Maybe<(
    { __typename?: 'PagedQuoteLostReasonsConnection' }
    & Pick<PagedQuoteLostReasonsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedQuoteLostReasonsEdge' }
      & { node: (
        { __typename?: 'QuoteLostReason' }
        & Pick<QuoteLostReason, 'id' | 'name' | 'code' | 'createdAt' | 'createdBy'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetQuoteLostReasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuoteLostReasonsQuery = (
  { __typename?: 'Query' }
  & { quoteLostReasons: Array<(
    { __typename?: 'QuoteLostReason' }
    & Pick<QuoteLostReason, 'id' | 'name' | 'code'>
  )> }
);

export type GetQuoteLostReasonQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetQuoteLostReasonQuery = (
  { __typename?: 'Query' }
  & { quoteLostReason?: Maybe<(
    { __typename?: 'QuoteLostReason' }
    & Pick<QuoteLostReason, 'id' | 'name' | 'code'>
  )> }
);

export type GetBookingUsersQueryVariables = Exact<{
  where?: Maybe<BookingUserDtoFilterInput>;
}>;


export type GetBookingUsersQuery = (
  { __typename?: 'Query' }
  & { bookingUsers: Array<(
    { __typename?: 'BookingUserDto' }
    & Pick<BookingUserDto, 'id' | 'email' | 'userName' | 'lastName' | 'firstName'>
  )> }
);

export type GetBookingCreatorsQueryVariables = Exact<{
  where?: Maybe<BookingUserDtoFilterInput>;
}>;


export type GetBookingCreatorsQuery = (
  { __typename?: 'Query' }
  & { bookingCreators: Array<(
    { __typename?: 'BookingUserDto' }
    & Pick<BookingUserDto, 'id' | 'email' | 'userName' | 'lastName' | 'firstName'>
  )> }
);

export type GetPotentialBookingFilesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<PotentialBookingFileSortInput> | PotentialBookingFileSortInput>;
  where?: Maybe<PotentialBookingFileFilterInput>;
}>;


export type GetPotentialBookingFilesQuery = (
  { __typename?: 'Query' }
  & { potentialBookingFiles?: Maybe<(
    { __typename?: 'PotentialBookingFilesConnection' }
    & Pick<PotentialBookingFilesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PotentialBookingFilesEdge' }
      & { node: (
        { __typename?: 'PotentialBookingFile' }
        & Pick<PotentialBookingFile, 'id' | 'isProcessed' | 'createdAt' | 'createdBy'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'originalFileName'>
        ), uploadedByCustomer?: Maybe<(
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'name'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetPotentialBookingFileQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetPotentialBookingFileQuery = (
  { __typename?: 'Query' }
  & { potentialBookingFile?: Maybe<(
    { __typename?: 'PotentialBookingFile' }
    & Pick<PotentialBookingFile, 'id' | 'isProcessed' | 'createdAt' | 'createdBy' | 'extractedData'>
    & { file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'originalFileName'>
    ), uploadedByCustomer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'name'>
    )> }
  )> }
);

export type GetPotentialBookingMappingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPotentialBookingMappingsQuery = (
  { __typename?: 'Query' }
  & { potentialBookingMappings: Array<(
    { __typename?: 'PotentialBookingMapping' }
    & Pick<PotentialBookingMapping, 'id' | 'name'>
  )> }
);

export type GetPotentialBookingsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<PotentialBookingSortInput> | PotentialBookingSortInput>;
  where?: Maybe<PotentialBookingFilterInput>;
}>;


export type GetPotentialBookingsQuery = (
  { __typename?: 'Query' }
  & { potentialBookings?: Maybe<(
    { __typename?: 'PotentialBookingsConnection' }
    & Pick<PotentialBookingsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PotentialBookingsEdge' }
      & { node: (
        { __typename?: 'PotentialBooking' }
        & Pick<PotentialBooking, 'id' | 'bookingIdentifierKey' | 'bookingIdentifierValue' | 'customerIdentifierKey' | 'customerIdentifierValue' | 'isProcessed' | 'createdAt' | 'createdBy'>
        & { potentialBookingFile: (
          { __typename?: 'PotentialBookingFile' }
          & Pick<PotentialBookingFile, 'id'>
          & { file: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'name'>
          ) }
        ), potentialBookingMapping: (
          { __typename?: 'PotentialBookingMapping' }
          & Pick<PotentialBookingMapping, 'id' | 'name'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetPotentialBookingQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetPotentialBookingQuery = (
  { __typename?: 'Query' }
  & { potentialBooking?: Maybe<(
    { __typename?: 'PotentialBooking' }
    & Pick<PotentialBooking, 'id' | 'bookingIdentifierKey' | 'bookingIdentifierValue' | 'customerIdentifierKey' | 'customerIdentifierValue'>
    & { potentialBookingFile: (
      { __typename?: 'PotentialBookingFile' }
      & Pick<PotentialBookingFile, 'id' | 'extractedData'>
    ), potentialBookingMapping: (
      { __typename?: 'PotentialBookingMapping' }
      & Pick<PotentialBookingMapping, 'id' | 'type'>
    ) }
  )> }
);

export type BookingsTrackingDashboardQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingTrackingDashboardDtoSortInput> | BookingTrackingDashboardDtoSortInput>;
  where?: Maybe<BookingTrackingDashboardDtoFilterInput>;
}>;


export type BookingsTrackingDashboardQuery = (
  { __typename?: 'Query' }
  & { bookingTrackingDashboardData?: Maybe<(
    { __typename?: 'BookingTrackingDashboardDataConnection' }
    & Pick<BookingTrackingDashboardDataConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'BookingTrackingDashboardDataEdge' }
      & { node: (
        { __typename?: 'BookingTrackingDashboardDto' }
        & Pick<BookingTrackingDashboardDto, 'id' | 'ourReference' | 'status' | 'statusLabel' | 'customerName' | 'customerReference'>
        & { driverStatus: (
          { __typename?: 'DriverStatusDto' }
          & Pick<DriverStatusDto, 'id' | 'status' | 'statusLabel' | 'date' | 'postcode'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetCommentariesQueryVariables = Exact<{
  where?: Maybe<CommentaryFilterInput>;
  order?: Maybe<Array<CommentarySortInput> | CommentarySortInput>;
}>;


export type GetCommentariesQuery = (
  { __typename?: 'Query' }
  & { commentaries: Array<(
    { __typename?: 'Commentary' }
    & Pick<Commentary, 'id'>
    & CommentaryDetailsFragment
  )> }
);

export type GetCommentaryQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetCommentaryQuery = (
  { __typename?: 'Query' }
  & { commentary?: Maybe<(
    { __typename?: 'Commentary' }
    & Pick<Commentary, 'id'>
    & CommentaryDetailsFragment
  )> }
);

export type GetPagedCongestionZonesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<CongestionZoneSortInput> | CongestionZoneSortInput>;
  where?: Maybe<CongestionZoneFilterInput>;
}>;


export type GetPagedCongestionZonesQuery = (
  { __typename?: 'Query' }
  & { pagedCongestionZones?: Maybe<(
    { __typename?: 'PagedCongestionZonesConnection' }
    & Pick<PagedCongestionZonesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedCongestionZonesEdge' }
      & { node: (
        { __typename?: 'CongestionZone' }
        & Pick<CongestionZone, 'id' | 'name' | 'isAttachedToAllTariffs' | 'isAppliedAutomatically' | 'xeroServiceCode' | 'customerCharge'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetCongestionZoneQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetCongestionZoneQuery = (
  { __typename?: 'Query' }
  & { congestionZone?: Maybe<(
    { __typename?: 'CongestionZone' }
    & Pick<CongestionZone, 'id' | 'timeZoneId' | 'is24Hours'>
    & { timeZone: (
      { __typename?: 'TimeZone' }
      & Pick<TimeZone, 'id' | 'name' | 'description'>
    ), postcodes: Array<(
      { __typename?: 'CongestionZonePostcode' }
      & Pick<CongestionZonePostcode, 'id'>
      & CongestionZonePostcodeFragmentFragment
    )> }
    & CongestionZoneDetailsFragment
  )> }
);

export type GetTimeZonesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTimeZonesQuery = (
  { __typename?: 'Query' }
  & { timeZones: Array<(
    { __typename?: 'TimeZone' }
    & Pick<TimeZone, 'id' | 'description'>
  )> }
);

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = (
  { __typename?: 'Query' }
  & { countries: Array<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name' | 'formalName'>
  )> }
);

export type GetCourierExchangeQuotesQueryVariables = Exact<{
  loadId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<CourierExchangeQuoteSortInput> | CourierExchangeQuoteSortInput>;
  where?: Maybe<CourierExchangeQuoteFilterInput>;
}>;


export type GetCourierExchangeQuotesQuery = (
  { __typename?: 'Query' }
  & { courierExchangeQuotes?: Maybe<(
    { __typename?: 'CourierExchangeQuotesConnection' }
    & Pick<CourierExchangeQuotesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'CourierExchangeQuotesEdge' }
      & { node: (
        { __typename?: 'CourierExchangeQuote' }
        & Pick<CourierExchangeQuote, 'id' | 'loadId' | 'quoteId' | 'price'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetCourierExchangeQuoteQueryVariables = Exact<{
  courierExchangeQuoteId: Scalars['Long'];
}>;


export type GetCourierExchangeQuoteQuery = (
  { __typename?: 'Query' }
  & { courierExchangeQuote?: Maybe<(
    { __typename?: 'CourierExchangeQuote' }
    & Pick<CourierExchangeQuote, 'id' | 'quoteId' | 'loadId' | 'quoteOwnerCompanyId' | 'totalPrice'>
  )> }
);

export type GetPackagingTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPackagingTypesQuery = (
  { __typename?: 'Query' }
  & { packagingTypes: Array<(
    { __typename?: 'PackagingTypeDto' }
    & Pick<PackagingTypeDto, 'id' | 'description' | 'packagingType' | 'disabled'>
  )> }
);

export type GetLoadCurrentLocationQueryVariables = Exact<{
  loadId: Scalars['Long'];
}>;


export type GetLoadCurrentLocationQuery = (
  { __typename?: 'Query' }
  & { loadCurrentLocation?: Maybe<(
    { __typename?: 'DriverLocation' }
    & Pick<DriverLocation, 'driverId' | 'longitude' | 'latitude' | 'accuracy'>
  )> }
);

export type GetPagedCurrenciesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<CurrencySortInput> | CurrencySortInput>;
  where?: Maybe<CurrencyFilterInput>;
}>;


export type GetPagedCurrenciesQuery = (
  { __typename?: 'Query' }
  & { pagedCurrencies?: Maybe<(
    { __typename?: 'PagedCurrenciesConnection' }
    & Pick<PagedCurrenciesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedCurrenciesEdge' }
      & { node: (
        { __typename?: 'Currency' }
        & Pick<Currency, 'id' | 'name' | 'code' | 'isBaseCurrency'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetCurrenciesQueryVariables = Exact<{
  where?: Maybe<CurrencyFilterInput>;
}>;


export type GetCurrenciesQuery = (
  { __typename?: 'Query' }
  & { currencies: Array<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'name' | 'code' | 'isBaseCurrency'>
  )> }
);

export type GetPagedCustomersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<CustomerSortInput> | CustomerSortInput>;
  where?: Maybe<CustomerFilterInput>;
}>;


export type GetPagedCustomersQuery = (
  { __typename?: 'Query' }
  & { pagedCustomers?: Maybe<(
    { __typename?: 'PagedCustomersConnection' }
    & Pick<PagedCustomersConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedCustomersEdge' }
      & { node: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'accountCode' | 'name' | 'isOnHold' | 'addressLine1' | 'addressLine2' | 'city' | 'postcode' | 'telephoneNumber' | 'invoiceEmail' | 'acquiredBy' | 'customerAccountType' | 'isEnabled'>
        & { contacts: Array<(
          { __typename?: 'Contact' }
          & Pick<Contact, 'id' | 'name'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetCustomersQueryVariables = Exact<{
  order?: Maybe<Array<CustomerSortInput> | CustomerSortInput>;
}>;


export type GetCustomersQuery = (
  { __typename?: 'Query' }
  & { customers: Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name' | 'isReferenceRequired' | 'isReasonCodeOptionEnabled' | 'isPackageDetailsOptional' | 'isEnabled'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'email' | 'telephoneNumber' | 'isDefault' | 'isEnabled'>
    )> }
  )> }
);

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'CustomerDto' }
    & Pick<CustomerDto, 'id' | 'logoId'>
    & { customer: (
      { __typename?: 'Customer' }
      & CustomerDetailsFragment
    ), logo?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  ) }
);

export type GetCustomerContactQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetCustomerContactQuery = (
  { __typename?: 'Query' }
  & { customerContact: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'isDefault' | 'name' | 'telephoneNumber' | 'email' | 'additionalEmails' | 'customerId' | 'isEnabled' | 'isActive'>
  )> }
);

export type GetCustomerContactsQueryVariables = Exact<{
  customerId: Scalars['Long'];
  order?: Maybe<Array<ContactSortInput> | ContactSortInput>;
  where?: Maybe<ContactFilterInput>;
}>;


export type GetCustomerContactsQuery = (
  { __typename?: 'Query' }
  & { customerContacts: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'isDefault' | 'name' | 'email' | 'telephoneNumber' | 'isActive'>
  )> }
);

export type GetPagedCustomerContactsQueryVariables = Exact<{
  customerId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ContactSortInput> | ContactSortInput>;
  where?: Maybe<ContactFilterInput>;
}>;


export type GetPagedCustomerContactsQuery = (
  { __typename?: 'Query' }
  & { pagedCustomerContacts?: Maybe<(
    { __typename?: 'PagedCustomerContactsConnection' }
    & Pick<PagedCustomerContactsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedCustomerContactsEdge' }
      & { node: (
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'name' | 'telephoneNumber' | 'email' | 'additionalEmails' | 'isDefault' | 'isActive'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetCustomerNotificationsQueryVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type GetCustomerNotificationsQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'CustomerDto' }
    & Pick<CustomerDto, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id'>
      & CustomerNotificationsFragment
    ) }
  ) }
);

export type GetCustomerReferencesAndNotesQueryVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type GetCustomerReferencesAndNotesQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'CustomerDto' }
    & Pick<CustomerDto, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id'>
      & CustomerReferenceAndNotesFragment
    ) }
  ) }
);

export type GetSalesNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSalesNamesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'salesNames'>
);

export type GetCustomerProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerProfileQuery = (
  { __typename?: 'Query' }
  & { customerProfile: (
    { __typename?: 'CustomerProfileDto' }
    & Pick<CustomerProfileDto, 'id' | 'customerId' | 'contactId' | 'customerTradingName' | 'isReferenceRequired' | 'isReasonCodeOptionEnabled' | 'isBookingImportOptionEnabled' | 'isPackageDetailsOptional' | 'primaryCustomerId'>
  ) }
);

export type GetSubCustomersQueryVariables = Exact<{
  primaryCustomerId: Scalars['Long'];
  isPrimaryCustomerIncluded?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Array<CustomerSortInput> | CustomerSortInput>;
}>;


export type GetSubCustomersQuery = (
  { __typename?: 'Query' }
  & { subCustomers: Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'accountCode' | 'name' | 'isOnHold' | 'addressLine1' | 'addressLine2' | 'city' | 'postcode' | 'telephoneNumber' | 'invoiceEmail' | 'customerAccountType' | 'isEnabled' | 'isReferenceRequired' | 'isReasonCodeOptionEnabled' | 'isPackageDetailsOptional'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'name' | 'email' | 'telephoneNumber' | 'isDefault' | 'isEnabled'>
    )> }
  )> }
);

export type GetPagedSubCustomersQueryVariables = Exact<{
  primaryCustomerId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<CustomerSortInput> | CustomerSortInput>;
  where?: Maybe<CustomerFilterInput>;
}>;


export type GetPagedSubCustomersQuery = (
  { __typename?: 'Query' }
  & { pagedSubCustomers?: Maybe<(
    { __typename?: 'PagedSubCustomersConnection' }
    & Pick<PagedSubCustomersConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedSubCustomersEdge' }
      & { node: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'id' | 'accountCode' | 'name' | 'isOnHold' | 'addressLine1' | 'addressLine2' | 'city' | 'postcode' | 'telephoneNumber' | 'invoiceEmail' | 'customerAccountType' | 'isEnabled'>
        & { contacts: Array<(
          { __typename?: 'Contact' }
          & Pick<Contact, 'id' | 'name'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetCustomerInfoForSubCustomerQueryVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type GetCustomerInfoForSubCustomerQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'CustomerDto' }
    & Pick<CustomerDto, 'id'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'invoiceEmail' | 'paymentMethodId' | 'customerAccountType' | 'invoiceTermId' | 'invoiceFrequency' | 'invoiceCompanyName' | 'isInvoiceGrouped' | 'currencyId' | 'isReferenceRequired' | 'isUlezCharge'>
    ) }
  ) }
);

export type GetCustomersInstructionsForDriverQueryVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type GetCustomersInstructionsForDriverQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'customersInstructionsForDriver'>
);

export type GetCustomerOptionsQueryVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type GetCustomerOptionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'customerOptions'>
);

export type GetCurrentCustomerOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentCustomerOptionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'currentCustomerOptions'>
);

export type GetDriverBidsQueryVariables = Exact<{
  where: DriverBidFilterInput;
}>;


export type GetDriverBidsQuery = (
  { __typename?: 'Query' }
  & { driverBids: Array<(
    { __typename?: 'DriverBid' }
    & Pick<DriverBid, 'id' | 'price' | 'eta' | 'notes' | 'driverId' | 'bookingId' | 'vehicleId' | 'createdAt' | 'isAllocated'>
    & { currency: (
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'name' | 'symbol'>
    ), driver: (
      { __typename?: 'Driver' }
      & Pick<Driver, 'id' | 'name' | 'averageRating'>
    ), vehicle: (
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id'>
      & { vehicleType: (
        { __typename?: 'VehicleType' }
        & Pick<VehicleType, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type GetDriverBidsPagedQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DriverBidSortInput> | DriverBidSortInput>;
  where?: Maybe<DriverBidFilterInput>;
}>;


export type GetDriverBidsPagedQuery = (
  { __typename?: 'Query' }
  & { driverBidsPaged?: Maybe<(
    { __typename?: 'DriverBidsPagedConnection' }
    & Pick<DriverBidsPagedConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'DriverBidsPagedEdge' }
      & { node: (
        { __typename?: 'DriverBid' }
        & Pick<DriverBid, 'id' | 'price' | 'eta' | 'notes' | 'driverId' | 'bookingId' | 'vehicleId' | 'createdAt' | 'isAllocated'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'id' | 'name' | 'symbol'>
        ), driver: (
          { __typename?: 'Driver' }
          & Pick<Driver, 'name' | 'averageRating'>
        ), vehicle: (
          { __typename?: 'Vehicle' }
          & { vehicleType: (
            { __typename?: 'VehicleType' }
            & Pick<VehicleType, 'name'>
          ) }
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetDriverQueryVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type GetDriverQuery = (
  { __typename?: 'Query' }
  & { driver?: Maybe<(
    { __typename?: 'Driver' }
    & DriverDetailsFragment
  )> }
);

export type GetDriverOrderDetailsQueryVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type GetDriverOrderDetailsQuery = (
  { __typename?: 'Query' }
  & { driver?: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'xeroPurchaseCode' | 'vatCategoryId'>
  )> }
);

export type GetDriverCurrencyQueryVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type GetDriverCurrencyQuery = (
  { __typename?: 'Query' }
  & { driver?: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'currencyId'>
  )> }
);

export type GetDriverByCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDriverByCurrentUserQuery = (
  { __typename?: 'Query' }
  & { driverByCurrentUser?: Maybe<(
    { __typename?: 'Driver' }
    & DriverDetailsFragment
  )> }
);

export type GetDriversQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DriverSortInput> | DriverSortInput>;
  where?: Maybe<DriverFilterInput>;
}>;


export type GetDriversQuery = (
  { __typename?: 'Query' }
  & { drivers?: Maybe<(
    { __typename?: 'DriversConnection' }
    & Pick<DriversConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'DriversEdge' }
      & { node: (
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'name' | 'addressLine1' | 'addressLine2' | 'city' | 'postcode' | 'email' | 'isActive' | 'callSign' | 'minMiles' | 'distanceRate' | 'minCharge' | 'licenseType' | 'licenseExpiryDate' | 'courierExchangeMemberId' | 'telephoneNumber' | 'driverType' | 'xeroCodeId'>
        & { country: (
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        ), xeroCode?: Maybe<(
          { __typename?: 'XeroCode' }
          & Pick<XeroCode, 'id' | 'name'>
        )>, vehicles: Array<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'registrationNumber' | 'vehicleTypeId'>
          & { vehicleType: (
            { __typename?: 'VehicleType' }
            & Pick<VehicleType, 'name'>
          ) }
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetDriversXeroPurchaseCodesQueryVariables = Exact<{
  where?: Maybe<DriverFilterInput>;
}>;


export type GetDriversXeroPurchaseCodesQuery = (
  { __typename?: 'Query' }
  & { nonPaginatedDrivers: Array<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'xeroPurchaseCode'>
  )> }
);

export type GetPrimaryDriversQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DriverSortInput> | DriverSortInput>;
  where?: Maybe<DriverFilterInput>;
}>;


export type GetPrimaryDriversQuery = (
  { __typename?: 'Query' }
  & { primaryDrivers?: Maybe<(
    { __typename?: 'PrimaryDriversConnection' }
    & Pick<PrimaryDriversConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PrimaryDriversEdge' }
      & { node: (
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'name' | 'addressLine1' | 'addressLine2' | 'city' | 'postcode' | 'email' | 'isActive' | 'callSign' | 'minMiles' | 'distanceRate' | 'minCharge' | 'licenseType' | 'licenseExpiryDate' | 'currencyId' | 'identityUserName' | 'isUsingInternalApp' | 'averageRating' | 'vatCategoryId' | 'xeroPurchaseCode' | 'telephoneNumber' | 'driverType'>
        & { currency?: Maybe<(
          { __typename?: 'Currency' }
          & Pick<Currency, 'id' | 'code'>
        )>, country: (
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        ), xeroCode?: Maybe<(
          { __typename?: 'XeroCode' }
          & Pick<XeroCode, 'id' | 'name'>
        )>, vehicles: Array<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'registrationNumber' | 'vehicleTypeId'>
          & { vehicleType: (
            { __typename?: 'VehicleType' }
            & Pick<VehicleType, 'name'>
          ) }
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetSubDriversQueryVariables = Exact<{
  driverId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DriverSortInput> | DriverSortInput>;
  where?: Maybe<DriverFilterInput>;
}>;


export type GetSubDriversQuery = (
  { __typename?: 'Query' }
  & { subDrivers?: Maybe<(
    { __typename?: 'SubDriversConnection' }
    & Pick<SubDriversConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'SubDriversEdge' }
      & { node: (
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'name' | 'callSign' | 'email' | 'telephoneNumber' | 'identityUserName' | 'isUsingInternalApp' | 'isActive'>
        & { xeroCode?: Maybe<(
          { __typename?: 'XeroCode' }
          & Pick<XeroCode, 'id' | 'name'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetDriverContactsQueryVariables = Exact<{
  driverId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DriverContactSortInput> | DriverContactSortInput>;
  where?: Maybe<DriverContactFilterInput>;
}>;


export type GetDriverContactsQuery = (
  { __typename?: 'Query' }
  & { driverContacts?: Maybe<(
    { __typename?: 'DriverContactsConnection' }
    & Pick<DriverContactsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'DriverContactsEdge' }
      & { node: (
        { __typename?: 'DriverContact' }
        & Pick<DriverContact, 'id' | 'name' | 'department' | 'telephoneNumber' | 'email'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetDriverContactQueryVariables = Exact<{
  driverContactId: Scalars['Long'];
}>;


export type GetDriverContactQuery = (
  { __typename?: 'Query' }
  & { driverContact?: Maybe<(
    { __typename?: 'DriverContact' }
    & DriverContactDetailsFragment
  )> }
);

export type GetAllocatedDriversQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllocatedDriversQuery = (
  { __typename?: 'Query' }
  & { allocatedDrivers: Array<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id'>
    & { driver: (
      { __typename?: 'Driver' }
      & Pick<Driver, 'id'>
    ) }
  )> }
);

export type GetDriversPayDetailsQueryVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type GetDriversPayDetailsQuery = (
  { __typename?: 'Query' }
  & { driver?: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'nationalInsuranceNumber' | 'paymentMethodId' | 'bankName' | 'bankAccountNumber' | 'isGenerateSeparateInvoice' | 'vatIdNumber' | 'bankSortCode' | 'employeeReference' | 'isEmailInvoice' | 'chequeName' | 'payFrequency' | 'creditCard'>
  )> }
);

export type GetDriverOtherDetailsQueryVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type GetDriverOtherDetailsQuery = (
  { __typename?: 'Query' }
  & { driver?: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'licenseType' | 'licenseExpiryDate' | 'endorsements'>
  )> }
);

export type GetDriversLocationsQueryVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type GetDriversLocationsQuery = (
  { __typename?: 'Query' }
  & { driversLocations: Array<Maybe<(
    { __typename?: 'DriverLocation' }
    & Pick<DriverLocation, 'id' | 'driverId' | 'longitude' | 'latitude' | 'accuracy' | 'createdAt' | 'lastModifiedAt'>
  )>> }
);

export type GetDriversLocationsByTrackingNumberQueryVariables = Exact<{
  trackingGuid: Scalars['UUID'];
}>;


export type GetDriversLocationsByTrackingNumberQuery = (
  { __typename?: 'Query' }
  & { driversLocationsByTrackingNumber: Array<Maybe<(
    { __typename?: 'DriverLocation' }
    & Pick<DriverLocation, 'id' | 'driverId' | 'longitude' | 'latitude' | 'accuracy' | 'createdAt' | 'lastModifiedAt'>
  )>> }
);

export type GetPaymentTermsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentTermsQuery = (
  { __typename?: 'Query' }
  & { paymentTerms: Array<(
    { __typename?: 'PaymentTermDto' }
    & Pick<PaymentTermDto, 'id' | 'paymentTerm' | 'description'>
  )> }
);

export type GetDriverInvoicesQueryVariables = Exact<{
  driverId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingInvoiceSortInput> | BookingInvoiceSortInput>;
  where?: Maybe<BookingInvoiceFilterInput>;
}>;


export type GetDriverInvoicesQuery = (
  { __typename?: 'Query' }
  & { pagedDriverInvoices?: Maybe<(
    { __typename?: 'PagedDriverInvoicesConnection' }
    & Pick<PagedDriverInvoicesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedDriverInvoicesEdge' }
      & { node: (
        { __typename?: 'BookingInvoice' }
        & Pick<BookingInvoice, 'id' | 'invoiceNumber' | 'invoiceDate' | 'dueDate' | 'bookingId' | 'poAddressLine1' | 'driverInvoiceNumber'>
        & { booking: (
          { __typename?: 'Booking' }
          & Pick<Booking, 'id' | 'ourReference'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetDriverProfileQueryVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type GetDriverProfileQuery = (
  { __typename?: 'Query' }
  & { driver?: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'identityUserName'>
    & { userProfile?: Maybe<(
      { __typename?: 'DriverUserProfileDto' }
      & Pick<DriverUserProfileDto, 'id' | 'firstName' | 'lastName' | 'fullName'>
    )> }
  )> }
);

export type GetDriverProfileByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDriverProfileByUserQuery = (
  { __typename?: 'Query' }
  & { driverProfile: (
    { __typename?: 'DriverProfileDto' }
    & Pick<DriverProfileDto, 'id'>
  ) }
);

export type CurrentDriversLocationsGridQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DriverLocationSortInput> | DriverLocationSortInput>;
  where?: Maybe<DriverLocationFilterInput>;
}>;


export type CurrentDriversLocationsGridQuery = (
  { __typename?: 'Query' }
  & { currentDriversLocations?: Maybe<(
    { __typename?: 'CurrentDriversLocationsConnection' }
    & Pick<CurrentDriversLocationsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'CurrentDriversLocationsEdge' }
      & { node: (
        { __typename?: 'DriverLocation' }
        & Pick<DriverLocation, 'id' | 'driverId' | 'latitude' | 'longitude' | 'lastModifiedAt' | 'createdAt'>
        & { driverTrackingStatus: (
          { __typename?: 'DriverTrackingStatusDto' }
          & Pick<DriverTrackingStatusDto, 'allocated' | 'nextStopPostCode' | 'nextStopDateTime' | 'nextStopLiveEta'>
        ), driver?: Maybe<(
          { __typename?: 'Driver' }
          & Pick<Driver, 'id' | 'name'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetDriverNextRouteQueryVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type GetDriverNextRouteQuery = (
  { __typename?: 'Query' }
  & { driverNextRoute: (
    { __typename?: 'DriverTrackingDto' }
    & Pick<DriverTrackingDto, 'id' | 'longitude' | 'latitude' | 'nextStopGoogleMapsPlaceId'>
  ) }
);

export type GetBookingDriverRatingQueryVariables = Exact<{
  bookingId: Scalars['Long'];
  driverId: Scalars['Long'];
}>;


export type GetBookingDriverRatingQuery = (
  { __typename?: 'Query' }
  & { bookingDriverRating: Array<(
    { __typename?: 'BookingDriver' }
    & Pick<BookingDriver, 'id' | 'bookingId' | 'rating' | 'ratingComment'>
    & { driver: (
      { __typename?: 'Driver' }
      & Pick<Driver, 'id' | 'name'>
    ) }
  )> }
);

export type GetDriverRatingQueryVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type GetDriverRatingQuery = (
  { __typename?: 'Query' }
  & { driver?: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'averageRating'>
  )> }
);

export type GetDriverAllocationsQueryVariables = Exact<{
  driverId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BookingDriverSortInput> | BookingDriverSortInput>;
  where?: Maybe<BookingDriverFilterInput>;
}>;


export type GetDriverAllocationsQuery = (
  { __typename?: 'Query' }
  & { pagedBookingDriverAllocations?: Maybe<(
    { __typename?: 'PagedBookingDriverAllocationsConnection' }
    & Pick<PagedBookingDriverAllocationsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedBookingDriverAllocationsEdge' }
      & { node: (
        { __typename?: 'BookingDriver' }
        & Pick<BookingDriver, 'id' | 'bookingId' | 'rating' | 'ratingComment' | 'createdBy' | 'lastModifiedBy'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetMissingDriverPaymentDataFieldsQueryVariables = Exact<{
  driverId: Scalars['Long'];
}>;


export type GetMissingDriverPaymentDataFieldsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'missingDriverPaymentDataFields'>
);

export type GetEmailTemplatesQueryVariables = Exact<{
  order?: Maybe<Array<EmailTemplateSortInput> | EmailTemplateSortInput>;
  where?: Maybe<EmailTemplateFilterInput>;
}>;


export type GetEmailTemplatesQuery = (
  { __typename?: 'Query' }
  & { emailTemplates: Array<(
    { __typename?: 'EmailTemplate' }
    & EmailTemplateDetailsFragment
  )> }
);

export type GetEmailTemplateTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailTemplateTokensQuery = (
  { __typename?: 'Query' }
  & { emailTemplateTokens: Array<(
    { __typename?: 'EmailTemplateTokenDto' }
    & Pick<EmailTemplateTokenDto, 'value' | 'token' | 'description' | 'numericValue'>
  )> }
);

export type GetSentEmailsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<SentEmailSortInput> | SentEmailSortInput>;
  where?: Maybe<SentEmailFilterInput>;
}>;


export type GetSentEmailsQuery = (
  { __typename?: 'Query' }
  & { sentEmails?: Maybe<(
    { __typename?: 'SentEmailsConnection' }
    & Pick<SentEmailsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'SentEmailsEdge' }
      & { node: (
        { __typename?: 'SentEmail' }
        & Pick<SentEmail, 'id' | 'createdAt' | 'createdBy' | 'to' | 'subject' | 'hasAttachments' | 'isSuccessful' | 'hasErrors'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetSentEmailQueryVariables = Exact<{
  sentEmailId: Scalars['Long'];
}>;


export type GetSentEmailQuery = (
  { __typename?: 'Query' }
  & { sentEmail?: Maybe<(
    { __typename?: 'SentEmail' }
    & Pick<SentEmail, 'id' | 'createdAt' | 'to' | 'cc' | 'bcc' | 'subject' | 'body' | 'hasAttachments' | 'isSuccessful' | 'hasErrors' | 'errorMessages' | 'sendGridMessageId'>
  )> }
);

export type GetExchangeRatesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ExchangeRateSortInput> | ExchangeRateSortInput>;
  where?: Maybe<ExchangeRateFilterInput>;
}>;


export type GetExchangeRatesQuery = (
  { __typename?: 'Query' }
  & { exchangeRates?: Maybe<(
    { __typename?: 'ExchangeRatesConnection' }
    & Pick<ExchangeRatesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'ExchangeRatesEdge' }
      & { node: (
        { __typename?: 'ExchangeRate' }
        & Pick<ExchangeRate, 'id' | 'sourceCurrencyId' | 'targetCurrencyId' | 'rate' | 'createdAt'>
        & { sourceCurrency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'id' | 'name' | 'code'>
        ), targetCurrency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'id' | 'name' | 'code'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetPagedFilesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FileSortInput> | FileSortInput>;
  where?: Maybe<FileFilterInput>;
}>;


export type GetPagedFilesQuery = (
  { __typename?: 'Query' }
  & { files?: Maybe<(
    { __typename?: 'FilesConnection' }
    & Pick<FilesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'FilesEdge' }
      & { node: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'originalFileName' | 'category' | 'createdAt' | 'createdBy'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetInvoiceTermsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInvoiceTermsQuery = (
  { __typename?: 'Query' }
  & { invoiceTerms: Array<(
    { __typename?: 'InvoiceTerm' }
    & Pick<InvoiceTerm, 'id' | 'name' | 'days'>
  )> }
);

export type GetNotificationsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<NotificationFilterInput>;
  order?: Maybe<Array<NotificationSortInput> | NotificationSortInput>;
}>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<(
    { __typename?: 'NotificationsConnection' }
    & Pick<NotificationsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'NotificationsEdge' }
      & { node: (
        { __typename?: 'Notification' }
        & Pick<Notification, 'id' | 'name' | 'description' | 'username' | 'isRead' | 'createdAt' | 'createdBy' | 'taskId' | 'priority' | 'date' | 'type' | 'deliveryStatus' | 'deliveryStatusLog'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type AllNotificationHubRegistrationInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type AllNotificationHubRegistrationInfoQuery = (
  { __typename?: 'Query' }
  & { allNotificationHubRegistrationInfo: Array<(
    { __typename?: 'DeviceTrend' }
    & Pick<DeviceTrend, 'deviceType' | 'registrationCount'>
  )> }
);

export type AllNotificationHubRegistrationQueryVariables = Exact<{ [key: string]: never; }>;


export type AllNotificationHubRegistrationQuery = (
  { __typename?: 'Query' }
  & { allNotificationHubRegistration: Array<(
    { __typename?: 'DeviceRegistration' }
    & Pick<DeviceRegistration, 'registrationId' | 'tags'>
  )> }
);

export type GetOtherChargePagedQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<OtherChargeSortInput> | OtherChargeSortInput>;
  where?: Maybe<OtherChargeFilterInput>;
}>;


export type GetOtherChargePagedQuery = (
  { __typename?: 'Query' }
  & { pagedCharges?: Maybe<(
    { __typename?: 'PagedChargesConnection' }
    & Pick<PagedChargesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedChargesEdge' }
      & { node: (
        { __typename?: 'OtherCharge' }
        & Pick<OtherCharge, 'id' | 'name' | 'description' | 'rate' | 'xeroServiceCode' | 'vatCategoryId'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetOtherChargeQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetOtherChargeQuery = (
  { __typename?: 'Query' }
  & { otherCharge?: Maybe<(
    { __typename?: 'OtherCharge' }
    & Pick<OtherCharge, 'id'>
    & OtherChargeDetailsFragment
  )> }
);

export type GetOtherChargesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOtherChargesQuery = (
  { __typename?: 'Query' }
  & { otherCharges: Array<(
    { __typename?: 'OtherCharge' }
    & Pick<OtherCharge, 'id'>
    & OtherChargeDetailsFragment
  )> }
);

export type OtherChargeNameIsUniqueQueryVariables = Exact<{
  name: Scalars['String'];
  id?: Maybe<Scalars['Long']>;
}>;


export type OtherChargeNameIsUniqueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'otherChargeNameIsUnique'>
);

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentMethodsQuery = (
  { __typename?: 'Query' }
  & { paymentMethods: Array<(
    { __typename?: 'PaymentMethod' }
    & Pick<PaymentMethod, 'id' | 'name'>
  )> }
);

export type GetPaymentRecordQueryVariables = Exact<{
  bookingInvoiceId: Scalars['Long'];
}>;


export type GetPaymentRecordQuery = (
  { __typename?: 'Query' }
  & { paymentRecord?: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id' | 'bookingInvoiceId' | 'transactionId'>
  )> }
);

export type GetPermissionsQueryVariables = Exact<{
  role: Scalars['String'];
}>;


export type GetPermissionsQuery = (
  { __typename?: 'Query' }
  & { policyRoles: Array<(
    { __typename?: 'PolicyRole' }
    & Pick<PolicyRole, 'id' | 'roleId' | 'policyId'>
    & { role: (
      { __typename?: 'ApplicationRole' }
      & Pick<ApplicationRole, 'id' | 'name' | 'description'>
    ), policy: (
      { __typename?: 'Policy' }
      & Pick<Policy, 'id' | 'name' | 'description'>
    ) }
  )> }
);

export type GetPagedReasonCodesQueryVariables = Exact<{
  customerId?: Maybe<Scalars['Long']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReasonCodeFilterInput>;
  order?: Maybe<Array<ReasonCodeSortInput> | ReasonCodeSortInput>;
}>;


export type GetPagedReasonCodesQuery = (
  { __typename?: 'Query' }
  & { pagedReasonCodes?: Maybe<(
    { __typename?: 'PagedReasonCodesConnection' }
    & Pick<PagedReasonCodesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedReasonCodesEdge' }
      & { node: (
        { __typename?: 'ReasonCode' }
        & Pick<ReasonCode, 'id' | 'name' | 'code' | 'createdAt' | 'createdBy'>
        & { customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'id' | 'name'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetUserPagedReasonCodesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ReasonCodeFilterInput>;
  order?: Maybe<Array<ReasonCodeSortInput> | ReasonCodeSortInput>;
}>;


export type GetUserPagedReasonCodesQuery = (
  { __typename?: 'Query' }
  & { userPagedReasonCodes?: Maybe<(
    { __typename?: 'UserPagedReasonCodesConnection' }
    & Pick<UserPagedReasonCodesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'UserPagedReasonCodesEdge' }
      & { node: (
        { __typename?: 'ReasonCode' }
        & Pick<ReasonCode, 'id' | 'name' | 'code' | 'description' | 'createdAt'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetReasonCodeQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetReasonCodeQuery = (
  { __typename?: 'Query' }
  & { reasonCode?: Maybe<(
    { __typename?: 'ReasonCode' }
    & Pick<ReasonCode, 'id'>
    & ReasonCodeDetailsFragment
  )> }
);

export type GetReasonCodesQueryVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type GetReasonCodesQuery = (
  { __typename?: 'Query' }
  & { reasonCodes: Array<(
    { __typename?: 'ReasonCode' }
    & Pick<ReasonCode, 'id'>
    & ReasonCodeDetailsFragment
  )> }
);

export type GetUserReasonCodesQueryVariables = Exact<{
  isIncludeSubCustomers: Scalars['Boolean'];
}>;


export type GetUserReasonCodesQuery = (
  { __typename?: 'Query' }
  & { userReasonCodes: Array<(
    { __typename?: 'ReasonCode' }
    & Pick<ReasonCode, 'id'>
    & ReasonCodeDetailsFragment
  )> }
);

export type GetCustomerIncomeReportQueryVariables = Exact<{
  input: CustomerIncomeReportInputDtoInput;
}>;


export type GetCustomerIncomeReportQuery = (
  { __typename?: 'Query' }
  & { customerIncomeReport: (
    { __typename?: 'GenericReportOutputDto' }
    & ReportRowsFragment
  ) }
);

export type GetMyIncomeReportQueryVariables = Exact<{
  input: MyIncomeReportInputDtoInput;
}>;


export type GetMyIncomeReportQuery = (
  { __typename?: 'Query' }
  & { myIncomeReport: (
    { __typename?: 'GenericReportOutputDto' }
    & ReportRowsFragment
  ) }
);

export type GetCustomerContactListReportQueryVariables = Exact<{
  input: CustomerContactListReportInputDtoInput;
}>;


export type GetCustomerContactListReportQuery = (
  { __typename?: 'Query' }
  & { customerContactListReport: (
    { __typename?: 'GenericReportOutputDto' }
    & ReportRowsFragment
  ) }
);

export type GetDriverListReportQueryVariables = Exact<{
  input: DriverListReportInputDtoInput;
}>;


export type GetDriverListReportQuery = (
  { __typename?: 'Query' }
  & { driverListReport: (
    { __typename?: 'GenericReportOutputDto' }
    & ReportRowsFragment
  ) }
);

export type GetSalesCommissionReportQueryVariables = Exact<{
  input: SalesCommissionReportInputDtoInput;
}>;


export type GetSalesCommissionReportQuery = (
  { __typename?: 'Query' }
  & { salesCommissionReport: (
    { __typename?: 'GenericReportOutputDto' }
    & ReportRowsFragment
  ) }
);

export type GetProofOfDeliveryReportQueryVariables = Exact<{
  input: ProofOfDeliveryReportInputDtoInput;
}>;


export type GetProofOfDeliveryReportQuery = (
  { __typename?: 'Query' }
  & { proofOfDeliveryReport: (
    { __typename?: 'GenericReportOutputDto' }
    & ReportRowsFragment
  ) }
);

export type GetPagedExternalDriverInvoicesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ExternalDriverInvoiceSortInput> | ExternalDriverInvoiceSortInput>;
  where?: Maybe<ExternalDriverInvoiceFilterInput>;
}>;


export type GetPagedExternalDriverInvoicesQuery = (
  { __typename?: 'Query' }
  & { pagedExternalDriverInvoices?: Maybe<(
    { __typename?: 'PagedExternalDriverInvoicesConnection' }
    & Pick<PagedExternalDriverInvoicesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedExternalDriverInvoicesEdge' }
      & { node: (
        { __typename?: 'ExternalDriverInvoice' }
        & Pick<ExternalDriverInvoice, 'id' | 'reference' | 'description' | 'quantity' | 'bookingInvoiceId' | 'invoiceNumber' | 'fileId' | 'createdAt' | 'createdBy'>
        & { bookingInvoice?: Maybe<(
          { __typename?: 'BookingInvoice' }
          & Pick<BookingInvoice, 'id' | 'bookingId'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetScheduledBookingTemplateByBookingIdQueryVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type GetScheduledBookingTemplateByBookingIdQuery = (
  { __typename?: 'Query' }
  & { scheduledBookingTemplateByBookingId?: Maybe<(
    { __typename?: 'ScheduledBookingTemplate' }
    & Pick<ScheduledBookingTemplate, 'id'>
    & TemplateDetailsFragment
  )> }
);

export type GetScheduledBookingDatesQueryVariables = Exact<{
  templateId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ScheduledBookingDateSortInput> | ScheduledBookingDateSortInput>;
}>;


export type GetScheduledBookingDatesQuery = (
  { __typename?: 'Query' }
  & { scheduledBookingDates?: Maybe<(
    { __typename?: 'ScheduledBookingDatesConnection' }
    & Pick<ScheduledBookingDatesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'ScheduledBookingDatesEdge' }
      & { node: (
        { __typename?: 'ScheduledBookingDate' }
        & Pick<ScheduledBookingDate, 'id' | 'templateId' | 'executeOnDate' | 'taskId'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetScheduledBookingTasksQueryVariables = Exact<{
  templateId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ScheduledBookingTaskSortInput> | ScheduledBookingTaskSortInput>;
  where?: Maybe<ScheduledBookingTaskFilterInput>;
}>;


export type GetScheduledBookingTasksQuery = (
  { __typename?: 'Query' }
  & { scheduledBookingTasks?: Maybe<(
    { __typename?: 'ScheduledBookingTasksConnection' }
    & Pick<ScheduledBookingTasksConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'ScheduledBookingTasksEdge' }
      & { node: (
        { __typename?: 'ScheduledBookingTask' }
        & Pick<ScheduledBookingTask, 'id' | 'templateId' | 'dueByDateTime' | 'createdAt' | 'lastModifiedAt' | 'status' | 'bookingId' | 'errorMessage'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetScheduledExchangeRateTemplatesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ScheduledExchangeRateTemplateSortInput> | ScheduledExchangeRateTemplateSortInput>;
  where?: Maybe<ScheduledExchangeRateTemplateFilterInput>;
}>;


export type GetScheduledExchangeRateTemplatesQuery = (
  { __typename?: 'Query' }
  & { scheduledExchangeRateTemplates?: Maybe<(
    { __typename?: 'ScheduledExchangeRateTemplatesConnection' }
    & Pick<ScheduledExchangeRateTemplatesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'ScheduledExchangeRateTemplatesEdge' }
      & { node: (
        { __typename?: 'ScheduledExchangeRateTemplate' }
        & Pick<ScheduledExchangeRateTemplate, 'id' | 'currencyId' | 'date' | 'createdAt' | 'createdBy'>
        & { currency: (
          { __typename?: 'Currency' }
          & Pick<Currency, 'id' | 'code'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetScheduledExchangeRateTasksQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ScheduledExchangeRateTaskSortInput> | ScheduledExchangeRateTaskSortInput>;
  where?: Maybe<ScheduledExchangeRateTaskFilterInput>;
}>;


export type GetScheduledExchangeRateTasksQuery = (
  { __typename?: 'Query' }
  & { scheduledExchangeRateTasks?: Maybe<(
    { __typename?: 'ScheduledExchangeRateTasksConnection' }
    & Pick<ScheduledExchangeRateTasksConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'ScheduledExchangeRateTasksEdge' }
      & { node: (
        { __typename?: 'ScheduledExchangeRateTask' }
        & Pick<ScheduledExchangeRateTask, 'id' | 'status' | 'templateId' | 'dueByDateTime' | 'createdBy' | 'createdAt'>
        & { template: (
          { __typename?: 'ScheduledExchangeRateTemplate' }
          & Pick<ScheduledExchangeRateTemplate, 'id' | 'currencyId'>
          & { currency: (
            { __typename?: 'Currency' }
            & Pick<Currency, 'id' | 'code'>
          ) }
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetSearchResultsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type GetSearchResultsQuery = (
  { __typename?: 'Query' }
  & { searchResults: Array<(
    { __typename?: 'SearchResultDto' }
    & Pick<SearchResultDto, 'entityId' | 'entityName' | 'description'>
  )> }
);

export type GetExternalSearchResultsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type GetExternalSearchResultsQuery = (
  { __typename?: 'Query' }
  & { externalSearchResults: Array<(
    { __typename?: 'SearchResultDto' }
    & Pick<SearchResultDto, 'entityId' | 'entityName' | 'description'>
  )> }
);

export type GetStandardOperatingProceduresQueryVariables = Exact<{
  customerId?: Maybe<Scalars['Long']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<StandardOperatingProcedureFilterInput>;
  order?: Maybe<Array<StandardOperatingProcedureSortInput> | StandardOperatingProcedureSortInput>;
}>;


export type GetStandardOperatingProceduresQuery = (
  { __typename?: 'Query' }
  & { standardOperatingProcedures?: Maybe<(
    { __typename?: 'StandardOperatingProceduresConnection' }
    & Pick<StandardOperatingProceduresConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'StandardOperatingProceduresEdge' }
      & { node: (
        { __typename?: 'StandardOperatingProcedure' }
        & Pick<StandardOperatingProcedure, 'id' | 'name' | 'isActive' | 'createdBy' | 'lastModifiedAt'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetStandardOperatingProcedureQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetStandardOperatingProcedureQuery = (
  { __typename?: 'Query' }
  & { standardOperatingProcedure?: Maybe<(
    { __typename?: 'StandardOperatingProcedure' }
    & Pick<StandardOperatingProcedure, 'id'>
    & StandardOperatingProcedureDetailsFragment
  )> }
);

export type GetStandardOperatingProcedureHistoryQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<StandardOperatingProcedureHistoryDtoSortInput> | StandardOperatingProcedureHistoryDtoSortInput>;
  where?: Maybe<StandardOperatingProcedureHistoryDtoFilterInput>;
}>;


export type GetStandardOperatingProcedureHistoryQuery = (
  { __typename?: 'Query' }
  & { standardOperatingProcedureHistory?: Maybe<(
    { __typename?: 'StandardOperatingProcedureHistoryConnection' }
    & Pick<StandardOperatingProcedureHistoryConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'StandardOperatingProcedureHistoryEdge' }
      & { node: (
        { __typename?: 'StandardOperatingProcedureHistoryDto' }
        & Pick<StandardOperatingProcedureHistoryDto, 'id' | 'sopId' | 'name' | 'isActive' | 'description' | 'lastModifiedAt' | 'lastModifiedBy' | 'isAttachedToAllCustomers'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetStrategicPartnersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<StrategicPartnerSortInput> | StrategicPartnerSortInput>;
  where?: Maybe<StrategicPartnerFilterInput>;
}>;


export type GetStrategicPartnersQuery = (
  { __typename?: 'Query' }
  & { strategicPartners?: Maybe<(
    { __typename?: 'StrategicPartnersConnection' }
    & Pick<StrategicPartnersConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'StrategicPartnersEdge' }
      & { node: (
        { __typename?: 'StrategicPartner' }
        & Pick<StrategicPartner, 'id' | 'isEnabled' | 'name' | 'addressLine1' | 'addressLine2' | 'city' | 'postcode' | 'email' | 'telephoneNumber'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetStrategicPartnerQueryVariables = Exact<{
  strategicPartnerId: Scalars['Long'];
}>;


export type GetStrategicPartnerQuery = (
  { __typename?: 'Query' }
  & { strategicPartner?: Maybe<(
    { __typename?: 'StrategicPartner' }
    & StrategicPartnerDetailsFragment
  )> }
);

export type GetStrategicPartnerContactsQueryVariables = Exact<{
  strategicPartnerId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<StrategicPartnerContactSortInput> | StrategicPartnerContactSortInput>;
  where?: Maybe<StrategicPartnerContactFilterInput>;
}>;


export type GetStrategicPartnerContactsQuery = (
  { __typename?: 'Query' }
  & { strategicPartnerContacts?: Maybe<(
    { __typename?: 'StrategicPartnerContactsConnection' }
    & Pick<StrategicPartnerContactsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'StrategicPartnerContactsEdge' }
      & { node: (
        { __typename?: 'StrategicPartnerContact' }
        & Pick<StrategicPartnerContact, 'id' | 'name' | 'department' | 'telephoneNumber' | 'email'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetStrategicPartnerContactQueryVariables = Exact<{
  strategicPartnerContactId: Scalars['Long'];
}>;


export type GetStrategicPartnerContactQuery = (
  { __typename?: 'Query' }
  & { strategicPartnerContact?: Maybe<(
    { __typename?: 'StrategicPartnerContact' }
    & StrategicPartnerContactDetailsFragment
  )> }
);

export type GetPagedTariffsQueryVariables = Exact<{
  customerId?: Maybe<Scalars['Long']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<TariffPagedDtoSortInput> | TariffPagedDtoSortInput>;
  where?: Maybe<TariffPagedDtoFilterInput>;
}>;


export type GetPagedTariffsQuery = (
  { __typename?: 'Query' }
  & { pagedTariffs?: Maybe<(
    { __typename?: 'PagedTariffsConnection' }
    & Pick<PagedTariffsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedTariffsEdge' }
      & { node: (
        { __typename?: 'TariffPagedDto' }
        & Pick<TariffPagedDto, 'id' | 'name' | 'type' | 'category' | 'baseUnit' | 'minMiles' | 'rate' | 'minCharge' | 'customer' | 'isExternal'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetUserPagedTariffsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<TariffPagedDtoSortInput> | TariffPagedDtoSortInput>;
  where?: Maybe<TariffPagedDtoFilterInput>;
}>;


export type GetUserPagedTariffsQuery = (
  { __typename?: 'Query' }
  & { userPagedTariffs?: Maybe<(
    { __typename?: 'UserPagedTariffsConnection' }
    & Pick<UserPagedTariffsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'UserPagedTariffsEdge' }
      & { node: (
        { __typename?: 'TariffPagedDto' }
        & Pick<TariffPagedDto, 'id' | 'name' | 'category' | 'type' | 'visibilitySequenceNumber'>
        & { tariffIcon?: Maybe<(
          { __typename?: 'TariffIcon' }
          & Pick<TariffIcon, 'id' | 'name' | 'vehicleCapacity'>
          & { file?: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'absoluteUri'>
          )> }
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetTariffsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTariffsQuery = (
  { __typename?: 'Query' }
  & { tariffs: Array<(
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'id' | 'name' | 'isEnabled'>
  )> }
);

export type GetTariffsForCustomerQueryVariables = Exact<{
  customerId: Scalars['Long'];
}>;


export type GetTariffsForCustomerQuery = (
  { __typename?: 'Query' }
  & { tariffsForCustomer: Array<(
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'id'>
    & { tariffCategory: (
      { __typename?: 'TariffCategory' }
      & Pick<TariffCategory, 'id' | 'courierExchangeVehicleSize'>
    ) }
  )> }
);

export type GetTariffQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetTariffQuery = (
  { __typename?: 'Query' }
  & { tariffById?: Maybe<(
    { __typename?: 'Tariff' }
    & { xeroCode?: Maybe<(
      { __typename?: 'XeroCode' }
      & Pick<XeroCode, 'id' | 'serviceType'>
    )> }
    & TariffDetailsFragment
  )> }
);

export type GetBookingTariffQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetBookingTariffQuery = (
  { __typename?: 'Query' }
  & { tariffById?: Maybe<(
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'id' | 'name' | 'type' | 'isWaitAndReturnEnabled' | 'minCharge' | 'minMiles' | 'rate' | 'baseUnit' | 'iconUrl' | 'isExternal' | 'isDropChargeEnabled' | 'isUseDefaultDropCharge' | 'dropChargeRate'>
    & { tariffCategory: (
      { __typename?: 'TariffCategory' }
      & Pick<TariffCategory, 'id' | 'name'>
    ), xeroCode?: Maybe<(
      { __typename?: 'XeroCode' }
      & Pick<XeroCode, 'id' | 'serviceType'>
    )> }
  )> }
);

export type GetUserTariffDetailsQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetUserTariffDetailsQuery = (
  { __typename?: 'Query' }
  & { tariffById?: Maybe<(
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'id' | 'name' | 'type' | 'isWaitAndReturnEnabled' | 'isExternal'>
    & { tariffCategory: (
      { __typename?: 'TariffCategory' }
      & Pick<TariffCategory, 'id' | 'name'>
    ), tariffIcon?: Maybe<(
      { __typename?: 'TariffIcon' }
      & Pick<TariffIcon, 'id' | 'vehicleCapacity'>
      & { file?: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'name' | 'absoluteUri'>
      )> }
    )>, xeroCode?: Maybe<(
      { __typename?: 'XeroCode' }
      & Pick<XeroCode, 'id' | 'serviceType'>
    )> }
  )> }
);

export type GetPagedTariffCategoriesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<TariffCategorySortInput> | TariffCategorySortInput>;
  where?: Maybe<TariffCategoryFilterInput>;
}>;


export type GetPagedTariffCategoriesQuery = (
  { __typename?: 'Query' }
  & { pagedTariffCategories?: Maybe<(
    { __typename?: 'PagedTariffCategoriesConnection' }
    & Pick<PagedTariffCategoriesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedTariffCategoriesEdge' }
      & { node: (
        { __typename?: 'TariffCategory' }
        & Pick<TariffCategory, 'id' | 'name' | 'courierExchangeVehicleSize' | 'createdAt' | 'createdBy'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetTariffCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTariffCategoriesQuery = (
  { __typename?: 'Query' }
  & { tariffCategories: Array<(
    { __typename?: 'TariffCategory' }
    & Pick<TariffCategory, 'id' | 'name' | 'createdAt' | 'createdBy'>
  )> }
);

export type GetTariffCategoryQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetTariffCategoryQuery = (
  { __typename?: 'Query' }
  & { tariffCategory?: Maybe<(
    { __typename?: 'TariffCategory' }
    & Pick<TariffCategory, 'id' | 'name' | 'courierExchangeVehicleSize' | 'hasSpotRate'>
  )> }
);

export type GetTariffCategoryNameQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetTariffCategoryNameQuery = (
  { __typename?: 'Query' }
  & { tariffCategory?: Maybe<(
    { __typename?: 'TariffCategory' }
    & Pick<TariffCategory, 'id' | 'name'>
  )> }
);

export type GetExternalTariffsQueryVariables = Exact<{
  where?: Maybe<TariffPagedDtoFilterInput>;
  order?: Maybe<Array<TariffPagedDtoSortInput> | TariffPagedDtoSortInput>;
}>;


export type GetExternalTariffsQuery = (
  { __typename?: 'Query' }
  & { pagedTariffs?: Maybe<(
    { __typename?: 'PagedTariffsConnection' }
    & Pick<PagedTariffsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedTariffsEdge' }
      & { node: (
        { __typename?: 'TariffPagedDto' }
        & Pick<TariffPagedDto, 'id' | 'visibilitySequenceNumber'>
      ) }
    )>> }
  )> }
);

export type GetPagedTariffIconsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<TariffIconFilterInput>;
  order?: Maybe<Array<TariffIconSortInput> | TariffIconSortInput>;
}>;


export type GetPagedTariffIconsQuery = (
  { __typename?: 'Query' }
  & { pagedTariffIcons?: Maybe<(
    { __typename?: 'PagedTariffIconsConnection' }
    & Pick<PagedTariffIconsConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedTariffIconsEdge' }
      & { node: (
        { __typename?: 'TariffIcon' }
        & Pick<TariffIcon, 'id' | 'name' | 'vehicleCapacity' | 'createdAt' | 'createdBy'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetTariffIconsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTariffIconsQuery = (
  { __typename?: 'Query' }
  & { tariffIcons: Array<(
    { __typename?: 'TariffIcon' }
    & Pick<TariffIcon, 'id' | 'name' | 'vehicleCapacity'>
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  )> }
);

export type GetTariffIconQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetTariffIconQuery = (
  { __typename?: 'Query' }
  & { tariffIcon?: Maybe<(
    { __typename?: 'TariffIcon' }
    & Pick<TariffIcon, 'id' | 'name' | 'vehicleCapacity' | 'fileId'>
    & { file?: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id'>
      & FileDetailsFragment
    )> }
  )> }
);

export type GetTasksQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<TaskSortInput> | TaskSortInput>;
  where?: Maybe<TaskFilterInput>;
}>;


export type GetTasksQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'TasksConnection' }
    & Pick<TasksConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'TasksEdge' }
      & { node: (
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'bookingId' | 'description' | 'status' | 'endDate' | 'time' | 'timeTotalSeconds' | 'categoryId' | 'assignedUser' | 'assignedUserName' | 'additionalDetail'>
        & { booking?: Maybe<(
          { __typename?: 'Booking' }
          & Pick<Booking, 'id' | 'ourReference' | 'status'>
        )>, category?: Maybe<(
          { __typename?: 'TaskCategory' }
          & Pick<TaskCategory, 'id' | 'name' | 'colour'>
        )> }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetTaskQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetTaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & TaskDetailsFragment
  )> }
);

export type GetTasksCountQueryVariables = Exact<{
  where?: Maybe<TaskFilterInput>;
}>;


export type GetTasksCountQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'TasksConnection' }
    & Pick<TasksConnection, 'totalCount'>
  )> }
);

export type GetTaskStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaskStatusesQuery = (
  { __typename?: 'Query' }
  & { taskStatuses: Array<(
    { __typename?: 'TaskStatusDto' }
    & Pick<TaskStatusDto, 'id' | 'status' | 'description'>
  )> }
);

export type GetTaskUsersQueryVariables = Exact<{
  where?: Maybe<TaskUserDtoFilterInput>;
}>;


export type GetTaskUsersQuery = (
  { __typename?: 'Query' }
  & { taskUsers: Array<(
    { __typename?: 'TaskUserDto' }
    & Pick<TaskUserDto, 'id' | 'userName' | 'lastName' | 'firstName' | 'isEnabled' | 'emailConfirmed'>
  )> }
);

export type GetTaskCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaskCategoriesQuery = (
  { __typename?: 'Query' }
  & { taskCategories: Array<(
    { __typename?: 'TaskCategory' }
    & Pick<TaskCategory, 'id' | 'name' | 'colour'>
  )> }
);

export type GetTaskBookingsQueryVariables = Exact<{
  where?: Maybe<BookingFilterInput>;
}>;


export type GetTaskBookingsQuery = (
  { __typename?: 'Query' }
  & { taskBookings: Array<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'id' | 'ourReference'>
  )> }
);

export type GetTaskSettingsQueryVariables = Exact<{
  where?: Maybe<SystemTaskSettingFilterInput>;
}>;


export type GetTaskSettingsQuery = (
  { __typename?: 'Query' }
  & { taskSettings: Array<(
    { __typename?: 'SystemTaskSetting' }
    & Pick<SystemTaskSetting, 'id'>
    & TaskSettingDetailsFragment
  )> }
);

export type GetUsersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<IdentityUserDtoSortInput> | IdentityUserDtoSortInput>;
  where?: Maybe<IdentityUserDtoFilterInput>;
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UsersConnection' }
    & Pick<UsersConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'UsersEdge' }
      & { node: (
        { __typename?: 'IdentityUserDto' }
        & Pick<IdentityUserDto, 'id' | 'userName' | 'firstName' | 'lastName' | 'role' | 'address' | 'email' | 'emailConfirmed' | 'isEnabled' | 'lastLoggedIn' | 'createdDate' | 'companyName'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetUserDetailsQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetUserDetailsQuery = (
  { __typename?: 'Query' }
  & { userDetails: (
    { __typename?: 'IdentityUserDetailsDto' }
    & Pick<IdentityUserDetailsDto, 'id' | 'userName' | 'role' | 'firstName' | 'lastName' | 'countryId' | 'city' | 'email' | 'postcode' | 'dob' | 'phoneNumber' | 'driverId' | 'customerId'>
    & { companyDetails?: Maybe<(
      { __typename?: 'UserCompanyDetailsDto' }
      & Pick<UserCompanyDetailsDto, 'companyName' | 'customerId' | 'contactId'>
    )> }
  ) }
);

export type GetApproveDeclineUserDetailsQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetApproveDeclineUserDetailsQuery = (
  { __typename?: 'Query' }
  & { userDetails: (
    { __typename?: 'IdentityUserDetailsDto' }
    & Pick<IdentityUserDetailsDto, 'id' | 'email' | 'userName' | 'firstName' | 'lastName' | 'phoneNumber' | 'role' | 'emailConfirmed' | 'suggestedCompany' | 'driverId'>
  ) }
);

export type GetProfileAvatarQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileAvatarQuery = (
  { __typename?: 'Query' }
  & { myProfile: (
    { __typename?: 'UserProfileDto' }
    & Pick<UserProfileDto, 'id' | 'firstName' | 'lastName' | 'avatarUri' | 'city' | 'country'>
  ) }
);

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyProfileQuery = (
  { __typename?: 'Query' }
  & { myProfile: (
    { __typename?: 'UserProfileDto' }
    & Pick<UserProfileDto, 'id'>
    & UserProfileFragment
  ) }
);

export type GetVatCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVatCategoriesQuery = (
  { __typename?: 'Query' }
  & { vatCategories: Array<(
    { __typename?: 'VatCategory' }
    & Pick<VatCategory, 'id' | 'name'>
  )> }
);

export type GetPagedVehiclesQueryVariables = Exact<{
  driverId: Scalars['Long'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<VehicleSortInput> | VehicleSortInput>;
  where?: Maybe<VehicleFilterInput>;
}>;


export type GetPagedVehiclesQuery = (
  { __typename?: 'Query' }
  & { vehicles?: Maybe<(
    { __typename?: 'VehiclesConnection' }
    & Pick<VehiclesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'VehiclesEdge' }
      & { node: (
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'colour' | 'registrationNumber' | 'manufacturer' | 'model' | 'dateOfManufacture'>
        & { vehicleType: (
          { __typename?: 'VehicleType' }
          & Pick<VehicleType, 'name'>
        ) }
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type GetVehicleQueryVariables = Exact<{
  driverId: Scalars['Long'];
  vehicleId: Scalars['Long'];
}>;


export type GetVehicleQuery = (
  { __typename?: 'Query' }
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'isDefault' | 'driverId' | 'vehicleTypeId' | 'manufacturer' | 'dateOfManufacture' | 'model' | 'colour' | 'insuranceCompany' | 'insuranceExpiryDate' | 'motTestExpiryDate' | 'isRenewalReminder' | 'registrationNumber' | 'policyOrCoverNote' | 'goodsInTransitExpiryDate'>
    & { vehicleType: (
      { __typename?: 'VehicleType' }
      & Pick<VehicleType, 'id' | 'name'>
    ) }
  )> }
);

export type GetVehicleTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVehicleTypesQuery = (
  { __typename?: 'Query' }
  & { vehicleTypes: Array<(
    { __typename?: 'VehicleType' }
    & Pick<VehicleType, 'id' | 'name' | 'isEnabled'>
  )> }
);

export type GetXeroServiceTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetXeroServiceTypesQuery = (
  { __typename?: 'Query' }
  & { xeroServiceTypes: Array<(
    { __typename?: 'XeroServiceTypeDto' }
    & Pick<XeroServiceTypeDto, 'id' | 'serviceType' | 'description'>
  )> }
);

export type GetXeroCodesQueryVariables = Exact<{
  where?: Maybe<XeroCodeFilterInput>;
}>;


export type GetXeroCodesQuery = (
  { __typename?: 'Query' }
  & { xeroCodes: Array<(
    { __typename?: 'XeroCode' }
    & Pick<XeroCode, 'id' | 'name' | 'code'>
  )> }
);

export type GetXeroCodeQueryVariables = Exact<{
  id: Scalars['Long'];
}>;


export type GetXeroCodeQuery = (
  { __typename?: 'Query' }
  & { xeroCode?: Maybe<(
    { __typename?: 'XeroCode' }
    & Pick<XeroCode, 'id' | 'name' | 'code' | 'description' | 'type' | 'serviceType' | 'isInternational'>
  )> }
);

export type GetPagedXeroCodesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<XeroCodeSortInput> | XeroCodeSortInput>;
  where?: Maybe<XeroCodeFilterInput>;
}>;


export type GetPagedXeroCodesQuery = (
  { __typename?: 'Query' }
  & { pagedXeroCodes?: Maybe<(
    { __typename?: 'PagedXeroCodesConnection' }
    & Pick<PagedXeroCodesConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'PagedXeroCodesEdge' }
      & { node: (
        { __typename?: 'XeroCode' }
        & Pick<XeroCode, 'id' | 'name' | 'code' | 'description' | 'type' | 'createdAt'>
      ) }
    )>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type OnBookingUpdateSubscriptionVariables = Exact<{
  bookingId: Scalars['Long'];
}>;


export type OnBookingUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { onBookingUpdated: (
    { __typename?: 'BookingUpdatedDto' }
    & Pick<BookingUpdatedDto, 'id' | 'updatedBy'>
  ) }
);

export type OnCommentaryCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCommentaryCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { onCommentaryCreated: (
    { __typename?: 'Commentary' }
    & Pick<Commentary, 'id'>
    & CommentaryDetailsFragment
  ) }
);

export type OnCommentaryUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCommentaryUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { onCommentaryUpdated: (
    { __typename?: 'Commentary' }
    & Pick<Commentary, 'id'>
    & CommentaryDetailsFragment
  ) }
);

export type OnCommentaryDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCommentaryDeletedSubscription = (
  { __typename?: 'Subscription' }
  & Pick<Subscription, 'onCommentaryDeleted'>
);

export type OnCourierExchangeQuoteCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnCourierExchangeQuoteCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { onCourierExchangeQuoteCreated: (
    { __typename?: 'CourierExchangeQuote' }
    & Pick<CourierExchangeQuote, 'id' | 'quoteId' | 'loadId' | 'quoteOwnerCompanyName' | 'totalPrice'>
  ) }
);

export type GetNotificationSubscriptionVariables = Exact<{
  username: Scalars['String'];
}>;


export type GetNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { onNewNotification: (
    { __typename?: 'Notification' }
    & Pick<Notification, 'id'>
    & NotificationDetailsFragment
  ) }
);

export type OnTaskCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnTaskCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { onTaskAdded: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & TaskDetailsFragment
  )> }
);

export type OnTaskUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnTaskUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { onTaskUpdated: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & TaskDetailsFragment
  )> }
);

export const ActivityDetailsFragmentDoc = gql`
    fragment ActivityDetails on Activity {
  id
  type
  note
  date
  bookingId
  booking {
    id
    ourReference
  }
  createdAt
  createdBy
  lastModifiedAt
  lastModifiedBy
}
    `;
export const BookingDetailsFragmentDoc = gql`
    fragment BookingDetails on Booking {
  customerReference
  customerId
  contactId
  customer {
    id
    name
    isReferenceRequired
    isReasonCodeOptionEnabled
    isPackageDetailsOptional
  }
  consignmentFee
  isEmailNotification
  courierExchangeLoadId
  isArchived
  isCancelledOrQuoteLost
  isOnHold
  noteOrSpecialInstruction
  alternativeOurReference
  ourReference
  overrideContactEmail
  overrideContactTelephoneNumber
  status
  statusLabel
  calculatedStatus
  distance
  isCalculateDistanceAutomatically
  isWaitAndReturn
  tariffId
  courierExchangeLastManualUpdateAt
  isImported
  isQuoteOriginally
  isQuickQuote
  isCreatedByCustomer
  validUntil
  marginPercent
  docketReference
  revenue
  cost
  takenBy
  takenOn
  createdBy
  createdAt
  isTransitIgnored
  potentialBookingId
  isBiddingEnabled
}
    `;
export const BookingAddressesFragmentDoc = gql`
    fragment BookingAddresses on Booking {
  addresses {
    id
    bookingId
    type
    at
    by
    sequenceOrder
    note
    noteFromDriver
    waitingTime
    waitingTimeCharge
    arrivedAt
    signedAt
    signedBy
    signedByFirstName
    signedByLastName
    driverTimeFrom
    driverTimeTo
    courierServiceNumberOfItems
    courierServiceWeight
    courierServiceWeightUnit
    eTA
    reasonCodeId
    addressPackages {
      id
      packageId
      quantity
      package {
        id
        name
        quantity
      }
    }
    allocations {
      id
      packageId
      quantity
      package {
        id
        name
        quantity
      }
    }
    address {
      id
      name
      addressLine1
      addressLine2
      postcode
      city
      countryId
      contact
      email
      telephoneNumber
      isVerified
      googleMapsPlaceId
    }
  }
}
    `;
export const BookingDriversFragmentDoc = gql`
    fragment BookingDrivers on Booking {
  drivers {
    id
    charge
    extraPayment
    driverId
    driver {
      id
      name
      callSign
      xeroCode {
        id
        name
      }
    }
    vehicleId
    vehicle {
      id
      vehicleTypeId
      vehicleType {
        id
        name
      }
    }
  }
}
    `;
export const BookingChargesFragmentDoc = gql`
    fragment BookingCharges on Booking {
  charges {
    id
    bookingId
    name
    type
    description
    quantity
    rate
    xeroServiceCode
    vatCategoryId
  }
}
    `;
export const BookingPackagesFragmentDoc = gql`
    fragment BookingPackages on Booking {
  packages {
    id
    name
    quantity
    type
    height
    width
    length
    weight
    packageReference
    collectionId
    deliveryId
    collection {
      sequenceOrder
    }
    collectionSequenceOrder
    delivery {
      sequenceOrder
    }
    deliverySequenceOrder
  }
}
    `;
export const BookingTariffFragmentDoc = gql`
    fragment BookingTariff on Booking {
  tariff {
    id
    name
    tariffCategory {
      id
      name
      courierExchangeVehicleSize
    }
    xeroCode {
      id
      serviceType
    }
    type
    minCharge
    minMiles
    rate
    baseUnit
    isWaitAndReturnEnabled
    iconUrl
    isExternal
    capacity
  }
}
    `;
export const BookingAllocatedDriverFragmentDoc = gql`
    fragment BookingAllocatedDriver on BookingDriver {
  bookingId
  driverId
  xeroPurchaseCode
  charge
  extraPayment
  isShownInstruction
  instruction
  currencyId
  driver {
    id
    name
    callSign
    xeroCode {
      id
      name
    }
  }
  vehicleId
  vehicle {
    id
    vehicleTypeId
    vehicleType {
      id
      name
    }
  }
  driverContact {
    name
  }
}
    `;
export const UserBookingDetailsFragmentDoc = gql`
    fragment UserBookingDetails on Booking {
  customerReference
  customerId
  contactId
  customer {
    id
    name
    isReasonCodeOptionEnabled
    isPackageDetailsOptional
    isReferenceRequired
  }
  consignmentFee
  isEmailNotification
  courierExchangeLoadId
  isArchived
  isCancelledOrQuoteLost
  isOnHold
  noteOrSpecialInstruction
  alternativeOurReference
  ourReference
  overrideContactEmail
  overrideContactTelephoneNumber
  status
  statusLabel
  calculatedStatus
  distance
  isCalculateDistanceAutomatically
  isWaitAndReturn
  tariffId
  courierExchangeLastManualUpdateAt
  isImported
  isQuoteOriginally
  isQuickQuote
  isCreatedByCustomer
  isTransitIgnored
  validUntil
  docketReference
  takenBy
  takenOn
  createdBy
  createdAt
  potentialBookingId
  isBiddingEnabled
}
    `;
export const CommentaryDetailsFragmentDoc = gql`
    fragment CommentaryDetails on Commentary {
  message
  taskId
  createdAt
  createdBy
  lastModifiedAt
  lastModifiedBy
}
    `;
export const CongestionZoneDetailsFragmentDoc = gql`
    fragment CongestionZoneDetails on CongestionZone {
  name
  isAppliedAutomatically
  isAttachedToAllTariffs
  customerCharge
  durationFrom
  durationTo
  durationFromTotalSeconds
  durationToTotalSeconds
  weekdaysCollection
  xeroServiceCode
  tariffs {
    id
    tariffId
    congestionZoneId
    tariff {
      id
      name
    }
  }
}
    `;
export const CongestionZonePostcodeFragmentFragmentDoc = gql`
    fragment CongestionZonePostcodeFragment on CongestionZonePostcode {
  name
  congestionZoneId
}
    `;
export const CurrencyDetailsFragmentDoc = gql`
    fragment CurrencyDetails on Currency {
  name
  code
  isBaseCurrency
}
    `;
export const CustomerReferenceAndNotesFragmentDoc = gql`
    fragment CustomerReferenceAndNotes on Customer {
  customerReferenceLabel
  isCustomerReferenceMandatory
  isPaperworkRequired
  noteForAccount
  noteForBooking
  instructionsForCourierExchangeDriver
  noteForPackage
  noteForPaperwork
}
    `;
export const CustomerNotificationsFragmentDoc = gql`
    fragment CustomerNotifications on Customer {
  isNotificationForQuote
  isNotificationForReadyForAllocation
  isNotificationForBooking
  isNotificationForPobArrival
  isNotificationForPOB
  isNotificationForPodArrival
  isNotificationForPodPartial
  isNotificationForPOD
  isNotificationForCancellation
  isNotificationForCarded
  isNotificationForAmendment
  isNotificationForDriverAllocated
  isNotificationForInvoice
  isNotificationForInvoiceDue
  customerInvoiceNotificationType
}
    `;
export const CustomerDetailsFragmentDoc = gql`
    fragment CustomerDetails on Customer {
  id
  primaryCustomerId
  name
  addressLine1
  addressLine2
  legalAccountType
  customerAccountType
  city
  postcode
  countryId
  invoiceEmail
  paymentMethodId
  vatCategoryId
  accountCode
  companyRegistrationNumber
  vatIdNumber
  eoriNumber
  invoiceCompanyName
  startDate
  acquiredBy
  customerAcquisitionSourceId
  notes
  invoiceTermId
  invoiceFrequency
  isInvoiceGrouped
  currencyId
  isReferenceRequired
  isUlezCharge
  isOnHold
  isOnHold
  isNotificationForBooking
  isNotificationForInvoice
  isNotificationForInvoiceDue
  isNotificationForPOB
  isNotificationForPOD
  isNotificationForQuote
  isNotificationForReadyForAllocation
  isCustomerReferenceMandatory
  customerInvoiceNotificationType
  telephoneNumber
  salesName
  additionalInvoiceEmails
  isPaperworkRequired
  isApplyVatToOtherCharges
  isEnabled
  isReasonCodeOptionEnabled
  isBookingImportOptionEnabled
  isPackageDetailsOptional
}
    `;
export const CustomerOptionsFragmentDoc = gql`
    fragment CustomerOptions on Customer {
  isReferenceRequired
  isReasonCodeOptionEnabled
  isPackageDetailsOptional
}
    `;
export const DriverDetailsFragmentDoc = gql`
    fragment DriverDetails on Driver {
  id
  firstName
  lastName
  addressLine1
  addressLine2
  bankAccountNumber
  bankName
  bankSortCode
  courierExchangeMemberId
  city
  countryId
  driverType
  xeroCodeId
  email
  employeeReference
  endorsements
  paymentTerm
  invoiceEmail
  eoriNumber
  isActive
  isEmailInvoice
  isGenerateSeparateInvoice
  lastPaid
  licenseExpiryDate
  licenseType
  currencyId
  name
  nationalInsuranceNumber
  paymentMethodId
  postcode
  publicLiabilityExpirationDate
  startDate
  telephoneNumber
  vatCategoryId
  vatIdNumber
  swift
  xeroPurchaseCode
  currencyId
  isOnboardingCompleted
  alternativeTelephoneNumber
  bankAccountHolderName
  internationalBankAccountNumber
  subDrivers {
    name
    email
    callSign
    telephoneNumber
    xeroPurchaseCode
  }
  companyRegistrationNumber
}
    `;
export const DriverContactDetailsFragmentDoc = gql`
    fragment DriverContactDetails on DriverContact {
  id
  name
  isDefault
  telephoneNumber
  email
  additionalEmails
  department
  jobRole
  driverId
}
    `;
export const EmailTemplateDetailsFragmentDoc = gql`
    fragment EmailTemplateDetails on EmailTemplate {
  id
  type
  isBlindCarbonCopyEnabled
  blindCarbonCopy
  subject
  message
  iconUri
  tokens
}
    `;
export const ExchangeRateDetailsFragmentDoc = gql`
    fragment ExchangeRateDetails on ExchangeRate {
  id
  rate
  sourceCurrencyId
  sourceCurrency {
    id
    code
  }
  targetCurrencyId
  targetCurrency {
    id
    code
  }
  createdAt
}
    `;
export const FileDetailsFragmentDoc = gql`
    fragment FileDetails on File {
  name
  category
  absoluteUri
  originalFileName
  fileNameInStorage
  entityReferenceId
  entityReference
  createdBy
  createdAt
}
    `;
export const NotificationDetailsFragmentDoc = gql`
    fragment NotificationDetails on Notification {
  name
  description
  username
  isRead
  createdAt
  createdBy
  taskId
  priority
  date
  type
}
    `;
export const OtherChargeDetailsFragmentDoc = gql`
    fragment OtherChargeDetails on OtherCharge {
  name
  rate
  description
  xeroServiceCode
  vatCategoryId
}
    `;
export const ReasonCodeDetailsFragmentDoc = gql`
    fragment ReasonCodeDetails on ReasonCode {
  name
  code
  description
  customerId
  customer {
    id
    name
  }
}
    `;
export const ReportRowsFragmentDoc = gql`
    fragment ReportRows on GenericReportOutputDto {
  rows {
    cells {
      value
      type
      isBold
      hyperlink
    }
  }
}
    `;
export const TemplateDetailsFragmentDoc = gql`
    fragment TemplateDetails on ScheduledBookingTemplate {
  id
  bookingId
  weekdaysCollection
  processOn
  processOnOnwards
  processOnOnwardsTotalSeconds
}
    `;
export const StandardOperatingProcedureDetailsFragmentDoc = gql`
    fragment StandardOperatingProcedureDetails on StandardOperatingProcedure {
  name
  isActive
  description
  steps
  frequentlyAskedQuestions
  notes
  driverInstructions
  isAttachedToAllCustomers
  customers {
    id
    customer {
      id
      name
    }
  }
}
    `;
export const StrategicPartnerDetailsFragmentDoc = gql`
    fragment StrategicPartnerDetails on StrategicPartner {
  id
  name
  serviceType
  addressLine1
  addressLine2
  city
  postcode
  email
  countryId
  telephoneNumber
  vatIdNumber
  eoriNumber
  xeroPurchaseCode
}
    `;
export const StrategicPartnerContactDetailsFragmentDoc = gql`
    fragment StrategicPartnerContactDetails on StrategicPartnerContact {
  id
  name
  isDefault
  telephoneNumber
  email
  additionalEmails
  department
  jobRole
  strategicPartnerId
}
    `;
export const TariffDetailsFragmentDoc = gql`
    fragment TariffDetails on Tariff {
  id
  basedOnTariffId
  name
  tariffCategoryId
  tariffCategory {
    id
    name
  }
  xeroCodeId
  xeroServiceCode
  type
  minCharge
  minMiles
  rate
  baseUnit
  isWaitAndReturnEnabled
  xeroCode {
    id
    name
    code
  }
  vatCategoryId
  isAttachedToAllCustomers
  customers {
    id
    customer {
      id
      name
    }
  }
  isDropChargeEnabled
  isUseDefaultDropCharge
  dropChargeRate
  isOverwriteDefaultWaitingTime
  collectionAllowanceMinutes
  deliveryAllowanceMinutes
  excessWaitingTimeCharge
  excessWaitingTimeMinutesPer
  calculateCharge
  isExternal
  visibilitySequenceNumber
  tariffIconId
}
    `;
export const TaskDetailsFragmentDoc = gql`
    fragment TaskDetails on Task {
  id
  bookingId
  booking {
    id
    ourReference
    status
  }
  description
  status
  endDate
  time
  timeTotalSeconds
  categoryId
  category {
    id
    name
    colour
  }
  assignedUser
  assignedUserName
  additionalDetail
}
    `;
export const TaskSettingDetailsFragmentDoc = gql`
    fragment TaskSettingDetails on SystemTaskSetting {
  taskRelatedTo
  taskCaption
  interval
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on UserProfileDto {
  country
  countryId
  city
  email
  lastName
  firstName
  phoneNumber
  userName
}
    `;
export const UpdateAccountPayableRecordStatusDocument = gql`
    mutation updateAccountPayableRecordStatus($input: AccountPayableRecordStatusDtoInput!) {
  updateAccountPayableRecordStatus(input: $input) {
    id
    status
  }
}
    `;
export type UpdateAccountPayableRecordStatusMutationFn = Apollo.MutationFunction<UpdateAccountPayableRecordStatusMutation, UpdateAccountPayableRecordStatusMutationVariables>;

/**
 * __useUpdateAccountPayableRecordStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPayableRecordStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPayableRecordStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPayableRecordStatusMutation, { data, loading, error }] = useUpdateAccountPayableRecordStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountPayableRecordStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountPayableRecordStatusMutation, UpdateAccountPayableRecordStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountPayableRecordStatusMutation, UpdateAccountPayableRecordStatusMutationVariables>(UpdateAccountPayableRecordStatusDocument, options);
      }
export type UpdateAccountPayableRecordStatusMutationHookResult = ReturnType<typeof useUpdateAccountPayableRecordStatusMutation>;
export type UpdateAccountPayableRecordStatusMutationResult = Apollo.MutationResult<UpdateAccountPayableRecordStatusMutation>;
export type UpdateAccountPayableRecordStatusMutationOptions = Apollo.BaseMutationOptions<UpdateAccountPayableRecordStatusMutation, UpdateAccountPayableRecordStatusMutationVariables>;
export const UpdateAccountPayablesPaymentDateDocument = gql`
    mutation updateAccountPayablesPaymentDate($input: AccountPayablePaymentDateDtoInput!) {
  updateAccountPayablesPaymentDate(input: $input) {
    id
    bookingId
    paymentDate
  }
}
    `;
export type UpdateAccountPayablesPaymentDateMutationFn = Apollo.MutationFunction<UpdateAccountPayablesPaymentDateMutation, UpdateAccountPayablesPaymentDateMutationVariables>;

/**
 * __useUpdateAccountPayablesPaymentDateMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPayablesPaymentDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPayablesPaymentDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPayablesPaymentDateMutation, { data, loading, error }] = useUpdateAccountPayablesPaymentDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountPayablesPaymentDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountPayablesPaymentDateMutation, UpdateAccountPayablesPaymentDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountPayablesPaymentDateMutation, UpdateAccountPayablesPaymentDateMutationVariables>(UpdateAccountPayablesPaymentDateDocument, options);
      }
export type UpdateAccountPayablesPaymentDateMutationHookResult = ReturnType<typeof useUpdateAccountPayablesPaymentDateMutation>;
export type UpdateAccountPayablesPaymentDateMutationResult = Apollo.MutationResult<UpdateAccountPayablesPaymentDateMutation>;
export type UpdateAccountPayablesPaymentDateMutationOptions = Apollo.BaseMutationOptions<UpdateAccountPayablesPaymentDateMutation, UpdateAccountPayablesPaymentDateMutationVariables>;
export const UpdateAccountPayableRecordsStatusRangeToScheduledPaymentDocument = gql`
    mutation updateAccountPayableRecordsStatusRangeToScheduledPayment($inputs: [AccountPayableRecordStatusDtoInput!]!) {
  updateAccountPayableRecordsStatusRangeToScheduledPayment(inputs: $inputs)
}
    `;
export type UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutationFn = Apollo.MutationFunction<UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation, UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutationVariables>;

/**
 * __useUpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPayableRecordsStatusRangeToScheduledPaymentMutation, { data, loading, error }] = useUpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation, UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation, UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutationVariables>(UpdateAccountPayableRecordsStatusRangeToScheduledPaymentDocument, options);
      }
export type UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutationHookResult = ReturnType<typeof useUpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation>;
export type UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutationResult = Apollo.MutationResult<UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation>;
export type UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutation, UpdateAccountPayableRecordsStatusRangeToScheduledPaymentMutationVariables>;
export const UpdateDriverInformationDocument = gql`
    mutation UpdateDriverInformation($input: UpdateDriverInformationDtoInput!) {
  updateDriverInformation(input: $input) {
    id
    vatCategoryId
    xeroPurchaseCode
  }
}
    `;
export type UpdateDriverInformationMutationFn = Apollo.MutationFunction<UpdateDriverInformationMutation, UpdateDriverInformationMutationVariables>;

/**
 * __useUpdateDriverInformationMutation__
 *
 * To run a mutation, you first call `useUpdateDriverInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverInformationMutation, { data, loading, error }] = useUpdateDriverInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriverInformationMutation, UpdateDriverInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriverInformationMutation, UpdateDriverInformationMutationVariables>(UpdateDriverInformationDocument, options);
      }
export type UpdateDriverInformationMutationHookResult = ReturnType<typeof useUpdateDriverInformationMutation>;
export type UpdateDriverInformationMutationResult = Apollo.MutationResult<UpdateDriverInformationMutation>;
export type UpdateDriverInformationMutationOptions = Apollo.BaseMutationOptions<UpdateDriverInformationMutation, UpdateDriverInformationMutationVariables>;
export const RefreshPaymentStatusDocument = gql`
    mutation refreshPaymentStatus($input: RefreshPaymentStatusDtoInput!) {
  refreshPaymentStatus(input: $input) {
    id
    status
  }
}
    `;
export type RefreshPaymentStatusMutationFn = Apollo.MutationFunction<RefreshPaymentStatusMutation, RefreshPaymentStatusMutationVariables>;

/**
 * __useRefreshPaymentStatusMutation__
 *
 * To run a mutation, you first call `useRefreshPaymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshPaymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshPaymentStatusMutation, { data, loading, error }] = useRefreshPaymentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshPaymentStatusMutation(baseOptions?: Apollo.MutationHookOptions<RefreshPaymentStatusMutation, RefreshPaymentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshPaymentStatusMutation, RefreshPaymentStatusMutationVariables>(RefreshPaymentStatusDocument, options);
      }
export type RefreshPaymentStatusMutationHookResult = ReturnType<typeof useRefreshPaymentStatusMutation>;
export type RefreshPaymentStatusMutationResult = Apollo.MutationResult<RefreshPaymentStatusMutation>;
export type RefreshPaymentStatusMutationOptions = Apollo.BaseMutationOptions<RefreshPaymentStatusMutation, RefreshPaymentStatusMutationVariables>;
export const UpdateAccountPayableNetAmountDocument = gql`
    mutation updateAccountPayableNetAmount($input: UpdateAccountPayableNetAmountDtoInput!) {
  updateAccountPayableNetAmount(input: $input) {
    id
    net
  }
}
    `;
export type UpdateAccountPayableNetAmountMutationFn = Apollo.MutationFunction<UpdateAccountPayableNetAmountMutation, UpdateAccountPayableNetAmountMutationVariables>;

/**
 * __useUpdateAccountPayableNetAmountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPayableNetAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPayableNetAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPayableNetAmountMutation, { data, loading, error }] = useUpdateAccountPayableNetAmountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountPayableNetAmountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountPayableNetAmountMutation, UpdateAccountPayableNetAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountPayableNetAmountMutation, UpdateAccountPayableNetAmountMutationVariables>(UpdateAccountPayableNetAmountDocument, options);
      }
export type UpdateAccountPayableNetAmountMutationHookResult = ReturnType<typeof useUpdateAccountPayableNetAmountMutation>;
export type UpdateAccountPayableNetAmountMutationResult = Apollo.MutationResult<UpdateAccountPayableNetAmountMutation>;
export type UpdateAccountPayableNetAmountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountPayableNetAmountMutation, UpdateAccountPayableNetAmountMutationVariables>;
export const CreateActivityDocument = gql`
    mutation createActivity($input: CreateUpdateActivityDtoInput!) {
  createActivity(input: $input) {
    id
    ...ActivityDetails
  }
}
    ${ActivityDetailsFragmentDoc}`;
export type CreateActivityMutationFn = Apollo.MutationFunction<CreateActivityMutation, CreateActivityMutationVariables>;

/**
 * __useCreateActivityMutation__
 *
 * To run a mutation, you first call `useCreateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityMutation, { data, loading, error }] = useCreateActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActivityMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivityMutation, CreateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActivityMutation, CreateActivityMutationVariables>(CreateActivityDocument, options);
      }
export type CreateActivityMutationHookResult = ReturnType<typeof useCreateActivityMutation>;
export type CreateActivityMutationResult = Apollo.MutationResult<CreateActivityMutation>;
export type CreateActivityMutationOptions = Apollo.BaseMutationOptions<CreateActivityMutation, CreateActivityMutationVariables>;
export const UpdateActivityDocument = gql`
    mutation updateActivity($input: CreateUpdateActivityDtoInput!) {
  updateActivity(input: $input) {
    id
    ...ActivityDetails
  }
}
    ${ActivityDetailsFragmentDoc}`;
export type UpdateActivityMutationFn = Apollo.MutationFunction<UpdateActivityMutation, UpdateActivityMutationVariables>;

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityMutation, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityMutation, UpdateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityMutation, UpdateActivityMutationVariables>(UpdateActivityDocument, options);
      }
export type UpdateActivityMutationHookResult = ReturnType<typeof useUpdateActivityMutation>;
export type UpdateActivityMutationResult = Apollo.MutationResult<UpdateActivityMutation>;
export type UpdateActivityMutationOptions = Apollo.BaseMutationOptions<UpdateActivityMutation, UpdateActivityMutationVariables>;
export const DeleteActivityDocument = gql`
    mutation deleteActivity($id: Long!) {
  deleteActivity(id: $id)
}
    `;
export type DeleteActivityMutationFn = Apollo.MutationFunction<DeleteActivityMutation, DeleteActivityMutationVariables>;

/**
 * __useDeleteActivityMutation__
 *
 * To run a mutation, you first call `useDeleteActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityMutation, { data, loading, error }] = useDeleteActivityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityMutation, DeleteActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityMutation, DeleteActivityMutationVariables>(DeleteActivityDocument, options);
      }
export type DeleteActivityMutationHookResult = ReturnType<typeof useDeleteActivityMutation>;
export type DeleteActivityMutationResult = Apollo.MutationResult<DeleteActivityMutation>;
export type DeleteActivityMutationOptions = Apollo.BaseMutationOptions<DeleteActivityMutation, DeleteActivityMutationVariables>;
export const CreateBannerDocument = gql`
    mutation CreateBanner($input: CreateAndUpdateBannerDtoInput!) {
  createBanner(input: $input) {
    id
    name
    type
    isActive
    fileId
    file {
      id
      ...FileDetails
    }
  }
}
    ${FileDetailsFragmentDoc}`;
export type CreateBannerMutationFn = Apollo.MutationFunction<CreateBannerMutation, CreateBannerMutationVariables>;

/**
 * __useCreateBannerMutation__
 *
 * To run a mutation, you first call `useCreateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBannerMutation, { data, loading, error }] = useCreateBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateBannerMutation, CreateBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBannerMutation, CreateBannerMutationVariables>(CreateBannerDocument, options);
      }
export type CreateBannerMutationHookResult = ReturnType<typeof useCreateBannerMutation>;
export type CreateBannerMutationResult = Apollo.MutationResult<CreateBannerMutation>;
export type CreateBannerMutationOptions = Apollo.BaseMutationOptions<CreateBannerMutation, CreateBannerMutationVariables>;
export const UpdateBannerDocument = gql`
    mutation UpdateBanner($input: CreateAndUpdateBannerDtoInput!) {
  updateBanner(input: $input) {
    id
    name
    type
    isActive
    fileId
    file {
      id
      ...FileDetails
    }
  }
}
    ${FileDetailsFragmentDoc}`;
export type UpdateBannerMutationFn = Apollo.MutationFunction<UpdateBannerMutation, UpdateBannerMutationVariables>;

/**
 * __useUpdateBannerMutation__
 *
 * To run a mutation, you first call `useUpdateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannerMutation, { data, loading, error }] = useUpdateBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBannerMutation, UpdateBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBannerMutation, UpdateBannerMutationVariables>(UpdateBannerDocument, options);
      }
export type UpdateBannerMutationHookResult = ReturnType<typeof useUpdateBannerMutation>;
export type UpdateBannerMutationResult = Apollo.MutationResult<UpdateBannerMutation>;
export type UpdateBannerMutationOptions = Apollo.BaseMutationOptions<UpdateBannerMutation, UpdateBannerMutationVariables>;
export const DeleteBannerDocument = gql`
    mutation DeleteBanner($id: Long!) {
  deleteBanner(id: $id)
}
    `;
export type DeleteBannerMutationFn = Apollo.MutationFunction<DeleteBannerMutation, DeleteBannerMutationVariables>;

/**
 * __useDeleteBannerMutation__
 *
 * To run a mutation, you first call `useDeleteBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBannerMutation, { data, loading, error }] = useDeleteBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBannerMutation, DeleteBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBannerMutation, DeleteBannerMutationVariables>(DeleteBannerDocument, options);
      }
export type DeleteBannerMutationHookResult = ReturnType<typeof useDeleteBannerMutation>;
export type DeleteBannerMutationResult = Apollo.MutationResult<DeleteBannerMutation>;
export type DeleteBannerMutationOptions = Apollo.BaseMutationOptions<DeleteBannerMutation, DeleteBannerMutationVariables>;
export const UpdateBookingAttachmentDocument = gql`
    mutation UpdateBookingAttachment($input: UpdateBookingAttachmentDtoInput!) {
  updateBookingAttachment(input: $input) {
    id
    fileId
    bookingId
    isAvailableForDriver
    isAvailableForCustomer
    isEnabled
  }
}
    `;
export type UpdateBookingAttachmentMutationFn = Apollo.MutationFunction<UpdateBookingAttachmentMutation, UpdateBookingAttachmentMutationVariables>;

/**
 * __useUpdateBookingAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateBookingAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingAttachmentMutation, { data, loading, error }] = useUpdateBookingAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingAttachmentMutation, UpdateBookingAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingAttachmentMutation, UpdateBookingAttachmentMutationVariables>(UpdateBookingAttachmentDocument, options);
      }
export type UpdateBookingAttachmentMutationHookResult = ReturnType<typeof useUpdateBookingAttachmentMutation>;
export type UpdateBookingAttachmentMutationResult = Apollo.MutationResult<UpdateBookingAttachmentMutation>;
export type UpdateBookingAttachmentMutationOptions = Apollo.BaseMutationOptions<UpdateBookingAttachmentMutation, UpdateBookingAttachmentMutationVariables>;
export const DeleteBookingAttachmentDocument = gql`
    mutation DeleteBookingAttachment($bookingId: Long!, $fileId: Long!) {
  deleteBookingAttachment(bookingId: $bookingId, fileId: $fileId)
}
    `;
export type DeleteBookingAttachmentMutationFn = Apollo.MutationFunction<DeleteBookingAttachmentMutation, DeleteBookingAttachmentMutationVariables>;

/**
 * __useDeleteBookingAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteBookingAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingAttachmentMutation, { data, loading, error }] = useDeleteBookingAttachmentMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useDeleteBookingAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingAttachmentMutation, DeleteBookingAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingAttachmentMutation, DeleteBookingAttachmentMutationVariables>(DeleteBookingAttachmentDocument, options);
      }
export type DeleteBookingAttachmentMutationHookResult = ReturnType<typeof useDeleteBookingAttachmentMutation>;
export type DeleteBookingAttachmentMutationResult = Apollo.MutationResult<DeleteBookingAttachmentMutation>;
export type DeleteBookingAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteBookingAttachmentMutation, DeleteBookingAttachmentMutationVariables>;
export const CreateBookingCombinedDocument = gql`
    mutation CreateBookingCombined($input: CreateAndUpdateBookingCombinedDtoInput!) {
  createBookingCombined(input: $input) {
    id
    ...BookingDetails
    ...BookingAddresses
    ...BookingCharges
    ...BookingPackages
  }
}
    ${BookingDetailsFragmentDoc}
${BookingAddressesFragmentDoc}
${BookingChargesFragmentDoc}
${BookingPackagesFragmentDoc}`;
export type CreateBookingCombinedMutationFn = Apollo.MutationFunction<CreateBookingCombinedMutation, CreateBookingCombinedMutationVariables>;

/**
 * __useCreateBookingCombinedMutation__
 *
 * To run a mutation, you first call `useCreateBookingCombinedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingCombinedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingCombinedMutation, { data, loading, error }] = useCreateBookingCombinedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingCombinedMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingCombinedMutation, CreateBookingCombinedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingCombinedMutation, CreateBookingCombinedMutationVariables>(CreateBookingCombinedDocument, options);
      }
export type CreateBookingCombinedMutationHookResult = ReturnType<typeof useCreateBookingCombinedMutation>;
export type CreateBookingCombinedMutationResult = Apollo.MutationResult<CreateBookingCombinedMutation>;
export type CreateBookingCombinedMutationOptions = Apollo.BaseMutationOptions<CreateBookingCombinedMutation, CreateBookingCombinedMutationVariables>;
export const UpdateBookingCombinedDocument = gql`
    mutation UpdateBookingCombined($input: CreateAndUpdateBookingCombinedDtoInput!) {
  updateBookingCombined(input: $input) {
    id
    ...BookingDetails
    ...BookingAddresses
    ...BookingDrivers
    ...BookingCharges
    ...BookingPackages
    ...BookingTariff
  }
}
    ${BookingDetailsFragmentDoc}
${BookingAddressesFragmentDoc}
${BookingDriversFragmentDoc}
${BookingChargesFragmentDoc}
${BookingPackagesFragmentDoc}
${BookingTariffFragmentDoc}`;
export type UpdateBookingCombinedMutationFn = Apollo.MutationFunction<UpdateBookingCombinedMutation, UpdateBookingCombinedMutationVariables>;

/**
 * __useUpdateBookingCombinedMutation__
 *
 * To run a mutation, you first call `useUpdateBookingCombinedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingCombinedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingCombinedMutation, { data, loading, error }] = useUpdateBookingCombinedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingCombinedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingCombinedMutation, UpdateBookingCombinedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingCombinedMutation, UpdateBookingCombinedMutationVariables>(UpdateBookingCombinedDocument, options);
      }
export type UpdateBookingCombinedMutationHookResult = ReturnType<typeof useUpdateBookingCombinedMutation>;
export type UpdateBookingCombinedMutationResult = Apollo.MutationResult<UpdateBookingCombinedMutation>;
export type UpdateBookingCombinedMutationOptions = Apollo.BaseMutationOptions<UpdateBookingCombinedMutation, UpdateBookingCombinedMutationVariables>;
export const CreateUserBookingCombinedDocument = gql`
    mutation CreateUserBookingCombined($input: CreateAndUpdateBookingCombinedDtoInput!) {
  createBookingCombined(input: $input) {
    id
    ...UserBookingDetails
    ...BookingAddresses
    ...BookingCharges
    ...BookingPackages
  }
}
    ${UserBookingDetailsFragmentDoc}
${BookingAddressesFragmentDoc}
${BookingChargesFragmentDoc}
${BookingPackagesFragmentDoc}`;
export type CreateUserBookingCombinedMutationFn = Apollo.MutationFunction<CreateUserBookingCombinedMutation, CreateUserBookingCombinedMutationVariables>;

/**
 * __useCreateUserBookingCombinedMutation__
 *
 * To run a mutation, you first call `useCreateUserBookingCombinedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserBookingCombinedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserBookingCombinedMutation, { data, loading, error }] = useCreateUserBookingCombinedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserBookingCombinedMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserBookingCombinedMutation, CreateUserBookingCombinedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserBookingCombinedMutation, CreateUserBookingCombinedMutationVariables>(CreateUserBookingCombinedDocument, options);
      }
export type CreateUserBookingCombinedMutationHookResult = ReturnType<typeof useCreateUserBookingCombinedMutation>;
export type CreateUserBookingCombinedMutationResult = Apollo.MutationResult<CreateUserBookingCombinedMutation>;
export type CreateUserBookingCombinedMutationOptions = Apollo.BaseMutationOptions<CreateUserBookingCombinedMutation, CreateUserBookingCombinedMutationVariables>;
export const UpdateUserBookingCombinedDocument = gql`
    mutation UpdateUserBookingCombined($input: CreateAndUpdateBookingCombinedDtoInput!) {
  updateBookingCombined(input: $input) {
    id
    ...UserBookingDetails
    ...BookingAddresses
    ...BookingDrivers
    ...BookingCharges
    ...BookingPackages
    ...BookingTariff
  }
}
    ${UserBookingDetailsFragmentDoc}
${BookingAddressesFragmentDoc}
${BookingDriversFragmentDoc}
${BookingChargesFragmentDoc}
${BookingPackagesFragmentDoc}
${BookingTariffFragmentDoc}`;
export type UpdateUserBookingCombinedMutationFn = Apollo.MutationFunction<UpdateUserBookingCombinedMutation, UpdateUserBookingCombinedMutationVariables>;

/**
 * __useUpdateUserBookingCombinedMutation__
 *
 * To run a mutation, you first call `useUpdateUserBookingCombinedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBookingCombinedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBookingCombinedMutation, { data, loading, error }] = useUpdateUserBookingCombinedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserBookingCombinedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserBookingCombinedMutation, UpdateUserBookingCombinedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserBookingCombinedMutation, UpdateUserBookingCombinedMutationVariables>(UpdateUserBookingCombinedDocument, options);
      }
export type UpdateUserBookingCombinedMutationHookResult = ReturnType<typeof useUpdateUserBookingCombinedMutation>;
export type UpdateUserBookingCombinedMutationResult = Apollo.MutationResult<UpdateUserBookingCombinedMutation>;
export type UpdateUserBookingCombinedMutationOptions = Apollo.BaseMutationOptions<UpdateUserBookingCombinedMutation, UpdateUserBookingCombinedMutationVariables>;
export const DeleteBookingAddressDocument = gql`
    mutation DeleteBookingAddress($bookingAddressId: Long!) {
  deleteBookingAddress(bookingAddressId: $bookingAddressId) {
    id
  }
}
    `;
export type DeleteBookingAddressMutationFn = Apollo.MutationFunction<DeleteBookingAddressMutation, DeleteBookingAddressMutationVariables>;

/**
 * __useDeleteBookingAddressMutation__
 *
 * To run a mutation, you first call `useDeleteBookingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingAddressMutation, { data, loading, error }] = useDeleteBookingAddressMutation({
 *   variables: {
 *      bookingAddressId: // value for 'bookingAddressId'
 *   },
 * });
 */
export function useDeleteBookingAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingAddressMutation, DeleteBookingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingAddressMutation, DeleteBookingAddressMutationVariables>(DeleteBookingAddressDocument, options);
      }
export type DeleteBookingAddressMutationHookResult = ReturnType<typeof useDeleteBookingAddressMutation>;
export type DeleteBookingAddressMutationResult = Apollo.MutationResult<DeleteBookingAddressMutation>;
export type DeleteBookingAddressMutationOptions = Apollo.BaseMutationOptions<DeleteBookingAddressMutation, DeleteBookingAddressMutationVariables>;
export const DeleteBookingChargeDocument = gql`
    mutation DeleteBookingCharge($bookingChargeId: Long!) {
  deleteBookingCharge(bookingChargeId: $bookingChargeId) {
    id
  }
}
    `;
export type DeleteBookingChargeMutationFn = Apollo.MutationFunction<DeleteBookingChargeMutation, DeleteBookingChargeMutationVariables>;

/**
 * __useDeleteBookingChargeMutation__
 *
 * To run a mutation, you first call `useDeleteBookingChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingChargeMutation, { data, loading, error }] = useDeleteBookingChargeMutation({
 *   variables: {
 *      bookingChargeId: // value for 'bookingChargeId'
 *   },
 * });
 */
export function useDeleteBookingChargeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingChargeMutation, DeleteBookingChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingChargeMutation, DeleteBookingChargeMutationVariables>(DeleteBookingChargeDocument, options);
      }
export type DeleteBookingChargeMutationHookResult = ReturnType<typeof useDeleteBookingChargeMutation>;
export type DeleteBookingChargeMutationResult = Apollo.MutationResult<DeleteBookingChargeMutation>;
export type DeleteBookingChargeMutationOptions = Apollo.BaseMutationOptions<DeleteBookingChargeMutation, DeleteBookingChargeMutationVariables>;
export const DeleteBookingPackageDocument = gql`
    mutation DeleteBookingPackage($packageId: Long!) {
  deleteBookingPackage(packageId: $packageId) {
    id
  }
}
    `;
export type DeleteBookingPackageMutationFn = Apollo.MutationFunction<DeleteBookingPackageMutation, DeleteBookingPackageMutationVariables>;

/**
 * __useDeleteBookingPackageMutation__
 *
 * To run a mutation, you first call `useDeleteBookingPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingPackageMutation, { data, loading, error }] = useDeleteBookingPackageMutation({
 *   variables: {
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function useDeleteBookingPackageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingPackageMutation, DeleteBookingPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingPackageMutation, DeleteBookingPackageMutationVariables>(DeleteBookingPackageDocument, options);
      }
export type DeleteBookingPackageMutationHookResult = ReturnType<typeof useDeleteBookingPackageMutation>;
export type DeleteBookingPackageMutationResult = Apollo.MutationResult<DeleteBookingPackageMutation>;
export type DeleteBookingPackageMutationOptions = Apollo.BaseMutationOptions<DeleteBookingPackageMutation, DeleteBookingPackageMutationVariables>;
export const CreateBookingDriversDocument = gql`
    mutation createBookingDrivers($input: [CreateAndUpdateBookingDriverDtoInput!]!) {
  createBookingDrivers(input: $input) {
    id
    ...BookingAllocatedDriver
    booking {
      id
      takenBy
      takenOn
    }
  }
}
    ${BookingAllocatedDriverFragmentDoc}`;
export type CreateBookingDriversMutationFn = Apollo.MutationFunction<CreateBookingDriversMutation, CreateBookingDriversMutationVariables>;

/**
 * __useCreateBookingDriversMutation__
 *
 * To run a mutation, you first call `useCreateBookingDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingDriversMutation, { data, loading, error }] = useCreateBookingDriversMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingDriversMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingDriversMutation, CreateBookingDriversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingDriversMutation, CreateBookingDriversMutationVariables>(CreateBookingDriversDocument, options);
      }
export type CreateBookingDriversMutationHookResult = ReturnType<typeof useCreateBookingDriversMutation>;
export type CreateBookingDriversMutationResult = Apollo.MutationResult<CreateBookingDriversMutation>;
export type CreateBookingDriversMutationOptions = Apollo.BaseMutationOptions<CreateBookingDriversMutation, CreateBookingDriversMutationVariables>;
export const CreateBookingDriverDocument = gql`
    mutation CreateBookingDriver($input: CreateAndUpdateBookingDriverDtoInput!) {
  createBookingDriver(input: $input) {
    id
    ...BookingAllocatedDriver
  }
}
    ${BookingAllocatedDriverFragmentDoc}`;
export type CreateBookingDriverMutationFn = Apollo.MutationFunction<CreateBookingDriverMutation, CreateBookingDriverMutationVariables>;

/**
 * __useCreateBookingDriverMutation__
 *
 * To run a mutation, you first call `useCreateBookingDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingDriverMutation, { data, loading, error }] = useCreateBookingDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingDriverMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingDriverMutation, CreateBookingDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingDriverMutation, CreateBookingDriverMutationVariables>(CreateBookingDriverDocument, options);
      }
export type CreateBookingDriverMutationHookResult = ReturnType<typeof useCreateBookingDriverMutation>;
export type CreateBookingDriverMutationResult = Apollo.MutationResult<CreateBookingDriverMutation>;
export type CreateBookingDriverMutationOptions = Apollo.BaseMutationOptions<CreateBookingDriverMutation, CreateBookingDriverMutationVariables>;
export const UpdateBookingDriverDocument = gql`
    mutation UpdateBookingDriver($input: CreateAndUpdateBookingDriverDtoInput!) {
  updateBookingDriver(input: $input) {
    id
    ...BookingAllocatedDriver
  }
}
    ${BookingAllocatedDriverFragmentDoc}`;
export type UpdateBookingDriverMutationFn = Apollo.MutationFunction<UpdateBookingDriverMutation, UpdateBookingDriverMutationVariables>;

/**
 * __useUpdateBookingDriverMutation__
 *
 * To run a mutation, you first call `useUpdateBookingDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingDriverMutation, { data, loading, error }] = useUpdateBookingDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingDriverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingDriverMutation, UpdateBookingDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingDriverMutation, UpdateBookingDriverMutationVariables>(UpdateBookingDriverDocument, options);
      }
export type UpdateBookingDriverMutationHookResult = ReturnType<typeof useUpdateBookingDriverMutation>;
export type UpdateBookingDriverMutationResult = Apollo.MutationResult<UpdateBookingDriverMutation>;
export type UpdateBookingDriverMutationOptions = Apollo.BaseMutationOptions<UpdateBookingDriverMutation, UpdateBookingDriverMutationVariables>;
export const DeleteBookingDriverDocument = gql`
    mutation DeleteBookingDriver($bookingDriverId: Long!) {
  deleteBookingDriver(bookingDriverId: $bookingDriverId) {
    id
    ...BookingAllocatedDriver
  }
}
    ${BookingAllocatedDriverFragmentDoc}`;
export type DeleteBookingDriverMutationFn = Apollo.MutationFunction<DeleteBookingDriverMutation, DeleteBookingDriverMutationVariables>;

/**
 * __useDeleteBookingDriverMutation__
 *
 * To run a mutation, you first call `useDeleteBookingDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingDriverMutation, { data, loading, error }] = useDeleteBookingDriverMutation({
 *   variables: {
 *      bookingDriverId: // value for 'bookingDriverId'
 *   },
 * });
 */
export function useDeleteBookingDriverMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingDriverMutation, DeleteBookingDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingDriverMutation, DeleteBookingDriverMutationVariables>(DeleteBookingDriverDocument, options);
      }
export type DeleteBookingDriverMutationHookResult = ReturnType<typeof useDeleteBookingDriverMutation>;
export type DeleteBookingDriverMutationResult = Apollo.MutationResult<DeleteBookingDriverMutation>;
export type DeleteBookingDriverMutationOptions = Apollo.BaseMutationOptions<DeleteBookingDriverMutation, DeleteBookingDriverMutationVariables>;
export const DeleteBookingDriverByBookingAndDriverIdDocument = gql`
    mutation DeleteBookingDriverByBookingAndDriverId($bookingId: Long!, $driverId: Long!) {
  deleteBookingDriverByBookingAndDriverId(
    bookingId: $bookingId
    driverId: $driverId
  ) {
    id
    ...BookingAllocatedDriver
  }
}
    ${BookingAllocatedDriverFragmentDoc}`;
export type DeleteBookingDriverByBookingAndDriverIdMutationFn = Apollo.MutationFunction<DeleteBookingDriverByBookingAndDriverIdMutation, DeleteBookingDriverByBookingAndDriverIdMutationVariables>;

/**
 * __useDeleteBookingDriverByBookingAndDriverIdMutation__
 *
 * To run a mutation, you first call `useDeleteBookingDriverByBookingAndDriverIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingDriverByBookingAndDriverIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingDriverByBookingAndDriverIdMutation, { data, loading, error }] = useDeleteBookingDriverByBookingAndDriverIdMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useDeleteBookingDriverByBookingAndDriverIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingDriverByBookingAndDriverIdMutation, DeleteBookingDriverByBookingAndDriverIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingDriverByBookingAndDriverIdMutation, DeleteBookingDriverByBookingAndDriverIdMutationVariables>(DeleteBookingDriverByBookingAndDriverIdDocument, options);
      }
export type DeleteBookingDriverByBookingAndDriverIdMutationHookResult = ReturnType<typeof useDeleteBookingDriverByBookingAndDriverIdMutation>;
export type DeleteBookingDriverByBookingAndDriverIdMutationResult = Apollo.MutationResult<DeleteBookingDriverByBookingAndDriverIdMutation>;
export type DeleteBookingDriverByBookingAndDriverIdMutationOptions = Apollo.BaseMutationOptions<DeleteBookingDriverByBookingAndDriverIdMutation, DeleteBookingDriverByBookingAndDriverIdMutationVariables>;
export const CancelBookingDocument = gql`
    mutation CancelBooking($input: CancelBookingDtoInput!) {
  cancelBooking(input: $input) {
    id
    isCancelledOrQuoteLost
    cancellationReasonOrQuoteLostDetail
    statusLabel
  }
}
    `;
export type CancelBookingMutationFn = Apollo.MutationFunction<CancelBookingMutation, CancelBookingMutationVariables>;

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelBookingMutation(baseOptions?: Apollo.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(CancelBookingDocument, options);
      }
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>;
export type CancelBookingMutationResult = Apollo.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = Apollo.BaseMutationOptions<CancelBookingMutation, CancelBookingMutationVariables>;
export const MarkQuoteLostDocument = gql`
    mutation MarkQuoteLost($input: QuoteLostDtoInput!) {
  markQuoteLost(input: $input) {
    id
    isCancelledOrQuoteLost
    quoteLostReasonId
    cancellationReasonOrQuoteLostDetail
    statusLabel
  }
}
    `;
export type MarkQuoteLostMutationFn = Apollo.MutationFunction<MarkQuoteLostMutation, MarkQuoteLostMutationVariables>;

/**
 * __useMarkQuoteLostMutation__
 *
 * To run a mutation, you first call `useMarkQuoteLostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkQuoteLostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markQuoteLostMutation, { data, loading, error }] = useMarkQuoteLostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkQuoteLostMutation(baseOptions?: Apollo.MutationHookOptions<MarkQuoteLostMutation, MarkQuoteLostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkQuoteLostMutation, MarkQuoteLostMutationVariables>(MarkQuoteLostDocument, options);
      }
export type MarkQuoteLostMutationHookResult = ReturnType<typeof useMarkQuoteLostMutation>;
export type MarkQuoteLostMutationResult = Apollo.MutationResult<MarkQuoteLostMutation>;
export type MarkQuoteLostMutationOptions = Apollo.BaseMutationOptions<MarkQuoteLostMutation, MarkQuoteLostMutationVariables>;
export const DeleteBookingDocument = gql`
    mutation DeleteBooking($input: DeleteOrDisableBookingDtoInput!) {
  deleteBooking(input: $input)
}
    `;
export type DeleteBookingMutationFn = Apollo.MutationFunction<DeleteBookingMutation, DeleteBookingMutationVariables>;

/**
 * __useDeleteBookingMutation__
 *
 * To run a mutation, you first call `useDeleteBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingMutation, { data, loading, error }] = useDeleteBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBookingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingMutation, DeleteBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingMutation, DeleteBookingMutationVariables>(DeleteBookingDocument, options);
      }
export type DeleteBookingMutationHookResult = ReturnType<typeof useDeleteBookingMutation>;
export type DeleteBookingMutationResult = Apollo.MutationResult<DeleteBookingMutation>;
export type DeleteBookingMutationOptions = Apollo.BaseMutationOptions<DeleteBookingMutation, DeleteBookingMutationVariables>;
export const ArchiveBookingDocument = gql`
    mutation ArchiveBooking($input: ArchiveBookingDtoInput!) {
  archiveBooking(input: $input) {
    id
    isArchived
    statusLabel
  }
}
    `;
export type ArchiveBookingMutationFn = Apollo.MutationFunction<ArchiveBookingMutation, ArchiveBookingMutationVariables>;

/**
 * __useArchiveBookingMutation__
 *
 * To run a mutation, you first call `useArchiveBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBookingMutation, { data, loading, error }] = useArchiveBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveBookingMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveBookingMutation, ArchiveBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveBookingMutation, ArchiveBookingMutationVariables>(ArchiveBookingDocument, options);
      }
export type ArchiveBookingMutationHookResult = ReturnType<typeof useArchiveBookingMutation>;
export type ArchiveBookingMutationResult = Apollo.MutationResult<ArchiveBookingMutation>;
export type ArchiveBookingMutationOptions = Apollo.BaseMutationOptions<ArchiveBookingMutation, ArchiveBookingMutationVariables>;
export const MakeBookingOnHoldDocument = gql`
    mutation MakeBookingOnHold($input: MakeBookingOnHoldDtoInput!) {
  makeBookingOnHold(input: $input) {
    id
    isOnHold
    statusLabel
    calculatedStatus
    status
  }
}
    `;
export type MakeBookingOnHoldMutationFn = Apollo.MutationFunction<MakeBookingOnHoldMutation, MakeBookingOnHoldMutationVariables>;

/**
 * __useMakeBookingOnHoldMutation__
 *
 * To run a mutation, you first call `useMakeBookingOnHoldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeBookingOnHoldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeBookingOnHoldMutation, { data, loading, error }] = useMakeBookingOnHoldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeBookingOnHoldMutation(baseOptions?: Apollo.MutationHookOptions<MakeBookingOnHoldMutation, MakeBookingOnHoldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeBookingOnHoldMutation, MakeBookingOnHoldMutationVariables>(MakeBookingOnHoldDocument, options);
      }
export type MakeBookingOnHoldMutationHookResult = ReturnType<typeof useMakeBookingOnHoldMutation>;
export type MakeBookingOnHoldMutationResult = Apollo.MutationResult<MakeBookingOnHoldMutation>;
export type MakeBookingOnHoldMutationOptions = Apollo.BaseMutationOptions<MakeBookingOnHoldMutation, MakeBookingOnHoldMutationVariables>;
export const ConfirmQuickQuoteDocument = gql`
    mutation ConfirmQuickQuote($input: ConfirmQuickQuoteDtoInput!) {
  confirmQuickQuote(input: $input) {
    id
    isQuickQuote
    status
    statusLabel
    calculatedStatus
    isArchived
    isCancelledOrQuoteLost
    isOnHold
    isPreBooked
    isPending
    ...BookingAddresses
  }
}
    ${BookingAddressesFragmentDoc}`;
export type ConfirmQuickQuoteMutationFn = Apollo.MutationFunction<ConfirmQuickQuoteMutation, ConfirmQuickQuoteMutationVariables>;

/**
 * __useConfirmQuickQuoteMutation__
 *
 * To run a mutation, you first call `useConfirmQuickQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmQuickQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmQuickQuoteMutation, { data, loading, error }] = useConfirmQuickQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmQuickQuoteMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmQuickQuoteMutation, ConfirmQuickQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmQuickQuoteMutation, ConfirmQuickQuoteMutationVariables>(ConfirmQuickQuoteDocument, options);
      }
export type ConfirmQuickQuoteMutationHookResult = ReturnType<typeof useConfirmQuickQuoteMutation>;
export type ConfirmQuickQuoteMutationResult = Apollo.MutationResult<ConfirmQuickQuoteMutation>;
export type ConfirmQuickQuoteMutationOptions = Apollo.BaseMutationOptions<ConfirmQuickQuoteMutation, ConfirmQuickQuoteMutationVariables>;
export const CopyBookingDocument = gql`
    mutation CopyBooking($bookingId: Long!) {
  copyBooking(bookingId: $bookingId)
}
    `;
export type CopyBookingMutationFn = Apollo.MutationFunction<CopyBookingMutation, CopyBookingMutationVariables>;

/**
 * __useCopyBookingMutation__
 *
 * To run a mutation, you first call `useCopyBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyBookingMutation, { data, loading, error }] = useCopyBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useCopyBookingMutation(baseOptions?: Apollo.MutationHookOptions<CopyBookingMutation, CopyBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyBookingMutation, CopyBookingMutationVariables>(CopyBookingDocument, options);
      }
export type CopyBookingMutationHookResult = ReturnType<typeof useCopyBookingMutation>;
export type CopyBookingMutationResult = Apollo.MutationResult<CopyBookingMutation>;
export type CopyBookingMutationOptions = Apollo.BaseMutationOptions<CopyBookingMutation, CopyBookingMutationVariables>;
export const UpdateBookingProofOfDeliveryDocument = gql`
    mutation UpdateBookingProofOfDelivery($input: UpdateProofOfDeliveryDtoInput!) {
  updateBookingProofOfDelivery(input: $input) {
    id
    bookingId
    booking {
      id
      status
      statusLabel
    }
    at
    by
    waitingTime
    waitingTimeCharge
    arrivedAt
    signedAt
    signedByFirstName
    signedByLastName
    driverTimeFrom
    driverTimeTo
    noteFromDriver
    eTA
    allocations {
      id
      packageId
      quantity
    }
  }
}
    `;
export type UpdateBookingProofOfDeliveryMutationFn = Apollo.MutationFunction<UpdateBookingProofOfDeliveryMutation, UpdateBookingProofOfDeliveryMutationVariables>;

/**
 * __useUpdateBookingProofOfDeliveryMutation__
 *
 * To run a mutation, you first call `useUpdateBookingProofOfDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingProofOfDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingProofOfDeliveryMutation, { data, loading, error }] = useUpdateBookingProofOfDeliveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingProofOfDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingProofOfDeliveryMutation, UpdateBookingProofOfDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingProofOfDeliveryMutation, UpdateBookingProofOfDeliveryMutationVariables>(UpdateBookingProofOfDeliveryDocument, options);
      }
export type UpdateBookingProofOfDeliveryMutationHookResult = ReturnType<typeof useUpdateBookingProofOfDeliveryMutation>;
export type UpdateBookingProofOfDeliveryMutationResult = Apollo.MutationResult<UpdateBookingProofOfDeliveryMutation>;
export type UpdateBookingProofOfDeliveryMutationOptions = Apollo.BaseMutationOptions<UpdateBookingProofOfDeliveryMutation, UpdateBookingProofOfDeliveryMutationVariables>;
export const UpdateBookingPackageOnBoardDocument = gql`
    mutation UpdateBookingPackageOnBoard($input: UpdatePackageOnBoardDtoInput!) {
  updateBookingPackageOnBoard(input: $input) {
    id
    bookingId
    booking {
      id
      status
      statusLabel
    }
    at
    by
    waitingTime
    waitingTimeCharge
    arrivedAt
    signedAt
    signedByFirstName
    signedByLastName
    driverTimeFrom
    driverTimeTo
    noteFromDriver
    eTA
    addressPackages {
      id
      packageId
      quantity
    }
  }
}
    `;
export type UpdateBookingPackageOnBoardMutationFn = Apollo.MutationFunction<UpdateBookingPackageOnBoardMutation, UpdateBookingPackageOnBoardMutationVariables>;

/**
 * __useUpdateBookingPackageOnBoardMutation__
 *
 * To run a mutation, you first call `useUpdateBookingPackageOnBoardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingPackageOnBoardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingPackageOnBoardMutation, { data, loading, error }] = useUpdateBookingPackageOnBoardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingPackageOnBoardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingPackageOnBoardMutation, UpdateBookingPackageOnBoardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingPackageOnBoardMutation, UpdateBookingPackageOnBoardMutationVariables>(UpdateBookingPackageOnBoardDocument, options);
      }
export type UpdateBookingPackageOnBoardMutationHookResult = ReturnType<typeof useUpdateBookingPackageOnBoardMutation>;
export type UpdateBookingPackageOnBoardMutationResult = Apollo.MutationResult<UpdateBookingPackageOnBoardMutation>;
export type UpdateBookingPackageOnBoardMutationOptions = Apollo.BaseMutationOptions<UpdateBookingPackageOnBoardMutation, UpdateBookingPackageOnBoardMutationVariables>;
export const CreateBookingInvoiceDocument = gql`
    mutation CreateBookingInvoice($input: CreateBookingInvoiceDtoInput!) {
  createBookingInvoice(input: $input) {
    id
    bookingId
  }
}
    `;
export type CreateBookingInvoiceMutationFn = Apollo.MutationFunction<CreateBookingInvoiceMutation, CreateBookingInvoiceMutationVariables>;

/**
 * __useCreateBookingInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateBookingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingInvoiceMutation, { data, loading, error }] = useCreateBookingInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingInvoiceMutation, CreateBookingInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingInvoiceMutation, CreateBookingInvoiceMutationVariables>(CreateBookingInvoiceDocument, options);
      }
export type CreateBookingInvoiceMutationHookResult = ReturnType<typeof useCreateBookingInvoiceMutation>;
export type CreateBookingInvoiceMutationResult = Apollo.MutationResult<CreateBookingInvoiceMutation>;
export type CreateBookingInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateBookingInvoiceMutation, CreateBookingInvoiceMutationVariables>;
export const UpdateBookingStatusDocument = gql`
    mutation UpdateBookingStatus($bookingId: Long!, $status: BookingStatus!) {
  updateBookingStatus(bookingId: $bookingId, status: $status) {
    id
    status
    statusLabel
    calculatedStatus
    ourReference
  }
}
    `;
export type UpdateBookingStatusMutationFn = Apollo.MutationFunction<UpdateBookingStatusMutation, UpdateBookingStatusMutationVariables>;

/**
 * __useUpdateBookingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBookingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingStatusMutation, { data, loading, error }] = useUpdateBookingStatusMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateBookingStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingStatusMutation, UpdateBookingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingStatusMutation, UpdateBookingStatusMutationVariables>(UpdateBookingStatusDocument, options);
      }
export type UpdateBookingStatusMutationHookResult = ReturnType<typeof useUpdateBookingStatusMutation>;
export type UpdateBookingStatusMutationResult = Apollo.MutationResult<UpdateBookingStatusMutation>;
export type UpdateBookingStatusMutationOptions = Apollo.BaseMutationOptions<UpdateBookingStatusMutation, UpdateBookingStatusMutationVariables>;
export const UpdateSequenceOrderDocument = gql`
    mutation UpdateSequenceOrder($input: UpdateSequenceOrderDtoInput!) {
  updateSequenceOrder(input: $input) {
    id
    distance
    consignmentFee
    addresses {
      id
      sequenceOrder
    }
  }
}
    `;
export type UpdateSequenceOrderMutationFn = Apollo.MutationFunction<UpdateSequenceOrderMutation, UpdateSequenceOrderMutationVariables>;

/**
 * __useUpdateSequenceOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSequenceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSequenceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSequenceOrderMutation, { data, loading, error }] = useUpdateSequenceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSequenceOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSequenceOrderMutation, UpdateSequenceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSequenceOrderMutation, UpdateSequenceOrderMutationVariables>(UpdateSequenceOrderDocument, options);
      }
export type UpdateSequenceOrderMutationHookResult = ReturnType<typeof useUpdateSequenceOrderMutation>;
export type UpdateSequenceOrderMutationResult = Apollo.MutationResult<UpdateSequenceOrderMutation>;
export type UpdateSequenceOrderMutationOptions = Apollo.BaseMutationOptions<UpdateSequenceOrderMutation, UpdateSequenceOrderMutationVariables>;
export const DeleteBookingAddressAttachmentDocument = gql`
    mutation DeleteBookingAddressAttachment($input: DeleteBookingAddressAttachmentDtoInput!) {
  deleteBookingAddressAttachment(input: $input)
}
    `;
export type DeleteBookingAddressAttachmentMutationFn = Apollo.MutationFunction<DeleteBookingAddressAttachmentMutation, DeleteBookingAddressAttachmentMutationVariables>;

/**
 * __useDeleteBookingAddressAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteBookingAddressAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookingAddressAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookingAddressAttachmentMutation, { data, loading, error }] = useDeleteBookingAddressAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBookingAddressAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookingAddressAttachmentMutation, DeleteBookingAddressAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookingAddressAttachmentMutation, DeleteBookingAddressAttachmentMutationVariables>(DeleteBookingAddressAttachmentDocument, options);
      }
export type DeleteBookingAddressAttachmentMutationHookResult = ReturnType<typeof useDeleteBookingAddressAttachmentMutation>;
export type DeleteBookingAddressAttachmentMutationResult = Apollo.MutationResult<DeleteBookingAddressAttachmentMutation>;
export type DeleteBookingAddressAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteBookingAddressAttachmentMutation, DeleteBookingAddressAttachmentMutationVariables>;
export const SendDocketDetailsDocument = gql`
    mutation sendDocketDetails($bookingId: Long!) {
  sendDocketDetailsEmail(bookingId: $bookingId)
}
    `;
export type SendDocketDetailsMutationFn = Apollo.MutationFunction<SendDocketDetailsMutation, SendDocketDetailsMutationVariables>;

/**
 * __useSendDocketDetailsMutation__
 *
 * To run a mutation, you first call `useSendDocketDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDocketDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDocketDetailsMutation, { data, loading, error }] = useSendDocketDetailsMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useSendDocketDetailsMutation(baseOptions?: Apollo.MutationHookOptions<SendDocketDetailsMutation, SendDocketDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDocketDetailsMutation, SendDocketDetailsMutationVariables>(SendDocketDetailsDocument, options);
      }
export type SendDocketDetailsMutationHookResult = ReturnType<typeof useSendDocketDetailsMutation>;
export type SendDocketDetailsMutationResult = Apollo.MutationResult<SendDocketDetailsMutation>;
export type SendDocketDetailsMutationOptions = Apollo.BaseMutationOptions<SendDocketDetailsMutation, SendDocketDetailsMutationVariables>;
export const UpdateBookingAddressEtaDocument = gql`
    mutation updateBookingAddressEta($input: UpdateAddressEtaDtoInput!) {
  updateBookingAddressEta(input: $input) {
    id
    eTA
  }
}
    `;
export type UpdateBookingAddressEtaMutationFn = Apollo.MutationFunction<UpdateBookingAddressEtaMutation, UpdateBookingAddressEtaMutationVariables>;

/**
 * __useUpdateBookingAddressEtaMutation__
 *
 * To run a mutation, you first call `useUpdateBookingAddressEtaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingAddressEtaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingAddressEtaMutation, { data, loading, error }] = useUpdateBookingAddressEtaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingAddressEtaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingAddressEtaMutation, UpdateBookingAddressEtaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingAddressEtaMutation, UpdateBookingAddressEtaMutationVariables>(UpdateBookingAddressEtaDocument, options);
      }
export type UpdateBookingAddressEtaMutationHookResult = ReturnType<typeof useUpdateBookingAddressEtaMutation>;
export type UpdateBookingAddressEtaMutationResult = Apollo.MutationResult<UpdateBookingAddressEtaMutation>;
export type UpdateBookingAddressEtaMutationOptions = Apollo.BaseMutationOptions<UpdateBookingAddressEtaMutation, UpdateBookingAddressEtaMutationVariables>;
export const SendProofOfDeliveryEmailDocument = gql`
    mutation sendProofOfDeliveryEmail($bookingId: Long!, $bookingAddressId: Long) {
  sendProofOfDeliveryEmail(
    bookingId: $bookingId
    bookingAddressId: $bookingAddressId
  )
}
    `;
export type SendProofOfDeliveryEmailMutationFn = Apollo.MutationFunction<SendProofOfDeliveryEmailMutation, SendProofOfDeliveryEmailMutationVariables>;

/**
 * __useSendProofOfDeliveryEmailMutation__
 *
 * To run a mutation, you first call `useSendProofOfDeliveryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendProofOfDeliveryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendProofOfDeliveryEmailMutation, { data, loading, error }] = useSendProofOfDeliveryEmailMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      bookingAddressId: // value for 'bookingAddressId'
 *   },
 * });
 */
export function useSendProofOfDeliveryEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendProofOfDeliveryEmailMutation, SendProofOfDeliveryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendProofOfDeliveryEmailMutation, SendProofOfDeliveryEmailMutationVariables>(SendProofOfDeliveryEmailDocument, options);
      }
export type SendProofOfDeliveryEmailMutationHookResult = ReturnType<typeof useSendProofOfDeliveryEmailMutation>;
export type SendProofOfDeliveryEmailMutationResult = Apollo.MutationResult<SendProofOfDeliveryEmailMutation>;
export type SendProofOfDeliveryEmailMutationOptions = Apollo.BaseMutationOptions<SendProofOfDeliveryEmailMutation, SendProofOfDeliveryEmailMutationVariables>;
export const UpdateAllBookingEtasDocument = gql`
    mutation updateAllBookingEtas {
  updateAllBookingEtas
}
    `;
export type UpdateAllBookingEtasMutationFn = Apollo.MutationFunction<UpdateAllBookingEtasMutation, UpdateAllBookingEtasMutationVariables>;

/**
 * __useUpdateAllBookingEtasMutation__
 *
 * To run a mutation, you first call `useUpdateAllBookingEtasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllBookingEtasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllBookingEtasMutation, { data, loading, error }] = useUpdateAllBookingEtasMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateAllBookingEtasMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAllBookingEtasMutation, UpdateAllBookingEtasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAllBookingEtasMutation, UpdateAllBookingEtasMutationVariables>(UpdateAllBookingEtasDocument, options);
      }
export type UpdateAllBookingEtasMutationHookResult = ReturnType<typeof useUpdateAllBookingEtasMutation>;
export type UpdateAllBookingEtasMutationResult = Apollo.MutationResult<UpdateAllBookingEtasMutation>;
export type UpdateAllBookingEtasMutationOptions = Apollo.BaseMutationOptions<UpdateAllBookingEtasMutation, UpdateAllBookingEtasMutationVariables>;
export const CreateQuoteLostReasonDocument = gql`
    mutation CreateQuoteLostReason($input: CreateAndUpdateQuoteLostReasonDtoInput!) {
  createQuoteLostReason(input: $input) {
    id
    name
    code
  }
}
    `;
export type CreateQuoteLostReasonMutationFn = Apollo.MutationFunction<CreateQuoteLostReasonMutation, CreateQuoteLostReasonMutationVariables>;

/**
 * __useCreateQuoteLostReasonMutation__
 *
 * To run a mutation, you first call `useCreateQuoteLostReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteLostReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteLostReasonMutation, { data, loading, error }] = useCreateQuoteLostReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuoteLostReasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteLostReasonMutation, CreateQuoteLostReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteLostReasonMutation, CreateQuoteLostReasonMutationVariables>(CreateQuoteLostReasonDocument, options);
      }
export type CreateQuoteLostReasonMutationHookResult = ReturnType<typeof useCreateQuoteLostReasonMutation>;
export type CreateQuoteLostReasonMutationResult = Apollo.MutationResult<CreateQuoteLostReasonMutation>;
export type CreateQuoteLostReasonMutationOptions = Apollo.BaseMutationOptions<CreateQuoteLostReasonMutation, CreateQuoteLostReasonMutationVariables>;
export const UpdateQuoteLostReasonDocument = gql`
    mutation UpdateQuoteLostReason($input: CreateAndUpdateQuoteLostReasonDtoInput!) {
  updateQuoteLostReason(input: $input) {
    id
    name
    code
  }
}
    `;
export type UpdateQuoteLostReasonMutationFn = Apollo.MutationFunction<UpdateQuoteLostReasonMutation, UpdateQuoteLostReasonMutationVariables>;

/**
 * __useUpdateQuoteLostReasonMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteLostReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteLostReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteLostReasonMutation, { data, loading, error }] = useUpdateQuoteLostReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteLostReasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteLostReasonMutation, UpdateQuoteLostReasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuoteLostReasonMutation, UpdateQuoteLostReasonMutationVariables>(UpdateQuoteLostReasonDocument, options);
      }
export type UpdateQuoteLostReasonMutationHookResult = ReturnType<typeof useUpdateQuoteLostReasonMutation>;
export type UpdateQuoteLostReasonMutationResult = Apollo.MutationResult<UpdateQuoteLostReasonMutation>;
export type UpdateQuoteLostReasonMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteLostReasonMutation, UpdateQuoteLostReasonMutationVariables>;
export const ProcessPotentialBookingFileDocument = gql`
    mutation ProcessPotentialBookingFile($input: ProcessPotentialBookingFileDtoInput!) {
  processPotentialBookingFile(input: $input)
}
    `;
export type ProcessPotentialBookingFileMutationFn = Apollo.MutationFunction<ProcessPotentialBookingFileMutation, ProcessPotentialBookingFileMutationVariables>;

/**
 * __useProcessPotentialBookingFileMutation__
 *
 * To run a mutation, you first call `useProcessPotentialBookingFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessPotentialBookingFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processPotentialBookingFileMutation, { data, loading, error }] = useProcessPotentialBookingFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessPotentialBookingFileMutation(baseOptions?: Apollo.MutationHookOptions<ProcessPotentialBookingFileMutation, ProcessPotentialBookingFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessPotentialBookingFileMutation, ProcessPotentialBookingFileMutationVariables>(ProcessPotentialBookingFileDocument, options);
      }
export type ProcessPotentialBookingFileMutationHookResult = ReturnType<typeof useProcessPotentialBookingFileMutation>;
export type ProcessPotentialBookingFileMutationResult = Apollo.MutationResult<ProcessPotentialBookingFileMutation>;
export type ProcessPotentialBookingFileMutationOptions = Apollo.BaseMutationOptions<ProcessPotentialBookingFileMutation, ProcessPotentialBookingFileMutationVariables>;
export const CreateCommentaryDocument = gql`
    mutation createCommentary($input: CreateUpdateCommentaryDtoInput!) {
  createCommentary(input: $input) {
    id
    ...CommentaryDetails
  }
}
    ${CommentaryDetailsFragmentDoc}`;
export type CreateCommentaryMutationFn = Apollo.MutationFunction<CreateCommentaryMutation, CreateCommentaryMutationVariables>;

/**
 * __useCreateCommentaryMutation__
 *
 * To run a mutation, you first call `useCreateCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentaryMutation, { data, loading, error }] = useCreateCommentaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentaryMutation, CreateCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentaryMutation, CreateCommentaryMutationVariables>(CreateCommentaryDocument, options);
      }
export type CreateCommentaryMutationHookResult = ReturnType<typeof useCreateCommentaryMutation>;
export type CreateCommentaryMutationResult = Apollo.MutationResult<CreateCommentaryMutation>;
export type CreateCommentaryMutationOptions = Apollo.BaseMutationOptions<CreateCommentaryMutation, CreateCommentaryMutationVariables>;
export const UpdateCommentaryDocument = gql`
    mutation updateCommentary($input: CreateUpdateCommentaryDtoInput!) {
  updateCommentary(input: $input) {
    id
    ...CommentaryDetails
  }
}
    ${CommentaryDetailsFragmentDoc}`;
export type UpdateCommentaryMutationFn = Apollo.MutationFunction<UpdateCommentaryMutation, UpdateCommentaryMutationVariables>;

/**
 * __useUpdateCommentaryMutation__
 *
 * To run a mutation, you first call `useUpdateCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentaryMutation, { data, loading, error }] = useUpdateCommentaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentaryMutation, UpdateCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentaryMutation, UpdateCommentaryMutationVariables>(UpdateCommentaryDocument, options);
      }
export type UpdateCommentaryMutationHookResult = ReturnType<typeof useUpdateCommentaryMutation>;
export type UpdateCommentaryMutationResult = Apollo.MutationResult<UpdateCommentaryMutation>;
export type UpdateCommentaryMutationOptions = Apollo.BaseMutationOptions<UpdateCommentaryMutation, UpdateCommentaryMutationVariables>;
export const DeleteCommentaryDocument = gql`
    mutation deleteCommentary($id: Long!) {
  deleteCommentary(id: $id)
}
    `;
export type DeleteCommentaryMutationFn = Apollo.MutationFunction<DeleteCommentaryMutation, DeleteCommentaryMutationVariables>;

/**
 * __useDeleteCommentaryMutation__
 *
 * To run a mutation, you first call `useDeleteCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentaryMutation, { data, loading, error }] = useDeleteCommentaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentaryMutation, DeleteCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentaryMutation, DeleteCommentaryMutationVariables>(DeleteCommentaryDocument, options);
      }
export type DeleteCommentaryMutationHookResult = ReturnType<typeof useDeleteCommentaryMutation>;
export type DeleteCommentaryMutationResult = Apollo.MutationResult<DeleteCommentaryMutation>;
export type DeleteCommentaryMutationOptions = Apollo.BaseMutationOptions<DeleteCommentaryMutation, DeleteCommentaryMutationVariables>;
export const CreateCongestionZoneDocument = gql`
    mutation CreateCongestionZone($input: CreateAndUpdateCongestionZoneDtoInput!) {
  createCongestionZone(input: $input) {
    id
    ...CongestionZoneDetails
  }
}
    ${CongestionZoneDetailsFragmentDoc}`;
export type CreateCongestionZoneMutationFn = Apollo.MutationFunction<CreateCongestionZoneMutation, CreateCongestionZoneMutationVariables>;

/**
 * __useCreateCongestionZoneMutation__
 *
 * To run a mutation, you first call `useCreateCongestionZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCongestionZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCongestionZoneMutation, { data, loading, error }] = useCreateCongestionZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCongestionZoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateCongestionZoneMutation, CreateCongestionZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCongestionZoneMutation, CreateCongestionZoneMutationVariables>(CreateCongestionZoneDocument, options);
      }
export type CreateCongestionZoneMutationHookResult = ReturnType<typeof useCreateCongestionZoneMutation>;
export type CreateCongestionZoneMutationResult = Apollo.MutationResult<CreateCongestionZoneMutation>;
export type CreateCongestionZoneMutationOptions = Apollo.BaseMutationOptions<CreateCongestionZoneMutation, CreateCongestionZoneMutationVariables>;
export const UpdateCongestionZoneDocument = gql`
    mutation UpdateCongestionZone($input: CreateAndUpdateCongestionZoneDtoInput!) {
  updateCongestionZone(input: $input) {
    id
    ...CongestionZoneDetails
  }
}
    ${CongestionZoneDetailsFragmentDoc}`;
export type UpdateCongestionZoneMutationFn = Apollo.MutationFunction<UpdateCongestionZoneMutation, UpdateCongestionZoneMutationVariables>;

/**
 * __useUpdateCongestionZoneMutation__
 *
 * To run a mutation, you first call `useUpdateCongestionZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCongestionZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCongestionZoneMutation, { data, loading, error }] = useUpdateCongestionZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCongestionZoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCongestionZoneMutation, UpdateCongestionZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCongestionZoneMutation, UpdateCongestionZoneMutationVariables>(UpdateCongestionZoneDocument, options);
      }
export type UpdateCongestionZoneMutationHookResult = ReturnType<typeof useUpdateCongestionZoneMutation>;
export type UpdateCongestionZoneMutationResult = Apollo.MutationResult<UpdateCongestionZoneMutation>;
export type UpdateCongestionZoneMutationOptions = Apollo.BaseMutationOptions<UpdateCongestionZoneMutation, UpdateCongestionZoneMutationVariables>;
export const CreateCongestionZonePostcodeDocument = gql`
    mutation CreateCongestionZonePostcode($input: CreateAndUpdateCongestionZonePostcodeDtoInput!) {
  createCongestionZonePostcode(input: $input) {
    id
    ...CongestionZonePostcodeFragment
  }
}
    ${CongestionZonePostcodeFragmentFragmentDoc}`;
export type CreateCongestionZonePostcodeMutationFn = Apollo.MutationFunction<CreateCongestionZonePostcodeMutation, CreateCongestionZonePostcodeMutationVariables>;

/**
 * __useCreateCongestionZonePostcodeMutation__
 *
 * To run a mutation, you first call `useCreateCongestionZonePostcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCongestionZonePostcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCongestionZonePostcodeMutation, { data, loading, error }] = useCreateCongestionZonePostcodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCongestionZonePostcodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateCongestionZonePostcodeMutation, CreateCongestionZonePostcodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCongestionZonePostcodeMutation, CreateCongestionZonePostcodeMutationVariables>(CreateCongestionZonePostcodeDocument, options);
      }
export type CreateCongestionZonePostcodeMutationHookResult = ReturnType<typeof useCreateCongestionZonePostcodeMutation>;
export type CreateCongestionZonePostcodeMutationResult = Apollo.MutationResult<CreateCongestionZonePostcodeMutation>;
export type CreateCongestionZonePostcodeMutationOptions = Apollo.BaseMutationOptions<CreateCongestionZonePostcodeMutation, CreateCongestionZonePostcodeMutationVariables>;
export const UpdateCongestionZonePostcodeDocument = gql`
    mutation UpdateCongestionZonePostcode($input: CreateAndUpdateCongestionZonePostcodeDtoInput!) {
  updateCongestionZonePostcode(input: $input) {
    id
    ...CongestionZonePostcodeFragment
  }
}
    ${CongestionZonePostcodeFragmentFragmentDoc}`;
export type UpdateCongestionZonePostcodeMutationFn = Apollo.MutationFunction<UpdateCongestionZonePostcodeMutation, UpdateCongestionZonePostcodeMutationVariables>;

/**
 * __useUpdateCongestionZonePostcodeMutation__
 *
 * To run a mutation, you first call `useUpdateCongestionZonePostcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCongestionZonePostcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCongestionZonePostcodeMutation, { data, loading, error }] = useUpdateCongestionZonePostcodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCongestionZonePostcodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCongestionZonePostcodeMutation, UpdateCongestionZonePostcodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCongestionZonePostcodeMutation, UpdateCongestionZonePostcodeMutationVariables>(UpdateCongestionZonePostcodeDocument, options);
      }
export type UpdateCongestionZonePostcodeMutationHookResult = ReturnType<typeof useUpdateCongestionZonePostcodeMutation>;
export type UpdateCongestionZonePostcodeMutationResult = Apollo.MutationResult<UpdateCongestionZonePostcodeMutation>;
export type UpdateCongestionZonePostcodeMutationOptions = Apollo.BaseMutationOptions<UpdateCongestionZonePostcodeMutation, UpdateCongestionZonePostcodeMutationVariables>;
export const DeleteCongestionZonePostcodeDocument = gql`
    mutation DeleteCongestionZonePostcode($id: Long!) {
  deleteCongestionZonePostcode(postcodeId: $id)
}
    `;
export type DeleteCongestionZonePostcodeMutationFn = Apollo.MutationFunction<DeleteCongestionZonePostcodeMutation, DeleteCongestionZonePostcodeMutationVariables>;

/**
 * __useDeleteCongestionZonePostcodeMutation__
 *
 * To run a mutation, you first call `useDeleteCongestionZonePostcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCongestionZonePostcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCongestionZonePostcodeMutation, { data, loading, error }] = useDeleteCongestionZonePostcodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCongestionZonePostcodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCongestionZonePostcodeMutation, DeleteCongestionZonePostcodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCongestionZonePostcodeMutation, DeleteCongestionZonePostcodeMutationVariables>(DeleteCongestionZonePostcodeDocument, options);
      }
export type DeleteCongestionZonePostcodeMutationHookResult = ReturnType<typeof useDeleteCongestionZonePostcodeMutation>;
export type DeleteCongestionZonePostcodeMutationResult = Apollo.MutationResult<DeleteCongestionZonePostcodeMutation>;
export type DeleteCongestionZonePostcodeMutationOptions = Apollo.BaseMutationOptions<DeleteCongestionZonePostcodeMutation, DeleteCongestionZonePostcodeMutationVariables>;
export const PostLoadDocument = gql`
    mutation PostLoad($input: CourierExchangeLoadDtoInput!) {
  postLoad(input: $input) {
    id
    bookingId
    loadId
    rawRequest
  }
}
    `;
export type PostLoadMutationFn = Apollo.MutationFunction<PostLoadMutation, PostLoadMutationVariables>;

/**
 * __usePostLoadMutation__
 *
 * To run a mutation, you first call `usePostLoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostLoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postLoadMutation, { data, loading, error }] = usePostLoadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostLoadMutation(baseOptions?: Apollo.MutationHookOptions<PostLoadMutation, PostLoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostLoadMutation, PostLoadMutationVariables>(PostLoadDocument, options);
      }
export type PostLoadMutationHookResult = ReturnType<typeof usePostLoadMutation>;
export type PostLoadMutationResult = Apollo.MutationResult<PostLoadMutation>;
export type PostLoadMutationOptions = Apollo.BaseMutationOptions<PostLoadMutation, PostLoadMutationVariables>;
export const LoadQuotesDocument = gql`
    mutation LoadQuotes($loadId: Long!) {
  loadQuotes(loadId: $loadId) {
    id
    loadId
    quoteId
    price
    currency
    quoteOwnerCompanyId
    quoteOwnerCompanyName
    quoteOwnerDriverName
    totalPrice
    createdOn
    status
    notes
    isDriverInSystem
    isDriverUsingOurApp
  }
}
    `;
export type LoadQuotesMutationFn = Apollo.MutationFunction<LoadQuotesMutation, LoadQuotesMutationVariables>;

/**
 * __useLoadQuotesMutation__
 *
 * To run a mutation, you first call `useLoadQuotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadQuotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadQuotesMutation, { data, loading, error }] = useLoadQuotesMutation({
 *   variables: {
 *      loadId: // value for 'loadId'
 *   },
 * });
 */
export function useLoadQuotesMutation(baseOptions?: Apollo.MutationHookOptions<LoadQuotesMutation, LoadQuotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoadQuotesMutation, LoadQuotesMutationVariables>(LoadQuotesDocument, options);
      }
export type LoadQuotesMutationHookResult = ReturnType<typeof useLoadQuotesMutation>;
export type LoadQuotesMutationResult = Apollo.MutationResult<LoadQuotesMutation>;
export type LoadQuotesMutationOptions = Apollo.BaseMutationOptions<LoadQuotesMutation, LoadQuotesMutationVariables>;
export const BookLoadDocument = gql`
    mutation BookLoad($input: CourierExchangeBookLoadDtoInput!) {
  bookLoad(input: $input) {
    id
    bookingId
    booking {
      id
      takenOn
      takenBy
    }
  }
}
    `;
export type BookLoadMutationFn = Apollo.MutationFunction<BookLoadMutation, BookLoadMutationVariables>;

/**
 * __useBookLoadMutation__
 *
 * To run a mutation, you first call `useBookLoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookLoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookLoadMutation, { data, loading, error }] = useBookLoadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookLoadMutation(baseOptions?: Apollo.MutationHookOptions<BookLoadMutation, BookLoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BookLoadMutation, BookLoadMutationVariables>(BookLoadDocument, options);
      }
export type BookLoadMutationHookResult = ReturnType<typeof useBookLoadMutation>;
export type BookLoadMutationResult = Apollo.MutationResult<BookLoadMutation>;
export type BookLoadMutationOptions = Apollo.BaseMutationOptions<BookLoadMutation, BookLoadMutationVariables>;
export const UpdateBookingStatusFromCourierExchangeDocument = gql`
    mutation UpdateBookingStatusFromCourierExchange($loadId: Long!) {
  updateBookingStatusFromCourierExchange(loadId: $loadId) {
    id
    ...BookingDetails
    ...BookingAddresses
  }
}
    ${BookingDetailsFragmentDoc}
${BookingAddressesFragmentDoc}`;
export type UpdateBookingStatusFromCourierExchangeMutationFn = Apollo.MutationFunction<UpdateBookingStatusFromCourierExchangeMutation, UpdateBookingStatusFromCourierExchangeMutationVariables>;

/**
 * __useUpdateBookingStatusFromCourierExchangeMutation__
 *
 * To run a mutation, you first call `useUpdateBookingStatusFromCourierExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingStatusFromCourierExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingStatusFromCourierExchangeMutation, { data, loading, error }] = useUpdateBookingStatusFromCourierExchangeMutation({
 *   variables: {
 *      loadId: // value for 'loadId'
 *   },
 * });
 */
export function useUpdateBookingStatusFromCourierExchangeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingStatusFromCourierExchangeMutation, UpdateBookingStatusFromCourierExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingStatusFromCourierExchangeMutation, UpdateBookingStatusFromCourierExchangeMutationVariables>(UpdateBookingStatusFromCourierExchangeDocument, options);
      }
export type UpdateBookingStatusFromCourierExchangeMutationHookResult = ReturnType<typeof useUpdateBookingStatusFromCourierExchangeMutation>;
export type UpdateBookingStatusFromCourierExchangeMutationResult = Apollo.MutationResult<UpdateBookingStatusFromCourierExchangeMutation>;
export type UpdateBookingStatusFromCourierExchangeMutationOptions = Apollo.BaseMutationOptions<UpdateBookingStatusFromCourierExchangeMutation, UpdateBookingStatusFromCourierExchangeMutationVariables>;
export const UpdateBookingListStatusFromCourierExchangeDocument = gql`
    mutation UpdateBookingListStatusFromCourierExchange {
  updateBookingListStatusFromCourierExchange
}
    `;
export type UpdateBookingListStatusFromCourierExchangeMutationFn = Apollo.MutationFunction<UpdateBookingListStatusFromCourierExchangeMutation, UpdateBookingListStatusFromCourierExchangeMutationVariables>;

/**
 * __useUpdateBookingListStatusFromCourierExchangeMutation__
 *
 * To run a mutation, you first call `useUpdateBookingListStatusFromCourierExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingListStatusFromCourierExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingListStatusFromCourierExchangeMutation, { data, loading, error }] = useUpdateBookingListStatusFromCourierExchangeMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateBookingListStatusFromCourierExchangeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingListStatusFromCourierExchangeMutation, UpdateBookingListStatusFromCourierExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingListStatusFromCourierExchangeMutation, UpdateBookingListStatusFromCourierExchangeMutationVariables>(UpdateBookingListStatusFromCourierExchangeDocument, options);
      }
export type UpdateBookingListStatusFromCourierExchangeMutationHookResult = ReturnType<typeof useUpdateBookingListStatusFromCourierExchangeMutation>;
export type UpdateBookingListStatusFromCourierExchangeMutationResult = Apollo.MutationResult<UpdateBookingListStatusFromCourierExchangeMutation>;
export type UpdateBookingListStatusFromCourierExchangeMutationOptions = Apollo.BaseMutationOptions<UpdateBookingListStatusFromCourierExchangeMutation, UpdateBookingListStatusFromCourierExchangeMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: CreateAndUpdateCustomerDtoInput!) {
  createCustomer(input: $input) {
    ...CustomerDetails
  }
}
    ${CustomerDetailsFragmentDoc}`;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: CreateAndUpdateCustomerDtoInput!) {
  updateCustomer(input: $input) {
    ...CustomerDetails
  }
}
    ${CustomerDetailsFragmentDoc}`;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($customerId: Long!) {
  deleteCustomer(customerId: $customerId)
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const CreateAddressDocument = gql`
    mutation createAddress($input: CreateAndUpdateAddressDtoInput!) {
  createAddress(input: $input) {
    id
    name
    city
    addressLine1
    addressLine2
    postcode
    email
    countryId
    telephoneNumber
    contact
    isNotification
    isAutoFill
    isDefault
  }
}
    `;
export type CreateAddressMutationFn = Apollo.MutationFunction<CreateAddressMutation, CreateAddressMutationVariables>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateAddressMutation, CreateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CreateAddressDocument, options);
      }
export type CreateAddressMutationHookResult = ReturnType<typeof useCreateAddressMutation>;
export type CreateAddressMutationResult = Apollo.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<CreateAddressMutation, CreateAddressMutationVariables>;
export const UpdateAddressDocument = gql`
    mutation updateAddress($input: CreateAndUpdateAddressDtoInput!) {
  updateAddress(input: $input) {
    id
    name
    city
    addressLine1
    addressLine2
    postcode
    email
    countryId
    telephoneNumber
    contact
    isNotification
    isAutoFill
    isDefault
  }
}
    `;
export type UpdateAddressMutationFn = Apollo.MutationFunction<UpdateAddressMutation, UpdateAddressMutationVariables>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, options);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export type UpdateAddressMutationResult = Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAddressMutation, UpdateAddressMutationVariables>;
export const DeleteAddressDocument = gql`
    mutation DeleteAddress($customerId: Long!, $addressId: Long!) {
  deleteAddress(addressId: $addressId, customerId: $customerId) {
    id
    name
    customerId
  }
}
    `;
export type DeleteAddressMutationFn = Apollo.MutationFunction<DeleteAddressMutation, DeleteAddressMutationVariables>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(DeleteAddressDocument, options);
      }
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<DeleteAddressMutation, DeleteAddressMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($input: CreateAndUpdateContactDtoInput!) {
  createContact(input: $input) {
    id
    name
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($input: CreateAndUpdateContactDtoInput!) {
  updateContact(input: $input) {
    id
    name
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation deleteContact($contactId: Long!) {
  deleteContact(contactId: $contactId)
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const DeleteCustomerLogoDocument = gql`
    mutation deleteCustomerLogo($customerId: Long!) {
  deleteCustomerLogo(customerId: $customerId)
}
    `;
export type DeleteCustomerLogoMutationFn = Apollo.MutationFunction<DeleteCustomerLogoMutation, DeleteCustomerLogoMutationVariables>;

/**
 * __useDeleteCustomerLogoMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerLogoMutation, { data, loading, error }] = useDeleteCustomerLogoMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useDeleteCustomerLogoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerLogoMutation, DeleteCustomerLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerLogoMutation, DeleteCustomerLogoMutationVariables>(DeleteCustomerLogoDocument, options);
      }
export type DeleteCustomerLogoMutationHookResult = ReturnType<typeof useDeleteCustomerLogoMutation>;
export type DeleteCustomerLogoMutationResult = Apollo.MutationResult<DeleteCustomerLogoMutation>;
export type DeleteCustomerLogoMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerLogoMutation, DeleteCustomerLogoMutationVariables>;
export const UpdateCustomerNotificationsDocument = gql`
    mutation updateCustomerNotifications($input: UpdateCustomerNotificationsDtoInput!) {
  updateCustomerNotifications(input: $input) {
    id
    isNotificationForQuote
    isNotificationForReadyForAllocation
    isNotificationForBooking
    isNotificationForPobArrival
    isNotificationForPOB
    isNotificationForPodArrival
    isNotificationForPodPartial
    isNotificationForPOD
    isNotificationForCancellation
    isNotificationForCarded
    isNotificationForAmendment
    isNotificationForDriverAllocated
    isNotificationForInvoice
    isNotificationForInvoiceDue
    customerInvoiceNotificationType
  }
}
    `;
export type UpdateCustomerNotificationsMutationFn = Apollo.MutationFunction<UpdateCustomerNotificationsMutation, UpdateCustomerNotificationsMutationVariables>;

/**
 * __useUpdateCustomerNotificationsMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerNotificationsMutation, { data, loading, error }] = useUpdateCustomerNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerNotificationsMutation, UpdateCustomerNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerNotificationsMutation, UpdateCustomerNotificationsMutationVariables>(UpdateCustomerNotificationsDocument, options);
      }
export type UpdateCustomerNotificationsMutationHookResult = ReturnType<typeof useUpdateCustomerNotificationsMutation>;
export type UpdateCustomerNotificationsMutationResult = Apollo.MutationResult<UpdateCustomerNotificationsMutation>;
export type UpdateCustomerNotificationsMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerNotificationsMutation, UpdateCustomerNotificationsMutationVariables>;
export const UpdateCustomerReferencesAndNotesDocument = gql`
    mutation updateCustomerReferencesAndNotes($input: UpdateCustomerReferencesAndNotesDtoInput!) {
  updateCustomerReferencesAndNotes(input: $input) {
    id
    customerReferenceLabel
    isCustomerReferenceMandatory
    isPaperworkRequired
    noteForAccount
    noteForBooking
    instructionsForCourierExchangeDriver
    noteForPackage
    noteForPaperwork
  }
}
    `;
export type UpdateCustomerReferencesAndNotesMutationFn = Apollo.MutationFunction<UpdateCustomerReferencesAndNotesMutation, UpdateCustomerReferencesAndNotesMutationVariables>;

/**
 * __useUpdateCustomerReferencesAndNotesMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerReferencesAndNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerReferencesAndNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerReferencesAndNotesMutation, { data, loading, error }] = useUpdateCustomerReferencesAndNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerReferencesAndNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerReferencesAndNotesMutation, UpdateCustomerReferencesAndNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerReferencesAndNotesMutation, UpdateCustomerReferencesAndNotesMutationVariables>(UpdateCustomerReferencesAndNotesDocument, options);
      }
export type UpdateCustomerReferencesAndNotesMutationHookResult = ReturnType<typeof useUpdateCustomerReferencesAndNotesMutation>;
export type UpdateCustomerReferencesAndNotesMutationResult = Apollo.MutationResult<UpdateCustomerReferencesAndNotesMutation>;
export type UpdateCustomerReferencesAndNotesMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerReferencesAndNotesMutation, UpdateCustomerReferencesAndNotesMutationVariables>;
export const DeleteCustomerTariffDocument = gql`
    mutation DeleteCustomerTariff($customerId: Long!, $tariffId: Long!) {
  deleteCustomerTariff(customerId: $customerId, tariffId: $tariffId) {
    id
    name
    tariffs {
      id
      customerId
      tariffId
    }
  }
}
    `;
export type DeleteCustomerTariffMutationFn = Apollo.MutationFunction<DeleteCustomerTariffMutation, DeleteCustomerTariffMutationVariables>;

/**
 * __useDeleteCustomerTariffMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerTariffMutation, { data, loading, error }] = useDeleteCustomerTariffMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      tariffId: // value for 'tariffId'
 *   },
 * });
 */
export function useDeleteCustomerTariffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerTariffMutation, DeleteCustomerTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerTariffMutation, DeleteCustomerTariffMutationVariables>(DeleteCustomerTariffDocument, options);
      }
export type DeleteCustomerTariffMutationHookResult = ReturnType<typeof useDeleteCustomerTariffMutation>;
export type DeleteCustomerTariffMutationResult = Apollo.MutationResult<DeleteCustomerTariffMutation>;
export type DeleteCustomerTariffMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerTariffMutation, DeleteCustomerTariffMutationVariables>;
export const AllocateDriverBidsDocument = gql`
    mutation AllocateDriverBids($ids: [Long!]!) {
  allocateDriverBids(bidIds: $ids) {
    id
    isAllocated
    isEnabled
  }
}
    `;
export type AllocateDriverBidsMutationFn = Apollo.MutationFunction<AllocateDriverBidsMutation, AllocateDriverBidsMutationVariables>;

/**
 * __useAllocateDriverBidsMutation__
 *
 * To run a mutation, you first call `useAllocateDriverBidsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateDriverBidsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateDriverBidsMutation, { data, loading, error }] = useAllocateDriverBidsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAllocateDriverBidsMutation(baseOptions?: Apollo.MutationHookOptions<AllocateDriverBidsMutation, AllocateDriverBidsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AllocateDriverBidsMutation, AllocateDriverBidsMutationVariables>(AllocateDriverBidsDocument, options);
      }
export type AllocateDriverBidsMutationHookResult = ReturnType<typeof useAllocateDriverBidsMutation>;
export type AllocateDriverBidsMutationResult = Apollo.MutationResult<AllocateDriverBidsMutation>;
export type AllocateDriverBidsMutationOptions = Apollo.BaseMutationOptions<AllocateDriverBidsMutation, AllocateDriverBidsMutationVariables>;
export const CreateDriverDocument = gql`
    mutation CreateDriver($input: CreateAndUpdateDriverDtoInput!) {
  createDriver(input: $input) {
    ...DriverDetails
  }
}
    ${DriverDetailsFragmentDoc}`;
export type CreateDriverMutationFn = Apollo.MutationFunction<CreateDriverMutation, CreateDriverMutationVariables>;

/**
 * __useCreateDriverMutation__
 *
 * To run a mutation, you first call `useCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverMutation, { data, loading, error }] = useCreateDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverMutation(baseOptions?: Apollo.MutationHookOptions<CreateDriverMutation, CreateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDriverMutation, CreateDriverMutationVariables>(CreateDriverDocument, options);
      }
export type CreateDriverMutationHookResult = ReturnType<typeof useCreateDriverMutation>;
export type CreateDriverMutationResult = Apollo.MutationResult<CreateDriverMutation>;
export type CreateDriverMutationOptions = Apollo.BaseMutationOptions<CreateDriverMutation, CreateDriverMutationVariables>;
export const UpdateDriverDocument = gql`
    mutation UpdateDriver($input: CreateAndUpdateDriverDtoInput!) {
  updateDriver(input: $input) {
    ...DriverDetails
  }
}
    ${DriverDetailsFragmentDoc}`;
export type UpdateDriverMutationFn = Apollo.MutationFunction<UpdateDriverMutation, UpdateDriverMutationVariables>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriverMutation, UpdateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriverMutation, UpdateDriverMutationVariables>(UpdateDriverDocument, options);
      }
export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = Apollo.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<UpdateDriverMutation, UpdateDriverMutationVariables>;
export const DeleteDriverDocument = gql`
    mutation DeleteDriver($driverId: Long!) {
  deleteDriver(driverId: $driverId)
}
    `;
export type DeleteDriverMutationFn = Apollo.MutationFunction<DeleteDriverMutation, DeleteDriverMutationVariables>;

/**
 * __useDeleteDriverMutation__
 *
 * To run a mutation, you first call `useDeleteDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverMutation, { data, loading, error }] = useDeleteDriverMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useDeleteDriverMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDriverMutation, DeleteDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDriverMutation, DeleteDriverMutationVariables>(DeleteDriverDocument, options);
      }
export type DeleteDriverMutationHookResult = ReturnType<typeof useDeleteDriverMutation>;
export type DeleteDriverMutationResult = Apollo.MutationResult<DeleteDriverMutation>;
export type DeleteDriverMutationOptions = Apollo.BaseMutationOptions<DeleteDriverMutation, DeleteDriverMutationVariables>;
export const CreateSubDriverDocument = gql`
    mutation CreateSubDriver($input: CreateAndUpdateSubDriverDtoInput!) {
  createSubDriver(input: $input) {
    id
    primaryDriverId
    callSign
    driverType
    email
    isActive
    xeroCodeId
    name
    telephoneNumber
    xeroPurchaseCode
  }
}
    `;
export type CreateSubDriverMutationFn = Apollo.MutationFunction<CreateSubDriverMutation, CreateSubDriverMutationVariables>;

/**
 * __useCreateSubDriverMutation__
 *
 * To run a mutation, you first call `useCreateSubDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubDriverMutation, { data, loading, error }] = useCreateSubDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubDriverMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubDriverMutation, CreateSubDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubDriverMutation, CreateSubDriverMutationVariables>(CreateSubDriverDocument, options);
      }
export type CreateSubDriverMutationHookResult = ReturnType<typeof useCreateSubDriverMutation>;
export type CreateSubDriverMutationResult = Apollo.MutationResult<CreateSubDriverMutation>;
export type CreateSubDriverMutationOptions = Apollo.BaseMutationOptions<CreateSubDriverMutation, CreateSubDriverMutationVariables>;
export const UpdateSubDriverDocument = gql`
    mutation UpdateSubDriver($input: CreateAndUpdateSubDriverDtoInput!) {
  updateSubDriver(input: $input) {
    id
    primaryDriverId
    callSign
    driverType
    email
    isActive
    xeroCodeId
    name
    telephoneNumber
    xeroPurchaseCode
  }
}
    `;
export type UpdateSubDriverMutationFn = Apollo.MutationFunction<UpdateSubDriverMutation, UpdateSubDriverMutationVariables>;

/**
 * __useUpdateSubDriverMutation__
 *
 * To run a mutation, you first call `useUpdateSubDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubDriverMutation, { data, loading, error }] = useUpdateSubDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubDriverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubDriverMutation, UpdateSubDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubDriverMutation, UpdateSubDriverMutationVariables>(UpdateSubDriverDocument, options);
      }
export type UpdateSubDriverMutationHookResult = ReturnType<typeof useUpdateSubDriverMutation>;
export type UpdateSubDriverMutationResult = Apollo.MutationResult<UpdateSubDriverMutation>;
export type UpdateSubDriverMutationOptions = Apollo.BaseMutationOptions<UpdateSubDriverMutation, UpdateSubDriverMutationVariables>;
export const CreateDriverContactDocument = gql`
    mutation CreateDriverContact($input: CreateAndUpdateDriverContactDtoInput!) {
  createDriverContact(input: $input) {
    id
    driverId
    name
    isDefault
    telephoneNumber
    email
    additionalEmails
    department
    jobRole
  }
}
    `;
export type CreateDriverContactMutationFn = Apollo.MutationFunction<CreateDriverContactMutation, CreateDriverContactMutationVariables>;

/**
 * __useCreateDriverContactMutation__
 *
 * To run a mutation, you first call `useCreateDriverContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverContactMutation, { data, loading, error }] = useCreateDriverContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateDriverContactMutation, CreateDriverContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDriverContactMutation, CreateDriverContactMutationVariables>(CreateDriverContactDocument, options);
      }
export type CreateDriverContactMutationHookResult = ReturnType<typeof useCreateDriverContactMutation>;
export type CreateDriverContactMutationResult = Apollo.MutationResult<CreateDriverContactMutation>;
export type CreateDriverContactMutationOptions = Apollo.BaseMutationOptions<CreateDriverContactMutation, CreateDriverContactMutationVariables>;
export const UpdateDriverContactDocument = gql`
    mutation UpdateDriverContact($input: CreateAndUpdateDriverContactDtoInput!) {
  updateDriverContact(input: $input) {
    id
    driverId
    name
    isDefault
    telephoneNumber
    email
    additionalEmails
    department
    jobRole
  }
}
    `;
export type UpdateDriverContactMutationFn = Apollo.MutationFunction<UpdateDriverContactMutation, UpdateDriverContactMutationVariables>;

/**
 * __useUpdateDriverContactMutation__
 *
 * To run a mutation, you first call `useUpdateDriverContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverContactMutation, { data, loading, error }] = useUpdateDriverContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriverContactMutation, UpdateDriverContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriverContactMutation, UpdateDriverContactMutationVariables>(UpdateDriverContactDocument, options);
      }
export type UpdateDriverContactMutationHookResult = ReturnType<typeof useUpdateDriverContactMutation>;
export type UpdateDriverContactMutationResult = Apollo.MutationResult<UpdateDriverContactMutation>;
export type UpdateDriverContactMutationOptions = Apollo.BaseMutationOptions<UpdateDriverContactMutation, UpdateDriverContactMutationVariables>;
export const DeleteDriverContactDocument = gql`
    mutation DeleteDriverContact($driverContactId: Long!) {
  deleteDriverContact(driverContactId: $driverContactId)
}
    `;
export type DeleteDriverContactMutationFn = Apollo.MutationFunction<DeleteDriverContactMutation, DeleteDriverContactMutationVariables>;

/**
 * __useDeleteDriverContactMutation__
 *
 * To run a mutation, you first call `useDeleteDriverContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverContactMutation, { data, loading, error }] = useDeleteDriverContactMutation({
 *   variables: {
 *      driverContactId: // value for 'driverContactId'
 *   },
 * });
 */
export function useDeleteDriverContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDriverContactMutation, DeleteDriverContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDriverContactMutation, DeleteDriverContactMutationVariables>(DeleteDriverContactDocument, options);
      }
export type DeleteDriverContactMutationHookResult = ReturnType<typeof useDeleteDriverContactMutation>;
export type DeleteDriverContactMutationResult = Apollo.MutationResult<DeleteDriverContactMutation>;
export type DeleteDriverContactMutationOptions = Apollo.BaseMutationOptions<DeleteDriverContactMutation, DeleteDriverContactMutationVariables>;
export const SendTestNotificationDocument = gql`
    mutation SendTestNotification($driverId: Long!, $title: String!, $message: String!, $subtitle: String!, $accessToken: String) {
  testPushNotification(
    driverId: $driverId
    title: $title
    message: $message
    subtitle: $subtitle
    accessToken: $accessToken
  )
}
    `;
export type SendTestNotificationMutationFn = Apollo.MutationFunction<SendTestNotificationMutation, SendTestNotificationMutationVariables>;

/**
 * __useSendTestNotificationMutation__
 *
 * To run a mutation, you first call `useSendTestNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTestNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTestNotificationMutation, { data, loading, error }] = useSendTestNotificationMutation({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      title: // value for 'title'
 *      message: // value for 'message'
 *      subtitle: // value for 'subtitle'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSendTestNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendTestNotificationMutation, SendTestNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTestNotificationMutation, SendTestNotificationMutationVariables>(SendTestNotificationDocument, options);
      }
export type SendTestNotificationMutationHookResult = ReturnType<typeof useSendTestNotificationMutation>;
export type SendTestNotificationMutationResult = Apollo.MutationResult<SendTestNotificationMutation>;
export type SendTestNotificationMutationOptions = Apollo.BaseMutationOptions<SendTestNotificationMutation, SendTestNotificationMutationVariables>;
export const UpdateBookingDriverRatingDocument = gql`
    mutation updateBookingDriverRating($input: BookingDriverRatingDtoInput!) {
  updateDriverRating(input: $input) {
    id
    bookingId
    rating
    ratingComment
    driver {
      id
      averageRating
    }
  }
}
    `;
export type UpdateBookingDriverRatingMutationFn = Apollo.MutationFunction<UpdateBookingDriverRatingMutation, UpdateBookingDriverRatingMutationVariables>;

/**
 * __useUpdateBookingDriverRatingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingDriverRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingDriverRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingDriverRatingMutation, { data, loading, error }] = useUpdateBookingDriverRatingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingDriverRatingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBookingDriverRatingMutation, UpdateBookingDriverRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBookingDriverRatingMutation, UpdateBookingDriverRatingMutationVariables>(UpdateBookingDriverRatingDocument, options);
      }
export type UpdateBookingDriverRatingMutationHookResult = ReturnType<typeof useUpdateBookingDriverRatingMutation>;
export type UpdateBookingDriverRatingMutationResult = Apollo.MutationResult<UpdateBookingDriverRatingMutation>;
export type UpdateBookingDriverRatingMutationOptions = Apollo.BaseMutationOptions<UpdateBookingDriverRatingMutation, UpdateBookingDriverRatingMutationVariables>;
export const UpdateEmailTemplateDocument = gql`
    mutation UpdateEmailTemplate($input: UpdateEmailTemplateInput!) {
  updateEmailTemplate(input: $input) {
    id
    ...EmailTemplateDetails
  }
}
    ${EmailTemplateDetailsFragmentDoc}`;
export type UpdateEmailTemplateMutationFn = Apollo.MutationFunction<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>;

/**
 * __useUpdateEmailTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailTemplateMutation, { data, loading, error }] = useUpdateEmailTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>(UpdateEmailTemplateDocument, options);
      }
export type UpdateEmailTemplateMutationHookResult = ReturnType<typeof useUpdateEmailTemplateMutation>;
export type UpdateEmailTemplateMutationResult = Apollo.MutationResult<UpdateEmailTemplateMutation>;
export type UpdateEmailTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>;
export const TestSendingEmailToCustomerDocument = gql`
    mutation TestSendingEmailToCustomer($type: EmailTemplateType, $bookingId: Long, $bookingAddressId: Long) {
  testSendingEmailToCustomer(
    type: $type
    bookingId: $bookingId
    bookingAddressId: $bookingAddressId
  )
}
    `;
export type TestSendingEmailToCustomerMutationFn = Apollo.MutationFunction<TestSendingEmailToCustomerMutation, TestSendingEmailToCustomerMutationVariables>;

/**
 * __useTestSendingEmailToCustomerMutation__
 *
 * To run a mutation, you first call `useTestSendingEmailToCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestSendingEmailToCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testSendingEmailToCustomerMutation, { data, loading, error }] = useTestSendingEmailToCustomerMutation({
 *   variables: {
 *      type: // value for 'type'
 *      bookingId: // value for 'bookingId'
 *      bookingAddressId: // value for 'bookingAddressId'
 *   },
 * });
 */
export function useTestSendingEmailToCustomerMutation(baseOptions?: Apollo.MutationHookOptions<TestSendingEmailToCustomerMutation, TestSendingEmailToCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestSendingEmailToCustomerMutation, TestSendingEmailToCustomerMutationVariables>(TestSendingEmailToCustomerDocument, options);
      }
export type TestSendingEmailToCustomerMutationHookResult = ReturnType<typeof useTestSendingEmailToCustomerMutation>;
export type TestSendingEmailToCustomerMutationResult = Apollo.MutationResult<TestSendingEmailToCustomerMutation>;
export type TestSendingEmailToCustomerMutationOptions = Apollo.BaseMutationOptions<TestSendingEmailToCustomerMutation, TestSendingEmailToCustomerMutationVariables>;
export const TestSendingEmailToDriverDocument = gql`
    mutation TestSendingEmailToDriver($type: EmailTemplateType, $bookingId: Long, $driverId: Long) {
  testSendingEmailToDriver(
    type: $type
    bookingId: $bookingId
    driverId: $driverId
  )
}
    `;
export type TestSendingEmailToDriverMutationFn = Apollo.MutationFunction<TestSendingEmailToDriverMutation, TestSendingEmailToDriverMutationVariables>;

/**
 * __useTestSendingEmailToDriverMutation__
 *
 * To run a mutation, you first call `useTestSendingEmailToDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestSendingEmailToDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testSendingEmailToDriverMutation, { data, loading, error }] = useTestSendingEmailToDriverMutation({
 *   variables: {
 *      type: // value for 'type'
 *      bookingId: // value for 'bookingId'
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useTestSendingEmailToDriverMutation(baseOptions?: Apollo.MutationHookOptions<TestSendingEmailToDriverMutation, TestSendingEmailToDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestSendingEmailToDriverMutation, TestSendingEmailToDriverMutationVariables>(TestSendingEmailToDriverDocument, options);
      }
export type TestSendingEmailToDriverMutationHookResult = ReturnType<typeof useTestSendingEmailToDriverMutation>;
export type TestSendingEmailToDriverMutationResult = Apollo.MutationResult<TestSendingEmailToDriverMutation>;
export type TestSendingEmailToDriverMutationOptions = Apollo.BaseMutationOptions<TestSendingEmailToDriverMutation, TestSendingEmailToDriverMutationVariables>;
export const RefreshExchangeRatesDocument = gql`
    mutation refreshExchangeRates {
  refreshExchangeRates {
    id
    sourceCurrencyId
    targetCurrencyId
  }
}
    `;
export type RefreshExchangeRatesMutationFn = Apollo.MutationFunction<RefreshExchangeRatesMutation, RefreshExchangeRatesMutationVariables>;

/**
 * __useRefreshExchangeRatesMutation__
 *
 * To run a mutation, you first call `useRefreshExchangeRatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshExchangeRatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshExchangeRatesMutation, { data, loading, error }] = useRefreshExchangeRatesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshExchangeRatesMutation(baseOptions?: Apollo.MutationHookOptions<RefreshExchangeRatesMutation, RefreshExchangeRatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshExchangeRatesMutation, RefreshExchangeRatesMutationVariables>(RefreshExchangeRatesDocument, options);
      }
export type RefreshExchangeRatesMutationHookResult = ReturnType<typeof useRefreshExchangeRatesMutation>;
export type RefreshExchangeRatesMutationResult = Apollo.MutationResult<RefreshExchangeRatesMutation>;
export type RefreshExchangeRatesMutationOptions = Apollo.BaseMutationOptions<RefreshExchangeRatesMutation, RefreshExchangeRatesMutationVariables>;
export const RunExchangeRateServiceDocument = gql`
    mutation runExchangeRateService {
  runExchangeRateService
}
    `;
export type RunExchangeRateServiceMutationFn = Apollo.MutationFunction<RunExchangeRateServiceMutation, RunExchangeRateServiceMutationVariables>;

/**
 * __useRunExchangeRateServiceMutation__
 *
 * To run a mutation, you first call `useRunExchangeRateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunExchangeRateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runExchangeRateServiceMutation, { data, loading, error }] = useRunExchangeRateServiceMutation({
 *   variables: {
 *   },
 * });
 */
export function useRunExchangeRateServiceMutation(baseOptions?: Apollo.MutationHookOptions<RunExchangeRateServiceMutation, RunExchangeRateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunExchangeRateServiceMutation, RunExchangeRateServiceMutationVariables>(RunExchangeRateServiceDocument, options);
      }
export type RunExchangeRateServiceMutationHookResult = ReturnType<typeof useRunExchangeRateServiceMutation>;
export type RunExchangeRateServiceMutationResult = Apollo.MutationResult<RunExchangeRateServiceMutation>;
export type RunExchangeRateServiceMutationOptions = Apollo.BaseMutationOptions<RunExchangeRateServiceMutation, RunExchangeRateServiceMutationVariables>;
export const ReadNotificationDocument = gql`
    mutation ReadNotification($id: Long!) {
  readNotification(id: $id) {
    id
    isRead
  }
}
    `;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation DeleteNotification($id: Long!) {
  deleteNotification(id: $id)
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const CreateOrUpdateInstallationDocument = gql`
    mutation CreateOrUpdateInstallation($input: SetPushNotificationTokenDtoInput!) {
  createOrUpdateInstallation(input: $input)
}
    `;
export type CreateOrUpdateInstallationMutationFn = Apollo.MutationFunction<CreateOrUpdateInstallationMutation, CreateOrUpdateInstallationMutationVariables>;

/**
 * __useCreateOrUpdateInstallationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateInstallationMutation, { data, loading, error }] = useCreateOrUpdateInstallationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateInstallationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateInstallationMutation, CreateOrUpdateInstallationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateInstallationMutation, CreateOrUpdateInstallationMutationVariables>(CreateOrUpdateInstallationDocument, options);
      }
export type CreateOrUpdateInstallationMutationHookResult = ReturnType<typeof useCreateOrUpdateInstallationMutation>;
export type CreateOrUpdateInstallationMutationResult = Apollo.MutationResult<CreateOrUpdateInstallationMutation>;
export type CreateOrUpdateInstallationMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateInstallationMutation, CreateOrUpdateInstallationMutationVariables>;
export const DeleteInstallationByIdDocument = gql`
    mutation DeleteInstallationById($installationId: Long!) {
  deleteInstallationById(installationId: $installationId)
}
    `;
export type DeleteInstallationByIdMutationFn = Apollo.MutationFunction<DeleteInstallationByIdMutation, DeleteInstallationByIdMutationVariables>;

/**
 * __useDeleteInstallationByIdMutation__
 *
 * To run a mutation, you first call `useDeleteInstallationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstallationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstallationByIdMutation, { data, loading, error }] = useDeleteInstallationByIdMutation({
 *   variables: {
 *      installationId: // value for 'installationId'
 *   },
 * });
 */
export function useDeleteInstallationByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstallationByIdMutation, DeleteInstallationByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstallationByIdMutation, DeleteInstallationByIdMutationVariables>(DeleteInstallationByIdDocument, options);
      }
export type DeleteInstallationByIdMutationHookResult = ReturnType<typeof useDeleteInstallationByIdMutation>;
export type DeleteInstallationByIdMutationResult = Apollo.MutationResult<DeleteInstallationByIdMutation>;
export type DeleteInstallationByIdMutationOptions = Apollo.BaseMutationOptions<DeleteInstallationByIdMutation, DeleteInstallationByIdMutationVariables>;
export const CreateOpenQueryDocument = gql`
    mutation createOpenQuery($input: CreateUpdateOpenQueryDtoInput!, $emailOptions: OpenQueryEmailOptionsDtoInput!) {
  createOpenQuery(input: $input, emailOptions: $emailOptions) {
    accountPayableRecordId
    reason
  }
}
    `;
export type CreateOpenQueryMutationFn = Apollo.MutationFunction<CreateOpenQueryMutation, CreateOpenQueryMutationVariables>;

/**
 * __useCreateOpenQueryMutation__
 *
 * To run a mutation, you first call `useCreateOpenQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenQueryMutation, { data, loading, error }] = useCreateOpenQueryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      emailOptions: // value for 'emailOptions'
 *   },
 * });
 */
export function useCreateOpenQueryMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpenQueryMutation, CreateOpenQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOpenQueryMutation, CreateOpenQueryMutationVariables>(CreateOpenQueryDocument, options);
      }
export type CreateOpenQueryMutationHookResult = ReturnType<typeof useCreateOpenQueryMutation>;
export type CreateOpenQueryMutationResult = Apollo.MutationResult<CreateOpenQueryMutation>;
export type CreateOpenQueryMutationOptions = Apollo.BaseMutationOptions<CreateOpenQueryMutation, CreateOpenQueryMutationVariables>;
export const DeleteOpenQueryDocument = gql`
    mutation deleteOpenQuery($id: Long!) {
  deleteOpenQuery(id: $id)
}
    `;
export type DeleteOpenQueryMutationFn = Apollo.MutationFunction<DeleteOpenQueryMutation, DeleteOpenQueryMutationVariables>;

/**
 * __useDeleteOpenQueryMutation__
 *
 * To run a mutation, you first call `useDeleteOpenQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOpenQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOpenQueryMutation, { data, loading, error }] = useDeleteOpenQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOpenQueryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOpenQueryMutation, DeleteOpenQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOpenQueryMutation, DeleteOpenQueryMutationVariables>(DeleteOpenQueryDocument, options);
      }
export type DeleteOpenQueryMutationHookResult = ReturnType<typeof useDeleteOpenQueryMutation>;
export type DeleteOpenQueryMutationResult = Apollo.MutationResult<DeleteOpenQueryMutation>;
export type DeleteOpenQueryMutationOptions = Apollo.BaseMutationOptions<DeleteOpenQueryMutation, DeleteOpenQueryMutationVariables>;
export const DeleteOpenQueryByAccountPayableRecordIdDocument = gql`
    mutation deleteOpenQueryByAccountPayableRecordId($accountPayableRecordId: Long!) {
  deleteOpenQueryByAccountPayableRecord(
    accountPayableRecordId: $accountPayableRecordId
  )
}
    `;
export type DeleteOpenQueryByAccountPayableRecordIdMutationFn = Apollo.MutationFunction<DeleteOpenQueryByAccountPayableRecordIdMutation, DeleteOpenQueryByAccountPayableRecordIdMutationVariables>;

/**
 * __useDeleteOpenQueryByAccountPayableRecordIdMutation__
 *
 * To run a mutation, you first call `useDeleteOpenQueryByAccountPayableRecordIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOpenQueryByAccountPayableRecordIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOpenQueryByAccountPayableRecordIdMutation, { data, loading, error }] = useDeleteOpenQueryByAccountPayableRecordIdMutation({
 *   variables: {
 *      accountPayableRecordId: // value for 'accountPayableRecordId'
 *   },
 * });
 */
export function useDeleteOpenQueryByAccountPayableRecordIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOpenQueryByAccountPayableRecordIdMutation, DeleteOpenQueryByAccountPayableRecordIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOpenQueryByAccountPayableRecordIdMutation, DeleteOpenQueryByAccountPayableRecordIdMutationVariables>(DeleteOpenQueryByAccountPayableRecordIdDocument, options);
      }
export type DeleteOpenQueryByAccountPayableRecordIdMutationHookResult = ReturnType<typeof useDeleteOpenQueryByAccountPayableRecordIdMutation>;
export type DeleteOpenQueryByAccountPayableRecordIdMutationResult = Apollo.MutationResult<DeleteOpenQueryByAccountPayableRecordIdMutation>;
export type DeleteOpenQueryByAccountPayableRecordIdMutationOptions = Apollo.BaseMutationOptions<DeleteOpenQueryByAccountPayableRecordIdMutation, DeleteOpenQueryByAccountPayableRecordIdMutationVariables>;
export const CreateOtherChargeDocument = gql`
    mutation CreateOtherCharge($input: CreateAndUpdateOtherChargeDtoInput!) {
  createOtherCharge(input: $input) {
    id
    ...OtherChargeDetails
  }
}
    ${OtherChargeDetailsFragmentDoc}`;
export type CreateOtherChargeMutationFn = Apollo.MutationFunction<CreateOtherChargeMutation, CreateOtherChargeMutationVariables>;

/**
 * __useCreateOtherChargeMutation__
 *
 * To run a mutation, you first call `useCreateOtherChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOtherChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOtherChargeMutation, { data, loading, error }] = useCreateOtherChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOtherChargeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOtherChargeMutation, CreateOtherChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOtherChargeMutation, CreateOtherChargeMutationVariables>(CreateOtherChargeDocument, options);
      }
export type CreateOtherChargeMutationHookResult = ReturnType<typeof useCreateOtherChargeMutation>;
export type CreateOtherChargeMutationResult = Apollo.MutationResult<CreateOtherChargeMutation>;
export type CreateOtherChargeMutationOptions = Apollo.BaseMutationOptions<CreateOtherChargeMutation, CreateOtherChargeMutationVariables>;
export const UpdateOtherChargeDocument = gql`
    mutation UpdateOtherCharge($input: CreateAndUpdateOtherChargeDtoInput!) {
  updateOtherCharge(input: $input) {
    id
    ...OtherChargeDetails
  }
}
    ${OtherChargeDetailsFragmentDoc}`;
export type UpdateOtherChargeMutationFn = Apollo.MutationFunction<UpdateOtherChargeMutation, UpdateOtherChargeMutationVariables>;

/**
 * __useUpdateOtherChargeMutation__
 *
 * To run a mutation, you first call `useUpdateOtherChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOtherChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOtherChargeMutation, { data, loading, error }] = useUpdateOtherChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOtherChargeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOtherChargeMutation, UpdateOtherChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOtherChargeMutation, UpdateOtherChargeMutationVariables>(UpdateOtherChargeDocument, options);
      }
export type UpdateOtherChargeMutationHookResult = ReturnType<typeof useUpdateOtherChargeMutation>;
export type UpdateOtherChargeMutationResult = Apollo.MutationResult<UpdateOtherChargeMutation>;
export type UpdateOtherChargeMutationOptions = Apollo.BaseMutationOptions<UpdateOtherChargeMutation, UpdateOtherChargeMutationVariables>;
export const DeleteOtherChargeDocument = gql`
    mutation DeleteOtherCharge($id: Long!) {
  deleteOtherCharge(id: $id)
}
    `;
export type DeleteOtherChargeMutationFn = Apollo.MutationFunction<DeleteOtherChargeMutation, DeleteOtherChargeMutationVariables>;

/**
 * __useDeleteOtherChargeMutation__
 *
 * To run a mutation, you first call `useDeleteOtherChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOtherChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOtherChargeMutation, { data, loading, error }] = useDeleteOtherChargeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOtherChargeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOtherChargeMutation, DeleteOtherChargeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOtherChargeMutation, DeleteOtherChargeMutationVariables>(DeleteOtherChargeDocument, options);
      }
export type DeleteOtherChargeMutationHookResult = ReturnType<typeof useDeleteOtherChargeMutation>;
export type DeleteOtherChargeMutationResult = Apollo.MutationResult<DeleteOtherChargeMutation>;
export type DeleteOtherChargeMutationOptions = Apollo.BaseMutationOptions<DeleteOtherChargeMutation, DeleteOtherChargeMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation CreatePayment($accountPayableId: ID!) {
  createPayment(input: {accountPayableId: $accountPayableId}) {
    id
    status
  }
}
    `;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      accountPayableId: // value for 'accountPayableId'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const CreateSmartApprovalPaymentDocument = gql`
    mutation CreateSmartApprovalPayment($accountPayableIds: [Long!]) {
  createSmartApprovalPayment(input: {accountPayableIds: $accountPayableIds}) {
    id
    status
  }
}
    `;
export type CreateSmartApprovalPaymentMutationFn = Apollo.MutationFunction<CreateSmartApprovalPaymentMutation, CreateSmartApprovalPaymentMutationVariables>;

/**
 * __useCreateSmartApprovalPaymentMutation__
 *
 * To run a mutation, you first call `useCreateSmartApprovalPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmartApprovalPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmartApprovalPaymentMutation, { data, loading, error }] = useCreateSmartApprovalPaymentMutation({
 *   variables: {
 *      accountPayableIds: // value for 'accountPayableIds'
 *   },
 * });
 */
export function useCreateSmartApprovalPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSmartApprovalPaymentMutation, CreateSmartApprovalPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSmartApprovalPaymentMutation, CreateSmartApprovalPaymentMutationVariables>(CreateSmartApprovalPaymentDocument, options);
      }
export type CreateSmartApprovalPaymentMutationHookResult = ReturnType<typeof useCreateSmartApprovalPaymentMutation>;
export type CreateSmartApprovalPaymentMutationResult = Apollo.MutationResult<CreateSmartApprovalPaymentMutation>;
export type CreateSmartApprovalPaymentMutationOptions = Apollo.BaseMutationOptions<CreateSmartApprovalPaymentMutation, CreateSmartApprovalPaymentMutationVariables>;
export const UpdateFailedPaymentStatusDocument = gql`
    mutation updateFailedPaymentStatus($accountPayableIds: [Long!]) {
  updateFailedPaymentStatus(inputs: {accountPayableIds: $accountPayableIds})
}
    `;
export type UpdateFailedPaymentStatusMutationFn = Apollo.MutationFunction<UpdateFailedPaymentStatusMutation, UpdateFailedPaymentStatusMutationVariables>;

/**
 * __useUpdateFailedPaymentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateFailedPaymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFailedPaymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFailedPaymentStatusMutation, { data, loading, error }] = useUpdateFailedPaymentStatusMutation({
 *   variables: {
 *      accountPayableIds: // value for 'accountPayableIds'
 *   },
 * });
 */
export function useUpdateFailedPaymentStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFailedPaymentStatusMutation, UpdateFailedPaymentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFailedPaymentStatusMutation, UpdateFailedPaymentStatusMutationVariables>(UpdateFailedPaymentStatusDocument, options);
      }
export type UpdateFailedPaymentStatusMutationHookResult = ReturnType<typeof useUpdateFailedPaymentStatusMutation>;
export type UpdateFailedPaymentStatusMutationResult = Apollo.MutationResult<UpdateFailedPaymentStatusMutation>;
export type UpdateFailedPaymentStatusMutationOptions = Apollo.BaseMutationOptions<UpdateFailedPaymentStatusMutation, UpdateFailedPaymentStatusMutationVariables>;
export const CreateReasonCodeDocument = gql`
    mutation createReasonCode($input: CreateAndUpdateReasonCodeDtoInput!) {
  createReasonCode(input: $input) {
    id
    ...ReasonCodeDetails
  }
}
    ${ReasonCodeDetailsFragmentDoc}`;
export type CreateReasonCodeMutationFn = Apollo.MutationFunction<CreateReasonCodeMutation, CreateReasonCodeMutationVariables>;

/**
 * __useCreateReasonCodeMutation__
 *
 * To run a mutation, you first call `useCreateReasonCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReasonCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReasonCodeMutation, { data, loading, error }] = useCreateReasonCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReasonCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateReasonCodeMutation, CreateReasonCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReasonCodeMutation, CreateReasonCodeMutationVariables>(CreateReasonCodeDocument, options);
      }
export type CreateReasonCodeMutationHookResult = ReturnType<typeof useCreateReasonCodeMutation>;
export type CreateReasonCodeMutationResult = Apollo.MutationResult<CreateReasonCodeMutation>;
export type CreateReasonCodeMutationOptions = Apollo.BaseMutationOptions<CreateReasonCodeMutation, CreateReasonCodeMutationVariables>;
export const UpdateReasonCodeDocument = gql`
    mutation updateReasonCode($input: CreateAndUpdateReasonCodeDtoInput!) {
  updateReasonCode(input: $input) {
    id
    ...ReasonCodeDetails
  }
}
    ${ReasonCodeDetailsFragmentDoc}`;
export type UpdateReasonCodeMutationFn = Apollo.MutationFunction<UpdateReasonCodeMutation, UpdateReasonCodeMutationVariables>;

/**
 * __useUpdateReasonCodeMutation__
 *
 * To run a mutation, you first call `useUpdateReasonCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReasonCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReasonCodeMutation, { data, loading, error }] = useUpdateReasonCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReasonCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReasonCodeMutation, UpdateReasonCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReasonCodeMutation, UpdateReasonCodeMutationVariables>(UpdateReasonCodeDocument, options);
      }
export type UpdateReasonCodeMutationHookResult = ReturnType<typeof useUpdateReasonCodeMutation>;
export type UpdateReasonCodeMutationResult = Apollo.MutationResult<UpdateReasonCodeMutation>;
export type UpdateReasonCodeMutationOptions = Apollo.BaseMutationOptions<UpdateReasonCodeMutation, UpdateReasonCodeMutationVariables>;
export const DeleteReasonCodeDocument = gql`
    mutation deleteReasonCode($reasonCodeId: Long!) {
  deleteReasonCode(reasonCodeId: $reasonCodeId)
}
    `;
export type DeleteReasonCodeMutationFn = Apollo.MutationFunction<DeleteReasonCodeMutation, DeleteReasonCodeMutationVariables>;

/**
 * __useDeleteReasonCodeMutation__
 *
 * To run a mutation, you first call `useDeleteReasonCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReasonCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReasonCodeMutation, { data, loading, error }] = useDeleteReasonCodeMutation({
 *   variables: {
 *      reasonCodeId: // value for 'reasonCodeId'
 *   },
 * });
 */
export function useDeleteReasonCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReasonCodeMutation, DeleteReasonCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReasonCodeMutation, DeleteReasonCodeMutationVariables>(DeleteReasonCodeDocument, options);
      }
export type DeleteReasonCodeMutationHookResult = ReturnType<typeof useDeleteReasonCodeMutation>;
export type DeleteReasonCodeMutationResult = Apollo.MutationResult<DeleteReasonCodeMutation>;
export type DeleteReasonCodeMutationOptions = Apollo.BaseMutationOptions<DeleteReasonCodeMutation, DeleteReasonCodeMutationVariables>;
export const MatchExternalDriverInvoiceDocument = gql`
    mutation matchExternalDriverInvoice($id: Long!) {
  matchExternalDriverInvoices(fileId: $id) {
    id
    fileId
    invoiceNumber
    createdAt
  }
}
    `;
export type MatchExternalDriverInvoiceMutationFn = Apollo.MutationFunction<MatchExternalDriverInvoiceMutation, MatchExternalDriverInvoiceMutationVariables>;

/**
 * __useMatchExternalDriverInvoiceMutation__
 *
 * To run a mutation, you first call `useMatchExternalDriverInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMatchExternalDriverInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [matchExternalDriverInvoiceMutation, { data, loading, error }] = useMatchExternalDriverInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMatchExternalDriverInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<MatchExternalDriverInvoiceMutation, MatchExternalDriverInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MatchExternalDriverInvoiceMutation, MatchExternalDriverInvoiceMutationVariables>(MatchExternalDriverInvoiceDocument, options);
      }
export type MatchExternalDriverInvoiceMutationHookResult = ReturnType<typeof useMatchExternalDriverInvoiceMutation>;
export type MatchExternalDriverInvoiceMutationResult = Apollo.MutationResult<MatchExternalDriverInvoiceMutation>;
export type MatchExternalDriverInvoiceMutationOptions = Apollo.BaseMutationOptions<MatchExternalDriverInvoiceMutation, MatchExternalDriverInvoiceMutationVariables>;
export const CreateScheduledBookingTemplateDocument = gql`
    mutation CreateScheduledBookingTemplate($bookingId: Long!) {
  createScheduledBookingTemplate(bookingId: $bookingId) {
    id
    ...TemplateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;
export type CreateScheduledBookingTemplateMutationFn = Apollo.MutationFunction<CreateScheduledBookingTemplateMutation, CreateScheduledBookingTemplateMutationVariables>;

/**
 * __useCreateScheduledBookingTemplateMutation__
 *
 * To run a mutation, you first call `useCreateScheduledBookingTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledBookingTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledBookingTemplateMutation, { data, loading, error }] = useCreateScheduledBookingTemplateMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useCreateScheduledBookingTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduledBookingTemplateMutation, CreateScheduledBookingTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduledBookingTemplateMutation, CreateScheduledBookingTemplateMutationVariables>(CreateScheduledBookingTemplateDocument, options);
      }
export type CreateScheduledBookingTemplateMutationHookResult = ReturnType<typeof useCreateScheduledBookingTemplateMutation>;
export type CreateScheduledBookingTemplateMutationResult = Apollo.MutationResult<CreateScheduledBookingTemplateMutation>;
export type CreateScheduledBookingTemplateMutationOptions = Apollo.BaseMutationOptions<CreateScheduledBookingTemplateMutation, CreateScheduledBookingTemplateMutationVariables>;
export const UpdateScheduledBookingTemplateDocument = gql`
    mutation UpdateScheduledBookingTemplate($input: UpdateScheduledBookingTemplateDtoInput!) {
  updateScheduledBookingTemplate(input: $input) {
    id
    ...TemplateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;
export type UpdateScheduledBookingTemplateMutationFn = Apollo.MutationFunction<UpdateScheduledBookingTemplateMutation, UpdateScheduledBookingTemplateMutationVariables>;

/**
 * __useUpdateScheduledBookingTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledBookingTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledBookingTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledBookingTemplateMutation, { data, loading, error }] = useUpdateScheduledBookingTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScheduledBookingTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduledBookingTemplateMutation, UpdateScheduledBookingTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduledBookingTemplateMutation, UpdateScheduledBookingTemplateMutationVariables>(UpdateScheduledBookingTemplateDocument, options);
      }
export type UpdateScheduledBookingTemplateMutationHookResult = ReturnType<typeof useUpdateScheduledBookingTemplateMutation>;
export type UpdateScheduledBookingTemplateMutationResult = Apollo.MutationResult<UpdateScheduledBookingTemplateMutation>;
export type UpdateScheduledBookingTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateScheduledBookingTemplateMutation, UpdateScheduledBookingTemplateMutationVariables>;
export const CreateScheduledBookingDateDocument = gql`
    mutation CreateScheduledBookingDate($input: [CreateScheduledBookingDateDtoInput!]!) {
  createScheduledBookingDate(input: $input) {
    id
    executeOnDate
    taskId
  }
}
    `;
export type CreateScheduledBookingDateMutationFn = Apollo.MutationFunction<CreateScheduledBookingDateMutation, CreateScheduledBookingDateMutationVariables>;

/**
 * __useCreateScheduledBookingDateMutation__
 *
 * To run a mutation, you first call `useCreateScheduledBookingDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledBookingDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledBookingDateMutation, { data, loading, error }] = useCreateScheduledBookingDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduledBookingDateMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduledBookingDateMutation, CreateScheduledBookingDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduledBookingDateMutation, CreateScheduledBookingDateMutationVariables>(CreateScheduledBookingDateDocument, options);
      }
export type CreateScheduledBookingDateMutationHookResult = ReturnType<typeof useCreateScheduledBookingDateMutation>;
export type CreateScheduledBookingDateMutationResult = Apollo.MutationResult<CreateScheduledBookingDateMutation>;
export type CreateScheduledBookingDateMutationOptions = Apollo.BaseMutationOptions<CreateScheduledBookingDateMutation, CreateScheduledBookingDateMutationVariables>;
export const DeleteScheduledBookingDateDocument = gql`
    mutation DeleteScheduledBookingDate($dateId: Long!) {
  deleteScheduledBookingDate(dateId: $dateId)
}
    `;
export type DeleteScheduledBookingDateMutationFn = Apollo.MutationFunction<DeleteScheduledBookingDateMutation, DeleteScheduledBookingDateMutationVariables>;

/**
 * __useDeleteScheduledBookingDateMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledBookingDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledBookingDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledBookingDateMutation, { data, loading, error }] = useDeleteScheduledBookingDateMutation({
 *   variables: {
 *      dateId: // value for 'dateId'
 *   },
 * });
 */
export function useDeleteScheduledBookingDateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduledBookingDateMutation, DeleteScheduledBookingDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduledBookingDateMutation, DeleteScheduledBookingDateMutationVariables>(DeleteScheduledBookingDateDocument, options);
      }
export type DeleteScheduledBookingDateMutationHookResult = ReturnType<typeof useDeleteScheduledBookingDateMutation>;
export type DeleteScheduledBookingDateMutationResult = Apollo.MutationResult<DeleteScheduledBookingDateMutation>;
export type DeleteScheduledBookingDateMutationOptions = Apollo.BaseMutationOptions<DeleteScheduledBookingDateMutation, DeleteScheduledBookingDateMutationVariables>;
export const CreateScheduledExchangeRateTemplateDocument = gql`
    mutation createScheduledExchangeRateTemplate($currencyId: Long!, $date: DateTime!) {
  createScheduledExchangeRateTemplate(currencyId: $currencyId, date: $date) {
    id
    currencyId
    createdBy
    createdAt
  }
}
    `;
export type CreateScheduledExchangeRateTemplateMutationFn = Apollo.MutationFunction<CreateScheduledExchangeRateTemplateMutation, CreateScheduledExchangeRateTemplateMutationVariables>;

/**
 * __useCreateScheduledExchangeRateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateScheduledExchangeRateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledExchangeRateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledExchangeRateTemplateMutation, { data, loading, error }] = useCreateScheduledExchangeRateTemplateMutation({
 *   variables: {
 *      currencyId: // value for 'currencyId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCreateScheduledExchangeRateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduledExchangeRateTemplateMutation, CreateScheduledExchangeRateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduledExchangeRateTemplateMutation, CreateScheduledExchangeRateTemplateMutationVariables>(CreateScheduledExchangeRateTemplateDocument, options);
      }
export type CreateScheduledExchangeRateTemplateMutationHookResult = ReturnType<typeof useCreateScheduledExchangeRateTemplateMutation>;
export type CreateScheduledExchangeRateTemplateMutationResult = Apollo.MutationResult<CreateScheduledExchangeRateTemplateMutation>;
export type CreateScheduledExchangeRateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateScheduledExchangeRateTemplateMutation, CreateScheduledExchangeRateTemplateMutationVariables>;
export const DeleteScheduledExchangeRateTemplateDocument = gql`
    mutation deleteScheduledExchangeRateTemplate($id: Long!) {
  deleteScheduledExchangeRateTemplate(id: $id)
}
    `;
export type DeleteScheduledExchangeRateTemplateMutationFn = Apollo.MutationFunction<DeleteScheduledExchangeRateTemplateMutation, DeleteScheduledExchangeRateTemplateMutationVariables>;

/**
 * __useDeleteScheduledExchangeRateTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledExchangeRateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledExchangeRateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledExchangeRateTemplateMutation, { data, loading, error }] = useDeleteScheduledExchangeRateTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduledExchangeRateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduledExchangeRateTemplateMutation, DeleteScheduledExchangeRateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduledExchangeRateTemplateMutation, DeleteScheduledExchangeRateTemplateMutationVariables>(DeleteScheduledExchangeRateTemplateDocument, options);
      }
export type DeleteScheduledExchangeRateTemplateMutationHookResult = ReturnType<typeof useDeleteScheduledExchangeRateTemplateMutation>;
export type DeleteScheduledExchangeRateTemplateMutationResult = Apollo.MutationResult<DeleteScheduledExchangeRateTemplateMutation>;
export type DeleteScheduledExchangeRateTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteScheduledExchangeRateTemplateMutation, DeleteScheduledExchangeRateTemplateMutationVariables>;
export const CreateStandardOperatingProcedureDocument = gql`
    mutation createStandardOperatingProcedure($input: CreateAndUpdateStandardOperatingProcedureDtoInput!) {
  createStandardOperatingProcedure(input: $input) {
    id
    ...StandardOperatingProcedureDetails
  }
}
    ${StandardOperatingProcedureDetailsFragmentDoc}`;
export type CreateStandardOperatingProcedureMutationFn = Apollo.MutationFunction<CreateStandardOperatingProcedureMutation, CreateStandardOperatingProcedureMutationVariables>;

/**
 * __useCreateStandardOperatingProcedureMutation__
 *
 * To run a mutation, you first call `useCreateStandardOperatingProcedureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStandardOperatingProcedureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStandardOperatingProcedureMutation, { data, loading, error }] = useCreateStandardOperatingProcedureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStandardOperatingProcedureMutation(baseOptions?: Apollo.MutationHookOptions<CreateStandardOperatingProcedureMutation, CreateStandardOperatingProcedureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStandardOperatingProcedureMutation, CreateStandardOperatingProcedureMutationVariables>(CreateStandardOperatingProcedureDocument, options);
      }
export type CreateStandardOperatingProcedureMutationHookResult = ReturnType<typeof useCreateStandardOperatingProcedureMutation>;
export type CreateStandardOperatingProcedureMutationResult = Apollo.MutationResult<CreateStandardOperatingProcedureMutation>;
export type CreateStandardOperatingProcedureMutationOptions = Apollo.BaseMutationOptions<CreateStandardOperatingProcedureMutation, CreateStandardOperatingProcedureMutationVariables>;
export const UpdateStandardOperatingProcedureDocument = gql`
    mutation updateStandardOperatingProcedure($input: CreateAndUpdateStandardOperatingProcedureDtoInput!) {
  updateStandardOperatingProcedure(input: $input) {
    id
    ...StandardOperatingProcedureDetails
  }
}
    ${StandardOperatingProcedureDetailsFragmentDoc}`;
export type UpdateStandardOperatingProcedureMutationFn = Apollo.MutationFunction<UpdateStandardOperatingProcedureMutation, UpdateStandardOperatingProcedureMutationVariables>;

/**
 * __useUpdateStandardOperatingProcedureMutation__
 *
 * To run a mutation, you first call `useUpdateStandardOperatingProcedureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStandardOperatingProcedureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStandardOperatingProcedureMutation, { data, loading, error }] = useUpdateStandardOperatingProcedureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStandardOperatingProcedureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStandardOperatingProcedureMutation, UpdateStandardOperatingProcedureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStandardOperatingProcedureMutation, UpdateStandardOperatingProcedureMutationVariables>(UpdateStandardOperatingProcedureDocument, options);
      }
export type UpdateStandardOperatingProcedureMutationHookResult = ReturnType<typeof useUpdateStandardOperatingProcedureMutation>;
export type UpdateStandardOperatingProcedureMutationResult = Apollo.MutationResult<UpdateStandardOperatingProcedureMutation>;
export type UpdateStandardOperatingProcedureMutationOptions = Apollo.BaseMutationOptions<UpdateStandardOperatingProcedureMutation, UpdateStandardOperatingProcedureMutationVariables>;
export const DeleteStandardOperatingProcedureAttachmentDocument = gql`
    mutation deleteStandardOperatingProcedureAttachment($input: DeleteSopAttachmentDtoInput!) {
  deleteSopAttachment(input: $input)
}
    `;
export type DeleteStandardOperatingProcedureAttachmentMutationFn = Apollo.MutationFunction<DeleteStandardOperatingProcedureAttachmentMutation, DeleteStandardOperatingProcedureAttachmentMutationVariables>;

/**
 * __useDeleteStandardOperatingProcedureAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteStandardOperatingProcedureAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStandardOperatingProcedureAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStandardOperatingProcedureAttachmentMutation, { data, loading, error }] = useDeleteStandardOperatingProcedureAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStandardOperatingProcedureAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStandardOperatingProcedureAttachmentMutation, DeleteStandardOperatingProcedureAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStandardOperatingProcedureAttachmentMutation, DeleteStandardOperatingProcedureAttachmentMutationVariables>(DeleteStandardOperatingProcedureAttachmentDocument, options);
      }
export type DeleteStandardOperatingProcedureAttachmentMutationHookResult = ReturnType<typeof useDeleteStandardOperatingProcedureAttachmentMutation>;
export type DeleteStandardOperatingProcedureAttachmentMutationResult = Apollo.MutationResult<DeleteStandardOperatingProcedureAttachmentMutation>;
export type DeleteStandardOperatingProcedureAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteStandardOperatingProcedureAttachmentMutation, DeleteStandardOperatingProcedureAttachmentMutationVariables>;
export const CreateStrategicPartnerDocument = gql`
    mutation CreateStrategicPartner($input: CreateAndUpdateStrategicPartnerDtoInput!) {
  createStrategicPartner(input: $input) {
    ...StrategicPartnerDetails
  }
}
    ${StrategicPartnerDetailsFragmentDoc}`;
export type CreateStrategicPartnerMutationFn = Apollo.MutationFunction<CreateStrategicPartnerMutation, CreateStrategicPartnerMutationVariables>;

/**
 * __useCreateStrategicPartnerMutation__
 *
 * To run a mutation, you first call `useCreateStrategicPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStrategicPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStrategicPartnerMutation, { data, loading, error }] = useCreateStrategicPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStrategicPartnerMutation(baseOptions?: Apollo.MutationHookOptions<CreateStrategicPartnerMutation, CreateStrategicPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStrategicPartnerMutation, CreateStrategicPartnerMutationVariables>(CreateStrategicPartnerDocument, options);
      }
export type CreateStrategicPartnerMutationHookResult = ReturnType<typeof useCreateStrategicPartnerMutation>;
export type CreateStrategicPartnerMutationResult = Apollo.MutationResult<CreateStrategicPartnerMutation>;
export type CreateStrategicPartnerMutationOptions = Apollo.BaseMutationOptions<CreateStrategicPartnerMutation, CreateStrategicPartnerMutationVariables>;
export const UpdateStrategicPartnerDocument = gql`
    mutation UpdateStrategicPartner($input: CreateAndUpdateStrategicPartnerDtoInput!) {
  updateStrategicPartner(input: $input) {
    ...StrategicPartnerDetails
  }
}
    ${StrategicPartnerDetailsFragmentDoc}`;
export type UpdateStrategicPartnerMutationFn = Apollo.MutationFunction<UpdateStrategicPartnerMutation, UpdateStrategicPartnerMutationVariables>;

/**
 * __useUpdateStrategicPartnerMutation__
 *
 * To run a mutation, you first call `useUpdateStrategicPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategicPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategicPartnerMutation, { data, loading, error }] = useUpdateStrategicPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrategicPartnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategicPartnerMutation, UpdateStrategicPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategicPartnerMutation, UpdateStrategicPartnerMutationVariables>(UpdateStrategicPartnerDocument, options);
      }
export type UpdateStrategicPartnerMutationHookResult = ReturnType<typeof useUpdateStrategicPartnerMutation>;
export type UpdateStrategicPartnerMutationResult = Apollo.MutationResult<UpdateStrategicPartnerMutation>;
export type UpdateStrategicPartnerMutationOptions = Apollo.BaseMutationOptions<UpdateStrategicPartnerMutation, UpdateStrategicPartnerMutationVariables>;
export const DeleteStrategicPartnerDocument = gql`
    mutation DeleteStrategicPartner($strategicPartnerId: Long!) {
  deleteStrategicPartner(strategicPartnerId: $strategicPartnerId)
}
    `;
export type DeleteStrategicPartnerMutationFn = Apollo.MutationFunction<DeleteStrategicPartnerMutation, DeleteStrategicPartnerMutationVariables>;

/**
 * __useDeleteStrategicPartnerMutation__
 *
 * To run a mutation, you first call `useDeleteStrategicPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStrategicPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStrategicPartnerMutation, { data, loading, error }] = useDeleteStrategicPartnerMutation({
 *   variables: {
 *      strategicPartnerId: // value for 'strategicPartnerId'
 *   },
 * });
 */
export function useDeleteStrategicPartnerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStrategicPartnerMutation, DeleteStrategicPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStrategicPartnerMutation, DeleteStrategicPartnerMutationVariables>(DeleteStrategicPartnerDocument, options);
      }
export type DeleteStrategicPartnerMutationHookResult = ReturnType<typeof useDeleteStrategicPartnerMutation>;
export type DeleteStrategicPartnerMutationResult = Apollo.MutationResult<DeleteStrategicPartnerMutation>;
export type DeleteStrategicPartnerMutationOptions = Apollo.BaseMutationOptions<DeleteStrategicPartnerMutation, DeleteStrategicPartnerMutationVariables>;
export const CreateStrategicPartnerContactDocument = gql`
    mutation CreateStrategicPartnerContact($input: CreateAndUpdateStrategicPartnerContactDtoInput!) {
  createStrategicPartnerContact(input: $input) {
    ...StrategicPartnerContactDetails
  }
}
    ${StrategicPartnerContactDetailsFragmentDoc}`;
export type CreateStrategicPartnerContactMutationFn = Apollo.MutationFunction<CreateStrategicPartnerContactMutation, CreateStrategicPartnerContactMutationVariables>;

/**
 * __useCreateStrategicPartnerContactMutation__
 *
 * To run a mutation, you first call `useCreateStrategicPartnerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStrategicPartnerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStrategicPartnerContactMutation, { data, loading, error }] = useCreateStrategicPartnerContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStrategicPartnerContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateStrategicPartnerContactMutation, CreateStrategicPartnerContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStrategicPartnerContactMutation, CreateStrategicPartnerContactMutationVariables>(CreateStrategicPartnerContactDocument, options);
      }
export type CreateStrategicPartnerContactMutationHookResult = ReturnType<typeof useCreateStrategicPartnerContactMutation>;
export type CreateStrategicPartnerContactMutationResult = Apollo.MutationResult<CreateStrategicPartnerContactMutation>;
export type CreateStrategicPartnerContactMutationOptions = Apollo.BaseMutationOptions<CreateStrategicPartnerContactMutation, CreateStrategicPartnerContactMutationVariables>;
export const UpdateStrategicPartnerContactDocument = gql`
    mutation UpdateStrategicPartnerContact($input: CreateAndUpdateStrategicPartnerContactDtoInput!) {
  updateStrategicPartnerContact(input: $input) {
    ...StrategicPartnerContactDetails
  }
}
    ${StrategicPartnerContactDetailsFragmentDoc}`;
export type UpdateStrategicPartnerContactMutationFn = Apollo.MutationFunction<UpdateStrategicPartnerContactMutation, UpdateStrategicPartnerContactMutationVariables>;

/**
 * __useUpdateStrategicPartnerContactMutation__
 *
 * To run a mutation, you first call `useUpdateStrategicPartnerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStrategicPartnerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStrategicPartnerContactMutation, { data, loading, error }] = useUpdateStrategicPartnerContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStrategicPartnerContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStrategicPartnerContactMutation, UpdateStrategicPartnerContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStrategicPartnerContactMutation, UpdateStrategicPartnerContactMutationVariables>(UpdateStrategicPartnerContactDocument, options);
      }
export type UpdateStrategicPartnerContactMutationHookResult = ReturnType<typeof useUpdateStrategicPartnerContactMutation>;
export type UpdateStrategicPartnerContactMutationResult = Apollo.MutationResult<UpdateStrategicPartnerContactMutation>;
export type UpdateStrategicPartnerContactMutationOptions = Apollo.BaseMutationOptions<UpdateStrategicPartnerContactMutation, UpdateStrategicPartnerContactMutationVariables>;
export const DeleteStrategicPartnerContactDocument = gql`
    mutation DeleteStrategicPartnerContact($strategicPartnerContactId: Long!) {
  deleteStrategicPartnerContact(
    strategicPartnerContactId: $strategicPartnerContactId
  )
}
    `;
export type DeleteStrategicPartnerContactMutationFn = Apollo.MutationFunction<DeleteStrategicPartnerContactMutation, DeleteStrategicPartnerContactMutationVariables>;

/**
 * __useDeleteStrategicPartnerContactMutation__
 *
 * To run a mutation, you first call `useDeleteStrategicPartnerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStrategicPartnerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStrategicPartnerContactMutation, { data, loading, error }] = useDeleteStrategicPartnerContactMutation({
 *   variables: {
 *      strategicPartnerContactId: // value for 'strategicPartnerContactId'
 *   },
 * });
 */
export function useDeleteStrategicPartnerContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStrategicPartnerContactMutation, DeleteStrategicPartnerContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStrategicPartnerContactMutation, DeleteStrategicPartnerContactMutationVariables>(DeleteStrategicPartnerContactDocument, options);
      }
export type DeleteStrategicPartnerContactMutationHookResult = ReturnType<typeof useDeleteStrategicPartnerContactMutation>;
export type DeleteStrategicPartnerContactMutationResult = Apollo.MutationResult<DeleteStrategicPartnerContactMutation>;
export type DeleteStrategicPartnerContactMutationOptions = Apollo.BaseMutationOptions<DeleteStrategicPartnerContactMutation, DeleteStrategicPartnerContactMutationVariables>;
export const CreateTariffDocument = gql`
    mutation CreateTariff($input: CreateAndUpdateTariffDtoInput!) {
  createTariff(input: $input) {
    ...TariffDetails
  }
}
    ${TariffDetailsFragmentDoc}`;
export type CreateTariffMutationFn = Apollo.MutationFunction<CreateTariffMutation, CreateTariffMutationVariables>;

/**
 * __useCreateTariffMutation__
 *
 * To run a mutation, you first call `useCreateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffMutation, { data, loading, error }] = useCreateTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTariffMutation(baseOptions?: Apollo.MutationHookOptions<CreateTariffMutation, CreateTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTariffMutation, CreateTariffMutationVariables>(CreateTariffDocument, options);
      }
export type CreateTariffMutationHookResult = ReturnType<typeof useCreateTariffMutation>;
export type CreateTariffMutationResult = Apollo.MutationResult<CreateTariffMutation>;
export type CreateTariffMutationOptions = Apollo.BaseMutationOptions<CreateTariffMutation, CreateTariffMutationVariables>;
export const UpdateTariffDocument = gql`
    mutation UpdateTariff($input: CreateAndUpdateTariffDtoInput!) {
  updateTariff(input: $input) {
    ...TariffDetails
  }
}
    ${TariffDetailsFragmentDoc}`;
export type UpdateTariffMutationFn = Apollo.MutationFunction<UpdateTariffMutation, UpdateTariffMutationVariables>;

/**
 * __useUpdateTariffMutation__
 *
 * To run a mutation, you first call `useUpdateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffMutation, { data, loading, error }] = useUpdateTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTariffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTariffMutation, UpdateTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTariffMutation, UpdateTariffMutationVariables>(UpdateTariffDocument, options);
      }
export type UpdateTariffMutationHookResult = ReturnType<typeof useUpdateTariffMutation>;
export type UpdateTariffMutationResult = Apollo.MutationResult<UpdateTariffMutation>;
export type UpdateTariffMutationOptions = Apollo.BaseMutationOptions<UpdateTariffMutation, UpdateTariffMutationVariables>;
export const DeleteTariffDocument = gql`
    mutation DeleteTariff($id: Long!) {
  deleteTariff(id: $id)
}
    `;
export type DeleteTariffMutationFn = Apollo.MutationFunction<DeleteTariffMutation, DeleteTariffMutationVariables>;

/**
 * __useDeleteTariffMutation__
 *
 * To run a mutation, you first call `useDeleteTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTariffMutation, { data, loading, error }] = useDeleteTariffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTariffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTariffMutation, DeleteTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTariffMutation, DeleteTariffMutationVariables>(DeleteTariffDocument, options);
      }
export type DeleteTariffMutationHookResult = ReturnType<typeof useDeleteTariffMutation>;
export type DeleteTariffMutationResult = Apollo.MutationResult<DeleteTariffMutation>;
export type DeleteTariffMutationOptions = Apollo.BaseMutationOptions<DeleteTariffMutation, DeleteTariffMutationVariables>;
export const CreateTariffCategoryDocument = gql`
    mutation CreateTariffCategory($input: CreateAndUpdateTariffCategoryDtoInput!) {
  createTariffCategory(input: $input) {
    id
    name
    courierExchangeVehicleSize
    hasSpotRate
  }
}
    `;
export type CreateTariffCategoryMutationFn = Apollo.MutationFunction<CreateTariffCategoryMutation, CreateTariffCategoryMutationVariables>;

/**
 * __useCreateTariffCategoryMutation__
 *
 * To run a mutation, you first call `useCreateTariffCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffCategoryMutation, { data, loading, error }] = useCreateTariffCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTariffCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateTariffCategoryMutation, CreateTariffCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTariffCategoryMutation, CreateTariffCategoryMutationVariables>(CreateTariffCategoryDocument, options);
      }
export type CreateTariffCategoryMutationHookResult = ReturnType<typeof useCreateTariffCategoryMutation>;
export type CreateTariffCategoryMutationResult = Apollo.MutationResult<CreateTariffCategoryMutation>;
export type CreateTariffCategoryMutationOptions = Apollo.BaseMutationOptions<CreateTariffCategoryMutation, CreateTariffCategoryMutationVariables>;
export const UpdateTariffCategoryDocument = gql`
    mutation UpdateTariffCategory($input: CreateAndUpdateTariffCategoryDtoInput!) {
  updateTariffCategory(input: $input) {
    id
    name
    courierExchangeVehicleSize
    hasSpotRate
  }
}
    `;
export type UpdateTariffCategoryMutationFn = Apollo.MutationFunction<UpdateTariffCategoryMutation, UpdateTariffCategoryMutationVariables>;

/**
 * __useUpdateTariffCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateTariffCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffCategoryMutation, { data, loading, error }] = useUpdateTariffCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTariffCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTariffCategoryMutation, UpdateTariffCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTariffCategoryMutation, UpdateTariffCategoryMutationVariables>(UpdateTariffCategoryDocument, options);
      }
export type UpdateTariffCategoryMutationHookResult = ReturnType<typeof useUpdateTariffCategoryMutation>;
export type UpdateTariffCategoryMutationResult = Apollo.MutationResult<UpdateTariffCategoryMutation>;
export type UpdateTariffCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateTariffCategoryMutation, UpdateTariffCategoryMutationVariables>;
export const CreateTariffIconDocument = gql`
    mutation CreateTariffIcon($input: CreateAndUpdateTariffIconDtoInput!) {
  createTariffIcon(input: $input) {
    id
    name
    vehicleCapacity
    fileId
    file {
      id
      ...FileDetails
    }
  }
}
    ${FileDetailsFragmentDoc}`;
export type CreateTariffIconMutationFn = Apollo.MutationFunction<CreateTariffIconMutation, CreateTariffIconMutationVariables>;

/**
 * __useCreateTariffIconMutation__
 *
 * To run a mutation, you first call `useCreateTariffIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffIconMutation, { data, loading, error }] = useCreateTariffIconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTariffIconMutation(baseOptions?: Apollo.MutationHookOptions<CreateTariffIconMutation, CreateTariffIconMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTariffIconMutation, CreateTariffIconMutationVariables>(CreateTariffIconDocument, options);
      }
export type CreateTariffIconMutationHookResult = ReturnType<typeof useCreateTariffIconMutation>;
export type CreateTariffIconMutationResult = Apollo.MutationResult<CreateTariffIconMutation>;
export type CreateTariffIconMutationOptions = Apollo.BaseMutationOptions<CreateTariffIconMutation, CreateTariffIconMutationVariables>;
export const UpdateTariffIconDocument = gql`
    mutation UpdateTariffIcon($input: CreateAndUpdateTariffIconDtoInput!) {
  updateTariffIcon(input: $input) {
    id
    name
    vehicleCapacity
    fileId
    file {
      id
      ...FileDetails
    }
  }
}
    ${FileDetailsFragmentDoc}`;
export type UpdateTariffIconMutationFn = Apollo.MutationFunction<UpdateTariffIconMutation, UpdateTariffIconMutationVariables>;

/**
 * __useUpdateTariffIconMutation__
 *
 * To run a mutation, you first call `useUpdateTariffIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffIconMutation, { data, loading, error }] = useUpdateTariffIconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTariffIconMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTariffIconMutation, UpdateTariffIconMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTariffIconMutation, UpdateTariffIconMutationVariables>(UpdateTariffIconDocument, options);
      }
export type UpdateTariffIconMutationHookResult = ReturnType<typeof useUpdateTariffIconMutation>;
export type UpdateTariffIconMutationResult = Apollo.MutationResult<UpdateTariffIconMutation>;
export type UpdateTariffIconMutationOptions = Apollo.BaseMutationOptions<UpdateTariffIconMutation, UpdateTariffIconMutationVariables>;
export const DeleteTariffIconDocument = gql`
    mutation DeleteTariffIcon($id: Long!) {
  deleteTariffIcon(id: $id)
}
    `;
export type DeleteTariffIconMutationFn = Apollo.MutationFunction<DeleteTariffIconMutation, DeleteTariffIconMutationVariables>;

/**
 * __useDeleteTariffIconMutation__
 *
 * To run a mutation, you first call `useDeleteTariffIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTariffIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTariffIconMutation, { data, loading, error }] = useDeleteTariffIconMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTariffIconMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTariffIconMutation, DeleteTariffIconMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTariffIconMutation, DeleteTariffIconMutationVariables>(DeleteTariffIconDocument, options);
      }
export type DeleteTariffIconMutationHookResult = ReturnType<typeof useDeleteTariffIconMutation>;
export type DeleteTariffIconMutationResult = Apollo.MutationResult<DeleteTariffIconMutation>;
export type DeleteTariffIconMutationOptions = Apollo.BaseMutationOptions<DeleteTariffIconMutation, DeleteTariffIconMutationVariables>;
export const CreateTaskDocument = gql`
    mutation createTask($input: CreateUpdateTaskDtoInput!) {
  createTask(input: $input) {
    id
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($input: CreateUpdateTaskDtoInput!) {
  updateTask(input: $input) {
    id
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation deleteTask($id: Long!) {
  deleteTask(id: $id)
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const CreateTaskSettingDocument = gql`
    mutation CreateTaskSetting($input: CreateUpdateTaskSettingDtoInput!) {
  createSystemTaskSetting(input: $input) {
    id
    ...TaskSettingDetails
  }
}
    ${TaskSettingDetailsFragmentDoc}`;
export type CreateTaskSettingMutationFn = Apollo.MutationFunction<CreateTaskSettingMutation, CreateTaskSettingMutationVariables>;

/**
 * __useCreateTaskSettingMutation__
 *
 * To run a mutation, you first call `useCreateTaskSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskSettingMutation, { data, loading, error }] = useCreateTaskSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskSettingMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskSettingMutation, CreateTaskSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskSettingMutation, CreateTaskSettingMutationVariables>(CreateTaskSettingDocument, options);
      }
export type CreateTaskSettingMutationHookResult = ReturnType<typeof useCreateTaskSettingMutation>;
export type CreateTaskSettingMutationResult = Apollo.MutationResult<CreateTaskSettingMutation>;
export type CreateTaskSettingMutationOptions = Apollo.BaseMutationOptions<CreateTaskSettingMutation, CreateTaskSettingMutationVariables>;
export const UpdateTaskSettingDocument = gql`
    mutation UpdateTaskSetting($input: CreateUpdateTaskSettingDtoInput!) {
  updateSystemTaskSetting(input: $input) {
    id
    ...TaskSettingDetails
  }
}
    ${TaskSettingDetailsFragmentDoc}`;
export type UpdateTaskSettingMutationFn = Apollo.MutationFunction<UpdateTaskSettingMutation, UpdateTaskSettingMutationVariables>;

/**
 * __useUpdateTaskSettingMutation__
 *
 * To run a mutation, you first call `useUpdateTaskSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskSettingMutation, { data, loading, error }] = useUpdateTaskSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskSettingMutation, UpdateTaskSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskSettingMutation, UpdateTaskSettingMutationVariables>(UpdateTaskSettingDocument, options);
      }
export type UpdateTaskSettingMutationHookResult = ReturnType<typeof useUpdateTaskSettingMutation>;
export type UpdateTaskSettingMutationResult = Apollo.MutationResult<UpdateTaskSettingMutation>;
export type UpdateTaskSettingMutationOptions = Apollo.BaseMutationOptions<UpdateTaskSettingMutation, UpdateTaskSettingMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUpdateUserDtoInput!) {
  createApplicationUser(input: $input) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: CreateUpdateUserDtoInput!) {
  updateApplicationUser(input: $input) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: Long!) {
  deleteApplicationUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($input: UpdateProfileDtoInput!) {
  updateMyProfile(input: $input) {
    id
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const UpdateUserApprovalDocument = gql`
    mutation updateUserApproval($input: UpdateUserApprovalDtoInput!) {
  updateUserApproval(input: $input)
}
    `;
export type UpdateUserApprovalMutationFn = Apollo.MutationFunction<UpdateUserApprovalMutation, UpdateUserApprovalMutationVariables>;

/**
 * __useUpdateUserApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateUserApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserApprovalMutation, { data, loading, error }] = useUpdateUserApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserApprovalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserApprovalMutation, UpdateUserApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserApprovalMutation, UpdateUserApprovalMutationVariables>(UpdateUserApprovalDocument, options);
      }
export type UpdateUserApprovalMutationHookResult = ReturnType<typeof useUpdateUserApprovalMutation>;
export type UpdateUserApprovalMutationResult = Apollo.MutationResult<UpdateUserApprovalMutation>;
export type UpdateUserApprovalMutationOptions = Apollo.BaseMutationOptions<UpdateUserApprovalMutation, UpdateUserApprovalMutationVariables>;
export const CreateVehicleDocument = gql`
    mutation CreateVehicle($input: CreateAndUpdateVehicleDtoInput!) {
  createVehicle(input: $input) {
    id
    isDefault
    driverId
    registrationNumber
    vehicleTypeId
    manufacturer
    dateOfManufacture
    model
    colour
    insuranceCompany
    insuranceExpiryDate
    motTestExpiryDate
    isRenewalReminder
    policyOrCoverNote
    goodsInTransitExpiryDate
  }
}
    `;
export type CreateVehicleMutationFn = Apollo.MutationFunction<CreateVehicleMutation, CreateVehicleMutationVariables>;

/**
 * __useCreateVehicleMutation__
 *
 * To run a mutation, you first call `useCreateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleMutation, { data, loading, error }] = useCreateVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<CreateVehicleMutation, CreateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVehicleMutation, CreateVehicleMutationVariables>(CreateVehicleDocument, options);
      }
export type CreateVehicleMutationHookResult = ReturnType<typeof useCreateVehicleMutation>;
export type CreateVehicleMutationResult = Apollo.MutationResult<CreateVehicleMutation>;
export type CreateVehicleMutationOptions = Apollo.BaseMutationOptions<CreateVehicleMutation, CreateVehicleMutationVariables>;
export const UpdateVehicleDocument = gql`
    mutation UpdateVehicle($input: CreateAndUpdateVehicleDtoInput!) {
  updateVehicle(input: $input) {
    id
    isDefault
    driverId
    registrationNumber
    vehicleTypeId
    manufacturer
    dateOfManufacture
    model
    colour
    insuranceCompany
    insuranceExpiryDate
    motTestExpiryDate
    isRenewalReminder
    policyOrCoverNote
    goodsInTransitExpiryDate
  }
}
    `;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<UpdateVehicleMutation, UpdateVehicleMutationVariables>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, options);
      }
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>;
export type UpdateVehicleMutationResult = Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export const DeleteXeroCodeDocument = gql`
    mutation deleteXeroCode($id: Long!) {
  deleteXeroCode(id: $id)
}
    `;
export type DeleteXeroCodeMutationFn = Apollo.MutationFunction<DeleteXeroCodeMutation, DeleteXeroCodeMutationVariables>;

/**
 * __useDeleteXeroCodeMutation__
 *
 * To run a mutation, you first call `useDeleteXeroCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteXeroCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteXeroCodeMutation, { data, loading, error }] = useDeleteXeroCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteXeroCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteXeroCodeMutation, DeleteXeroCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteXeroCodeMutation, DeleteXeroCodeMutationVariables>(DeleteXeroCodeDocument, options);
      }
export type DeleteXeroCodeMutationHookResult = ReturnType<typeof useDeleteXeroCodeMutation>;
export type DeleteXeroCodeMutationResult = Apollo.MutationResult<DeleteXeroCodeMutation>;
export type DeleteXeroCodeMutationOptions = Apollo.BaseMutationOptions<DeleteXeroCodeMutation, DeleteXeroCodeMutationVariables>;
export const CreateXeroCodeDocument = gql`
    mutation CreateXeroCode($input: CreateAndUpdateXeroCodeDtoInput!) {
  createXeroCode(input: $input) {
    id
    name
    code
    description
    type
    serviceType
    isInternational
  }
}
    `;
export type CreateXeroCodeMutationFn = Apollo.MutationFunction<CreateXeroCodeMutation, CreateXeroCodeMutationVariables>;

/**
 * __useCreateXeroCodeMutation__
 *
 * To run a mutation, you first call `useCreateXeroCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateXeroCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createXeroCodeMutation, { data, loading, error }] = useCreateXeroCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateXeroCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateXeroCodeMutation, CreateXeroCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateXeroCodeMutation, CreateXeroCodeMutationVariables>(CreateXeroCodeDocument, options);
      }
export type CreateXeroCodeMutationHookResult = ReturnType<typeof useCreateXeroCodeMutation>;
export type CreateXeroCodeMutationResult = Apollo.MutationResult<CreateXeroCodeMutation>;
export type CreateXeroCodeMutationOptions = Apollo.BaseMutationOptions<CreateXeroCodeMutation, CreateXeroCodeMutationVariables>;
export const UpdateXeroCodeDocument = gql`
    mutation UpdateXeroCode($input: CreateAndUpdateXeroCodeDtoInput!) {
  updateXeroCode(input: $input) {
    id
    name
    code
    description
    type
    serviceType
    isInternational
  }
}
    `;
export type UpdateXeroCodeMutationFn = Apollo.MutationFunction<UpdateXeroCodeMutation, UpdateXeroCodeMutationVariables>;

/**
 * __useUpdateXeroCodeMutation__
 *
 * To run a mutation, you first call `useUpdateXeroCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateXeroCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateXeroCodeMutation, { data, loading, error }] = useUpdateXeroCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateXeroCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateXeroCodeMutation, UpdateXeroCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateXeroCodeMutation, UpdateXeroCodeMutationVariables>(UpdateXeroCodeDocument, options);
      }
export type UpdateXeroCodeMutationHookResult = ReturnType<typeof useUpdateXeroCodeMutation>;
export type UpdateXeroCodeMutationResult = Apollo.MutationResult<UpdateXeroCodeMutation>;
export type UpdateXeroCodeMutationOptions = Apollo.BaseMutationOptions<UpdateXeroCodeMutation, UpdateXeroCodeMutationVariables>;
export const CreateInvoiceTaskDocument = gql`
    mutation createInvoiceTask {
  createInvoiceTask
}
    `;
export type CreateInvoiceTaskMutationFn = Apollo.MutationFunction<CreateInvoiceTaskMutation, CreateInvoiceTaskMutationVariables>;

/**
 * __useCreateInvoiceTaskMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceTaskMutation, { data, loading, error }] = useCreateInvoiceTaskMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateInvoiceTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceTaskMutation, CreateInvoiceTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceTaskMutation, CreateInvoiceTaskMutationVariables>(CreateInvoiceTaskDocument, options);
      }
export type CreateInvoiceTaskMutationHookResult = ReturnType<typeof useCreateInvoiceTaskMutation>;
export type CreateInvoiceTaskMutationResult = Apollo.MutationResult<CreateInvoiceTaskMutation>;
export type CreateInvoiceTaskMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceTaskMutation, CreateInvoiceTaskMutationVariables>;
export const ProcessInvoiceTaskDocument = gql`
    mutation processInvoiceTask {
  processTasks
}
    `;
export type ProcessInvoiceTaskMutationFn = Apollo.MutationFunction<ProcessInvoiceTaskMutation, ProcessInvoiceTaskMutationVariables>;

/**
 * __useProcessInvoiceTaskMutation__
 *
 * To run a mutation, you first call `useProcessInvoiceTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessInvoiceTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processInvoiceTaskMutation, { data, loading, error }] = useProcessInvoiceTaskMutation({
 *   variables: {
 *   },
 * });
 */
export function useProcessInvoiceTaskMutation(baseOptions?: Apollo.MutationHookOptions<ProcessInvoiceTaskMutation, ProcessInvoiceTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessInvoiceTaskMutation, ProcessInvoiceTaskMutationVariables>(ProcessInvoiceTaskDocument, options);
      }
export type ProcessInvoiceTaskMutationHookResult = ReturnType<typeof useProcessInvoiceTaskMutation>;
export type ProcessInvoiceTaskMutationResult = Apollo.MutationResult<ProcessInvoiceTaskMutation>;
export type ProcessInvoiceTaskMutationOptions = Apollo.BaseMutationOptions<ProcessInvoiceTaskMutation, ProcessInvoiceTaskMutationVariables>;
export const VerifyAddressDeliveryDocument = gql`
    mutation verifyAddressDelivery {
  verifyAddressDelivery
}
    `;
export type VerifyAddressDeliveryMutationFn = Apollo.MutationFunction<VerifyAddressDeliveryMutation, VerifyAddressDeliveryMutationVariables>;

/**
 * __useVerifyAddressDeliveryMutation__
 *
 * To run a mutation, you first call `useVerifyAddressDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAddressDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAddressDeliveryMutation, { data, loading, error }] = useVerifyAddressDeliveryMutation({
 *   variables: {
 *   },
 * });
 */
export function useVerifyAddressDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAddressDeliveryMutation, VerifyAddressDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAddressDeliveryMutation, VerifyAddressDeliveryMutationVariables>(VerifyAddressDeliveryDocument, options);
      }
export type VerifyAddressDeliveryMutationHookResult = ReturnType<typeof useVerifyAddressDeliveryMutation>;
export type VerifyAddressDeliveryMutationResult = Apollo.MutationResult<VerifyAddressDeliveryMutation>;
export type VerifyAddressDeliveryMutationOptions = Apollo.BaseMutationOptions<VerifyAddressDeliveryMutation, VerifyAddressDeliveryMutationVariables>;
export const AllAccountPayableRecordsDocument = gql`
    query allAccountPayableRecords($after: String, $before: String, $first: Int, $last: Int, $order: [AccountPayableRecordSortInput!], $where: AccountPayableRecordFilterInput) {
  accountPayableRecords(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        status
        statusLabel
        net
        vat
        gross
        currency {
          symbol
        }
        openQueries {
          id
          reason
        }
        bookingInvoice {
          id
          invoiceDate
          driverInvoiceNumber
          dueDate
          paymentDate
          bookingDriver {
            id
            driver {
              id
              name
            }
          }
          booking {
            id
            ourReference
          }
        }
        bookingInvoiceBatchLine {
          id
          batchId
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useAllAccountPayableRecordsQuery__
 *
 * To run a query within a React component, call `useAllAccountPayableRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAccountPayableRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAccountPayableRecordsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllAccountPayableRecordsQuery(baseOptions?: Apollo.QueryHookOptions<AllAccountPayableRecordsQuery, AllAccountPayableRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAccountPayableRecordsQuery, AllAccountPayableRecordsQueryVariables>(AllAccountPayableRecordsDocument, options);
      }
export function useAllAccountPayableRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAccountPayableRecordsQuery, AllAccountPayableRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAccountPayableRecordsQuery, AllAccountPayableRecordsQueryVariables>(AllAccountPayableRecordsDocument, options);
        }
export type AllAccountPayableRecordsQueryHookResult = ReturnType<typeof useAllAccountPayableRecordsQuery>;
export type AllAccountPayableRecordsLazyQueryHookResult = ReturnType<typeof useAllAccountPayableRecordsLazyQuery>;
export type AllAccountPayableRecordsQueryResult = Apollo.QueryResult<AllAccountPayableRecordsQuery, AllAccountPayableRecordsQueryVariables>;
export function refetchAllAccountPayableRecordsQuery(variables?: AllAccountPayableRecordsQueryVariables) {
      return { query: AllAccountPayableRecordsDocument, variables: variables }
    }
export const GetAllAccountPayableRecordsDocument = gql`
    query getAllAccountPayableRecords($order: [AccountPayableRecordSortInput!], $where: AccountPayableRecordFilterInput) {
  allAccountPayableRecords(order: $order, where: $where) {
    id
    status
    statusLabel
    net
    vat
    gross
    currency {
      symbol
    }
    openQueries {
      id
      reason
    }
    bookingInvoice {
      id
      invoiceDate
      driverInvoiceNumber
      dueDate
      paymentDate
      bookingDriver {
        id
        driver {
          id
          name
        }
      }
      booking {
        id
        ourReference
      }
    }
  }
}
    `;

/**
 * __useGetAllAccountPayableRecordsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountPayableRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountPayableRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountPayableRecordsQuery({
 *   variables: {
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllAccountPayableRecordsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAccountPayableRecordsQuery, GetAllAccountPayableRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAccountPayableRecordsQuery, GetAllAccountPayableRecordsQueryVariables>(GetAllAccountPayableRecordsDocument, options);
      }
export function useGetAllAccountPayableRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAccountPayableRecordsQuery, GetAllAccountPayableRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAccountPayableRecordsQuery, GetAllAccountPayableRecordsQueryVariables>(GetAllAccountPayableRecordsDocument, options);
        }
export type GetAllAccountPayableRecordsQueryHookResult = ReturnType<typeof useGetAllAccountPayableRecordsQuery>;
export type GetAllAccountPayableRecordsLazyQueryHookResult = ReturnType<typeof useGetAllAccountPayableRecordsLazyQuery>;
export type GetAllAccountPayableRecordsQueryResult = Apollo.QueryResult<GetAllAccountPayableRecordsQuery, GetAllAccountPayableRecordsQueryVariables>;
export function refetchGetAllAccountPayableRecordsQuery(variables?: GetAllAccountPayableRecordsQueryVariables) {
      return { query: GetAllAccountPayableRecordsDocument, variables: variables }
    }
export const AllGroupedAccountPayableRecordsDocument = gql`
    query allGroupedAccountPayableRecords($after: String, $before: String, $first: Int, $last: Int, $order: [GroupedAccountPayableRecordDtoSortInput!], $where: GroupedAccountPayableRecordDtoFilterInput, $status: Int!, $dueDateStart: DateTime, $dueDateEnd: DateTime, $accountsPayableFilterCriteria: AccountsPayableFilterCriteria) {
  groupedAccountPayableRecords(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
    status: $status
    dueDateStart: $dueDateStart
    dueDateEnd: $dueDateEnd
    accountsPayableFilterCriteria: $accountsPayableFilterCriteria
  ) {
    edges {
      node {
        id
        driverId
        driverName
        invoiceCount
        accountPayableStatus
        courierExchangeLoadId
        ourReference
        driverNumber
        dueDate
        xeroPurchaseCode
        currencyAmountSum {
          id
          net
          vat
          gross
          currencySymbol
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useAllGroupedAccountPayableRecordsQuery__
 *
 * To run a query within a React component, call `useAllGroupedAccountPayableRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGroupedAccountPayableRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGroupedAccountPayableRecordsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *      status: // value for 'status'
 *      dueDateStart: // value for 'dueDateStart'
 *      dueDateEnd: // value for 'dueDateEnd'
 *      accountsPayableFilterCriteria: // value for 'accountsPayableFilterCriteria'
 *   },
 * });
 */
export function useAllGroupedAccountPayableRecordsQuery(baseOptions: Apollo.QueryHookOptions<AllGroupedAccountPayableRecordsQuery, AllGroupedAccountPayableRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllGroupedAccountPayableRecordsQuery, AllGroupedAccountPayableRecordsQueryVariables>(AllGroupedAccountPayableRecordsDocument, options);
      }
export function useAllGroupedAccountPayableRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllGroupedAccountPayableRecordsQuery, AllGroupedAccountPayableRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllGroupedAccountPayableRecordsQuery, AllGroupedAccountPayableRecordsQueryVariables>(AllGroupedAccountPayableRecordsDocument, options);
        }
export type AllGroupedAccountPayableRecordsQueryHookResult = ReturnType<typeof useAllGroupedAccountPayableRecordsQuery>;
export type AllGroupedAccountPayableRecordsLazyQueryHookResult = ReturnType<typeof useAllGroupedAccountPayableRecordsLazyQuery>;
export type AllGroupedAccountPayableRecordsQueryResult = Apollo.QueryResult<AllGroupedAccountPayableRecordsQuery, AllGroupedAccountPayableRecordsQueryVariables>;
export function refetchAllGroupedAccountPayableRecordsQuery(variables?: AllGroupedAccountPayableRecordsQueryVariables) {
      return { query: AllGroupedAccountPayableRecordsDocument, variables: variables }
    }
export const GetAccountPayableRecordDocument = gql`
    query GetAccountPayableRecord($id: Long!) {
  accountPayableRecord(accountPayableRecordId: $id) {
    id
    status
    openQueries {
      id
      reason
    }
    gross
    currency {
      id
      symbol
    }
    bookingInvoice {
      id
      invoiceDate
      bookingDriver {
        id
        driver {
          id
          name
          xeroPurchaseCode
          vatCategory {
            id
          }
        }
      }
      booking {
        id
        ourReference
        customerReference
        courierExchangeLoadId
        createdAt
        tariff {
          id
          name
          tariffCategory {
            id
            name
          }
        }
        addresses {
          id
          type
          at
          by
          address {
            id
            name
            postcode
            addressLine1
            addressLine2
            city
            country {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAccountPayableRecordQuery__
 *
 * To run a query within a React component, call `useGetAccountPayableRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPayableRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPayableRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountPayableRecordQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPayableRecordQuery, GetAccountPayableRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPayableRecordQuery, GetAccountPayableRecordQueryVariables>(GetAccountPayableRecordDocument, options);
      }
export function useGetAccountPayableRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPayableRecordQuery, GetAccountPayableRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPayableRecordQuery, GetAccountPayableRecordQueryVariables>(GetAccountPayableRecordDocument, options);
        }
export type GetAccountPayableRecordQueryHookResult = ReturnType<typeof useGetAccountPayableRecordQuery>;
export type GetAccountPayableRecordLazyQueryHookResult = ReturnType<typeof useGetAccountPayableRecordLazyQuery>;
export type GetAccountPayableRecordQueryResult = Apollo.QueryResult<GetAccountPayableRecordQuery, GetAccountPayableRecordQueryVariables>;
export function refetchGetAccountPayableRecordQuery(variables?: GetAccountPayableRecordQueryVariables) {
      return { query: GetAccountPayableRecordDocument, variables: variables }
    }
export const GetAccountPayableDriverNameDocument = gql`
    query getAccountPayableDriverName($id: Long!) {
  accountPayableRecord(accountPayableRecordId: $id) {
    id
    status
    statusLabel
    bookingInvoice {
      id
      booking {
        id
        ourReference
      }
      bookingDriver {
        id
        driver {
          id
          name
          email
        }
      }
    }
    bookingInvoiceBatchLine {
      id
      batchId
    }
  }
}
    `;

/**
 * __useGetAccountPayableDriverNameQuery__
 *
 * To run a query within a React component, call `useGetAccountPayableDriverNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPayableDriverNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPayableDriverNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountPayableDriverNameQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPayableDriverNameQuery, GetAccountPayableDriverNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPayableDriverNameQuery, GetAccountPayableDriverNameQueryVariables>(GetAccountPayableDriverNameDocument, options);
      }
export function useGetAccountPayableDriverNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPayableDriverNameQuery, GetAccountPayableDriverNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPayableDriverNameQuery, GetAccountPayableDriverNameQueryVariables>(GetAccountPayableDriverNameDocument, options);
        }
export type GetAccountPayableDriverNameQueryHookResult = ReturnType<typeof useGetAccountPayableDriverNameQuery>;
export type GetAccountPayableDriverNameLazyQueryHookResult = ReturnType<typeof useGetAccountPayableDriverNameLazyQuery>;
export type GetAccountPayableDriverNameQueryResult = Apollo.QueryResult<GetAccountPayableDriverNameQuery, GetAccountPayableDriverNameQueryVariables>;
export function refetchGetAccountPayableDriverNameQuery(variables?: GetAccountPayableDriverNameQueryVariables) {
      return { query: GetAccountPayableDriverNameDocument, variables: variables }
    }
export const GetTotalSumDocument = gql`
    query getTotalSum($status: Int!, $supplierId: Long!) {
  accountPayableTotals(status: $status, supplierId: $supplierId) {
    id
    net
    vat
    gross
    currencySymbol
  }
}
    `;

/**
 * __useGetTotalSumQuery__
 *
 * To run a query within a React component, call `useGetTotalSumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalSumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalSumQuery({
 *   variables: {
 *      status: // value for 'status'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useGetTotalSumQuery(baseOptions: Apollo.QueryHookOptions<GetTotalSumQuery, GetTotalSumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalSumQuery, GetTotalSumQueryVariables>(GetTotalSumDocument, options);
      }
export function useGetTotalSumLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalSumQuery, GetTotalSumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalSumQuery, GetTotalSumQueryVariables>(GetTotalSumDocument, options);
        }
export type GetTotalSumQueryHookResult = ReturnType<typeof useGetTotalSumQuery>;
export type GetTotalSumLazyQueryHookResult = ReturnType<typeof useGetTotalSumLazyQuery>;
export type GetTotalSumQueryResult = Apollo.QueryResult<GetTotalSumQuery, GetTotalSumQueryVariables>;
export function refetchGetTotalSumQuery(variables?: GetTotalSumQueryVariables) {
      return { query: GetTotalSumDocument, variables: variables }
    }
export const AccountPayableOverviewDocument = gql`
    query accountPayableOverview($startDate: DateTime, $endDate: DateTime, $supplierCodes: [String!]!) {
  accountPayableOverview(
    startDate: $startDate
    endDate: $endDate
    supplierCodes: $supplierCodes
  ) {
    eurosAmount
    poundsAmount
    dollarsAmount
    scheduledInvoicesCount
    bookingsWithCompletedDocumentsCount
    bookingsWithOpenQueriesCount
    bookingsWithSupplementaryInvoiceReceivedCount
    bookingsWithCreditNoteReceivedCount
    bookingsWithApprovedDocumentsCount
    overduesForApprovalCount
    failedPaymentsCount
    sentPaymentsCount
  }
}
    `;

/**
 * __useAccountPayableOverviewQuery__
 *
 * To run a query within a React component, call `useAccountPayableOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPayableOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPayableOverviewQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      supplierCodes: // value for 'supplierCodes'
 *   },
 * });
 */
export function useAccountPayableOverviewQuery(baseOptions: Apollo.QueryHookOptions<AccountPayableOverviewQuery, AccountPayableOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountPayableOverviewQuery, AccountPayableOverviewQueryVariables>(AccountPayableOverviewDocument, options);
      }
export function useAccountPayableOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountPayableOverviewQuery, AccountPayableOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountPayableOverviewQuery, AccountPayableOverviewQueryVariables>(AccountPayableOverviewDocument, options);
        }
export type AccountPayableOverviewQueryHookResult = ReturnType<typeof useAccountPayableOverviewQuery>;
export type AccountPayableOverviewLazyQueryHookResult = ReturnType<typeof useAccountPayableOverviewLazyQuery>;
export type AccountPayableOverviewQueryResult = Apollo.QueryResult<AccountPayableOverviewQuery, AccountPayableOverviewQueryVariables>;
export function refetchAccountPayableOverviewQuery(variables?: AccountPayableOverviewQueryVariables) {
      return { query: AccountPayableOverviewDocument, variables: variables }
    }
export const GetAccountPayableTotalCountDocument = gql`
    query getAccountPayableTotalCount($status: Int!, $supplierId: Long!) {
  accountPayableTotalCount(status: $status, supplierId: $supplierId)
}
    `;

/**
 * __useGetAccountPayableTotalCountQuery__
 *
 * To run a query within a React component, call `useGetAccountPayableTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPayableTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPayableTotalCountQuery({
 *   variables: {
 *      status: // value for 'status'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useGetAccountPayableTotalCountQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPayableTotalCountQuery, GetAccountPayableTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPayableTotalCountQuery, GetAccountPayableTotalCountQueryVariables>(GetAccountPayableTotalCountDocument, options);
      }
export function useGetAccountPayableTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPayableTotalCountQuery, GetAccountPayableTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPayableTotalCountQuery, GetAccountPayableTotalCountQueryVariables>(GetAccountPayableTotalCountDocument, options);
        }
export type GetAccountPayableTotalCountQueryHookResult = ReturnType<typeof useGetAccountPayableTotalCountQuery>;
export type GetAccountPayableTotalCountLazyQueryHookResult = ReturnType<typeof useGetAccountPayableTotalCountLazyQuery>;
export type GetAccountPayableTotalCountQueryResult = Apollo.QueryResult<GetAccountPayableTotalCountQuery, GetAccountPayableTotalCountQueryVariables>;
export function refetchGetAccountPayableTotalCountQuery(variables?: GetAccountPayableTotalCountQueryVariables) {
      return { query: GetAccountPayableTotalCountDocument, variables: variables }
    }
export const GetAccountPayableInvoiceDocument = gql`
    query getAccountPayableInvoice($accountPayableRecordId: Long!) {
  accountPayableInvoice(accountPayableRecordId: $accountPayableRecordId) {
    htmlString
    createdAt
  }
}
    `;

/**
 * __useGetAccountPayableInvoiceQuery__
 *
 * To run a query within a React component, call `useGetAccountPayableInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPayableInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPayableInvoiceQuery({
 *   variables: {
 *      accountPayableRecordId: // value for 'accountPayableRecordId'
 *   },
 * });
 */
export function useGetAccountPayableInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPayableInvoiceQuery, GetAccountPayableInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPayableInvoiceQuery, GetAccountPayableInvoiceQueryVariables>(GetAccountPayableInvoiceDocument, options);
      }
export function useGetAccountPayableInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPayableInvoiceQuery, GetAccountPayableInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPayableInvoiceQuery, GetAccountPayableInvoiceQueryVariables>(GetAccountPayableInvoiceDocument, options);
        }
export type GetAccountPayableInvoiceQueryHookResult = ReturnType<typeof useGetAccountPayableInvoiceQuery>;
export type GetAccountPayableInvoiceLazyQueryHookResult = ReturnType<typeof useGetAccountPayableInvoiceLazyQuery>;
export type GetAccountPayableInvoiceQueryResult = Apollo.QueryResult<GetAccountPayableInvoiceQuery, GetAccountPayableInvoiceQueryVariables>;
export function refetchGetAccountPayableInvoiceQuery(variables?: GetAccountPayableInvoiceQueryVariables) {
      return { query: GetAccountPayableInvoiceDocument, variables: variables }
    }
export const GetAccountPayablePodDocument = gql`
    query getAccountPayablePod($accountPayableRecordId: Long!) {
  accountPayablePod(accountPayableRecordId: $accountPayableRecordId) {
    id
    htmlString
    createdAt
    signatureUrls
    proofOfDeliveryPhotoUrls
    proofOfCollectionPhotoUrls
  }
}
    `;

/**
 * __useGetAccountPayablePodQuery__
 *
 * To run a query within a React component, call `useGetAccountPayablePodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPayablePodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPayablePodQuery({
 *   variables: {
 *      accountPayableRecordId: // value for 'accountPayableRecordId'
 *   },
 * });
 */
export function useGetAccountPayablePodQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPayablePodQuery, GetAccountPayablePodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPayablePodQuery, GetAccountPayablePodQueryVariables>(GetAccountPayablePodDocument, options);
      }
export function useGetAccountPayablePodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPayablePodQuery, GetAccountPayablePodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPayablePodQuery, GetAccountPayablePodQueryVariables>(GetAccountPayablePodDocument, options);
        }
export type GetAccountPayablePodQueryHookResult = ReturnType<typeof useGetAccountPayablePodQuery>;
export type GetAccountPayablePodLazyQueryHookResult = ReturnType<typeof useGetAccountPayablePodLazyQuery>;
export type GetAccountPayablePodQueryResult = Apollo.QueryResult<GetAccountPayablePodQuery, GetAccountPayablePodQueryVariables>;
export function refetchGetAccountPayablePodQuery(variables?: GetAccountPayablePodQueryVariables) {
      return { query: GetAccountPayablePodDocument, variables: variables }
    }
export const GetAccountPayableInvoiceSearchRequestDocument = gql`
    query getAccountPayableInvoiceSearchRequest($query: String!) {
  accountPayableSearchResults(query: $query) {
    entityId
    entityName
    description
  }
}
    `;

/**
 * __useGetAccountPayableInvoiceSearchRequestQuery__
 *
 * To run a query within a React component, call `useGetAccountPayableInvoiceSearchRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPayableInvoiceSearchRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPayableInvoiceSearchRequestQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetAccountPayableInvoiceSearchRequestQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPayableInvoiceSearchRequestQuery, GetAccountPayableInvoiceSearchRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPayableInvoiceSearchRequestQuery, GetAccountPayableInvoiceSearchRequestQueryVariables>(GetAccountPayableInvoiceSearchRequestDocument, options);
      }
export function useGetAccountPayableInvoiceSearchRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPayableInvoiceSearchRequestQuery, GetAccountPayableInvoiceSearchRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPayableInvoiceSearchRequestQuery, GetAccountPayableInvoiceSearchRequestQueryVariables>(GetAccountPayableInvoiceSearchRequestDocument, options);
        }
export type GetAccountPayableInvoiceSearchRequestQueryHookResult = ReturnType<typeof useGetAccountPayableInvoiceSearchRequestQuery>;
export type GetAccountPayableInvoiceSearchRequestLazyQueryHookResult = ReturnType<typeof useGetAccountPayableInvoiceSearchRequestLazyQuery>;
export type GetAccountPayableInvoiceSearchRequestQueryResult = Apollo.QueryResult<GetAccountPayableInvoiceSearchRequestQuery, GetAccountPayableInvoiceSearchRequestQueryVariables>;
export function refetchGetAccountPayableInvoiceSearchRequestQuery(variables?: GetAccountPayableInvoiceSearchRequestQueryVariables) {
      return { query: GetAccountPayableInvoiceSearchRequestDocument, variables: variables }
    }
export const GetFailedPaymentErrorMessageDocument = gql`
    query getFailedPaymentErrorMessage($accountPayableRecordId: Long!) {
  paymentFailedErrorMessage(accountPayableRecordId: $accountPayableRecordId)
}
    `;

/**
 * __useGetFailedPaymentErrorMessageQuery__
 *
 * To run a query within a React component, call `useGetFailedPaymentErrorMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFailedPaymentErrorMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFailedPaymentErrorMessageQuery({
 *   variables: {
 *      accountPayableRecordId: // value for 'accountPayableRecordId'
 *   },
 * });
 */
export function useGetFailedPaymentErrorMessageQuery(baseOptions: Apollo.QueryHookOptions<GetFailedPaymentErrorMessageQuery, GetFailedPaymentErrorMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFailedPaymentErrorMessageQuery, GetFailedPaymentErrorMessageQueryVariables>(GetFailedPaymentErrorMessageDocument, options);
      }
export function useGetFailedPaymentErrorMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFailedPaymentErrorMessageQuery, GetFailedPaymentErrorMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFailedPaymentErrorMessageQuery, GetFailedPaymentErrorMessageQueryVariables>(GetFailedPaymentErrorMessageDocument, options);
        }
export type GetFailedPaymentErrorMessageQueryHookResult = ReturnType<typeof useGetFailedPaymentErrorMessageQuery>;
export type GetFailedPaymentErrorMessageLazyQueryHookResult = ReturnType<typeof useGetFailedPaymentErrorMessageLazyQuery>;
export type GetFailedPaymentErrorMessageQueryResult = Apollo.QueryResult<GetFailedPaymentErrorMessageQuery, GetFailedPaymentErrorMessageQueryVariables>;
export function refetchGetFailedPaymentErrorMessageQuery(variables?: GetFailedPaymentErrorMessageQueryVariables) {
      return { query: GetFailedPaymentErrorMessageDocument, variables: variables }
    }
export const GetAccountPayableRecordsByIdsDocument = gql`
    query getAccountPayableRecordsByIds($accountPayableRecordsIds: [Long!]!) {
  accountPayableRecordsByIds(accountPayableRecordsIds: $accountPayableRecordsIds) {
    id
    bookingInvoice {
      id
      invoiceDate
      driverInvoiceNumber
    }
  }
}
    `;

/**
 * __useGetAccountPayableRecordsByIdsQuery__
 *
 * To run a query within a React component, call `useGetAccountPayableRecordsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPayableRecordsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPayableRecordsByIdsQuery({
 *   variables: {
 *      accountPayableRecordsIds: // value for 'accountPayableRecordsIds'
 *   },
 * });
 */
export function useGetAccountPayableRecordsByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPayableRecordsByIdsQuery, GetAccountPayableRecordsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPayableRecordsByIdsQuery, GetAccountPayableRecordsByIdsQueryVariables>(GetAccountPayableRecordsByIdsDocument, options);
      }
export function useGetAccountPayableRecordsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPayableRecordsByIdsQuery, GetAccountPayableRecordsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPayableRecordsByIdsQuery, GetAccountPayableRecordsByIdsQueryVariables>(GetAccountPayableRecordsByIdsDocument, options);
        }
export type GetAccountPayableRecordsByIdsQueryHookResult = ReturnType<typeof useGetAccountPayableRecordsByIdsQuery>;
export type GetAccountPayableRecordsByIdsLazyQueryHookResult = ReturnType<typeof useGetAccountPayableRecordsByIdsLazyQuery>;
export type GetAccountPayableRecordsByIdsQueryResult = Apollo.QueryResult<GetAccountPayableRecordsByIdsQuery, GetAccountPayableRecordsByIdsQueryVariables>;
export function refetchGetAccountPayableRecordsByIdsQuery(variables?: GetAccountPayableRecordsByIdsQueryVariables) {
      return { query: GetAccountPayableRecordsByIdsDocument, variables: variables }
    }
export const AllAccountPayableRecordsWithBatchLineDocument = gql`
    query allAccountPayableRecordsWithBatchLine($after: String, $before: String, $first: Int, $last: Int, $order: [AccountPayableRecordSortInput!], $where: AccountPayableRecordFilterInput) {
  accountPayableRecords(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        status
        statusLabel
        net
        vat
        gross
        currency {
          symbol
        }
        openQueries {
          id
          reason
        }
        bookingInvoice {
          id
          invoiceDate
          driverInvoiceNumber
          dueDate
          paymentDate
          bookingDriver {
            id
            driver {
              id
              name
            }
          }
          booking {
            id
            ourReference
          }
        }
        bookingInvoiceBatchLine {
          id
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useAllAccountPayableRecordsWithBatchLineQuery__
 *
 * To run a query within a React component, call `useAllAccountPayableRecordsWithBatchLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAccountPayableRecordsWithBatchLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAccountPayableRecordsWithBatchLineQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllAccountPayableRecordsWithBatchLineQuery(baseOptions?: Apollo.QueryHookOptions<AllAccountPayableRecordsWithBatchLineQuery, AllAccountPayableRecordsWithBatchLineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAccountPayableRecordsWithBatchLineQuery, AllAccountPayableRecordsWithBatchLineQueryVariables>(AllAccountPayableRecordsWithBatchLineDocument, options);
      }
export function useAllAccountPayableRecordsWithBatchLineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAccountPayableRecordsWithBatchLineQuery, AllAccountPayableRecordsWithBatchLineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAccountPayableRecordsWithBatchLineQuery, AllAccountPayableRecordsWithBatchLineQueryVariables>(AllAccountPayableRecordsWithBatchLineDocument, options);
        }
export type AllAccountPayableRecordsWithBatchLineQueryHookResult = ReturnType<typeof useAllAccountPayableRecordsWithBatchLineQuery>;
export type AllAccountPayableRecordsWithBatchLineLazyQueryHookResult = ReturnType<typeof useAllAccountPayableRecordsWithBatchLineLazyQuery>;
export type AllAccountPayableRecordsWithBatchLineQueryResult = Apollo.QueryResult<AllAccountPayableRecordsWithBatchLineQuery, AllAccountPayableRecordsWithBatchLineQueryVariables>;
export function refetchAllAccountPayableRecordsWithBatchLineQuery(variables?: AllAccountPayableRecordsWithBatchLineQueryVariables) {
      return { query: AllAccountPayableRecordsWithBatchLineDocument, variables: variables }
    }
export const AccountPayableRecordLogsDocument = gql`
    query accountPayableRecordLogs($after: String, $before: String, $first: Int, $last: Int, $accountPayableRecordId: Long!) {
  accountPayableRecordLogs(
    after: $after
    before: $before
    first: $first
    last: $last
    accountPayableRecordId: $accountPayableRecordId
  ) {
    edges {
      node {
        id
        description
        reason
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useAccountPayableRecordLogsQuery__
 *
 * To run a query within a React component, call `useAccountPayableRecordLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPayableRecordLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPayableRecordLogsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      accountPayableRecordId: // value for 'accountPayableRecordId'
 *   },
 * });
 */
export function useAccountPayableRecordLogsQuery(baseOptions: Apollo.QueryHookOptions<AccountPayableRecordLogsQuery, AccountPayableRecordLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountPayableRecordLogsQuery, AccountPayableRecordLogsQueryVariables>(AccountPayableRecordLogsDocument, options);
      }
export function useAccountPayableRecordLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountPayableRecordLogsQuery, AccountPayableRecordLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountPayableRecordLogsQuery, AccountPayableRecordLogsQueryVariables>(AccountPayableRecordLogsDocument, options);
        }
export type AccountPayableRecordLogsQueryHookResult = ReturnType<typeof useAccountPayableRecordLogsQuery>;
export type AccountPayableRecordLogsLazyQueryHookResult = ReturnType<typeof useAccountPayableRecordLogsLazyQuery>;
export type AccountPayableRecordLogsQueryResult = Apollo.QueryResult<AccountPayableRecordLogsQuery, AccountPayableRecordLogsQueryVariables>;
export function refetchAccountPayableRecordLogsQuery(variables?: AccountPayableRecordLogsQueryVariables) {
      return { query: AccountPayableRecordLogsDocument, variables: variables }
    }
export const GetAccountPayableDetailsDocument = gql`
    query GetAccountPayableDetails($id: Long!) {
  accountPayableRecord(accountPayableRecordId: $id) {
    id
    net
    currency {
      id
      symbol
    }
  }
}
    `;

/**
 * __useGetAccountPayableDetailsQuery__
 *
 * To run a query within a React component, call `useGetAccountPayableDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPayableDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPayableDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountPayableDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountPayableDetailsQuery, GetAccountPayableDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPayableDetailsQuery, GetAccountPayableDetailsQueryVariables>(GetAccountPayableDetailsDocument, options);
      }
export function useGetAccountPayableDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPayableDetailsQuery, GetAccountPayableDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPayableDetailsQuery, GetAccountPayableDetailsQueryVariables>(GetAccountPayableDetailsDocument, options);
        }
export type GetAccountPayableDetailsQueryHookResult = ReturnType<typeof useGetAccountPayableDetailsQuery>;
export type GetAccountPayableDetailsLazyQueryHookResult = ReturnType<typeof useGetAccountPayableDetailsLazyQuery>;
export type GetAccountPayableDetailsQueryResult = Apollo.QueryResult<GetAccountPayableDetailsQuery, GetAccountPayableDetailsQueryVariables>;
export function refetchGetAccountPayableDetailsQuery(variables?: GetAccountPayableDetailsQueryVariables) {
      return { query: GetAccountPayableDetailsDocument, variables: variables }
    }
export const GetAcquisitionSourcesDocument = gql`
    query GetAcquisitionSources {
  acquisitionSources {
    id
    name
  }
}
    `;

/**
 * __useGetAcquisitionSourcesQuery__
 *
 * To run a query within a React component, call `useGetAcquisitionSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcquisitionSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcquisitionSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAcquisitionSourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetAcquisitionSourcesQuery, GetAcquisitionSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAcquisitionSourcesQuery, GetAcquisitionSourcesQueryVariables>(GetAcquisitionSourcesDocument, options);
      }
export function useGetAcquisitionSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAcquisitionSourcesQuery, GetAcquisitionSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAcquisitionSourcesQuery, GetAcquisitionSourcesQueryVariables>(GetAcquisitionSourcesDocument, options);
        }
export type GetAcquisitionSourcesQueryHookResult = ReturnType<typeof useGetAcquisitionSourcesQuery>;
export type GetAcquisitionSourcesLazyQueryHookResult = ReturnType<typeof useGetAcquisitionSourcesLazyQuery>;
export type GetAcquisitionSourcesQueryResult = Apollo.QueryResult<GetAcquisitionSourcesQuery, GetAcquisitionSourcesQueryVariables>;
export function refetchGetAcquisitionSourcesQuery(variables?: GetAcquisitionSourcesQueryVariables) {
      return { query: GetAcquisitionSourcesDocument, variables: variables }
    }
export const GetActivitiesDocument = gql`
    query getActivities($after: String, $before: String, $first: Int, $last: Int, $order: [ActivitySortInput!], $where: ActivityFilterInput) {
  activities(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        ...ActivityDetails
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    ${ActivityDetailsFragmentDoc}`;

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, options);
      }
export function useGetActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, options);
        }
export type GetActivitiesQueryHookResult = ReturnType<typeof useGetActivitiesQuery>;
export type GetActivitiesLazyQueryHookResult = ReturnType<typeof useGetActivitiesLazyQuery>;
export type GetActivitiesQueryResult = Apollo.QueryResult<GetActivitiesQuery, GetActivitiesQueryVariables>;
export function refetchGetActivitiesQuery(variables?: GetActivitiesQueryVariables) {
      return { query: GetActivitiesDocument, variables: variables }
    }
export const GetActivityDocument = gql`
    query getActivity($id: Long!) {
  activity(id: $id) {
    ...ActivityDetails
  }
}
    ${ActivityDetailsFragmentDoc}`;

/**
 * __useGetActivityQuery__
 *
 * To run a query within a React component, call `useGetActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityQuery(baseOptions: Apollo.QueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
      }
export function useGetActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
        }
export type GetActivityQueryHookResult = ReturnType<typeof useGetActivityQuery>;
export type GetActivityLazyQueryHookResult = ReturnType<typeof useGetActivityLazyQuery>;
export type GetActivityQueryResult = Apollo.QueryResult<GetActivityQuery, GetActivityQueryVariables>;
export function refetchGetActivityQuery(variables?: GetActivityQueryVariables) {
      return { query: GetActivityDocument, variables: variables }
    }
export const GetAddressDocument = gql`
    query GetAddress($id: Long!) {
  address(id: $id) {
    id
    name
    city
    addressLine1
    addressLine2
    postcode
    email
    countryId
    telephoneNumber
    contact
    isNotification
    isAutoFill
    isDefault
  }
}
    `;

/**
 * __useGetAddressQuery__
 *
 * To run a query within a React component, call `useGetAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAddressQuery(baseOptions: Apollo.QueryHookOptions<GetAddressQuery, GetAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddressQuery, GetAddressQueryVariables>(GetAddressDocument, options);
      }
export function useGetAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressQuery, GetAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddressQuery, GetAddressQueryVariables>(GetAddressDocument, options);
        }
export type GetAddressQueryHookResult = ReturnType<typeof useGetAddressQuery>;
export type GetAddressLazyQueryHookResult = ReturnType<typeof useGetAddressLazyQuery>;
export type GetAddressQueryResult = Apollo.QueryResult<GetAddressQuery, GetAddressQueryVariables>;
export function refetchGetAddressQuery(variables?: GetAddressQueryVariables) {
      return { query: GetAddressDocument, variables: variables }
    }
export const GetPagedAddressesDocument = gql`
    query GetPagedAddresses($customerId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [AddressSortInput!], $where: AddressFilterInput) {
  addresses(
    customerId: $customerId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        addressLine1
        addressLine2
        city
        postcode
        email
        telephoneNumber
        isDefault
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedAddressesQuery__
 *
 * To run a query within a React component, call `useGetPagedAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedAddressesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedAddressesQuery(baseOptions: Apollo.QueryHookOptions<GetPagedAddressesQuery, GetPagedAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedAddressesQuery, GetPagedAddressesQueryVariables>(GetPagedAddressesDocument, options);
      }
export function useGetPagedAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedAddressesQuery, GetPagedAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedAddressesQuery, GetPagedAddressesQueryVariables>(GetPagedAddressesDocument, options);
        }
export type GetPagedAddressesQueryHookResult = ReturnType<typeof useGetPagedAddressesQuery>;
export type GetPagedAddressesLazyQueryHookResult = ReturnType<typeof useGetPagedAddressesLazyQuery>;
export type GetPagedAddressesQueryResult = Apollo.QueryResult<GetPagedAddressesQuery, GetPagedAddressesQueryVariables>;
export function refetchGetPagedAddressesQuery(variables?: GetPagedAddressesQueryVariables) {
      return { query: GetPagedAddressesDocument, variables: variables }
    }
export const GetCustomerDefaultAddressDocument = gql`
    query GetCustomerDefaultAddress($customerId: Long!, $where: AddressFilterInput = {isDefault: {eq: true}}) {
  addresses(customerId: $customerId, where: $where) {
    edges {
      node {
        id
        name
        city
        addressLine1
        addressLine2
        postcode
        countryId
        contact
        email
        telephoneNumber
        isDefault
      }
    }
  }
}
    `;

/**
 * __useGetCustomerDefaultAddressQuery__
 *
 * To run a query within a React component, call `useGetCustomerDefaultAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDefaultAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDefaultAddressQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCustomerDefaultAddressQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerDefaultAddressQuery, GetCustomerDefaultAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerDefaultAddressQuery, GetCustomerDefaultAddressQueryVariables>(GetCustomerDefaultAddressDocument, options);
      }
export function useGetCustomerDefaultAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerDefaultAddressQuery, GetCustomerDefaultAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerDefaultAddressQuery, GetCustomerDefaultAddressQueryVariables>(GetCustomerDefaultAddressDocument, options);
        }
export type GetCustomerDefaultAddressQueryHookResult = ReturnType<typeof useGetCustomerDefaultAddressQuery>;
export type GetCustomerDefaultAddressLazyQueryHookResult = ReturnType<typeof useGetCustomerDefaultAddressLazyQuery>;
export type GetCustomerDefaultAddressQueryResult = Apollo.QueryResult<GetCustomerDefaultAddressQuery, GetCustomerDefaultAddressQueryVariables>;
export function refetchGetCustomerDefaultAddressQuery(variables?: GetCustomerDefaultAddressQueryVariables) {
      return { query: GetCustomerDefaultAddressDocument, variables: variables }
    }
export const GetLoqateFindDocument = gql`
    query GetLoqateFind($text: String!, $countryId: Long!, $container: String, $order: [LoqateFindDtoSortInput!], $where: LoqateFindDtoFilterInput) {
  loqateFind(
    text: $text
    countryId: $countryId
    container: $container
    order: $order
    where: $where
  ) {
    id
    type
    text
    description
    isContainer
    isRetrievable
  }
}
    `;

/**
 * __useGetLoqateFindQuery__
 *
 * To run a query within a React component, call `useGetLoqateFindQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoqateFindQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoqateFindQuery({
 *   variables: {
 *      text: // value for 'text'
 *      countryId: // value for 'countryId'
 *      container: // value for 'container'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoqateFindQuery(baseOptions: Apollo.QueryHookOptions<GetLoqateFindQuery, GetLoqateFindQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoqateFindQuery, GetLoqateFindQueryVariables>(GetLoqateFindDocument, options);
      }
export function useGetLoqateFindLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoqateFindQuery, GetLoqateFindQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoqateFindQuery, GetLoqateFindQueryVariables>(GetLoqateFindDocument, options);
        }
export type GetLoqateFindQueryHookResult = ReturnType<typeof useGetLoqateFindQuery>;
export type GetLoqateFindLazyQueryHookResult = ReturnType<typeof useGetLoqateFindLazyQuery>;
export type GetLoqateFindQueryResult = Apollo.QueryResult<GetLoqateFindQuery, GetLoqateFindQueryVariables>;
export function refetchGetLoqateFindQuery(variables?: GetLoqateFindQueryVariables) {
      return { query: GetLoqateFindDocument, variables: variables }
    }
export const GetLoqateRetrieveDocument = gql`
    query GetLoqateRetrieve($id: String!) {
  loqateRetrieve(id: $id) {
    id
    name
    postcode
    addressLine1
    addressLine2
    city
    countryId
  }
}
    `;

/**
 * __useGetLoqateRetrieveQuery__
 *
 * To run a query within a React component, call `useGetLoqateRetrieveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoqateRetrieveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoqateRetrieveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoqateRetrieveQuery(baseOptions: Apollo.QueryHookOptions<GetLoqateRetrieveQuery, GetLoqateRetrieveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoqateRetrieveQuery, GetLoqateRetrieveQueryVariables>(GetLoqateRetrieveDocument, options);
      }
export function useGetLoqateRetrieveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoqateRetrieveQuery, GetLoqateRetrieveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoqateRetrieveQuery, GetLoqateRetrieveQueryVariables>(GetLoqateRetrieveDocument, options);
        }
export type GetLoqateRetrieveQueryHookResult = ReturnType<typeof useGetLoqateRetrieveQuery>;
export type GetLoqateRetrieveLazyQueryHookResult = ReturnType<typeof useGetLoqateRetrieveLazyQuery>;
export type GetLoqateRetrieveQueryResult = Apollo.QueryResult<GetLoqateRetrieveQuery, GetLoqateRetrieveQueryVariables>;
export function refetchGetLoqateRetrieveQuery(variables?: GetLoqateRetrieveQueryVariables) {
      return { query: GetLoqateRetrieveDocument, variables: variables }
    }
export const GetDistanceCalculationDocument = gql`
    query GetDistanceCalculation($input: [GoogleAddressDtoInput!]!) {
  distanceCalculation(input: $input) {
    id
    status
    errorMessage
    distance
  }
}
    `;

/**
 * __useGetDistanceCalculationQuery__
 *
 * To run a query within a React component, call `useGetDistanceCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistanceCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistanceCalculationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDistanceCalculationQuery(baseOptions: Apollo.QueryHookOptions<GetDistanceCalculationQuery, GetDistanceCalculationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistanceCalculationQuery, GetDistanceCalculationQueryVariables>(GetDistanceCalculationDocument, options);
      }
export function useGetDistanceCalculationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistanceCalculationQuery, GetDistanceCalculationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistanceCalculationQuery, GetDistanceCalculationQueryVariables>(GetDistanceCalculationDocument, options);
        }
export type GetDistanceCalculationQueryHookResult = ReturnType<typeof useGetDistanceCalculationQuery>;
export type GetDistanceCalculationLazyQueryHookResult = ReturnType<typeof useGetDistanceCalculationLazyQuery>;
export type GetDistanceCalculationQueryResult = Apollo.QueryResult<GetDistanceCalculationQuery, GetDistanceCalculationQueryVariables>;
export function refetchGetDistanceCalculationQuery(variables?: GetDistanceCalculationQueryVariables) {
      return { query: GetDistanceCalculationDocument, variables: variables }
    }
export const GetPagedBannersDocument = gql`
    query GetPagedBanners($after: String, $before: String, $first: Int, $last: Int, $order: [BannerSortInput!], $where: BannerFilterInput) {
  pagedBanners(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        isActive
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedBannersQuery__
 *
 * To run a query within a React component, call `useGetPagedBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedBannersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedBannersQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedBannersQuery, GetPagedBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedBannersQuery, GetPagedBannersQueryVariables>(GetPagedBannersDocument, options);
      }
export function useGetPagedBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedBannersQuery, GetPagedBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedBannersQuery, GetPagedBannersQueryVariables>(GetPagedBannersDocument, options);
        }
export type GetPagedBannersQueryHookResult = ReturnType<typeof useGetPagedBannersQuery>;
export type GetPagedBannersLazyQueryHookResult = ReturnType<typeof useGetPagedBannersLazyQuery>;
export type GetPagedBannersQueryResult = Apollo.QueryResult<GetPagedBannersQuery, GetPagedBannersQueryVariables>;
export function refetchGetPagedBannersQuery(variables?: GetPagedBannersQueryVariables) {
      return { query: GetPagedBannersDocument, variables: variables }
    }
export const GetBannersDocument = gql`
    query GetBanners($where: BannerFilterInput) {
  banners(where: $where) {
    id
    name
    isActive
    file {
      id
      ...FileDetails
    }
  }
}
    ${FileDetailsFragmentDoc}`;

/**
 * __useGetBannersQuery__
 *
 * To run a query within a React component, call `useGetBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBannersQuery(baseOptions?: Apollo.QueryHookOptions<GetBannersQuery, GetBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBannersQuery, GetBannersQueryVariables>(GetBannersDocument, options);
      }
export function useGetBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBannersQuery, GetBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBannersQuery, GetBannersQueryVariables>(GetBannersDocument, options);
        }
export type GetBannersQueryHookResult = ReturnType<typeof useGetBannersQuery>;
export type GetBannersLazyQueryHookResult = ReturnType<typeof useGetBannersLazyQuery>;
export type GetBannersQueryResult = Apollo.QueryResult<GetBannersQuery, GetBannersQueryVariables>;
export function refetchGetBannersQuery(variables?: GetBannersQueryVariables) {
      return { query: GetBannersDocument, variables: variables }
    }
export const GetBannerDocument = gql`
    query GetBanner($id: Long!) {
  banner(id: $id) {
    id
    name
    type
    isActive
    fileId
    file {
      id
      ...FileDetails
    }
  }
}
    ${FileDetailsFragmentDoc}`;

/**
 * __useGetBannerQuery__
 *
 * To run a query within a React component, call `useGetBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBannerQuery(baseOptions: Apollo.QueryHookOptions<GetBannerQuery, GetBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBannerQuery, GetBannerQueryVariables>(GetBannerDocument, options);
      }
export function useGetBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBannerQuery, GetBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBannerQuery, GetBannerQueryVariables>(GetBannerDocument, options);
        }
export type GetBannerQueryHookResult = ReturnType<typeof useGetBannerQuery>;
export type GetBannerLazyQueryHookResult = ReturnType<typeof useGetBannerLazyQuery>;
export type GetBannerQueryResult = Apollo.QueryResult<GetBannerQuery, GetBannerQueryVariables>;
export function refetchGetBannerQuery(variables?: GetBannerQueryVariables) {
      return { query: GetBannerDocument, variables: variables }
    }
export const GetBookingAttachmentDocument = gql`
    query getBookingAttachment($bookingId: Long!, $fileId: Long!) {
  bookingAttachment(bookingId: $bookingId, fileId: $fileId) {
    id
    isAvailableForDriver
    isAvailableForCustomer
    fileId
    file {
      id
      absoluteUri
    }
    bookingId
    createdBy
  }
}
    `;

/**
 * __useGetBookingAttachmentQuery__
 *
 * To run a query within a React component, call `useGetBookingAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAttachmentQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetBookingAttachmentQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAttachmentQuery, GetBookingAttachmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAttachmentQuery, GetBookingAttachmentQueryVariables>(GetBookingAttachmentDocument, options);
      }
export function useGetBookingAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAttachmentQuery, GetBookingAttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAttachmentQuery, GetBookingAttachmentQueryVariables>(GetBookingAttachmentDocument, options);
        }
export type GetBookingAttachmentQueryHookResult = ReturnType<typeof useGetBookingAttachmentQuery>;
export type GetBookingAttachmentLazyQueryHookResult = ReturnType<typeof useGetBookingAttachmentLazyQuery>;
export type GetBookingAttachmentQueryResult = Apollo.QueryResult<GetBookingAttachmentQuery, GetBookingAttachmentQueryVariables>;
export function refetchGetBookingAttachmentQuery(variables?: GetBookingAttachmentQueryVariables) {
      return { query: GetBookingAttachmentDocument, variables: variables }
    }
export const GetBookingAttachmentsDocument = gql`
    query GetBookingAttachments($bookingId: Long!, $after: String, $before: String, $first: Int, $last: Int, $where: FileFilterInput, $order: [FileSortInput!]) {
  bookingAttachments(
    bookingId: $bookingId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        category
        size
        description
        fileOrigin
        originalFileName
        bookingAttachmentId
        bookingAttachment {
          id
          isAvailableForDriver
          isAvailableForCustomer
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetBookingAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetBookingAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAttachmentsQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetBookingAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAttachmentsQuery, GetBookingAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAttachmentsQuery, GetBookingAttachmentsQueryVariables>(GetBookingAttachmentsDocument, options);
      }
export function useGetBookingAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAttachmentsQuery, GetBookingAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAttachmentsQuery, GetBookingAttachmentsQueryVariables>(GetBookingAttachmentsDocument, options);
        }
export type GetBookingAttachmentsQueryHookResult = ReturnType<typeof useGetBookingAttachmentsQuery>;
export type GetBookingAttachmentsLazyQueryHookResult = ReturnType<typeof useGetBookingAttachmentsLazyQuery>;
export type GetBookingAttachmentsQueryResult = Apollo.QueryResult<GetBookingAttachmentsQuery, GetBookingAttachmentsQueryVariables>;
export function refetchGetBookingAttachmentsQuery(variables?: GetBookingAttachmentsQueryVariables) {
      return { query: GetBookingAttachmentsDocument, variables: variables }
    }
export const GetBookingInvoiceBatchesDocument = gql`
    query GetBookingInvoiceBatches($after: String, $before: String, $first: Int, $last: Int, $order: [BookingInvoiceBatchSortInput!], $where: BookingInvoiceBatchFilterInput) {
  bookingInvoiceBatches(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        date
        paymentDate
        createdAt
        lastModifiedAt
        currencyId
        isSentToXero
        currencyId
        currency {
          code
        }
        isSentToXero
        isSuccess
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetBookingInvoiceBatchesQuery__
 *
 * To run a query within a React component, call `useGetBookingInvoiceBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingInvoiceBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingInvoiceBatchesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBookingInvoiceBatchesQuery(baseOptions?: Apollo.QueryHookOptions<GetBookingInvoiceBatchesQuery, GetBookingInvoiceBatchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingInvoiceBatchesQuery, GetBookingInvoiceBatchesQueryVariables>(GetBookingInvoiceBatchesDocument, options);
      }
export function useGetBookingInvoiceBatchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingInvoiceBatchesQuery, GetBookingInvoiceBatchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingInvoiceBatchesQuery, GetBookingInvoiceBatchesQueryVariables>(GetBookingInvoiceBatchesDocument, options);
        }
export type GetBookingInvoiceBatchesQueryHookResult = ReturnType<typeof useGetBookingInvoiceBatchesQuery>;
export type GetBookingInvoiceBatchesLazyQueryHookResult = ReturnType<typeof useGetBookingInvoiceBatchesLazyQuery>;
export type GetBookingInvoiceBatchesQueryResult = Apollo.QueryResult<GetBookingInvoiceBatchesQuery, GetBookingInvoiceBatchesQueryVariables>;
export function refetchGetBookingInvoiceBatchesQuery(variables?: GetBookingInvoiceBatchesQueryVariables) {
      return { query: GetBookingInvoiceBatchesDocument, variables: variables }
    }
export const AllbookingInvoiceBatchLinesDocument = gql`
    query allbookingInvoiceBatchLines($after: String, $before: String, $first: Int, $last: Int, $order: [BookingInvoiceBatchLineSortInput!], $where: BookingInvoiceBatchLineFilterInput) {
  bookingInvoiceBatchLines(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        accountCode
        taxType
        total
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useAllbookingInvoiceBatchLinesQuery__
 *
 * To run a query within a React component, call `useAllbookingInvoiceBatchLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllbookingInvoiceBatchLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllbookingInvoiceBatchLinesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAllbookingInvoiceBatchLinesQuery(baseOptions?: Apollo.QueryHookOptions<AllbookingInvoiceBatchLinesQuery, AllbookingInvoiceBatchLinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllbookingInvoiceBatchLinesQuery, AllbookingInvoiceBatchLinesQueryVariables>(AllbookingInvoiceBatchLinesDocument, options);
      }
export function useAllbookingInvoiceBatchLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllbookingInvoiceBatchLinesQuery, AllbookingInvoiceBatchLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllbookingInvoiceBatchLinesQuery, AllbookingInvoiceBatchLinesQueryVariables>(AllbookingInvoiceBatchLinesDocument, options);
        }
export type AllbookingInvoiceBatchLinesQueryHookResult = ReturnType<typeof useAllbookingInvoiceBatchLinesQuery>;
export type AllbookingInvoiceBatchLinesLazyQueryHookResult = ReturnType<typeof useAllbookingInvoiceBatchLinesLazyQuery>;
export type AllbookingInvoiceBatchLinesQueryResult = Apollo.QueryResult<AllbookingInvoiceBatchLinesQuery, AllbookingInvoiceBatchLinesQueryVariables>;
export function refetchAllbookingInvoiceBatchLinesQuery(variables?: AllbookingInvoiceBatchLinesQueryVariables) {
      return { query: AllbookingInvoiceBatchLinesDocument, variables: variables }
    }
export const GetBookingInvoiceBatchDocument = gql`
    query GetBookingInvoiceBatch($id: Long!) {
  bookingInvoiceBatch(bookingInvoiceBatchId: $id) {
    id
    isSentToXero
    isSuccess
    errorMessage
  }
}
    `;

/**
 * __useGetBookingInvoiceBatchQuery__
 *
 * To run a query within a React component, call `useGetBookingInvoiceBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingInvoiceBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingInvoiceBatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingInvoiceBatchQuery(baseOptions: Apollo.QueryHookOptions<GetBookingInvoiceBatchQuery, GetBookingInvoiceBatchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingInvoiceBatchQuery, GetBookingInvoiceBatchQueryVariables>(GetBookingInvoiceBatchDocument, options);
      }
export function useGetBookingInvoiceBatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingInvoiceBatchQuery, GetBookingInvoiceBatchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingInvoiceBatchQuery, GetBookingInvoiceBatchQueryVariables>(GetBookingInvoiceBatchDocument, options);
        }
export type GetBookingInvoiceBatchQueryHookResult = ReturnType<typeof useGetBookingInvoiceBatchQuery>;
export type GetBookingInvoiceBatchLazyQueryHookResult = ReturnType<typeof useGetBookingInvoiceBatchLazyQuery>;
export type GetBookingInvoiceBatchQueryResult = Apollo.QueryResult<GetBookingInvoiceBatchQuery, GetBookingInvoiceBatchQueryVariables>;
export function refetchGetBookingInvoiceBatchQuery(variables?: GetBookingInvoiceBatchQueryVariables) {
      return { query: GetBookingInvoiceBatchDocument, variables: variables }
    }
export const GetPagedBookingsDocument = gql`
    query getPagedBookings($after: String, $before: String, $first: Int, $last: Int, $order: [BookingSortInput!], $where: BookingFilterInput) {
  bookings(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        ourReference
        status
        pagedStatus
        statusLabel
        courierServicesStatusLabel
        collectionEstimatedTimeOfArrival
        deliveryEstimatedTimeOfArrival
        isOnHold
        isArchived
        isCancelledOrQuoteLost
        isPending
        isQuickQuote
        takenOn
        docs
        isUsingInternalApp
        consignmentFee
        ourReference
        contactId
        courierExchangeLoadId
        contact {
          id
          name
        }
        customer {
          id
          name
        }
        charges {
          id
          rate
          quantity
        }
        customerReference
        customerId
        customer {
          id
          name
        }
        addresses {
          id
          type
          sequenceOrder
          at
          by
          address {
            id
            name
            city
            postcode
          }
        }
        tariffId
        tariff {
          id
          name
          tariffCategory {
            id
            name
          }
          xeroCode {
            id
            code
            name
            isInternational
          }
        }
        attachments {
          id
        }
        drivers {
          charge
          driver {
            id
            name
          }
        }
        revenue
        packages {
          packageReference
        }
        hasTasks
        hasNotes
        takenBy
        salesInvoiceNumber
        invoices {
          id
          invoiceType
          invoiceNumber
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedBookingsQuery__
 *
 * To run a query within a React component, call `useGetPagedBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedBookingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedBookingsQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedBookingsQuery, GetPagedBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedBookingsQuery, GetPagedBookingsQueryVariables>(GetPagedBookingsDocument, options);
      }
export function useGetPagedBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedBookingsQuery, GetPagedBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedBookingsQuery, GetPagedBookingsQueryVariables>(GetPagedBookingsDocument, options);
        }
export type GetPagedBookingsQueryHookResult = ReturnType<typeof useGetPagedBookingsQuery>;
export type GetPagedBookingsLazyQueryHookResult = ReturnType<typeof useGetPagedBookingsLazyQuery>;
export type GetPagedBookingsQueryResult = Apollo.QueryResult<GetPagedBookingsQuery, GetPagedBookingsQueryVariables>;
export function refetchGetPagedBookingsQuery(variables?: GetPagedBookingsQueryVariables) {
      return { query: GetPagedBookingsDocument, variables: variables }
    }
export const GerUserBookingsDocument = gql`
    query GerUserBookings($after: String, $before: String, $first: Int, $last: Int, $order: [BookingSortInput!], $where: BookingFilterInput) {
  userBookings(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        ourReference
        status
        pagedStatus
        statusLabel
        collectionEstimatedTimeOfArrival
        deliveryEstimatedTimeOfArrival
        isOnHold
        isArchived
        isCancelledOrQuoteLost
        isPending
        isQuickQuote
        takenOn
        docs
        isUsingInternalApp
        consignmentFee
        ourReference
        courierExchangeLoadId
        contactId
        contact {
          id
          name
        }
        customer {
          id
          name
          isReferenceRequired
        }
        charges {
          id
          rate
          quantity
        }
        customerReference
        customerId
        customer {
          id
          name
        }
        addresses {
          id
          type
          sequenceOrder
          at
          by
          address {
            id
            name
            city
            postcode
          }
        }
        tariffId
        tariff {
          id
          name
        }
        attachments {
          id
        }
        distance
        packages {
          packageReference
        }
        hasNotes
        takenBy
        salesInvoiceNumber
        invoices {
          id
          invoiceType
          invoiceNumber
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGerUserBookingsQuery__
 *
 * To run a query within a React component, call `useGerUserBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGerUserBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGerUserBookingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGerUserBookingsQuery(baseOptions?: Apollo.QueryHookOptions<GerUserBookingsQuery, GerUserBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GerUserBookingsQuery, GerUserBookingsQueryVariables>(GerUserBookingsDocument, options);
      }
export function useGerUserBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GerUserBookingsQuery, GerUserBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GerUserBookingsQuery, GerUserBookingsQueryVariables>(GerUserBookingsDocument, options);
        }
export type GerUserBookingsQueryHookResult = ReturnType<typeof useGerUserBookingsQuery>;
export type GerUserBookingsLazyQueryHookResult = ReturnType<typeof useGerUserBookingsLazyQuery>;
export type GerUserBookingsQueryResult = Apollo.QueryResult<GerUserBookingsQuery, GerUserBookingsQueryVariables>;
export function refetchGerUserBookingsQuery(variables?: GerUserBookingsQueryVariables) {
      return { query: GerUserBookingsDocument, variables: variables }
    }
export const GetBookingByTrackingNumberDocument = gql`
    query GetBookingByTrackingNumber($trackingGuid: UUID!) {
  bookingByTrackingNumber(trackingGuid: $trackingGuid) {
    id
    distance
    consignmentFee
    trackingGuid
    trackingExpiredDate
    tariff {
      id
      rate
      minMiles
      minCharge
    }
    addresses {
      id
      sequenceOrder
      type
      address {
        id
        addressLine1
        addressLine2
        postcode
        city
        countryId
        isVerified
        name
        googleMapsPlaceId
      }
    }
  }
}
    `;

/**
 * __useGetBookingByTrackingNumberQuery__
 *
 * To run a query within a React component, call `useGetBookingByTrackingNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingByTrackingNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingByTrackingNumberQuery({
 *   variables: {
 *      trackingGuid: // value for 'trackingGuid'
 *   },
 * });
 */
export function useGetBookingByTrackingNumberQuery(baseOptions: Apollo.QueryHookOptions<GetBookingByTrackingNumberQuery, GetBookingByTrackingNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingByTrackingNumberQuery, GetBookingByTrackingNumberQueryVariables>(GetBookingByTrackingNumberDocument, options);
      }
export function useGetBookingByTrackingNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingByTrackingNumberQuery, GetBookingByTrackingNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingByTrackingNumberQuery, GetBookingByTrackingNumberQueryVariables>(GetBookingByTrackingNumberDocument, options);
        }
export type GetBookingByTrackingNumberQueryHookResult = ReturnType<typeof useGetBookingByTrackingNumberQuery>;
export type GetBookingByTrackingNumberLazyQueryHookResult = ReturnType<typeof useGetBookingByTrackingNumberLazyQuery>;
export type GetBookingByTrackingNumberQueryResult = Apollo.QueryResult<GetBookingByTrackingNumberQuery, GetBookingByTrackingNumberQueryVariables>;
export function refetchGetBookingByTrackingNumberQuery(variables?: GetBookingByTrackingNumberQueryVariables) {
      return { query: GetBookingByTrackingNumberDocument, variables: variables }
    }
export const GetBookingDocument = gql`
    query GetBooking($bookingId: Long!) {
  booking(bookingId: $bookingId) {
    id
    ...BookingDetails
    ...BookingAddresses
    ...BookingDrivers
    ...BookingCharges
    ...BookingPackages
    ...BookingTariff
  }
}
    ${BookingDetailsFragmentDoc}
${BookingAddressesFragmentDoc}
${BookingDriversFragmentDoc}
${BookingChargesFragmentDoc}
${BookingPackagesFragmentDoc}
${BookingTariffFragmentDoc}`;

/**
 * __useGetBookingQuery__
 *
 * To run a query within a React component, call `useGetBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingQuery(baseOptions: Apollo.QueryHookOptions<GetBookingQuery, GetBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingQuery, GetBookingQueryVariables>(GetBookingDocument, options);
      }
export function useGetBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingQuery, GetBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingQuery, GetBookingQueryVariables>(GetBookingDocument, options);
        }
export type GetBookingQueryHookResult = ReturnType<typeof useGetBookingQuery>;
export type GetBookingLazyQueryHookResult = ReturnType<typeof useGetBookingLazyQuery>;
export type GetBookingQueryResult = Apollo.QueryResult<GetBookingQuery, GetBookingQueryVariables>;
export function refetchGetBookingQuery(variables?: GetBookingQueryVariables) {
      return { query: GetBookingDocument, variables: variables }
    }
export const GetUserBookingDocument = gql`
    query GetUserBooking($bookingId: Long!) {
  userBooking(bookingId: $bookingId) {
    id
    ...UserBookingDetails
    ...BookingAddresses
    ...BookingCharges
    ...BookingPackages
    ...BookingTariff
  }
}
    ${UserBookingDetailsFragmentDoc}
${BookingAddressesFragmentDoc}
${BookingChargesFragmentDoc}
${BookingPackagesFragmentDoc}
${BookingTariffFragmentDoc}`;

/**
 * __useGetUserBookingQuery__
 *
 * To run a query within a React component, call `useGetUserBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBookingQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetUserBookingQuery(baseOptions: Apollo.QueryHookOptions<GetUserBookingQuery, GetUserBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBookingQuery, GetUserBookingQueryVariables>(GetUserBookingDocument, options);
      }
export function useGetUserBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBookingQuery, GetUserBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBookingQuery, GetUserBookingQueryVariables>(GetUserBookingDocument, options);
        }
export type GetUserBookingQueryHookResult = ReturnType<typeof useGetUserBookingQuery>;
export type GetUserBookingLazyQueryHookResult = ReturnType<typeof useGetUserBookingLazyQuery>;
export type GetUserBookingQueryResult = Apollo.QueryResult<GetUserBookingQuery, GetUserBookingQueryVariables>;
export function refetchGetUserBookingQuery(variables?: GetUserBookingQueryVariables) {
      return { query: GetUserBookingDocument, variables: variables }
    }
export const GetBookingAddressesDocument = gql`
    query GetBookingAddresses($bookingId: Long!) {
  booking(bookingId: $bookingId) {
    id
    ...BookingAddresses
  }
}
    ${BookingAddressesFragmentDoc}`;

/**
 * __useGetBookingAddressesQuery__
 *
 * To run a query within a React component, call `useGetBookingAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAddressesQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingAddressesQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAddressesQuery, GetBookingAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAddressesQuery, GetBookingAddressesQueryVariables>(GetBookingAddressesDocument, options);
      }
export function useGetBookingAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAddressesQuery, GetBookingAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAddressesQuery, GetBookingAddressesQueryVariables>(GetBookingAddressesDocument, options);
        }
export type GetBookingAddressesQueryHookResult = ReturnType<typeof useGetBookingAddressesQuery>;
export type GetBookingAddressesLazyQueryHookResult = ReturnType<typeof useGetBookingAddressesLazyQuery>;
export type GetBookingAddressesQueryResult = Apollo.QueryResult<GetBookingAddressesQuery, GetBookingAddressesQueryVariables>;
export function refetchGetBookingAddressesQuery(variables?: GetBookingAddressesQueryVariables) {
      return { query: GetBookingAddressesDocument, variables: variables }
    }
export const GetBookingAddressesDataDocument = gql`
    query GetBookingAddressesData($bookingId: Long!, $where: BookingAddressFilterInput) {
  bookingAddresses(bookingId: $bookingId, where: $where) {
    id
    address {
      id
      name
      postcode
    }
  }
}
    `;

/**
 * __useGetBookingAddressesDataQuery__
 *
 * To run a query within a React component, call `useGetBookingAddressesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAddressesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAddressesDataQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBookingAddressesDataQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAddressesDataQuery, GetBookingAddressesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAddressesDataQuery, GetBookingAddressesDataQueryVariables>(GetBookingAddressesDataDocument, options);
      }
export function useGetBookingAddressesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAddressesDataQuery, GetBookingAddressesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAddressesDataQuery, GetBookingAddressesDataQueryVariables>(GetBookingAddressesDataDocument, options);
        }
export type GetBookingAddressesDataQueryHookResult = ReturnType<typeof useGetBookingAddressesDataQuery>;
export type GetBookingAddressesDataLazyQueryHookResult = ReturnType<typeof useGetBookingAddressesDataLazyQuery>;
export type GetBookingAddressesDataQueryResult = Apollo.QueryResult<GetBookingAddressesDataQuery, GetBookingAddressesDataQueryVariables>;
export function refetchGetBookingAddressesDataQuery(variables?: GetBookingAddressesDataQueryVariables) {
      return { query: GetBookingAddressesDataDocument, variables: variables }
    }
export const GetUserBookingAddressesDocument = gql`
    query GetUserBookingAddresses($bookingId: Long!) {
  userBooking(bookingId: $bookingId) {
    id
    ...BookingAddresses
  }
}
    ${BookingAddressesFragmentDoc}`;

/**
 * __useGetUserBookingAddressesQuery__
 *
 * To run a query within a React component, call `useGetUserBookingAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBookingAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBookingAddressesQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetUserBookingAddressesQuery(baseOptions: Apollo.QueryHookOptions<GetUserBookingAddressesQuery, GetUserBookingAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBookingAddressesQuery, GetUserBookingAddressesQueryVariables>(GetUserBookingAddressesDocument, options);
      }
export function useGetUserBookingAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBookingAddressesQuery, GetUserBookingAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBookingAddressesQuery, GetUserBookingAddressesQueryVariables>(GetUserBookingAddressesDocument, options);
        }
export type GetUserBookingAddressesQueryHookResult = ReturnType<typeof useGetUserBookingAddressesQuery>;
export type GetUserBookingAddressesLazyQueryHookResult = ReturnType<typeof useGetUserBookingAddressesLazyQuery>;
export type GetUserBookingAddressesQueryResult = Apollo.QueryResult<GetUserBookingAddressesQuery, GetUserBookingAddressesQueryVariables>;
export function refetchGetUserBookingAddressesQuery(variables?: GetUserBookingAddressesQueryVariables) {
      return { query: GetUserBookingAddressesDocument, variables: variables }
    }
export const GetBookingAddressSignatureDocument = gql`
    query GetBookingAddressSignature($bookingAddressId: Long!) {
  bookingAddressSignature(id: $bookingAddressId) {
    id
    ...FileDetails
  }
}
    ${FileDetailsFragmentDoc}`;

/**
 * __useGetBookingAddressSignatureQuery__
 *
 * To run a query within a React component, call `useGetBookingAddressSignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAddressSignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAddressSignatureQuery({
 *   variables: {
 *      bookingAddressId: // value for 'bookingAddressId'
 *   },
 * });
 */
export function useGetBookingAddressSignatureQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAddressSignatureQuery, GetBookingAddressSignatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAddressSignatureQuery, GetBookingAddressSignatureQueryVariables>(GetBookingAddressSignatureDocument, options);
      }
export function useGetBookingAddressSignatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAddressSignatureQuery, GetBookingAddressSignatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAddressSignatureQuery, GetBookingAddressSignatureQueryVariables>(GetBookingAddressSignatureDocument, options);
        }
export type GetBookingAddressSignatureQueryHookResult = ReturnType<typeof useGetBookingAddressSignatureQuery>;
export type GetBookingAddressSignatureLazyQueryHookResult = ReturnType<typeof useGetBookingAddressSignatureLazyQuery>;
export type GetBookingAddressSignatureQueryResult = Apollo.QueryResult<GetBookingAddressSignatureQuery, GetBookingAddressSignatureQueryVariables>;
export function refetchGetBookingAddressSignatureQuery(variables?: GetBookingAddressSignatureQueryVariables) {
      return { query: GetBookingAddressSignatureDocument, variables: variables }
    }
export const GetBookingAddressAttachmentsDocument = gql`
    query GetBookingAddressAttachments($id: Long!) {
  bookingAddress(id: $id) {
    id
    type
    bookingId
    attachments {
      id
      ...FileDetails
    }
  }
}
    ${FileDetailsFragmentDoc}`;

/**
 * __useGetBookingAddressAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetBookingAddressAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAddressAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAddressAttachmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingAddressAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAddressAttachmentsQuery, GetBookingAddressAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAddressAttachmentsQuery, GetBookingAddressAttachmentsQueryVariables>(GetBookingAddressAttachmentsDocument, options);
      }
export function useGetBookingAddressAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAddressAttachmentsQuery, GetBookingAddressAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAddressAttachmentsQuery, GetBookingAddressAttachmentsQueryVariables>(GetBookingAddressAttachmentsDocument, options);
        }
export type GetBookingAddressAttachmentsQueryHookResult = ReturnType<typeof useGetBookingAddressAttachmentsQuery>;
export type GetBookingAddressAttachmentsLazyQueryHookResult = ReturnType<typeof useGetBookingAddressAttachmentsLazyQuery>;
export type GetBookingAddressAttachmentsQueryResult = Apollo.QueryResult<GetBookingAddressAttachmentsQuery, GetBookingAddressAttachmentsQueryVariables>;
export function refetchGetBookingAddressAttachmentsQuery(variables?: GetBookingAddressAttachmentsQueryVariables) {
      return { query: GetBookingAddressAttachmentsDocument, variables: variables }
    }
export const GetBookingAllocatedDriversDocument = gql`
    query GetBookingAllocatedDrivers($bookingId: Long!, $order: [BookingDriverSortInput!], $where: BookingDriverFilterInput) {
  bookingAllocatedDrivers(bookingId: $bookingId, order: $order, where: $where) {
    id
    charge
    extraPayment
    driver {
      id
      name
    }
    vehicle {
      id
      vehicleType {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetBookingAllocatedDriversQuery__
 *
 * To run a query within a React component, call `useGetBookingAllocatedDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAllocatedDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAllocatedDriversQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBookingAllocatedDriversQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAllocatedDriversQuery, GetBookingAllocatedDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAllocatedDriversQuery, GetBookingAllocatedDriversQueryVariables>(GetBookingAllocatedDriversDocument, options);
      }
export function useGetBookingAllocatedDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAllocatedDriversQuery, GetBookingAllocatedDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAllocatedDriversQuery, GetBookingAllocatedDriversQueryVariables>(GetBookingAllocatedDriversDocument, options);
        }
export type GetBookingAllocatedDriversQueryHookResult = ReturnType<typeof useGetBookingAllocatedDriversQuery>;
export type GetBookingAllocatedDriversLazyQueryHookResult = ReturnType<typeof useGetBookingAllocatedDriversLazyQuery>;
export type GetBookingAllocatedDriversQueryResult = Apollo.QueryResult<GetBookingAllocatedDriversQuery, GetBookingAllocatedDriversQueryVariables>;
export function refetchGetBookingAllocatedDriversQuery(variables?: GetBookingAllocatedDriversQueryVariables) {
      return { query: GetBookingAllocatedDriversDocument, variables: variables }
    }
export const GetBookingAllocatedDriversRatingsDocument = gql`
    query GetBookingAllocatedDriversRatings($bookingId: Long!, $order: [BookingDriverSortInput!], $where: BookingDriverFilterInput) {
  bookingAllocatedDrivers(bookingId: $bookingId, order: $order, where: $where) {
    id
    rating
  }
}
    `;

/**
 * __useGetBookingAllocatedDriversRatingsQuery__
 *
 * To run a query within a React component, call `useGetBookingAllocatedDriversRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAllocatedDriversRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAllocatedDriversRatingsQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBookingAllocatedDriversRatingsQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAllocatedDriversRatingsQuery, GetBookingAllocatedDriversRatingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAllocatedDriversRatingsQuery, GetBookingAllocatedDriversRatingsQueryVariables>(GetBookingAllocatedDriversRatingsDocument, options);
      }
export function useGetBookingAllocatedDriversRatingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAllocatedDriversRatingsQuery, GetBookingAllocatedDriversRatingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAllocatedDriversRatingsQuery, GetBookingAllocatedDriversRatingsQueryVariables>(GetBookingAllocatedDriversRatingsDocument, options);
        }
export type GetBookingAllocatedDriversRatingsQueryHookResult = ReturnType<typeof useGetBookingAllocatedDriversRatingsQuery>;
export type GetBookingAllocatedDriversRatingsLazyQueryHookResult = ReturnType<typeof useGetBookingAllocatedDriversRatingsLazyQuery>;
export type GetBookingAllocatedDriversRatingsQueryResult = Apollo.QueryResult<GetBookingAllocatedDriversRatingsQuery, GetBookingAllocatedDriversRatingsQueryVariables>;
export function refetchGetBookingAllocatedDriversRatingsQuery(variables?: GetBookingAllocatedDriversRatingsQueryVariables) {
      return { query: GetBookingAllocatedDriversRatingsDocument, variables: variables }
    }
export const GetPagedBookingAllocatedDriversDocument = gql`
    query GetPagedBookingAllocatedDrivers($bookingId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [BookingDriverSortInput!], $where: BookingDriverFilterInput) {
  pagedBookingAllocatedDrivers(
    bookingId: $bookingId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        charge
        extraPayment
        createdAt
        xeroPurchaseCode
        allocationStatus
        createdAt
        totalCharge
        driverNotes
        reference
        rating
        bookingExchangeRates {
          id
          stage
          exchangeRate {
            id
            rate
            sourceCurrency {
              id
              code
            }
            targetCurrency {
              id
              code
            }
          }
        }
        driver {
          id
          name
          callSign
          xeroCode {
            id
            name
          }
          identityUserName
          isUsingInternalApp
        }
        vehicle {
          id
          vehicleType {
            id
            name
          }
        }
        currency {
          id
          code
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedBookingAllocatedDriversQuery__
 *
 * To run a query within a React component, call `useGetPagedBookingAllocatedDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedBookingAllocatedDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedBookingAllocatedDriversQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedBookingAllocatedDriversQuery(baseOptions: Apollo.QueryHookOptions<GetPagedBookingAllocatedDriversQuery, GetPagedBookingAllocatedDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedBookingAllocatedDriversQuery, GetPagedBookingAllocatedDriversQueryVariables>(GetPagedBookingAllocatedDriversDocument, options);
      }
export function useGetPagedBookingAllocatedDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedBookingAllocatedDriversQuery, GetPagedBookingAllocatedDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedBookingAllocatedDriversQuery, GetPagedBookingAllocatedDriversQueryVariables>(GetPagedBookingAllocatedDriversDocument, options);
        }
export type GetPagedBookingAllocatedDriversQueryHookResult = ReturnType<typeof useGetPagedBookingAllocatedDriversQuery>;
export type GetPagedBookingAllocatedDriversLazyQueryHookResult = ReturnType<typeof useGetPagedBookingAllocatedDriversLazyQuery>;
export type GetPagedBookingAllocatedDriversQueryResult = Apollo.QueryResult<GetPagedBookingAllocatedDriversQuery, GetPagedBookingAllocatedDriversQueryVariables>;
export function refetchGetPagedBookingAllocatedDriversQuery(variables?: GetPagedBookingAllocatedDriversQueryVariables) {
      return { query: GetPagedBookingAllocatedDriversDocument, variables: variables }
    }
export const GetBookingAllocatedDriverDocument = gql`
    query GetBookingAllocatedDriver($bookingDriverId: Long!) {
  bookingAllocatedDriver(bookingDriverId: $bookingDriverId) {
    id
    ...BookingAllocatedDriver
  }
}
    ${BookingAllocatedDriverFragmentDoc}`;

/**
 * __useGetBookingAllocatedDriverQuery__
 *
 * To run a query within a React component, call `useGetBookingAllocatedDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAllocatedDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAllocatedDriverQuery({
 *   variables: {
 *      bookingDriverId: // value for 'bookingDriverId'
 *   },
 * });
 */
export function useGetBookingAllocatedDriverQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAllocatedDriverQuery, GetBookingAllocatedDriverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAllocatedDriverQuery, GetBookingAllocatedDriverQueryVariables>(GetBookingAllocatedDriverDocument, options);
      }
export function useGetBookingAllocatedDriverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAllocatedDriverQuery, GetBookingAllocatedDriverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAllocatedDriverQuery, GetBookingAllocatedDriverQueryVariables>(GetBookingAllocatedDriverDocument, options);
        }
export type GetBookingAllocatedDriverQueryHookResult = ReturnType<typeof useGetBookingAllocatedDriverQuery>;
export type GetBookingAllocatedDriverLazyQueryHookResult = ReturnType<typeof useGetBookingAllocatedDriverLazyQuery>;
export type GetBookingAllocatedDriverQueryResult = Apollo.QueryResult<GetBookingAllocatedDriverQuery, GetBookingAllocatedDriverQueryVariables>;
export function refetchGetBookingAllocatedDriverQuery(variables?: GetBookingAllocatedDriverQueryVariables) {
      return { query: GetBookingAllocatedDriverDocument, variables: variables }
    }
export const GetInvoiceBookingsDocument = gql`
    query getInvoiceBookings($invoiceNumber: Long!, $invoiceType: InvoiceType!, $after: String, $before: String, $first: Int, $last: Int, $order: [BookingSortInput!], $where: BookingFilterInput) {
  invoiceBookings(
    invoiceNumber: $invoiceNumber
    invoiceType: $invoiceType
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        ourReference
        customerReference
        status
        createdAt
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetInvoiceBookingsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceBookingsQuery({
 *   variables: {
 *      invoiceNumber: // value for 'invoiceNumber'
 *      invoiceType: // value for 'invoiceType'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetInvoiceBookingsQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceBookingsQuery, GetInvoiceBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceBookingsQuery, GetInvoiceBookingsQueryVariables>(GetInvoiceBookingsDocument, options);
      }
export function useGetInvoiceBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceBookingsQuery, GetInvoiceBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceBookingsQuery, GetInvoiceBookingsQueryVariables>(GetInvoiceBookingsDocument, options);
        }
export type GetInvoiceBookingsQueryHookResult = ReturnType<typeof useGetInvoiceBookingsQuery>;
export type GetInvoiceBookingsLazyQueryHookResult = ReturnType<typeof useGetInvoiceBookingsLazyQuery>;
export type GetInvoiceBookingsQueryResult = Apollo.QueryResult<GetInvoiceBookingsQuery, GetInvoiceBookingsQueryVariables>;
export function refetchGetInvoiceBookingsQuery(variables?: GetInvoiceBookingsQueryVariables) {
      return { query: GetInvoiceBookingsDocument, variables: variables }
    }
export const GetBookingInvoiceDocument = gql`
    query GetBookingInvoice($customerId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [BookingInvoiceSortInput!], $where: BookingInvoiceFilterInput) {
  bookingInvoice(
    customerId: $customerId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        invoiceNumber
        invoiceDate
        reference
        bookingId
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetBookingInvoiceQuery__
 *
 * To run a query within a React component, call `useGetBookingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingInvoiceQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBookingInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetBookingInvoiceQuery, GetBookingInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingInvoiceQuery, GetBookingInvoiceQueryVariables>(GetBookingInvoiceDocument, options);
      }
export function useGetBookingInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingInvoiceQuery, GetBookingInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingInvoiceQuery, GetBookingInvoiceQueryVariables>(GetBookingInvoiceDocument, options);
        }
export type GetBookingInvoiceQueryHookResult = ReturnType<typeof useGetBookingInvoiceQuery>;
export type GetBookingInvoiceLazyQueryHookResult = ReturnType<typeof useGetBookingInvoiceLazyQuery>;
export type GetBookingInvoiceQueryResult = Apollo.QueryResult<GetBookingInvoiceQuery, GetBookingInvoiceQueryVariables>;
export function refetchGetBookingInvoiceQuery(variables?: GetBookingInvoiceQueryVariables) {
      return { query: GetBookingInvoiceDocument, variables: variables }
    }
export const GetBookingLoadIdDocument = gql`
    query GetBookingLoadId($bookingId: Long!) {
  booking(bookingId: $bookingId) {
    id
    courierExchangeLoadId
  }
}
    `;

/**
 * __useGetBookingLoadIdQuery__
 *
 * To run a query within a React component, call `useGetBookingLoadIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingLoadIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingLoadIdQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingLoadIdQuery(baseOptions: Apollo.QueryHookOptions<GetBookingLoadIdQuery, GetBookingLoadIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingLoadIdQuery, GetBookingLoadIdQueryVariables>(GetBookingLoadIdDocument, options);
      }
export function useGetBookingLoadIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingLoadIdQuery, GetBookingLoadIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingLoadIdQuery, GetBookingLoadIdQueryVariables>(GetBookingLoadIdDocument, options);
        }
export type GetBookingLoadIdQueryHookResult = ReturnType<typeof useGetBookingLoadIdQuery>;
export type GetBookingLoadIdLazyQueryHookResult = ReturnType<typeof useGetBookingLoadIdLazyQuery>;
export type GetBookingLoadIdQueryResult = Apollo.QueryResult<GetBookingLoadIdQuery, GetBookingLoadIdQueryVariables>;
export function refetchGetBookingLoadIdQuery(variables?: GetBookingLoadIdQueryVariables) {
      return { query: GetBookingLoadIdDocument, variables: variables }
    }
export const GetBookingLogsDocument = gql`
    query GetBookingLogs($bookingId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [BookingLogSortInput!]) {
  bookingLogs(
    bookingId: $bookingId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
  ) {
    edges {
      node {
        id
        createdAt
        createdBy
        type
        description
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetBookingLogsQuery__
 *
 * To run a query within a React component, call `useGetBookingLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingLogsQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetBookingLogsQuery(baseOptions: Apollo.QueryHookOptions<GetBookingLogsQuery, GetBookingLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingLogsQuery, GetBookingLogsQueryVariables>(GetBookingLogsDocument, options);
      }
export function useGetBookingLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingLogsQuery, GetBookingLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingLogsQuery, GetBookingLogsQueryVariables>(GetBookingLogsDocument, options);
        }
export type GetBookingLogsQueryHookResult = ReturnType<typeof useGetBookingLogsQuery>;
export type GetBookingLogsLazyQueryHookResult = ReturnType<typeof useGetBookingLogsLazyQuery>;
export type GetBookingLogsQueryResult = Apollo.QueryResult<GetBookingLogsQuery, GetBookingLogsQueryVariables>;
export function refetchGetBookingLogsQuery(variables?: GetBookingLogsQueryVariables) {
      return { query: GetBookingLogsDocument, variables: variables }
    }
export const GetBookingLogDocument = gql`
    query GetBookingLog($bookingLogId: Long!) {
  bookingLog(bookingLogId: $bookingLogId) {
    id
    type
    description
    details
  }
}
    `;

/**
 * __useGetBookingLogQuery__
 *
 * To run a query within a React component, call `useGetBookingLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingLogQuery({
 *   variables: {
 *      bookingLogId: // value for 'bookingLogId'
 *   },
 * });
 */
export function useGetBookingLogQuery(baseOptions: Apollo.QueryHookOptions<GetBookingLogQuery, GetBookingLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingLogQuery, GetBookingLogQueryVariables>(GetBookingLogDocument, options);
      }
export function useGetBookingLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingLogQuery, GetBookingLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingLogQuery, GetBookingLogQueryVariables>(GetBookingLogDocument, options);
        }
export type GetBookingLogQueryHookResult = ReturnType<typeof useGetBookingLogQuery>;
export type GetBookingLogLazyQueryHookResult = ReturnType<typeof useGetBookingLogLazyQuery>;
export type GetBookingLogQueryResult = Apollo.QueryResult<GetBookingLogQuery, GetBookingLogQueryVariables>;
export function refetchGetBookingLogQuery(variables?: GetBookingLogQueryVariables) {
      return { query: GetBookingLogDocument, variables: variables }
    }
export const GetBookingAuditDocument = gql`
    query GetBookingAudit($bookingId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [AuditRecordSortInput!]) {
  bookingAudit(
    bookingId: $bookingId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
  ) {
    edges {
      node {
        id
        createdAt
        createdBy
        name
        primaryKey
        action
        changes
        columnValues
        transactionId
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetBookingAuditQuery__
 *
 * To run a query within a React component, call `useGetBookingAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingAuditQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetBookingAuditQuery(baseOptions: Apollo.QueryHookOptions<GetBookingAuditQuery, GetBookingAuditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingAuditQuery, GetBookingAuditQueryVariables>(GetBookingAuditDocument, options);
      }
export function useGetBookingAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingAuditQuery, GetBookingAuditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingAuditQuery, GetBookingAuditQueryVariables>(GetBookingAuditDocument, options);
        }
export type GetBookingAuditQueryHookResult = ReturnType<typeof useGetBookingAuditQuery>;
export type GetBookingAuditLazyQueryHookResult = ReturnType<typeof useGetBookingAuditLazyQuery>;
export type GetBookingAuditQueryResult = Apollo.QueryResult<GetBookingAuditQuery, GetBookingAuditQueryVariables>;
export function refetchGetBookingAuditQuery(variables?: GetBookingAuditQueryVariables) {
      return { query: GetBookingAuditDocument, variables: variables }
    }
export const GetAuditRecordDocument = gql`
    query GetAuditRecord($auditRecordId: Long!) {
  auditRecord(auditRecordId: $auditRecordId) {
    id
    name
    action
    columnValues
    success
    errorMessage
  }
}
    `;

/**
 * __useGetAuditRecordQuery__
 *
 * To run a query within a React component, call `useGetAuditRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditRecordQuery({
 *   variables: {
 *      auditRecordId: // value for 'auditRecordId'
 *   },
 * });
 */
export function useGetAuditRecordQuery(baseOptions: Apollo.QueryHookOptions<GetAuditRecordQuery, GetAuditRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditRecordQuery, GetAuditRecordQueryVariables>(GetAuditRecordDocument, options);
      }
export function useGetAuditRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditRecordQuery, GetAuditRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditRecordQuery, GetAuditRecordQueryVariables>(GetAuditRecordDocument, options);
        }
export type GetAuditRecordQueryHookResult = ReturnType<typeof useGetAuditRecordQuery>;
export type GetAuditRecordLazyQueryHookResult = ReturnType<typeof useGetAuditRecordLazyQuery>;
export type GetAuditRecordQueryResult = Apollo.QueryResult<GetAuditRecordQuery, GetAuditRecordQueryVariables>;
export function refetchGetAuditRecordQuery(variables?: GetAuditRecordQueryVariables) {
      return { query: GetAuditRecordDocument, variables: variables }
    }
export const GetQuoteDocument = gql`
    query GetQuote($quoteId: Long!) {
  booking(bookingId: $quoteId) {
    id
    docketReference
    takenBy
    takenOn
    customer {
      id
      name
    }
    drivers {
      id
    }
    ...BookingDetails
    ...BookingAddresses
    ...BookingCharges
    ...BookingTariff
  }
}
    ${BookingDetailsFragmentDoc}
${BookingAddressesFragmentDoc}
${BookingChargesFragmentDoc}
${BookingTariffFragmentDoc}`;

/**
 * __useGetQuoteQuery__
 *
 * To run a query within a React component, call `useGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetQuoteQuery(baseOptions: Apollo.QueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
      }
export function useGetQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
        }
export type GetQuoteQueryHookResult = ReturnType<typeof useGetQuoteQuery>;
export type GetQuoteLazyQueryHookResult = ReturnType<typeof useGetQuoteLazyQuery>;
export type GetQuoteQueryResult = Apollo.QueryResult<GetQuoteQuery, GetQuoteQueryVariables>;
export function refetchGetQuoteQuery(variables?: GetQuoteQueryVariables) {
      return { query: GetQuoteDocument, variables: variables }
    }
export const GetUserQuoteDocument = gql`
    query GetUserQuote($quoteId: Long!) {
  userBooking(bookingId: $quoteId) {
    id
    docketReference
    takenBy
    takenOn
    customer {
      id
      name
    }
    drivers {
      id
    }
    ...UserBookingDetails
    ...BookingAddresses
    ...BookingCharges
    ...BookingTariff
  }
}
    ${UserBookingDetailsFragmentDoc}
${BookingAddressesFragmentDoc}
${BookingChargesFragmentDoc}
${BookingTariffFragmentDoc}`;

/**
 * __useGetUserQuoteQuery__
 *
 * To run a query within a React component, call `useGetUserQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuoteQuery({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useGetUserQuoteQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuoteQuery, GetUserQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuoteQuery, GetUserQuoteQueryVariables>(GetUserQuoteDocument, options);
      }
export function useGetUserQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuoteQuery, GetUserQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuoteQuery, GetUserQuoteQueryVariables>(GetUserQuoteDocument, options);
        }
export type GetUserQuoteQueryHookResult = ReturnType<typeof useGetUserQuoteQuery>;
export type GetUserQuoteLazyQueryHookResult = ReturnType<typeof useGetUserQuoteLazyQuery>;
export type GetUserQuoteQueryResult = Apollo.QueryResult<GetUserQuoteQuery, GetUserQuoteQueryVariables>;
export function refetchGetUserQuoteQuery(variables?: GetUserQuoteQueryVariables) {
      return { query: GetUserQuoteDocument, variables: variables }
    }
export const GetPagedCustomerInvoiceDocument = gql`
    query getPagedCustomerInvoice($customerId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [CustomerInvoiceDtoSortInput!], $where: CustomerInvoiceDtoFilterInput) {
  pagedCustomerInvoices(
    customerId: $customerId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        bookings {
          id
          status
          customerReference
          createdAt
          collectionEstimatedTimeOfArrival
        }
        bookingStatuses
        ourReferences
        customerReferences
        bookingDate
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedCustomerInvoiceQuery__
 *
 * To run a query within a React component, call `useGetPagedCustomerInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedCustomerInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedCustomerInvoiceQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedCustomerInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetPagedCustomerInvoiceQuery, GetPagedCustomerInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedCustomerInvoiceQuery, GetPagedCustomerInvoiceQueryVariables>(GetPagedCustomerInvoiceDocument, options);
      }
export function useGetPagedCustomerInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedCustomerInvoiceQuery, GetPagedCustomerInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedCustomerInvoiceQuery, GetPagedCustomerInvoiceQueryVariables>(GetPagedCustomerInvoiceDocument, options);
        }
export type GetPagedCustomerInvoiceQueryHookResult = ReturnType<typeof useGetPagedCustomerInvoiceQuery>;
export type GetPagedCustomerInvoiceLazyQueryHookResult = ReturnType<typeof useGetPagedCustomerInvoiceLazyQuery>;
export type GetPagedCustomerInvoiceQueryResult = Apollo.QueryResult<GetPagedCustomerInvoiceQuery, GetPagedCustomerInvoiceQueryVariables>;
export function refetchGetPagedCustomerInvoiceQuery(variables?: GetPagedCustomerInvoiceQueryVariables) {
      return { query: GetPagedCustomerInvoiceDocument, variables: variables }
    }
export const GetPagedInvoiceLinesDocument = gql`
    query GetPagedInvoiceLines($after: String, $before: String, $first: Int, $last: Int, $order: [BookingInvoiceContentSortInput!], $where: BookingInvoiceContentFilterInput) {
  pagedInvoiceLines(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        unitAmount
        accountCode
        description
        bookingInvoice {
          invoiceNumber
          reference
          invoiceDate
          dueDate
          bookingId
          booking {
            id
            ourReference
          }
          customer {
            name
            postcode
            customerReferenceLabel
          }
          driverInvoiceNumber
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedInvoiceLinesQuery__
 *
 * To run a query within a React component, call `useGetPagedInvoiceLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedInvoiceLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedInvoiceLinesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedInvoiceLinesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedInvoiceLinesQuery, GetPagedInvoiceLinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedInvoiceLinesQuery, GetPagedInvoiceLinesQueryVariables>(GetPagedInvoiceLinesDocument, options);
      }
export function useGetPagedInvoiceLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedInvoiceLinesQuery, GetPagedInvoiceLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedInvoiceLinesQuery, GetPagedInvoiceLinesQueryVariables>(GetPagedInvoiceLinesDocument, options);
        }
export type GetPagedInvoiceLinesQueryHookResult = ReturnType<typeof useGetPagedInvoiceLinesQuery>;
export type GetPagedInvoiceLinesLazyQueryHookResult = ReturnType<typeof useGetPagedInvoiceLinesLazyQuery>;
export type GetPagedInvoiceLinesQueryResult = Apollo.QueryResult<GetPagedInvoiceLinesQuery, GetPagedInvoiceLinesQueryVariables>;
export function refetchGetPagedInvoiceLinesQuery(variables?: GetPagedInvoiceLinesQueryVariables) {
      return { query: GetPagedInvoiceLinesDocument, variables: variables }
    }
export const GetBookingExchangeRatesDocument = gql`
    query getBookingExchangeRates($bookingId: Long!) {
  bookingExchangeRates(bookingId: $bookingId) {
    id
    stage
    bookingId
    exchangeRateId
    exchangeRate {
      ...ExchangeRateDetails
    }
    createdAt
  }
}
    ${ExchangeRateDetailsFragmentDoc}`;

/**
 * __useGetBookingExchangeRatesQuery__
 *
 * To run a query within a React component, call `useGetBookingExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingExchangeRatesQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingExchangeRatesQuery(baseOptions: Apollo.QueryHookOptions<GetBookingExchangeRatesQuery, GetBookingExchangeRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingExchangeRatesQuery, GetBookingExchangeRatesQueryVariables>(GetBookingExchangeRatesDocument, options);
      }
export function useGetBookingExchangeRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingExchangeRatesQuery, GetBookingExchangeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingExchangeRatesQuery, GetBookingExchangeRatesQueryVariables>(GetBookingExchangeRatesDocument, options);
        }
export type GetBookingExchangeRatesQueryHookResult = ReturnType<typeof useGetBookingExchangeRatesQuery>;
export type GetBookingExchangeRatesLazyQueryHookResult = ReturnType<typeof useGetBookingExchangeRatesLazyQuery>;
export type GetBookingExchangeRatesQueryResult = Apollo.QueryResult<GetBookingExchangeRatesQuery, GetBookingExchangeRatesQueryVariables>;
export function refetchGetBookingExchangeRatesQuery(variables?: GetBookingExchangeRatesQueryVariables) {
      return { query: GetBookingExchangeRatesDocument, variables: variables }
    }
export const GetBookingDriverTotalChargeDocument = gql`
    query getBookingDriverTotalCharge($bookingId: Long!) {
  booking(bookingId: $bookingId) {
    id
    totalDriverCharge
  }
}
    `;

/**
 * __useGetBookingDriverTotalChargeQuery__
 *
 * To run a query within a React component, call `useGetBookingDriverTotalChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingDriverTotalChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingDriverTotalChargeQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingDriverTotalChargeQuery(baseOptions: Apollo.QueryHookOptions<GetBookingDriverTotalChargeQuery, GetBookingDriverTotalChargeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingDriverTotalChargeQuery, GetBookingDriverTotalChargeQueryVariables>(GetBookingDriverTotalChargeDocument, options);
      }
export function useGetBookingDriverTotalChargeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingDriverTotalChargeQuery, GetBookingDriverTotalChargeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingDriverTotalChargeQuery, GetBookingDriverTotalChargeQueryVariables>(GetBookingDriverTotalChargeDocument, options);
        }
export type GetBookingDriverTotalChargeQueryHookResult = ReturnType<typeof useGetBookingDriverTotalChargeQuery>;
export type GetBookingDriverTotalChargeLazyQueryHookResult = ReturnType<typeof useGetBookingDriverTotalChargeLazyQuery>;
export type GetBookingDriverTotalChargeQueryResult = Apollo.QueryResult<GetBookingDriverTotalChargeQuery, GetBookingDriverTotalChargeQueryVariables>;
export function refetchGetBookingDriverTotalChargeQuery(variables?: GetBookingDriverTotalChargeQueryVariables) {
      return { query: GetBookingDriverTotalChargeDocument, variables: variables }
    }
export const GetPagedQuoteLostReasonsDocument = gql`
    query GetPagedQuoteLostReasons($after: String, $before: String, $first: Int, $last: Int, $order: [QuoteLostReasonSortInput!], $where: QuoteLostReasonFilterInput) {
  pagedQuoteLostReasons(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        code
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedQuoteLostReasonsQuery__
 *
 * To run a query within a React component, call `useGetPagedQuoteLostReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedQuoteLostReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedQuoteLostReasonsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedQuoteLostReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedQuoteLostReasonsQuery, GetPagedQuoteLostReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedQuoteLostReasonsQuery, GetPagedQuoteLostReasonsQueryVariables>(GetPagedQuoteLostReasonsDocument, options);
      }
export function useGetPagedQuoteLostReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedQuoteLostReasonsQuery, GetPagedQuoteLostReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedQuoteLostReasonsQuery, GetPagedQuoteLostReasonsQueryVariables>(GetPagedQuoteLostReasonsDocument, options);
        }
export type GetPagedQuoteLostReasonsQueryHookResult = ReturnType<typeof useGetPagedQuoteLostReasonsQuery>;
export type GetPagedQuoteLostReasonsLazyQueryHookResult = ReturnType<typeof useGetPagedQuoteLostReasonsLazyQuery>;
export type GetPagedQuoteLostReasonsQueryResult = Apollo.QueryResult<GetPagedQuoteLostReasonsQuery, GetPagedQuoteLostReasonsQueryVariables>;
export function refetchGetPagedQuoteLostReasonsQuery(variables?: GetPagedQuoteLostReasonsQueryVariables) {
      return { query: GetPagedQuoteLostReasonsDocument, variables: variables }
    }
export const GetQuoteLostReasonsDocument = gql`
    query GetQuoteLostReasons {
  quoteLostReasons {
    id
    name
    code
  }
}
    `;

/**
 * __useGetQuoteLostReasonsQuery__
 *
 * To run a query within a React component, call `useGetQuoteLostReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteLostReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteLostReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuoteLostReasonsQuery(baseOptions?: Apollo.QueryHookOptions<GetQuoteLostReasonsQuery, GetQuoteLostReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteLostReasonsQuery, GetQuoteLostReasonsQueryVariables>(GetQuoteLostReasonsDocument, options);
      }
export function useGetQuoteLostReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteLostReasonsQuery, GetQuoteLostReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteLostReasonsQuery, GetQuoteLostReasonsQueryVariables>(GetQuoteLostReasonsDocument, options);
        }
export type GetQuoteLostReasonsQueryHookResult = ReturnType<typeof useGetQuoteLostReasonsQuery>;
export type GetQuoteLostReasonsLazyQueryHookResult = ReturnType<typeof useGetQuoteLostReasonsLazyQuery>;
export type GetQuoteLostReasonsQueryResult = Apollo.QueryResult<GetQuoteLostReasonsQuery, GetQuoteLostReasonsQueryVariables>;
export function refetchGetQuoteLostReasonsQuery(variables?: GetQuoteLostReasonsQueryVariables) {
      return { query: GetQuoteLostReasonsDocument, variables: variables }
    }
export const GetQuoteLostReasonDocument = gql`
    query GetQuoteLostReason($id: Long!) {
  quoteLostReason(id: $id) {
    id
    name
    code
  }
}
    `;

/**
 * __useGetQuoteLostReasonQuery__
 *
 * To run a query within a React component, call `useGetQuoteLostReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteLostReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteLostReasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuoteLostReasonQuery(baseOptions: Apollo.QueryHookOptions<GetQuoteLostReasonQuery, GetQuoteLostReasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteLostReasonQuery, GetQuoteLostReasonQueryVariables>(GetQuoteLostReasonDocument, options);
      }
export function useGetQuoteLostReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteLostReasonQuery, GetQuoteLostReasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteLostReasonQuery, GetQuoteLostReasonQueryVariables>(GetQuoteLostReasonDocument, options);
        }
export type GetQuoteLostReasonQueryHookResult = ReturnType<typeof useGetQuoteLostReasonQuery>;
export type GetQuoteLostReasonLazyQueryHookResult = ReturnType<typeof useGetQuoteLostReasonLazyQuery>;
export type GetQuoteLostReasonQueryResult = Apollo.QueryResult<GetQuoteLostReasonQuery, GetQuoteLostReasonQueryVariables>;
export function refetchGetQuoteLostReasonQuery(variables?: GetQuoteLostReasonQueryVariables) {
      return { query: GetQuoteLostReasonDocument, variables: variables }
    }
export const GetBookingUsersDocument = gql`
    query getBookingUsers($where: BookingUserDtoFilterInput) {
  bookingUsers(where: $where) {
    id
    email
    userName
    lastName
    firstName
  }
}
    `;

/**
 * __useGetBookingUsersQuery__
 *
 * To run a query within a React component, call `useGetBookingUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBookingUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetBookingUsersQuery, GetBookingUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingUsersQuery, GetBookingUsersQueryVariables>(GetBookingUsersDocument, options);
      }
export function useGetBookingUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingUsersQuery, GetBookingUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingUsersQuery, GetBookingUsersQueryVariables>(GetBookingUsersDocument, options);
        }
export type GetBookingUsersQueryHookResult = ReturnType<typeof useGetBookingUsersQuery>;
export type GetBookingUsersLazyQueryHookResult = ReturnType<typeof useGetBookingUsersLazyQuery>;
export type GetBookingUsersQueryResult = Apollo.QueryResult<GetBookingUsersQuery, GetBookingUsersQueryVariables>;
export function refetchGetBookingUsersQuery(variables?: GetBookingUsersQueryVariables) {
      return { query: GetBookingUsersDocument, variables: variables }
    }
export const GetBookingCreatorsDocument = gql`
    query getBookingCreators($where: BookingUserDtoFilterInput) {
  bookingCreators(where: $where) {
    id
    email
    userName
    lastName
    firstName
  }
}
    `;

/**
 * __useGetBookingCreatorsQuery__
 *
 * To run a query within a React component, call `useGetBookingCreatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingCreatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingCreatorsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBookingCreatorsQuery(baseOptions?: Apollo.QueryHookOptions<GetBookingCreatorsQuery, GetBookingCreatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingCreatorsQuery, GetBookingCreatorsQueryVariables>(GetBookingCreatorsDocument, options);
      }
export function useGetBookingCreatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingCreatorsQuery, GetBookingCreatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingCreatorsQuery, GetBookingCreatorsQueryVariables>(GetBookingCreatorsDocument, options);
        }
export type GetBookingCreatorsQueryHookResult = ReturnType<typeof useGetBookingCreatorsQuery>;
export type GetBookingCreatorsLazyQueryHookResult = ReturnType<typeof useGetBookingCreatorsLazyQuery>;
export type GetBookingCreatorsQueryResult = Apollo.QueryResult<GetBookingCreatorsQuery, GetBookingCreatorsQueryVariables>;
export function refetchGetBookingCreatorsQuery(variables?: GetBookingCreatorsQueryVariables) {
      return { query: GetBookingCreatorsDocument, variables: variables }
    }
export const GetPotentialBookingFilesDocument = gql`
    query GetPotentialBookingFiles($after: String, $before: String, $first: Int, $last: Int, $order: [PotentialBookingFileSortInput!], $where: PotentialBookingFileFilterInput) {
  potentialBookingFiles(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        file {
          id
          originalFileName
        }
        uploadedByCustomer {
          id
          name
        }
        isProcessed
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPotentialBookingFilesQuery__
 *
 * To run a query within a React component, call `useGetPotentialBookingFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPotentialBookingFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPotentialBookingFilesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPotentialBookingFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetPotentialBookingFilesQuery, GetPotentialBookingFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPotentialBookingFilesQuery, GetPotentialBookingFilesQueryVariables>(GetPotentialBookingFilesDocument, options);
      }
export function useGetPotentialBookingFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPotentialBookingFilesQuery, GetPotentialBookingFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPotentialBookingFilesQuery, GetPotentialBookingFilesQueryVariables>(GetPotentialBookingFilesDocument, options);
        }
export type GetPotentialBookingFilesQueryHookResult = ReturnType<typeof useGetPotentialBookingFilesQuery>;
export type GetPotentialBookingFilesLazyQueryHookResult = ReturnType<typeof useGetPotentialBookingFilesLazyQuery>;
export type GetPotentialBookingFilesQueryResult = Apollo.QueryResult<GetPotentialBookingFilesQuery, GetPotentialBookingFilesQueryVariables>;
export function refetchGetPotentialBookingFilesQuery(variables?: GetPotentialBookingFilesQueryVariables) {
      return { query: GetPotentialBookingFilesDocument, variables: variables }
    }
export const GetPotentialBookingFileDocument = gql`
    query GetPotentialBookingFile($id: Long!) {
  potentialBookingFile(id: $id) {
    id
    file {
      id
      originalFileName
    }
    uploadedByCustomer {
      id
      name
    }
    isProcessed
    createdAt
    createdBy
    extractedData
  }
}
    `;

/**
 * __useGetPotentialBookingFileQuery__
 *
 * To run a query within a React component, call `useGetPotentialBookingFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPotentialBookingFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPotentialBookingFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPotentialBookingFileQuery(baseOptions: Apollo.QueryHookOptions<GetPotentialBookingFileQuery, GetPotentialBookingFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPotentialBookingFileQuery, GetPotentialBookingFileQueryVariables>(GetPotentialBookingFileDocument, options);
      }
export function useGetPotentialBookingFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPotentialBookingFileQuery, GetPotentialBookingFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPotentialBookingFileQuery, GetPotentialBookingFileQueryVariables>(GetPotentialBookingFileDocument, options);
        }
export type GetPotentialBookingFileQueryHookResult = ReturnType<typeof useGetPotentialBookingFileQuery>;
export type GetPotentialBookingFileLazyQueryHookResult = ReturnType<typeof useGetPotentialBookingFileLazyQuery>;
export type GetPotentialBookingFileQueryResult = Apollo.QueryResult<GetPotentialBookingFileQuery, GetPotentialBookingFileQueryVariables>;
export function refetchGetPotentialBookingFileQuery(variables?: GetPotentialBookingFileQueryVariables) {
      return { query: GetPotentialBookingFileDocument, variables: variables }
    }
export const GetPotentialBookingMappingsDocument = gql`
    query GetPotentialBookingMappings {
  potentialBookingMappings {
    id
    name
  }
}
    `;

/**
 * __useGetPotentialBookingMappingsQuery__
 *
 * To run a query within a React component, call `useGetPotentialBookingMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPotentialBookingMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPotentialBookingMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPotentialBookingMappingsQuery(baseOptions?: Apollo.QueryHookOptions<GetPotentialBookingMappingsQuery, GetPotentialBookingMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPotentialBookingMappingsQuery, GetPotentialBookingMappingsQueryVariables>(GetPotentialBookingMappingsDocument, options);
      }
export function useGetPotentialBookingMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPotentialBookingMappingsQuery, GetPotentialBookingMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPotentialBookingMappingsQuery, GetPotentialBookingMappingsQueryVariables>(GetPotentialBookingMappingsDocument, options);
        }
export type GetPotentialBookingMappingsQueryHookResult = ReturnType<typeof useGetPotentialBookingMappingsQuery>;
export type GetPotentialBookingMappingsLazyQueryHookResult = ReturnType<typeof useGetPotentialBookingMappingsLazyQuery>;
export type GetPotentialBookingMappingsQueryResult = Apollo.QueryResult<GetPotentialBookingMappingsQuery, GetPotentialBookingMappingsQueryVariables>;
export function refetchGetPotentialBookingMappingsQuery(variables?: GetPotentialBookingMappingsQueryVariables) {
      return { query: GetPotentialBookingMappingsDocument, variables: variables }
    }
export const GetPotentialBookingsDocument = gql`
    query GetPotentialBookings($after: String, $before: String, $first: Int, $last: Int, $order: [PotentialBookingSortInput!], $where: PotentialBookingFilterInput) {
  potentialBookings(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        potentialBookingFile {
          id
          file {
            id
            name
          }
        }
        potentialBookingMapping {
          id
          name
        }
        bookingIdentifierKey
        bookingIdentifierValue
        customerIdentifierKey
        customerIdentifierValue
        isProcessed
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPotentialBookingsQuery__
 *
 * To run a query within a React component, call `useGetPotentialBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPotentialBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPotentialBookingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPotentialBookingsQuery(baseOptions?: Apollo.QueryHookOptions<GetPotentialBookingsQuery, GetPotentialBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPotentialBookingsQuery, GetPotentialBookingsQueryVariables>(GetPotentialBookingsDocument, options);
      }
export function useGetPotentialBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPotentialBookingsQuery, GetPotentialBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPotentialBookingsQuery, GetPotentialBookingsQueryVariables>(GetPotentialBookingsDocument, options);
        }
export type GetPotentialBookingsQueryHookResult = ReturnType<typeof useGetPotentialBookingsQuery>;
export type GetPotentialBookingsLazyQueryHookResult = ReturnType<typeof useGetPotentialBookingsLazyQuery>;
export type GetPotentialBookingsQueryResult = Apollo.QueryResult<GetPotentialBookingsQuery, GetPotentialBookingsQueryVariables>;
export function refetchGetPotentialBookingsQuery(variables?: GetPotentialBookingsQueryVariables) {
      return { query: GetPotentialBookingsDocument, variables: variables }
    }
export const GetPotentialBookingDocument = gql`
    query GetPotentialBooking($id: Long!) {
  potentialBooking(id: $id) {
    id
    bookingIdentifierKey
    bookingIdentifierValue
    customerIdentifierKey
    customerIdentifierValue
    potentialBookingFile {
      id
      extractedData
    }
    potentialBookingMapping {
      id
      type
    }
  }
}
    `;

/**
 * __useGetPotentialBookingQuery__
 *
 * To run a query within a React component, call `useGetPotentialBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPotentialBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPotentialBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPotentialBookingQuery(baseOptions: Apollo.QueryHookOptions<GetPotentialBookingQuery, GetPotentialBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPotentialBookingQuery, GetPotentialBookingQueryVariables>(GetPotentialBookingDocument, options);
      }
export function useGetPotentialBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPotentialBookingQuery, GetPotentialBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPotentialBookingQuery, GetPotentialBookingQueryVariables>(GetPotentialBookingDocument, options);
        }
export type GetPotentialBookingQueryHookResult = ReturnType<typeof useGetPotentialBookingQuery>;
export type GetPotentialBookingLazyQueryHookResult = ReturnType<typeof useGetPotentialBookingLazyQuery>;
export type GetPotentialBookingQueryResult = Apollo.QueryResult<GetPotentialBookingQuery, GetPotentialBookingQueryVariables>;
export function refetchGetPotentialBookingQuery(variables?: GetPotentialBookingQueryVariables) {
      return { query: GetPotentialBookingDocument, variables: variables }
    }
export const BookingsTrackingDashboardDocument = gql`
    query bookingsTrackingDashboard($after: String, $before: String, $first: Int, $last: Int, $order: [BookingTrackingDashboardDtoSortInput!], $where: BookingTrackingDashboardDtoFilterInput) {
  bookingTrackingDashboardData(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        ourReference
        status
        statusLabel
        customerName
        customerReference
        driverStatus {
          id
          status
          statusLabel
          date
          postcode
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useBookingsTrackingDashboardQuery__
 *
 * To run a query within a React component, call `useBookingsTrackingDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsTrackingDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsTrackingDashboardQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBookingsTrackingDashboardQuery(baseOptions?: Apollo.QueryHookOptions<BookingsTrackingDashboardQuery, BookingsTrackingDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingsTrackingDashboardQuery, BookingsTrackingDashboardQueryVariables>(BookingsTrackingDashboardDocument, options);
      }
export function useBookingsTrackingDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingsTrackingDashboardQuery, BookingsTrackingDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingsTrackingDashboardQuery, BookingsTrackingDashboardQueryVariables>(BookingsTrackingDashboardDocument, options);
        }
export type BookingsTrackingDashboardQueryHookResult = ReturnType<typeof useBookingsTrackingDashboardQuery>;
export type BookingsTrackingDashboardLazyQueryHookResult = ReturnType<typeof useBookingsTrackingDashboardLazyQuery>;
export type BookingsTrackingDashboardQueryResult = Apollo.QueryResult<BookingsTrackingDashboardQuery, BookingsTrackingDashboardQueryVariables>;
export function refetchBookingsTrackingDashboardQuery(variables?: BookingsTrackingDashboardQueryVariables) {
      return { query: BookingsTrackingDashboardDocument, variables: variables }
    }
export const GetCommentariesDocument = gql`
    query getCommentaries($where: CommentaryFilterInput, $order: [CommentarySortInput!]) {
  commentaries(where: $where, order: $order) {
    id
    ...CommentaryDetails
  }
}
    ${CommentaryDetailsFragmentDoc}`;

/**
 * __useGetCommentariesQuery__
 *
 * To run a query within a React component, call `useGetCommentariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentariesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCommentariesQuery(baseOptions?: Apollo.QueryHookOptions<GetCommentariesQuery, GetCommentariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentariesQuery, GetCommentariesQueryVariables>(GetCommentariesDocument, options);
      }
export function useGetCommentariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentariesQuery, GetCommentariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentariesQuery, GetCommentariesQueryVariables>(GetCommentariesDocument, options);
        }
export type GetCommentariesQueryHookResult = ReturnType<typeof useGetCommentariesQuery>;
export type GetCommentariesLazyQueryHookResult = ReturnType<typeof useGetCommentariesLazyQuery>;
export type GetCommentariesQueryResult = Apollo.QueryResult<GetCommentariesQuery, GetCommentariesQueryVariables>;
export function refetchGetCommentariesQuery(variables?: GetCommentariesQueryVariables) {
      return { query: GetCommentariesDocument, variables: variables }
    }
export const GetCommentaryDocument = gql`
    query getCommentary($id: Long!) {
  commentary(id: $id) {
    id
    ...CommentaryDetails
  }
}
    ${CommentaryDetailsFragmentDoc}`;

/**
 * __useGetCommentaryQuery__
 *
 * To run a query within a React component, call `useGetCommentaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommentaryQuery(baseOptions: Apollo.QueryHookOptions<GetCommentaryQuery, GetCommentaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentaryQuery, GetCommentaryQueryVariables>(GetCommentaryDocument, options);
      }
export function useGetCommentaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentaryQuery, GetCommentaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentaryQuery, GetCommentaryQueryVariables>(GetCommentaryDocument, options);
        }
export type GetCommentaryQueryHookResult = ReturnType<typeof useGetCommentaryQuery>;
export type GetCommentaryLazyQueryHookResult = ReturnType<typeof useGetCommentaryLazyQuery>;
export type GetCommentaryQueryResult = Apollo.QueryResult<GetCommentaryQuery, GetCommentaryQueryVariables>;
export function refetchGetCommentaryQuery(variables?: GetCommentaryQueryVariables) {
      return { query: GetCommentaryDocument, variables: variables }
    }
export const GetPagedCongestionZonesDocument = gql`
    query GetPagedCongestionZones($after: String, $before: String, $first: Int, $last: Int, $order: [CongestionZoneSortInput!], $where: CongestionZoneFilterInput) {
  pagedCongestionZones(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        isAttachedToAllTariffs
        isAppliedAutomatically
        xeroServiceCode
        customerCharge
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedCongestionZonesQuery__
 *
 * To run a query within a React component, call `useGetPagedCongestionZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedCongestionZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedCongestionZonesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedCongestionZonesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedCongestionZonesQuery, GetPagedCongestionZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedCongestionZonesQuery, GetPagedCongestionZonesQueryVariables>(GetPagedCongestionZonesDocument, options);
      }
export function useGetPagedCongestionZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedCongestionZonesQuery, GetPagedCongestionZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedCongestionZonesQuery, GetPagedCongestionZonesQueryVariables>(GetPagedCongestionZonesDocument, options);
        }
export type GetPagedCongestionZonesQueryHookResult = ReturnType<typeof useGetPagedCongestionZonesQuery>;
export type GetPagedCongestionZonesLazyQueryHookResult = ReturnType<typeof useGetPagedCongestionZonesLazyQuery>;
export type GetPagedCongestionZonesQueryResult = Apollo.QueryResult<GetPagedCongestionZonesQuery, GetPagedCongestionZonesQueryVariables>;
export function refetchGetPagedCongestionZonesQuery(variables?: GetPagedCongestionZonesQueryVariables) {
      return { query: GetPagedCongestionZonesDocument, variables: variables }
    }
export const GetCongestionZoneDocument = gql`
    query GetCongestionZone($id: Long!) {
  congestionZone(id: $id) {
    id
    timeZoneId
    timeZone {
      id
      name
      description
    }
    is24Hours
    ...CongestionZoneDetails
    postcodes {
      id
      ...CongestionZonePostcodeFragment
    }
  }
}
    ${CongestionZoneDetailsFragmentDoc}
${CongestionZonePostcodeFragmentFragmentDoc}`;

/**
 * __useGetCongestionZoneQuery__
 *
 * To run a query within a React component, call `useGetCongestionZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCongestionZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCongestionZoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCongestionZoneQuery(baseOptions: Apollo.QueryHookOptions<GetCongestionZoneQuery, GetCongestionZoneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCongestionZoneQuery, GetCongestionZoneQueryVariables>(GetCongestionZoneDocument, options);
      }
export function useGetCongestionZoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCongestionZoneQuery, GetCongestionZoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCongestionZoneQuery, GetCongestionZoneQueryVariables>(GetCongestionZoneDocument, options);
        }
export type GetCongestionZoneQueryHookResult = ReturnType<typeof useGetCongestionZoneQuery>;
export type GetCongestionZoneLazyQueryHookResult = ReturnType<typeof useGetCongestionZoneLazyQuery>;
export type GetCongestionZoneQueryResult = Apollo.QueryResult<GetCongestionZoneQuery, GetCongestionZoneQueryVariables>;
export function refetchGetCongestionZoneQuery(variables?: GetCongestionZoneQueryVariables) {
      return { query: GetCongestionZoneDocument, variables: variables }
    }
export const GetTimeZonesDocument = gql`
    query GetTimeZones {
  timeZones {
    id
    description
  }
}
    `;

/**
 * __useGetTimeZonesQuery__
 *
 * To run a query within a React component, call `useGetTimeZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeZonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTimeZonesQuery(baseOptions?: Apollo.QueryHookOptions<GetTimeZonesQuery, GetTimeZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimeZonesQuery, GetTimeZonesQueryVariables>(GetTimeZonesDocument, options);
      }
export function useGetTimeZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimeZonesQuery, GetTimeZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimeZonesQuery, GetTimeZonesQueryVariables>(GetTimeZonesDocument, options);
        }
export type GetTimeZonesQueryHookResult = ReturnType<typeof useGetTimeZonesQuery>;
export type GetTimeZonesLazyQueryHookResult = ReturnType<typeof useGetTimeZonesLazyQuery>;
export type GetTimeZonesQueryResult = Apollo.QueryResult<GetTimeZonesQuery, GetTimeZonesQueryVariables>;
export function refetchGetTimeZonesQuery(variables?: GetTimeZonesQueryVariables) {
      return { query: GetTimeZonesDocument, variables: variables }
    }
export const GetCountriesDocument = gql`
    query GetCountries {
  countries {
    id
    name
    formalName
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export function refetchGetCountriesQuery(variables?: GetCountriesQueryVariables) {
      return { query: GetCountriesDocument, variables: variables }
    }
export const GetCourierExchangeQuotesDocument = gql`
    query GetCourierExchangeQuotes($loadId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [CourierExchangeQuoteSortInput!], $where: CourierExchangeQuoteFilterInput) {
  courierExchangeQuotes(
    loadId: $loadId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        loadId
        quoteId
        price
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetCourierExchangeQuotesQuery__
 *
 * To run a query within a React component, call `useGetCourierExchangeQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourierExchangeQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourierExchangeQuotesQuery({
 *   variables: {
 *      loadId: // value for 'loadId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCourierExchangeQuotesQuery(baseOptions: Apollo.QueryHookOptions<GetCourierExchangeQuotesQuery, GetCourierExchangeQuotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourierExchangeQuotesQuery, GetCourierExchangeQuotesQueryVariables>(GetCourierExchangeQuotesDocument, options);
      }
export function useGetCourierExchangeQuotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourierExchangeQuotesQuery, GetCourierExchangeQuotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourierExchangeQuotesQuery, GetCourierExchangeQuotesQueryVariables>(GetCourierExchangeQuotesDocument, options);
        }
export type GetCourierExchangeQuotesQueryHookResult = ReturnType<typeof useGetCourierExchangeQuotesQuery>;
export type GetCourierExchangeQuotesLazyQueryHookResult = ReturnType<typeof useGetCourierExchangeQuotesLazyQuery>;
export type GetCourierExchangeQuotesQueryResult = Apollo.QueryResult<GetCourierExchangeQuotesQuery, GetCourierExchangeQuotesQueryVariables>;
export function refetchGetCourierExchangeQuotesQuery(variables?: GetCourierExchangeQuotesQueryVariables) {
      return { query: GetCourierExchangeQuotesDocument, variables: variables }
    }
export const GetCourierExchangeQuoteDocument = gql`
    query GetCourierExchangeQuote($courierExchangeQuoteId: Long!) {
  courierExchangeQuote(courierExchangeQuoteId: $courierExchangeQuoteId) {
    id
    quoteId
    loadId
    quoteOwnerCompanyId
    totalPrice
  }
}
    `;

/**
 * __useGetCourierExchangeQuoteQuery__
 *
 * To run a query within a React component, call `useGetCourierExchangeQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourierExchangeQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourierExchangeQuoteQuery({
 *   variables: {
 *      courierExchangeQuoteId: // value for 'courierExchangeQuoteId'
 *   },
 * });
 */
export function useGetCourierExchangeQuoteQuery(baseOptions: Apollo.QueryHookOptions<GetCourierExchangeQuoteQuery, GetCourierExchangeQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourierExchangeQuoteQuery, GetCourierExchangeQuoteQueryVariables>(GetCourierExchangeQuoteDocument, options);
      }
export function useGetCourierExchangeQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourierExchangeQuoteQuery, GetCourierExchangeQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourierExchangeQuoteQuery, GetCourierExchangeQuoteQueryVariables>(GetCourierExchangeQuoteDocument, options);
        }
export type GetCourierExchangeQuoteQueryHookResult = ReturnType<typeof useGetCourierExchangeQuoteQuery>;
export type GetCourierExchangeQuoteLazyQueryHookResult = ReturnType<typeof useGetCourierExchangeQuoteLazyQuery>;
export type GetCourierExchangeQuoteQueryResult = Apollo.QueryResult<GetCourierExchangeQuoteQuery, GetCourierExchangeQuoteQueryVariables>;
export function refetchGetCourierExchangeQuoteQuery(variables?: GetCourierExchangeQuoteQueryVariables) {
      return { query: GetCourierExchangeQuoteDocument, variables: variables }
    }
export const GetPackagingTypesDocument = gql`
    query getPackagingTypes {
  packagingTypes {
    id
    description
    packagingType
    disabled
  }
}
    `;

/**
 * __useGetPackagingTypesQuery__
 *
 * To run a query within a React component, call `useGetPackagingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagingTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagingTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPackagingTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetPackagingTypesQuery, GetPackagingTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackagingTypesQuery, GetPackagingTypesQueryVariables>(GetPackagingTypesDocument, options);
      }
export function useGetPackagingTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackagingTypesQuery, GetPackagingTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackagingTypesQuery, GetPackagingTypesQueryVariables>(GetPackagingTypesDocument, options);
        }
export type GetPackagingTypesQueryHookResult = ReturnType<typeof useGetPackagingTypesQuery>;
export type GetPackagingTypesLazyQueryHookResult = ReturnType<typeof useGetPackagingTypesLazyQuery>;
export type GetPackagingTypesQueryResult = Apollo.QueryResult<GetPackagingTypesQuery, GetPackagingTypesQueryVariables>;
export function refetchGetPackagingTypesQuery(variables?: GetPackagingTypesQueryVariables) {
      return { query: GetPackagingTypesDocument, variables: variables }
    }
export const GetLoadCurrentLocationDocument = gql`
    query GetLoadCurrentLocation($loadId: Long!) {
  loadCurrentLocation(loadId: $loadId) {
    driverId
    longitude
    latitude
    accuracy
  }
}
    `;

/**
 * __useGetLoadCurrentLocationQuery__
 *
 * To run a query within a React component, call `useGetLoadCurrentLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoadCurrentLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoadCurrentLocationQuery({
 *   variables: {
 *      loadId: // value for 'loadId'
 *   },
 * });
 */
export function useGetLoadCurrentLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLoadCurrentLocationQuery, GetLoadCurrentLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoadCurrentLocationQuery, GetLoadCurrentLocationQueryVariables>(GetLoadCurrentLocationDocument, options);
      }
export function useGetLoadCurrentLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoadCurrentLocationQuery, GetLoadCurrentLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoadCurrentLocationQuery, GetLoadCurrentLocationQueryVariables>(GetLoadCurrentLocationDocument, options);
        }
export type GetLoadCurrentLocationQueryHookResult = ReturnType<typeof useGetLoadCurrentLocationQuery>;
export type GetLoadCurrentLocationLazyQueryHookResult = ReturnType<typeof useGetLoadCurrentLocationLazyQuery>;
export type GetLoadCurrentLocationQueryResult = Apollo.QueryResult<GetLoadCurrentLocationQuery, GetLoadCurrentLocationQueryVariables>;
export function refetchGetLoadCurrentLocationQuery(variables?: GetLoadCurrentLocationQueryVariables) {
      return { query: GetLoadCurrentLocationDocument, variables: variables }
    }
export const GetPagedCurrenciesDocument = gql`
    query getPagedCurrencies($after: String, $before: String, $first: Int, $last: Int, $order: [CurrencySortInput!], $where: CurrencyFilterInput) {
  pagedCurrencies(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        code
        isBaseCurrency
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetPagedCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedCurrenciesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedCurrenciesQuery, GetPagedCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedCurrenciesQuery, GetPagedCurrenciesQueryVariables>(GetPagedCurrenciesDocument, options);
      }
export function useGetPagedCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedCurrenciesQuery, GetPagedCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedCurrenciesQuery, GetPagedCurrenciesQueryVariables>(GetPagedCurrenciesDocument, options);
        }
export type GetPagedCurrenciesQueryHookResult = ReturnType<typeof useGetPagedCurrenciesQuery>;
export type GetPagedCurrenciesLazyQueryHookResult = ReturnType<typeof useGetPagedCurrenciesLazyQuery>;
export type GetPagedCurrenciesQueryResult = Apollo.QueryResult<GetPagedCurrenciesQuery, GetPagedCurrenciesQueryVariables>;
export function refetchGetPagedCurrenciesQuery(variables?: GetPagedCurrenciesQueryVariables) {
      return { query: GetPagedCurrenciesDocument, variables: variables }
    }
export const GetCurrenciesDocument = gql`
    query getCurrencies($where: CurrencyFilterInput) {
  currencies(where: $where) {
    id
    name
    code
    isBaseCurrency
  }
}
    `;

/**
 * __useGetCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrenciesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
      }
export function useGetCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrenciesQuery, GetCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrenciesQuery, GetCurrenciesQueryVariables>(GetCurrenciesDocument, options);
        }
export type GetCurrenciesQueryHookResult = ReturnType<typeof useGetCurrenciesQuery>;
export type GetCurrenciesLazyQueryHookResult = ReturnType<typeof useGetCurrenciesLazyQuery>;
export type GetCurrenciesQueryResult = Apollo.QueryResult<GetCurrenciesQuery, GetCurrenciesQueryVariables>;
export function refetchGetCurrenciesQuery(variables?: GetCurrenciesQueryVariables) {
      return { query: GetCurrenciesDocument, variables: variables }
    }
export const GetPagedCustomersDocument = gql`
    query GetPagedCustomers($after: String, $before: String, $first: Int, $last: Int, $order: [CustomerSortInput!], $where: CustomerFilterInput) {
  pagedCustomers(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        accountCode
        name
        isOnHold
        addressLine1
        addressLine2
        city
        postcode
        telephoneNumber
        invoiceEmail
        contacts {
          id
          name
        }
        acquiredBy
        customerAccountType
        isEnabled
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedCustomersQuery__
 *
 * To run a query within a React component, call `useGetPagedCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedCustomersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedCustomersQuery, GetPagedCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedCustomersQuery, GetPagedCustomersQueryVariables>(GetPagedCustomersDocument, options);
      }
export function useGetPagedCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedCustomersQuery, GetPagedCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedCustomersQuery, GetPagedCustomersQueryVariables>(GetPagedCustomersDocument, options);
        }
export type GetPagedCustomersQueryHookResult = ReturnType<typeof useGetPagedCustomersQuery>;
export type GetPagedCustomersLazyQueryHookResult = ReturnType<typeof useGetPagedCustomersLazyQuery>;
export type GetPagedCustomersQueryResult = Apollo.QueryResult<GetPagedCustomersQuery, GetPagedCustomersQueryVariables>;
export function refetchGetPagedCustomersQuery(variables?: GetPagedCustomersQueryVariables) {
      return { query: GetPagedCustomersDocument, variables: variables }
    }
export const GetCustomersDocument = gql`
    query GetCustomers($order: [CustomerSortInput!]) {
  customers(order: $order) {
    id
    name
    isReferenceRequired
    isReasonCodeOptionEnabled
    isPackageDetailsOptional
    contacts {
      id
      name
      email
      telephoneNumber
      isDefault
      isEnabled
    }
    isEnabled
  }
}
    `;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export function refetchGetCustomersQuery(variables?: GetCustomersQueryVariables) {
      return { query: GetCustomersDocument, variables: variables }
    }
export const GetCustomerDocument = gql`
    query GetCustomer($customerId: Long!) {
  customer(id: $customerId) {
    id
    customer {
      ...CustomerDetails
    }
    logoId
    logo {
      id
      ...FileDetails
    }
  }
}
    ${CustomerDetailsFragmentDoc}
${FileDetailsFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export function refetchGetCustomerQuery(variables?: GetCustomerQueryVariables) {
      return { query: GetCustomerDocument, variables: variables }
    }
export const GetCustomerContactDocument = gql`
    query GetCustomerContact($id: Long!) {
  customerContact(id: $id) {
    id
    isDefault
    name
    telephoneNumber
    email
    additionalEmails
    customerId
    isEnabled
    isActive
  }
}
    `;

/**
 * __useGetCustomerContactQuery__
 *
 * To run a query within a React component, call `useGetCustomerContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerContactQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerContactQuery, GetCustomerContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerContactQuery, GetCustomerContactQueryVariables>(GetCustomerContactDocument, options);
      }
export function useGetCustomerContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerContactQuery, GetCustomerContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerContactQuery, GetCustomerContactQueryVariables>(GetCustomerContactDocument, options);
        }
export type GetCustomerContactQueryHookResult = ReturnType<typeof useGetCustomerContactQuery>;
export type GetCustomerContactLazyQueryHookResult = ReturnType<typeof useGetCustomerContactLazyQuery>;
export type GetCustomerContactQueryResult = Apollo.QueryResult<GetCustomerContactQuery, GetCustomerContactQueryVariables>;
export function refetchGetCustomerContactQuery(variables?: GetCustomerContactQueryVariables) {
      return { query: GetCustomerContactDocument, variables: variables }
    }
export const GetCustomerContactsDocument = gql`
    query GetCustomerContacts($customerId: Long!, $order: [ContactSortInput!], $where: ContactFilterInput) {
  customerContacts(customerId: $customerId, order: $order, where: $where) {
    id
    isDefault
    name
    email
    telephoneNumber
    isActive
  }
}
    `;

/**
 * __useGetCustomerContactsQuery__
 *
 * To run a query within a React component, call `useGetCustomerContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerContactsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCustomerContactsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>(GetCustomerContactsDocument, options);
      }
export function useGetCustomerContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>(GetCustomerContactsDocument, options);
        }
export type GetCustomerContactsQueryHookResult = ReturnType<typeof useGetCustomerContactsQuery>;
export type GetCustomerContactsLazyQueryHookResult = ReturnType<typeof useGetCustomerContactsLazyQuery>;
export type GetCustomerContactsQueryResult = Apollo.QueryResult<GetCustomerContactsQuery, GetCustomerContactsQueryVariables>;
export function refetchGetCustomerContactsQuery(variables?: GetCustomerContactsQueryVariables) {
      return { query: GetCustomerContactsDocument, variables: variables }
    }
export const GetPagedCustomerContactsDocument = gql`
    query GetPagedCustomerContacts($customerId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [ContactSortInput!], $where: ContactFilterInput) {
  pagedCustomerContacts(
    customerId: $customerId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        telephoneNumber
        email
        additionalEmails
        isDefault
        isActive
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedCustomerContactsQuery__
 *
 * To run a query within a React component, call `useGetPagedCustomerContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedCustomerContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedCustomerContactsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedCustomerContactsQuery(baseOptions: Apollo.QueryHookOptions<GetPagedCustomerContactsQuery, GetPagedCustomerContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedCustomerContactsQuery, GetPagedCustomerContactsQueryVariables>(GetPagedCustomerContactsDocument, options);
      }
export function useGetPagedCustomerContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedCustomerContactsQuery, GetPagedCustomerContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedCustomerContactsQuery, GetPagedCustomerContactsQueryVariables>(GetPagedCustomerContactsDocument, options);
        }
export type GetPagedCustomerContactsQueryHookResult = ReturnType<typeof useGetPagedCustomerContactsQuery>;
export type GetPagedCustomerContactsLazyQueryHookResult = ReturnType<typeof useGetPagedCustomerContactsLazyQuery>;
export type GetPagedCustomerContactsQueryResult = Apollo.QueryResult<GetPagedCustomerContactsQuery, GetPagedCustomerContactsQueryVariables>;
export function refetchGetPagedCustomerContactsQuery(variables?: GetPagedCustomerContactsQueryVariables) {
      return { query: GetPagedCustomerContactsDocument, variables: variables }
    }
export const GetCustomerNotificationsDocument = gql`
    query GetCustomerNotifications($customerId: Long!) {
  customer(id: $customerId) {
    id
    customer {
      id
      ...CustomerNotifications
    }
  }
}
    ${CustomerNotificationsFragmentDoc}`;

/**
 * __useGetCustomerNotificationsQuery__
 *
 * To run a query within a React component, call `useGetCustomerNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerNotificationsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerNotificationsQuery, GetCustomerNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerNotificationsQuery, GetCustomerNotificationsQueryVariables>(GetCustomerNotificationsDocument, options);
      }
export function useGetCustomerNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerNotificationsQuery, GetCustomerNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerNotificationsQuery, GetCustomerNotificationsQueryVariables>(GetCustomerNotificationsDocument, options);
        }
export type GetCustomerNotificationsQueryHookResult = ReturnType<typeof useGetCustomerNotificationsQuery>;
export type GetCustomerNotificationsLazyQueryHookResult = ReturnType<typeof useGetCustomerNotificationsLazyQuery>;
export type GetCustomerNotificationsQueryResult = Apollo.QueryResult<GetCustomerNotificationsQuery, GetCustomerNotificationsQueryVariables>;
export function refetchGetCustomerNotificationsQuery(variables?: GetCustomerNotificationsQueryVariables) {
      return { query: GetCustomerNotificationsDocument, variables: variables }
    }
export const GetCustomerReferencesAndNotesDocument = gql`
    query GetCustomerReferencesAndNotes($customerId: Long!) {
  customer(id: $customerId) {
    id
    customer {
      id
      ...CustomerReferenceAndNotes
    }
  }
}
    ${CustomerReferenceAndNotesFragmentDoc}`;

/**
 * __useGetCustomerReferencesAndNotesQuery__
 *
 * To run a query within a React component, call `useGetCustomerReferencesAndNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReferencesAndNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReferencesAndNotesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerReferencesAndNotesQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerReferencesAndNotesQuery, GetCustomerReferencesAndNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerReferencesAndNotesQuery, GetCustomerReferencesAndNotesQueryVariables>(GetCustomerReferencesAndNotesDocument, options);
      }
export function useGetCustomerReferencesAndNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerReferencesAndNotesQuery, GetCustomerReferencesAndNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerReferencesAndNotesQuery, GetCustomerReferencesAndNotesQueryVariables>(GetCustomerReferencesAndNotesDocument, options);
        }
export type GetCustomerReferencesAndNotesQueryHookResult = ReturnType<typeof useGetCustomerReferencesAndNotesQuery>;
export type GetCustomerReferencesAndNotesLazyQueryHookResult = ReturnType<typeof useGetCustomerReferencesAndNotesLazyQuery>;
export type GetCustomerReferencesAndNotesQueryResult = Apollo.QueryResult<GetCustomerReferencesAndNotesQuery, GetCustomerReferencesAndNotesQueryVariables>;
export function refetchGetCustomerReferencesAndNotesQuery(variables?: GetCustomerReferencesAndNotesQueryVariables) {
      return { query: GetCustomerReferencesAndNotesDocument, variables: variables }
    }
export const GetSalesNamesDocument = gql`
    query GetSalesNames {
  salesNames
}
    `;

/**
 * __useGetSalesNamesQuery__
 *
 * To run a query within a React component, call `useGetSalesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSalesNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesNamesQuery, GetSalesNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesNamesQuery, GetSalesNamesQueryVariables>(GetSalesNamesDocument, options);
      }
export function useGetSalesNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesNamesQuery, GetSalesNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesNamesQuery, GetSalesNamesQueryVariables>(GetSalesNamesDocument, options);
        }
export type GetSalesNamesQueryHookResult = ReturnType<typeof useGetSalesNamesQuery>;
export type GetSalesNamesLazyQueryHookResult = ReturnType<typeof useGetSalesNamesLazyQuery>;
export type GetSalesNamesQueryResult = Apollo.QueryResult<GetSalesNamesQuery, GetSalesNamesQueryVariables>;
export function refetchGetSalesNamesQuery(variables?: GetSalesNamesQueryVariables) {
      return { query: GetSalesNamesDocument, variables: variables }
    }
export const GetCustomerProfileDocument = gql`
    query GetCustomerProfile {
  customerProfile {
    id
    customerId
    contactId
    customerTradingName
    isReferenceRequired
    isReasonCodeOptionEnabled
    isBookingImportOptionEnabled
    isPackageDetailsOptional
    primaryCustomerId
  }
}
    `;

/**
 * __useGetCustomerProfileQuery__
 *
 * To run a query within a React component, call `useGetCustomerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>(GetCustomerProfileDocument, options);
      }
export function useGetCustomerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>(GetCustomerProfileDocument, options);
        }
export type GetCustomerProfileQueryHookResult = ReturnType<typeof useGetCustomerProfileQuery>;
export type GetCustomerProfileLazyQueryHookResult = ReturnType<typeof useGetCustomerProfileLazyQuery>;
export type GetCustomerProfileQueryResult = Apollo.QueryResult<GetCustomerProfileQuery, GetCustomerProfileQueryVariables>;
export function refetchGetCustomerProfileQuery(variables?: GetCustomerProfileQueryVariables) {
      return { query: GetCustomerProfileDocument, variables: variables }
    }
export const GetSubCustomersDocument = gql`
    query GetSubCustomers($primaryCustomerId: Long!, $isPrimaryCustomerIncluded: Boolean, $order: [CustomerSortInput!]) {
  subCustomers(
    primaryCustomerId: $primaryCustomerId
    isPrimaryCustomerIncluded: $isPrimaryCustomerIncluded
    order: $order
  ) {
    id
    accountCode
    name
    isOnHold
    addressLine1
    addressLine2
    city
    postcode
    telephoneNumber
    invoiceEmail
    contacts {
      id
      name
      email
      telephoneNumber
      isDefault
      isEnabled
    }
    customerAccountType
    isEnabled
    isReferenceRequired
    isReasonCodeOptionEnabled
    isPackageDetailsOptional
  }
}
    `;

/**
 * __useGetSubCustomersQuery__
 *
 * To run a query within a React component, call `useGetSubCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubCustomersQuery({
 *   variables: {
 *      primaryCustomerId: // value for 'primaryCustomerId'
 *      isPrimaryCustomerIncluded: // value for 'isPrimaryCustomerIncluded'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetSubCustomersQuery(baseOptions: Apollo.QueryHookOptions<GetSubCustomersQuery, GetSubCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubCustomersQuery, GetSubCustomersQueryVariables>(GetSubCustomersDocument, options);
      }
export function useGetSubCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubCustomersQuery, GetSubCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubCustomersQuery, GetSubCustomersQueryVariables>(GetSubCustomersDocument, options);
        }
export type GetSubCustomersQueryHookResult = ReturnType<typeof useGetSubCustomersQuery>;
export type GetSubCustomersLazyQueryHookResult = ReturnType<typeof useGetSubCustomersLazyQuery>;
export type GetSubCustomersQueryResult = Apollo.QueryResult<GetSubCustomersQuery, GetSubCustomersQueryVariables>;
export function refetchGetSubCustomersQuery(variables?: GetSubCustomersQueryVariables) {
      return { query: GetSubCustomersDocument, variables: variables }
    }
export const GetPagedSubCustomersDocument = gql`
    query GetPagedSubCustomers($primaryCustomerId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [CustomerSortInput!], $where: CustomerFilterInput) {
  pagedSubCustomers(
    primaryCustomerId: $primaryCustomerId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        accountCode
        name
        isOnHold
        addressLine1
        addressLine2
        city
        postcode
        telephoneNumber
        invoiceEmail
        contacts {
          id
          name
        }
        customerAccountType
        isEnabled
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedSubCustomersQuery__
 *
 * To run a query within a React component, call `useGetPagedSubCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedSubCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedSubCustomersQuery({
 *   variables: {
 *      primaryCustomerId: // value for 'primaryCustomerId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedSubCustomersQuery(baseOptions: Apollo.QueryHookOptions<GetPagedSubCustomersQuery, GetPagedSubCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedSubCustomersQuery, GetPagedSubCustomersQueryVariables>(GetPagedSubCustomersDocument, options);
      }
export function useGetPagedSubCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedSubCustomersQuery, GetPagedSubCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedSubCustomersQuery, GetPagedSubCustomersQueryVariables>(GetPagedSubCustomersDocument, options);
        }
export type GetPagedSubCustomersQueryHookResult = ReturnType<typeof useGetPagedSubCustomersQuery>;
export type GetPagedSubCustomersLazyQueryHookResult = ReturnType<typeof useGetPagedSubCustomersLazyQuery>;
export type GetPagedSubCustomersQueryResult = Apollo.QueryResult<GetPagedSubCustomersQuery, GetPagedSubCustomersQueryVariables>;
export function refetchGetPagedSubCustomersQuery(variables?: GetPagedSubCustomersQueryVariables) {
      return { query: GetPagedSubCustomersDocument, variables: variables }
    }
export const GetCustomerInfoForSubCustomerDocument = gql`
    query GetCustomerInfoForSubCustomer($customerId: Long!) {
  customer(id: $customerId) {
    id
    customer {
      id
      invoiceEmail
      paymentMethodId
      customerAccountType
      invoiceTermId
      invoiceFrequency
      invoiceCompanyName
      isInvoiceGrouped
      currencyId
      isReferenceRequired
      isUlezCharge
    }
  }
}
    `;

/**
 * __useGetCustomerInfoForSubCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerInfoForSubCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerInfoForSubCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerInfoForSubCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerInfoForSubCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerInfoForSubCustomerQuery, GetCustomerInfoForSubCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerInfoForSubCustomerQuery, GetCustomerInfoForSubCustomerQueryVariables>(GetCustomerInfoForSubCustomerDocument, options);
      }
export function useGetCustomerInfoForSubCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerInfoForSubCustomerQuery, GetCustomerInfoForSubCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerInfoForSubCustomerQuery, GetCustomerInfoForSubCustomerQueryVariables>(GetCustomerInfoForSubCustomerDocument, options);
        }
export type GetCustomerInfoForSubCustomerQueryHookResult = ReturnType<typeof useGetCustomerInfoForSubCustomerQuery>;
export type GetCustomerInfoForSubCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerInfoForSubCustomerLazyQuery>;
export type GetCustomerInfoForSubCustomerQueryResult = Apollo.QueryResult<GetCustomerInfoForSubCustomerQuery, GetCustomerInfoForSubCustomerQueryVariables>;
export function refetchGetCustomerInfoForSubCustomerQuery(variables?: GetCustomerInfoForSubCustomerQueryVariables) {
      return { query: GetCustomerInfoForSubCustomerDocument, variables: variables }
    }
export const GetCustomersInstructionsForDriverDocument = gql`
    query GetCustomersInstructionsForDriver($customerId: Long!) {
  customersInstructionsForDriver(customerId: $customerId)
}
    `;

/**
 * __useGetCustomersInstructionsForDriverQuery__
 *
 * To run a query within a React component, call `useGetCustomersInstructionsForDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersInstructionsForDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersInstructionsForDriverQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomersInstructionsForDriverQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersInstructionsForDriverQuery, GetCustomersInstructionsForDriverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersInstructionsForDriverQuery, GetCustomersInstructionsForDriverQueryVariables>(GetCustomersInstructionsForDriverDocument, options);
      }
export function useGetCustomersInstructionsForDriverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersInstructionsForDriverQuery, GetCustomersInstructionsForDriverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersInstructionsForDriverQuery, GetCustomersInstructionsForDriverQueryVariables>(GetCustomersInstructionsForDriverDocument, options);
        }
export type GetCustomersInstructionsForDriverQueryHookResult = ReturnType<typeof useGetCustomersInstructionsForDriverQuery>;
export type GetCustomersInstructionsForDriverLazyQueryHookResult = ReturnType<typeof useGetCustomersInstructionsForDriverLazyQuery>;
export type GetCustomersInstructionsForDriverQueryResult = Apollo.QueryResult<GetCustomersInstructionsForDriverQuery, GetCustomersInstructionsForDriverQueryVariables>;
export function refetchGetCustomersInstructionsForDriverQuery(variables?: GetCustomersInstructionsForDriverQueryVariables) {
      return { query: GetCustomersInstructionsForDriverDocument, variables: variables }
    }
export const GetCustomerOptionsDocument = gql`
    query GetCustomerOptions($customerId: Long!) {
  customerOptions(customerId: $customerId)
}
    `;

/**
 * __useGetCustomerOptionsQuery__
 *
 * To run a query within a React component, call `useGetCustomerOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerOptionsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerOptionsQuery, GetCustomerOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerOptionsQuery, GetCustomerOptionsQueryVariables>(GetCustomerOptionsDocument, options);
      }
export function useGetCustomerOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerOptionsQuery, GetCustomerOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerOptionsQuery, GetCustomerOptionsQueryVariables>(GetCustomerOptionsDocument, options);
        }
export type GetCustomerOptionsQueryHookResult = ReturnType<typeof useGetCustomerOptionsQuery>;
export type GetCustomerOptionsLazyQueryHookResult = ReturnType<typeof useGetCustomerOptionsLazyQuery>;
export type GetCustomerOptionsQueryResult = Apollo.QueryResult<GetCustomerOptionsQuery, GetCustomerOptionsQueryVariables>;
export function refetchGetCustomerOptionsQuery(variables?: GetCustomerOptionsQueryVariables) {
      return { query: GetCustomerOptionsDocument, variables: variables }
    }
export const GetCurrentCustomerOptionsDocument = gql`
    query GetCurrentCustomerOptions {
  currentCustomerOptions
}
    `;

/**
 * __useGetCurrentCustomerOptionsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCustomerOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCustomerOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCustomerOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCustomerOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentCustomerOptionsQuery, GetCurrentCustomerOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentCustomerOptionsQuery, GetCurrentCustomerOptionsQueryVariables>(GetCurrentCustomerOptionsDocument, options);
      }
export function useGetCurrentCustomerOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentCustomerOptionsQuery, GetCurrentCustomerOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentCustomerOptionsQuery, GetCurrentCustomerOptionsQueryVariables>(GetCurrentCustomerOptionsDocument, options);
        }
export type GetCurrentCustomerOptionsQueryHookResult = ReturnType<typeof useGetCurrentCustomerOptionsQuery>;
export type GetCurrentCustomerOptionsLazyQueryHookResult = ReturnType<typeof useGetCurrentCustomerOptionsLazyQuery>;
export type GetCurrentCustomerOptionsQueryResult = Apollo.QueryResult<GetCurrentCustomerOptionsQuery, GetCurrentCustomerOptionsQueryVariables>;
export function refetchGetCurrentCustomerOptionsQuery(variables?: GetCurrentCustomerOptionsQueryVariables) {
      return { query: GetCurrentCustomerOptionsDocument, variables: variables }
    }
export const GetDriverBidsDocument = gql`
    query GetDriverBids($where: DriverBidFilterInput!) {
  driverBids(where: $where) {
    id
    price
    currency {
      id
      name
      symbol
    }
    eta
    notes
    driverId
    driver {
      id
      name
      averageRating
    }
    bookingId
    vehicleId
    vehicle {
      id
      vehicleType {
        id
        name
      }
    }
    createdAt
    isAllocated
  }
}
    `;

/**
 * __useGetDriverBidsQuery__
 *
 * To run a query within a React component, call `useGetDriverBidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverBidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverBidsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDriverBidsQuery(baseOptions: Apollo.QueryHookOptions<GetDriverBidsQuery, GetDriverBidsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverBidsQuery, GetDriverBidsQueryVariables>(GetDriverBidsDocument, options);
      }
export function useGetDriverBidsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverBidsQuery, GetDriverBidsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverBidsQuery, GetDriverBidsQueryVariables>(GetDriverBidsDocument, options);
        }
export type GetDriverBidsQueryHookResult = ReturnType<typeof useGetDriverBidsQuery>;
export type GetDriverBidsLazyQueryHookResult = ReturnType<typeof useGetDriverBidsLazyQuery>;
export type GetDriverBidsQueryResult = Apollo.QueryResult<GetDriverBidsQuery, GetDriverBidsQueryVariables>;
export function refetchGetDriverBidsQuery(variables?: GetDriverBidsQueryVariables) {
      return { query: GetDriverBidsDocument, variables: variables }
    }
export const GetDriverBidsPagedDocument = gql`
    query GetDriverBidsPaged($after: String, $before: String, $first: Int, $last: Int, $order: [DriverBidSortInput!], $where: DriverBidFilterInput) {
  driverBidsPaged(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        price
        currency {
          id
          name
          symbol
        }
        eta
        notes
        driverId
        driver {
          name
          averageRating
        }
        bookingId
        vehicleId
        vehicle {
          vehicleType {
            name
          }
        }
        createdAt
        isAllocated
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetDriverBidsPagedQuery__
 *
 * To run a query within a React component, call `useGetDriverBidsPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverBidsPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverBidsPagedQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDriverBidsPagedQuery(baseOptions?: Apollo.QueryHookOptions<GetDriverBidsPagedQuery, GetDriverBidsPagedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverBidsPagedQuery, GetDriverBidsPagedQueryVariables>(GetDriverBidsPagedDocument, options);
      }
export function useGetDriverBidsPagedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverBidsPagedQuery, GetDriverBidsPagedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverBidsPagedQuery, GetDriverBidsPagedQueryVariables>(GetDriverBidsPagedDocument, options);
        }
export type GetDriverBidsPagedQueryHookResult = ReturnType<typeof useGetDriverBidsPagedQuery>;
export type GetDriverBidsPagedLazyQueryHookResult = ReturnType<typeof useGetDriverBidsPagedLazyQuery>;
export type GetDriverBidsPagedQueryResult = Apollo.QueryResult<GetDriverBidsPagedQuery, GetDriverBidsPagedQueryVariables>;
export function refetchGetDriverBidsPagedQuery(variables?: GetDriverBidsPagedQueryVariables) {
      return { query: GetDriverBidsPagedDocument, variables: variables }
    }
export const GetDriverDocument = gql`
    query GetDriver($driverId: Long!) {
  driver(driverId: $driverId) {
    ...DriverDetails
  }
}
    ${DriverDetailsFragmentDoc}`;

/**
 * __useGetDriverQuery__
 *
 * To run a query within a React component, call `useGetDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverQuery(baseOptions: Apollo.QueryHookOptions<GetDriverQuery, GetDriverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverQuery, GetDriverQueryVariables>(GetDriverDocument, options);
      }
export function useGetDriverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverQuery, GetDriverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverQuery, GetDriverQueryVariables>(GetDriverDocument, options);
        }
export type GetDriverQueryHookResult = ReturnType<typeof useGetDriverQuery>;
export type GetDriverLazyQueryHookResult = ReturnType<typeof useGetDriverLazyQuery>;
export type GetDriverQueryResult = Apollo.QueryResult<GetDriverQuery, GetDriverQueryVariables>;
export function refetchGetDriverQuery(variables?: GetDriverQueryVariables) {
      return { query: GetDriverDocument, variables: variables }
    }
export const GetDriverOrderDetailsDocument = gql`
    query GetDriverOrderDetails($driverId: Long!) {
  driver(driverId: $driverId) {
    id
    xeroPurchaseCode
    vatCategoryId
  }
}
    `;

/**
 * __useGetDriverOrderDetailsQuery__
 *
 * To run a query within a React component, call `useGetDriverOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverOrderDetailsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverOrderDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetDriverOrderDetailsQuery, GetDriverOrderDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverOrderDetailsQuery, GetDriverOrderDetailsQueryVariables>(GetDriverOrderDetailsDocument, options);
      }
export function useGetDriverOrderDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverOrderDetailsQuery, GetDriverOrderDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverOrderDetailsQuery, GetDriverOrderDetailsQueryVariables>(GetDriverOrderDetailsDocument, options);
        }
export type GetDriverOrderDetailsQueryHookResult = ReturnType<typeof useGetDriverOrderDetailsQuery>;
export type GetDriverOrderDetailsLazyQueryHookResult = ReturnType<typeof useGetDriverOrderDetailsLazyQuery>;
export type GetDriverOrderDetailsQueryResult = Apollo.QueryResult<GetDriverOrderDetailsQuery, GetDriverOrderDetailsQueryVariables>;
export function refetchGetDriverOrderDetailsQuery(variables?: GetDriverOrderDetailsQueryVariables) {
      return { query: GetDriverOrderDetailsDocument, variables: variables }
    }
export const GetDriverCurrencyDocument = gql`
    query GetDriverCurrency($driverId: Long!) {
  driver(driverId: $driverId) {
    id
    currencyId
  }
}
    `;

/**
 * __useGetDriverCurrencyQuery__
 *
 * To run a query within a React component, call `useGetDriverCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverCurrencyQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverCurrencyQuery(baseOptions: Apollo.QueryHookOptions<GetDriverCurrencyQuery, GetDriverCurrencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverCurrencyQuery, GetDriverCurrencyQueryVariables>(GetDriverCurrencyDocument, options);
      }
export function useGetDriverCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverCurrencyQuery, GetDriverCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverCurrencyQuery, GetDriverCurrencyQueryVariables>(GetDriverCurrencyDocument, options);
        }
export type GetDriverCurrencyQueryHookResult = ReturnType<typeof useGetDriverCurrencyQuery>;
export type GetDriverCurrencyLazyQueryHookResult = ReturnType<typeof useGetDriverCurrencyLazyQuery>;
export type GetDriverCurrencyQueryResult = Apollo.QueryResult<GetDriverCurrencyQuery, GetDriverCurrencyQueryVariables>;
export function refetchGetDriverCurrencyQuery(variables?: GetDriverCurrencyQueryVariables) {
      return { query: GetDriverCurrencyDocument, variables: variables }
    }
export const GetDriverByCurrentUserDocument = gql`
    query GetDriverByCurrentUser {
  driverByCurrentUser {
    ...DriverDetails
  }
}
    ${DriverDetailsFragmentDoc}`;

/**
 * __useGetDriverByCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetDriverByCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverByCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverByCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDriverByCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetDriverByCurrentUserQuery, GetDriverByCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverByCurrentUserQuery, GetDriverByCurrentUserQueryVariables>(GetDriverByCurrentUserDocument, options);
      }
export function useGetDriverByCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverByCurrentUserQuery, GetDriverByCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverByCurrentUserQuery, GetDriverByCurrentUserQueryVariables>(GetDriverByCurrentUserDocument, options);
        }
export type GetDriverByCurrentUserQueryHookResult = ReturnType<typeof useGetDriverByCurrentUserQuery>;
export type GetDriverByCurrentUserLazyQueryHookResult = ReturnType<typeof useGetDriverByCurrentUserLazyQuery>;
export type GetDriverByCurrentUserQueryResult = Apollo.QueryResult<GetDriverByCurrentUserQuery, GetDriverByCurrentUserQueryVariables>;
export function refetchGetDriverByCurrentUserQuery(variables?: GetDriverByCurrentUserQueryVariables) {
      return { query: GetDriverByCurrentUserDocument, variables: variables }
    }
export const GetDriversDocument = gql`
    query GetDrivers($after: String, $before: String, $first: Int, $last: Int, $order: [DriverSortInput!], $where: DriverFilterInput) {
  drivers(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        addressLine1
        addressLine2
        city
        postcode
        email
        isActive
        callSign
        minMiles
        distanceRate
        minCharge
        licenseType
        licenseExpiryDate
        courierExchangeMemberId
        country {
          id
          name
        }
        telephoneNumber
        driverType
        xeroCodeId
        xeroCode {
          id
          name
        }
        vehicles {
          id
          registrationNumber
          vehicleTypeId
          vehicleType {
            name
          }
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetDriversQuery__
 *
 * To run a query within a React component, call `useGetDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDriversQuery(baseOptions?: Apollo.QueryHookOptions<GetDriversQuery, GetDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, options);
      }
export function useGetDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriversQuery, GetDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, options);
        }
export type GetDriversQueryHookResult = ReturnType<typeof useGetDriversQuery>;
export type GetDriversLazyQueryHookResult = ReturnType<typeof useGetDriversLazyQuery>;
export type GetDriversQueryResult = Apollo.QueryResult<GetDriversQuery, GetDriversQueryVariables>;
export function refetchGetDriversQuery(variables?: GetDriversQueryVariables) {
      return { query: GetDriversDocument, variables: variables }
    }
export const GetDriversXeroPurchaseCodesDocument = gql`
    query GetDriversXeroPurchaseCodes($where: DriverFilterInput) {
  nonPaginatedDrivers(where: $where) {
    id
    xeroPurchaseCode
  }
}
    `;

/**
 * __useGetDriversXeroPurchaseCodesQuery__
 *
 * To run a query within a React component, call `useGetDriversXeroPurchaseCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversXeroPurchaseCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversXeroPurchaseCodesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDriversXeroPurchaseCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetDriversXeroPurchaseCodesQuery, GetDriversXeroPurchaseCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriversXeroPurchaseCodesQuery, GetDriversXeroPurchaseCodesQueryVariables>(GetDriversXeroPurchaseCodesDocument, options);
      }
export function useGetDriversXeroPurchaseCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriversXeroPurchaseCodesQuery, GetDriversXeroPurchaseCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriversXeroPurchaseCodesQuery, GetDriversXeroPurchaseCodesQueryVariables>(GetDriversXeroPurchaseCodesDocument, options);
        }
export type GetDriversXeroPurchaseCodesQueryHookResult = ReturnType<typeof useGetDriversXeroPurchaseCodesQuery>;
export type GetDriversXeroPurchaseCodesLazyQueryHookResult = ReturnType<typeof useGetDriversXeroPurchaseCodesLazyQuery>;
export type GetDriversXeroPurchaseCodesQueryResult = Apollo.QueryResult<GetDriversXeroPurchaseCodesQuery, GetDriversXeroPurchaseCodesQueryVariables>;
export function refetchGetDriversXeroPurchaseCodesQuery(variables?: GetDriversXeroPurchaseCodesQueryVariables) {
      return { query: GetDriversXeroPurchaseCodesDocument, variables: variables }
    }
export const GetPrimaryDriversDocument = gql`
    query GetPrimaryDrivers($after: String, $before: String, $first: Int, $last: Int, $order: [DriverSortInput!], $where: DriverFilterInput) {
  primaryDrivers(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        addressLine1
        addressLine2
        city
        postcode
        email
        isActive
        callSign
        minMiles
        distanceRate
        minCharge
        licenseType
        licenseExpiryDate
        currencyId
        identityUserName
        isUsingInternalApp
        averageRating
        vatCategoryId
        xeroPurchaseCode
        currency {
          id
          code
        }
        country {
          id
          name
        }
        telephoneNumber
        driverType
        xeroCode {
          id
          name
        }
        vehicles {
          id
          registrationNumber
          vehicleTypeId
          vehicleType {
            name
          }
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPrimaryDriversQuery__
 *
 * To run a query within a React component, call `useGetPrimaryDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrimaryDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrimaryDriversQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPrimaryDriversQuery(baseOptions?: Apollo.QueryHookOptions<GetPrimaryDriversQuery, GetPrimaryDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrimaryDriversQuery, GetPrimaryDriversQueryVariables>(GetPrimaryDriversDocument, options);
      }
export function useGetPrimaryDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrimaryDriversQuery, GetPrimaryDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrimaryDriversQuery, GetPrimaryDriversQueryVariables>(GetPrimaryDriversDocument, options);
        }
export type GetPrimaryDriversQueryHookResult = ReturnType<typeof useGetPrimaryDriversQuery>;
export type GetPrimaryDriversLazyQueryHookResult = ReturnType<typeof useGetPrimaryDriversLazyQuery>;
export type GetPrimaryDriversQueryResult = Apollo.QueryResult<GetPrimaryDriversQuery, GetPrimaryDriversQueryVariables>;
export function refetchGetPrimaryDriversQuery(variables?: GetPrimaryDriversQueryVariables) {
      return { query: GetPrimaryDriversDocument, variables: variables }
    }
export const GetSubDriversDocument = gql`
    query GetSubDrivers($driverId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [DriverSortInput!], $where: DriverFilterInput) {
  subDrivers(
    driverId: $driverId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        callSign
        email
        telephoneNumber
        identityUserName
        isUsingInternalApp
        isActive
        xeroCode {
          id
          name
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetSubDriversQuery__
 *
 * To run a query within a React component, call `useGetSubDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubDriversQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSubDriversQuery(baseOptions: Apollo.QueryHookOptions<GetSubDriversQuery, GetSubDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubDriversQuery, GetSubDriversQueryVariables>(GetSubDriversDocument, options);
      }
export function useGetSubDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubDriversQuery, GetSubDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubDriversQuery, GetSubDriversQueryVariables>(GetSubDriversDocument, options);
        }
export type GetSubDriversQueryHookResult = ReturnType<typeof useGetSubDriversQuery>;
export type GetSubDriversLazyQueryHookResult = ReturnType<typeof useGetSubDriversLazyQuery>;
export type GetSubDriversQueryResult = Apollo.QueryResult<GetSubDriversQuery, GetSubDriversQueryVariables>;
export function refetchGetSubDriversQuery(variables?: GetSubDriversQueryVariables) {
      return { query: GetSubDriversDocument, variables: variables }
    }
export const GetDriverContactsDocument = gql`
    query GetDriverContacts($driverId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [DriverContactSortInput!], $where: DriverContactFilterInput) {
  driverContacts(
    driverId: $driverId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        department
        telephoneNumber
        email
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetDriverContactsQuery__
 *
 * To run a query within a React component, call `useGetDriverContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverContactsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDriverContactsQuery(baseOptions: Apollo.QueryHookOptions<GetDriverContactsQuery, GetDriverContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverContactsQuery, GetDriverContactsQueryVariables>(GetDriverContactsDocument, options);
      }
export function useGetDriverContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverContactsQuery, GetDriverContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverContactsQuery, GetDriverContactsQueryVariables>(GetDriverContactsDocument, options);
        }
export type GetDriverContactsQueryHookResult = ReturnType<typeof useGetDriverContactsQuery>;
export type GetDriverContactsLazyQueryHookResult = ReturnType<typeof useGetDriverContactsLazyQuery>;
export type GetDriverContactsQueryResult = Apollo.QueryResult<GetDriverContactsQuery, GetDriverContactsQueryVariables>;
export function refetchGetDriverContactsQuery(variables?: GetDriverContactsQueryVariables) {
      return { query: GetDriverContactsDocument, variables: variables }
    }
export const GetDriverContactDocument = gql`
    query GetDriverContact($driverContactId: Long!) {
  driverContact(driverContactId: $driverContactId) {
    ...DriverContactDetails
  }
}
    ${DriverContactDetailsFragmentDoc}`;

/**
 * __useGetDriverContactQuery__
 *
 * To run a query within a React component, call `useGetDriverContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverContactQuery({
 *   variables: {
 *      driverContactId: // value for 'driverContactId'
 *   },
 * });
 */
export function useGetDriverContactQuery(baseOptions: Apollo.QueryHookOptions<GetDriverContactQuery, GetDriverContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverContactQuery, GetDriverContactQueryVariables>(GetDriverContactDocument, options);
      }
export function useGetDriverContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverContactQuery, GetDriverContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverContactQuery, GetDriverContactQueryVariables>(GetDriverContactDocument, options);
        }
export type GetDriverContactQueryHookResult = ReturnType<typeof useGetDriverContactQuery>;
export type GetDriverContactLazyQueryHookResult = ReturnType<typeof useGetDriverContactLazyQuery>;
export type GetDriverContactQueryResult = Apollo.QueryResult<GetDriverContactQuery, GetDriverContactQueryVariables>;
export function refetchGetDriverContactQuery(variables?: GetDriverContactQueryVariables) {
      return { query: GetDriverContactDocument, variables: variables }
    }
export const GetAllocatedDriversDocument = gql`
    query GetAllocatedDrivers {
  allocatedDrivers {
    id
    driver {
      id
    }
  }
}
    `;

/**
 * __useGetAllocatedDriversQuery__
 *
 * To run a query within a React component, call `useGetAllocatedDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllocatedDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllocatedDriversQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllocatedDriversQuery(baseOptions?: Apollo.QueryHookOptions<GetAllocatedDriversQuery, GetAllocatedDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllocatedDriversQuery, GetAllocatedDriversQueryVariables>(GetAllocatedDriversDocument, options);
      }
export function useGetAllocatedDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllocatedDriversQuery, GetAllocatedDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllocatedDriversQuery, GetAllocatedDriversQueryVariables>(GetAllocatedDriversDocument, options);
        }
export type GetAllocatedDriversQueryHookResult = ReturnType<typeof useGetAllocatedDriversQuery>;
export type GetAllocatedDriversLazyQueryHookResult = ReturnType<typeof useGetAllocatedDriversLazyQuery>;
export type GetAllocatedDriversQueryResult = Apollo.QueryResult<GetAllocatedDriversQuery, GetAllocatedDriversQueryVariables>;
export function refetchGetAllocatedDriversQuery(variables?: GetAllocatedDriversQueryVariables) {
      return { query: GetAllocatedDriversDocument, variables: variables }
    }
export const GetDriversPayDetailsDocument = gql`
    query GetDriversPayDetails($driverId: Long!) {
  driver(driverId: $driverId) {
    id
    nationalInsuranceNumber
    paymentMethodId
    bankName
    bankAccountNumber
    isGenerateSeparateInvoice
    vatIdNumber
    bankSortCode
    employeeReference
    isEmailInvoice
    chequeName
    payFrequency
    creditCard
  }
}
    `;

/**
 * __useGetDriversPayDetailsQuery__
 *
 * To run a query within a React component, call `useGetDriversPayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversPayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversPayDetailsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriversPayDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetDriversPayDetailsQuery, GetDriversPayDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriversPayDetailsQuery, GetDriversPayDetailsQueryVariables>(GetDriversPayDetailsDocument, options);
      }
export function useGetDriversPayDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriversPayDetailsQuery, GetDriversPayDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriversPayDetailsQuery, GetDriversPayDetailsQueryVariables>(GetDriversPayDetailsDocument, options);
        }
export type GetDriversPayDetailsQueryHookResult = ReturnType<typeof useGetDriversPayDetailsQuery>;
export type GetDriversPayDetailsLazyQueryHookResult = ReturnType<typeof useGetDriversPayDetailsLazyQuery>;
export type GetDriversPayDetailsQueryResult = Apollo.QueryResult<GetDriversPayDetailsQuery, GetDriversPayDetailsQueryVariables>;
export function refetchGetDriversPayDetailsQuery(variables?: GetDriversPayDetailsQueryVariables) {
      return { query: GetDriversPayDetailsDocument, variables: variables }
    }
export const GetDriverOtherDetailsDocument = gql`
    query GetDriverOtherDetails($driverId: Long!) {
  driver(driverId: $driverId) {
    id
    licenseType
    licenseExpiryDate
    endorsements
  }
}
    `;

/**
 * __useGetDriverOtherDetailsQuery__
 *
 * To run a query within a React component, call `useGetDriverOtherDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverOtherDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverOtherDetailsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverOtherDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetDriverOtherDetailsQuery, GetDriverOtherDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverOtherDetailsQuery, GetDriverOtherDetailsQueryVariables>(GetDriverOtherDetailsDocument, options);
      }
export function useGetDriverOtherDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverOtherDetailsQuery, GetDriverOtherDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverOtherDetailsQuery, GetDriverOtherDetailsQueryVariables>(GetDriverOtherDetailsDocument, options);
        }
export type GetDriverOtherDetailsQueryHookResult = ReturnType<typeof useGetDriverOtherDetailsQuery>;
export type GetDriverOtherDetailsLazyQueryHookResult = ReturnType<typeof useGetDriverOtherDetailsLazyQuery>;
export type GetDriverOtherDetailsQueryResult = Apollo.QueryResult<GetDriverOtherDetailsQuery, GetDriverOtherDetailsQueryVariables>;
export function refetchGetDriverOtherDetailsQuery(variables?: GetDriverOtherDetailsQueryVariables) {
      return { query: GetDriverOtherDetailsDocument, variables: variables }
    }
export const GetDriversLocationsDocument = gql`
    query GetDriversLocations($bookingId: Long!) {
  driversLocations(bookingId: $bookingId) {
    id
    driverId
    longitude
    latitude
    accuracy
    createdAt
    lastModifiedAt
  }
}
    `;

/**
 * __useGetDriversLocationsQuery__
 *
 * To run a query within a React component, call `useGetDriversLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversLocationsQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetDriversLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetDriversLocationsQuery, GetDriversLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriversLocationsQuery, GetDriversLocationsQueryVariables>(GetDriversLocationsDocument, options);
      }
export function useGetDriversLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriversLocationsQuery, GetDriversLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriversLocationsQuery, GetDriversLocationsQueryVariables>(GetDriversLocationsDocument, options);
        }
export type GetDriversLocationsQueryHookResult = ReturnType<typeof useGetDriversLocationsQuery>;
export type GetDriversLocationsLazyQueryHookResult = ReturnType<typeof useGetDriversLocationsLazyQuery>;
export type GetDriversLocationsQueryResult = Apollo.QueryResult<GetDriversLocationsQuery, GetDriversLocationsQueryVariables>;
export function refetchGetDriversLocationsQuery(variables?: GetDriversLocationsQueryVariables) {
      return { query: GetDriversLocationsDocument, variables: variables }
    }
export const GetDriversLocationsByTrackingNumberDocument = gql`
    query GetDriversLocationsByTrackingNumber($trackingGuid: UUID!) {
  driversLocationsByTrackingNumber(trackingGuid: $trackingGuid) {
    id
    driverId
    longitude
    latitude
    accuracy
    createdAt
    lastModifiedAt
  }
}
    `;

/**
 * __useGetDriversLocationsByTrackingNumberQuery__
 *
 * To run a query within a React component, call `useGetDriversLocationsByTrackingNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversLocationsByTrackingNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversLocationsByTrackingNumberQuery({
 *   variables: {
 *      trackingGuid: // value for 'trackingGuid'
 *   },
 * });
 */
export function useGetDriversLocationsByTrackingNumberQuery(baseOptions: Apollo.QueryHookOptions<GetDriversLocationsByTrackingNumberQuery, GetDriversLocationsByTrackingNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriversLocationsByTrackingNumberQuery, GetDriversLocationsByTrackingNumberQueryVariables>(GetDriversLocationsByTrackingNumberDocument, options);
      }
export function useGetDriversLocationsByTrackingNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriversLocationsByTrackingNumberQuery, GetDriversLocationsByTrackingNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriversLocationsByTrackingNumberQuery, GetDriversLocationsByTrackingNumberQueryVariables>(GetDriversLocationsByTrackingNumberDocument, options);
        }
export type GetDriversLocationsByTrackingNumberQueryHookResult = ReturnType<typeof useGetDriversLocationsByTrackingNumberQuery>;
export type GetDriversLocationsByTrackingNumberLazyQueryHookResult = ReturnType<typeof useGetDriversLocationsByTrackingNumberLazyQuery>;
export type GetDriversLocationsByTrackingNumberQueryResult = Apollo.QueryResult<GetDriversLocationsByTrackingNumberQuery, GetDriversLocationsByTrackingNumberQueryVariables>;
export function refetchGetDriversLocationsByTrackingNumberQuery(variables?: GetDriversLocationsByTrackingNumberQueryVariables) {
      return { query: GetDriversLocationsByTrackingNumberDocument, variables: variables }
    }
export const GetPaymentTermsDocument = gql`
    query getPaymentTerms {
  paymentTerms {
    id
    paymentTerm
    description
  }
}
    `;

/**
 * __useGetPaymentTermsQuery__
 *
 * To run a query within a React component, call `useGetPaymentTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentTermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentTermsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentTermsQuery, GetPaymentTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentTermsQuery, GetPaymentTermsQueryVariables>(GetPaymentTermsDocument, options);
      }
export function useGetPaymentTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentTermsQuery, GetPaymentTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentTermsQuery, GetPaymentTermsQueryVariables>(GetPaymentTermsDocument, options);
        }
export type GetPaymentTermsQueryHookResult = ReturnType<typeof useGetPaymentTermsQuery>;
export type GetPaymentTermsLazyQueryHookResult = ReturnType<typeof useGetPaymentTermsLazyQuery>;
export type GetPaymentTermsQueryResult = Apollo.QueryResult<GetPaymentTermsQuery, GetPaymentTermsQueryVariables>;
export function refetchGetPaymentTermsQuery(variables?: GetPaymentTermsQueryVariables) {
      return { query: GetPaymentTermsDocument, variables: variables }
    }
export const GetDriverInvoicesDocument = gql`
    query GetDriverInvoices($driverId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [BookingInvoiceSortInput!], $where: BookingInvoiceFilterInput) {
  pagedDriverInvoices(
    driverId: $driverId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        invoiceNumber
        invoiceDate
        dueDate
        bookingId
        booking {
          id
          ourReference
        }
        poAddressLine1
        driverInvoiceNumber
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetDriverInvoicesQuery__
 *
 * To run a query within a React component, call `useGetDriverInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverInvoicesQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDriverInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetDriverInvoicesQuery, GetDriverInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverInvoicesQuery, GetDriverInvoicesQueryVariables>(GetDriverInvoicesDocument, options);
      }
export function useGetDriverInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverInvoicesQuery, GetDriverInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverInvoicesQuery, GetDriverInvoicesQueryVariables>(GetDriverInvoicesDocument, options);
        }
export type GetDriverInvoicesQueryHookResult = ReturnType<typeof useGetDriverInvoicesQuery>;
export type GetDriverInvoicesLazyQueryHookResult = ReturnType<typeof useGetDriverInvoicesLazyQuery>;
export type GetDriverInvoicesQueryResult = Apollo.QueryResult<GetDriverInvoicesQuery, GetDriverInvoicesQueryVariables>;
export function refetchGetDriverInvoicesQuery(variables?: GetDriverInvoicesQueryVariables) {
      return { query: GetDriverInvoicesDocument, variables: variables }
    }
export const GetDriverProfileDocument = gql`
    query getDriverProfile($driverId: Long!) {
  driver(driverId: $driverId) {
    id
    identityUserName
    userProfile {
      id
      firstName
      lastName
      fullName
    }
  }
}
    `;

/**
 * __useGetDriverProfileQuery__
 *
 * To run a query within a React component, call `useGetDriverProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverProfileQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverProfileQuery(baseOptions: Apollo.QueryHookOptions<GetDriverProfileQuery, GetDriverProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverProfileQuery, GetDriverProfileQueryVariables>(GetDriverProfileDocument, options);
      }
export function useGetDriverProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverProfileQuery, GetDriverProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverProfileQuery, GetDriverProfileQueryVariables>(GetDriverProfileDocument, options);
        }
export type GetDriverProfileQueryHookResult = ReturnType<typeof useGetDriverProfileQuery>;
export type GetDriverProfileLazyQueryHookResult = ReturnType<typeof useGetDriverProfileLazyQuery>;
export type GetDriverProfileQueryResult = Apollo.QueryResult<GetDriverProfileQuery, GetDriverProfileQueryVariables>;
export function refetchGetDriverProfileQuery(variables?: GetDriverProfileQueryVariables) {
      return { query: GetDriverProfileDocument, variables: variables }
    }
export const GetDriverProfileByUserDocument = gql`
    query getDriverProfileByUser {
  driverProfile {
    id
  }
}
    `;

/**
 * __useGetDriverProfileByUserQuery__
 *
 * To run a query within a React component, call `useGetDriverProfileByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverProfileByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverProfileByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDriverProfileByUserQuery(baseOptions?: Apollo.QueryHookOptions<GetDriverProfileByUserQuery, GetDriverProfileByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverProfileByUserQuery, GetDriverProfileByUserQueryVariables>(GetDriverProfileByUserDocument, options);
      }
export function useGetDriverProfileByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverProfileByUserQuery, GetDriverProfileByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverProfileByUserQuery, GetDriverProfileByUserQueryVariables>(GetDriverProfileByUserDocument, options);
        }
export type GetDriverProfileByUserQueryHookResult = ReturnType<typeof useGetDriverProfileByUserQuery>;
export type GetDriverProfileByUserLazyQueryHookResult = ReturnType<typeof useGetDriverProfileByUserLazyQuery>;
export type GetDriverProfileByUserQueryResult = Apollo.QueryResult<GetDriverProfileByUserQuery, GetDriverProfileByUserQueryVariables>;
export function refetchGetDriverProfileByUserQuery(variables?: GetDriverProfileByUserQueryVariables) {
      return { query: GetDriverProfileByUserDocument, variables: variables }
    }
export const CurrentDriversLocationsGridDocument = gql`
    query currentDriversLocationsGrid($after: String, $before: String, $first: Int, $last: Int, $order: [DriverLocationSortInput!], $where: DriverLocationFilterInput) {
  currentDriversLocations(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        driverId
        latitude
        longitude
        lastModifiedAt
        createdAt
        driverTrackingStatus {
          allocated
          nextStopPostCode
          nextStopDateTime
          nextStopLiveEta
        }
        driver {
          id
          name
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useCurrentDriversLocationsGridQuery__
 *
 * To run a query within a React component, call `useCurrentDriversLocationsGridQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentDriversLocationsGridQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentDriversLocationsGridQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCurrentDriversLocationsGridQuery(baseOptions?: Apollo.QueryHookOptions<CurrentDriversLocationsGridQuery, CurrentDriversLocationsGridQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentDriversLocationsGridQuery, CurrentDriversLocationsGridQueryVariables>(CurrentDriversLocationsGridDocument, options);
      }
export function useCurrentDriversLocationsGridLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentDriversLocationsGridQuery, CurrentDriversLocationsGridQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentDriversLocationsGridQuery, CurrentDriversLocationsGridQueryVariables>(CurrentDriversLocationsGridDocument, options);
        }
export type CurrentDriversLocationsGridQueryHookResult = ReturnType<typeof useCurrentDriversLocationsGridQuery>;
export type CurrentDriversLocationsGridLazyQueryHookResult = ReturnType<typeof useCurrentDriversLocationsGridLazyQuery>;
export type CurrentDriversLocationsGridQueryResult = Apollo.QueryResult<CurrentDriversLocationsGridQuery, CurrentDriversLocationsGridQueryVariables>;
export function refetchCurrentDriversLocationsGridQuery(variables?: CurrentDriversLocationsGridQueryVariables) {
      return { query: CurrentDriversLocationsGridDocument, variables: variables }
    }
export const GetDriverNextRouteDocument = gql`
    query getDriverNextRoute($driverId: Long!) {
  driverNextRoute(driverId: $driverId) {
    id
    longitude
    latitude
    nextStopGoogleMapsPlaceId
  }
}
    `;

/**
 * __useGetDriverNextRouteQuery__
 *
 * To run a query within a React component, call `useGetDriverNextRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverNextRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverNextRouteQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverNextRouteQuery(baseOptions: Apollo.QueryHookOptions<GetDriverNextRouteQuery, GetDriverNextRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverNextRouteQuery, GetDriverNextRouteQueryVariables>(GetDriverNextRouteDocument, options);
      }
export function useGetDriverNextRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverNextRouteQuery, GetDriverNextRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverNextRouteQuery, GetDriverNextRouteQueryVariables>(GetDriverNextRouteDocument, options);
        }
export type GetDriverNextRouteQueryHookResult = ReturnType<typeof useGetDriverNextRouteQuery>;
export type GetDriverNextRouteLazyQueryHookResult = ReturnType<typeof useGetDriverNextRouteLazyQuery>;
export type GetDriverNextRouteQueryResult = Apollo.QueryResult<GetDriverNextRouteQuery, GetDriverNextRouteQueryVariables>;
export function refetchGetDriverNextRouteQuery(variables?: GetDriverNextRouteQueryVariables) {
      return { query: GetDriverNextRouteDocument, variables: variables }
    }
export const GetBookingDriverRatingDocument = gql`
    query getBookingDriverRating($bookingId: Long!, $driverId: Long!) {
  bookingDriverRating(bookingId: $bookingId, driverId: $driverId) {
    id
    bookingId
    rating
    ratingComment
    driver {
      id
      name
    }
  }
}
    `;

/**
 * __useGetBookingDriverRatingQuery__
 *
 * To run a query within a React component, call `useGetBookingDriverRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingDriverRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingDriverRatingQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetBookingDriverRatingQuery(baseOptions: Apollo.QueryHookOptions<GetBookingDriverRatingQuery, GetBookingDriverRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingDriverRatingQuery, GetBookingDriverRatingQueryVariables>(GetBookingDriverRatingDocument, options);
      }
export function useGetBookingDriverRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingDriverRatingQuery, GetBookingDriverRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingDriverRatingQuery, GetBookingDriverRatingQueryVariables>(GetBookingDriverRatingDocument, options);
        }
export type GetBookingDriverRatingQueryHookResult = ReturnType<typeof useGetBookingDriverRatingQuery>;
export type GetBookingDriverRatingLazyQueryHookResult = ReturnType<typeof useGetBookingDriverRatingLazyQuery>;
export type GetBookingDriverRatingQueryResult = Apollo.QueryResult<GetBookingDriverRatingQuery, GetBookingDriverRatingQueryVariables>;
export function refetchGetBookingDriverRatingQuery(variables?: GetBookingDriverRatingQueryVariables) {
      return { query: GetBookingDriverRatingDocument, variables: variables }
    }
export const GetDriverRatingDocument = gql`
    query GetDriverRating($driverId: Long!) {
  driver(driverId: $driverId) {
    id
    averageRating
  }
}
    `;

/**
 * __useGetDriverRatingQuery__
 *
 * To run a query within a React component, call `useGetDriverRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverRatingQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetDriverRatingQuery(baseOptions: Apollo.QueryHookOptions<GetDriverRatingQuery, GetDriverRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverRatingQuery, GetDriverRatingQueryVariables>(GetDriverRatingDocument, options);
      }
export function useGetDriverRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverRatingQuery, GetDriverRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverRatingQuery, GetDriverRatingQueryVariables>(GetDriverRatingDocument, options);
        }
export type GetDriverRatingQueryHookResult = ReturnType<typeof useGetDriverRatingQuery>;
export type GetDriverRatingLazyQueryHookResult = ReturnType<typeof useGetDriverRatingLazyQuery>;
export type GetDriverRatingQueryResult = Apollo.QueryResult<GetDriverRatingQuery, GetDriverRatingQueryVariables>;
export function refetchGetDriverRatingQuery(variables?: GetDriverRatingQueryVariables) {
      return { query: GetDriverRatingDocument, variables: variables }
    }
export const GetDriverAllocationsDocument = gql`
    query GetDriverAllocations($driverId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [BookingDriverSortInput!], $where: BookingDriverFilterInput) {
  pagedBookingDriverAllocations(
    driverId: $driverId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        bookingId
        rating
        ratingComment
        createdBy
        lastModifiedBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetDriverAllocationsQuery__
 *
 * To run a query within a React component, call `useGetDriverAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverAllocationsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDriverAllocationsQuery(baseOptions: Apollo.QueryHookOptions<GetDriverAllocationsQuery, GetDriverAllocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverAllocationsQuery, GetDriverAllocationsQueryVariables>(GetDriverAllocationsDocument, options);
      }
export function useGetDriverAllocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverAllocationsQuery, GetDriverAllocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverAllocationsQuery, GetDriverAllocationsQueryVariables>(GetDriverAllocationsDocument, options);
        }
export type GetDriverAllocationsQueryHookResult = ReturnType<typeof useGetDriverAllocationsQuery>;
export type GetDriverAllocationsLazyQueryHookResult = ReturnType<typeof useGetDriverAllocationsLazyQuery>;
export type GetDriverAllocationsQueryResult = Apollo.QueryResult<GetDriverAllocationsQuery, GetDriverAllocationsQueryVariables>;
export function refetchGetDriverAllocationsQuery(variables?: GetDriverAllocationsQueryVariables) {
      return { query: GetDriverAllocationsDocument, variables: variables }
    }
export const GetMissingDriverPaymentDataFieldsDocument = gql`
    query getMissingDriverPaymentDataFields($driverId: Long!) {
  missingDriverPaymentDataFields(driverId: $driverId)
}
    `;

/**
 * __useGetMissingDriverPaymentDataFieldsQuery__
 *
 * To run a query within a React component, call `useGetMissingDriverPaymentDataFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingDriverPaymentDataFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingDriverPaymentDataFieldsQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *   },
 * });
 */
export function useGetMissingDriverPaymentDataFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetMissingDriverPaymentDataFieldsQuery, GetMissingDriverPaymentDataFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMissingDriverPaymentDataFieldsQuery, GetMissingDriverPaymentDataFieldsQueryVariables>(GetMissingDriverPaymentDataFieldsDocument, options);
      }
export function useGetMissingDriverPaymentDataFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMissingDriverPaymentDataFieldsQuery, GetMissingDriverPaymentDataFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMissingDriverPaymentDataFieldsQuery, GetMissingDriverPaymentDataFieldsQueryVariables>(GetMissingDriverPaymentDataFieldsDocument, options);
        }
export type GetMissingDriverPaymentDataFieldsQueryHookResult = ReturnType<typeof useGetMissingDriverPaymentDataFieldsQuery>;
export type GetMissingDriverPaymentDataFieldsLazyQueryHookResult = ReturnType<typeof useGetMissingDriverPaymentDataFieldsLazyQuery>;
export type GetMissingDriverPaymentDataFieldsQueryResult = Apollo.QueryResult<GetMissingDriverPaymentDataFieldsQuery, GetMissingDriverPaymentDataFieldsQueryVariables>;
export function refetchGetMissingDriverPaymentDataFieldsQuery(variables?: GetMissingDriverPaymentDataFieldsQueryVariables) {
      return { query: GetMissingDriverPaymentDataFieldsDocument, variables: variables }
    }
export const GetEmailTemplatesDocument = gql`
    query GetEmailTemplates($order: [EmailTemplateSortInput!], $where: EmailTemplateFilterInput) {
  emailTemplates(order: $order, where: $where) {
    ...EmailTemplateDetails
  }
}
    ${EmailTemplateDetailsFragmentDoc}`;

/**
 * __useGetEmailTemplatesQuery__
 *
 * To run a query within a React component, call `useGetEmailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailTemplatesQuery({
 *   variables: {
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEmailTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>(GetEmailTemplatesDocument, options);
      }
export function useGetEmailTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>(GetEmailTemplatesDocument, options);
        }
export type GetEmailTemplatesQueryHookResult = ReturnType<typeof useGetEmailTemplatesQuery>;
export type GetEmailTemplatesLazyQueryHookResult = ReturnType<typeof useGetEmailTemplatesLazyQuery>;
export type GetEmailTemplatesQueryResult = Apollo.QueryResult<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>;
export function refetchGetEmailTemplatesQuery(variables?: GetEmailTemplatesQueryVariables) {
      return { query: GetEmailTemplatesDocument, variables: variables }
    }
export const GetEmailTemplateTokensDocument = gql`
    query GetEmailTemplateTokens {
  emailTemplateTokens {
    value
    token
    description
    numericValue
  }
}
    `;

/**
 * __useGetEmailTemplateTokensQuery__
 *
 * To run a query within a React component, call `useGetEmailTemplateTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailTemplateTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailTemplateTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailTemplateTokensQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailTemplateTokensQuery, GetEmailTemplateTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailTemplateTokensQuery, GetEmailTemplateTokensQueryVariables>(GetEmailTemplateTokensDocument, options);
      }
export function useGetEmailTemplateTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailTemplateTokensQuery, GetEmailTemplateTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailTemplateTokensQuery, GetEmailTemplateTokensQueryVariables>(GetEmailTemplateTokensDocument, options);
        }
export type GetEmailTemplateTokensQueryHookResult = ReturnType<typeof useGetEmailTemplateTokensQuery>;
export type GetEmailTemplateTokensLazyQueryHookResult = ReturnType<typeof useGetEmailTemplateTokensLazyQuery>;
export type GetEmailTemplateTokensQueryResult = Apollo.QueryResult<GetEmailTemplateTokensQuery, GetEmailTemplateTokensQueryVariables>;
export function refetchGetEmailTemplateTokensQuery(variables?: GetEmailTemplateTokensQueryVariables) {
      return { query: GetEmailTemplateTokensDocument, variables: variables }
    }
export const GetSentEmailsDocument = gql`
    query GetSentEmails($after: String, $before: String, $first: Int, $last: Int, $order: [SentEmailSortInput!], $where: SentEmailFilterInput) {
  sentEmails(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        createdAt
        createdBy
        to
        subject
        hasAttachments
        isSuccessful
        hasErrors
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetSentEmailsQuery__
 *
 * To run a query within a React component, call `useGetSentEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSentEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSentEmailsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSentEmailsQuery(baseOptions?: Apollo.QueryHookOptions<GetSentEmailsQuery, GetSentEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSentEmailsQuery, GetSentEmailsQueryVariables>(GetSentEmailsDocument, options);
      }
export function useGetSentEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSentEmailsQuery, GetSentEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSentEmailsQuery, GetSentEmailsQueryVariables>(GetSentEmailsDocument, options);
        }
export type GetSentEmailsQueryHookResult = ReturnType<typeof useGetSentEmailsQuery>;
export type GetSentEmailsLazyQueryHookResult = ReturnType<typeof useGetSentEmailsLazyQuery>;
export type GetSentEmailsQueryResult = Apollo.QueryResult<GetSentEmailsQuery, GetSentEmailsQueryVariables>;
export function refetchGetSentEmailsQuery(variables?: GetSentEmailsQueryVariables) {
      return { query: GetSentEmailsDocument, variables: variables }
    }
export const GetSentEmailDocument = gql`
    query GetSentEmail($sentEmailId: Long!) {
  sentEmail(sentEmailId: $sentEmailId) {
    id
    createdAt
    to
    cc
    bcc
    subject
    body
    hasAttachments
    isSuccessful
    hasErrors
    errorMessages
    sendGridMessageId
  }
}
    `;

/**
 * __useGetSentEmailQuery__
 *
 * To run a query within a React component, call `useGetSentEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSentEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSentEmailQuery({
 *   variables: {
 *      sentEmailId: // value for 'sentEmailId'
 *   },
 * });
 */
export function useGetSentEmailQuery(baseOptions: Apollo.QueryHookOptions<GetSentEmailQuery, GetSentEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSentEmailQuery, GetSentEmailQueryVariables>(GetSentEmailDocument, options);
      }
export function useGetSentEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSentEmailQuery, GetSentEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSentEmailQuery, GetSentEmailQueryVariables>(GetSentEmailDocument, options);
        }
export type GetSentEmailQueryHookResult = ReturnType<typeof useGetSentEmailQuery>;
export type GetSentEmailLazyQueryHookResult = ReturnType<typeof useGetSentEmailLazyQuery>;
export type GetSentEmailQueryResult = Apollo.QueryResult<GetSentEmailQuery, GetSentEmailQueryVariables>;
export function refetchGetSentEmailQuery(variables?: GetSentEmailQueryVariables) {
      return { query: GetSentEmailDocument, variables: variables }
    }
export const GetExchangeRatesDocument = gql`
    query getExchangeRates($after: String, $before: String, $first: Int, $last: Int, $order: [ExchangeRateSortInput!], $where: ExchangeRateFilterInput) {
  exchangeRates(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        sourceCurrencyId
        sourceCurrency {
          id
          name
          code
        }
        targetCurrencyId
        targetCurrency {
          id
          name
          code
        }
        rate
        createdAt
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetExchangeRatesQuery__
 *
 * To run a query within a React component, call `useGetExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeRatesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetExchangeRatesQuery(baseOptions?: Apollo.QueryHookOptions<GetExchangeRatesQuery, GetExchangeRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExchangeRatesQuery, GetExchangeRatesQueryVariables>(GetExchangeRatesDocument, options);
      }
export function useGetExchangeRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeRatesQuery, GetExchangeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExchangeRatesQuery, GetExchangeRatesQueryVariables>(GetExchangeRatesDocument, options);
        }
export type GetExchangeRatesQueryHookResult = ReturnType<typeof useGetExchangeRatesQuery>;
export type GetExchangeRatesLazyQueryHookResult = ReturnType<typeof useGetExchangeRatesLazyQuery>;
export type GetExchangeRatesQueryResult = Apollo.QueryResult<GetExchangeRatesQuery, GetExchangeRatesQueryVariables>;
export function refetchGetExchangeRatesQuery(variables?: GetExchangeRatesQueryVariables) {
      return { query: GetExchangeRatesDocument, variables: variables }
    }
export const GetPagedFilesDocument = gql`
    query getPagedFiles($after: String, $before: String, $first: Int, $last: Int, $order: [FileSortInput!], $where: FileFilterInput) {
  files(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        originalFileName
        category
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedFilesQuery__
 *
 * To run a query within a React component, call `useGetPagedFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedFilesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedFilesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedFilesQuery, GetPagedFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedFilesQuery, GetPagedFilesQueryVariables>(GetPagedFilesDocument, options);
      }
export function useGetPagedFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedFilesQuery, GetPagedFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedFilesQuery, GetPagedFilesQueryVariables>(GetPagedFilesDocument, options);
        }
export type GetPagedFilesQueryHookResult = ReturnType<typeof useGetPagedFilesQuery>;
export type GetPagedFilesLazyQueryHookResult = ReturnType<typeof useGetPagedFilesLazyQuery>;
export type GetPagedFilesQueryResult = Apollo.QueryResult<GetPagedFilesQuery, GetPagedFilesQueryVariables>;
export function refetchGetPagedFilesQuery(variables?: GetPagedFilesQueryVariables) {
      return { query: GetPagedFilesDocument, variables: variables }
    }
export const GetInvoiceTermsDocument = gql`
    query GetInvoiceTerms {
  invoiceTerms {
    id
    name
    days
  }
}
    `;

/**
 * __useGetInvoiceTermsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceTermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceTermsQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoiceTermsQuery, GetInvoiceTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceTermsQuery, GetInvoiceTermsQueryVariables>(GetInvoiceTermsDocument, options);
      }
export function useGetInvoiceTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceTermsQuery, GetInvoiceTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceTermsQuery, GetInvoiceTermsQueryVariables>(GetInvoiceTermsDocument, options);
        }
export type GetInvoiceTermsQueryHookResult = ReturnType<typeof useGetInvoiceTermsQuery>;
export type GetInvoiceTermsLazyQueryHookResult = ReturnType<typeof useGetInvoiceTermsLazyQuery>;
export type GetInvoiceTermsQueryResult = Apollo.QueryResult<GetInvoiceTermsQuery, GetInvoiceTermsQueryVariables>;
export function refetchGetInvoiceTermsQuery(variables?: GetInvoiceTermsQueryVariables) {
      return { query: GetInvoiceTermsDocument, variables: variables }
    }
export const GetNotificationsDocument = gql`
    query getNotifications($after: String, $before: String, $first: Int, $last: Int, $where: NotificationFilterInput, $order: [NotificationSortInput!]) {
  notifications(
    after: $after
    before: $before
    first: $first
    last: $last
    where: $where
    order: $order
  ) {
    edges {
      node {
        id
        name
        description
        username
        isRead
        createdAt
        createdBy
        taskId
        priority
        date
        type
        deliveryStatus
        deliveryStatusLog
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export function refetchGetNotificationsQuery(variables?: GetNotificationsQueryVariables) {
      return { query: GetNotificationsDocument, variables: variables }
    }
export const AllNotificationHubRegistrationInfoDocument = gql`
    query AllNotificationHubRegistrationInfo {
  allNotificationHubRegistrationInfo {
    deviceType
    registrationCount
  }
}
    `;

/**
 * __useAllNotificationHubRegistrationInfoQuery__
 *
 * To run a query within a React component, call `useAllNotificationHubRegistrationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNotificationHubRegistrationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNotificationHubRegistrationInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNotificationHubRegistrationInfoQuery(baseOptions?: Apollo.QueryHookOptions<AllNotificationHubRegistrationInfoQuery, AllNotificationHubRegistrationInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNotificationHubRegistrationInfoQuery, AllNotificationHubRegistrationInfoQueryVariables>(AllNotificationHubRegistrationInfoDocument, options);
      }
export function useAllNotificationHubRegistrationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNotificationHubRegistrationInfoQuery, AllNotificationHubRegistrationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNotificationHubRegistrationInfoQuery, AllNotificationHubRegistrationInfoQueryVariables>(AllNotificationHubRegistrationInfoDocument, options);
        }
export type AllNotificationHubRegistrationInfoQueryHookResult = ReturnType<typeof useAllNotificationHubRegistrationInfoQuery>;
export type AllNotificationHubRegistrationInfoLazyQueryHookResult = ReturnType<typeof useAllNotificationHubRegistrationInfoLazyQuery>;
export type AllNotificationHubRegistrationInfoQueryResult = Apollo.QueryResult<AllNotificationHubRegistrationInfoQuery, AllNotificationHubRegistrationInfoQueryVariables>;
export function refetchAllNotificationHubRegistrationInfoQuery(variables?: AllNotificationHubRegistrationInfoQueryVariables) {
      return { query: AllNotificationHubRegistrationInfoDocument, variables: variables }
    }
export const AllNotificationHubRegistrationDocument = gql`
    query AllNotificationHubRegistration {
  allNotificationHubRegistration {
    registrationId
    tags
  }
}
    `;

/**
 * __useAllNotificationHubRegistrationQuery__
 *
 * To run a query within a React component, call `useAllNotificationHubRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNotificationHubRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNotificationHubRegistrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNotificationHubRegistrationQuery(baseOptions?: Apollo.QueryHookOptions<AllNotificationHubRegistrationQuery, AllNotificationHubRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNotificationHubRegistrationQuery, AllNotificationHubRegistrationQueryVariables>(AllNotificationHubRegistrationDocument, options);
      }
export function useAllNotificationHubRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNotificationHubRegistrationQuery, AllNotificationHubRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNotificationHubRegistrationQuery, AllNotificationHubRegistrationQueryVariables>(AllNotificationHubRegistrationDocument, options);
        }
export type AllNotificationHubRegistrationQueryHookResult = ReturnType<typeof useAllNotificationHubRegistrationQuery>;
export type AllNotificationHubRegistrationLazyQueryHookResult = ReturnType<typeof useAllNotificationHubRegistrationLazyQuery>;
export type AllNotificationHubRegistrationQueryResult = Apollo.QueryResult<AllNotificationHubRegistrationQuery, AllNotificationHubRegistrationQueryVariables>;
export function refetchAllNotificationHubRegistrationQuery(variables?: AllNotificationHubRegistrationQueryVariables) {
      return { query: AllNotificationHubRegistrationDocument, variables: variables }
    }
export const GetOtherChargePagedDocument = gql`
    query GetOtherChargePaged($after: String, $before: String, $first: Int, $last: Int, $order: [OtherChargeSortInput!], $where: OtherChargeFilterInput) {
  pagedCharges(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        description
        rate
        xeroServiceCode
        vatCategoryId
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetOtherChargePagedQuery__
 *
 * To run a query within a React component, call `useGetOtherChargePagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherChargePagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherChargePagedQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOtherChargePagedQuery(baseOptions?: Apollo.QueryHookOptions<GetOtherChargePagedQuery, GetOtherChargePagedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOtherChargePagedQuery, GetOtherChargePagedQueryVariables>(GetOtherChargePagedDocument, options);
      }
export function useGetOtherChargePagedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOtherChargePagedQuery, GetOtherChargePagedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOtherChargePagedQuery, GetOtherChargePagedQueryVariables>(GetOtherChargePagedDocument, options);
        }
export type GetOtherChargePagedQueryHookResult = ReturnType<typeof useGetOtherChargePagedQuery>;
export type GetOtherChargePagedLazyQueryHookResult = ReturnType<typeof useGetOtherChargePagedLazyQuery>;
export type GetOtherChargePagedQueryResult = Apollo.QueryResult<GetOtherChargePagedQuery, GetOtherChargePagedQueryVariables>;
export function refetchGetOtherChargePagedQuery(variables?: GetOtherChargePagedQueryVariables) {
      return { query: GetOtherChargePagedDocument, variables: variables }
    }
export const GetOtherChargeDocument = gql`
    query GetOtherCharge($id: Long!) {
  otherCharge(id: $id) {
    id
    ...OtherChargeDetails
  }
}
    ${OtherChargeDetailsFragmentDoc}`;

/**
 * __useGetOtherChargeQuery__
 *
 * To run a query within a React component, call `useGetOtherChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherChargeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOtherChargeQuery(baseOptions: Apollo.QueryHookOptions<GetOtherChargeQuery, GetOtherChargeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOtherChargeQuery, GetOtherChargeQueryVariables>(GetOtherChargeDocument, options);
      }
export function useGetOtherChargeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOtherChargeQuery, GetOtherChargeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOtherChargeQuery, GetOtherChargeQueryVariables>(GetOtherChargeDocument, options);
        }
export type GetOtherChargeQueryHookResult = ReturnType<typeof useGetOtherChargeQuery>;
export type GetOtherChargeLazyQueryHookResult = ReturnType<typeof useGetOtherChargeLazyQuery>;
export type GetOtherChargeQueryResult = Apollo.QueryResult<GetOtherChargeQuery, GetOtherChargeQueryVariables>;
export function refetchGetOtherChargeQuery(variables?: GetOtherChargeQueryVariables) {
      return { query: GetOtherChargeDocument, variables: variables }
    }
export const GetOtherChargesDocument = gql`
    query GetOtherCharges {
  otherCharges {
    id
    ...OtherChargeDetails
  }
}
    ${OtherChargeDetailsFragmentDoc}`;

/**
 * __useGetOtherChargesQuery__
 *
 * To run a query within a React component, call `useGetOtherChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherChargesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOtherChargesQuery(baseOptions?: Apollo.QueryHookOptions<GetOtherChargesQuery, GetOtherChargesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOtherChargesQuery, GetOtherChargesQueryVariables>(GetOtherChargesDocument, options);
      }
export function useGetOtherChargesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOtherChargesQuery, GetOtherChargesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOtherChargesQuery, GetOtherChargesQueryVariables>(GetOtherChargesDocument, options);
        }
export type GetOtherChargesQueryHookResult = ReturnType<typeof useGetOtherChargesQuery>;
export type GetOtherChargesLazyQueryHookResult = ReturnType<typeof useGetOtherChargesLazyQuery>;
export type GetOtherChargesQueryResult = Apollo.QueryResult<GetOtherChargesQuery, GetOtherChargesQueryVariables>;
export function refetchGetOtherChargesQuery(variables?: GetOtherChargesQueryVariables) {
      return { query: GetOtherChargesDocument, variables: variables }
    }
export const OtherChargeNameIsUniqueDocument = gql`
    query OtherChargeNameIsUnique($name: String!, $id: Long) {
  otherChargeNameIsUnique(name: $name, id: $id)
}
    `;

/**
 * __useOtherChargeNameIsUniqueQuery__
 *
 * To run a query within a React component, call `useOtherChargeNameIsUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherChargeNameIsUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherChargeNameIsUniqueQuery({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOtherChargeNameIsUniqueQuery(baseOptions: Apollo.QueryHookOptions<OtherChargeNameIsUniqueQuery, OtherChargeNameIsUniqueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OtherChargeNameIsUniqueQuery, OtherChargeNameIsUniqueQueryVariables>(OtherChargeNameIsUniqueDocument, options);
      }
export function useOtherChargeNameIsUniqueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtherChargeNameIsUniqueQuery, OtherChargeNameIsUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OtherChargeNameIsUniqueQuery, OtherChargeNameIsUniqueQueryVariables>(OtherChargeNameIsUniqueDocument, options);
        }
export type OtherChargeNameIsUniqueQueryHookResult = ReturnType<typeof useOtherChargeNameIsUniqueQuery>;
export type OtherChargeNameIsUniqueLazyQueryHookResult = ReturnType<typeof useOtherChargeNameIsUniqueLazyQuery>;
export type OtherChargeNameIsUniqueQueryResult = Apollo.QueryResult<OtherChargeNameIsUniqueQuery, OtherChargeNameIsUniqueQueryVariables>;
export function refetchOtherChargeNameIsUniqueQuery(variables?: OtherChargeNameIsUniqueQueryVariables) {
      return { query: OtherChargeNameIsUniqueDocument, variables: variables }
    }
export const GetPaymentMethodsDocument = gql`
    query GetPaymentMethods {
  paymentMethods {
    id
    name
  }
}
    `;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, options);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>;
export function refetchGetPaymentMethodsQuery(variables?: GetPaymentMethodsQueryVariables) {
      return { query: GetPaymentMethodsDocument, variables: variables }
    }
export const GetPaymentRecordDocument = gql`
    query getPaymentRecord($bookingInvoiceId: Long!) {
  paymentRecord(bookingInvoiceId: $bookingInvoiceId) {
    id
    bookingInvoiceId
    transactionId
  }
}
    `;

/**
 * __useGetPaymentRecordQuery__
 *
 * To run a query within a React component, call `useGetPaymentRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentRecordQuery({
 *   variables: {
 *      bookingInvoiceId: // value for 'bookingInvoiceId'
 *   },
 * });
 */
export function useGetPaymentRecordQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentRecordQuery, GetPaymentRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentRecordQuery, GetPaymentRecordQueryVariables>(GetPaymentRecordDocument, options);
      }
export function useGetPaymentRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentRecordQuery, GetPaymentRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentRecordQuery, GetPaymentRecordQueryVariables>(GetPaymentRecordDocument, options);
        }
export type GetPaymentRecordQueryHookResult = ReturnType<typeof useGetPaymentRecordQuery>;
export type GetPaymentRecordLazyQueryHookResult = ReturnType<typeof useGetPaymentRecordLazyQuery>;
export type GetPaymentRecordQueryResult = Apollo.QueryResult<GetPaymentRecordQuery, GetPaymentRecordQueryVariables>;
export function refetchGetPaymentRecordQuery(variables?: GetPaymentRecordQueryVariables) {
      return { query: GetPaymentRecordDocument, variables: variables }
    }
export const GetPermissionsDocument = gql`
    query getPermissions($role: String!) {
  policyRoles(role: $role) {
    id
    roleId
    role {
      id
      name
      description
    }
    policyId
    policy {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useGetPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
      }
export function useGetPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsQuery, GetPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(GetPermissionsDocument, options);
        }
export type GetPermissionsQueryHookResult = ReturnType<typeof useGetPermissionsQuery>;
export type GetPermissionsLazyQueryHookResult = ReturnType<typeof useGetPermissionsLazyQuery>;
export type GetPermissionsQueryResult = Apollo.QueryResult<GetPermissionsQuery, GetPermissionsQueryVariables>;
export function refetchGetPermissionsQuery(variables?: GetPermissionsQueryVariables) {
      return { query: GetPermissionsDocument, variables: variables }
    }
export const GetPagedReasonCodesDocument = gql`
    query GetPagedReasonCodes($customerId: Long, $after: String, $before: String, $first: Int, $last: Int, $where: ReasonCodeFilterInput, $order: [ReasonCodeSortInput!]) {
  pagedReasonCodes(
    customerId: $customerId
    after: $after
    before: $before
    first: $first
    last: $last
    where: $where
    order: $order
  ) {
    edges {
      node {
        id
        name
        code
        createdAt
        createdBy
        customer {
          id
          name
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedReasonCodesQuery__
 *
 * To run a query within a React component, call `useGetPagedReasonCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedReasonCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedReasonCodesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPagedReasonCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedReasonCodesQuery, GetPagedReasonCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedReasonCodesQuery, GetPagedReasonCodesQueryVariables>(GetPagedReasonCodesDocument, options);
      }
export function useGetPagedReasonCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedReasonCodesQuery, GetPagedReasonCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedReasonCodesQuery, GetPagedReasonCodesQueryVariables>(GetPagedReasonCodesDocument, options);
        }
export type GetPagedReasonCodesQueryHookResult = ReturnType<typeof useGetPagedReasonCodesQuery>;
export type GetPagedReasonCodesLazyQueryHookResult = ReturnType<typeof useGetPagedReasonCodesLazyQuery>;
export type GetPagedReasonCodesQueryResult = Apollo.QueryResult<GetPagedReasonCodesQuery, GetPagedReasonCodesQueryVariables>;
export function refetchGetPagedReasonCodesQuery(variables?: GetPagedReasonCodesQueryVariables) {
      return { query: GetPagedReasonCodesDocument, variables: variables }
    }
export const GetUserPagedReasonCodesDocument = gql`
    query GetUserPagedReasonCodes($after: String, $before: String, $first: Int, $last: Int, $where: ReasonCodeFilterInput, $order: [ReasonCodeSortInput!]) {
  userPagedReasonCodes(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        code
        description
        createdAt
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUserPagedReasonCodesQuery__
 *
 * To run a query within a React component, call `useGetUserPagedReasonCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPagedReasonCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPagedReasonCodesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetUserPagedReasonCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPagedReasonCodesQuery, GetUserPagedReasonCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPagedReasonCodesQuery, GetUserPagedReasonCodesQueryVariables>(GetUserPagedReasonCodesDocument, options);
      }
export function useGetUserPagedReasonCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPagedReasonCodesQuery, GetUserPagedReasonCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPagedReasonCodesQuery, GetUserPagedReasonCodesQueryVariables>(GetUserPagedReasonCodesDocument, options);
        }
export type GetUserPagedReasonCodesQueryHookResult = ReturnType<typeof useGetUserPagedReasonCodesQuery>;
export type GetUserPagedReasonCodesLazyQueryHookResult = ReturnType<typeof useGetUserPagedReasonCodesLazyQuery>;
export type GetUserPagedReasonCodesQueryResult = Apollo.QueryResult<GetUserPagedReasonCodesQuery, GetUserPagedReasonCodesQueryVariables>;
export function refetchGetUserPagedReasonCodesQuery(variables?: GetUserPagedReasonCodesQueryVariables) {
      return { query: GetUserPagedReasonCodesDocument, variables: variables }
    }
export const GetReasonCodeDocument = gql`
    query GetReasonCode($id: Long!) {
  reasonCode(id: $id) {
    id
    ...ReasonCodeDetails
  }
}
    ${ReasonCodeDetailsFragmentDoc}`;

/**
 * __useGetReasonCodeQuery__
 *
 * To run a query within a React component, call `useGetReasonCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReasonCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReasonCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReasonCodeQuery(baseOptions: Apollo.QueryHookOptions<GetReasonCodeQuery, GetReasonCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReasonCodeQuery, GetReasonCodeQueryVariables>(GetReasonCodeDocument, options);
      }
export function useGetReasonCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReasonCodeQuery, GetReasonCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReasonCodeQuery, GetReasonCodeQueryVariables>(GetReasonCodeDocument, options);
        }
export type GetReasonCodeQueryHookResult = ReturnType<typeof useGetReasonCodeQuery>;
export type GetReasonCodeLazyQueryHookResult = ReturnType<typeof useGetReasonCodeLazyQuery>;
export type GetReasonCodeQueryResult = Apollo.QueryResult<GetReasonCodeQuery, GetReasonCodeQueryVariables>;
export function refetchGetReasonCodeQuery(variables?: GetReasonCodeQueryVariables) {
      return { query: GetReasonCodeDocument, variables: variables }
    }
export const GetReasonCodesDocument = gql`
    query GetReasonCodes($customerId: Long!) {
  reasonCodes(customerId: $customerId) {
    id
    ...ReasonCodeDetails
  }
}
    ${ReasonCodeDetailsFragmentDoc}`;

/**
 * __useGetReasonCodesQuery__
 *
 * To run a query within a React component, call `useGetReasonCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReasonCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReasonCodesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetReasonCodesQuery(baseOptions: Apollo.QueryHookOptions<GetReasonCodesQuery, GetReasonCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReasonCodesQuery, GetReasonCodesQueryVariables>(GetReasonCodesDocument, options);
      }
export function useGetReasonCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReasonCodesQuery, GetReasonCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReasonCodesQuery, GetReasonCodesQueryVariables>(GetReasonCodesDocument, options);
        }
export type GetReasonCodesQueryHookResult = ReturnType<typeof useGetReasonCodesQuery>;
export type GetReasonCodesLazyQueryHookResult = ReturnType<typeof useGetReasonCodesLazyQuery>;
export type GetReasonCodesQueryResult = Apollo.QueryResult<GetReasonCodesQuery, GetReasonCodesQueryVariables>;
export function refetchGetReasonCodesQuery(variables?: GetReasonCodesQueryVariables) {
      return { query: GetReasonCodesDocument, variables: variables }
    }
export const GetUserReasonCodesDocument = gql`
    query GetUserReasonCodes($isIncludeSubCustomers: Boolean!) {
  userReasonCodes(isIncludeSubCustomers: $isIncludeSubCustomers) {
    id
    ...ReasonCodeDetails
  }
}
    ${ReasonCodeDetailsFragmentDoc}`;

/**
 * __useGetUserReasonCodesQuery__
 *
 * To run a query within a React component, call `useGetUserReasonCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReasonCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReasonCodesQuery({
 *   variables: {
 *      isIncludeSubCustomers: // value for 'isIncludeSubCustomers'
 *   },
 * });
 */
export function useGetUserReasonCodesQuery(baseOptions: Apollo.QueryHookOptions<GetUserReasonCodesQuery, GetUserReasonCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserReasonCodesQuery, GetUserReasonCodesQueryVariables>(GetUserReasonCodesDocument, options);
      }
export function useGetUserReasonCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserReasonCodesQuery, GetUserReasonCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserReasonCodesQuery, GetUserReasonCodesQueryVariables>(GetUserReasonCodesDocument, options);
        }
export type GetUserReasonCodesQueryHookResult = ReturnType<typeof useGetUserReasonCodesQuery>;
export type GetUserReasonCodesLazyQueryHookResult = ReturnType<typeof useGetUserReasonCodesLazyQuery>;
export type GetUserReasonCodesQueryResult = Apollo.QueryResult<GetUserReasonCodesQuery, GetUserReasonCodesQueryVariables>;
export function refetchGetUserReasonCodesQuery(variables?: GetUserReasonCodesQueryVariables) {
      return { query: GetUserReasonCodesDocument, variables: variables }
    }
export const GetCustomerIncomeReportDocument = gql`
    query getCustomerIncomeReport($input: CustomerIncomeReportInputDtoInput!) {
  customerIncomeReport(input: $input) {
    ...ReportRows
  }
}
    ${ReportRowsFragmentDoc}`;

/**
 * __useGetCustomerIncomeReportQuery__
 *
 * To run a query within a React component, call `useGetCustomerIncomeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerIncomeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerIncomeReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomerIncomeReportQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerIncomeReportQuery, GetCustomerIncomeReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerIncomeReportQuery, GetCustomerIncomeReportQueryVariables>(GetCustomerIncomeReportDocument, options);
      }
export function useGetCustomerIncomeReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerIncomeReportQuery, GetCustomerIncomeReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerIncomeReportQuery, GetCustomerIncomeReportQueryVariables>(GetCustomerIncomeReportDocument, options);
        }
export type GetCustomerIncomeReportQueryHookResult = ReturnType<typeof useGetCustomerIncomeReportQuery>;
export type GetCustomerIncomeReportLazyQueryHookResult = ReturnType<typeof useGetCustomerIncomeReportLazyQuery>;
export type GetCustomerIncomeReportQueryResult = Apollo.QueryResult<GetCustomerIncomeReportQuery, GetCustomerIncomeReportQueryVariables>;
export function refetchGetCustomerIncomeReportQuery(variables?: GetCustomerIncomeReportQueryVariables) {
      return { query: GetCustomerIncomeReportDocument, variables: variables }
    }
export const GetMyIncomeReportDocument = gql`
    query getMyIncomeReport($input: MyIncomeReportInputDtoInput!) {
  myIncomeReport(input: $input) {
    ...ReportRows
  }
}
    ${ReportRowsFragmentDoc}`;

/**
 * __useGetMyIncomeReportQuery__
 *
 * To run a query within a React component, call `useGetMyIncomeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyIncomeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyIncomeReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyIncomeReportQuery(baseOptions: Apollo.QueryHookOptions<GetMyIncomeReportQuery, GetMyIncomeReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyIncomeReportQuery, GetMyIncomeReportQueryVariables>(GetMyIncomeReportDocument, options);
      }
export function useGetMyIncomeReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyIncomeReportQuery, GetMyIncomeReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyIncomeReportQuery, GetMyIncomeReportQueryVariables>(GetMyIncomeReportDocument, options);
        }
export type GetMyIncomeReportQueryHookResult = ReturnType<typeof useGetMyIncomeReportQuery>;
export type GetMyIncomeReportLazyQueryHookResult = ReturnType<typeof useGetMyIncomeReportLazyQuery>;
export type GetMyIncomeReportQueryResult = Apollo.QueryResult<GetMyIncomeReportQuery, GetMyIncomeReportQueryVariables>;
export function refetchGetMyIncomeReportQuery(variables?: GetMyIncomeReportQueryVariables) {
      return { query: GetMyIncomeReportDocument, variables: variables }
    }
export const GetCustomerContactListReportDocument = gql`
    query getCustomerContactListReport($input: CustomerContactListReportInputDtoInput!) {
  customerContactListReport(input: $input) {
    ...ReportRows
  }
}
    ${ReportRowsFragmentDoc}`;

/**
 * __useGetCustomerContactListReportQuery__
 *
 * To run a query within a React component, call `useGetCustomerContactListReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerContactListReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerContactListReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomerContactListReportQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerContactListReportQuery, GetCustomerContactListReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerContactListReportQuery, GetCustomerContactListReportQueryVariables>(GetCustomerContactListReportDocument, options);
      }
export function useGetCustomerContactListReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerContactListReportQuery, GetCustomerContactListReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerContactListReportQuery, GetCustomerContactListReportQueryVariables>(GetCustomerContactListReportDocument, options);
        }
export type GetCustomerContactListReportQueryHookResult = ReturnType<typeof useGetCustomerContactListReportQuery>;
export type GetCustomerContactListReportLazyQueryHookResult = ReturnType<typeof useGetCustomerContactListReportLazyQuery>;
export type GetCustomerContactListReportQueryResult = Apollo.QueryResult<GetCustomerContactListReportQuery, GetCustomerContactListReportQueryVariables>;
export function refetchGetCustomerContactListReportQuery(variables?: GetCustomerContactListReportQueryVariables) {
      return { query: GetCustomerContactListReportDocument, variables: variables }
    }
export const GetDriverListReportDocument = gql`
    query getDriverListReport($input: DriverListReportInputDtoInput!) {
  driverListReport(input: $input) {
    ...ReportRows
  }
}
    ${ReportRowsFragmentDoc}`;

/**
 * __useGetDriverListReportQuery__
 *
 * To run a query within a React component, call `useGetDriverListReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverListReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverListReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDriverListReportQuery(baseOptions: Apollo.QueryHookOptions<GetDriverListReportQuery, GetDriverListReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDriverListReportQuery, GetDriverListReportQueryVariables>(GetDriverListReportDocument, options);
      }
export function useGetDriverListReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDriverListReportQuery, GetDriverListReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDriverListReportQuery, GetDriverListReportQueryVariables>(GetDriverListReportDocument, options);
        }
export type GetDriverListReportQueryHookResult = ReturnType<typeof useGetDriverListReportQuery>;
export type GetDriverListReportLazyQueryHookResult = ReturnType<typeof useGetDriverListReportLazyQuery>;
export type GetDriverListReportQueryResult = Apollo.QueryResult<GetDriverListReportQuery, GetDriverListReportQueryVariables>;
export function refetchGetDriverListReportQuery(variables?: GetDriverListReportQueryVariables) {
      return { query: GetDriverListReportDocument, variables: variables }
    }
export const GetSalesCommissionReportDocument = gql`
    query getSalesCommissionReport($input: SalesCommissionReportInputDtoInput!) {
  salesCommissionReport(input: $input) {
    ...ReportRows
  }
}
    ${ReportRowsFragmentDoc}`;

/**
 * __useGetSalesCommissionReportQuery__
 *
 * To run a query within a React component, call `useGetSalesCommissionReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesCommissionReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesCommissionReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSalesCommissionReportQuery(baseOptions: Apollo.QueryHookOptions<GetSalesCommissionReportQuery, GetSalesCommissionReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesCommissionReportQuery, GetSalesCommissionReportQueryVariables>(GetSalesCommissionReportDocument, options);
      }
export function useGetSalesCommissionReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesCommissionReportQuery, GetSalesCommissionReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesCommissionReportQuery, GetSalesCommissionReportQueryVariables>(GetSalesCommissionReportDocument, options);
        }
export type GetSalesCommissionReportQueryHookResult = ReturnType<typeof useGetSalesCommissionReportQuery>;
export type GetSalesCommissionReportLazyQueryHookResult = ReturnType<typeof useGetSalesCommissionReportLazyQuery>;
export type GetSalesCommissionReportQueryResult = Apollo.QueryResult<GetSalesCommissionReportQuery, GetSalesCommissionReportQueryVariables>;
export function refetchGetSalesCommissionReportQuery(variables?: GetSalesCommissionReportQueryVariables) {
      return { query: GetSalesCommissionReportDocument, variables: variables }
    }
export const GetProofOfDeliveryReportDocument = gql`
    query getProofOfDeliveryReport($input: ProofOfDeliveryReportInputDtoInput!) {
  proofOfDeliveryReport(input: $input) {
    ...ReportRows
  }
}
    ${ReportRowsFragmentDoc}`;

/**
 * __useGetProofOfDeliveryReportQuery__
 *
 * To run a query within a React component, call `useGetProofOfDeliveryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProofOfDeliveryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProofOfDeliveryReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProofOfDeliveryReportQuery(baseOptions: Apollo.QueryHookOptions<GetProofOfDeliveryReportQuery, GetProofOfDeliveryReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProofOfDeliveryReportQuery, GetProofOfDeliveryReportQueryVariables>(GetProofOfDeliveryReportDocument, options);
      }
export function useGetProofOfDeliveryReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProofOfDeliveryReportQuery, GetProofOfDeliveryReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProofOfDeliveryReportQuery, GetProofOfDeliveryReportQueryVariables>(GetProofOfDeliveryReportDocument, options);
        }
export type GetProofOfDeliveryReportQueryHookResult = ReturnType<typeof useGetProofOfDeliveryReportQuery>;
export type GetProofOfDeliveryReportLazyQueryHookResult = ReturnType<typeof useGetProofOfDeliveryReportLazyQuery>;
export type GetProofOfDeliveryReportQueryResult = Apollo.QueryResult<GetProofOfDeliveryReportQuery, GetProofOfDeliveryReportQueryVariables>;
export function refetchGetProofOfDeliveryReportQuery(variables?: GetProofOfDeliveryReportQueryVariables) {
      return { query: GetProofOfDeliveryReportDocument, variables: variables }
    }
export const GetPagedExternalDriverInvoicesDocument = gql`
    query getPagedExternalDriverInvoices($after: String, $before: String, $first: Int, $last: Int, $order: [ExternalDriverInvoiceSortInput!], $where: ExternalDriverInvoiceFilterInput) {
  pagedExternalDriverInvoices(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        reference
        description
        quantity
        bookingInvoiceId
        bookingInvoice {
          id
          bookingId
        }
        invoiceNumber
        fileId
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedExternalDriverInvoicesQuery__
 *
 * To run a query within a React component, call `useGetPagedExternalDriverInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedExternalDriverInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedExternalDriverInvoicesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedExternalDriverInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedExternalDriverInvoicesQuery, GetPagedExternalDriverInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedExternalDriverInvoicesQuery, GetPagedExternalDriverInvoicesQueryVariables>(GetPagedExternalDriverInvoicesDocument, options);
      }
export function useGetPagedExternalDriverInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedExternalDriverInvoicesQuery, GetPagedExternalDriverInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedExternalDriverInvoicesQuery, GetPagedExternalDriverInvoicesQueryVariables>(GetPagedExternalDriverInvoicesDocument, options);
        }
export type GetPagedExternalDriverInvoicesQueryHookResult = ReturnType<typeof useGetPagedExternalDriverInvoicesQuery>;
export type GetPagedExternalDriverInvoicesLazyQueryHookResult = ReturnType<typeof useGetPagedExternalDriverInvoicesLazyQuery>;
export type GetPagedExternalDriverInvoicesQueryResult = Apollo.QueryResult<GetPagedExternalDriverInvoicesQuery, GetPagedExternalDriverInvoicesQueryVariables>;
export function refetchGetPagedExternalDriverInvoicesQuery(variables?: GetPagedExternalDriverInvoicesQueryVariables) {
      return { query: GetPagedExternalDriverInvoicesDocument, variables: variables }
    }
export const GetScheduledBookingTemplateByBookingIdDocument = gql`
    query getScheduledBookingTemplateByBookingId($bookingId: Long!) {
  scheduledBookingTemplateByBookingId(bookingId: $bookingId) {
    id
    ...TemplateDetails
  }
}
    ${TemplateDetailsFragmentDoc}`;

/**
 * __useGetScheduledBookingTemplateByBookingIdQuery__
 *
 * To run a query within a React component, call `useGetScheduledBookingTemplateByBookingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledBookingTemplateByBookingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledBookingTemplateByBookingIdQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetScheduledBookingTemplateByBookingIdQuery(baseOptions: Apollo.QueryHookOptions<GetScheduledBookingTemplateByBookingIdQuery, GetScheduledBookingTemplateByBookingIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledBookingTemplateByBookingIdQuery, GetScheduledBookingTemplateByBookingIdQueryVariables>(GetScheduledBookingTemplateByBookingIdDocument, options);
      }
export function useGetScheduledBookingTemplateByBookingIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledBookingTemplateByBookingIdQuery, GetScheduledBookingTemplateByBookingIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledBookingTemplateByBookingIdQuery, GetScheduledBookingTemplateByBookingIdQueryVariables>(GetScheduledBookingTemplateByBookingIdDocument, options);
        }
export type GetScheduledBookingTemplateByBookingIdQueryHookResult = ReturnType<typeof useGetScheduledBookingTemplateByBookingIdQuery>;
export type GetScheduledBookingTemplateByBookingIdLazyQueryHookResult = ReturnType<typeof useGetScheduledBookingTemplateByBookingIdLazyQuery>;
export type GetScheduledBookingTemplateByBookingIdQueryResult = Apollo.QueryResult<GetScheduledBookingTemplateByBookingIdQuery, GetScheduledBookingTemplateByBookingIdQueryVariables>;
export function refetchGetScheduledBookingTemplateByBookingIdQuery(variables?: GetScheduledBookingTemplateByBookingIdQueryVariables) {
      return { query: GetScheduledBookingTemplateByBookingIdDocument, variables: variables }
    }
export const GetScheduledBookingDatesDocument = gql`
    query getScheduledBookingDates($templateId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [ScheduledBookingDateSortInput!]) {
  scheduledBookingDates(
    templateId: $templateId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
  ) {
    edges {
      node {
        id
        templateId
        executeOnDate
        taskId
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetScheduledBookingDatesQuery__
 *
 * To run a query within a React component, call `useGetScheduledBookingDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledBookingDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledBookingDatesQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetScheduledBookingDatesQuery(baseOptions: Apollo.QueryHookOptions<GetScheduledBookingDatesQuery, GetScheduledBookingDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledBookingDatesQuery, GetScheduledBookingDatesQueryVariables>(GetScheduledBookingDatesDocument, options);
      }
export function useGetScheduledBookingDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledBookingDatesQuery, GetScheduledBookingDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledBookingDatesQuery, GetScheduledBookingDatesQueryVariables>(GetScheduledBookingDatesDocument, options);
        }
export type GetScheduledBookingDatesQueryHookResult = ReturnType<typeof useGetScheduledBookingDatesQuery>;
export type GetScheduledBookingDatesLazyQueryHookResult = ReturnType<typeof useGetScheduledBookingDatesLazyQuery>;
export type GetScheduledBookingDatesQueryResult = Apollo.QueryResult<GetScheduledBookingDatesQuery, GetScheduledBookingDatesQueryVariables>;
export function refetchGetScheduledBookingDatesQuery(variables?: GetScheduledBookingDatesQueryVariables) {
      return { query: GetScheduledBookingDatesDocument, variables: variables }
    }
export const GetScheduledBookingTasksDocument = gql`
    query getScheduledBookingTasks($templateId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [ScheduledBookingTaskSortInput!], $where: ScheduledBookingTaskFilterInput) {
  scheduledBookingTasks(
    templateId: $templateId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        templateId
        dueByDateTime
        createdAt
        lastModifiedAt
        status
        bookingId
        errorMessage
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetScheduledBookingTasksQuery__
 *
 * To run a query within a React component, call `useGetScheduledBookingTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledBookingTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledBookingTasksQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetScheduledBookingTasksQuery(baseOptions: Apollo.QueryHookOptions<GetScheduledBookingTasksQuery, GetScheduledBookingTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledBookingTasksQuery, GetScheduledBookingTasksQueryVariables>(GetScheduledBookingTasksDocument, options);
      }
export function useGetScheduledBookingTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledBookingTasksQuery, GetScheduledBookingTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledBookingTasksQuery, GetScheduledBookingTasksQueryVariables>(GetScheduledBookingTasksDocument, options);
        }
export type GetScheduledBookingTasksQueryHookResult = ReturnType<typeof useGetScheduledBookingTasksQuery>;
export type GetScheduledBookingTasksLazyQueryHookResult = ReturnType<typeof useGetScheduledBookingTasksLazyQuery>;
export type GetScheduledBookingTasksQueryResult = Apollo.QueryResult<GetScheduledBookingTasksQuery, GetScheduledBookingTasksQueryVariables>;
export function refetchGetScheduledBookingTasksQuery(variables?: GetScheduledBookingTasksQueryVariables) {
      return { query: GetScheduledBookingTasksDocument, variables: variables }
    }
export const GetScheduledExchangeRateTemplatesDocument = gql`
    query getScheduledExchangeRateTemplates($after: String, $before: String, $first: Int, $last: Int, $order: [ScheduledExchangeRateTemplateSortInput!], $where: ScheduledExchangeRateTemplateFilterInput) {
  scheduledExchangeRateTemplates(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        currencyId
        currency {
          id
          code
        }
        date
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetScheduledExchangeRateTemplatesQuery__
 *
 * To run a query within a React component, call `useGetScheduledExchangeRateTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledExchangeRateTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledExchangeRateTemplatesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetScheduledExchangeRateTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetScheduledExchangeRateTemplatesQuery, GetScheduledExchangeRateTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledExchangeRateTemplatesQuery, GetScheduledExchangeRateTemplatesQueryVariables>(GetScheduledExchangeRateTemplatesDocument, options);
      }
export function useGetScheduledExchangeRateTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledExchangeRateTemplatesQuery, GetScheduledExchangeRateTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledExchangeRateTemplatesQuery, GetScheduledExchangeRateTemplatesQueryVariables>(GetScheduledExchangeRateTemplatesDocument, options);
        }
export type GetScheduledExchangeRateTemplatesQueryHookResult = ReturnType<typeof useGetScheduledExchangeRateTemplatesQuery>;
export type GetScheduledExchangeRateTemplatesLazyQueryHookResult = ReturnType<typeof useGetScheduledExchangeRateTemplatesLazyQuery>;
export type GetScheduledExchangeRateTemplatesQueryResult = Apollo.QueryResult<GetScheduledExchangeRateTemplatesQuery, GetScheduledExchangeRateTemplatesQueryVariables>;
export function refetchGetScheduledExchangeRateTemplatesQuery(variables?: GetScheduledExchangeRateTemplatesQueryVariables) {
      return { query: GetScheduledExchangeRateTemplatesDocument, variables: variables }
    }
export const GetScheduledExchangeRateTasksDocument = gql`
    query getScheduledExchangeRateTasks($after: String, $before: String, $first: Int, $last: Int, $order: [ScheduledExchangeRateTaskSortInput!], $where: ScheduledExchangeRateTaskFilterInput) {
  scheduledExchangeRateTasks(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        status
        templateId
        template {
          id
          currencyId
          currency {
            id
            code
          }
        }
        dueByDateTime
        createdBy
        createdAt
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetScheduledExchangeRateTasksQuery__
 *
 * To run a query within a React component, call `useGetScheduledExchangeRateTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledExchangeRateTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledExchangeRateTasksQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetScheduledExchangeRateTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetScheduledExchangeRateTasksQuery, GetScheduledExchangeRateTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduledExchangeRateTasksQuery, GetScheduledExchangeRateTasksQueryVariables>(GetScheduledExchangeRateTasksDocument, options);
      }
export function useGetScheduledExchangeRateTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduledExchangeRateTasksQuery, GetScheduledExchangeRateTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduledExchangeRateTasksQuery, GetScheduledExchangeRateTasksQueryVariables>(GetScheduledExchangeRateTasksDocument, options);
        }
export type GetScheduledExchangeRateTasksQueryHookResult = ReturnType<typeof useGetScheduledExchangeRateTasksQuery>;
export type GetScheduledExchangeRateTasksLazyQueryHookResult = ReturnType<typeof useGetScheduledExchangeRateTasksLazyQuery>;
export type GetScheduledExchangeRateTasksQueryResult = Apollo.QueryResult<GetScheduledExchangeRateTasksQuery, GetScheduledExchangeRateTasksQueryVariables>;
export function refetchGetScheduledExchangeRateTasksQuery(variables?: GetScheduledExchangeRateTasksQueryVariables) {
      return { query: GetScheduledExchangeRateTasksDocument, variables: variables }
    }
export const GetSearchResultsDocument = gql`
    query GetSearchResults($query: String!) {
  searchResults(query: $query) {
    entityId
    entityName
    description
  }
}
    `;

/**
 * __useGetSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(GetSearchResultsDocument, options);
      }
export function useGetSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(GetSearchResultsDocument, options);
        }
export type GetSearchResultsQueryHookResult = ReturnType<typeof useGetSearchResultsQuery>;
export type GetSearchResultsLazyQueryHookResult = ReturnType<typeof useGetSearchResultsLazyQuery>;
export type GetSearchResultsQueryResult = Apollo.QueryResult<GetSearchResultsQuery, GetSearchResultsQueryVariables>;
export function refetchGetSearchResultsQuery(variables?: GetSearchResultsQueryVariables) {
      return { query: GetSearchResultsDocument, variables: variables }
    }
export const GetExternalSearchResultsDocument = gql`
    query GetExternalSearchResults($query: String!) {
  externalSearchResults(query: $query) {
    entityId
    entityName
    description
  }
}
    `;

/**
 * __useGetExternalSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetExternalSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalSearchResultsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetExternalSearchResultsQuery(baseOptions: Apollo.QueryHookOptions<GetExternalSearchResultsQuery, GetExternalSearchResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalSearchResultsQuery, GetExternalSearchResultsQueryVariables>(GetExternalSearchResultsDocument, options);
      }
export function useGetExternalSearchResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalSearchResultsQuery, GetExternalSearchResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalSearchResultsQuery, GetExternalSearchResultsQueryVariables>(GetExternalSearchResultsDocument, options);
        }
export type GetExternalSearchResultsQueryHookResult = ReturnType<typeof useGetExternalSearchResultsQuery>;
export type GetExternalSearchResultsLazyQueryHookResult = ReturnType<typeof useGetExternalSearchResultsLazyQuery>;
export type GetExternalSearchResultsQueryResult = Apollo.QueryResult<GetExternalSearchResultsQuery, GetExternalSearchResultsQueryVariables>;
export function refetchGetExternalSearchResultsQuery(variables?: GetExternalSearchResultsQueryVariables) {
      return { query: GetExternalSearchResultsDocument, variables: variables }
    }
export const GetStandardOperatingProceduresDocument = gql`
    query GetStandardOperatingProcedures($customerId: Long, $after: String, $before: String, $first: Int, $last: Int, $where: StandardOperatingProcedureFilterInput, $order: [StandardOperatingProcedureSortInput!]) {
  standardOperatingProcedures(
    customerId: $customerId
    after: $after
    before: $before
    first: $first
    last: $last
    where: $where
    order: $order
  ) {
    edges {
      node {
        id
        name
        isActive
        createdBy
        lastModifiedAt
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetStandardOperatingProceduresQuery__
 *
 * To run a query within a React component, call `useGetStandardOperatingProceduresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardOperatingProceduresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardOperatingProceduresQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetStandardOperatingProceduresQuery(baseOptions?: Apollo.QueryHookOptions<GetStandardOperatingProceduresQuery, GetStandardOperatingProceduresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStandardOperatingProceduresQuery, GetStandardOperatingProceduresQueryVariables>(GetStandardOperatingProceduresDocument, options);
      }
export function useGetStandardOperatingProceduresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStandardOperatingProceduresQuery, GetStandardOperatingProceduresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStandardOperatingProceduresQuery, GetStandardOperatingProceduresQueryVariables>(GetStandardOperatingProceduresDocument, options);
        }
export type GetStandardOperatingProceduresQueryHookResult = ReturnType<typeof useGetStandardOperatingProceduresQuery>;
export type GetStandardOperatingProceduresLazyQueryHookResult = ReturnType<typeof useGetStandardOperatingProceduresLazyQuery>;
export type GetStandardOperatingProceduresQueryResult = Apollo.QueryResult<GetStandardOperatingProceduresQuery, GetStandardOperatingProceduresQueryVariables>;
export function refetchGetStandardOperatingProceduresQuery(variables?: GetStandardOperatingProceduresQueryVariables) {
      return { query: GetStandardOperatingProceduresDocument, variables: variables }
    }
export const GetStandardOperatingProcedureDocument = gql`
    query GetStandardOperatingProcedure($id: Long!) {
  standardOperatingProcedure(id: $id) {
    id
    ...StandardOperatingProcedureDetails
  }
}
    ${StandardOperatingProcedureDetailsFragmentDoc}`;

/**
 * __useGetStandardOperatingProcedureQuery__
 *
 * To run a query within a React component, call `useGetStandardOperatingProcedureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardOperatingProcedureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardOperatingProcedureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStandardOperatingProcedureQuery(baseOptions: Apollo.QueryHookOptions<GetStandardOperatingProcedureQuery, GetStandardOperatingProcedureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStandardOperatingProcedureQuery, GetStandardOperatingProcedureQueryVariables>(GetStandardOperatingProcedureDocument, options);
      }
export function useGetStandardOperatingProcedureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStandardOperatingProcedureQuery, GetStandardOperatingProcedureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStandardOperatingProcedureQuery, GetStandardOperatingProcedureQueryVariables>(GetStandardOperatingProcedureDocument, options);
        }
export type GetStandardOperatingProcedureQueryHookResult = ReturnType<typeof useGetStandardOperatingProcedureQuery>;
export type GetStandardOperatingProcedureLazyQueryHookResult = ReturnType<typeof useGetStandardOperatingProcedureLazyQuery>;
export type GetStandardOperatingProcedureQueryResult = Apollo.QueryResult<GetStandardOperatingProcedureQuery, GetStandardOperatingProcedureQueryVariables>;
export function refetchGetStandardOperatingProcedureQuery(variables?: GetStandardOperatingProcedureQueryVariables) {
      return { query: GetStandardOperatingProcedureDocument, variables: variables }
    }
export const GetStandardOperatingProcedureHistoryDocument = gql`
    query GetStandardOperatingProcedureHistory($after: String, $before: String, $first: Int, $last: Int, $order: [StandardOperatingProcedureHistoryDtoSortInput!], $where: StandardOperatingProcedureHistoryDtoFilterInput) {
  standardOperatingProcedureHistory(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        sopId
        name
        isActive
        description
        lastModifiedAt
        lastModifiedBy
        isAttachedToAllCustomers
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetStandardOperatingProcedureHistoryQuery__
 *
 * To run a query within a React component, call `useGetStandardOperatingProcedureHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardOperatingProcedureHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardOperatingProcedureHistoryQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetStandardOperatingProcedureHistoryQuery(baseOptions?: Apollo.QueryHookOptions<GetStandardOperatingProcedureHistoryQuery, GetStandardOperatingProcedureHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStandardOperatingProcedureHistoryQuery, GetStandardOperatingProcedureHistoryQueryVariables>(GetStandardOperatingProcedureHistoryDocument, options);
      }
export function useGetStandardOperatingProcedureHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStandardOperatingProcedureHistoryQuery, GetStandardOperatingProcedureHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStandardOperatingProcedureHistoryQuery, GetStandardOperatingProcedureHistoryQueryVariables>(GetStandardOperatingProcedureHistoryDocument, options);
        }
export type GetStandardOperatingProcedureHistoryQueryHookResult = ReturnType<typeof useGetStandardOperatingProcedureHistoryQuery>;
export type GetStandardOperatingProcedureHistoryLazyQueryHookResult = ReturnType<typeof useGetStandardOperatingProcedureHistoryLazyQuery>;
export type GetStandardOperatingProcedureHistoryQueryResult = Apollo.QueryResult<GetStandardOperatingProcedureHistoryQuery, GetStandardOperatingProcedureHistoryQueryVariables>;
export function refetchGetStandardOperatingProcedureHistoryQuery(variables?: GetStandardOperatingProcedureHistoryQueryVariables) {
      return { query: GetStandardOperatingProcedureHistoryDocument, variables: variables }
    }
export const GetStrategicPartnersDocument = gql`
    query GetStrategicPartners($after: String, $before: String, $first: Int, $last: Int, $order: [StrategicPartnerSortInput!], $where: StrategicPartnerFilterInput) {
  strategicPartners(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        isEnabled
        name
        addressLine1
        addressLine2
        city
        postcode
        email
        country {
          id
          name
        }
        telephoneNumber
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetStrategicPartnersQuery__
 *
 * To run a query within a React component, call `useGetStrategicPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrategicPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrategicPartnersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetStrategicPartnersQuery(baseOptions?: Apollo.QueryHookOptions<GetStrategicPartnersQuery, GetStrategicPartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStrategicPartnersQuery, GetStrategicPartnersQueryVariables>(GetStrategicPartnersDocument, options);
      }
export function useGetStrategicPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStrategicPartnersQuery, GetStrategicPartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStrategicPartnersQuery, GetStrategicPartnersQueryVariables>(GetStrategicPartnersDocument, options);
        }
export type GetStrategicPartnersQueryHookResult = ReturnType<typeof useGetStrategicPartnersQuery>;
export type GetStrategicPartnersLazyQueryHookResult = ReturnType<typeof useGetStrategicPartnersLazyQuery>;
export type GetStrategicPartnersQueryResult = Apollo.QueryResult<GetStrategicPartnersQuery, GetStrategicPartnersQueryVariables>;
export function refetchGetStrategicPartnersQuery(variables?: GetStrategicPartnersQueryVariables) {
      return { query: GetStrategicPartnersDocument, variables: variables }
    }
export const GetStrategicPartnerDocument = gql`
    query GetStrategicPartner($strategicPartnerId: Long!) {
  strategicPartner(strategicPartnerId: $strategicPartnerId) {
    ...StrategicPartnerDetails
  }
}
    ${StrategicPartnerDetailsFragmentDoc}`;

/**
 * __useGetStrategicPartnerQuery__
 *
 * To run a query within a React component, call `useGetStrategicPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrategicPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrategicPartnerQuery({
 *   variables: {
 *      strategicPartnerId: // value for 'strategicPartnerId'
 *   },
 * });
 */
export function useGetStrategicPartnerQuery(baseOptions: Apollo.QueryHookOptions<GetStrategicPartnerQuery, GetStrategicPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStrategicPartnerQuery, GetStrategicPartnerQueryVariables>(GetStrategicPartnerDocument, options);
      }
export function useGetStrategicPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStrategicPartnerQuery, GetStrategicPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStrategicPartnerQuery, GetStrategicPartnerQueryVariables>(GetStrategicPartnerDocument, options);
        }
export type GetStrategicPartnerQueryHookResult = ReturnType<typeof useGetStrategicPartnerQuery>;
export type GetStrategicPartnerLazyQueryHookResult = ReturnType<typeof useGetStrategicPartnerLazyQuery>;
export type GetStrategicPartnerQueryResult = Apollo.QueryResult<GetStrategicPartnerQuery, GetStrategicPartnerQueryVariables>;
export function refetchGetStrategicPartnerQuery(variables?: GetStrategicPartnerQueryVariables) {
      return { query: GetStrategicPartnerDocument, variables: variables }
    }
export const GetStrategicPartnerContactsDocument = gql`
    query GetStrategicPartnerContacts($strategicPartnerId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [StrategicPartnerContactSortInput!], $where: StrategicPartnerContactFilterInput) {
  strategicPartnerContacts(
    strategicPartnerId: $strategicPartnerId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        department
        telephoneNumber
        email
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetStrategicPartnerContactsQuery__
 *
 * To run a query within a React component, call `useGetStrategicPartnerContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrategicPartnerContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrategicPartnerContactsQuery({
 *   variables: {
 *      strategicPartnerId: // value for 'strategicPartnerId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetStrategicPartnerContactsQuery(baseOptions: Apollo.QueryHookOptions<GetStrategicPartnerContactsQuery, GetStrategicPartnerContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStrategicPartnerContactsQuery, GetStrategicPartnerContactsQueryVariables>(GetStrategicPartnerContactsDocument, options);
      }
export function useGetStrategicPartnerContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStrategicPartnerContactsQuery, GetStrategicPartnerContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStrategicPartnerContactsQuery, GetStrategicPartnerContactsQueryVariables>(GetStrategicPartnerContactsDocument, options);
        }
export type GetStrategicPartnerContactsQueryHookResult = ReturnType<typeof useGetStrategicPartnerContactsQuery>;
export type GetStrategicPartnerContactsLazyQueryHookResult = ReturnType<typeof useGetStrategicPartnerContactsLazyQuery>;
export type GetStrategicPartnerContactsQueryResult = Apollo.QueryResult<GetStrategicPartnerContactsQuery, GetStrategicPartnerContactsQueryVariables>;
export function refetchGetStrategicPartnerContactsQuery(variables?: GetStrategicPartnerContactsQueryVariables) {
      return { query: GetStrategicPartnerContactsDocument, variables: variables }
    }
export const GetStrategicPartnerContactDocument = gql`
    query GetStrategicPartnerContact($strategicPartnerContactId: Long!) {
  strategicPartnerContact(strategicPartnerContactId: $strategicPartnerContactId) {
    ...StrategicPartnerContactDetails
  }
}
    ${StrategicPartnerContactDetailsFragmentDoc}`;

/**
 * __useGetStrategicPartnerContactQuery__
 *
 * To run a query within a React component, call `useGetStrategicPartnerContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStrategicPartnerContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStrategicPartnerContactQuery({
 *   variables: {
 *      strategicPartnerContactId: // value for 'strategicPartnerContactId'
 *   },
 * });
 */
export function useGetStrategicPartnerContactQuery(baseOptions: Apollo.QueryHookOptions<GetStrategicPartnerContactQuery, GetStrategicPartnerContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStrategicPartnerContactQuery, GetStrategicPartnerContactQueryVariables>(GetStrategicPartnerContactDocument, options);
      }
export function useGetStrategicPartnerContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStrategicPartnerContactQuery, GetStrategicPartnerContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStrategicPartnerContactQuery, GetStrategicPartnerContactQueryVariables>(GetStrategicPartnerContactDocument, options);
        }
export type GetStrategicPartnerContactQueryHookResult = ReturnType<typeof useGetStrategicPartnerContactQuery>;
export type GetStrategicPartnerContactLazyQueryHookResult = ReturnType<typeof useGetStrategicPartnerContactLazyQuery>;
export type GetStrategicPartnerContactQueryResult = Apollo.QueryResult<GetStrategicPartnerContactQuery, GetStrategicPartnerContactQueryVariables>;
export function refetchGetStrategicPartnerContactQuery(variables?: GetStrategicPartnerContactQueryVariables) {
      return { query: GetStrategicPartnerContactDocument, variables: variables }
    }
export const GetPagedTariffsDocument = gql`
    query GetPagedTariffs($customerId: Long, $after: String, $before: String, $first: Int, $last: Int, $order: [TariffPagedDtoSortInput!], $where: TariffPagedDtoFilterInput) {
  pagedTariffs(
    customerId: $customerId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        type
        category
        baseUnit
        minMiles
        rate
        minCharge
        customer
        isExternal
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedTariffsQuery__
 *
 * To run a query within a React component, call `useGetPagedTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedTariffsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedTariffsQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedTariffsQuery, GetPagedTariffsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedTariffsQuery, GetPagedTariffsQueryVariables>(GetPagedTariffsDocument, options);
      }
export function useGetPagedTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedTariffsQuery, GetPagedTariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedTariffsQuery, GetPagedTariffsQueryVariables>(GetPagedTariffsDocument, options);
        }
export type GetPagedTariffsQueryHookResult = ReturnType<typeof useGetPagedTariffsQuery>;
export type GetPagedTariffsLazyQueryHookResult = ReturnType<typeof useGetPagedTariffsLazyQuery>;
export type GetPagedTariffsQueryResult = Apollo.QueryResult<GetPagedTariffsQuery, GetPagedTariffsQueryVariables>;
export function refetchGetPagedTariffsQuery(variables?: GetPagedTariffsQueryVariables) {
      return { query: GetPagedTariffsDocument, variables: variables }
    }
export const GetUserPagedTariffsDocument = gql`
    query GetUserPagedTariffs($after: String, $before: String, $first: Int, $last: Int, $order: [TariffPagedDtoSortInput!], $where: TariffPagedDtoFilterInput) {
  userPagedTariffs(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        category
        type
        tariffIcon {
          id
          name
          vehicleCapacity
          file {
            id
            absoluteUri
          }
        }
        visibilitySequenceNumber
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUserPagedTariffsQuery__
 *
 * To run a query within a React component, call `useGetUserPagedTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPagedTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPagedTariffsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserPagedTariffsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPagedTariffsQuery, GetUserPagedTariffsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPagedTariffsQuery, GetUserPagedTariffsQueryVariables>(GetUserPagedTariffsDocument, options);
      }
export function useGetUserPagedTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPagedTariffsQuery, GetUserPagedTariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPagedTariffsQuery, GetUserPagedTariffsQueryVariables>(GetUserPagedTariffsDocument, options);
        }
export type GetUserPagedTariffsQueryHookResult = ReturnType<typeof useGetUserPagedTariffsQuery>;
export type GetUserPagedTariffsLazyQueryHookResult = ReturnType<typeof useGetUserPagedTariffsLazyQuery>;
export type GetUserPagedTariffsQueryResult = Apollo.QueryResult<GetUserPagedTariffsQuery, GetUserPagedTariffsQueryVariables>;
export function refetchGetUserPagedTariffsQuery(variables?: GetUserPagedTariffsQueryVariables) {
      return { query: GetUserPagedTariffsDocument, variables: variables }
    }
export const GetTariffsDocument = gql`
    query GetTariffs {
  tariffs {
    id
    name
    isEnabled
  }
}
    `;

/**
 * __useGetTariffsQuery__
 *
 * To run a query within a React component, call `useGetTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTariffsQuery(baseOptions?: Apollo.QueryHookOptions<GetTariffsQuery, GetTariffsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffsQuery, GetTariffsQueryVariables>(GetTariffsDocument, options);
      }
export function useGetTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffsQuery, GetTariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffsQuery, GetTariffsQueryVariables>(GetTariffsDocument, options);
        }
export type GetTariffsQueryHookResult = ReturnType<typeof useGetTariffsQuery>;
export type GetTariffsLazyQueryHookResult = ReturnType<typeof useGetTariffsLazyQuery>;
export type GetTariffsQueryResult = Apollo.QueryResult<GetTariffsQuery, GetTariffsQueryVariables>;
export function refetchGetTariffsQuery(variables?: GetTariffsQueryVariables) {
      return { query: GetTariffsDocument, variables: variables }
    }
export const GetTariffsForCustomerDocument = gql`
    query GetTariffsForCustomer($customerId: Long!) {
  tariffsForCustomer(customerId: $customerId) {
    id
    tariffCategory {
      id
      courierExchangeVehicleSize
    }
  }
}
    `;

/**
 * __useGetTariffsForCustomerQuery__
 *
 * To run a query within a React component, call `useGetTariffsForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffsForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffsForCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetTariffsForCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetTariffsForCustomerQuery, GetTariffsForCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffsForCustomerQuery, GetTariffsForCustomerQueryVariables>(GetTariffsForCustomerDocument, options);
      }
export function useGetTariffsForCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffsForCustomerQuery, GetTariffsForCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffsForCustomerQuery, GetTariffsForCustomerQueryVariables>(GetTariffsForCustomerDocument, options);
        }
export type GetTariffsForCustomerQueryHookResult = ReturnType<typeof useGetTariffsForCustomerQuery>;
export type GetTariffsForCustomerLazyQueryHookResult = ReturnType<typeof useGetTariffsForCustomerLazyQuery>;
export type GetTariffsForCustomerQueryResult = Apollo.QueryResult<GetTariffsForCustomerQuery, GetTariffsForCustomerQueryVariables>;
export function refetchGetTariffsForCustomerQuery(variables?: GetTariffsForCustomerQueryVariables) {
      return { query: GetTariffsForCustomerDocument, variables: variables }
    }
export const GetTariffDocument = gql`
    query GetTariff($id: Long!) {
  tariffById(id: $id) {
    ...TariffDetails
    xeroCode {
      id
      serviceType
    }
  }
}
    ${TariffDetailsFragmentDoc}`;

/**
 * __useGetTariffQuery__
 *
 * To run a query within a React component, call `useGetTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTariffQuery(baseOptions: Apollo.QueryHookOptions<GetTariffQuery, GetTariffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffQuery, GetTariffQueryVariables>(GetTariffDocument, options);
      }
export function useGetTariffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffQuery, GetTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffQuery, GetTariffQueryVariables>(GetTariffDocument, options);
        }
export type GetTariffQueryHookResult = ReturnType<typeof useGetTariffQuery>;
export type GetTariffLazyQueryHookResult = ReturnType<typeof useGetTariffLazyQuery>;
export type GetTariffQueryResult = Apollo.QueryResult<GetTariffQuery, GetTariffQueryVariables>;
export function refetchGetTariffQuery(variables?: GetTariffQueryVariables) {
      return { query: GetTariffDocument, variables: variables }
    }
export const GetBookingTariffDocument = gql`
    query GetBookingTariff($id: Long!) {
  tariffById(id: $id) {
    id
    name
    tariffCategory {
      id
      name
    }
    type
    isWaitAndReturnEnabled
    minCharge
    minMiles
    rate
    baseUnit
    iconUrl
    isExternal
    xeroCode {
      id
      serviceType
    }
    isDropChargeEnabled
    isUseDefaultDropCharge
    dropChargeRate
  }
}
    `;

/**
 * __useGetBookingTariffQuery__
 *
 * To run a query within a React component, call `useGetBookingTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingTariffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingTariffQuery(baseOptions: Apollo.QueryHookOptions<GetBookingTariffQuery, GetBookingTariffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingTariffQuery, GetBookingTariffQueryVariables>(GetBookingTariffDocument, options);
      }
export function useGetBookingTariffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingTariffQuery, GetBookingTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingTariffQuery, GetBookingTariffQueryVariables>(GetBookingTariffDocument, options);
        }
export type GetBookingTariffQueryHookResult = ReturnType<typeof useGetBookingTariffQuery>;
export type GetBookingTariffLazyQueryHookResult = ReturnType<typeof useGetBookingTariffLazyQuery>;
export type GetBookingTariffQueryResult = Apollo.QueryResult<GetBookingTariffQuery, GetBookingTariffQueryVariables>;
export function refetchGetBookingTariffQuery(variables?: GetBookingTariffQueryVariables) {
      return { query: GetBookingTariffDocument, variables: variables }
    }
export const GetUserTariffDetailsDocument = gql`
    query GetUserTariffDetails($id: Long!) {
  tariffById(id: $id) {
    id
    name
    tariffCategory {
      id
      name
    }
    type
    isWaitAndReturnEnabled
    tariffIcon {
      id
      vehicleCapacity
      file {
        id
        name
        absoluteUri
      }
    }
    isExternal
    xeroCode {
      id
      serviceType
    }
  }
}
    `;

/**
 * __useGetUserTariffDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserTariffDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTariffDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTariffDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserTariffDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserTariffDetailsQuery, GetUserTariffDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTariffDetailsQuery, GetUserTariffDetailsQueryVariables>(GetUserTariffDetailsDocument, options);
      }
export function useGetUserTariffDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTariffDetailsQuery, GetUserTariffDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTariffDetailsQuery, GetUserTariffDetailsQueryVariables>(GetUserTariffDetailsDocument, options);
        }
export type GetUserTariffDetailsQueryHookResult = ReturnType<typeof useGetUserTariffDetailsQuery>;
export type GetUserTariffDetailsLazyQueryHookResult = ReturnType<typeof useGetUserTariffDetailsLazyQuery>;
export type GetUserTariffDetailsQueryResult = Apollo.QueryResult<GetUserTariffDetailsQuery, GetUserTariffDetailsQueryVariables>;
export function refetchGetUserTariffDetailsQuery(variables?: GetUserTariffDetailsQueryVariables) {
      return { query: GetUserTariffDetailsDocument, variables: variables }
    }
export const GetPagedTariffCategoriesDocument = gql`
    query GetPagedTariffCategories($after: String, $before: String, $first: Int, $last: Int, $order: [TariffCategorySortInput!], $where: TariffCategoryFilterInput) {
  pagedTariffCategories(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        courierExchangeVehicleSize
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedTariffCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPagedTariffCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedTariffCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedTariffCategoriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedTariffCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedTariffCategoriesQuery, GetPagedTariffCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedTariffCategoriesQuery, GetPagedTariffCategoriesQueryVariables>(GetPagedTariffCategoriesDocument, options);
      }
export function useGetPagedTariffCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedTariffCategoriesQuery, GetPagedTariffCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedTariffCategoriesQuery, GetPagedTariffCategoriesQueryVariables>(GetPagedTariffCategoriesDocument, options);
        }
export type GetPagedTariffCategoriesQueryHookResult = ReturnType<typeof useGetPagedTariffCategoriesQuery>;
export type GetPagedTariffCategoriesLazyQueryHookResult = ReturnType<typeof useGetPagedTariffCategoriesLazyQuery>;
export type GetPagedTariffCategoriesQueryResult = Apollo.QueryResult<GetPagedTariffCategoriesQuery, GetPagedTariffCategoriesQueryVariables>;
export function refetchGetPagedTariffCategoriesQuery(variables?: GetPagedTariffCategoriesQueryVariables) {
      return { query: GetPagedTariffCategoriesDocument, variables: variables }
    }
export const GetTariffCategoriesDocument = gql`
    query GetTariffCategories {
  tariffCategories {
    id
    name
    createdAt
    createdBy
  }
}
    `;

/**
 * __useGetTariffCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTariffCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTariffCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetTariffCategoriesQuery, GetTariffCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffCategoriesQuery, GetTariffCategoriesQueryVariables>(GetTariffCategoriesDocument, options);
      }
export function useGetTariffCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffCategoriesQuery, GetTariffCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffCategoriesQuery, GetTariffCategoriesQueryVariables>(GetTariffCategoriesDocument, options);
        }
export type GetTariffCategoriesQueryHookResult = ReturnType<typeof useGetTariffCategoriesQuery>;
export type GetTariffCategoriesLazyQueryHookResult = ReturnType<typeof useGetTariffCategoriesLazyQuery>;
export type GetTariffCategoriesQueryResult = Apollo.QueryResult<GetTariffCategoriesQuery, GetTariffCategoriesQueryVariables>;
export function refetchGetTariffCategoriesQuery(variables?: GetTariffCategoriesQueryVariables) {
      return { query: GetTariffCategoriesDocument, variables: variables }
    }
export const GetTariffCategoryDocument = gql`
    query GetTariffCategory($id: Long!) {
  tariffCategory(id: $id) {
    id
    name
    courierExchangeVehicleSize
    hasSpotRate
  }
}
    `;

/**
 * __useGetTariffCategoryQuery__
 *
 * To run a query within a React component, call `useGetTariffCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTariffCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetTariffCategoryQuery, GetTariffCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffCategoryQuery, GetTariffCategoryQueryVariables>(GetTariffCategoryDocument, options);
      }
export function useGetTariffCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffCategoryQuery, GetTariffCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffCategoryQuery, GetTariffCategoryQueryVariables>(GetTariffCategoryDocument, options);
        }
export type GetTariffCategoryQueryHookResult = ReturnType<typeof useGetTariffCategoryQuery>;
export type GetTariffCategoryLazyQueryHookResult = ReturnType<typeof useGetTariffCategoryLazyQuery>;
export type GetTariffCategoryQueryResult = Apollo.QueryResult<GetTariffCategoryQuery, GetTariffCategoryQueryVariables>;
export function refetchGetTariffCategoryQuery(variables?: GetTariffCategoryQueryVariables) {
      return { query: GetTariffCategoryDocument, variables: variables }
    }
export const GetTariffCategoryNameDocument = gql`
    query GetTariffCategoryName($id: Long!) {
  tariffCategory(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetTariffCategoryNameQuery__
 *
 * To run a query within a React component, call `useGetTariffCategoryNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffCategoryNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffCategoryNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTariffCategoryNameQuery(baseOptions: Apollo.QueryHookOptions<GetTariffCategoryNameQuery, GetTariffCategoryNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffCategoryNameQuery, GetTariffCategoryNameQueryVariables>(GetTariffCategoryNameDocument, options);
      }
export function useGetTariffCategoryNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffCategoryNameQuery, GetTariffCategoryNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffCategoryNameQuery, GetTariffCategoryNameQueryVariables>(GetTariffCategoryNameDocument, options);
        }
export type GetTariffCategoryNameQueryHookResult = ReturnType<typeof useGetTariffCategoryNameQuery>;
export type GetTariffCategoryNameLazyQueryHookResult = ReturnType<typeof useGetTariffCategoryNameLazyQuery>;
export type GetTariffCategoryNameQueryResult = Apollo.QueryResult<GetTariffCategoryNameQuery, GetTariffCategoryNameQueryVariables>;
export function refetchGetTariffCategoryNameQuery(variables?: GetTariffCategoryNameQueryVariables) {
      return { query: GetTariffCategoryNameDocument, variables: variables }
    }
export const GetExternalTariffsDocument = gql`
    query GetExternalTariffs($where: TariffPagedDtoFilterInput, $order: [TariffPagedDtoSortInput!]) {
  pagedTariffs(where: $where, order: $order) {
    edges {
      node {
        id
        visibilitySequenceNumber
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetExternalTariffsQuery__
 *
 * To run a query within a React component, call `useGetExternalTariffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalTariffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalTariffsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetExternalTariffsQuery(baseOptions?: Apollo.QueryHookOptions<GetExternalTariffsQuery, GetExternalTariffsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalTariffsQuery, GetExternalTariffsQueryVariables>(GetExternalTariffsDocument, options);
      }
export function useGetExternalTariffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalTariffsQuery, GetExternalTariffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalTariffsQuery, GetExternalTariffsQueryVariables>(GetExternalTariffsDocument, options);
        }
export type GetExternalTariffsQueryHookResult = ReturnType<typeof useGetExternalTariffsQuery>;
export type GetExternalTariffsLazyQueryHookResult = ReturnType<typeof useGetExternalTariffsLazyQuery>;
export type GetExternalTariffsQueryResult = Apollo.QueryResult<GetExternalTariffsQuery, GetExternalTariffsQueryVariables>;
export function refetchGetExternalTariffsQuery(variables?: GetExternalTariffsQueryVariables) {
      return { query: GetExternalTariffsDocument, variables: variables }
    }
export const GetPagedTariffIconsDocument = gql`
    query GetPagedTariffIcons($after: String, $before: String, $first: Int, $last: Int, $where: TariffIconFilterInput, $order: [TariffIconSortInput!]) {
  pagedTariffIcons(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        vehicleCapacity
        createdAt
        createdBy
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedTariffIconsQuery__
 *
 * To run a query within a React component, call `useGetPagedTariffIconsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedTariffIconsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedTariffIconsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPagedTariffIconsQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedTariffIconsQuery, GetPagedTariffIconsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedTariffIconsQuery, GetPagedTariffIconsQueryVariables>(GetPagedTariffIconsDocument, options);
      }
export function useGetPagedTariffIconsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedTariffIconsQuery, GetPagedTariffIconsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedTariffIconsQuery, GetPagedTariffIconsQueryVariables>(GetPagedTariffIconsDocument, options);
        }
export type GetPagedTariffIconsQueryHookResult = ReturnType<typeof useGetPagedTariffIconsQuery>;
export type GetPagedTariffIconsLazyQueryHookResult = ReturnType<typeof useGetPagedTariffIconsLazyQuery>;
export type GetPagedTariffIconsQueryResult = Apollo.QueryResult<GetPagedTariffIconsQuery, GetPagedTariffIconsQueryVariables>;
export function refetchGetPagedTariffIconsQuery(variables?: GetPagedTariffIconsQueryVariables) {
      return { query: GetPagedTariffIconsDocument, variables: variables }
    }
export const GetTariffIconsDocument = gql`
    query GetTariffIcons {
  tariffIcons {
    id
    name
    vehicleCapacity
    file {
      id
      ...FileDetails
    }
  }
}
    ${FileDetailsFragmentDoc}`;

/**
 * __useGetTariffIconsQuery__
 *
 * To run a query within a React component, call `useGetTariffIconsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffIconsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffIconsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTariffIconsQuery(baseOptions?: Apollo.QueryHookOptions<GetTariffIconsQuery, GetTariffIconsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffIconsQuery, GetTariffIconsQueryVariables>(GetTariffIconsDocument, options);
      }
export function useGetTariffIconsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffIconsQuery, GetTariffIconsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffIconsQuery, GetTariffIconsQueryVariables>(GetTariffIconsDocument, options);
        }
export type GetTariffIconsQueryHookResult = ReturnType<typeof useGetTariffIconsQuery>;
export type GetTariffIconsLazyQueryHookResult = ReturnType<typeof useGetTariffIconsLazyQuery>;
export type GetTariffIconsQueryResult = Apollo.QueryResult<GetTariffIconsQuery, GetTariffIconsQueryVariables>;
export function refetchGetTariffIconsQuery(variables?: GetTariffIconsQueryVariables) {
      return { query: GetTariffIconsDocument, variables: variables }
    }
export const GetTariffIconDocument = gql`
    query GetTariffIcon($id: Long!) {
  tariffIcon(id: $id) {
    id
    name
    vehicleCapacity
    fileId
    file {
      id
      ...FileDetails
    }
  }
}
    ${FileDetailsFragmentDoc}`;

/**
 * __useGetTariffIconQuery__
 *
 * To run a query within a React component, call `useGetTariffIconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffIconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffIconQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTariffIconQuery(baseOptions: Apollo.QueryHookOptions<GetTariffIconQuery, GetTariffIconQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffIconQuery, GetTariffIconQueryVariables>(GetTariffIconDocument, options);
      }
export function useGetTariffIconLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffIconQuery, GetTariffIconQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffIconQuery, GetTariffIconQueryVariables>(GetTariffIconDocument, options);
        }
export type GetTariffIconQueryHookResult = ReturnType<typeof useGetTariffIconQuery>;
export type GetTariffIconLazyQueryHookResult = ReturnType<typeof useGetTariffIconLazyQuery>;
export type GetTariffIconQueryResult = Apollo.QueryResult<GetTariffIconQuery, GetTariffIconQueryVariables>;
export function refetchGetTariffIconQuery(variables?: GetTariffIconQueryVariables) {
      return { query: GetTariffIconDocument, variables: variables }
    }
export const GetTasksDocument = gql`
    query getTasks($after: String, $before: String, $first: Int, $last: Int, $order: [TaskSortInput!], $where: TaskFilterInput) {
  tasks(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        bookingId
        booking {
          id
          ourReference
          status
        }
        description
        status
        endDate
        time
        timeTotalSeconds
        categoryId
        category {
          id
          name
          colour
        }
        assignedUser
        assignedUserName
        additionalDetail
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTasksQuery(baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
      }
export function useGetTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
        }
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
export function refetchGetTasksQuery(variables?: GetTasksQueryVariables) {
      return { query: GetTasksDocument, variables: variables }
    }
export const GetTaskDocument = gql`
    query getTask($id: Long!) {
  task(id: $id) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions: Apollo.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
      }
export function useGetTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, options);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = Apollo.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export function refetchGetTaskQuery(variables?: GetTaskQueryVariables) {
      return { query: GetTaskDocument, variables: variables }
    }
export const GetTasksCountDocument = gql`
    query getTasksCount($where: TaskFilterInput) {
  tasks(where: $where) {
    totalCount
  }
}
    `;

/**
 * __useGetTasksCountQuery__
 *
 * To run a query within a React component, call `useGetTasksCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTasksCountQuery(baseOptions?: Apollo.QueryHookOptions<GetTasksCountQuery, GetTasksCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTasksCountQuery, GetTasksCountQueryVariables>(GetTasksCountDocument, options);
      }
export function useGetTasksCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTasksCountQuery, GetTasksCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTasksCountQuery, GetTasksCountQueryVariables>(GetTasksCountDocument, options);
        }
export type GetTasksCountQueryHookResult = ReturnType<typeof useGetTasksCountQuery>;
export type GetTasksCountLazyQueryHookResult = ReturnType<typeof useGetTasksCountLazyQuery>;
export type GetTasksCountQueryResult = Apollo.QueryResult<GetTasksCountQuery, GetTasksCountQueryVariables>;
export function refetchGetTasksCountQuery(variables?: GetTasksCountQueryVariables) {
      return { query: GetTasksCountDocument, variables: variables }
    }
export const GetTaskStatusesDocument = gql`
    query getTaskStatuses {
  taskStatuses {
    id
    status
    description
  }
}
    `;

/**
 * __useGetTaskStatusesQuery__
 *
 * To run a query within a React component, call `useGetTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaskStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskStatusesQuery, GetTaskStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskStatusesQuery, GetTaskStatusesQueryVariables>(GetTaskStatusesDocument, options);
      }
export function useGetTaskStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskStatusesQuery, GetTaskStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskStatusesQuery, GetTaskStatusesQueryVariables>(GetTaskStatusesDocument, options);
        }
export type GetTaskStatusesQueryHookResult = ReturnType<typeof useGetTaskStatusesQuery>;
export type GetTaskStatusesLazyQueryHookResult = ReturnType<typeof useGetTaskStatusesLazyQuery>;
export type GetTaskStatusesQueryResult = Apollo.QueryResult<GetTaskStatusesQuery, GetTaskStatusesQueryVariables>;
export function refetchGetTaskStatusesQuery(variables?: GetTaskStatusesQueryVariables) {
      return { query: GetTaskStatusesDocument, variables: variables }
    }
export const GetTaskUsersDocument = gql`
    query getTaskUsers($where: TaskUserDtoFilterInput) {
  taskUsers(where: $where) {
    id
    userName
    lastName
    firstName
    isEnabled
    emailConfirmed
  }
}
    `;

/**
 * __useGetTaskUsersQuery__
 *
 * To run a query within a React component, call `useGetTaskUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTaskUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskUsersQuery, GetTaskUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskUsersQuery, GetTaskUsersQueryVariables>(GetTaskUsersDocument, options);
      }
export function useGetTaskUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskUsersQuery, GetTaskUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskUsersQuery, GetTaskUsersQueryVariables>(GetTaskUsersDocument, options);
        }
export type GetTaskUsersQueryHookResult = ReturnType<typeof useGetTaskUsersQuery>;
export type GetTaskUsersLazyQueryHookResult = ReturnType<typeof useGetTaskUsersLazyQuery>;
export type GetTaskUsersQueryResult = Apollo.QueryResult<GetTaskUsersQuery, GetTaskUsersQueryVariables>;
export function refetchGetTaskUsersQuery(variables?: GetTaskUsersQueryVariables) {
      return { query: GetTaskUsersDocument, variables: variables }
    }
export const GetTaskCategoriesDocument = gql`
    query getTaskCategories {
  taskCategories {
    id
    name
    colour
  }
}
    `;

/**
 * __useGetTaskCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTaskCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTaskCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskCategoriesQuery, GetTaskCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskCategoriesQuery, GetTaskCategoriesQueryVariables>(GetTaskCategoriesDocument, options);
      }
export function useGetTaskCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskCategoriesQuery, GetTaskCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskCategoriesQuery, GetTaskCategoriesQueryVariables>(GetTaskCategoriesDocument, options);
        }
export type GetTaskCategoriesQueryHookResult = ReturnType<typeof useGetTaskCategoriesQuery>;
export type GetTaskCategoriesLazyQueryHookResult = ReturnType<typeof useGetTaskCategoriesLazyQuery>;
export type GetTaskCategoriesQueryResult = Apollo.QueryResult<GetTaskCategoriesQuery, GetTaskCategoriesQueryVariables>;
export function refetchGetTaskCategoriesQuery(variables?: GetTaskCategoriesQueryVariables) {
      return { query: GetTaskCategoriesDocument, variables: variables }
    }
export const GetTaskBookingsDocument = gql`
    query getTaskBookings($where: BookingFilterInput) {
  taskBookings(where: $where) {
    id
    ourReference
  }
}
    `;

/**
 * __useGetTaskBookingsQuery__
 *
 * To run a query within a React component, call `useGetTaskBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskBookingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTaskBookingsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskBookingsQuery, GetTaskBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskBookingsQuery, GetTaskBookingsQueryVariables>(GetTaskBookingsDocument, options);
      }
export function useGetTaskBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskBookingsQuery, GetTaskBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskBookingsQuery, GetTaskBookingsQueryVariables>(GetTaskBookingsDocument, options);
        }
export type GetTaskBookingsQueryHookResult = ReturnType<typeof useGetTaskBookingsQuery>;
export type GetTaskBookingsLazyQueryHookResult = ReturnType<typeof useGetTaskBookingsLazyQuery>;
export type GetTaskBookingsQueryResult = Apollo.QueryResult<GetTaskBookingsQuery, GetTaskBookingsQueryVariables>;
export function refetchGetTaskBookingsQuery(variables?: GetTaskBookingsQueryVariables) {
      return { query: GetTaskBookingsDocument, variables: variables }
    }
export const GetTaskSettingsDocument = gql`
    query GetTaskSettings($where: SystemTaskSettingFilterInput) {
  taskSettings(where: $where) {
    id
    ...TaskSettingDetails
  }
}
    ${TaskSettingDetailsFragmentDoc}`;

/**
 * __useGetTaskSettingsQuery__
 *
 * To run a query within a React component, call `useGetTaskSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskSettingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTaskSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskSettingsQuery, GetTaskSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskSettingsQuery, GetTaskSettingsQueryVariables>(GetTaskSettingsDocument, options);
      }
export function useGetTaskSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskSettingsQuery, GetTaskSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskSettingsQuery, GetTaskSettingsQueryVariables>(GetTaskSettingsDocument, options);
        }
export type GetTaskSettingsQueryHookResult = ReturnType<typeof useGetTaskSettingsQuery>;
export type GetTaskSettingsLazyQueryHookResult = ReturnType<typeof useGetTaskSettingsLazyQuery>;
export type GetTaskSettingsQueryResult = Apollo.QueryResult<GetTaskSettingsQuery, GetTaskSettingsQueryVariables>;
export function refetchGetTaskSettingsQuery(variables?: GetTaskSettingsQueryVariables) {
      return { query: GetTaskSettingsDocument, variables: variables }
    }
export const GetUsersDocument = gql`
    query GetUsers($after: String, $before: String, $first: Int, $last: Int, $order: [IdentityUserDtoSortInput!], $where: IdentityUserDtoFilterInput) {
  users(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        userName
        firstName
        lastName
        role
        address
        email
        emailConfirmed
        isEnabled
        lastLoggedIn
        createdDate
        companyName
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export function refetchGetUsersQuery(variables?: GetUsersQueryVariables) {
      return { query: GetUsersDocument, variables: variables }
    }
export const GetUserDetailsDocument = gql`
    query getUserDetails($id: Long!) {
  userDetails(userId: $id) {
    id
    userName
    role
    firstName
    lastName
    countryId
    city
    email
    postcode
    dob
    phoneNumber
    driverId
    customerId
    companyDetails {
      companyName
      customerId
      contactId
    }
  }
}
    `;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export function refetchGetUserDetailsQuery(variables?: GetUserDetailsQueryVariables) {
      return { query: GetUserDetailsDocument, variables: variables }
    }
export const GetApproveDeclineUserDetailsDocument = gql`
    query getApproveDeclineUserDetails($id: Long!) {
  userDetails(userId: $id) {
    id
    email
    userName
    firstName
    lastName
    phoneNumber
    role
    emailConfirmed
    suggestedCompany
    driverId
  }
}
    `;

/**
 * __useGetApproveDeclineUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetApproveDeclineUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApproveDeclineUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApproveDeclineUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApproveDeclineUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetApproveDeclineUserDetailsQuery, GetApproveDeclineUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApproveDeclineUserDetailsQuery, GetApproveDeclineUserDetailsQueryVariables>(GetApproveDeclineUserDetailsDocument, options);
      }
export function useGetApproveDeclineUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApproveDeclineUserDetailsQuery, GetApproveDeclineUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApproveDeclineUserDetailsQuery, GetApproveDeclineUserDetailsQueryVariables>(GetApproveDeclineUserDetailsDocument, options);
        }
export type GetApproveDeclineUserDetailsQueryHookResult = ReturnType<typeof useGetApproveDeclineUserDetailsQuery>;
export type GetApproveDeclineUserDetailsLazyQueryHookResult = ReturnType<typeof useGetApproveDeclineUserDetailsLazyQuery>;
export type GetApproveDeclineUserDetailsQueryResult = Apollo.QueryResult<GetApproveDeclineUserDetailsQuery, GetApproveDeclineUserDetailsQueryVariables>;
export function refetchGetApproveDeclineUserDetailsQuery(variables?: GetApproveDeclineUserDetailsQueryVariables) {
      return { query: GetApproveDeclineUserDetailsDocument, variables: variables }
    }
export const GetProfileAvatarDocument = gql`
    query getProfileAvatar {
  myProfile {
    id
    firstName
    lastName
    avatarUri
    city
    country
  }
}
    `;

/**
 * __useGetProfileAvatarQuery__
 *
 * To run a query within a React component, call `useGetProfileAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileAvatarQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileAvatarQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileAvatarQuery, GetProfileAvatarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileAvatarQuery, GetProfileAvatarQueryVariables>(GetProfileAvatarDocument, options);
      }
export function useGetProfileAvatarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileAvatarQuery, GetProfileAvatarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileAvatarQuery, GetProfileAvatarQueryVariables>(GetProfileAvatarDocument, options);
        }
export type GetProfileAvatarQueryHookResult = ReturnType<typeof useGetProfileAvatarQuery>;
export type GetProfileAvatarLazyQueryHookResult = ReturnType<typeof useGetProfileAvatarLazyQuery>;
export type GetProfileAvatarQueryResult = Apollo.QueryResult<GetProfileAvatarQuery, GetProfileAvatarQueryVariables>;
export function refetchGetProfileAvatarQuery(variables?: GetProfileAvatarQueryVariables) {
      return { query: GetProfileAvatarDocument, variables: variables }
    }
export const GetMyProfileDocument = gql`
    query getMyProfile {
  myProfile {
    id
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
      }
export function useGetMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
        }
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>;
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>;
export type GetMyProfileQueryResult = Apollo.QueryResult<GetMyProfileQuery, GetMyProfileQueryVariables>;
export function refetchGetMyProfileQuery(variables?: GetMyProfileQueryVariables) {
      return { query: GetMyProfileDocument, variables: variables }
    }
export const GetVatCategoriesDocument = gql`
    query GetVatCategories {
  vatCategories {
    id
    name
  }
}
    `;

/**
 * __useGetVatCategoriesQuery__
 *
 * To run a query within a React component, call `useGetVatCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVatCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVatCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVatCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetVatCategoriesQuery, GetVatCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVatCategoriesQuery, GetVatCategoriesQueryVariables>(GetVatCategoriesDocument, options);
      }
export function useGetVatCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVatCategoriesQuery, GetVatCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVatCategoriesQuery, GetVatCategoriesQueryVariables>(GetVatCategoriesDocument, options);
        }
export type GetVatCategoriesQueryHookResult = ReturnType<typeof useGetVatCategoriesQuery>;
export type GetVatCategoriesLazyQueryHookResult = ReturnType<typeof useGetVatCategoriesLazyQuery>;
export type GetVatCategoriesQueryResult = Apollo.QueryResult<GetVatCategoriesQuery, GetVatCategoriesQueryVariables>;
export function refetchGetVatCategoriesQuery(variables?: GetVatCategoriesQueryVariables) {
      return { query: GetVatCategoriesDocument, variables: variables }
    }
export const GetPagedVehiclesDocument = gql`
    query GetPagedVehicles($driverId: Long!, $after: String, $before: String, $first: Int, $last: Int, $order: [VehicleSortInput!], $where: VehicleFilterInput) {
  vehicles(
    driverId: $driverId
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        colour
        registrationNumber
        manufacturer
        model
        dateOfManufacture
        vehicleType {
          name
        }
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedVehiclesQuery__
 *
 * To run a query within a React component, call `useGetPagedVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedVehiclesQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedVehiclesQuery(baseOptions: Apollo.QueryHookOptions<GetPagedVehiclesQuery, GetPagedVehiclesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedVehiclesQuery, GetPagedVehiclesQueryVariables>(GetPagedVehiclesDocument, options);
      }
export function useGetPagedVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedVehiclesQuery, GetPagedVehiclesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedVehiclesQuery, GetPagedVehiclesQueryVariables>(GetPagedVehiclesDocument, options);
        }
export type GetPagedVehiclesQueryHookResult = ReturnType<typeof useGetPagedVehiclesQuery>;
export type GetPagedVehiclesLazyQueryHookResult = ReturnType<typeof useGetPagedVehiclesLazyQuery>;
export type GetPagedVehiclesQueryResult = Apollo.QueryResult<GetPagedVehiclesQuery, GetPagedVehiclesQueryVariables>;
export function refetchGetPagedVehiclesQuery(variables?: GetPagedVehiclesQueryVariables) {
      return { query: GetPagedVehiclesDocument, variables: variables }
    }
export const GetVehicleDocument = gql`
    query GetVehicle($driverId: Long!, $vehicleId: Long!) {
  vehicle(driverId: $driverId, vehicleId: $vehicleId) {
    id
    isDefault
    driverId
    vehicleTypeId
    vehicleType {
      id
      name
    }
    manufacturer
    dateOfManufacture
    model
    colour
    insuranceCompany
    insuranceExpiryDate
    motTestExpiryDate
    isRenewalReminder
    registrationNumber
    policyOrCoverNote
    goodsInTransitExpiryDate
  }
}
    `;

/**
 * __useGetVehicleQuery__
 *
 * To run a query within a React component, call `useGetVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleQuery({
 *   variables: {
 *      driverId: // value for 'driverId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useGetVehicleQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleQuery, GetVehicleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleQuery, GetVehicleQueryVariables>(GetVehicleDocument, options);
      }
export function useGetVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleQuery, GetVehicleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleQuery, GetVehicleQueryVariables>(GetVehicleDocument, options);
        }
export type GetVehicleQueryHookResult = ReturnType<typeof useGetVehicleQuery>;
export type GetVehicleLazyQueryHookResult = ReturnType<typeof useGetVehicleLazyQuery>;
export type GetVehicleQueryResult = Apollo.QueryResult<GetVehicleQuery, GetVehicleQueryVariables>;
export function refetchGetVehicleQuery(variables?: GetVehicleQueryVariables) {
      return { query: GetVehicleDocument, variables: variables }
    }
export const GetVehicleTypesDocument = gql`
    query GetVehicleTypes {
  vehicleTypes {
    id
    name
    isEnabled
  }
}
    `;

/**
 * __useGetVehicleTypesQuery__
 *
 * To run a query within a React component, call `useGetVehicleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVehicleTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetVehicleTypesQuery, GetVehicleTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleTypesQuery, GetVehicleTypesQueryVariables>(GetVehicleTypesDocument, options);
      }
export function useGetVehicleTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleTypesQuery, GetVehicleTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleTypesQuery, GetVehicleTypesQueryVariables>(GetVehicleTypesDocument, options);
        }
export type GetVehicleTypesQueryHookResult = ReturnType<typeof useGetVehicleTypesQuery>;
export type GetVehicleTypesLazyQueryHookResult = ReturnType<typeof useGetVehicleTypesLazyQuery>;
export type GetVehicleTypesQueryResult = Apollo.QueryResult<GetVehicleTypesQuery, GetVehicleTypesQueryVariables>;
export function refetchGetVehicleTypesQuery(variables?: GetVehicleTypesQueryVariables) {
      return { query: GetVehicleTypesDocument, variables: variables }
    }
export const GetXeroServiceTypesDocument = gql`
    query GetXeroServiceTypes {
  xeroServiceTypes {
    id
    serviceType
    description
  }
}
    `;

/**
 * __useGetXeroServiceTypesQuery__
 *
 * To run a query within a React component, call `useGetXeroServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetXeroServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetXeroServiceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetXeroServiceTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetXeroServiceTypesQuery, GetXeroServiceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetXeroServiceTypesQuery, GetXeroServiceTypesQueryVariables>(GetXeroServiceTypesDocument, options);
      }
export function useGetXeroServiceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetXeroServiceTypesQuery, GetXeroServiceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetXeroServiceTypesQuery, GetXeroServiceTypesQueryVariables>(GetXeroServiceTypesDocument, options);
        }
export type GetXeroServiceTypesQueryHookResult = ReturnType<typeof useGetXeroServiceTypesQuery>;
export type GetXeroServiceTypesLazyQueryHookResult = ReturnType<typeof useGetXeroServiceTypesLazyQuery>;
export type GetXeroServiceTypesQueryResult = Apollo.QueryResult<GetXeroServiceTypesQuery, GetXeroServiceTypesQueryVariables>;
export function refetchGetXeroServiceTypesQuery(variables?: GetXeroServiceTypesQueryVariables) {
      return { query: GetXeroServiceTypesDocument, variables: variables }
    }
export const GetXeroCodesDocument = gql`
    query GetXeroCodes($where: XeroCodeFilterInput) {
  xeroCodes(where: $where) {
    id
    name
    code
  }
}
    `;

/**
 * __useGetXeroCodesQuery__
 *
 * To run a query within a React component, call `useGetXeroCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetXeroCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetXeroCodesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetXeroCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetXeroCodesQuery, GetXeroCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetXeroCodesQuery, GetXeroCodesQueryVariables>(GetXeroCodesDocument, options);
      }
export function useGetXeroCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetXeroCodesQuery, GetXeroCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetXeroCodesQuery, GetXeroCodesQueryVariables>(GetXeroCodesDocument, options);
        }
export type GetXeroCodesQueryHookResult = ReturnType<typeof useGetXeroCodesQuery>;
export type GetXeroCodesLazyQueryHookResult = ReturnType<typeof useGetXeroCodesLazyQuery>;
export type GetXeroCodesQueryResult = Apollo.QueryResult<GetXeroCodesQuery, GetXeroCodesQueryVariables>;
export function refetchGetXeroCodesQuery(variables?: GetXeroCodesQueryVariables) {
      return { query: GetXeroCodesDocument, variables: variables }
    }
export const GetXeroCodeDocument = gql`
    query GetXeroCode($id: Long!) {
  xeroCode(id: $id) {
    id
    name
    code
    description
    type
    serviceType
    isInternational
  }
}
    `;

/**
 * __useGetXeroCodeQuery__
 *
 * To run a query within a React component, call `useGetXeroCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetXeroCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetXeroCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetXeroCodeQuery(baseOptions: Apollo.QueryHookOptions<GetXeroCodeQuery, GetXeroCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetXeroCodeQuery, GetXeroCodeQueryVariables>(GetXeroCodeDocument, options);
      }
export function useGetXeroCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetXeroCodeQuery, GetXeroCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetXeroCodeQuery, GetXeroCodeQueryVariables>(GetXeroCodeDocument, options);
        }
export type GetXeroCodeQueryHookResult = ReturnType<typeof useGetXeroCodeQuery>;
export type GetXeroCodeLazyQueryHookResult = ReturnType<typeof useGetXeroCodeLazyQuery>;
export type GetXeroCodeQueryResult = Apollo.QueryResult<GetXeroCodeQuery, GetXeroCodeQueryVariables>;
export function refetchGetXeroCodeQuery(variables?: GetXeroCodeQueryVariables) {
      return { query: GetXeroCodeDocument, variables: variables }
    }
export const GetPagedXeroCodesDocument = gql`
    query GetPagedXeroCodes($after: String, $before: String, $first: Int, $last: Int, $order: [XeroCodeSortInput!], $where: XeroCodeFilterInput) {
  pagedXeroCodes(
    after: $after
    before: $before
    first: $first
    last: $last
    order: $order
    where: $where
  ) {
    edges {
      node {
        id
        name
        code
        description
        type
        createdAt
      }
    }
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetPagedXeroCodesQuery__
 *
 * To run a query within a React component, call `useGetPagedXeroCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedXeroCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedXeroCodesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPagedXeroCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagedXeroCodesQuery, GetPagedXeroCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedXeroCodesQuery, GetPagedXeroCodesQueryVariables>(GetPagedXeroCodesDocument, options);
      }
export function useGetPagedXeroCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedXeroCodesQuery, GetPagedXeroCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedXeroCodesQuery, GetPagedXeroCodesQueryVariables>(GetPagedXeroCodesDocument, options);
        }
export type GetPagedXeroCodesQueryHookResult = ReturnType<typeof useGetPagedXeroCodesQuery>;
export type GetPagedXeroCodesLazyQueryHookResult = ReturnType<typeof useGetPagedXeroCodesLazyQuery>;
export type GetPagedXeroCodesQueryResult = Apollo.QueryResult<GetPagedXeroCodesQuery, GetPagedXeroCodesQueryVariables>;
export function refetchGetPagedXeroCodesQuery(variables?: GetPagedXeroCodesQueryVariables) {
      return { query: GetPagedXeroCodesDocument, variables: variables }
    }
export const OnBookingUpdateDocument = gql`
    subscription onBookingUpdate($bookingId: Long!) {
  onBookingUpdated(bookingId: $bookingId) {
    id
    updatedBy
  }
}
    `;

/**
 * __useOnBookingUpdateSubscription__
 *
 * To run a query within a React component, call `useOnBookingUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnBookingUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnBookingUpdateSubscription({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useOnBookingUpdateSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnBookingUpdateSubscription, OnBookingUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnBookingUpdateSubscription, OnBookingUpdateSubscriptionVariables>(OnBookingUpdateDocument, options);
      }
export type OnBookingUpdateSubscriptionHookResult = ReturnType<typeof useOnBookingUpdateSubscription>;
export type OnBookingUpdateSubscriptionResult = Apollo.SubscriptionResult<OnBookingUpdateSubscription>;
export const OnCommentaryCreatedDocument = gql`
    subscription onCommentaryCreated {
  onCommentaryCreated {
    id
    ...CommentaryDetails
  }
}
    ${CommentaryDetailsFragmentDoc}`;

/**
 * __useOnCommentaryCreatedSubscription__
 *
 * To run a query within a React component, call `useOnCommentaryCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCommentaryCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCommentaryCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCommentaryCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCommentaryCreatedSubscription, OnCommentaryCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCommentaryCreatedSubscription, OnCommentaryCreatedSubscriptionVariables>(OnCommentaryCreatedDocument, options);
      }
export type OnCommentaryCreatedSubscriptionHookResult = ReturnType<typeof useOnCommentaryCreatedSubscription>;
export type OnCommentaryCreatedSubscriptionResult = Apollo.SubscriptionResult<OnCommentaryCreatedSubscription>;
export const OnCommentaryUpdatedDocument = gql`
    subscription onCommentaryUpdated {
  onCommentaryUpdated {
    id
    ...CommentaryDetails
  }
}
    ${CommentaryDetailsFragmentDoc}`;

/**
 * __useOnCommentaryUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnCommentaryUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCommentaryUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCommentaryUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCommentaryUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCommentaryUpdatedSubscription, OnCommentaryUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCommentaryUpdatedSubscription, OnCommentaryUpdatedSubscriptionVariables>(OnCommentaryUpdatedDocument, options);
      }
export type OnCommentaryUpdatedSubscriptionHookResult = ReturnType<typeof useOnCommentaryUpdatedSubscription>;
export type OnCommentaryUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnCommentaryUpdatedSubscription>;
export const OnCommentaryDeletedDocument = gql`
    subscription onCommentaryDeleted {
  onCommentaryDeleted
}
    `;

/**
 * __useOnCommentaryDeletedSubscription__
 *
 * To run a query within a React component, call `useOnCommentaryDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCommentaryDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCommentaryDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCommentaryDeletedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCommentaryDeletedSubscription, OnCommentaryDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCommentaryDeletedSubscription, OnCommentaryDeletedSubscriptionVariables>(OnCommentaryDeletedDocument, options);
      }
export type OnCommentaryDeletedSubscriptionHookResult = ReturnType<typeof useOnCommentaryDeletedSubscription>;
export type OnCommentaryDeletedSubscriptionResult = Apollo.SubscriptionResult<OnCommentaryDeletedSubscription>;
export const OnCourierExchangeQuoteCreatedDocument = gql`
    subscription OnCourierExchangeQuoteCreated {
  onCourierExchangeQuoteCreated {
    id
    quoteId
    loadId
    quoteOwnerCompanyName
    totalPrice
  }
}
    `;

/**
 * __useOnCourierExchangeQuoteCreatedSubscription__
 *
 * To run a query within a React component, call `useOnCourierExchangeQuoteCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCourierExchangeQuoteCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCourierExchangeQuoteCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCourierExchangeQuoteCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnCourierExchangeQuoteCreatedSubscription, OnCourierExchangeQuoteCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnCourierExchangeQuoteCreatedSubscription, OnCourierExchangeQuoteCreatedSubscriptionVariables>(OnCourierExchangeQuoteCreatedDocument, options);
      }
export type OnCourierExchangeQuoteCreatedSubscriptionHookResult = ReturnType<typeof useOnCourierExchangeQuoteCreatedSubscription>;
export type OnCourierExchangeQuoteCreatedSubscriptionResult = Apollo.SubscriptionResult<OnCourierExchangeQuoteCreatedSubscription>;
export const GetNotificationDocument = gql`
    subscription getNotification($username: String!) {
  onNewNotification(username: $username) {
    id
    ...NotificationDetails
  }
}
    ${NotificationDetailsFragmentDoc}`;

/**
 * __useGetNotificationSubscription__
 *
 * To run a query within a React component, call `useGetNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSubscription({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetNotificationSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetNotificationSubscription, GetNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetNotificationSubscription, GetNotificationSubscriptionVariables>(GetNotificationDocument, options);
      }
export type GetNotificationSubscriptionHookResult = ReturnType<typeof useGetNotificationSubscription>;
export type GetNotificationSubscriptionResult = Apollo.SubscriptionResult<GetNotificationSubscription>;
export const OnTaskCreatedDocument = gql`
    subscription onTaskCreated {
  onTaskAdded {
    id
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

/**
 * __useOnTaskCreatedSubscription__
 *
 * To run a query within a React component, call `useOnTaskCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTaskCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTaskCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnTaskCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnTaskCreatedSubscription, OnTaskCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnTaskCreatedSubscription, OnTaskCreatedSubscriptionVariables>(OnTaskCreatedDocument, options);
      }
export type OnTaskCreatedSubscriptionHookResult = ReturnType<typeof useOnTaskCreatedSubscription>;
export type OnTaskCreatedSubscriptionResult = Apollo.SubscriptionResult<OnTaskCreatedSubscription>;
export const OnTaskUpdatedDocument = gql`
    subscription onTaskUpdated {
  onTaskUpdated {
    id
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;

/**
 * __useOnTaskUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnTaskUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTaskUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTaskUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnTaskUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnTaskUpdatedSubscription, OnTaskUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnTaskUpdatedSubscription, OnTaskUpdatedSubscriptionVariables>(OnTaskUpdatedDocument, options);
      }
export type OnTaskUpdatedSubscriptionHookResult = ReturnType<typeof useOnTaskUpdatedSubscription>;
export type OnTaskUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnTaskUpdatedSubscription>;
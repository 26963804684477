import { useEffect, useMemo } from 'react'

import { Box, InputAdornment, Stack } from '@mui/material'
import { ControlledSelector, ControlledTextField, ISelectorOptions } from 'components/common'
import {
  useGetAccountPayableDetailsQuery,
  useGetDriverOrderDetailsQuery,
  useGetVatCategoriesQuery,
} from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

type OrderDetailsEditFormProps = {
  disabledEditStatus: boolean
  isOrderDetailsFormEditable: boolean
  driverId?: string
  accountPayableRecordId?: string
}

const OrderDetailsEditForm = ({
  disabledEditStatus,
  driverId,
  accountPayableRecordId,
  isOrderDetailsFormEditable,
}: OrderDetailsEditFormProps) => {
  const {
    control,
    formState: { errors },
    reset,
  } = useFormContext<any>()

  // Queries

  const { data: driverOrderDetails } = useGetDriverOrderDetailsQuery({
    variables: { driverId: Number(driverId) },
    skip: !driverId,
  })

  const { data: accountPayableDetails } = useGetAccountPayableDetailsQuery({
    variables: { id: Number(accountPayableRecordId) },
    skip: !accountPayableRecordId,
  })

  const driver = driverOrderDetails?.driver
  const accountPayableRecord = accountPayableDetails?.accountPayableRecord

  const { data: vatCategoriesData, loading: vatCategoriesLoading } = useGetVatCategoriesQuery()

  const vatCategoryItems: ISelectorOptions = useMemo(() => {
    if (!vatCategoriesLoading && vatCategoriesData?.vatCategories) {
      return vatCategoriesData.vatCategories.map((vc) => ({ value: vc.id, label: vc.name }))
    }

    return []
  }, [vatCategoriesData, vatCategoriesLoading])

  useEffect(() => {
    if (driver && accountPayableRecord) {
      reset({
        xeroPurchaseCode: driver?.xeroPurchaseCode,
        vatCategoryId: driver?.vatCategoryId,
        net: accountPayableRecord?.net,
      })
    }
  }, [driver, accountPayableRecord, reset])

  return (
    <>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Box sx={{ opacity: 0.5, whiteSpace: 'nowrap' }}>Purchase Code:</Box>

        <ControlledTextField
          control={control}
          name='xeroPurchaseCode'
          label=''
          variant='standard'
          disabled={!isOrderDetailsFormEditable || disabledEditStatus}
          sx={{ background: disabledEditStatus ? 'transparent' : 'auto' }}
          error={!!errors.xeroPurchaseCode}
          helperText={errors.xeroPurchaseCode?.message}
        />
      </Stack>

      <Stack spacing={1} direction={'row'} alignItems='baseline'>
        <Box sx={{ opacity: 0.5, whiteSpace: 'nowrap' }}>VAT:</Box>
        <ControlledSelector
          disabled={!isOrderDetailsFormEditable || disabledEditStatus}
          size='small'
          variant='standard'
          control={control}
          name='vatCategoryId'
          displayEmpty
          options={vatCategoryItems}
          error={!!errors.vatCategoryId}
          helperText={errors.vatCategoryId?.message}
        />
      </Stack>

      <Stack spacing={1} direction='row' alignItems='center'>
        <Box sx={{ opacity: 0.5, whiteSpace: 'nowrap' }}>Price:</Box>

        <ControlledTextField
          control={control}
          name='net'
          label=''
          variant='standard'
          disabled={!isOrderDetailsFormEditable || disabledEditStatus}
          sx={{ background: disabledEditStatus ? 'transparent' : 'auto' }}
          startAdornment={
            <InputAdornment position='start'>
              {accountPayableRecord?.currency.symbol}
            </InputAdornment>
          }
          error={!!errors.net}
          helperText={errors.net?.message}
        />
      </Stack>
    </>
  )
}

export default OrderDetailsEditForm

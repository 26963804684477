import { DigitPattern } from 'constants/index'

import { useState } from 'react'

import { joiResolver } from '@hookform/resolvers/joi'
import { LoadingButton } from '@mui/lab'
import { Button, Divider, Stack, Typography } from '@mui/material'
import {
  AccountPayableRecordStatus,
  GetAccountPayableInvoiceDocument,
  useGetAccountPayableRecordQuery,
  useGetPaymentRecordQuery,
  useUpdateAccountPayableNetAmountMutation,
  useUpdateDriverInformationMutation,
} from 'generated/graphql'
import Joi from 'joi'
import { Restricted } from 'providers'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'

import DocumentActionPanel from '../DocumentActionPanel/DocumentActionPanel'
import PaperPanel from '../PaperPanel'
import OrderDetailsEditForm from './OrderDetailsEditForm'
import OrderDetailsItems from './OrderDetailsItems'
import OrderDetailsStepper from './OrderDetailsStepper'

type OrderDetailsForm = {
  xeroPurchaseCode: string
  vatCategoryId: string
  net: number
}

function OrderDetailsFormValidationSchema() {
  return Joi.object({
    xeroPurchaseCode: Joi.string().required().pattern(new RegExp(DigitPattern)).messages({
      'string.base': 'Mandatory field',
      'string.empty': 'Mandatory field',
      'string.pattern.base': 'Only digits are allowed',
    }),
    vatCategoryId: Joi.string().required().label('Vat category'),
    net: Joi.number().positive().precision(2).required().messages({
      'number.empty': 'Mandatory field',
      'number.base': 'Price must be a numeric value',
      'number.precision': 'Price must have up to 2 decimal places',
      'number.positive': 'Price must be a positive number',
    }),
  })
}
const schema = OrderDetailsFormValidationSchema()

function OrderDetailsPanel() {
  const { id } = useParams<{ id?: string }>()

  // Order details form
  const methods = useForm<OrderDetailsForm>({
    resolver: joiResolver(schema),
    defaultValues: {
      xeroPurchaseCode: '',
      vatCategoryId: '',
      net: 0,
    },
    mode: 'onChange',
  })

  const [isOrderDetailsFormEditable, setOrderDetailsFormEditable] = useState(false)

  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
    resetField,
  } = methods

  // Queries
  const { data: orderDetails, loading } = useGetAccountPayableRecordQuery({
    variables: { id: Number(id) },
  })

  const accountPayableRecordStatus = orderDetails?.accountPayableRecord?.status

  const disabledEditStatus =
    accountPayableRecordStatus !== AccountPayableRecordStatus.Flagged &&
    accountPayableRecordStatus !== AccountPayableRecordStatus.UnderReview

  const bookingInvoiceId =
    orderDetails && Number(orderDetails?.accountPayableRecord?.bookingInvoice.id)

  const { data: payment, loading: paymentLoading } = useGetPaymentRecordQuery({
    variables: { bookingInvoiceId },
    skip: !bookingInvoiceId,
  })

  const [updateDriverInformation, { loading: driverInformationLoading }] =
    useUpdateDriverInformationMutation({
      refetchQueries: [
        {
          query: GetAccountPayableInvoiceDocument,
          variables: {
            accountPayableRecordId: Number(id),
          },
        },
      ],
    })

  const [updateAccountPayableNetAmount, { loading: accountPayableNetAmountLoading }] =
    useUpdateAccountPayableNetAmountMutation({
      refetchQueries: [
        {
          query: GetAccountPayableInvoiceDocument,
          variables: {
            accountPayableRecordId: Number(id),
          },
        },
      ],
    })

  const disabledSaveBtnStatus =
    !isValid || !isDirty || driverInformationLoading || accountPayableNetAmountLoading

  const loadingSaveBtnStatus = driverInformationLoading || accountPayableNetAmountLoading

  const onSubmit = async (data: OrderDetailsForm) => {
    const accountPayableRecordId = orderDetails?.accountPayableRecord?.id

    if (!accountPayableRecordId) {
      return
    }

    try {
      const driverResult = await updateDriverInformation({
        variables: {
          input: {
            accountPayableRecordId: Number(accountPayableRecordId),
            xeroPurchaseCode: data.xeroPurchaseCode,
            vatCategoryId: Number(data.vatCategoryId),
          },
        },
      })

      const driverInfo = driverResult.data?.updateDriverInformation

      const netResult = await updateAccountPayableNetAmount({
        variables: {
          input: {
            id: Number(accountPayableRecordId),
            net: data.net,
          },
        },
      })

      const netInfo = netResult.data?.updateAccountPayableNetAmount

      reset({
        xeroPurchaseCode: driverInfo?.xeroPurchaseCode ?? '',
        vatCategoryId: String(driverInfo?.vatCategoryId ?? ''),
        net: netInfo?.net,
      })

      toast.success('Changes saved successfully')
      setOrderDetailsFormEditable(false)
    } catch (error) {
      console.error(error)
      toast.error('Something went wrong. Please try again')
    }
  }

  const handleEditClick = () => {
    setOrderDetailsFormEditable(true)
  }

  const handleCancelClick = () => {
    setOrderDetailsFormEditable(false)
    resetField('xeroPurchaseCode')
    resetField('vatCategoryId')
    resetField('net')
  }

  if (loading || paymentLoading) {
    return <LoadingButton loading={loading || paymentLoading} />
  }

  return (
    <Stack spacing={2}>
      <Typography variant='h6'>Order Details</Typography>
      <PaperPanel>
        <Stack spacing={2} p={8}>
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography variant='h6'>
              {orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.name} (
              {orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.id ?? 'N/A'}
              )
            </Typography>
            <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
              <Button variant='outlined' onClick={handleEditClick} disabled={disabledEditStatus}>
                EDIT
              </Button>
              <Button
                variant='outlined'
                onClick={handleCancelClick}
                disabled={disabledEditStatus || !isOrderDetailsFormEditable}>
                Cancel
              </Button>
            </Stack>
          </Stack>
          <Divider role='presentation' />
          <FormProvider {...methods}>
            <OrderDetailsItems
              orderDetails={{
                fplRef: orderDetails?.accountPayableRecord?.bookingInvoice.booking.ourReference,
                customerRef:
                  orderDetails?.accountPayableRecord?.bookingInvoice.booking.customerReference,
                loadId:
                  orderDetails?.accountPayableRecord?.bookingInvoice.booking.courierExchangeLoadId,
                transactionId: payment?.paymentRecord?.transactionId,
                tariff: orderDetails?.accountPayableRecord?.bookingInvoice.booking.tariff?.name,
                tariffCategory:
                  orderDetails?.accountPayableRecord?.bookingInvoice.booking.tariff?.tariffCategory
                    .name,
              }}
              OrderDetailsEditForm={
                <OrderDetailsEditForm
                  isOrderDetailsFormEditable={isOrderDetailsFormEditable}
                  disabledEditStatus={disabledEditStatus}
                  driverId={
                    orderDetails?.accountPayableRecord?.bookingInvoice.bookingDriver?.driver.id
                  }
                  accountPayableRecordId={orderDetails?.accountPayableRecord?.id}
                />
              }
            />
          </FormProvider>

          <OrderDetailsStepper
            adresses={orderDetails?.accountPayableRecord?.bookingInvoice.booking.addresses.map(
              (address) => ({
                id: address.id,
                at: address?.at ?? '',
                by: address?.by ?? '',
                type: address.type,
                adress: {
                  id: address.address.id,
                  name: address.address.name,
                  adressLine1: address.address.addressLine1,
                  adressLine2: address.address.addressLine2,
                  postcode: address.address.postcode,
                  city: address.address.city,
                  countryName: address.address.country.name,
                },
              }),
            )}
          />
        </Stack>
      </PaperPanel>
      <DocumentActionPanel
        documentName='Order Details'
        createdAt={new Date(orderDetails?.accountPayableRecord?.bookingInvoice.invoiceDate)}>
        <Restricted to='ViewAccountsPayableRecordDocumentActions'>
          <LoadingButton
            loading={loadingSaveBtnStatus}
            variant='contained'
            disabled={disabledSaveBtnStatus}
            onClick={handleSubmit(onSubmit)}>
            Save
          </LoadingButton>
        </Restricted>
      </DocumentActionPanel>
    </Stack>
  )
}

export default OrderDetailsPanel

import { gql } from '@apollo/client'

export const GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY = gql`
  query allAccountPayableRecords(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [AccountPayableRecordSortInput!]
    $where: AccountPayableRecordFilterInput
  ) {
    accountPayableRecords(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          status
          statusLabel
          net
          vat
          gross
          currency {
            symbol
          }
          openQueries {
            id
            reason
          }
          bookingInvoice {
            id
            invoiceDate
            driverInvoiceNumber
            dueDate
            paymentDate
            bookingDriver {
              id
              driver {
                id
                name
              }
            }
            booking {
              id
              ourReference
            }
          }
          bookingInvoiceBatchLine {
            id
            batchId
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_NON_PAGINATED_ACCOUNTPAYABLE_RECORDS_QUERY = gql`
  query getAllAccountPayableRecords(
    $order: [AccountPayableRecordSortInput!]
    $where: AccountPayableRecordFilterInput
  ) {
    allAccountPayableRecords(order: $order, where: $where) {
      id
      status
      statusLabel
      net
      vat
      gross
      currency {
        symbol
      }
      openQueries {
        id
        reason
      }
      bookingInvoice {
        id
        invoiceDate
        driverInvoiceNumber
        dueDate
        paymentDate
        bookingDriver {
          id
          driver {
            id
            name
          }
        }
        booking {
          id
          ourReference
        }
      }
    }
  }
`

export const GET_ALL_GROUPED_ACCOUNTPAYABLE_RECORDS_QUERY = gql`
  query allGroupedAccountPayableRecords(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [GroupedAccountPayableRecordDtoSortInput!]
    $where: GroupedAccountPayableRecordDtoFilterInput
    $status: Int!
    $dueDateStart: DateTime
    $dueDateEnd: DateTime
    $accountsPayableFilterCriteria: AccountsPayableFilterCriteria
  ) {
    groupedAccountPayableRecords(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
      status: $status
      dueDateStart: $dueDateStart
      dueDateEnd: $dueDateEnd
      accountsPayableFilterCriteria: $accountsPayableFilterCriteria
    ) {
      edges {
        node {
          id
          driverId
          driverName
          invoiceCount
          accountPayableStatus
          courierExchangeLoadId
          ourReference
          driverNumber
          dueDate
          xeroPurchaseCode
          currencyAmountSum {
            id
            net
            vat
            gross
            currencySymbol
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
export const GET_ACCOUNT_PAYABLE_RECORD_QUERY = gql`
  query GetAccountPayableRecord($id: Long!) {
    accountPayableRecord(accountPayableRecordId: $id) {
      id
      status
      openQueries {
        id
        reason
      }
      gross
      currency {
        id
        symbol
      }
      bookingInvoice {
        id
        invoiceDate
        bookingDriver {
          id
          driver {
            id
            name
            xeroPurchaseCode
            vatCategory {
              id
            }
          }
        }
        booking {
          id
          ourReference
          customerReference
          courierExchangeLoadId
          createdAt
          tariff {
            id
            name
            tariffCategory {
              id
              name
            }
          }
          addresses {
            id
            type
            at
            by
            address {
              id
              name
              postcode
              addressLine1
              addressLine2
              city
              country {
                name
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ACCOUNT_PAYABLE_DRIVER_NAME = gql`
  query getAccountPayableDriverName($id: Long!) {
    accountPayableRecord(accountPayableRecordId: $id) {
      id
      status
      statusLabel
      bookingInvoice {
        id
        booking {
          id
          ourReference
        }
        bookingDriver {
          id
          driver {
            id
            name
            email
          }
        }
      }
      bookingInvoiceBatchLine {
        id
        batchId
      }
    }
  }
`

export const GET_TOTAL_SUM_ACCOUNT_PAYABLE = gql`
  query getTotalSum($status: Int!, $supplierId: Long!) {
    accountPayableTotals(status: $status, supplierId: $supplierId) {
      id
      net
      vat
      gross
      currencySymbol
    }
  }
`

export const GET_ACCOUNT_PAYABLE_OVERVIEW = gql`
  query accountPayableOverview(
    $startDate: DateTime
    $endDate: DateTime
    $supplierCodes: [String!]!
  ) {
    accountPayableOverview(
      startDate: $startDate
      endDate: $endDate
      supplierCodes: $supplierCodes
    ) {
      eurosAmount
      poundsAmount
      dollarsAmount
      scheduledInvoicesCount
      bookingsWithCompletedDocumentsCount
      bookingsWithOpenQueriesCount
      bookingsWithSupplementaryInvoiceReceivedCount
      bookingsWithCreditNoteReceivedCount
      bookingsWithApprovedDocumentsCount
      overduesForApprovalCount
      failedPaymentsCount
      sentPaymentsCount
    }
  }
`

export const GET_ACCOUNT_PAYABLE_TOTAL_COUNT = gql`
  query getAccountPayableTotalCount($status: Int!, $supplierId: Long!) {
    accountPayableTotalCount(status: $status, supplierId: $supplierId)
  }
`

export const GET_INVOICE = gql`
  query getAccountPayableInvoice($accountPayableRecordId: Long!) {
    accountPayableInvoice(accountPayableRecordId: $accountPayableRecordId) {
      htmlString
      createdAt
    }
  }
`

export const GET_ACCOUNT_PAYABLE_POD = gql`
  query getAccountPayablePod($accountPayableRecordId: Long!) {
    accountPayablePod(accountPayableRecordId: $accountPayableRecordId) {
      id
      htmlString
      createdAt
      signatureUrls
      proofOfDeliveryPhotoUrls
      proofOfCollectionPhotoUrls
    }
  }
`

export const GET_ACCOUNT_PAYABLE_SEARCH_RESULTS = gql`
  query getAccountPayableInvoiceSearchRequest($query: String!) {
    accountPayableSearchResults(query: $query) {
      entityId
      entityName
      description
    }
  }
`

export const GET_FAILED_PAYMENT_ERROR_MESSAGE = gql`
  query getFailedPaymentErrorMessage($accountPayableRecordId: Long!) {
    paymentFailedErrorMessage(accountPayableRecordId: $accountPayableRecordId)
  }
`

export const GET_ACCOUNT_PAYABLE_RECORDS_BY_IDS = gql`
  query getAccountPayableRecordsByIds($accountPayableRecordsIds: [Long!]!) {
    accountPayableRecordsByIds(accountPayableRecordsIds: $accountPayableRecordsIds) {
      id
      bookingInvoice {
        id
        invoiceDate
        driverInvoiceNumber
      }
    }
  }
`

export const GET_ALL_ACCOUNTPAYABLE_RECORDS_QUERY_WITH_BATCHLINE = gql`
  query allAccountPayableRecordsWithBatchLine(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [AccountPayableRecordSortInput!]
    $where: AccountPayableRecordFilterInput
  ) {
    accountPayableRecords(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      where: $where
    ) {
      edges {
        node {
          id
          status
          statusLabel
          net
          vat
          gross
          currency {
            symbol
          }
          openQueries {
            id
            reason
          }
          bookingInvoice {
            id
            invoiceDate
            driverInvoiceNumber
            dueDate
            paymentDate
            bookingDriver {
              id
              driver {
                id
                name
              }
            }
            booking {
              id
              ourReference
            }
          }
          bookingInvoiceBatchLine {
            id
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

export const GET_ACCOUNTPAYABLE_RECORD_LOGS_QUERY = gql`
  query accountPayableRecordLogs(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $accountPayableRecordId: Long!
  ) {
    accountPayableRecordLogs(
      after: $after
      before: $before
      first: $first
      last: $last
      accountPayableRecordId: $accountPayableRecordId
    ) {
      edges {
        node {
          id
          description
          reason
          createdAt
          createdBy
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`
export const GET_ACCOUNT_PAYABLE_DETAILS_QUERY = gql`
  query GetAccountPayableDetails($id: Long!) {
    accountPayableRecord(accountPayableRecordId: $id) {
      id
      net
      currency {
        id
        symbol
      }
    }
  }
`
